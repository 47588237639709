import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { motion, AnimatePresence } from 'framer-motion';

const CompExplainer = ({
    user,
    title,
    explainerId,
    type = 'inline', // 'inline' or 'modal'
    children, // Rich text content passed as children
    className = '' // Additional styling classes
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(true);

    useEffect(() => {
        const checkUserPreference = async () => {
            if (user?.uid) {
                try {
                    const userDocRef = doc(db, 'users', user.uid);
                    const userDoc = await getDoc(userDocRef);

                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        const preference = userData?.onboarding?.[explainerId];

                        // If preference exists and is true, don't show the explainer
                        if (preference) {
                            setIsVisible(false);
                            //setIsVisible(true);
                        } else {
                            setIsVisible(true);
                        }
                    } else {
                        setIsVisible(true);
                    }
                } catch (error) {
                    console.error("Error checking user preferences:", error);
                    setIsVisible(true); // Show by default if there's an error
                }
            }
        };

        checkUserPreference();
    }, [user, explainerId]);

    const handleDismiss = async () => {
        if (dontShowAgain && user?.uid) {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                await updateDoc(userDocRef, {
                    [`onboarding.${explainerId}`]: true
                });
            } catch (error) {
                console.error("Error updating user preferences:", error);
            }
        }

        // Animate out
        setIsVisible(false);
    };

    if (!isVisible) return null;


    // b3-admin-explainer.svg

    // Inline explainer component
    if (type === 'inline') {
        return (
            <AnimatePresence>
                {isVisible && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, height: 0, marginBottom: 0 }}
                        transition={{ duration: 0.3 }}
                        className={`bg-black/20 border border-zinc-800 rounded-lg p-4 mb-4 shadow-lg ${className}`}
                    >
                        {/* {title && (
                            <h3 className="text-lg  text-zinc-800 dark:text-zinc-50 mb-2">
                                {title}
                            </h3>
                        )} */}

                        <div>

                            <div className=" text-zinc-300 mb-4 mt-4">
                                <div className='flex items-start gap-4'>
                                    <img src="/util/app/b3-admin-explainer-2.svg" alt="b3-admin-explainer" className="w-10 h-10 flex-shrink-0 mt-1" />
                                    <div className='text-base'>
                                        {title && (
                                            <h3 className="text-lg  text-zinc-50 mb-2">
                                                {title}
                                            </h3>
                                        )}
                                        {children}
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center justify-end gap-4 mt-3 pt-2">
                                <label className="flex items-center text-sm  text-zinc-400">
                                    <input
                                        type="checkbox"
                                        checked={dontShowAgain}
                                        onChange={(e) => setDontShowAgain(e.target.checked)}
                                        className="mr-2 h-4 w-4 text-sky-400 focus:ring-sky-500 border-sky-300 rounded"
                                    />
                                    Don't show again
                                </label>

                                <button
                                    onClick={handleDismiss}
                                    type="button"
                                    className="px-3 py-1 text-sm bg-sky-500 hover:bg-sky-400 text-white rounded-md transition-colors"
                                >
                                    Dismiss
                                </button>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        );
    }

    // Modal explainer component
    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50"
                    onClick={(e) => e.target === e.currentTarget && handleDismiss()}
                >
                    <motion.div
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        className={`bg-white dark:bg-zinc-800 rounded-lg p-6 max-w-md w-full shadow-xl mx-auto ${className}`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {title && (
                            <h3 className="text-xl font-bold text-zinc-800 dark:text-zinc-200 mb-3">
                                {title}
                            </h3>
                        )}

                        <div className="text-zinc-600 dark:text-zinc-300 mb-6 max-h-[60vh] overflow-y-auto">
                            {children}
                        </div>

                        <div className="flex items-center justify-between pt-4 border-t border-zinc-100 dark:border-zinc-700">
                            <label className="flex items-center text-sm text-zinc-500 dark:text-zinc-400">
                                <input
                                    type="checkbox"
                                    checked={dontShowAgain}
                                    onChange={(e) => setDontShowAgain(e.target.checked)}
                                    className="mr-2 h-4 w-4 text-zinc-600 focus:ring-zinc-500 border-zinc-300 rounded"
                                />
                                Don't show again
                            </label>

                            <button
                                onClick={handleDismiss}
                                type="button"
                                className="px-4 py-2 bg-zinc-600 hover:bg-zinc-700 text-white rounded-md transition-colors"
                            >
                                Dismiss
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default CompExplainer;