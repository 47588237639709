import React, { useState, useEffect } from 'react'
import { CompHSChat } from '../components/CompHSChat'
//import { UserAuth } from '../context/AuthContext'
import FormEAPSupport from './FormEAPSupport'
import CompEAPSupport from './CompEAPSupport'
import { UserAuth } from '../context/AuthContext'
import CompGenCards from '../components/CompGenCards'

import { motion } from 'framer-motion'

import CompHelpGuide from '../components/CompHelpGuide'
import { BookOpenIcon, AcademicCapIcon, ChatBubbleLeftRightIcon, ArrowRightIcon } from '@heroicons/react/24/solid'
import { query, collection, onSnapshot, orderBy, where } from 'firebase/firestore'
import { db } from '../firebase'
import { Link, useNavigate } from 'react-router-dom'
//import { useTab } from './AdminNav-v2'
//import { useTab } from '../components/AdminNav-v2'
//import { useAnimatedTabs, tabs } from '../components/AdminNav-v2'
//import {navAllStories} from '../components/AdminNav-v2'
import { useNavigation } from '../components/AdminNav-v2';
//import { Skeleton } from '@mui/material';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import KanbanModal from '../components/KanbanModal';
import ReactPlayer from 'react-player'


const Home = () => {
  //const { handleTabClick } = useTab();
  const currentHour = new Date().getHours();
  const greetingMessage = currentHour >= 4 && currentHour < 12 ? 'Good morning' :
    currentHour >= 12 && currentHour <= 17 ? 'Good afternoon' :
      currentHour > 17 || currentHour < 4 ? 'Good evening' : 'Welcome';

  const { user, logOut } = UserAuth();
  const navigate = useNavigate();

  const { navAllStories } = useNavigation();

  const [isKanbanModalOpen, setIsKanbanModalOpen] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);

  // Function to open the Kanban modal with a specific story
  const openKanbanModal = (story) => {
    setSelectedStory(story);
    setIsKanbanModalOpen(true);
  };

  //const { handleTabClick } = useAnimatedTabs();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const baseColor = '#1C1C22';
  const highlightColor = '#2d2d2d';



  const messagesList = [
    // {
    //   avatar: 'AI',
    //   author: 'StoryPath.ai Team',
    //   title: 'Ready? Create Your First Story in 3 Minutes',
    //   //timestamp: '3 mins ago',
    //   timestamp: '2025-03-07',
    //   image: '/media/notifications/sp-home-sp-1.png',
    //   content: [
    //     'It only takes 3 minutes to create your first story.  Click the button below to get started.'
    //   ],
    //   link: {
    //     text: 'Create Your First Story',
    //     url: '/app/storyboard'
    //   }
    // },
    {
      avatar: 'SP',
      author: 'StoryPath.ai Team',
      video: 'https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2FStoryPath_Product_Overview_1.mp4?alt=media&token=3eb2d3ee-4fbd-42fc-96fa-6f93d2ad90a2',
      //video: 'https://www.youtube.com/watch?v=NX5VFpC5kmQ',
      title: 'Welcome to StoryPath',
      //timestamp: 'Just now',
      timestamp: '2025-03-07',
      image: '/media/video/video-cover.png',
      content: [
        'It only takes 3 minutes to create your first story.  Click the link below to get started.'
      ],
      link: {
        text: 'Create Your First Story',
        url: '/app/storyboard'
      }
    },
    // {
    //   avatar: 'AI',
    //   author: 'AI Assistant',
    //   title: 'Share has been opened!',
    //   timestamp: '2 mins ago',
    //   image: '/util/app/video-placeholder.png',
    //   content: [
    //     'I can help you create compelling stories using our advanced AI tools.',
    //     'Let me know if you need any assistance!'
    //   ]
    // },
  ];

  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState(true);
  const [processedRequestIds] = useState(new Set());

  // Initialize Firebase Functions
  const functions = getFunctions();
  if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }

  const getPulseMessagesCall = httpsCallable(functions, 'getPulseMessages');

  // Update useEffect to fetch messages from Firebase
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        if (!user) {
          console.log('User not authenticated');
          return;
        }

        // const requestId = Date.now().toString();
        // const result = await getPulseMessagesCall({ requestId });

        // // Check if we already processed this response
        // if (processedRequestIds.has(result.data.requestId)) {
        //   return;
        // }

        // // Process the messages
        // const messages = result.data.messages || [];
        // setDisplayedMessages(messages);

        setDisplayedMessages(messagesList);

        // // Mark this request as processed
        // processedRequestIds.add(result.data.requestId);

      } catch (error) {
        console.error('Error fetching messages:', error);
        setDisplayedMessages([]); // Set empty array on error
      } finally {
        setMessagesLoading(false);
      }
    };

    if (user) {
      fetchMessages();
    } else {
      setMessagesLoading(false);
    }
  }, [user]);

  const [stories, setStories] = useState([])
  const [storiesLoaded, setStoriesLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (user?.uid) {
        const q = query(
          collection(db, 'stories'),
          orderBy('lastModified', 'desc'),
          where("role.owner", "array-contains", user?.uid)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let storiesArr = [];
          querySnapshot.forEach((doc) => {
            storiesArr.push({ ...doc.data(), id: doc.id });
          });
          // Only keep the 3 most recent stories
          setStories(storiesArr.slice(0, 4));
          setStoriesLoaded(true);
        });
        return () => unsubscribe();
      }
    }, 250);
  }, [user]);

  // Add this tab object that matches the Stories configuration from AdminNav-v2
  const storiesTab = {
    value: "path-Stories",
    label: "Story Library",
    desc: "Stories Content",
    link: "/app/stories",
    icon: (
      <img src="/util/app/b3-admin-story-5.svg" className="h-7 " />
    )
  };

  const navigateToStories = () => {
    const storiesTab = tabs.find(tab => tab.value === "path-Stories");
    handleTabClick(storiesTab);
  };
 

  const VideoPlayer = ({ url, previewImage }) => {
    const [playing, setPlaying] = useState(false);
    
    return (
      <div className="relative w-full h-full">
        {!playing && (
          <div className="absolute inset-0 z-10 flex items-center justify-center">
            {/* Preview Image */}
            <div 
              className="absolute inset-0 bg-cover bg-center" 
              style={{ 
                backgroundImage: `url(${previewImage})`,
                backgroundSize: 'cover',
              }}
            />
            
            {/* Play Button */}
            <button
              onClick={() => setPlaying(true)}
              className="relative z-20 w-16 h-16 flex items-center justify-center bg-sky-600/90 hover:bg-sky-500 rounded-full transition-colors shadow-lg"
              aria-label="Play video"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 text-white ml-1">
                <path fillRule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286l-11.54 6.347c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clipRule="evenodd" />
              </svg>
            </button>
            
            {/* Optional Overlay Gradient */}
            <div className="absolute inset-0 bg-black/20 z-10" />
          </div>
        )}
        
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          playing={playing}
          controls={playing}
          className="absolute top-0 left-0 rounded-lg"
          onEnded={() => setPlaying(false)}
        />
      </div>
    );
  };



  return (
    <div className="h-[calc(100vh-210px)] admin-scroll">

      {/* <CompGenCards /> */}

      <KanbanModal
        isOpen={isKanbanModalOpen}
        onClose={() => setIsKanbanModalOpen(false)}
        story={selectedStory}
      />

      <div className="relative isolate  ">


        <div className="grid grid-cols-12 gap-8  ">
          {/* Left Column - Messages & Updates */}

          <div className="col-span-8">



            <div className="px-0">
              {/* 
            <div className="text-2xl   leading-7 text-zinc-50 mb-8">
            Welcome  
          </div>
          <p className="mt-4 text-base leading-8 text-zinc-400">
            We are excited to help support your storytelling journey. The following resources will help you to get a jump start on your first story. Please reach out with any questions or feedback and we appreciate your participation in our Early Access Program.
          </p> */}

              <div className="flex items-center   mb-4 -mt-4   ">
                {/* <img src="/util/app/b3-admin-active-4.svg" className="w-8 h-8 opacity-50" /> */}
                {/* <h2 className="text-xl font-base tracking-tight text-white">Messages</h2> */}
                {/* <h6 class="block antialiased tracking-normal font-sans text-base   leading-relaxed text-zinc-200">New Activity</h6> */}

              </div>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <div className="block antialiased tracking-normal text-lg leading-relaxed text-zinc-200">
                    <img src="/util/app/b3-admin-activity.svg" className="h-7 inline-block mr-3 opacity-50" />
                    Updates
                  </div>

                  <div className="flex gap-2">
                    {/* <div
                      onClick={navAllStories}
                      className="text-sm   transition-colors cursor-pointer
                  bg-black/10 hover:bg-black/30 rounded-lg p-2 text-zinc-500 hover:text-zinc-200 pl-3
                  "
                    >
                      archive
                      <img src="/util/app/b3-admin-archive.svg" className="h-5 inline-block ml-2 opacity-50" />
                    </div> */}

                  </div>
                </div>
                <div className="  h-[calc(100vh-300px)] p-4 rounded-lg bg-black/10">

                  <div className="overflow-y-auto h-full pr-4">
                    {messagesLoading ? (
                      <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
                        <Skeleton count={3} height={200} className="mb-4" />
                      </SkeletonTheme>
                    ) : (
                      [...displayedMessages].reverse().map((message, index) => (
                        <motion.div
                          key={message.title + index}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: index * 0.1 }}
                          className="mb-4 w-full bg-black/0 rounded-lg pr-2"
                        >
                          <div className='flex'>
                            {/* <div className='flex-none w-96 aspect-square p-4'>
                              <div className='w-full h-full bg-black/20 rounded'
                                style={{
                                  backgroundImage: `url(${message.image})`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center'
                                }}
                              />
                            </div> */}

                            <div className=" py-5 pl-0  flex-grow gap-4">
                              <div className="flex-shrink-0   gap-4  ">
                                {/* <div className="w-12 h-12 rounded-lg bg-[#56F3D6]/5 flex items-center justify-center">
                                  <img src={message.avatarImage || message.author?.substring(0, 2)} alt="Avatar" className="w-12 h-12 rounded-lg" />
                                </div> */}
                                <div className="flex flex-col ml-4">
                                  <div className="text-sm font-base text-orange-300">{message.author}</div>
                                  <h3 className="text-2xl font-mediumx text-zinc-50 mt-1">{message.title}</h3>
                                </div>
                                {message.video && (
  <div className="w-[92%] my-4">
    <div className="aspect-video relative rounded-lg overflow-hidden">
      <VideoPlayer 
        url={message.video}
        previewImage={message.image || "/media/video/video-cover.png"}
      />
    </div>
  </div>
)}



                                {/* <div className="flex flex-1 justify-end">
                                  {message.timestamp ? (
                                    <div className="text-xs text-zinc-500 bg-white/5 rounded-lg p-2 h-8">
                                      {new Date(message.timestamp).toLocaleDateString()}
                                    </div>
                                  ) : (
                                    <div className="text-xs text-zinc-500 bg-white/5 rounded-lg p-2 h-8">
                                      Just now
                                    </div>
                                  )}
                                </div> */}
                              </div>

                              <div className="flex-1 text-base   ml-4">
                                <div className="space-y-1 text-zinc-200    pb-4 leading-8">
                                  {Array.isArray(message.content) ? (
                                    message.content.map((paragraph, pIndex) => (
                                      <p key={pIndex}>{paragraph}</p>
                                    ))
                                  ) : (
                                    <div
                                      className="space-y-1 text-zinc-300 mt-6 pb-4 leading-8"
                                      dangerouslySetInnerHTML={{ __html: message.content }}
                                    />
                                  )}
                                </div>
                              </div>

                              {message.link && (
                                <div className="flex-1 text-base  ml-3.5 -mt-2">
                                  
                                    <div
                                      className="space-y-1 text-zinc-300  leading-8"
                                    >
                                      <a href={message.link.url} className="text-sky-500 hover:text-sky-400   ">{message.link.text} &gt;</a>
                                    </div>
                                  
                                </div>
                              )}

                            </div>

                          </div>
                        </motion.div>
                      ))
                    )}
                  </div>
                </div>


                {/* <div className="  bg-black/20 rounded-lg p-4">
                  <form onSubmit={(e) => {
                    e.preventDefault();
                  }}>
                    <div className="flex gap-4">
                      <input
                        type="text"
                        className="flex-1 bg-black/20 text-white rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                        placeholder="Type your message..."
                      />
                      <button
                        type="submit"
                        className="bg-sky-600 hover:bg-sky-700 text-white px-4 py-2 rounded-lg transition-colors"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
          </div>

          {/* Right Column - Timeline */}
          <div className="col-span-4">
            <div className="space-y-4 -mt-0.5">
              {/* <div className="text-xl font-base tracking-tight text-white mb-8 ">
                Recent Stories
              </div> */}
              <div className="flex justify-between items-center">
                <div className="block    tracking-normal text-lg leading-relaxed text-zinc-200">
                  <img src="/util/app/b3-admin-story-5.svg" className="h-7 inline-block mr-3 opacity-50" />
                  Recent Stories
                </div>
                <div
                  onClick={navAllStories}
                  className="text-xs   transition-colors cursor-pointer
                  bg-black/20 hover:bg-black/30 rounded-lg p-2 text-sky-500 hover:text-sky-400 pl-3
                  "
                >
                  all stories
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 w-4 inline-block">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" />
                  </svg>

                  {/* <ArrowRightIcon className="w-4 h-4 inline-block ml-2" /> */}
                </div>
              </div>


              <div className=" ">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.25,
                    ease: "easeOut"
                  }}
                >
                  <div className="gap-4 bg-black/10 rounded-lg pl-4 pr-2 py-4">
                    <div >
                      {storiesLoaded && stories.length > 0 ? (
                        <div className=" section-scroll  h-[calc(100vh-330px)] pr-4">
                          {stories.map((story, index) => (
                            <motion.div
                              key={story.id}
                              whileHover={{ scale: 1.02 }}
                              transition={{ duration: 0.2 }}
                              className={`relative pointer-events-auto mb-4 overflow-hidden rounded-lg
                              animate__animated animate__fadeIn
                            `}
                            >
                              {story.type === "tell" ? (
                                // Special layout for "tell" type stories
                                <div className="rounded-lg">
                                  <div className="w-full aspect-video rounded-lg overflow-hidden">
                                    <div
                                      className="w-full h-full bg-cover bg-center relative flex flex-col bg-black/10"
                                    >
                                      {/* Gradient overlay */}
                                      <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-black/25 to-transparent" />

                                      {/* Background image with effects */}
                                      <div className="absolute inset-0  
                                        opacity-10
                                        brightness-100
                                        contrast-[1.5]
                                        saturate-100
                                        hue-rotate-0
                                        pointer-events-none"
                                        style={{
                                          backgroundImage: `url(${story.previewThumb})`,
                                          backgroundSize: 'cover',
                                        }}
                                      />

                                      {/* Top section with avatar and visibility */}
                                      <div className="relative z-10 flex justify-between">
                                        <div className='m-4'>
                                          {story.userPhotoURL ? (
                                            <img
                                              referrerPolicy="no-referrer"
                                              src={story.userPhotoURL}
                                              className="w-12 h-12 rounded-lg"
                                              alt="Author"
                                              onError={(e) => {
                                                e.target.style.display = 'none';
                                                e.target.nextSibling.style.display = 'flex';
                                              }}
                                            />
                                          ) : null}
                                          <div
                                            className={`w-14 h-14 rounded-lg bg-sky-600 text-white flex items-center justify-center text-lg font-medium ${story.userPhotoURL ? 'hidden' : ''}`}
                                          >
                                            {story.userName ? story.userName.split(' ').map(name => name[0]).join('').substring(0, 2).toUpperCase() : 'U'}
                                          </div>
                                        </div>
                                        <div className="m-1.5 content-start flex flex-col items-end mt-4">
                                          {story.visibility === 'public' ? (
                                            <div className="text-amber-500 px-2 py-1 bg-black/50 h-6 rounded text-xs">public</div>
                                          ) : (
                                            <div className="text-zinc-300 px-2 py-1 bg-black/50 h-6 rounded text-xs">private</div>
                                          )}
                                          <div className="text-zinc-100 text-xs pt-2 opacity-80 mr-2 text-right">
                                            {story.createdAt && new Date(story.createdAt.seconds * 1000).toLocaleDateString("en-US", { month: 'short', day: 'numeric' })}
                                          </div>
                                        </div>
                                      </div>

                                      {/* Middle content section */}
                                      <div className="relative z-10 flex-grow flex mt-4 px-4 drop-shadow-lg">
                                        <div className="w-full">
                                          {story.formData && (
                                            <>
                                              <h3 className="text-zinc-50 font-semibold text-xl w-full mt-4">
                                                {JSON.parse(story.formData).promptCustomerName}
                                                {/* <span className="text-zinc-50 text-sm ml-1 w-full">
                                                  {JSON.parse(story.formData).promptCustomerRole}
                                                </span> */}
                                              </h3>
                                              <p className="text-zinc-400 text-sm w-full line-clamp-2 mt-1">
                                                {JSON.parse(story.formData).promptProductName}
                                              </p>
                                              {/* <p className="text-zinc-400 mt-2 text-xs w-full line-clamp-2">
                                                {JSON.parse(story.formData).promptCustomerProblem}
                                              </p> */}
                                            </>
                                          ) || (
                                              <>
                                                <h3 className="text-white text-xl font-bold">{story.name}</h3>
                                                <p className="text-zinc-300 text-sm line-clamp-2 mt-2">
                                                  {story.description || 'No description available'}
                                                </p>
                                              </>
                                            )}
                                        </div>
                                      </div>

                                      {/* Bottom section with action buttons */}
                                      <div className=" gap-x-2 flex   z-10    absolute bottom-4   right-3">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            openKanbanModal(story);
                                          }}
                                          className="p-2 bg-white/5 hover:bg-white/10 text-white rounded-md transition-colors duration-200 inline-flex text-sm items-center justify-center cursor-pointer"
                                        >
                                          Open StoryPaths

                                        </button>
                                        <Link
                                          to={`/app/stories/${story.id}/edit/0`}
                                          className="p-2 bg-white/5 hover:bg-white/10 text-white rounded-md transition-colors duration-200 inline-flex text-sm items-center justify-center cursor-pointer"
                                        >
                                          Theater
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                // Original layout for other story types
                                <Link
                                  to={`/app/stories/${story.id}/edit/0`}
                                  key={story.id}
                                  className="rounded-lg">

                                  <div className={`w-full  ${story.backgroundSky}`}>
                                    <div
                                      className="w-full  bg-cover bg-center relative aspect-video overflow-hidden rounded-lg"
                                      style={{
                                        backgroundImage: `url(${story.previewThumb.replace('/upload', '/upload/ar_1:1,c_fill,w_1200/')})`,
                                      }}
                                    >
                                      <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-black/25 to-transparent" />

                                      {/* Author avatar */}
                                      <div className="absolute top-4 left-4">
                                        {story.userPhotoURL ? (
                                          <img
                                            referrerPolicy="no-referrer"
                                            src={story.userPhotoURL}
                                            className="w-12 h-12 rounded-lg"
                                            alt="Author"
                                            onError={(e) => {
                                              e.target.style.display = 'none';
                                              e.target.nextSibling.style.display = 'flex';
                                            }}
                                          />
                                        ) : null}
                                        <div
                                          className={`w-14 h-14 rounded-lg bg-sky-600 text-white flex items-center justify-center text-lg font-medium ${story.userPhotoURL ? 'hidden' : ''}`}
                                        >
                                          {story.userName ? story.userName.split(' ').map(name => name[0]).join('').substring(0, 2).toUpperCase() : 'U'}
                                        </div>
                                      </div>

                                      {/* Story details */}
                                      <div className="absolute bottom-0 left-0 p-4 w-full">
                                        <div>
                                          <h3 className="text-white text-xl font-bold">{story.name}</h3>
                                          {/* <p className="text-zinc-300 text-sm opacity-80">
                                            {story.createdAt && new Date(story.createdAt.seconds * 1000).toLocaleDateString("en-US")}
                                          </p> */}
                                        </div>
                                        <p className="text-zinc-300 text-sm line-clamp-2 ">
                                          {story.description || 'No description available'}
                                        </p>
                                      </div>

                                      {/* Visibility badge */}
                                      {/* <div className="absolute top-4 right-2 px-2 py-1 bg-black/50 rounded text-xs">
                                        {story.visibility === 'public' ? (
                                          <span className="text-amber-500">public</span>
                                        ) : (
                                          <span className="text-zinc-300">private</span>
                                        )}
                                      </div> */}
                                      <div className="m-1.5 content-start flex flex-col items-end mt-4">
                                        {story.visibility === 'public' ? (
                                          <div className="text-amber-500 px-2 py-1 bg-black/50 h-6 rounded text-xs">public</div>
                                        ) : (
                                          <div className="text-zinc-300 px-2 py-1 bg-black/50 h-6 rounded text-xs">private</div>
                                        )}
                                        <div className="text-zinc-100 text-xs pt-2 opacity-80 mr-2 text-right">
                                          {story.createdAt && new Date(story.createdAt.seconds * 1000).toLocaleDateString("en-US", { month: 'short', day: 'numeric' })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              )}

                              <div className="absolute top-4 right-4 z-10">

                              </div>
                            </motion.div>
                          ))}
                        </div>
                      ) : storiesLoaded ? (
                        <div className="flex flex-col items-center mt-12 -mb-12 h-[calc(100vh-334px)] text-center p-6">
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.6 }}
                            className="flex flex-col items-center"
                          >
                            <div className="mb-4">
                              <img src="/util/app/b3-admin-story-5.svg" className="h-16 w-16 mx-auto opacity-50" alt="Create story" />
                            </div>
                            <h3 className="text-xl font-medium text-zinc-200 mb-2">No stories yet</h3>
                            <p className="text-zinc-400 mb-6">Start your storytelling journey by creating your first story</p>
                            <a
                              href="/app/storyboard"
                              className="px-4 py-2 bg-sky-600 hover:bg-sky-500 text-white rounded-lg transition-colors"
                            >
                              Create Your First Story
                            </a>
                          </motion.div>
                        </div>
                      ) : (
                        <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
                          <Skeleton className="h-72 mb-4" count={3} />
                        </SkeletonTheme>
                      )}

                    </div>
                  </div>

                </motion.div>
              </div>





              {/* <div className="block antialiased pt-4 tracking-normal font-sans text-base font-semibold leading-relaxed text-zinc-200">
                <img src="/util/app/b3-admin-idea.svg" className="w-8 h-8 inline-block mr-2 opacity-50" />
                Tips &amp; Community
                </div>


              <div className="flow-root">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.25,
                    ease: "easeOut"
                  }}
                >
                  <div className="bg-black/10 rounded-lg">
                    <Carousel
                      autoPlay={true}
                      interval={5000}
                      infiniteLoop={true}
                      showStatus={false}
                      showThumbs={false}
                      showArrows={false}
                      className="custom-carousel"
                      // transitionTime={500}
                      // transitionMode="fade"
                    >
                      {tutorialPosts.map((post) => (
                        <div 
                          key={post.id}
                          className="p-4 hover:bg-black/20 rounded-lg transition-all cursor-pointer"
                        >
                          <div 
                            className="relative p-4 rounded-lg aspect-video"
                          >
                            <div className="absolute inset-0 bg-black/20 rounded-lg"
                              style={{ 
                                backgroundImage: `url(${post.image})`, 
                                backgroundSize: 'cover', 
                                backgroundPosition: 'center' 
                              }}
                            ></div>
                            
                            <div className="absolute w-3/5 z-10 right-0 text-left top-1/4 -mt-4 pr-12">
                              <div className="flex items-center text-left gap-2 mb-2">
                                <span className="text-sm px-2 py-1 bg-sky-500/10 text-amber-400 text-left rounded">
                                  {post.category}
                                </span>
                                <span className="text-sm text-zinc-300">
                                  {post.duration}
                                </span>
                              </div>
                              <h5 className="text-zinc-50 text-2xl font-medium mt-4">
                                {post.title}
                              </h5>
                              <p className="text-zinc-300 text-sm line-clamp-4 mt-2">
                                {post.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </motion.div>
              </div> */}

              {/* 

              <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-zinc-200">Lastest</h6>


              <div className="flow-root">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.25,
                    ease: "easeOut"
                  }}
                >

                  <div className="p-4 bg-black/10 rounded-lg text-sm text-zinc-200">
                    nNew here from community
                  </div>

                </motion.div>
              </div> */}


            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default Home;