import React, { useEffect } from 'react'; 
import { formatDistanceToNow, parseISO } from 'date-fns';


// .replace(/^'|'$/g, '');

/**
 * Removes single quotes from the beginning and end of a string
 * @param {string} text - The text to format
 * @returns {string} - The formatted text without leading or trailing single quotes
 */
const formatText = (text) => {
    if (!text) return '';
    return text.trim()
        .replace(/^[‘’']|[‘’']$/g, '')  // Remove leading/trailing straight or curly apostrophes
        .replace(/\s+([,.])/g, '$1')     // Remove spaces before commas or periods
        .replace(/([,.])(\S)/g, '$1 $2')  // Ensure exactly one space after commas or periods
        .trim();
};



/**
 * Formats the date to a relative time string (e.g. "2 days ago")
 * @param {string} dateString - The date string to format
 * @returns {string} - The formatted relative time
 */
const formatRelativeDate = (timestamp) => {
  // Add validation to prevent invalid date errors
  if (!timestamp || isNaN(new Date(timestamp).getTime())) {
    return 'Invalid date';
  }
  
  try {
    return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Date unavailable';
  }
};

const isValidUrl = (urlString) => {
    try {
        return Boolean(urlString && urlString.startsWith('http') && new URL(urlString));
    } catch (e) {
        return false;
    }
};

const CompAIStoryBoardItem = ({ newsItem }) => {



/**
 * Formats the text of a news item, applying the formatText function to relevant fields
 * @param {Object} newsItem - The news item to format
 * @returns {Object} - A new news item object with formatted text fields
 */
const formatNewsItem = (newsItem) => {
    if (!newsItem) return {};
    
    return {
      ...newsItem,
      headline: formatText(newsItem.headline),
      summary: formatText(newsItem.summary),
      relevance: formatText(newsItem.relevance),
      impact: formatText(newsItem.impact),
      sales_takeaways: Array.isArray(newsItem.sales_takeaways) 
        ? newsItem.sales_takeaways.map(takeaway => formatText(takeaway))
        : []
    };
  };
  
  useEffect(() => {
      const formattedNewsItem = formatNewsItem(newsItem);
      //console.log(formattedNewsItem);
  }, [newsItem]);


    return (
        <div className="bg-black/20 rounded-md overflow-hidden flex flex-col">
            <div className="flex flex-col h-full">
                <div className="relative flex flex-col h-full p-6 ">
                    <div className="flex items-center justify-between w-full">
                        <div className="text-lg text-zinc-100 flex-1 pr-2 ">
                            {formatText(newsItem.headline)}
                        </div>
                        <div className='text-zinc-100 w-12 h-12 pl-6 text-sm flex-none'>
                            {newsItem.url ? (
                                <a
                                    href={newsItem.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className=""
                                    title="View source"
                                >
                                    <img
                                        src="/media/icon/noun-link-1721082-FFFFFF.svg"
                                        className="w-5 h-5"
                                        alt="External link"
                                    />
                                </a>
                            ) : (
                                <a
                                    href={'https://www.google.com/search?q=' + newsItem.headline}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className=""
                                    title="View source"
                                >
                                    <img
                                        src="/media/icon/noun-link-1721082-FFFFFF.svg"
                                        className="w-5 h-5"
                                        alt="External link"
                                    />
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="text-zinc-300 text-base print:text-sm pb-2 leading-8 pt-2 pr-4">
                        {formatText(newsItem.summary)}
                    </div>
                    {newsItem.relevance && newsItem.relevance.split(/\s+/).length > 150
                        ? ''
                        :
                        <div className="bg-sky-900/20 border-l-2 border-sky-400 w-1/3 flex-none text-sky-300 py-2 rounded-r-md text-md -ml-6 pl-6 mb-4 print:hidden mt-8">
                            Why Change
                        </div>
                    }
                    <div className="text-zinc-100 leading-6 ">

                        <div className="bg-green-900/20 flex-none text-green-300 px-3 py-2 rounded-md text-md text-left leading-6 -mx-3 mb-2 hidden print:block">
                            Why Change
                        </div>

                        {newsItem.relevance && newsItem.relevance.split(/\s+/).length > 150
                            ? ''
                            : formatText(newsItem.relevance)}
                    </div>

                    <div className="bg-green-900/20 border-l-2 border-green-400 w-1/3 flex-none text-green-300 p-2 rounded-r-md text-md -ml-6 pl-6 mb-4 print:hidden mt-8">
                        Why Us
                    </div>
                    <div className="text-zinc-100 leading-6">

                        <div className="bg-sky-900/20 flex-none text-sky-300 px-3 py-2 rounded-md text-md text-left leading-6 -mx-3 mb-2 hidden print:block">
                            Why Us
                        </div>
                        {newsItem.impact && newsItem.impact.split(/\s+/).length > 150
                            ? ''
                            : formatText(newsItem.impact)}

                        <ul className="list-disc pl-8 space-y-2 mt-4">
                            {newsItem.sales_takeaways?.map((takeaway, i) => (
                                <li key={i} className="text-zinc-200 marker:text-green-400 print:text-sm -ml-4">
                                    {takeaway}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>


                {newsItem.url ? (
                    <a
                        href={newsItem.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className=""
                        title="View source"
                    >
                        <div className='text-zinc-100 text-sm bottom-0 right-0 px-4 pb-2 ml-auto bg-black/10 hover:bg-black/30 rounded-b-lg p-2'>
                            
                            <div className="flex justify-between items-center">
                                <div className="text-left flex items-center gap-2 py-2">
                                    <div className="flex p-2 rounded-full bg-black/50">
                                    {newsItem.url && (
                                        <img 
                                            src={`https://www.google.com/s2/favicons?domain=${
                                                isValidUrl(newsItem.url) ? new URL(newsItem.url).hostname : ''
                                            }`}
                                            alt=""
                                            className="w-5 h-5"
                                            onError={(e) => {e.target.style.display = 'none'}}
                                        />
                                    )}
                                    </div>
                                    <div className="flex flex-col ml-2">
                                        <div className="text-zinc-300 text-xs">source</div> 
                                        <div className="text-zinc-300 text-sm">{newsItem.source}</div>
                                    </div>
                                </div>
                                    <div className="text-right">{newsItem.datePublished ? formatRelativeDate(newsItem.datePublished) : ''}</div>
                            </div>
                        </div>
                    </a>
                ) : (
                    <a
                        href={'https://www.google.com/search?q=' + newsItem.headline}
                        target="_blank"
                        rel="noopener noreferrer"
                        className=""
                        title="View source"
                    >
                        <div className='text-zinc-100 text-sm bottom-0 right-0 px-4 pb-2    ml-auto bg-black/20 hover:bg-black/30 rounded-b-lg p-2'>
                        <div className="flex justify-between items-center">
                                <div className="text-left flex items-center gap-2 py-2">
                                    <div className="flex p-2 rounded-full bg-black/20">
                                     
                                        <img 
                                            src='/util/app/b3-admin-google.png'
                                            alt=""
                                            className="w-5 h-5"
                                            onError={(e) => {e.target.style.display = 'none'}}
                                        />
                                    
                                    </div>
                                    <div className="flex flex-col ml-2">
                                        <div className="text-zinc-300 text-xs">source</div> 
                                        <div className="text-zinc-300 text-sm">Google Search</div>
                                    </div>
                                </div>
                                    <div className="text-right">{newsItem.datePublished ? formatRelativeDate(newsItem.datePublished) : ''}</div>
                            </div>
                        </div>
                    </a>
                )}
            </div>
        </div>
    );
};

export default CompAIStoryBoardItem; 