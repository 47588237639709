import React, { useEffect, useState, useRef } from 'react';
import { collection, query, where, orderBy, limit, getDocs, Timestamp, addDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const AccessCode = () => {
    const [inviteCodes, setInviteCodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        welcomeMessage: '',
        company: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [copiedCode, setCopiedCode] = useState(null);

    // Fetch invite codes from Firestore
    const fetchInviteCodes = async () => {
        setLoading(true);
        try {
            const inviteCodesRef = collection(db, 'invite-codes');
            const q = query(inviteCodesRef, orderBy('createdAt', 'desc'));
            const querySnapshot = await getDocs(q);
            
            const codes = [];
            querySnapshot.forEach((doc) => {
                codes.push({ id: doc.id, ...doc.data() });
            });
            
            setInviteCodes(codes);
        } catch (err) {
            console.error('Error fetching invite codes:', err);
            setError('Failed to load invite codes');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInviteCodes();
    }, []);

    // Generate 6-digit alpha-numeric code based on email and company
    const generateAccessCode = (email, company) => {
        // Create a string by combining email and company
        const combinedString = (email + company).toLowerCase();
        
        // Create a hash-like value from the combined string
        let hash = 0;
        for (let i = 0; i < combinedString.length; i++) {
            hash = ((hash << 5) - hash) + combinedString.charCodeAt(i);
            hash |= 0; // Convert to 32bit integer
        }
        
        // Convert to positive number and get last 6 characters
        const positiveHash = Math.abs(hash).toString();
        let code = '';
        
        // Use the hash to generate a 6-character alpha-numeric code
        const charset = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'; // Excluding similar looking characters
        for (let i = 0; i < 6; i++) {
            const index = (hash + i * positiveHash.charCodeAt(i % positiveHash.length)) % charset.length;
            code += charset[Math.abs(index)];
        }
        
        return code;
    };

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError('');
        setSuccess('');
        
        try {
            // Validate form
            if (!formData.name || !formData.email || !formData.company) {
                throw new Error('Name, email, and company are required');
            }
            
            // Generate access code
            const accessCode = generateAccessCode(formData.email, formData.company);
            
            // Add document to Firestore
            await addDoc(collection(db, 'invite-codes'), {
                name: formData.name,
                email: formData.email.toLowerCase(),
                welcomeMessage: formData.welcomeMessage,
                company: formData.company,
                code: accessCode,
                createdAt: Timestamp.now(),
                used: false
            });
            
            // Reset form
            setFormData({
                name: '',
                email: '',
                welcomeMessage: '',
                company: ''
            });
            
            // Show success message and refresh list
            setSuccess('Invite code created successfully!');
            fetchInviteCodes();
        } catch (err) {
            console.error('Error creating invite code:', err);
            setError(err.message || 'Failed to create invite code');
        } finally {
            setIsSubmitting(false);
        }
    };

    // Add function to delete an invite code
    const handleDeleteCode = async (id) => {
        if (window.confirm('Are you sure you want to delete this invite code?')) {
            try {
                const docRef = doc(db, 'invite-codes', id);
                await deleteDoc(docRef);
                setSuccess('Invite code deleted successfully!');
                fetchInviteCodes(); // Refresh the list
            } catch (err) {
                console.error('Error deleting invite code:', err);
                setError('Failed to delete invite code');
            }
        }
    };
    
    // Add function to copy invite link to clipboard
    const copyInviteLink = (code) => {
        const inviteLink = `https://storypath.ai/?invite=${code}`;
        navigator.clipboard.writeText(inviteLink)
            .then(() => {
                setCopiedCode(code);
                setTimeout(() => setCopiedCode(null), 2000); // Reset after 2 seconds
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
                setError('Failed to copy invite link to clipboard');
            });
    };

    const copyInviteLinkTest = (code) => {
        const inviteLink = `https://storypath-site-backend.web.app/?invite=${code}`;
        navigator.clipboard.writeText(inviteLink)
            .then(() => {
                setCopiedCode(code);
                setTimeout(() => setCopiedCode(null), 2000); // Reset after 2 seconds
            });
    };


    return (
        <div className="flex flex-col pb-48 pt-12 w-full mx-auto grid grid-cols-12 gap-4">
            
            
            {/* Create Invite Code Form */}
            <div className=" rounded-lg p-6 mb-8 col-span-4">
                <h2 className="text-xl font-semibold mb-4 text-white">Create New Invite Code</h2>
                
                {error && (
                    <div className="bg-red-900/50 border border-red-700 text-red-300 px-4 py-3 rounded mb-4">
                        {error}
                    </div>
                )}
                
                {success && (
                    <div className="bg-green-900/50 border border-green-700 text-green-300 px-4 py-3 rounded mb-4">
                        {success}
                    </div>
                )}
                
                <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="mb-4 md:col-span-2">
                        <label className="block text-zinc-300 text-sm font-medium mb-2" htmlFor="name">
                            Name*
                        </label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            value={formData.name}
                            onChange={handleChange}
                            className="bg-zinc-700 appearance-none border border-zinc-600 rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                            required
                        />
                    </div>
                    
                    <div className="mb-4 md:col-span-2">
                        <label className="block text-zinc-300 text-sm font-medium mb-2" htmlFor="email">
                            Email Address*
                        </label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="bg-zinc-700 appearance-none border border-zinc-600 rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                            required
                        />
                    </div>
                    
                    <div className="mb-4 md:col-span-2">
                        <label className="block text-zinc-300 text-sm font-medium mb-2" htmlFor="company">
                            Company*
                        </label>
                        <input
                            id="company"
                            name="company"
                            type="text"
                            value={formData.company}
                            onChange={handleChange}
                            className="bg-zinc-700 appearance-none border border-zinc-600 rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                            required
                        />
                    </div>
                    
                    <div className="mb-4 md:col-span-2">
                        <label className="block text-zinc-300 text-sm font-medium mb-2" htmlFor="welcomeMessage">
                            Welcome Message
                        </label>
                        <textarea
                            id="welcomeMessage"
                            name="welcomeMessage"
                            value={formData.welcomeMessage}
                            onChange={handleChange}
                            className="bg-zinc-700 appearance-none border border-zinc-600 rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                            rows="3"
                        />
                    </div>
                    
                    <div className="md:col-span-2">
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="bg-sky-500 hover:bg-sky-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-sky-400 min-w-[150px] transition-colors disabled:bg-sky-800 disabled:text-sky-200"
                        >
                            {isSubmitting ? 'Creating...' : 'Create Invite Code'}
                        </button>
                    </div>
                </form>
            </div>
            
            {/* Invite Codes Table */}
            <div className="bg-zinc-800   rounded-lg overflow-hidden border border-zinc-700 col-span-8">
                <h2 className="text-xl font-semibold p-6  text-white">Existing Invite Codes</h2>
                
                <div className="overflow-x-auto">
                    <div className="min-w-full divide-y divide-zinc-700">
                        {/* Header */}
                        <div className="bg-zinc-900">
                            <div className="grid grid-cols-6 md:grid-cols-6">
                                <div className="px-6 py-3 text-left text-xs font-medium text-zinc-400 uppercase tracking-wider">Code</div>
                                <div className="px-6 py-3 text-left text-xs font-medium text-zinc-400 uppercase tracking-wider">Name</div>
                                <div className="px-6 py-3 text-left text-xs font-medium text-zinc-400 uppercase tracking-wider hidden md:block">Email</div>
                                <div className="px-6 py-3 text-left text-xs font-medium text-zinc-400 uppercase tracking-wider hidden md:block">Company</div>
                                <div className="px-6 py-3 text-left text-xs font-medium text-zinc-400 uppercase tracking-wider hidden md:block">Created</div>
                                <div className="px-6 py-3 text-left text-xs font-medium text-zinc-400 uppercase tracking-wider">Status</div>
                            </div>
                        </div>
                        
                        {/* Body */}
                        <div className="bg-zinc-800 divide-y divide-zinc-700">
                            {loading ? (
                                Array(3).fill().map((_, index) => (
                                    <div key={index} className="px-6 py-4">
                                        <SkeletonTheme baseColor="#3f3f46" highlightColor="#52525b">
                                            <Skeleton height={20} />
                                        </SkeletonTheme>
                                    </div>
                                ))
                            ) : inviteCodes.length === 0 ? (
                                <div className="px-6 py-4 text-center text-sm text-zinc-400">
                                    No invite codes found
                                </div>
                            ) : (
                                inviteCodes.map((code) => (
                                    <div key={code.id} className="group hover:bg-zinc-700 transition-colors">
                                        {/* Code info row */}
                                        <div className="grid grid-cols-6 md:grid-cols-6">
                                            <div className="px-6 pt-4 pb-2 whitespace-nowrap font-mono text-sm font-medium text-sky-400">
                                                {code.code}
                                            </div>
                                            <div className="px-6 pt-4 pb-2 whitespace-nowrap text-sm text-zinc-200">
                                                {code.name}
                                            </div>
                                            <div className="px-6 pt-4 pb-2 whitespace-nowrap text-sm text-zinc-300 hidden md:block">
                                                {code.email}
                                            </div>
                                            <div className="px-6 pt-4 pb-2 whitespace-nowrap text-sm text-zinc-300 hidden md:block">
                                                {code.company}
                                            </div>
                                            <div className="px-6 pt-4 pb-2 whitespace-nowrap text-sm text-zinc-300 hidden md:block">
                                                {code.createdAt?.toDate().toLocaleDateString() || 'N/A'}
                                            </div>
                                            <div className="px-6 pt-4 pb-2 whitespace-nowrap">
                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                    code.used 
                                                        ? 'bg-red-900/60 text-red-200 border border-red-700' 
                                                        : 'bg-green-900/60 text-green-200 border border-green-700'
                                                }`}>
                                                    {code.used ? 'Used' : 'Available'}
                                                </span>
                                            </div>
                                        </div>
                                        
                                        {/* Mobile only data rows */}
                                        <div className="md:hidden px-6 py-2 grid grid-cols-1 gap-2">
                                            <div className="text-sm text-zinc-400">
                                                <span className="font-medium">Email:</span> {code.email}
                                            </div>
                                            <div className="text-sm text-zinc-400">
                                                <span className="font-medium">Company:</span> {code.company}
                                            </div>
                                            <div className="text-sm text-zinc-400">
                                                <span className="font-medium">Created:</span> {code.createdAt?.toDate().toLocaleDateString() || 'N/A'}
                                            </div>
                                        </div>
                                        
                                        {/* Actions row - now part of the same hoverable group */}
                                        <div className="flex flex-wrap gap-2 justify-start px-6 py-3 ">
                                            <button
                                                onClick={() => copyInviteLink(code.code)}
                                                className="text-sky-400 hover:text-sky-300 bg-sky-900/30 hover:bg-sky-800/50 px-3 py-2 rounded-md transition-colors text-xs flex items-center min-h-[44px]"
                                            >
                                                {copiedCode === code.code ? (
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                        </svg>
                                                        Copied!
                                                    </>
                                                ) : (
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                                        </svg>
                                                        Copy Production Link
                                                    </>
                                                )}
                                            </button>
                                            <button
                                                onClick={() => copyInviteLinkTest(code.code)}
                                                className="text-sky-400 hover:text-sky-300 bg-sky-900/30 hover:bg-sky-800/50 px-3 py-2 rounded-md transition-colors text-xs flex items-center min-h-[44px]"
                                            >
                                                {copiedCode === code.code ? (
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                        </svg>
                                                        Copied!
                                                    </>
                                                ) : (
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                                        </svg>
                                                        Copy TEST Link
                                                    </>
                                                )}
                                            </button>
                                            <button
                                                onClick={() => handleDeleteCode(code.id)}
                                                className="text-red-400 hover:text-red-300 bg-red-900/30 hover:bg-red-800/50 px-3 py-2 rounded-md transition-colors text-xs flex items-center min-h-[44px]"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                </svg>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessCode; 