import React, { useMemo } from 'react';
import { format, isToday, isYesterday, isSameDay, subDays } from 'date-fns';
import { Card, CardHeader, Typography } from '@material-tailwind/react';
import { Square3Stack3DIcon } from '@heroicons/react/24/outline';

const CompInsightsDashboard = ({ shares, analytics, stories }) => {
    const processedData = useMemo(() => {
        if (!shares || !analytics || !stories) return [];

        console.log('shares', shares);
        console.log('stories', stories);

        const shareStats = shares.reduce((acc, share) => {
            if (share && share.name) {
                const matchingStory = stories.find(stories => stories.id === share.storyId);
                acc[share.name] = {
                    name: share.name,
                    storyID: share.storyId,
                    imageStoryLogo: share.imageStoryLogo,
                    previewThumb: matchingStory ? matchingStory.previewThumb : null,
                    scenesViewed: 0,
                    timeWatched: 0,
                    uniqueViewers: new Set()
                };
            }
            return acc;
        }, {});

        analytics.forEach(item => {
            if (item && item.shareCode) {
                const shareName = shares.find(s => s.shareFriendlyURL === item.shareCode)?.name;
                if (shareName && shareStats[shareName]) {
                    shareStats[shareName].scenesViewed += 1;
                    shareStats[shareName].timeWatched += item.engagementTime || 0;
                    if (item.userAnon) shareStats[shareName].uniqueViewers.add(item.userAnon);
                }
            }
        });

        return Object.values(shareStats).map(stat => ({
            ...stat,
            uniqueViewers: stat.uniqueViewers.size
        }));
    }, [shares, analytics, stories]);

    const topShares = useMemo(() => {
        return processedData
            .sort((a, b) =>
                b.uniqueViewers - a.uniqueViewers ||
                b.timeWatched - a.timeWatched ||
                b.scenesViewed - a.scenesViewed
            )
            .slice(0, 5);
    }, [processedData]);

    const recentViewers = useMemo(() => {
        if (!analytics || !shares) return [];

        return analytics
            .filter(item => item && item.createdAt && item.userAnon)
            .sort((a, b) => (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0))
            .filter((item, index, self) =>
                index === self.findIndex(t => t.userAnon === item.userAnon)
            )
            .slice(0, 50)
            .map(item => {
                const shareName = shares.find(s => s.shareFriendlyURL === item.shareCode)?.name || 'Unknown';
                const userStats = analytics.filter(a => a.userAnon === item.userAnon);
                return {
                    shareName,
                    userAnon: item.userAnon,
                    uniqueViews: userStats.length,
                    totalTimeViewed: userStats.reduce((sum, a) => sum + (a.engagementTime || 0), 0),
                    totalScenesViewed: new Set(userStats.map(a => a.sceneId).filter(Boolean)).size,
                    createdAt: new Date(item.createdAt.seconds * 1000)
                };
            });
    }, [analytics, shares]);

    const shareEngagement = useMemo(() => {
        const engagement = {};
        recentViewers.forEach(viewer => {
            engagement[viewer.shareName] = (engagement[viewer.shareName] || 0) + viewer.uniqueViews;
        });
        return engagement;
    }, [recentViewers]);

    const maxEngagement = Math.max(...Object.values(shareEngagement), 1);

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${hours}h ${minutes}m`; // ${remainingSeconds}s`;
    };

    const groupedViewers = useMemo(() => {
        const groups = {};
        recentViewers.forEach(viewer => {
            const date = viewer.createdAt;
            const dateString = format(date, 'yyyy-MM-dd');
            if (!groups[dateString]) {
                groups[dateString] = [];
            }
            groups[dateString].push(viewer);
        });
        return groups;
    }, [recentViewers]);

    const formatDateHeader = (date) => {
        if (isToday(date)) {
            return 'Today';
        } else if (isYesterday(date)) {
            return 'Yesterday';
        } else if (isSameDay(date, subDays(new Date(), 2))) {
            return '2 days ago';
        } else {
            return format(date, 'EEEE, MMM d');
        }
    };


    /* 
    {
        "sceneName": "Ask",
        "engagementTime": 0,
        "storyId": "jx8j9nEF9lNvnK3WVTle",
        "engagementTags": [],
        "storyName": "Brevit3 Investor Sample",
        "userAccount": "",
        "windowHeight": 911,
        "scenePriorIndex": 9,
        "createdAt": {
            "seconds": 1726505676,
            "nanoseconds": 260000000
        },
        "scenePriorId": "tb2zRcDUo1yvdTOypo0H",
        "windowWidth": 1920,
        "userAgent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36",
        "sceneId": "82HLJPNZ4skOZpE8bJbg",
        "sceneIndex": 10,
        "userAnon": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36|en-US|1920x1080|420",
        "shareCode": "wd_sample_demo",
        "id": "lo2EFy8iwBa1ERzgP9UW"
    }
    */




    return (
        <div className="w-full h-fit text-zinc-300 bg-black/0 rounded-md">

            {/* <h2 className="text-xl mb-4">Top Shares</h2> */}

            {/* <Card className="bg-black/0 border-0 shadow-none">
                <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="flex flex-col   rounded-none md:flex-row md:items-center"
                >
                    <div className="w-max rounded-lg p-5 text-white">
                        <Square3Stack3DIcon className="h-6 w-6" />
                    </div>
                    <div>
                        <Typography variant="h6" className="text-zinc-200">
                            Top Shares
                        </Typography>
                    </div>
                </CardHeader>
            </Card> */}

            <div className="space-y-2 p-4">
                {topShares.map((share, index) => (
                    <div key={index} className="   bg-black/20 rounded-md p-4 ">
                        <div className="flex-shrink-0  ">
                            <div className="text-lg text-zinc-50">{share.name}</div>

                        </div>
                        <div className='w-full'>
                            <div className='grid grid-cols-4 gap-6 mt-4'>
                                <div className="h-14 aspect-video mb-2 mr-4 bg-sky-300 rounded   ">
                                    {share.previewThumb && (
                                        <>
                                            <img
                                                src={share.previewThumb}
                                                alt={`${share.name} preview`}
                                                className="w-full h-full object-cover rounded"
                                            />
                                        </>
                                    )}
                                </div>
                                <div><span className='text-xl text-zinc-50'>{share.uniqueViewers}</span><br /> <span className='text-xs text-zinc-400'>Unique Viewers </span></div>
                                <div><span className='text-xl text-zinc-50'>{formatTime(share.timeWatched)}</span><br /> <span className='text-xs text-zinc-400'>Total Watched </span></div>
                                <div><span className='text-xl text-zinc-50'>{share.scenesViewed}</span><br /> <span className='text-xs text-zinc-400'>Scenes Viewed </span></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default CompInsightsDashboard;