import React, { Fragment, useState, useEffect, createContext, useContext } from 'react'
import { Dialog, Transition, Popover } from '@headlessui/react'
import {
    Bars3Icon,
    CalendarIcon,
    ChartPieIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/outline'
import { UserAuth } from '../context/AuthContext'
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom'
import '/Style.css'

import { motion } from 'framer-motion'
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react"

import { db } from '../firebase'
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    orderBy,
    doc,
    where,
    addDoc,
    deleteDoc,
    serverTimestamp,
    getDoc
} from 'firebase/firestore'

import CompChat from './CompChat'
import Confetti from 'react-confetti'
import { CompHSChat } from './CompHSChat'

import Intercom from '@intercom/messenger-js-sdk';
import { logCustomEvent } from './UtilAnalytics'


// Create a context for navigation
const NavigationContext = createContext();

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// Move tabs definition outside the component to prevent recreation
const tabs = [
    {
        value: "path-type",
        label: "Home",
        desc: "Updates Content",
        link: "/app/home",
        icon: (
            <img src="/util/app/b3-admin-active-5.svg" className="w-5 h-5 " />
        )
    },
    {
        value: "path-Create",
        label: "Create Story",
        desc: "Create Content",
        link: "/app/storyboard",
        icon: (
            <img src="/util/app/b3-admin-add-2.svg" className="w-5 h-5 " />
        )
    },
    {
        value: "path-Stories",
        label: "Story Library",
        desc: "Stories Content",
        link: "/app/stories",
        icon: (
            <img src="/util/app/b3-admin-story-5.svg" className="h-7 " />
        )
    },

    {
        value: "path-create",
        label: "Sharing",
        desc: "Sharing Content",
        link: "/app/share",
        icon: (
            <img src="/util/app/b3-admin-send2.svg" className="w-5 h-5 " alt="Slack" />
        )
    },
    {
        value: "path-storyboard",
        label: "Engagement Insights",
        desc: "Analytics Content",
        link: "/app/insights",
        icon: (
            <img src="/util/app/b3-admin-analytics-11.svg" className="w-6 h-6" />
        )
    },
    {
        value: "path-connect",
        label: "Connect",
        desc: "Connect Content",
        link: "/app/storyboard",
        type: "connect",
        icon: (
            <img src="/util/app/Slack_icon_2019.svg" className="w-5 h-5" alt="Slack" />
        )
    },
    {
        value: "path-share",
        label: "Connect",
        desc: "Connect Content",
        link: "/app/share",
        type: "connect",
        icon: (
            <img src="/util/app/logo-box.svg" className="w-5 h-5" alt="Slack" />
        )
    },
];

// Memoize the entire header section
const Header = React.memo(function Header() {
    const { user, logOut } = UserAuth() //user?.email == 'john.brunswick@gmail.com' ?
    const [selectedNavigationIndex, setSelectedNavigationIndex] = useState(0)
    const [confettiShown, setConfettiShown] = useState(1)

    const [storypathType, setStorypathType] = useState('blank')

    const navigate = useNavigate();
    //const { googleSignIn, user } = UserAuth();

    const handleSignOut = async () => {
        try {
            await logOut().then(() => navigate('/signin'));
            //user = null
        } catch (error) {
            console.log(error)
        }
    }
    return (
        // <div className="fixed top-0 right-0  pt-2 z-50 left-0 w-full bg-[#121216]">
        <div className="fixed top-0 right-0  pt-2 z-50 left-0 w-full bg-[#1C1C22]">
            {/* <div className="fixed top-0 right-0 pt-2 z-50 left-0 w-full bg-black/20 [background-image:_url('/util/noise.png')]_[background-size:_100px_100px] [background-repeat:_repeat]"> */}
            {/* <img src="/util/app/pulse-placeholder-1.png" className="h-12 inline mr-4" /> */}
            {/* <div className=" text-center bg-zinc-950 border-b border-zinc-800 text-zinc-400 text-sm h-8">
                hello
            </div> */}
            <div className="flex items-center space-x-4 py-6">

                {/* <img src="/util/app/sp-demo7.svg" className="h-12 inline mr-4"  />   */}

                <div className="text-xl ml-16 text-zinc-100 ml-12 mx-auto flex items-center">
                    {/* <div className="h-12 w-12 inline-block mr-4 bg-zinc-800 rounded-lg"/>
                    <div className="inline-block">StoryPath</div> */}
                    <Link to="/app/home">
                        <img src="/util/app/sp-test-logo-2.png" className="h-10 inline mr-4" />
                    </Link>
                </div>

                <div

                //onClick={() => NavagationSelection(6)}

                >
                    <div className={classNames(
                        selectedNavigationIndex == 6
                            ? 'bg-gray-700 text-zinc-100'
                            : 'text-zinc-300 hover:text-white ',
                        'flex items-center gap-x-2 px-2 mr-8 rounded-md'
                    )}>
                        <div className="hidden md:block text-right mr-4 mt-1">
                            <div className="text-zinc-50">{user?.displayName}</div>
                            <div className="text-xs text-zinc-500">{user?.email || ''}</div>
                        </div>

                        <img
                            className="h-12 w-12 rounded-full border-2 border-zinc-700 bg-gray-800"
                            src={user?.photoURL}
                            referrerPolicy="no-referrer"
                            alt=""
                        />
                        <Popover className="relative">
                            <Popover.Button>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-6 h-6 mt-1 text-zinc-400 hover:text-zinc-200"
                                >
                                    <path d="M12 8.25a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 5.25a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 5.25a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                                </svg>
                            </Popover.Button>

                            <Popover.Panel className="absolute right-2 z-10 mt-4 w-48 origin-top-right">
                                <motion.div
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 10 }}
                                    transition={{ duration: 0.2 }}
                                    className="rounded-md bg-zinc-800  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-2"
                                >
                                    <Popover.Button as={Link} to="/app/account" className="block px-4 py-2 text-sm text-zinc-300 hover:bg-black/20 rounded-md">
                                        Account
                                    </Popover.Button>
                                    <button
                                        onClick={handleSignOut}
                                        className="block w-full text-left px-4 py-2 text-sm text-zinc-300 hover:bg-black/20 rounded-md"
                                    >
                                        Sign Out
                                    </button>
                                </motion.div>
                            </Popover.Panel>
                        </Popover>
                    </div>


                </div>
                {/* <button
                    onClick={handleSignOut}
                    className="p-2 rounded-lg hover:bg-gray-700"
                >
                    <img
                        src="/media/icon/noun-logout-3750552-FFFFFF.svg"
                        className="h-5 w-5"
                        aria-hidden="true"
                    />
                </button> */}
            </div>
            <div className="   border-b border-zinc-800 text-zinc-400 text-sm">
                <AnimatedTabs />
            </div>

        </div>
    );
});

// Update navAllStories to use a custom hook
export const useNavigation = () => {
    const handleTabClick = useContext(NavigationContext);

    const navAllStories = () => {
        if (handleTabClick) {
            handleTabClick(tabs[2]);
        } else {
            // Fallback to direct navigation if context is not available
            window.location.href = tabs[2].link;
            document.title = tabs[2].label;
        }
    };

    return { navAllStories };
};

// Memoize the AnimatedTabs
const AnimatedTabs = React.memo(function AnimatedTabs() {
    const navigate = useNavigate();
    const location = useLocation();
    const [showNotification, setShowNotification] = useState(false);

    const handleTabClick = React.useCallback((tab) => {
        logCustomEvent('nav_tab_click_' + tab.value, { tab: tab.value });
        if (tab.value === "path-type") {
            setShowNotification(false);
        }
        document.title = tab.label;
        navigate(tab.link, { replace: true });
    }, [navigate]);

    const activeTab = React.useMemo(() => {
        const currentPath = location.pathname.split('/').pop();
        return tabs.find(tab => tab.link.includes(currentPath))?.value || tabs[0].value;
    }, [location.pathname]);

    const showConnect = location.pathname.includes('/app/storyboard') ||
        location.pathname.includes('/app/share');

    useEffect(() => {
        // Listen for the AI message event
        const timer = setTimeout(() => {
            setShowNotification(true);
        }, 4000); // Same timing as the AI message

        return () => clearTimeout(timer);
    }, []);

    return (
        <NavigationContext.Provider value={handleTabClick}>
            <Tabs value={activeTab}>
                <TabsHeader
                    className="rounded-none border-b-0 bg-black/0 pl-16 -mt-4 flex justify-between pb-0 mb-0"
                    indicatorProps={{
                        className: "bg-transparent border-b-4 border-white/50 shadow-none rounded-none",
                    }}
                >
                    <div className="flex">
                        {tabs.map((tab) => (
                            // Only render non-connect tabs here
                            tab.type !== "connect" && (
                                <Tab
                                    key={tab.value}
                                    value={tab.value}
                                    onClick={() => handleTabClick(tab)}
                                    className={`pr-8 py-4 text-base w-fit mr-4 transition-colors duration-200 relative
                                        ${activeTab === tab.value
                                            ? "text-zinc-50"
                                            : "text-zinc-400 hover:text-zinc-200 "}`}
                                >
                                    <div className="flex items-center gap-4">
                                        <div className={`transition-opacity duration-200 ${activeTab === tab.value ? "opacity-100" : "opacity-50"
                                            }`}>
                                            {tab.icon}
                                        </div>
                                        {tab.label}
                                    </div>
                                </Tab>
                            )
                        ))}
                    </div>

                    {/* Modified Connect tab section */}
                    {/* {showConnect && (
                        <div className="mr-6">
                            <motion.div
                                initial={{ x: 50, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.3, ease: "easeOut" }}
                            >
                                <Tab
                                    value="connect-tab"
                                    className="px-4 py-2   mt-4 rounded-lg text-base w-fit text-sm transition-colors duration-200 text-zinc-400 hover:text-zinc-200     mr-4"
                                >
                                    <div className="flex items-center gap-2">
                                        {tabs.some(tab =>
                                            tab.type === "connect" &&
                                            location.pathname.includes(tab.link)
                                        ) && "Connect"}

                                        {tabs.map((tab) => (
                                            tab.type === "connect" &&
                                            location.pathname.includes(tab.link) && (
                                                <div
                                                    key={tab.value}
                                                    onClick={() => handleTabClick(tab)}
                                                    className="cursor-pointer"
                                                >
                                                    {React.cloneElement(tab.icon, {
                                                        className: "w-4 h-4 object-contain"
                                                    })}
                                                </div>
                                            )
                                        ))}
                                    </div> 
                                 </Tab> 
                            </motion.div>
                        </div>
                    )} */}
                </TabsHeader>
            </Tabs>
        </NavigationContext.Provider>
    );
});

// Memoize the content section to prevent re-renders
// const Content = React.memo(function Content() {
//     return (
//         <div className="pt-[200px] px-16 overflow-y-auto">
//             <Outlet />
//         </div>
//     );
// });

function Content() {
    return (
        <div className="pt-[200px] px-16 overflow-y-auto">
            <Outlet />
        </div>
    );
}

// Add this new component before AdminNavV2
// const ChatBubble = React.memo(function ChatBubble() {
//     const [isOpen, setIsOpen] = useState(false)

//     return (
//         <>
//             {/* Chat Bubble Button */}
//             <motion.div
//                 className="fixed bottom-8 right-8 z-50"
//                 whileHover={{ scale: 1.05 }}
//                 whileTap={{ scale: 0.95 }}
//             >
//                 <button
//                     onClick={() => setIsOpen(true)}
//                     className="flex items-center gap-2 bg-zinc-900 text-white px-4 py-4 rounded-full shadow-lg hover:bg-zinc-600 cursor-pointer"
//                 >
//                     <ChatBubbleLeftRightIcon className="h-8 w-8" />
//                     {/* <span>Help or Question</span> */}
//                 </button>
//             </motion.div>

//             {/* Chat Modal */}
//             <Transition show={isOpen} as={Fragment}>
//                 <Dialog
//                     as="div"
//                     className="relative z-50"
//                     onClose={() => setIsOpen(false)}
//                 >
//                     <Transition.Child
//                         as={Fragment}
//                         enter="ease-out duration-300"
//                         enterFrom="opacity-0"
//                         enterTo="opacity-100"
//                         leave="ease-in duration-200"
//                         leaveFrom="opacity-100"
//                         leaveTo="opacity-0"
//                     >
//                         <div className="fixed inset-0 bg-black/25" />
//                     </Transition.Child>

//                     <div className="fixed inset-0">
//                         <div className="absolute bottom-0 right-0 p-8">
//                             <Transition.Child
//                                 as={Fragment}
//                                 enter="ease-out duration-300"
//                                 enterFrom="translate-y-full opacity-0"
//                                 enterTo="translate-y-0 opacity-100"
//                                 leave="ease-in duration-200"
//                                 leaveFrom="translate-y-0 opacity-100"
//                                 leaveTo="translate-y-full opacity-0"
//                             >
//                                 <Dialog.Panel className="w-[400px] transform overflow-hidden rounded-xl bg-white shadow-xl transition-all">
//                                     <div className="relative">
//                                         <button
//                                             onClick={() => setIsOpen(false)}
//                                             className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
//                                         >
//                                             <XMarkIcon className="h-5 w-5" />
//                                         </button>
//                                         <div className="h-[600px]">
//                                             Help chat to Slack here!
//                                         </div>
//                                     </div>
//                                 </Dialog.Panel>
//                             </Transition.Child>
//                         </div>
//                     </div>
//                 </Dialog>
//             </Transition>
//         </>
//     )
// })

const ChatBubble = React.memo(function ChatBubble() {
    const { user } = UserAuth()
    const location = useLocation(); // This will update whenever the URL changes

    // Define paths where the chat bubble should not appear
    const excludedPaths = [
        /\/app\/stories\/[^/]+\/edit\//,  // Your existing pattern
        // Add more patterns as needed
        ///\/app\/some-other-path\//,
        ///\/app\/another-path\//
    ];

    // Check if current path matches any excluded pattern
    const shouldHide = excludedPaths.some(pattern => pattern.test(location.pathname));

    useEffect(() => {
        //console.log('shouldHide', shouldHide)
        // Only initialize Intercom if we're showing the chat bubble
        if (!shouldHide) {
            Intercom({
                app_id: 'g2h9i6in',
                user_id: user?.uid,
                name: user?.displayName,
                email: user?.email,
                created_at: user?.metadata?.createdAt,
            });
        }
    }, [user, shouldHide, location.pathname]);

    // Don't render anything if we're on an excluded path
    if (shouldHide) {
        return null;
    }

    return (
        <>
            {/* Your chat bubble content here */}
        </>
    );
});

// Add this new component for the welcome modal
const WelcomeModal = React.memo(function WelcomeModal({ isOpen, setIsOpen }) {
    const { user } = UserAuth();
    const [dontShowAgain, setDontShowAgain] = useState(true);

    const handleClose = async () => {
        if (dontShowAgain && user?.uid) {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                await updateDoc(userDocRef, {
                    "onboarding.brevit3-upgrade": true
                });
                //console.log("User preference saved");
            } catch (error) {
                console.error("Error updating user preferences:", error);
            }
        }
        setIsOpen(false);
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-2/3 transform overflow-hidden rounded-2xl  bg-zinc-900  p-12 text-left align-middle  transition-all">
                                {/* <Dialog.Title
                                    as="h3"
                                    className="text-2xl font-bold leading-6 text-white mb-4 flex items-center"
                                >
                                    

                                </Dialog.Title> */}

                                <div className="mt-4 text-zinc-300 text-lg col-span-2">
                                    <div className="px-8">
                                        <img src="/util/app/sp-test-logo-2.png" className="h-16 mr-3" alt="StoryPath Logo" />
                                        <div className='text-3xl font-semibold leading-6 text-orange-300 mb-4 mt-12  items-center'>
                                        Exciting News - Brevit3 is now StoryPath.ai
                                        <p className="mb-12 pt-4 text-zinc-400 text-lg font-normal">We've enhanced our platform with intelligent storytelling capabilities to help you brainstorm and create engaging stories for your solutions.  All of your existing content and settings have been preserved in this transition.</p>
                                    </div>
                                    </div>



                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-6">
                                        <div className="bg-zinc-800 p-8 rounded-lg">
                                            <h4 className="text-zinc-50   mb-2 flex items-center text-xl">
                                                <img src="/util/app/b3-admin-story-5.svg" className="h-10 mr-2 -ml-1" />
                                                Storytelling Deep Research
                                            </h4>
                                            <p className="text-base text-zinc-400  mt-4">Let's us help with the heavy lifting and generate research, insights, strategies and stories based on your needs.</p>
                                        </div>

                                        <div className="bg-zinc-800 p-8 rounded-lg">
                                            <h4 className="text-zinc-50  mb-2 flex items-center text-xl">
                                                <img src="/util/app/b3-admin-context-analysis.svg" className="h-10 mr-2 -ml-1" />
                                                Contextual Analysis
                                            </h4>
                                            <p className="text-base text-zinc-400  mt-4">
                                                Our intelligence is focused on providing relevant insights and suggestions for your storytelling based on your direction and input.
                                            </p>
                                        </div>

                                        <div className="bg-zinc-800 p-8 rounded-lg">
                                            <h4 className="text-zinc-50   mb-2 flex items-center text-xl">
                                                <img src="/util/app/b3-admin-story-settings.svg" className="h-8 mr-3 " />
                                                Customizable Tone &amp; Style
                                            </h4>
                                            <p className="text-base text-zinc-400 mt-4">Your stories will be created based on tone and style parameters you set, making them easy to create and understand.</p>
                                        </div>

                                        <div className="bg-zinc-800 p-8 rounded-lg">
                                            <h4 className="text-zinc-50  mb-2 flex items-center text-xl">
                                                <img src="/util/app/b3-story-bite.svg" className="h-10 mr-2 -ml-1" />
                                                Story Bites Generation
                                            </h4>
                                            <p className="text-base text-zinc-400 mt-4">One click generation of bite-sized content to highlight the value of the business transform your solutions provides to customers.</p>
                                        </div>

                                        <div className="  p-8 rounded-lg col-span-2">
                                            <h4 className="text-zinc-50  mb-2 flex items-center text-xl">
                                                <img src="/util/app/b3-admin-support-chat.png" className="h-8 mr-3" />
                                                Questions, Feedback and Support
                                            </h4>
                                            <p className="text-base text-zinc-400 mt-4">We're here to help.  Send us your questions, feedback and support requests by clicking the chat icon in the bottom right corner.</p>
                                        </div>
                                    </div>

                                    {/* <p className="text-base text-zinc-400 italic">All your existing content and settings have been preserved in this transition.</p> */}
                                </div>

                                <div className="mt-12 flex flex-col sm:flex-row items-center justify-between">
                                    <label className="flex items-center text-zinc-300 mb-4 sm:mb-0 ml-8">
                                        <input
                                            type="checkbox"
                                            checked={dontShowAgain}
                                            onChange={() => setDontShowAgain(!dontShowAgain)}
                                            className="mr-2 h-4 w-4 rounded border-gray-300 text-sky-500 focus:ring-sky-500"
                                        />
                                        Don't show again
                                    </label>

                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-sky-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-sky-400 "
                                        onClick={handleClose}
                                    >
                                        Get Started with StoryPath
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
});

// Main component
export default function AdminNavV2() {
    const location = useLocation();
    const { user } = UserAuth();
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);

    // Check if the current path matches the pattern we want to exclude
    const isEditPage = /\/app\/stories\/[^/]+\/edit\//.test(location.pathname);

    useEffect(() => {
        const checkUserCreationDate = async () => {
            if (user?.uid) {
                try {
                    // Check if user was created before March 1st, 2025
                    const userCreationTime = new Date(parseInt(user.metadata.createdAt));
                    const cutoffDate = new Date('2025-03-01');

                    if (userCreationTime < cutoffDate) {
                        // Check if user has already seen the modal
                        const userDocRef = doc(db, 'users', user.uid);
                        const userDoc = await getDoc(userDocRef);

                        if (!userDoc.exists() ||
                            !userDoc.data().onboarding ||
                            !userDoc.data().onboarding['brevit3-upgrade']) {
                            setShowWelcomeModal(true);
                        }
                    }
                } catch (error) {
                    console.error("Error checking user creation date:", error);
                }
            }
        };

        checkUserCreationDate();
    }, [user]);

    return (
        <div className="">
            <Header />





            {/* <div className="pt-4   mt-12  ">
                <AnimatedTabs />
            </div> */}

            <Content />
            {!isEditPage && <ChatBubble />}
            <WelcomeModal isOpen={showWelcomeModal} setIsOpen={setShowWelcomeModal} />
        </div>
    );
}