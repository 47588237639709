import React, { useRef, useState, useContext, Fragment, useEffect, Suspense, CSSProperties } from "react";
import { analytics, db } from '../firebase'
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    orderBy,
    doc,
    where,
    addDoc,
    deleteDoc, serverTimestamp
} from 'firebase/firestore'
import { UserAuth } from '../context/AuthContext';
import { Dialog, Transition, Listbox, Menu } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link, Outlet, Navigate, useNavigate } from 'react-router-dom'
import { RadioGroup } from '@headlessui/react'
import BarLoader from "react-spinners/BarLoader";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { ToastContainer, toast } from 'react-toastify';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import hotkeys from 'hotkeys-js';

//import { useNotificationCenter } from "react-toastify/addons/useNotificationCenter"
import 'react-toastify/dist/ReactToastify.css';
import { ArcDiagram } from '../analytics/ArcDiagram'
import { LineChart, Line, Pie, PieChart, XAxis, YAxis, CartesianGrid, BarChart, Bar, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { motion } from "framer-motion";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const Share = (props) => {
    const { user, logOut } = UserAuth();

    let [loading, setLoading] = useState(true)
    let [color, setColor] = useState("#0ca5e9")

    //const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    // Advanced
    const cancelButtonRef = useRef(null)
    const [stories, setStories] = useState([])
    const [shares, setShares] = useState([])

    const [shareName, setShareName] = useState('')
    const [shareDescription, setShareDescription] = useState('')
    const [shareStory, setShareStory] = useState('')
    const [shareURL, setShareURL] = useState('')
    const [shareNameError, setShareNameError] = useState(false);
    const [shareURLError, setShareURLError] = useState(false);
    const [shareStoryError, setShareStoryError] = useState(false);
    const [shareFriendlyURL, setShareFriendlyURL] = useState('')

    const [sharesLoaded, setSharesLoaded] = useState(false)
    const [storiesLoaded, setStoriesLoaded] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const baseColor = '#1C1C22';
    const highlightColor = '#2d2d2d';


    // useEffect(() => {

    // }, [shareStory]


    const createShare = async () => {

        let isValid = true;

        if (shareName === '') {
            setShareNameError(true);
            isValid = false;
        } else {
            setShareNameError(false);
        }

        if (shareStory === '') {
            setShareStoryError(true);
            isValid = false;
        } else {
            setShareStoryError(false);
        }

        if (shareURL === '') {
            setShareURLError(true);
            isValid = false;
        } else {
            setShareURLError(false);
        }

        if (!isValid) {
            return;
        }


        // Grab the user ID...
        let userOwnerKey = user?.uid

        const docRef = await addDoc(collection(db, 'shares'), {
            name: shareName,
            description: shareDescription,
            userPhotoURL: user?.photoURL,
            userEmail: user?.email,
            userDisplayName: user?.displayName,
            messageUserId: user?.uid,
            inviteTitle: '',
            inviteMessage: '',
            inviteAddresses: [
                'mike@brevit3.com', 'olive@brevit3.com'
            ],
            storyName: shareStory.name,
            storyId: shareStory.id,
            shareFriendlyURL: shareURL,
            createdAt: serverTimestamp(),
            lastModified: serverTimestamp(),
            //analyticsViewTotal: 0,
            analyticsLastViewed: serverTimestamp(),
            imageStoryBackground: shareStory.imageStoryBackground, // '/media/icon/B3-Image-Placeholder.png', // TODO - add default image
            imageStoryLogo: shareStory.imageStoryLogo, //'/media/icon/B3-Image-Placeholder.png', // TODO - add default image
            role: {
                //[userOwnerKey]: 'owner',
                owner: [userOwnerKey], //t8X5CAmys8W88z5rOQWEQaIWhHn2
                commenter: [userOwnerKey],
                writer: [userOwnerKey],
                reader: [userOwnerKey],
            },
        });

        // Reset form fields
        setShareName('');
        setShareDescription('');
        setShareURL('');
        setOpen(false);
        //navigate('/app/stories/' + docRef.id + '/edit/0')
    };

    useEffect(() => {
        setTimeout(() => {
            if (user?.uid) {

                const q = query(collection(db, 'shares'), orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    let sharesArr = [];
                    querySnapshot.forEach((doc) => {
                        sharesArr.push({ ...doc.data(), id: doc.id })
                    });
                    setShares(sharesArr)
                    setSharesLoaded(true)
                    console.log('Shares loaded')
                    console.log(sharesArr)
                });
                return () => unsubscribe()
            }
        }, 250)
    }, [user])



    // >> Analytics
    const [analytics, setAnalytics] = useState([])
    const [analyticsCount, setAnalyticsCount] = useState([])
    const [analyticsLoaded, setAnalyticsLoaded] = useState(false)


    function countEngagementTags(engagementData) {
        const tagCountMap = new Map();
        const tagTimeMap = new Map();
        engagementData.forEach((engagement) => {
            engagement.engagementTags.forEach((tag) => {
                if (tagCountMap.has(tag)) {
                    tagCountMap.set(tag, tagCountMap.get(tag) + 1);
                    tagTimeMap.set(tag, tagTimeMap.get(tag) + engagement.engagementTime);
                } else {
                    tagCountMap.set(tag, 1);
                    tagTimeMap.set(tag, engagement.engagementTime);
                }
            });
        });
        const tagCountArray = Array.from(tagCountMap.entries()).map(([name, value]) => ({ name, value, totalEngagementTime: tagTimeMap.get(name) }));
        return tagCountArray;
    }

    function aggregateItems(items) {
        // Create a map to store the count of each id
        const itemCountMap = new Map();

        // Iterate through the items array
        items.forEach(item => {
            if (itemCountMap.has(item.sceneIndex)) {
                // If the id is already in the map, increment the count
                const existingItem = itemCountMap.get(item.sceneIndex);
                existingItem.count += 1;
            } else {
                // If the id is not in the map, add it with a count of 1
                itemCountMap.set(item.sceneIndex, { id: item.sceneIndex, name: item.name, count: 1 });
            }
        });

        // Convert the map to an array
        const aggregatedItems = Array.from(itemCountMap.values());

        return aggregatedItems;
    }

    function countUniqueCombinations(items) {
        const combinationsMap = {};

        items.forEach(item => {
            const key = `${item.from}-${item.to}`;
            if (combinationsMap[key]) {
                combinationsMap[key].count++;
            } else {
                combinationsMap[key] = { from: item.from, to: item.to, count: 1 };
            }
        });

        return Object.values(combinationsMap);
    }

    useEffect(() => {
        setTimeout(() => {
            if (user?.uid) {

                const q = query(collection(db, 'analytics')); //, orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    let analyticsArr = [];
                    querySnapshot.forEach((doc) => {
                        analyticsArr.push({ ...doc.data(), id: doc.id })
                    });
                    setAnalytics(analyticsArr)

                    const analyticsData = {
                        "nodes": aggregateItems(analyticsArr),
                        "links": countUniqueCombinations(analyticsArr),
                    }

                    const tagCounts = countEngagementTags(analyticsArr);

                    setAnalytics(analyticsData)
                    setAnalyticsCount(tagCounts)

                    console.log('///////////  ARRAY COUNT FOR ANALYTICS ///////////')

                    setTimeout(() => {
                        setAnalyticsLoaded(true)
                    }, 500)

                });
                return () => unsubscribe()
            }
        }, 250)
    }, [user])
    // // Analytics


    useEffect(() => {
        console.log('Analytics Count')
        console.log(analyticsCount)
    }, [analyticsCount])


    useEffect(() => {
        setTimeout(() => {
            if (user?.uid) {
                const q = query(collection(db, 'stories'), orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    let storiesArr = [];
                    querySnapshot.forEach((doc) => {
                        storiesArr.push({ ...doc.data(), id: doc.id })
                    });
                    setStories(storiesArr);
                    setStoriesLoaded(true)
                    console.log('Stories loaded')
                    console.log(storiesArr)
                });
                return () => unsubscribe()
            }
        }, 250)
    }, [user])



    const override = {
        width: "50%",
        margin: "auto",
        marginTop: "20%",
    }

    return (
        <div className="h-screen flex flex-col">
            <div className="   ">
                <div className="mx-auto ">
                    {/* <div className="w-full">
                        <BarLoader
                            color={color}
                            loading={!storiesLoaded}
                            cssOverride={override}
                            size={450}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div> */}


                    <div className="grid grid-cols-5 gap-4">
                        <Transition.Root show={open} as={Fragment}>
                            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenDialog}>

                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 rounded-md transition-opacity" />
                                </Transition.Child>

                                <div className="fixed inset-0 z-10 overflow-y-auto"
                                >
                                    <div className="flex min-h-full items-end justify-center text-center rounded-md sm:items-center px-10">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        >

                                            <Dialog.Panel className={`relative transform overflow-hidden rounded-md bg-zinc-900 px-4 pb-2 pt-2 text-left
               shadow-xl transition-all sm:my-2 sm:w-full sm:w-none m-40 sm:p-10`}>
                                                <div>
                                                    <div className="text-left">
                                                        <Dialog.Title as="h3" className="text-xl leading-6 text-gray-100">
                                                            Create New Share
                                                        </Dialog.Title>


                                                        <div className=" mt-8">

                                                            <Menu as="div" className={`relative inline-block w-full text-left  rounded-md  ring-1 ring-outset  ${shareStoryError ? 'ring-sky-500' : 'ring-zinc-700'}`}>
                                                                {({ open }) => (
                                                                    <>
                                                                        <div className={`admin-dropdown rounded-md  `}>
                                                                            <Menu.Button
                                                                                className="inline-flex w-full gap-x-1.5 rounded admin-bg-side admin-component pl-4 py-4 text-base font-normal text-gray-200"
                                                                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                                                            >
                                                                                {shareStory ? shareStory.name : 'Select Story to Share'}
                                                                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            show={isDropdownOpen}
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="absolute left-0 z-10 w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
                                                                                <div className="section-scroll h-64 grid grid-cols-1 gap-1 px-1">
                                                                                    {stories.map((story, index) => (
                                                                                        <Menu.Item key={story.id}>
                                                                                            {({ active }) => (
                                                                                                <div
                                                                                                    className={`w-full p-2 rounded ${active ? 'bg-white/20' : ''
                                                                                                        } ${story.id === 'changeme' ? 'bg-white/20' : 'hover:bg-white/20 cursor-pointer'}`}
                                                                                                    onClick={() => {
                                                                                                        setShareStory(story)
                                                                                                        setIsDropdownOpen(false)
                                                                                                    }}
                                                                                                >
                                                                                                    {story.name}
                                                                                                </div>
                                                                                            )}
                                                                                        </Menu.Item>
                                                                                    ))}
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </>
                                                                )}
                                                            </Menu>

                                                            <div className="mt-8  pb-4">
                                                                <div className="text-zinc-100">
                                                                    Internal Details
                                                                </div>
                                                                <div className={`flex rounded-md shadow-sm ring-1 ring-inset ${shareNameError ? 'ring-sky-500' : 'ring-gray-600'} focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-400 mt-4 focus:outline-none`}>
                                                                    <input
                                                                        type="text"
                                                                        className={`block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset ${shareNameError ? 'ring-sky-500' : 'ring-zinc-700'} placeholder:text-zinc-500 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none`}
                                                                        placeholder={shareNameError ? "Required - Share Name" : "Share Name"}
                                                                        value={shareName}
                                                                        onChange={(e) => {
                                                                            setShareName(e.target.value);
                                                                            setShareNameError(false);
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className="mt-2">
                                                                    <textarea
                                                                        placeholder="Description"
                                                                        rows={3}
                                                                        className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                  ring-zinc-700 placeholder:text-zinc-500  focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none resize-none"
                                                                        style={{ resize: 'none !important' }}
                                                                        value={shareDescription}
                                                                        onChange={(e) => setShareDescription(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="mt-8 pb-4">
                                                                <div className="text-zinc-100">
                                                                    External Details
                                                                </div>
                                                                {shareStory ?
                                                                    <div className="flex">
                                                                        <div className="flex rounded-md border-0 py-4 bg-transparent text-zinc-500 shadow-sm ring-1 ring-inset ring-transparent placeholder:text-gray-400 sm:text-base sm:leading-6 focus:outline-none mr-2">
                                                                            https://www.brevit3.com/share/{shareStory.id}/v/
                                                                        </div>

                                                                        <input
                                                                            type="text"
                                                                            className={`flex-auto rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset ${shareURLError ? 'ring-sky-500' : 'ring-zinc-700'} placeholder:text-zinc-500 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none`}
                                                                            placeholder={shareURLError ? "Required - shared_demo_name_here" : "shared_demo_name_here"}
                                                                            value={shareURL}
                                                                            onChange={(e) => {
                                                                                setShareURL(e.target.value);
                                                                                setShareURLError(false);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="flex">
                                                                        <div className="flex rounded-md border-0 py-4 bg-transparent text-zinc-500 shadow-sm ring-1 ring-inset ring-transparent placeholder:text-gray-400 sm:text-base sm:leading-6 focus:outline-none mr-2">
                                                                            please select a story before sharing
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="col-span-full">

                                                            </div>
                                                            <div className="mt-8  pb-4">
                                                                <div className="text-zinc-100 ">
                                                                    Notify on open (optional)
                                                                </div>
                                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                focus-within:ring-inset focus-within:ring-sky-400 mt-4 focus:outline-none">
                                                                    <input
                                                                        type="text"
                                                                        className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                              ring-zinc-700 placeholder:text-zinc-500  focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                                                        placeholder="email@yourcompany.com"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-20 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 pt-4">
                                                    <button
                                                        type="button"
                                                        className="inline-flex w-full justify-center rounded bg-sky-500 px-3 py-2 text-base font-base text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                                                        onClick={createShare}
                                                    >
                                                        Create Share
                                                    </button>
                                                    {/* <button
                                                            type="button"
                                                            className="mt-3 inline-flex w-full justify-center rounded bg-gray-500 px-3 py-2 bg-zinc-800 text-base font-base text-gray-100 shadow-sm hover:bg-zinc-700 sm:col-start-1 sm:mt-0"
                                                            onClick={() => setOpen(false)}
                                                            ref={cancelButtonRef}
                                                        >
                                                            Cancel
                                                        </button> */}
                                                </div>
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </Dialog>
                        </Transition.Root>

                        {/* <motion.button
                            onClick={() => setOpen(true)}
                            className="group bg-black/10 hover:bg-black/25 rounded-lg p-6 text-left transition-all duration-200 w-64"
                            whileTap={{ scale: 0.95 }}
                            whileHover={{ scale: 1.02 }}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.2,
                                ease: "easeOut",
                                layout: { duration: 0.2 }
                            }}
                        >
                            <div className="space-x-4">
                                <div className="px-3 py-2 flex rounded-lg items-center">
                                    <motion.img
                                        src="/media/icon/new.svg"
                                        className="w-6 h-6 mr-4 text-sky-500"
                                        animate={{
                                            scale: 1,
                                            color: "#38bdf8"
                                        }}
                                        transition={{ duration: 0.2 }}
                                    />
                                    <h3 className="text-zinc-100 font-medium mb-2">New Share</h3>
                                </div>
                                <div>
                                    <p className="text-zinc-400 text-sm">Create a new share to distribute your story</p>
                                </div>
                            </div>
                        </motion.button> */}

                        <div className="col-span-2">
                            <div className="text-left">
                                <div className="text-lg leading-6 text-zinc-200 pt-1">
                                    <img src="/util/app/b3-admin-add-2.svg" className="h-5 inline-block mr-3 opacity-50" />
                                    Create New Share
                                </div>

                                <div className=" bg-black/10 h-[calc(100vh-300px)]  rounded-lg mt-4 p-4">
                                    {!storiesLoaded ? (
                                        // Skeleton loading for Create New Share section
                                        <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
                                            <div className="animate-pulse">
                                                <Skeleton className="w-full h-96 mb-8" />

                                            </div>
                                        </SkeletonTheme>
                                    ) : (
                                        <div className="p-1">
                                            <Menu as="div" className={`relative inline-block w-full text-left rounded-md ring-1 ring-outset ${shareStoryError ? 'ring-sky-500' : 'ring-zinc-700'}`}>
                                                {({ open }) => (
                                                    <>
                                                        <div className={`admin-dropdown rounded-md bg-zinc-900 `}>
                                                            <Menu.Button
                                                                className="inline-flex w-full gap-x-1.5 rounded      pl-4 py-4 text-base font-normal text-zinc-200"
                                                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                                            >
                                                                {shareStory ? shareStory.name : 'Select Story to Share'}
                                                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition
                                                            show={isDropdownOpen}
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items className="absolute left-0 z-10 w-full text-zinc-200 origin-top rounded-md bg-zinc-950 shadow-lg focus:outline-none">
                                                                <div className="section-scroll h-64 grid grid-cols-1 gap-1 px-1">
                                                                    {stories.map((story, index) => (
                                                                        <Menu.Item key={story.id}>
                                                                            {({ active }) => (
                                                                                <div
                                                                                    className={`w-full p-2 rounded ${active ? 'bg-white/20' : ''
                                                                                        } ${story.id === 'changeme' ? 'bg-white/20' : 'hover:bg-white/20 cursor-pointer'}`}
                                                                                    onClick={() => {
                                                                                        setShareStory(story)
                                                                                        setIsDropdownOpen(false)
                                                                                    }}
                                                                                >
                                                                                    {story.name}
                                                                                </div>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </>
                                                )}
                                            </Menu>

                                            <div className="mt-8  pb-4">
                                                <div className="text-zinc-100 ml-2.5">
                                                    Internal Details
                                                </div>
                                                <div className={`flex rounded-md shadow-sm ring-1 ring-inset ${shareNameError ? 'ring-sky-500' : 'ring-gray-600'} focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-400 mt-4 focus:outline-none`}>
                                                    <input
                                                        type="text"
                                                        className={`block w-full rounded-md border-0 p-4   bg-black/20  text-gray-100 shadow-sm ring-1 ring-inset ${shareNameError ? 'ring-sky-500' : 'ring-zinc-700'} placeholder:text-zinc-500 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none`}
                                                        placeholder={shareNameError ? "Required - Share Name" : "Share Name"}
                                                        value={shareName}
                                                        onChange={(e) => {
                                                            setShareName(e.target.value);
                                                            setShareNameError(false);
                                                        }}
                                                    />
                                                </div>

                                                <div className="mt-2">
                                                    <textarea
                                                        placeholder="Description"
                                                        rows={3}
                                                        className="inline-flex w-full p-3  bg-black/20 text-zinc-50 rounded border border-zinc-700 focus:border-sky-500 focus:ring-1 focus:ring-sky-500 [&:-webkit-autofill]:bg-zinc-600/25 [&:-webkit-autofill]:!text-zinc-50 [&:-webkit-autofill]:shadow-[0_0_0_30px_rgb(82_82_91_/_0.25)_inset] [&:-webkit-autofill]:[filter:none] resize-none"
                                                        value={shareDescription}
                                                        onChange={(e) => setShareDescription(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-8 pb-4   animate__animated  animate__fadeIn">
                                                <div className="text-zinc-100 ml-2.5">
                                                    External Details
                                                </div>
                                                {shareStory ?
                                                    <div className="flex ml-2.5">
                                                        <div className="flex rounded-md border-0 py-4 bg-transparent text-zinc-400 shadow-sm ring-1 ring-inset ring-transparent placeholder:text-gray-400 text-sm sm:leading-6 focus:outline-none mr-2">
                                                            https://www.brevit3.com/share/.../v/
                                                        </div>

                                                        <input
                                                            type="text"
                                                            className={`block w-full rounded-md border-0 p-4   bg-black/20  text-gray-100 shadow-sm ring-1 ring-inset ring-zinc-700 placeholder:text-zinc-500 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none ${shareURLError ? 'ring-sky-500' : 'ring-zinc-700'} placeholder:text-zinc-500 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none`}
                                                            placeholder={shareURLError ? "Required - shared_demo_name_here" : "shared_demo_name_here"}
                                                            value={shareURL}
                                                            onChange={(e) => {
                                                                setShareURL(e.target.value);
                                                                setShareURLError(false);
                                                            }}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="flex ">
                                                        <div className="flex rounded-md border-0 py-4 bg-transparent text-zinc-500 shadow-sm ring-1 ring-inset ring-transparent placeholder:text-gray-400 sm:text-base sm:leading-6 focus:outline-none mr-2 ml-2.5">
                                                            please select a story before sharing
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className="col-span-full  mt-4 animate__animated  animate__fadeIn">
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded bg-sky-500 px-3 py-2 text-base font-base text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                                                    onClick={createShare}
                                                >
                                                    Create Share
                                                </button>
                                            </div>
                                            {/* <div className="mt-8  pb-4">
                                                    <div className="text-zinc-100 ">
                                                        Notify on open (optional)
                                                    </div>
                                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                        focus-within:ring-inset focus-within:ring-sky-400 mt-4 focus:outline-none">
                                                        <input
                                                            type="text"
                                                            className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                                  ring-zinc-700 placeholder:text-zinc-500  focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                                            placeholder="email@yourcompany.com"
                                                        />
                                                    </div>
                                                </div> */}
                                        </div>
                                    )}


                                    {/* <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded bg-gray-500 px-3 py-2 bg-zinc-800 text-base font-base text-gray-100 shadow-sm hover:bg-zinc-700 sm:col-start-1 sm:mt-0"
                                    onClick={() => setOpen(false)}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-span-3 ml-4 gap-2 text-zinc-100">
                            <div className="flex justify-between items-center">
                                <div className="block antialiased tracking-normal  text-lg  leading-relaxed text-zinc-200">
                                    <img src="/util/app/b3-admin-send2.svg" className="h-5 inline-block mr-3 opacity-50" />
                                    Recent Shares
                                </div>

                            </div>

                            <div className="h-[calc(100vh-300px)] p-4 rounded-lg bg-black/10 mt-4">
                                {!sharesLoaded ? (
                                    // Skeleton loading for Recent Shares section
                                    <div className="space-y-4">
                                        <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
                                            {[1, 2, 3].map((item) => (
                                                <Skeleton key={item} height={140} className="w-full mb-2" />
                                            ))}
                                        </SkeletonTheme>
                                    </div>
                                ) : shares.length === 0 ? (
                                    // Empty state for shares
                                    <div className="p-6 flex items-center mt-12">
                                        <div className="mx-auto text-center">
                                            <img src="/media/icon/b3-admin-share.svg" className="w-10 h-10 mx-auto" />
                                            <div className="text-lg text-zinc-50 mt-4">No shares found</div>
                                            <div className="text-sm text-zinc-400 mt-2">Create a new share to get started</div>
                                        </div>
                                    </div>
                                ) : (
                                    // Existing shares list
                                    shares.map((share, index) => (
                                        <motion.div
                                            key={index}
                                            className="bg-black/10 rounded-lg p-6 flex flex-col md:flex-row md:items-center md:justify-between gap-2 transition-all hover:bg-black/20 mb-2"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.2 }}
                                        >
                                            <div className="flex items-start gap-4 w-96">
                                                <div
                                                    className="w-16 h-16 bg-contain bg-center bg-no-repeat rounded-md mr-4 flex-shrink-0"
                                                    style={{ backgroundImage: `url(${share.imageStoryLogo})` }}
                                                />
                                                <div>
                                                    <h3 className="text-lg font-medium">{share.name}</h3>

                                                    <div className="text-sm text-zinc-500 mt-1">
                                                        {share.createdAt?.toDate()?.toDateString()}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-1">
                                                {share.description && (
                                                    <p className=" text-zinc-200 mt-1">{share.description}</p>
                                                )}
                                            </div>

                                            <div className="flex items-center gap-3 flex-shrink-0">
                                                <a
                                                    href={`/app/stories/${share.storyId}/edit/0`}
                                                    target="_blank"
                                                    className="flex items-center px-4 py-2 rounded-md text-sm text-zinc-300 hover:bg-zinc-800 transition-colors"
                                                >
                                                    Edit Story
                                                    <img src="/media/icon/noun-link-5006217-FFFFFF.svg" className="h-7 ml-2 opacity-50" />
                                                </a>

                                                <CopyToClipboard
                                                    text={`https://www.brevit3.com/share/${share.storyId}/v/${share.shareFriendlyURL}`}
                                                    onCopy={() =>
                                                        toast.info("Link copied to clipboard", {
                                                            position: "bottom-center",
                                                            autoClose: 3000,
                                                            hideProgressBar: true,
                                                            theme: "dark",
                                                        })
                                                    }
                                                >
                                                    <button className="flex items-center px-4 py-2 rounded-md text-sm text-zinc-300 hover:bg-zinc-800 transition-colors">
                                                        Copy Link
                                                        <img src="/media/icon/noun-copy-2757462-FFFFFF.svg" className="h-8 ml-2 opacity-50" />
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </motion.div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>

                    <ToastContainer
                        // position="bottom-center"
                        // autoClose={5000}
                        // limit={5}
                        // hideProgressBar={false}
                        // newestOnTop
                        // closeOnClick
                        // rtl={false}
                        // //pauseOnFocusLoss
                        // draggable={false}
                        // pauseOnHover
                        // theme="dark"
                        // transition="Slide"
                        transition={Slide}
                    />

                </div>
            </div>
        </div>
    )
}

export default Share