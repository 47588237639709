import React, { useEffect, useState } from 'react';
import { UserAuth } from '../context/AuthContext';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import CompRegister from './CompRegister'
import GoogleButton from 'react-google-button';

const Signin = () => {
  const { googleSignIn, user, signIn } = UserAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState({ email: '', password: '' });

  const validateForm = () => {
    let valid = true;
    const errors = { email: '', password: '' };

    if (!email.trim()) {
      errors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
      valid = false;
    }

    if (!password.trim()) {
      errors.password = 'Password is required';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user != null) {
      //console.log('User...')
      //console.log(user)
      
      if (user.accessToken) {
        // //console.log('User is logged in')
        // if (document.url)
        // {
        //   //dispatch(push('/' + url.query.redirectURL))
        //   navigate(document.url)
        // }
        // else {
        console.log('got signin component...')

        navigate('/app/home')
        console.log(window.location.pathname)
        //navigate(window.location.pathname)
        //}
      }
      else {
        navigate('/signin')
      }
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!validateForm()) {
      return;
    }

    try {
      await signIn(email, password);
      navigate('/app/home');
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault()

    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        navigate("/app/home")
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        // ..
      });
  }

// redirect

  return (

    <div className="flex   flex-1 flex-col justify-left pt-40 pb-40  ">
      <div></div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md text-left">
        <img src="/util/app/sp-test-logo-2.png" id="login-logo" className="logo"></img>
        <h1 className='text-xl font-base py-2 text-gray-300 text-center mt-12'>Sign in to your account</h1>
        <div className='rounded-md bg-white/5 p-6 ring-1 ring-inset ring-white/10 mt-4'>
 
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col py-2'>
              <label className='py-2 font-sm text-gray-300'>Email Address</label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                className='block w-full rounded-md border-0 bg-white/5 py-3 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6'
                type='email'
                value={email}
              />
              {formErrors.email && (
                <p className="mt-1 text-sm text-red-500">{formErrors.email}</p>
              )}
            </div>
            <div className='flex flex-col py-2'>
              <label className='py-2 font-sm text-gray-300'>Password</label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                className='block w-full rounded-md border-0 bg-white/5 py-3 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6'
                type='password'
                value={password}
              />
              {formErrors.password && (
                <p className="mt-1 text-sm text-red-500">{formErrors.password}</p>
              )}
            </div>
            {error && (
              <p className="mt-1 text-sm text-red-500">{error}</p>
            )}
            <button 
              className='border border-blue-500 bg-sky-600 hover:bg-sky-500 w-full py-2 mt-6 text-white rounded'
              type="submit"
            >
              Sign In
            </button>
          </form>

          <div className="relative mt-10 mb-10">
            <div className="absolute inset-0 flex items-center " aria-hidden="true">
              <div className="w-full  " />
            </div>
            <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className=" px-6 text-gray-200">Or continue with</span>
            </div>
          </div>

          <div className='google-login-center mb-6'>
            <GoogleButton onClick={handleGoogleSignIn} />
          </div>



        </div>
        <p className='py-8   text-gray-400 text-center'>
          {/* Interested in Early Access?{' '} */}
          {/* <Link to='/signup' className='hover:underline text-sky-500'>
            Sign up
          </Link> */}
          {/* <CompRegister linkType="plain"></CompRegister> */}
        </p>
      </div>
      <div></div>
    </div>

  );
};

export default Signin;
