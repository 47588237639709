import React from 'react';

const CompPrintCover = ({ formData, coverType = 'story' }) => {
    return (
        <>
            <div className='w-full text-zinc-100 text-3xl hidden print:block'>
                <img
                    src="/util/app/StoryPath-Logo-Print.png"
                    className="h-8 absolute bottom-20 left-24"
                    alt="StoryPath Logo"
                />
                <div className='text-zinc-100 absolute top-24 left-24 w-full py-24'>
                    <div className='flex flex-col items-start'>
                        <div className='text-4xl font-semibold mb-8 font-sans'>
                            <h3 className='text-xl font-normal mb-1 text-white/50 font-sans'>customer</h3>
                            {formData.promptCustomerName || 'Customer details'}, &nbsp;
                            {formData.promptCustomerRole || 'Target'}
                        </div>
                        <div className='text-4xl mb-2 font-sans'>
                            <h3 className='text-xl font-normal mb-1 text-white/50 font-sans'>product</h3>
                            {formData.promptProductName || 'Product Name'}
                        </div>

                        <div className='w-full max-w-2xl mb-8 space-y-4 mt-12'>
                            <div className='text-left'>
                                <h3 className='text-xl font-normal mb-1 text-white/50 font-sans'>challenge</h3>
                                <p className='text-2xl font-sans'>
                                    {formData.promptCustomerProblem || 'Challenge details'}
                                </p>
                            </div>
                        </div>


                    </div>
                    <div className='text-sm mt-24 absolute -bottom-80 right-36 text-white/50'>
                    
                        <div className="px-2 py-1 rounded-full bg-white/5 font-sans">
                            Created on {new Date().toLocaleDateString()}
                        </div>
                    </div>
                </div>
            </div>
            {coverType === 'day-to-day' && (
                <div className='hidden print:block print:break-after-page'>

                </div>
            )}
        </>
    );
};

export default CompPrintCover; 