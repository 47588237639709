import { React, useState } from 'react'
//import '/Style.css'
import '/PublicSite.css'
import { Outlet } from 'react-router-dom'
import FormNewsletter from './FormNewsletter'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog } from '@headlessui/react'

const navigation = {
  solutions: [
    { name: 'Home', href: '/' },
    { name: 'Pricing', href: '/#pricing' },
    { name: 'About', href: '/about' },
    { name: 'Contact Us', href: '/contact' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '/about' },
    { name: 'Contact Us', href: '/contact' },
    //   { name: 'Jobs', href: '#' },
    //   { name: 'Press', href: '#' },
    //   { name: 'Partners', href: '#' },
  ],
  legal: [
    //   { name: 'Claim', href: '#' },
    { name: 'Privacy Policy', href: '/privacy' },
    { name: 'Terms of Service', href: '/terms' },
  ],
  social: [
    {
      name: 'Twitter',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'YouTube',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}

export const Menu = (props) => {
  //const { onSectionChange, menuOpened, setMenuOpened } = props;

  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <>
      <div id="scroll" className="absolute right-64 top-16 text-xl font-semibold text-white hidden"></div>



      <div id="top-buttons" className="fixed z-10 right-32 top-12 text-lg font-base text-zinc-100 hidden lg:inline">
        <a href="/login">
          <div className=' rounded-md p-3.5 px-4 bg-black/60'>
            Login

            {/* <div className='hidden xxs:inline'>
            xxs
          </div>
          <div className='hidden xs:inline'>
            xs
          </div>
          <div className='hidden sm:inline'>
            sm
          </div>
          <div className='hidden md:inline'>
            md
          </div>
          <div className='hidden lg:inline'>
            lg
          </div>
          <div className='hidden xl:inline'>
            xl
          </div>
          <div className='hidden 2xl:inline'>
            2xl
          </div>
          <div className='hidden 3xl:inline'>
            3xl
          </div> */}

          </div>
        </a>
      </div>



      <div className="absolute z-10 
      sm:top-12 
      xs:top-12 
      
      sm:left-12 
      left-8  
      top-6
      ">
        <a href="/" className='cursor-pointer'>
          <img src="/images/Brevity3-Logo-Icon.png" className="xl:hidden h-10 md:h-14 inline animate__animated animate__fadeIn " />
          <img src="/B3-Logo-Header.png" className="xl:inline z-10 h-14 hidden animate__animated animate__fadeIn" />
        </a>
      </div>
      {/* <img src="/Brevity3-Logo-Icon.png" className="lg:absolute z-10 sm:top-12 left-12 h-14 top-4 hidden" /> */}


      <button
        onClick={() => setMenuOpened(!menuOpened)}
        className="z-20 fixed top-4 right-4 md:top-12 md:right-14 p-3 bg-black/10 md:bg-black/60  w-14 h-14 rounded-md  "
      >
        <div
          className={`bg-zinc-100 h-0.5 rounded-md w-full transition-all ${menuOpened ? "rotate-45  translate-y-0.5" : ""
            }`}
        />
        <div
          className={`bg-zinc-100 h-0.5 rounded-md w-full my-1 ${menuOpened ? "hidden" : ""
            }`}
        />
        <div
          className={`bg-zinc-100 h-0.5 rounded-md w-full transition-all ${menuOpened ? "-rotate-45" : ""
            }`}
        />
      </button>
      <div
        className={`z-10 fixed top-0 right-0 bottom-0    transition-all overflow-hidden flex flex-col
      ${menuOpened ? "w-full lg:w-1/3 md:w-1/2" : "w-0"}`}
        style={{ backgroundColor: '#121217' }}
      >
        <div className="absolute flex-1 flex items-start w-full justify-center flex-col  p-8   top-12">
          <img src="/images/Brevity3-Logo-Icon.png" className='w-8 mb-12 ml-2 -mt-14 lg:-mt-8' />


          <div className='font-medium text-zinc-50 text-xl ml-2 mb-2'>
            Overview
          </div>
          <MenuButton label="Home"
            goUrl="/"
          />
          <MenuButton label="How it Works"
            goUrl="/#how-it-works"
            // onClick={() => setMenuOpened(!menuOpened)}
            setMenuOpened={setMenuOpened}
            menuOpened={menuOpened}
          />
          <MenuButton label="FAQs"
            goUrl="/#faqs"
            setMenuOpened={setMenuOpened}
            menuOpened={menuOpened}

          />
          <MenuButton label="Plans"
            goUrl="/#plans"
            className="mb-12"
            setMenuOpened={setMenuOpened}
            menuOpened={menuOpened}
          />

          <div className='font-medium text-zinc-50 text-xl ml-2 mb-2'>
            Company
          </div>
          <MenuButton label="Contact"
            goUrl="/contact"
          />
          <MenuButton label="About"
            goUrl="/about"
            className="mb-12"
          />

          <div className='font-medium text-zinc-50 text-xl ml-2 mb-2'>
            Legal
          </div>
          <MenuButton label="Terms"
            goUrl="/terms"
          />
          <MenuButton label="Privacy"
            goUrl="/privacy"
          />
          <MenuButton label="Login"
            className="mt-24"
            goUrl="/login"
          />
        </div>
      </div>
    </>
  );
};

const MenuButton = (props) => {
  const { label, goUrl } = props;
  return (
    <a
      //onClick={onClick}
      onClick={() => props.setMenuOpened(!props.menuOpened)}
      href={goUrl || ''}
      className={`text-zinc-400 w-full text-left hover:bg-zinc-800/50  p-2 rounded-md text-lg font-base cursor-pointer hover:text-white transition-all ${props.className || ''}`}
    >
      {label}
    </a>
  );
};



const PublicTemplate = () => {


  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  // const [checked, setChecked] = useState(false);
  // const handleChange = nextChecked => {
  //   setChecked(nextChecked);
  // };

  return (
    <div className='overflow-y-auto fixed h-full w-full'>

      <Outlet />

      <footer className="  " aria-labelledby="footer-heading">
        <div
          // style={{ position: 'absolute', top: bpFooter, height: '100vh' }}
          className='w-full '>
          <div className=" 
                w-full
                mx-auto px-12
                mt-20
                lg:mt-36
                mb-20
                lg:mb-36
                "
          >
            <div className="lg:text-5xl text-3xl  mb-6 
                      bg-gradient-to-b from-[#ffffff] to-[#ccc] bg-clip-text text-center  leading-[1] text-transparent
                      font-medium 
                      text-center text-gray-50 ">
              Stay in the Loop
            </div>
            <div className="text-xl text-gray-400 text-center">
              Get storytelling and product updates sent to your inbox - no spam, we promise
            </div>
            <div className="text-lg text-gray-400 text-center">
              {/* <form className="mx-auto mt-10 flex max-w-md gap-x-4">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                  placeholder="Enter your email"
                />
                <button
                  type="submit"
                  className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Notify me
                </button>
              </form> */}
              <div className="mx-auto mt-10   max-w-md gap-x-4">
                <FormNewsletter />
              </div>
            </div>
          </div>


          <div className="grid 
                 lg:grid-cols-2 lg:gap-4 gap-2
                 w-full pb-40
                 w-full mx-auto lg:px-12 px-8 py-12
                 bg-black/30
                 "
          >
            <div className="lg:p-12 p-2  text-gray-50 text-left">
              <div className="text-3xl mb-6 font-medium">
                <img src="/B3-Logo-Header.png" className="max-h-14 lg:h-12 h-8" />
              </div>
              <div className="lg:text-lg text-base text-gray-400">© 2025 Brevit3, Inc. All rights reserved.</div>
            </div>

            <div className="lg:p-12 p-2 pb-12 lg:mt-0 mt-8 text-gray-100 text-left grid grid-cols-2 text-base ">
              <div>
                <div className="text-lg mb-6 font-medium">Company</div>
                <div className="text-gray-400 mb-2"><a href="/about">About</a></div>
                {/* <div className=" text-gray-400 mb-2">Careers</div> */}
                <div className=" text-gray-400"><a href="/contact">Contact Us</a></div>
              </div>
              <div>
                <div className="text-lg mb-6 font-medium">Legal</div>
                <div className=" text-gray-400 mb-2"><a href="/terms">Terms and Conditions</a></div>
                <div className=" text-gray-400"><a href="/privacy">Privacy</a></div>
              </div>
            </div>
          </div>



        </div>
      </footer >

      <Menu
      //onSectionChange={setSection}
      //menuOpened={menuOpened}
      //setMenuOpened={setMenuOpened}
      />

    </div>
  )
}

export default PublicTemplate