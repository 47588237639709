import React, { useEffect, useState, useRef } from 'react';
import { collection, query, where, orderBy, limit, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  PieChart, Pie, Cell, BarChart, Bar, AreaChart, Area, Sankey, Rectangle, Layer
} from 'recharts';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Define vibrant color schemes for different node types
const USER_COLORS = [
  "#486DF0", // Blue
  "#F49352", // Orange
  "#D6475D", // Red
  "#85357D", // Purple
  "#6F50E5", // sky
  "#3C898E", // Teal
];

const EVENT_COLORS = [
  "#F08696", // Pink
  "#F09EDA", // Light Purple
  "#9C86F0", // Light sky
  "#90A5F0", // Light Blue
  "#75CBD1", // Light Teal
  "#F1C086"  // Light Orange
];

// Link colors (lighter versions of node colors for better aesthetics)
const LINK_COLORS = [
  "#B6C5F9", // Light Blue
  "#FBD4BA", // Light Orange
  "#EFB5BE", // Light Red
  "#CEAECB", // Light Purple
  "#C5B9F5", // Light sky
  "#B1D0D2", // Light Teal
  "#F9CFD5", // Light Pink
  "#F9D8F0", // Very Light Purple
];

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// Custom Node component for Sankey diagram - with larger labels
const CustomNode = (props) => {
  // Determine if this is a user node (left side) or event node (right side)
  const isUserNode = props.payload.category === 'user';
  
  return (
    <g>
      <rect
        x={props.x}
        y={props.y}
        width={props.width}
        height={props.height}
        fill={props.payload.color || (isUserNode ? USER_COLORS[0] : EVENT_COLORS[0])}
        rx={3}
        stroke="#ffffff"
        strokeWidth={0.5}
        strokeOpacity={0.8}
      />
      
      {/* Add text label next to the node with larger font size */}
      <text
        x={isUserNode ? props.x - 10 : props.x + props.width + 10}
        y={props.y + props.height / 2}
        textAnchor={isUserNode ? "end" : "start"}
        dominantBaseline="middle"
        fill="#ffffff"
        fontSize={13} // Increased from 11
        fontWeight="600" // Increased from 500
        style={{ textShadow: '0 0 4px rgba(0, 0, 0, 0.8)' }}
      >
        {props.payload.displayName}
      </text>
    </g>
  );
};

// Custom Link component with gradient coloring and reduced opacity
const CustomLink = (props) => {
  const minY = Math.min(props.sourceY, props.targetY) - props.linkWidth / 2;
  const maxY = Math.max(props.sourceY, props.targetY) + props.linkWidth / 2;
  const maskId = `mask-${props.index}`;
  const gradientId = `gradient-${props.index}`;
  
  // Get source and target node names from payload
  const sourceNode = props.payload.source?.name || '';
  const targetNode = props.payload.target?.name || '';
  
  // Get source and target node colors for gradient
  const sourceColor = typeof props.payload.source === 'object' ? 
    props.payload.source.color : props.payload.sourceColor || USER_COLORS[0];
  const targetColor = typeof props.payload.target === 'object' ? 
    props.payload.target.color : props.payload.targetColor || EVENT_COLORS[0];
  
  return (
    <>
      {/* Define the gradient for this specific link */}
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={sourceColor} stopOpacity="0.5" />
          <stop offset="100%" stopColor={targetColor} stopOpacity="0.5" />
        </linearGradient>
      </defs>
      
      <mask id={maskId}>
        <rect
          x={props.sourceX}
          y={minY}
          width={props.targetX - props.sourceX}
          height={maxY - minY}
          fill="white"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <rect
          x={props.sourceX}
          y={minY}
          width={props.targetX - props.sourceX}
          height={maxY - minY}
          fill="transparent"
        />
        <path
          d={`
          M${props.sourceX},${props.sourceY}
          C${props.sourceControlX},${props.sourceY} ${props.targetControlX},${props.targetY} ${props.targetX},${props.targetY}`}
          fill="none"
          stroke={`url(#${gradientId})`}
          strokeWidth={props.linkWidth}
          strokeOpacity={0.5} // Set to 50% opacity
          strokeLinecap="butt"
        />
      </g>
      <foreignObject
        x={props.sourceX}
        y={props.targetY - props.linkWidth / 2}
        width={Math.max(props.targetX, props.sourceX) - Math.min(props.targetX, props.sourceX)}
        height={props.linkWidth}
        style={{ overflow: "visible" }}
      >
        <div
          style={{
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            overflow: "visible",
            padding: "0.5em",
            gap: 8
          }}
        >
          {/* Only show source label for links with larger width */}
          {props.linkWidth > 15 && (
            <div
              style={{
                fontSize: 11,
                fontFamily: "sans-serif",
                textAlign: "center",
                backgroundColor: "rgba(15, 23, 42, 0.8)",
                color: "white",
                padding: "0.25em 0.5em",
                borderRadius: 4,
                position: "relative",
                zIndex: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "120px"
              }}
            >
              {sourceNode}
            </div>
          )}
          
          {/* Always show target label with event count */}
          <div
            style={{
              fontSize: 11,
              fontFamily: "sans-serif",
              textAlign: "center",
              backgroundColor: "rgba(15, 23, 42, 0.8)",
              color: "white",
              padding: "0.25em 0.5em",
              borderRadius: 4,
              position: "relative",
              zIndex: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "140px"
            }}
          >
            {targetNode}: {props.payload.value}
          </div>
        </div>
      </foreignObject>
    </>
  );
};

const ActivityDashboard = () => {
  const [activityData, setActivityData] = useState([]);
  const [userActivityCounts, setUserActivityCounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedUser, setExpandedUser] = useState(null);
  const svgContainerRef = useRef(null);
  // Add state for storing user email mapping
  const [userEmailMap, setUserEmailMap] = useState({});
  
  // Add new state for date filters
  const [dateRange, setDateRange] = useState('48h'); // Default: 48 hours
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  // Add new state for SP team filter
  const [excludeSPTeam, setExcludeSPTeam] = useState(true);

  // SP team user IDs to exclude when filter is active
  const SP_TEAM_USER_IDS = [
    '8OJilk3jRXTvfQBh0rfgaszvr0r1',
    't8X5CAmys8W88z5rOQWEQaIWhHn2',
    '4NOPEyW6zVhKIQKRSO5zVFStEZ82'
  ];

  // Move foreignObjects to the top for better layering
  const moveForeignObjectsToTop = () => {
    const svg = svgContainerRef.current?.querySelector("svg");
    const foreignObjects = svg?.querySelectorAll("foreignObject");
    foreignObjects?.forEach((foreignObject) => {
      svg?.appendChild(foreignObject);
    });
  };

  useEffect(() => {
    // Move foreign objects to top after render
    if (svgContainerRef.current) {
      moveForeignObjectsToTop();
    }
  });

  useEffect(() => {
    fetchActivityData();
  }, [dateRange, customStartDate, customEndDate, excludeSPTeam]);

  // Add a function to fetch user data from the "users" collection
  const fetchUserEmails = async (userIds) => {
    try {
      // Create a map to store user ID to email mapping
      const emailMap = {};
      
      // Use Promise.all to run multiple Firebase queries concurrently
      await Promise.all(
        userIds.map(async (userId) => {
          try {
            const userDoc = await getDocs(
              query(collection(db, "users"), where("uid", "==", userId), limit(1))
            );
            
            if (!userDoc.empty) {
              const userData = userDoc.docs[0].data();
              // Store the email if it exists, otherwise use the userId
              emailMap[userId] = userData.email || userId;
            } else {
              // If no matching user document, keep the original userId
              emailMap[userId] = userId;
            }
          } catch (error) {
            console.error(`Error fetching user data for ${userId}:`, error);
            // In case of error, keep the original userId
            emailMap[userId] = userId;
          }
        })
      );
      
      return emailMap;
    } catch (error) {
      console.error("Error fetching user emails:", error);
      return {};
    }
  };

  const fetchActivityData = async () => {
    setIsLoading(true);
    try {
      // Calculate the timestamp based on selected date range
      let startDate = new Date();
      const endDate = new Date(); // Current time as end date
      
      // Handle preset date ranges
      if (dateRange === '30m') {
        startDate.setMinutes(startDate.getMinutes() - 30);
      } else if (dateRange === '1h') {
        startDate.setHours(startDate.getHours() - 1);
      } else if (dateRange === '2h') {
        startDate.setHours(startDate.getHours() - 2);
      } else if (dateRange === '4h') {
        startDate.setHours(startDate.getHours() - 4);
      } else if (dateRange === '24h') {
        startDate.setHours(startDate.getHours() - 24);
      } else if (dateRange === '48h') {
        startDate.setHours(startDate.getHours() - 48);
      } else if (dateRange === '7d') {
        startDate.setDate(startDate.getDate() - 7);
      } else if (dateRange === '30d') {
        startDate.setDate(startDate.getDate() - 30);
      } else if (dateRange === 'custom' && customStartDate) {
        // For custom date range
        startDate = new Date(customStartDate);
        if (customEndDate) {
          const customEnd = new Date(customEndDate);
          // Set time to the end of the day
          customEnd.setHours(23, 59, 59, 999);
          endDate.setTime(customEnd.getTime());
        }
      }
      
      // Create the query with date range
      const q = query(
        collection(db, "core-activity"),
        where("timestamp", ">=", Timestamp.fromDate(startDate)),
        where("timestamp", "<=", Timestamp.fromDate(endDate)),
        orderBy("timestamp", "asc")
      );
      
      const querySnapshot = await getDocs(q);
      
      // Process activity data for charts
      const activities = [];
      const userActivityMap = new Map();
      const uniqueUserIds = new Set();
      
      // First pass: collect all unique user IDs
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.userId) {
          // Skip SP team members if the filter is active
          if (excludeSPTeam && SP_TEAM_USER_IDS.includes(data.userId)) {
            return;
          }
          uniqueUserIds.add(data.userId);
        }
      });
      
      // Fetch email addresses for all unique user IDs
      const emailMap = await fetchUserEmails(Array.from(uniqueUserIds));
      setUserEmailMap(emailMap);
      
      // Second pass: process activity data with email addresses
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        
        // Skip SP team members if the filter is active
        if (excludeSPTeam && SP_TEAM_USER_IDS.includes(data.userId)) {
          return;
        }
        
        const userId = data.userId || 'unknown';
        const userEmail = emailMap[userId] || userId;
        
        // Format data for line chart
        activities.push({
          ...data,
          timestamp: data.timestamp.toDate(),
          id: doc.id,
          userEmail: userEmail // Add user email to the activity data
        });
        
        // Count activities per user (using email instead of ID)
        userActivityMap.set(userEmail, (userActivityMap.get(userEmail) || 0) + 1);
      });
      
      // Format user activity counts for table and pie chart
      const userCountsArray = Array.from(userActivityMap, ([userEmail, count]) => ({
        userId: userEmail, // Using email as the userId for display purposes
        count
      })).sort((a, b) => b.count - a.count);
      
      console.log(activities);
      setActivityData(activities);
      setUserActivityCounts(userCountsArray);
    } catch (error) {
      console.error("Error fetching activity data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Updated function to use emails instead of user IDs
  const prepareLineChartData = () => {
    // Group activities by timestamp and user
    const userActivitiesByTime = {};
    
    activityData.forEach(activity => {
      const timeKey = activity.timestamp.toISOString().slice(0, 16); // Format to hour precision
      const userKey = activity.userEmail || activity.userId || 'unknown';
      
      if (!userActivitiesByTime[timeKey]) {
        userActivitiesByTime[timeKey] = { 
          timestamp: activity.timestamp,
          timeKey
        };
      }
      
      if (!userActivitiesByTime[timeKey][userKey]) {
        userActivitiesByTime[timeKey][userKey] = 0;
      }
      
      userActivitiesByTime[timeKey][userKey] += 1;
    });
    
    return Object.values(userActivitiesByTime).sort((a, b) => a.timestamp - b.timestamp);
  };

  // Get unique users for line chart (using emails)
  const getUniqueUsers = () => {
    const users = new Set();
    activityData.forEach(activity => {
      users.add(activity.userEmail || activity.userId || 'unknown');
    });
    return Array.from(users);
  };

  // Get activities for a specific user (using email)
  const getUserActivities = (userEmail) => {
    return activityData
      .filter(activity => (activity.userEmail || activity.userId) === userEmail)
      .sort((a, b) => b.timestamp - a.timestamp); // Sort by newest first
  };

  // Toggle timeline visibility for a user
  const toggleUserTimeline = (userId) => {
    setExpandedUser(expandedUser === userId ? null : userId);
  };

  // Get counts for each event type
  const getEventTypeCounts = () => {
    const eventCounts = new Map();
    
    activityData.forEach(activity => {
      // Check if the activity has an event property
      if (activity.event) {
        const eventName = activity.event;
        eventCounts.set(eventName, (eventCounts.get(eventName) || 0) + 1);
      }
      // Also check if there's a type property as fallback
      else if (activity.type) {
        const eventName = activity.type;
        eventCounts.set(eventName, (eventCounts.get(eventName) || 0) + 1);
      }
    });
    
    // Convert to array format for the bar chart
    return Array.from(eventCounts, ([name, count]) => ({ name, count }))
      .sort((a, b) => b.count - a.count); // Sort by count in descending order
  };

  // Handle date range change
  const handleDateRangeChange = (event) => {
    const newRange = event.target.value;
    setDateRange(newRange);
    // Hide custom date picker if not selecting "custom"
    setShowCustomDatePicker(newRange === 'custom');
  };

  // Get the title for the current date range
  const getDateRangeTitle = () => {
    switch (dateRange) {
      case '30m': return 'Past 30 Minutes';
      case '1h': return 'Past Hour';
      case '2h': return 'Past 2 Hours';
      case '4h': return 'Past 4 Hours';
      case '24h': return 'Past 24 Hours';
      case '48h': return 'Past 48 Hours';
      case '7d': return 'Past 7 Days';
      case '30d': return 'Past 30 Days';
      case 'custom': 
        if (customStartDate && customEndDate) {
          return `${new Date(customStartDate).toLocaleDateString()} - ${new Date(customEndDate).toLocaleDateString()}`;
        } else if (customStartDate) {
          return `From ${new Date(customStartDate).toLocaleDateString()}`;
        }
        return 'Custom Range';
      default: return 'Activity Data';
    }
  };

  const lineChartData = prepareLineChartData();
  const uniqueUsers = getUniqueUsers();

  // Add this function before the return statement to prepare data for the gen_story area chart
  const prepareGenStoryData = () => {
    // Create a map to store counts by day
    const dailyCounts = new Map();
    
    activityData.forEach(activity => {
      // Check if it's a gen_story event
      if (activity.event === 'gen_story' || activity.type === 'gen_story') {
        // Format date to YYYY-MM-DD for grouping by day
        const dateKey = activity.timestamp.toISOString().split('T')[0];
        
        if (!dailyCounts.has(dateKey)) {
          dailyCounts.set(dateKey, {
            date: dateKey,
            count: 0
          });
        }
        
        dailyCounts.get(dateKey).count += 1;
      }
    });
    
    // Convert to array and sort by date
    return Array.from(dailyCounts.values())
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  // Updated Sankey data preparation function to use emails
  const prepareSankeyData = () => {
    if (!activityData || activityData.length === 0) {
      return { nodes: [], links: [] };
    }
    
    // Track users and events
    const userCounts = {};
    const eventCounts = {};
    const userEventLinks = {};
    
    activityData.forEach(activity => {
      const userKey = activity.userEmail || activity.userId || 'unknown';
      const eventType = activity.event || activity.type || 'unknown';
      
      // Count user activity
      userCounts[userKey] = (userCounts[userKey] || 0) + 1;
      
      // Count event types
      eventCounts[eventType] = (eventCounts[eventType] || 0) + 1;
      
      // Track links between users and events
      const linkKey = `${userKey}-${eventType}`;
      userEventLinks[linkKey] = (userEventLinks[linkKey] || 0) + 1;
    });
    
    // Sort users by activity count (descending) and limit to top 10 users
    const sortedUsers = Object.entries(userCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10) // Limit to top 10 users
      .map(([userKey, count]) => ({ id: userKey, count }));
      
    // Sort events by count (descending)
    const sortedEvents = Object.entries(eventCounts)
      .sort((a, b) => b[1] - a[1])
      .map(([eventType, count]) => ({ id: eventType, count }));
    
    // Prepare nodes for Sankey diagram - users on left, events on right
    const nodes = [];
    const userIdToNodeIndex = {};
    const eventTypeToNodeIndex = {};
    const nodeColors = {}; // Track node colors for links
    
    // Add user nodes first
    sortedUsers.forEach((user, index) => {
      const color = USER_COLORS[index % USER_COLORS.length];
      userIdToNodeIndex[user.id] = index;
      nodeColors[index] = color; // Store color for links
      
      // Format email display name for better readability
      const displayName = user.id.includes('@') 
        ? user.id.split('@')[0] 
        : (user.id.length > 12 ? user.id.substring(0, 10) + '...' : user.id);
      
      nodes.push({
        name: user.id,
        displayName: displayName,
        color: color,
        category: 'user',
        value: user.count // Use count for node sizing
      });
    });
    
    // Add event nodes second
    sortedEvents.forEach((event, index) => {
      const nodeIndex = index + sortedUsers.length;
      const color = EVENT_COLORS[index % EVENT_COLORS.length];
      eventTypeToNodeIndex[event.id] = nodeIndex;
      nodeColors[nodeIndex] = color; // Store color for links
      
      nodes.push({
        name: event.id,
        displayName: event.id,
        color: color,
        category: 'event',
        value: event.count // Use count for node sizing
      });
    });
    
    // Create links between users and events
    const links = [];
    
    Object.entries(userEventLinks).forEach(([key, value]) => {
      const [userId, eventType] = key.split('-');
      
      // Only include links where both the user and event type are in our nodes
      if (userIdToNodeIndex[userId] !== undefined && eventTypeToNodeIndex[eventType] !== undefined) {
        const sourceIndex = userIdToNodeIndex[userId];
        const targetIndex = eventTypeToNodeIndex[eventType];
        
        links.push({
          source: sourceIndex,
          target: targetIndex,
          value: value,
          sourceColor: nodeColors[sourceIndex], // Add source node color
          targetColor: nodeColors[targetIndex], // Add target node color
          sourceName: userId,
          targetName: eventType
        });
      }
    });
    
    return {
      nodes,
      links,
      userNodeCount: sortedUsers.length
    };
  };

  return (
    <div className="flex flex-col pb-48 pt-12">
      <div className="md:flex md:items-center md:justify-between mb-6">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-2xl sm:tracking-tight">
            Activity Dashboard
          </h2>
        </div>
        
        {/* Date and Time Filter */}
        <div className="mt-4 md:mt-0 flex flex-col sm:flex-row gap-3 items-center">
          {/* SP team filter checkbox */}
          <div className="flex items-center mr-3">
            <input
              id="exclude-sp-team"
              type="checkbox"
              checked={excludeSPTeam}
              onChange={(e) => setExcludeSPTeam(e.target.checked)}
              className="h-4 w-4 rounded border-zinc-300 text-sky-600 focus:ring-sky-500"
            />
            <label htmlFor="exclude-sp-team" className="ml-2 text-sm text-zinc-200">
              Exclude SP team
            </label>
          </div>
          
          <div className="relative rounded-md">
            <select
              value={dateRange}
              onChange={handleDateRangeChange}
              className="block w-full rounded-md border-0 bg-zinc-800 py-2 px-3 text-zinc-200 shadow-sm ring-1 ring-inset ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm"
            >
              <option value="30m">Last 30 Minutes</option>
              <option value="1h">Last Hour</option>
              <option value="2h">Last 2 Hours</option>
              <option value="4h">Last 4 Hours</option>
              <option value="24h">Last 24 Hours</option>
              <option value="48h">Last 48 Hours</option>
              <option value="7d">Last 7 Days</option>
              <option value="30d">Last 30 Days</option>
              <option value="custom">Custom Range</option>
            </select>
          </div>
          
          {showCustomDatePicker && (
            <div className="flex flex-col sm:flex-row gap-3">
              <div>
                <label htmlFor="start-date" className="sr-only">Start Date</label>
                <input
                  type="date"
                  id="start-date"
                  value={customStartDate}
                  onChange={(e) => setCustomStartDate(e.target.value)}
                  className="block w-full rounded-md border-0 bg-zinc-800 py-2 px-3 text-zinc-200 shadow-sm ring-1 ring-inset ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="end-date" className="sr-only">End Date</label>
                <input
                  type="date"
                  id="end-date"
                  value={customEndDate}
                  onChange={(e) => setCustomEndDate(e.target.value)}
                  className="block w-full rounded-md border-0 bg-zinc-800 py-2 px-3 text-zinc-200 shadow-sm ring-1 ring-inset ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm"
                />
              </div>
              <button
                onClick={fetchActivityData}
                className="inline-flex items-center justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
              >
                Apply
              </button>
            </div>
          )}
        </div>
      </div>

      {isLoading ? (
        <SkeletonTheme baseColor="#27272a" highlightColor="#3f3f46">
          <div className="space-y-4">
            <Skeleton height={300} className="w-full rounded-md" />
            <div className="grid grid-cols-12 gap-4">
              <Skeleton height={400} className="w-full rounded-md col-span-6" />
              <Skeleton height={400} className="w-full rounded-md col-span-6" />
            </div>
          </div>
        </SkeletonTheme>
      ) : (
        <div className="grid grid-cols-12 gap-6 pb-12">
          {/* User Activity Summary - Left Column */}
          <div className="bg-black/20 p-4 rounded-lg shadow col-span-4 h-full">
            <h3 className="text-lg font-medium text-zinc-100 mb-4 flex justify-between items-center">
              <span>User Activity Summary</span>
              <span className="text-sm bg-sky-600/50 text-sky-100 px-2 py-1 rounded-md">
                {userActivityCounts.length} Users
              </span>
            </h3>


            {/* MAU and DAU Stats Section */}
            <div className="mt-4 bg-zinc-900/70 rounded-lg p-3">
              <h4 className="text-sm font-medium text-zinc-400 mb-2">User Statistics</h4>
              <div className="grid grid-cols-2 gap-3">
                <div className="bg-zinc-800 rounded-md p-3">
                  <div className="text-xs text-zinc-400">DAU (Daily Active Users)</div>
                  <div className="text-xl font-medium text-sky-300 mt-1">
                    {activityData.length > 0 
                      ? new Set(activityData
                          .filter(a => {
                            const today = new Date();
                            const activityDate = new Date(a.timestamp);
                            return activityDate.setHours(0,0,0,0) === today.setHours(0,0,0,0);
                          })
                          .map(a => a.userEmail || a.userId)).size 
                      : 0}
                  </div>
                </div>
                <div className="bg-zinc-800 rounded-md p-3">
                  <div className="text-xs text-zinc-400">MAU (Monthly Active Users)</div>
                  <div className="text-xl font-medium text-sky-300 mt-1">
                    {activityData.length > 0 
                      ? new Set(activityData
                          .filter(a => {
                            const today = new Date();
                            const activityDate = new Date(a.timestamp);
                            const thirtyDaysAgo = new Date();
                            thirtyDaysAgo.setDate(today.getDate() - 30);
                            return activityDate >= thirtyDaysAgo;
                          })
                          .map(a => a.userEmail || a.userId)).size 
                      : 0}
                  </div>
                </div>
              </div>
            </div>


            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg mt-4 h-[calc(100%-12rem)]">
              <div className="max-h-full overflow-y-auto">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead className="sticky top-0 bg-zinc-900">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-300 sm:pl-6">
                        User ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-300">
                        Activity Count
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-zinc-800 bg-zinc-900">
                    {userActivityCounts.map((userActivity) => (
                      <React.Fragment key={userActivity.userId}>
                        <tr 
                          onClick={() => toggleUserTimeline(userActivity.userId)}
                          className="cursor-pointer hover:bg-zinc-800 transition-colors"
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-100 sm:pl-6 flex items-center">
                            <span className="mr-2">{expandedUser === userActivity.userId ? '▼' : '▶'}</span>
                            {userActivity.userId}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                            {userActivity.count}
                          </td>
                        </tr>
                        {expandedUser === userActivity.userId && (
                          <tr>
                            <td colSpan={2} className="py-2 px-4">
                              <div className="pl-8 py-3">
                                {getUserActivities(userActivity.userId).length > 0 ? (
                                  <div className="relative">
                                    {/* Timeline line */}
                                    <div className="absolute top-0 bottom-0 left-4 w-0.5 bg-sky-500"></div>
                                    
                                    {/* Timeline items */}
                                    <ul className="space-y-4">
                                      {getUserActivities(userActivity.userId).map((activity, index) => (
                                        <li key={activity.id} className="relative pl-10">
                                          {/* Timeline dot */}
                                          <div className="absolute left-[0.9px] w-8 h-8 bg-zinc-900 rounded-full border-4 border-sky-500 flex items-center justify-center">
                                            <span className="text-xs font-medium text-sky-300">{index + 1}</span>
                                          </div>
                                          
                                          {/* Timeline content */}
                                          <div className="bg-zinc-800 rounded-md p-3 ml-2">
                                            <div className="flex justify-between items-start">
                                              <div className="font-medium text-sky-300">
                                                {activity.action || activity.type || "Activity"}
                                              </div>
                                              <div className="text-xs text-zinc-400">
                                                {activity.timestamp.toLocaleString()}
                                              </div>
                                            </div>
                                            <div className="text-sm text-zinc-300 mt-1">
                                              {activity.details || 
                                               JSON.stringify(
                                                 Object.entries(activity)
                                                   .filter(([key]) => !['id', 'userId', 'timestamp', 'action', 'type'].includes(key))
                                                   .reduce((obj, [key, value]) => ({...obj, [key]: value}), {})
                                               )}
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ) : (
                                  <div className="text-sm text-zinc-400 italic text-center">
                                    No detailed activity records found
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                    {userActivityCounts.length === 0 && (
                      <tr>
                        <td colSpan={2} className="py-4 pl-4 pr-3 text-sm text-center text-gray-400">
                          No activity data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            

          </div>

          {/* Right Column Sections */}
          <div className="space-y-6 col-span-8">
            {/* Updated Sankey Diagram with custom nodes and links */}
            <div className="bg-black/20 p-4 rounded-lg shadow">
              <h3 className="text-lg font-medium text-zinc-100 mb-4">
                User Activity Flow
              </h3>
              <div className="flex justify-center">
                <div ref={svgContainerRef} className="h-[600px] w-full">
                  <ResponsiveContainer width="100%" height="100%" onResize={moveForeignObjectsToTop}>
                    {(() => {
                      const sankeyData = prepareSankeyData();
                      
                      if (sankeyData.nodes.length === 0 || sankeyData.links.length === 0) {
                        return (
                          <div className="flex items-center justify-center h-full">
                            <p className="text-zinc-400 italic">No event flow data available for this time period</p>
                          </div>
                        );
                      }
                      
                      return (
                        <Sankey
                          data={sankeyData}
                          node={CustomNode}
                          link={CustomLink}
                          nodeWidth={25}
                          nodePadding={20}
                          margin={{ top: 50, right: 140, bottom: 50, left: 140 }}
                          linkCurvature={0.5}
                          iterations={64}
                        >
                          {/* Category labels with larger font */}
                          <text
                            x={80}
                            y={25}
                            textAnchor="middle"
                            fill="#fff"
                            fontSize={16}
                            fontWeight="bold"
                          >
                            Users
                          </text>
                          <text
                            x="calc(100% - 80px)"
                            y={25}
                            textAnchor="middle"
                            fill="#fff"
                            fontSize={16}
                            fontWeight="bold"
                          >
                            Event Types
                          </text>
                          
                          <Tooltip 
                            contentStyle={{ 
                              backgroundColor: '#27272a', 
                              border: 'none', 
                              borderRadius: '4px', 
                              color: '#fff',
                              padding: '8px 12px',
                              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                              fontSize: '13px'
                            }}
                            formatter={(value, name, {payload}) => {
                              // Display node or link information
                              const isLink = payload.hasOwnProperty('source');
                              if (isLink) {
                                const sourceName = typeof payload.source === 'object' ? 
                                  payload.source.name : sankeyData.nodes[payload.source].name;
                                const targetName = typeof payload.target === 'object' ? 
                                  payload.target.name : sankeyData.nodes[payload.target].name;
                                return [
                                  `${value} activities`, 
                                  <span style={{fontWeight: 'bold', color: 'white'}}>{sourceName} → {targetName}</span>
                                ];
                              }
                              
                              // For nodes, show total count
                              const totalCount = payload.value || 
                                sankeyData.links.filter(l => 
                                  (typeof l.source === 'object' ? l.source.index : l.source) === payload.index || 
                                  (typeof l.target === 'object' ? l.target.index : l.target) === payload.index
                                ).reduce((sum, l) => sum + l.value, 0);
                              
                              return [
                                payload.displayName || name, 
                                `Total activities: ${totalCount}`
                              ];
                            }}
                          />
                        </Sankey>
                      );
                    })()}
                  </ResponsiveContainer>
                  
                  {/* Legends for the chart - larger text */}
                  <div className="flex justify-center items-center mt-4 gap-6 flex-wrap">
                    <div className="flex items-center">
                      <div style={{ backgroundColor: USER_COLORS[0] }} className="w-5 h-5 mr-2 rounded-sm"></div>
                      <span className="text-sm text-zinc-300">Users</span>
                    </div>
                    <div className="flex items-center">
                      <div style={{ backgroundColor: EVENT_COLORS[0] }} className="w-5 h-5 mr-2 rounded-sm"></div>
                      <span className="text-sm text-zinc-300">Event Types</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Line Chart - User Activity */}
            <div className="bg-black/20 p-4 rounded-lg shadow">
              <h3 className="text-lg font-medium text-zinc-100 mb-4">
                User Activity ({getDateRangeTitle()})
              </h3>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={lineChartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                    <XAxis 
                      dataKey="timeKey" 
                      tick={{ fill: '#9ca3af' }}
                      tickFormatter={(timeStr) => {
                        const date = new Date(timeStr);
                        return `${date.getMonth()+1}/${date.getDate()} ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
                      }}
                    />
                    <YAxis tick={{ fill: '#9ca3af' }} />
                    <Tooltip 
                      contentStyle={{ backgroundColor: '#27272a', border: 'none', borderRadius: '4px', color: '#fff' }} 
                      labelStyle={{ color: '#fff' }}
                    />
                    <Legend wrapperStyle={{ color: '#fff' }} />
                    {uniqueUsers.map((userId, index) => (
                      <Line 
                        key={userId}
                        type="monotone" 
                        dataKey={userId} 
                        stroke={COLORS[index % COLORS.length]} 
                        activeDot={{ r: 8 }}
                        name={`User: ${userId}`}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Event Type Distribution Bar Chart */}
            <div className="bg-black/20 p-4 rounded-lg shadow">
              <h3 className="text-lg font-medium text-zinc-100 mb-4">
                Event Type Distribution
              </h3>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart 
                    data={getEventTypeCounts()} 
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                    <XAxis 
                      dataKey="name" 
                      tick={{ fill: '#9ca3af' }}
                      angle={-45}
                      textAnchor="end"
                      height={80}
                    />
                    <YAxis tick={{ fill: '#9ca3af' }} />
                    <Tooltip 
                      contentStyle={{ backgroundColor: '#27272a', border: 'none', borderRadius: '4px', color: '#e4e4e7' }} 
                      labelStyle={{ color: '#e4e4e7' }}
                    />
                    <Legend wrapperStyle={{ color: '#e4e4e7' }} />
                    <Bar dataKey="count" name="Event Count" fill="#8884d8">
                      {getEventTypeCounts().map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Donut Chart */}
            <div className="bg-black/20 p-4 rounded-lg shadow">
              <h3 className="text-lg font-medium text-zinc-100 mb-4">
                Activity Distribution by User
              </h3>
              <div className="h-[400px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={userActivityCounts}
                      cx="50%"
                      cy="50%"
                      labelLine={true}
                      outerRadius={120}
                      innerRadius={60}
                      fill="#8884d8"
                      dataKey="count"
                      nameKey="userId"
                      label={({ userId, count, percent }) => `${userId}: ${count} (${(percent * 100).toFixed(0)}%)`}
                    >
                      {userActivityCounts.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip 
                      contentStyle={{ backgroundColor: '#27272a', border: 'none', borderRadius: '4px', color: '#e4e4e7' }}
                      formatter={(value, name, props) => [`${value} activities`, `User: ${props.payload.userId}`]}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Daily Story Generation Activity */}
            <div className="bg-black/20 p-4 rounded-lg shadow mt-6">
              <h3 className="text-lg font-medium text-zinc-100 mb-4">
                Daily Story Generation Activity
              </h3>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={prepareGenStoryData()}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                    <XAxis 
                      dataKey="date"
                      tick={{ fill: '#9ca3af' }}
                      tickFormatter={(dateStr) => {
                        const date = new Date(dateStr);
                        return `${date.getMonth()+1}/${date.getDate()}`;
                      }}
                    />
                    <YAxis tick={{ fill: '#9ca3af' }} />
                    <Tooltip 
                      contentStyle={{ backgroundColor: '#27272a', border: 'none', borderRadius: '4px', color: '#e4e4e7' }}
                      formatter={(value) => [`${value} stories generated`, 'Count']}
                      labelFormatter={(dateStr) => new Date(dateStr).toLocaleDateString()}
                    />
                    <Area 
                      type="monotone" 
                      dataKey="count" 
                      stroke="#8884d8" 
                      fill="#8884d8" 
                      fillOpacity={0.6}
                      name="Stories Generated"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              {prepareGenStoryData().length === 0 && (
                <div className="text-center text-zinc-400 italic mt-4">
                  No story generation data found in the selected time period
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityDashboard; 