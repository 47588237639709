import React, { useEffect, useState } from 'react';
import { UserAuth } from '../context/AuthContext';
import { app } from '../firebase';

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
// import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, ScatterChart, Scatter, CartesianGrid, Tooltip, Legend, LineChart, Line, PieChart, Pie, Cell, ComposedChart } from 'recharts';
import ActivityDashboard from './ActivityDashboard';
import AccessCode from './AccessCode';  

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import RagDemo from '../components/rag/RagDemo';

const Account = () => {
  const { user } = UserAuth()

  useEffect(() => {
    //console.log('user', user)

    callFirebaseFunction();
  }, [user])

  const [userListResponse, setUserListResponse] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('code');

  const functions = getFunctions(app);

  const callFirebaseFunction = async () => {
    setIsLoading(true);
    try {
      // console.log('calling getUsersCall')
      // console.log('user', user)
      const getUsersCall = httpsCallable(functions, "orgGetUsers"); //orgGetUsers
      const resp = await getUsersCall();
      setUserListResponse(resp.data)
      //console.log('userListResponse', userListResponse)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen flex flex-col">

      <div className="md:flex md:items-center md:justify-between ">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-2xl mb-4 sm:tracking-tight">
            Account
          </h2>
        </div>
      </div>
      <div className="px-4 sm:px-0 flex">
        {/* <h3 className="text-base/7 font-semibold text-zinc-300">Applicant Information</h3> */}

        <img src={user?.photoURL} referrerPolicy="no-referrer"
          alt=""
          className='rounded-lg mr-4' />
        <div>
          <div className='text-zinc-100 text-lg'>{user?.displayName}</div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-zinc-300">Authentication Provider</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">
              {user?.providerData?.[0]?.providerId}
            </dd>
          </div>
        </div>
      </div>
      {/* <div className="mt-6 border-t border-white/10">
        <dl className="divide-y divide-white/10">
          
        </dl>
        <dl className="divide-y divide-white/10">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-zinc-300">Organization(s)</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{0}</dd>
          </div>
        </dl>
      </div> */}




      {user?.email === "john@storypath.ai" || user?.email === "doug@storypath.ai" ? (
        <>
          <div className="mt-8">
            <div className="border-b border-gray-700">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {[
                  { name: 'Code Management', component: 'code' },
                  { name: 'Activity Dashboard', component: 'activity' },
                  { name: 'RAG Demo', component: 'rag' }
                ].map((tab) => {
                  const isActive = activeTab === tab.component;
                  return (
                    <button
                      key={tab.name}
                      onClick={() => setActiveTab(tab.component)}
                      className={`
                        whitespace-nowrap py-4 px-1 border-b-2 font-medium text-base
                        ${isActive 
                          ? 'border-sky-500 text-sky-400' 
                          : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-400'}
                      `}
                      aria-current={isActive ? 'page' : undefined}
                    >
                      {tab.name}
                    </button>
                  );
                })}
              </nav>
            </div>
            
            <div className="mt-4">
              {activeTab === 'code' && <AccessCode />}
              {activeTab === 'activity' && <ActivityDashboard />}
              {activeTab === 'rag' && <RagDemo />}
            </div>
          </div>
        </>
      ) : null}





      <div className="mt-14">
        <h3 className="text-base/7 font-semibold text-zinc-300">
          Users in Your Organization ({isLoading ? '...' : userListResponse?.length || 0})
        </h3>
      </div>

      <div className="mt-4 flow-root flex-1 min-h-0">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 h-full">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 h-full">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg h-full">
              <div className="max-h-[600px] overflow-y-auto">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead className="sticky top-0 bg-zinc-900">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-300 sm:pl-6 w-1/4">User</th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-300">Email</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-zinc-800 bg-zinc-900">
                    {isLoading ? (
                      <tr>
                        <td colSpan={2} className="p-4">
                          <SkeletonTheme baseColor="#27272a" highlightColor="#3f3f46">
                            <div className="space-y-4">
                              {[...Array(10)].map((_, i) => (
                                <Skeleton key={i} height={60} className="w-full rounded-md" />
                              ))}
                            </div>
                          </SkeletonTheme>
                        </td>
                      </tr>
                    ) : (
                      userListResponse?.map((user) => (
                        <tr key={user.uid}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                {user.photoURL ? (
                                  <img className="h-10 w-10 rounded-md" src={user.photoURL} alt="" referrerPolicy="no-referrer" />
                                ) : (
                                  <div className="h-10 w-10 rounded-md bg-gray-700 flex items-center justify-center">
                                    <span className="text-white font-bold ">{user.displayName?.[0] || user.email[0].toUpperCase()}</span>
                                  </div>
                                )}
                              </div>
                              <div className="ml-4">
                                <div className="font-medium text-zinc-100">{user.displayName || 'No name'}</div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                            {user.email}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>

  );
};

export default Account;
