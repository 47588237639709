import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { db } from '../firebase';
import {
    query,
    collection,
    onSnapshot,
    orderBy,
    where,
    addDoc,
    deleteDoc,
    serverTimestamp
} from 'firebase/firestore';
import { UserAuth } from '../context/AuthContext';
import { BarLoader } from 'react-spinners';
import { Menu, Transition, Listbox, Dialog } from '@headlessui/react';
import { toast } from 'react-hot-toast';
import StoryDetailEdit from "../components/StoryDetailEdit";
//import UserPermissions from "../components/UserPermissions";
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from 'react-loading-skeleton';
import KanbanBoard from './KanbanBoard';
import KanbanModal from './KanbanModal';
const StoryLibrary = () => {
    const { user } = UserAuth();
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [viewMode, setViewMode] = useState('netflix');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [storiesPerPage, setStoriesPerPage] = useState(8);
    const [visibilityFilter, setVisibilityFilter] = useState('all');
    const [sharingFilter, setSharingFilter] = useState('all');
    const [isKanbanModalOpen, setIsKanbanModalOpen] = useState(false);
    const [selectedStory, setSelectedStory] = useState(null);

    // Function to open the Kanban modal with a specific story
    const openKanbanModal = (story) => {
        setSelectedStory(story);
        setIsKanbanModalOpen(true);
    };

    const baseColor = '#18181b';
    const highlightColor = '#27272a';

    // Update storiesPerPage when viewMode changes
    useEffect(() => {
        if (viewMode === 'list') {
            setStoriesPerPage(10);
        } else if (viewMode === 'netflix') {
            setStoriesPerPage(3); // Display 3 stories per page for Netflix view
        } else {
            setStoriesPerPage(8); // For grid view
        }
        // Reset to first page when changing view modes
        setCurrentPage(1);
    }, [viewMode]);

    // Fetch stories data
    useEffect(() => {
        if (user?.uid) {
            const q = query(
                collection(db, 'stories'),
                orderBy('lastModified', 'desc'),
                where("role.owner", "array-contains", user?.uid)
            );

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                let storiesArr = [];
                querySnapshot.forEach((doc) => {
                    storiesArr.push({ ...doc.data(), id: doc.id });
                });
                // console.log('--- STORIES ARR ---')
                // console.log(storiesArr)
                setStories(storiesArr);
                setLoading(false);
            });

            return () => unsubscribe();
        }
    }, [user]);




    const storyClone = async (storyData) => {

        let cloneStoryData = storyData

        storyData['parentId'] = storyData.id
        storyData['name'] = storyData.name + " (copy)"

        // toast("Success - " + storyData['name'] + " has been copied",{
        //   transition: Slide
        // })

        // toast(
        //   "Success - " + storyData['name'] + " has been copied", {
        //   icon: '👏',
        //   style: {
        //     borderRadius: '10px',
        //     background: '#18181b',
        //     color: '#fff',
        //   },
        // })

        toast.custom((t) => (
            <div
                className={`${t.visible ? 'fadeInUp' : 'fadeOut'
                    } max-w-md w-full bg-zinc-900 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
                <div className="flex-1 w-0 p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0 pt-0.5">
                            <img src="/media/icon/noun-clone-2075584-FFFFFF.svg" className="w-5 opacity-100 inline mr-4" />
                        </div>
                        <div className="ml-3 flex-1">
                            <p className="text-sm font-medium text-gray-100">
                                {storyData['name']} has been copied
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div> */}
            </div>
        ))




        // Remove prior document id
        delete storyData.id

        // Reset analytics
        storyData['analyticsViewTotal'] = 0

        const docRef = await addDoc(collection(db, "stories"), storyData)
        //console.log(docRef.id)

        const q = query(collection(db, 'stories', storyData['parentId'], 'scenes'), orderBy("order"))

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let sceneArr = []
            querySnapshot.forEach((doc) => {
                sceneArr.push({ ...doc.data() })
                const docRefScenes = addDoc(collection(db, 'stories', docRef.id, 'scenes'), {
                    ...doc.data()
                })
                sceneArr = []
            })

            unsubscribe()
            // console.log('--- STORY ID & SCENE ARR ---')
            // console.log(docRef.id)
            // console.log(sceneArr)
        })

    }




    const CollabUserCrud = (props) => {
        const [userLoading, setUserLoading] = useState(false)
        const [userList, setUserList] = useState()

        const [storyId, setStoryId] = useState(props.storyId);
        const [userEmail, setUserEmail] = useState('');

        const storyName = props.storyName
        const storyDescription = props.storyDescription
        const senderName = props.senderName
        const senderPhoto = user?.photoURL


        // const [userLoading, setUserLoading] = useState(false)
//        const [userList, setUserList] = useState()
        const color = "#3b82f6" // Add this line to define the color variable
        
        // Also need to define override2 if it's being used
        const override2 = {
            display: "block",
            margin: "0 auto",
            borderColor: "red",
        }

        const handleFormSubmit = async () => {
            setUserLoading(true)
            try {
                const response = await fetch('https://us-central1-brevit3-57b00.cloudfunctions.net/updateUserRole', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ storyId, userEmail, storyName, storyDescription, senderName, senderPhoto }),
                });
                if (response.ok) {
                    console.log('Role updated successfully');
                    setUserLoading(false)
                    //setOpen(true)
                } else {
                    console.error('Failed to update role:', response.statusText);
                    setUserLoading(false)
                }
            } catch (error) {
                console.error('Error updating role:', error.message);
                setUserLoading(false)
            }
        };


        // collabRemoveUser const { storyId, uid} = req.body;
        const handleUserRemove = async (storyId, uid) => {
            //setUserLoading(true)

            console.log('/// SENDING!!!')
            console.log({ storyId: storyId, uid: uid })


            try {
                const response = await fetch('https://us-central1-brevit3-57b00.cloudfunctions.net/collabRemoveUser', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ storyId, uid }),
                });
                if (response.ok) {
                    console.log('Role updated successfully');
                    //setUserLoading(false)
                    //setOpen(true)
                } else {
                    console.error('Failed to update role:', response.statusText);
                    //setUserLoading(false)
                }
            } catch (error) {
                console.error('Error updating role:', error.message);
                //setUserLoading(false)
            }
        };


        useEffect(() => {
            async function getCollabUsers() {
                // console.log('Sending...')
                // console.log(props.story.role.owner)

                try {
                    const response = await fetch('https://us-central1-brevit3-57b00.cloudfunctions.net/getCollabUsers', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ ids: props.story.role.owner })
                    })

                    const userData = await response.json();
                    //          console.log(userData.collabusers)
                    setUserList(userData.collabusers)

                    if (response.ok) {
                        // console.log('Got users...')
                        // console.log(response)
                        // console.log(response.data)
                    } else {
                        //console.error('Failed to update role:', response.statusText);
                        //setUserLoading(false)
                    }
                } catch (error) {
                    //console.error('Error updating role:', error.message);
                    //setUserLoading(false)
                }
            }

            getCollabUsers()
        }, [])


        return (
            <div className="min-h-64">
                {userList ?
                    <div className="w-full mt-6">
                        {userList.filter(person => person.email != props.senderEmail).map((person) => (
                            <div key={person.email} className="w-full flex bg-black/20 rounded mb-2 py-4 text-gray-400">
                                <div className="flex px-3 text-sm">
                                    <img referrerPolicy="no-referrer" src={person.photoURL} className="h-10 rounded-md" />
                                </div>
                                <div className="w-1/4 px-3 text-left text-sm mt-2">{person.displayName}</div>
                                <div className="grow px-3 text-left text-sm mt-2">{person.email}</div>
                                <div className="flex-auto w-24 pl-3    pr-4">
                                    <div className="text-sky-400 hover:text-sky-300 p-2 px-3 bg-black/20 rounded-md w-fit float-right  cursor-pointer "
                                        onClick={() => handleUserRemove(props.storyId, person.uid)}
                                    >
                                        remove access
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <div className="w-full py-4">
                        <BarLoader
                            color={color}
                            //loading={userLoading}
                            cssOverride={override2}
                            size={450}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                }
                <div className="flex mt-8  pb-4">

                    {userLoading ?
                        <div className=" w-full">
                            <div className="text-center text-gray-100 text-xl bounce mb-2">
                                <img src="/util/app/b3-mail.svg" className="h-20 mx-auto animate__animated animate__fadeIn" />
                            </div>
                            <div className="text-center text-gray-300 mb-8">
                                Sending Invite
                            </div>
                            <BarLoader
                                color={color}
                                loading={userLoading}
                                cssOverride={override2}
                                size={450}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />

                        </div>
                        :
                        <div className="w-full">
                            <div className="flex w-full mt-12">
                                <div className="text-lg text-gray-100 ml-4">Invite</div>
                            </div>
                            <div className="flex w-full mt-4">
                                <input
                                    type="text"
                                    className="flex-auto rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                                  ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                    placeholder="Email Address"
                                    value={userEmail}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                />
                                <div

                                    className="flex-none justify-center rounded bg-sky-500 px-6 ml-4  
                         text-base font-base text-white shadow-sm hover:bg-sky-400 focus-visible:outline h-12 pt-3
                          focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 cursor-pointer "
                                    // onClick={() => {
                                    //   handleFormSubmit().then(() => setOpen(true))}
                                    // }

                                    onClick={() => handleFormSubmit()}

                                // onClick={(ev) => {
                                //   ev.stopPropagation();
                                //   //handleFormSubmit();
                                //   //popSample()
                                // }}

                                >
                                    Add
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }




    const UserPermissions = (props) => {

        const [open, setOpen] = useState(false)

        useEffect(() => {
            if (props.story) {
                setOpen(true)
            }
            else {
                setOpen(false)
            }
        }, [props.story])

        const closePerms = () => {
            // Clear the current story
            setStoryActivePerm(null)
            setOpen(false)
        }

        return (
            <>
                {/* <span hover-tooltip="Collaboration" tooltip-position="bottom"
                onClick={() => { setOpen(true) }}
              >
                <img src="/util/app/b3-users.svg" className="w-5 h-4 mt-3 opacity-60 hover:opacity-100" />
              </span> */}

                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closePerms}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 -mt-20 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-zinc-900 px-4 pt-2 text-left
                   shadow-xl transition-all sm:my-2 sm:w-1/2 sm:w-none m-40 sm:p-12">
                                        <div className="text-left">
                                            <Dialog.Title as="h3" className="text-3xl leading-6 text-gray-100">
                                                Collaboration Settings
                                            </Dialog.Title>
                                        </div>
                                        <div className="absolute right-4 -top-4 hidden  h-fit pr-4 pt-4 sm:block w-10">
                                            <button
                                                type="button"
                                                className="rounded-md   focus:outline-none  "
                                                onClick={() =>
                                                    //setOpen(false)
                                                    closePerms()
                                                }
                                            >
                                                <span className="sr-only">Close</span>
                                                <img src={'/media/icon/noun-close-fff.svg'} className=' opacity-50 hover:opacity-80 absolute hover:scale-105 ' />
                                            </button>
                                        </div>
                                        {props.story ?
                                            <div className="mx-auto max-w-7xl">
                                                <div className="py-4">
                                                    <div className="mt-8 flow-root">
                                                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                                <div className="flex text-gray-300 w-full mb-12">
                                                                    <div className="flex mr-6">
                                                                        <img
                                                                            className="rounded-md h-18"
                                                                            referrerPolicy="no-referrer"
                                                                            src={props.story.userPhotoURL} />
                                                                    </div>
                                                                    <div className="flex-auto">
                                                                        <div className="text-lg text-gray-200">Story Owner</div>
                                                                        <div className="text-xl">{props.story.userDisplayName}</div>
                                                                        <div className="text-sm mt-2">{props.story.userEmail}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="min-w-full   text-gray-200">
                                                                    The following users will have access to edit this story
                                                                </div>

                                                                <CollabUserCrud
                                                                    storyName={props.storyName}
                                                                    storyDescription={props.storyDescription}
                                                                    senderName={props.senderName}
                                                                    senderEmail={props.story.userEmail}
                                                                    senderPhoto={user?.photoURL}
                                                                    story={props.story}
                                                                    storyId={props.storyId}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root >
            </>
        )
        //}
    }



    const [storyActiveEdit, setStoryActiveEdit] = useState()
    const [storyActivePerm, setStoryActivePerm] = useState()


    const StoryMenu = ({ story, setStoryActiveEdit, setStoryActivePerm, storyClone }) => {
        return (
            <Menu as="div" className="relative inline-block text-left -mt-20">
                <Menu.Button
                    className="button rounded hover:bg-white/5 w-10 h-10 p-1"
                >
                    <img src="/util/app/b3-admin-menu-more.svg" className="w-full" />
                </Menu.Button>

                <Menu.Items
                    unmount={false}
                    className="absolute right-0
                animate__animated animate__fadeIn 
                w-64 rounded-md bg-zinc-900 text-gray-300 z-10
                shadow shadow-black/50 p-3"
                    style={{ '--animate-duration': '.25s' }}
                >
                    <Menu.Item onClick={() => {
                        setStoryActiveEdit(story)
                        setStoryActivePerm(null)
                    }}>
                        <span className='text-gray-400 hover:text-white cursor-pointer hover:bg-gray-800 block rounded-md p-3 text-sm leading-6 font-semibold'>
                            <img src="/media/icon/noun-edit-1918366-FFFFFF.svg" className="w-5 opacity-80 inline mr-4" />
                            Edit Details &amp; Visibility
                        </span>
                    </Menu.Item>

                    <Menu.Item onClick={() => storyClone(story)}>
                        <span className={'text-gray-400 hover:text-white cursor-pointer hover:bg-gray-800 block rounded-md p-3 text-sm leading-6 font-semibold'}>
                            <img src="/media/icon/noun-clone-2075584-FFFFFF.svg" className="w-5 opacity-80 inline mr-4" />
                            Make a Copy
                        </span>
                    </Menu.Item>

                    <Menu.Item>
                        <span className={'text-gray-400 hover:text-white cursor-pointer hover:bg-gray-800 block rounded-md p-3 text-sm leading-6 font-semibold'}
                            onClick={() => {
                                setStoryActivePerm(story)
                                setStoryActiveEdit(null)
                            }}
                        >
                            <img src="/util/app/b3-users.svg" className="w-5 opacity-80 inline mr-4" />
                            Collaborators
                        </span>
                    </Menu.Item>
                </Menu.Items>
            </Menu>
        );
    };



    const StoryCard = ({ story }) => {
        return (
            <motion.div
                whileHover={{
                    // scale: 1.01,
                    // y: 0,
                    // transition: { duration: 0.2 }
                }}
                className="  rounded-lg   hover:bg-black/20 bg-black/10 transition-all duration-300  p-3 cursor-pointer group"
            >

                <div className="aspect-video bg-zinc-800 relative rounded-lg  overflow-hidden  ">



                    <div className={`     ${story.backgroundSky}`}>
                        <Link
                            to={`/app/stories/${story.id}/edit/0`}
                            key={story.id}
                            className="  ">
                            <div className={`bg-story-thumbnail transition-all duration-300 p-4  bg-black/20 rounded-lg relative  aspect-video overflow-hidden `}
                                style={{
                                    backgroundImage: 'url(' + story.previewThumb.replace('/upload', '/upload/ar_16:9,c_fit,w_900/') + ')',
                                    backgroundSize: 'cover',
                                    // backgroundPosition: 'center',
                                }}
                            >

                            </div> </Link>
                        {/* <img src={story.previewThumb.replace('/upload', '/upload/ar_16:9,c_fit,w_900/')} className="w-full aspect-auto rounded-tr rounded-tl" /> */}
                        <div className="left-2 top-2 absolute px-2 py-1 bg-zinc-800 rounded-md text-xs">
                            {story.visibility == 'public' ?
                                <div className="text-amber-500">
                                    public
                                </div>
                                :
                                <div className="text-zinc-300 ">
                                    private
                                </div>
                            }
                        </div>
                    </div>




                </div>

                <div className="p-4">
                    <div className="flex gap-3">
                        {/* Story Avatar */}
                        <div className="w-9 h-9 rounded-full bg-zinc-800 flex items-center justify-center flex-shrink-0">
                            <img referrerPolicy="no-referrer" src={story.userPhotoURL} className="w-full mt-1 rounded" />
                        </div>

                        {/* Story Details */}
                        <div className="flex-1 min-w-0">
                            <Link
                                to={`/app/stories/${story.id}/edit/0`}
                                key={story.id}
                                className="  ">
                                <h3 className="text-zinc-100 font-medium text-sm mb-1 truncate group-hover:text-zinc-50 transition-colors duration-300">
                                    {story.name}
                                </h3>
                                <p className="text-zinc-400 text-xs truncate">
                                    {story.description || 'No description available'}
                                </p>
                            </Link>
                            <div className="flex items-center gap-2 mt-2 text-zinc-500 text-xs">
                                <span>
                                    {story.createdAt && format(story.createdAt.toDate(), 'MMM d, yyyy')}
                                </span>

                                <div className="flex-grow"></div>
                                <StoryMenu
                                    story={story}
                                    setStoryActiveEdit={setStoryActiveEdit}
                                    setStoryActivePerm={setStoryActivePerm}
                                    storyClone={storyClone}
                                />


                            </div>
                        </div>
                    </div>
                </div>

            </motion.div>
        );
    };

    // Filter stories based on search
    const filteredStories = stories.filter(story => {
        // Search filter
        const matchesSearch = story.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            story.description?.toLowerCase().includes(searchQuery.toLowerCase());

        // Visibility filter
        const matchesVisibility = visibilityFilter === 'all' || story.visibility === visibilityFilter;

        // Sharing filter
        const isShared = story.role && (
            (Array.isArray(story.role.owner) && story.role.owner.length > 1) ||
            (Array.isArray(story.role.commentor) && story.role.commentor.length > 0) ||
            (Array.isArray(story.role.reader) && story.role.reader.length > 0) ||
            (Array.isArray(story.role.writer) && story.role.writer.length > 0)
        );
        const matchesSharing = sharingFilter === 'all' ||
            (sharingFilter === 'shared' && isShared) ||
            (sharingFilter === 'private' && !isShared);

        return matchesSearch && matchesVisibility && matchesSharing;
    });

    // Calculate pagination
    const indexOfLastStory = currentPage * storiesPerPage;
    const indexOfFirstStory = indexOfLastStory - storiesPerPage;
    const currentStories = filteredStories.slice(indexOfFirstStory, indexOfLastStory);
    const totalPages = Math.ceil(filteredStories.length / storiesPerPage);

    const ListViewItem = ({ story }) => (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="bg-black/20 hover:bg-black/20 rounded-lg  transition-all duration-200 p-4"
        >

            <Link to={`/app/stories/${story.id}/edit/0`} className="flex items-center gap-4">
                {/* <div className="w-16 h-16  rounded-lg flex-shrink-0 flex items-center justify-center">
          {story.previewImage ? (
            <img src={story.previewImage} alt={story.name} className="w-full h-full object-cover rounded-lg" />
          ) : (
            <img src="/util/app/b3-new-story-template.svg" className="h-8 w-8 opacity-50" alt="Story template" />
          )}
        </div> */}

                <div className={`overflow-hidden  aspect-video  h-24  rounded-md  ${story.backgroundSky}`}>
                    <div className={`bg-story-thumbnail p-4 bg-black/30 rounded-md duration-300 relative  aspect-video overflow-hidden `}
                        style={{
                            backgroundImage: 'url(' + story.previewThumb.replace('/upload', '/upload/ar_16:9,c_fit,w_900/') + ')',
                            backgroundSize: 'cover',
                            // backgroundPosition: 'center',
                        }}
                    >

                    </div>
                    {/* <img src={story.previewThumb.replace('/upload', '/upload/ar_16:9,c_fit,w_900/')} className="w-full aspect-auto rounded-tr rounded-tl" /> */}
                    <div className="  px-2 py-1 bg-zinc-800 rounded-md text-xs">
                        {story.visibility == 'public' ?
                            <div className="text-amber-500">
                                public
                            </div>
                            :
                            <div className="text-zinc-300 ">
                                private
                            </div>
                        }
                    </div>
                </div>

                <div className="flex-1 min-w-0">
                    <h3 className="text-zinc-100  text-lg mb-1">{story.name}</h3>
                    <p className="text-zinc-400 text-sm">{story.description || 'No description available'}</p>
                    <div className="flex items-center gap-2 mt-2 text-zinc-500 text-xs">
                        <span>{story.createdAt && format(story.createdAt.toDate(), 'MMM d, yyyy')}</span>
                    </div>
                </div>
            </Link>
            <div className="relative  w-full flex justify-end">
                <StoryMenu
                    story={story}
                    setStoryActiveEdit={setStoryActiveEdit}
                    setStoryActivePerm={setStoryActivePerm}
                    storyClone={storyClone}
                />
            </div>
        </motion.div>
    );

    // Add new NetflixCard component
    const NetflixCard = ({ story }) => {
        // Check if the story is a "tell" type
        if (story.type === "tell") {
            // Parse JSON data if available
            let producerData = {};
            let businessData = {};

            try {
                if (story.producerResult) {
                    producerData = JSON.parse(story.producerResult);
                }
                if (story.businessAnalysis) {
                    businessData = JSON.parse(story.businessAnalysis);
                }
            } catch (e) {
                console.error("Error parsing JSON data", e);
            }

            // Extract title and summary if available
            const title = producerData.title?.title || story.name;
            const summary = producerData.opening?.summary || story.description;
            const challenges = Array.isArray(businessData.challenges) ? businessData.challenges : [];

            return (
                <div className="relative">
                    <motion.div
                        whileHover={{ scale: 1.0075 }}
                        className="relative"
                    >

                        <div className={`w-full h-full aspect-square rounded-lg overflow-hidden`}>
                            <div
                                className="w-full h-full bg-cover bg-center relative flex flex-col  bg-black/10"
                                
                            >
                                {/* Gradient overlay */}
                                <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-black/25 to-transparent" />
                                <div className="absolute inset-0  
                                opacity-10
                                brightness-100
                                contrast-[1.5]
                                saturate-100
                                hue-rotate-0
                                pointer-events-none "
                                
                                style={{
                                    backgroundImage: `url(${story.previewThumb})`,
                                    backgroundSize: 'cover',
                                    //backgroundPosition: 'center',
                                }}
                                />

                                {/* <div className="absolute inset-0 bg-black/20 pointer-events-none" /> */}

                                {/* Top section with avatar and buttons */}
                                <div className="relative z-10 flex justify-between">
                                    <div className='m-4'>
                                        <img
                                            referrerPolicy="no-referrer"
                                            src={story.userPhotoURL}
                                            className="w-14 h-14 rounded-lg"
                                            alt="Author"
                                        />
                                    </div>
                                    <div className="m-4 content-start flex flex-col items-end">
                                        {story.visibility === 'public' ? (
                                            <div className="text-amber-500 px-2 py-1 bg-black/50 h-6 rounded text-xs">public</div>
                                        ) : (
                                            <div className="text-zinc-300 px-2 py-1 bg-black/50 h-6 rounded text-xs">private</div>
                                        )}
                                        <div className="text-zinc-100 text-sm pt-4 opacity-80 text-right">
                                            {story.createdAt && format(story.createdAt.toDate(), 'MMM d, yyyy')}
                                        </div>
                                    </div>
                                </div>

                                {/* Middle content section */}
                                <div className="relative z-10 flex-grow flex mt-20 px-8 drop-shadow-lg">
                                    <div className="w-full">
                                        <h3 className="text-zinc-50 text-3xl w-full">{JSON.parse(story.formData).promptCustomerName}, <span className="text-zinc-50 text-lg w-full">{JSON.parse(story.formData).promptCustomerRole}</span></h3>

                                        <p className="text-zinc-50 text-md w-full mt-2">{JSON.parse(story.formData).promptProductName}</p>
                                        <p className="text-zinc-400 mt-4 text-md w-full">{JSON.parse(story.formData).promptCustomerProblem}</p>
                                    </div>
                                </div>

                                {/* Bottom section with date and description */}
                                <div className="relative z-10 p-4">
                                    <div className="flex justify-between items-end pb-8">
                                        <div className="flex-grow">
                                            {/* <p className="text-zinc-300 text-sm opacity-80">
                                                {story.createdAt && format(story.createdAt.toDate(), 'MMM d, yyyy')}
                                            </p> */}
                                            {/* <p className="text-zinc-300 text-sm line-clamp-2 mt-2">
                                                {story.description || 'No description available'}
                                            </p> */}
                                        </div>

                                    </div>

                                </div>
                                <div className="flex w-full gap-x-0.5 h-12 z-10 rounded-b-lg bg-zinc-900 absolute bottom-0 left-0 right-0 ">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            openKanbanModal(story);
                                        }}
                                        className="px-4 py-1 bg-zinc-900 hover:bg-white/5  text-white rounded-bl-md transition-colors duration-200 w-full cursor-pointer"
                                    >
                                        Open StoryPaths

                                    </button>

                                    <Link
                                        to={`/app/stories/${story.id}/edit/0`}
                                        className="px-4 py-1 bg-black/20 hover:bg-white/5 text-white rounded-br-md transition-colors duration-200 inline-flex items-center justify-center w-full cursor-pointer"
                                    >
                                        Open in Theater
                                    </Link>
                                </div> 

                            </div>

                        </div>

                    </motion.div>
                    <div className="relative mt-24 -mb-12 w-full flex justify-end">
                        <StoryMenu
                            story={story}
                            setStoryActiveEdit={setStoryActiveEdit}
                            setStoryActivePerm={setStoryActivePerm}
                            storyClone={storyClone}
                        />
                    </div>
                </div>
            );
        }

        // Original Netflix card for non-tell type stories
        return (
            <div className="relative">
                <motion.div
                    whileHover={{ scale: 1.0075 }}
                    className="relative"
                >
                    {/* <Link to={`/app/stories/${story.id}/edit/0`}> */}
                        <div className={`w-full h-full ${story.backgroundSky}  aspect-square rounded-lg overflow-hidden cursor-pointer`}>
                            <div
                                className="w-full h-full bg-cover bg-center relative"
                                style={{
                                    backgroundImage: `url(${story.previewThumb.replace('/upload', '/upload/ar_1:1,c_fill,w_1200/')})`,
                                }}
                            >
                                <div className="absolute inset-0 bg-gradient-to-t from-black via-black/10 to-black/0 pointer-events-none" /> 
                                <div className="relative z-10 flex justify-between">
                                    <div className='m-4'>
                                        <img
                                            referrerPolicy="no-referrer"
                                            src={story.userPhotoURL}
                                            className="w-14 h-14 rounded-lg"
                                            alt="Author"
                                        />
                                    </div>
                                    <div className="m-4 content-start flex flex-col items-end">
                                        {story.visibility === 'public' ? (
                                            <div className="text-amber-500 px-2 py-1 bg-black/50 h-6 rounded text-xs">public</div>
                                        ) : (
                                            <div className="text-zinc-300 px-2 py-1 bg-black/50 h-6 rounded text-xs">private</div>
                                        )}
                                        <div className="text-zinc-100 text-sm pt-4 opacity-80 text-right">
                                            {story.createdAt && format(story.createdAt.toDate(), 'MMM d, yyyy')}
                                        </div>
                                    </div>
                                </div>

                                

                                {/* Bottom section with date and description */}
                                <div className="absolute bottom-0 left-0 right-0 z-10 p-8">
                                    <div className="flex justify-between items-end pb-8">
                                        <div className="flex-grow">
                                            <p className="text-zinc-100 xs:text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl 2xl:text-3xl font-medium truncate group-hover:text-zinc-50 transition-colors duration-300">
                                                {/* {story.createdAt && format(story.createdAt.toDate(), 'MMM d, yyyy')} */}
                                                {story.name}
                                            </p>
                                            <p className="text-zinc-200  line-clamp-2 mt-1">
                                                {story.description || 'No description available'}
                                            </p>
                                        </div>

                                    </div>

                                </div>
                                <div className="flex w-full gap-x-0.5 h-12 z-10 rounded-b-lg bg-zinc-900 absolute bottom-0 left-0 right-0">
                                    {/* <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            openKanbanModal(story);
                                        }}
                                        className="px-4 py-1 bg-black/20 hover:bg-white/5 text-white rounded-bl-md transition-colors duration-200 w-full cursor-pointer"
                                    >
                                        Open StoryPaths

                                    </button> */}

                                    <Link
                                        to={`/app/stories/${story.id}/edit/0`}
                                        className="px-4 py-1 bg-black/20 hover:bg-white/5 text-white rounded-br-md transition-colors duration-200 inline-flex items-center justify-center w-full cursor-pointer"
                                    >
                                        Open in Theater
                                    </Link>
                                </div> 
                            </div>
                        </div>
                    {/* </Link> */}
                </motion.div>
                <div className="relative mt-24 -mb-12 w-full flex justify-end">
                    <StoryMenu
                        story={story}
                        setStoryActiveEdit={setStoryActiveEdit}
                        setStoryActivePerm={setStoryActivePerm}
                        storyClone={storyClone}
                    />
                </div>
            </div>
        );
    };

    // Add the new PaginationControls component
    const PaginationControls = ({ currentPage, totalPages, setCurrentPage }) => {
        return (
            <div className="flex items-center w-full justify-between gap-2">
                {/* Previous button */}
                <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className={`px-3 py-2 rounded-md ${currentPage === 1
                        ? 'bg-black/10 text-zinc-600 bg-zinc-900'
                        : 'bg-black/20 text-zinc-400 hover:bg-zinc-800'
                        }`}
                >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                </button>

                {/* Centered page numbers */}
                <div className="flex items-center gap-2">
                    {(() => {
                        let pages = [];
                        const maxVisible = 5;
                        let start = Math.max(1, Math.min(currentPage - Math.floor(maxVisible / 2), totalPages - maxVisible + 1));
                        let end = Math.min(start + maxVisible - 1, totalPages);

                        if (start > 1) {
                            pages.push(1);
                            if (start > 2) pages.push('...');
                        }

                        for (let i = start; i <= end; i++) {
                            pages.push(i);
                        }

                        if (end < totalPages) {
                            if (end < totalPages - 1) pages.push('...');
                            pages.push(totalPages);
                        }

                        return pages.map((page, index) => (
                            typeof page === 'number' ? (
                                <button
                                    key={index}
                                    onClick={() => setCurrentPage(page)}
                                    className={`px-4 py-2 rounded-md ${currentPage === page
                                        ? 'bg-sky-500 text-white'
                                        : 'bg-zinc-900 text-zinc-400 hover:bg-zinc-800'
                                        }`}
                                >
                                    {page}
                                </button>
                            ) : (
                                <span key={index} className="text-zinc-400">
                                    {page}
                                </span>
                            )
                        ));
                    })()}
                </div>

                {/* Next button */}
                {/* <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className={`px-3 py-2 rounded-md ${currentPage === totalPages
                        ? 'bg-sky-500 text-white'
                        : 'bg-zinc-900 text-zinc-400 hover:bg-zinc-800'
                        }`}
                >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                </button> */}
                <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className={`px-3 py-2 rounded-md ${currentPage === totalPages
                        ? 'bg-black/10 text-zinc-600 bg-zinc-900'
                        : 'bg-black/20 text-zinc-400 hover:bg-zinc-800'
                        }`}
                >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>
        );
    };

    // if (loading) {
    //     return (
    //         <div className="min-h-screen flex items-center justify-center">
    //             <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-sky-500"></div>
    //         </div>
    //     );
    // }

    return (
        <div className="  mx-auto h-[calc(100vh-210px)] mt-0">
            <div className="mb-6 flex w-full mx-auto items-center gap-4     rounded-lg">
                <div className="relative flex-grow rounded-lg">

                    <input
                        type="text"
                        placeholder="Search stories..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="inline-flex w-full p-3 pl-4 bg-zinc-600/25 text-zinc-50 rounded-md border border-zinc-600 focus:border-sky-500 focus:ring-1 focus:ring-sky-500 [&:-webkit-autofill]:bg-zinc-600/25 [&:-webkit-autofill]:!text-zinc-50 [&:-webkit-autofill]:shadow-[0_0_0_30px_rgb(82_82_91_/_0.25)_inset] [&:-webkit-autofill]:[filter:none]"
                    />

                    <svg
                        className="absolute right-4 top-3.5 h-5 w-5 text-zinc-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </div>

                <div className="ml-6 flex items-center gap-2 text-sm text-zinc-400">
                    <div className="text-zinc-400 inline-block mr-2">Filter</div>

                    <div className="inline-flex rounded-md" role="group">
                        <button
                            onClick={() => setVisibilityFilter('all')}
                            className={`px-3 py-3 rounded-l-lg ${visibilityFilter === 'all'
                                ? 'bg-sky-500 text-white'
                                : 'bg-zinc-900   text-zinc-400 hover:bg-zinc-800'
                                } border-r-0`}
                        >
                            All
                        </button>
                        <button
                            onClick={() => setVisibilityFilter('public')}
                            className={`px-3 py-3 ${visibilityFilter === 'public'
                                ? 'bg-sky-500 text-white'
                                : 'bg-zinc-900   text-zinc-400 hover:bg-zinc-800'
                                } border-r-0`}
                        >
                            Public
                        </button>
                        <button
                            onClick={() => setVisibilityFilter('private')}
                            className={`px-3 py-3 rounded-r-lg ${visibilityFilter === 'private'
                                ? 'bg-sky-500 text-white'
                                : 'bg-zinc-900 text-zinc-400 hover:bg-zinc-800'
                                }`}
                        >
                            Private
                        </button>
                    </div>

                    {/* <select
                        value={sharingFilter}
                        onChange={(e) => setSharingFilter(e.target.value)}
                        className="bg-zinc-900 border border-zinc-800 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-sky-500"
                    >
                        <option value="all">All Sharing</option>
                        <option value="shared">Shared</option>
                        <option value="private">Not Shared</option>
                    </select> */}
                </div>

                <div className="ml-6 flex items-center gap-2 flex-shrink-0 ">
                    <div className="text-zinc-400 inline-block mr-2">Display As</div>
                    <div className="inline-flex rounded-md" role="group">
                        <button
                            onClick={() => setViewMode('netflix')}
                            className={`px-3 py-3 rounded-l-lg ${viewMode === 'netflix'
                                ? 'bg-sky-500 text-white'
                                : 'bg-zinc-900 text-zinc-400 hover:bg-zinc-800'
                                } border-r-0`}
                        >
                            <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
                            </svg>
                        </button>
                        <button
                            onClick={() => setViewMode('grid')}
                            className={`px-3 py-3 ${viewMode === 'grid'
                                ? 'bg-sky-500 text-white'
                                : 'bg-zinc-900 text-zinc-400 hover:bg-zinc-800'
                                } border-r-0`}
                        >
                            <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                            </svg>
                        </button>
                        <button
                            onClick={() => setViewMode('list')}
                            className={`px-3 py-3 rounded-r-lg ${viewMode === 'list'
                                ? 'bg-sky-500 text-white'
                                : 'bg-zinc-900 text-zinc-400 hover:bg-zinc-800'
                                }`}
                        >
                            <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <KanbanModal
                isOpen={isKanbanModalOpen}
                onClose={() => setIsKanbanModalOpen(false)}
                story={selectedStory}
            />


            <div className="space-y-4 p-4 bg-black/10 rounded-lg">

                <div className="flex justify-between items-center">
                    <h2 className="text-lg text-zinc-100">Recent Stories</h2>
                    <div className="text-zinc-200 text-sm">
                        Showing {indexOfFirstStory + 1}-{Math.min(indexOfLastStory, filteredStories.length)} of {filteredStories.length} stories
                    </div>
                </div>


                {loading && (
                    <div className="grid grid-cols-3  gap-4">
                        <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
                            <Skeleton className="aspect-square mb-4 rounded-lg" count={1} />
                            <Skeleton className="aspect-square mb-4 rounded-lg" count={1} />
                            <Skeleton className="aspect-square mb-4 rounded-lg" count={1} />
                        </SkeletonTheme>
                    </div>
                )}

                {/* <AnimatePresence mode="wait"> */}
                {filteredStories.length > 0 && !loading ? (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 0.25,
                            ease: "easeOut"
                        }}
                    >
                        {viewMode === 'grid' ? (
                            <>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                                    {currentStories.map((story) => (
                                        <StoryCard key={story.id} story={story} />
                                    ))}
                                </div>
                            </>
                        ) : viewMode === 'list' ? (

                            <>
                                {/* <div className="text-zinc-200 text-sm pl-4">
                                    Showing {indexOfFirstStory + 1}-{Math.min(indexOfLastStory, filteredStories.length)} of {filteredStories.length} stories
                                </div> */}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {currentStories.map((story) => (
                                        <ListViewItem key={story.id} story={story} />
                                    ))}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="space-y-4 mb-12">
                                    {/* <div className="flex justify-between items-center">
                                        <h2 className="text-lg text-zinc-100">Recent Stories</h2>
                                        <div className="text-zinc-200 text-sm">
                                            Showing {indexOfFirstStory + 1}-{Math.min(indexOfLastStory, filteredStories.length)} of {filteredStories.length} stories
                                        </div>
                                    </div> */}
                                    {/* <div className="grid grid-cols-3 gap-6">
                                        {currentStories.slice(0, 3).map((story) => (
                                            <NetflixCard key={story.id} story={story} />
                                        ))}
                                    </div> */}
                                    <div className="grid grid-cols-3 gap-4">
                                        {currentStories.slice(0, 3).map((story) => (
                                            <NetflixCard key={story.id} story={story} />
                                        ))}
                                    </div>
                                </div>
                                <PaginationControls
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    setCurrentPage={setCurrentPage}
                                />
                            </>
                        )}
                    </motion.div>
                ) : (
                    <>
                        {!loading ?
                            <div className="flex flex-col items-center justify-center py-16 px-4 rounded-lg text-center">
                                <img
                                    src="/util/app/b3-admin-story-5.svg"
                                    alt="No stories"
                                    className="w-24 h-24 opacity-50 mb-6"
                                />
                                <h3 className="text-lg text-zinc-50 mb-2">No Stories Found</h3>
                                <p className="text-zinc-400 max-w-md mb-6">
                                    {searchQuery ?
                                        `We couldn't find any stories matching "${searchQuery}". Try adjusting your search criteria.` :
                                        "You don't have any Stories yet or none match your filters. Change your filter or create your first Story to get started."}
                                </p>
                                <a
                                    href="/app/storyboard"
                                    className="px-5 py-3 bg-sky-500 hover:bg-sky-400 text-white rounded-md transition-colors duration-200 flex items-center gap-2"
                                >
                                    {/* <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg> */}
                                    Create Story
                                </a>
                            </div>
                            :
                            null
                        }
                    </>
                )}
                {/* </AnimatePresence> */}

                {/* Only show pagination if there are stories and we're not in Netflix view */}
                {filteredStories.length > 0 && totalPages > 1 && viewMode !== 'netflix' && (
                    <div className="mt-8 px-0 flex justify-between items-center">
                        {/* Story count and range */}
                        {/* <div className="text-zinc-200 text-sm pl-4">
                            Showing {indexOfFirstStory + 1}-{Math.min(indexOfLastStory, filteredStories.length)} of {filteredStories.length} stories
                        </div> */}

                        <PaginationControls
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                )}

                <StoryDetailEdit
                    story={storyActiveEdit}
                    linkOnly={0}
                    setStoryActiveEdit={setStoryActiveEdit}
                />

                <UserPermissions
                    storyId={storyActivePerm ? storyActivePerm.id : 0}
                    storyName={storyActivePerm ? storyActivePerm.name : ''}
                    storyDescription={storyActivePerm ? storyActivePerm.description : ''}
                    senderName={user.displayName}
                    story={storyActivePerm}
                />
            </div>
        </div>
    );

};

export default StoryLibrary; 