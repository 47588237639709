import React, { useRef } from 'react';
import { useReactToPrint } from "react-to-print";

const PathProductBusinessAnalysis = ({ businessAnalysisData, storySpeakerSummaryResult, producedResult, salesAnalysisData, businessTransformationResult, formData }) => {
    //console.log('PRODUCED RESULT ///////')
    //console.log(producedResult);
    //console.log('PRODUCED RESULT ///////')
    if (!businessAnalysisData) return null;




    const storySummaryRef = useRef(null);

    const handlePrint = useReactToPrint({
        //content: () => storySummaryRef.current,
        contentRef: storySummaryRef,
        documentTitle: 'StoryPath_' + (formData.promptCustomerName || 'Customer').replace(/ /g, '_') + '_Summary_Story_' + (producedResult?.title?.title || 'Product').replace(/ /g, '_') + '_' + new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' }),
        onBeforeGetContent: () => {
            if (!storySummaryRef.current) {
                throw new Error('Content not ready for printing');
            }
            //console.log('Preparing content for print...');
        },
        onAfterPrint: () => {
            //console.log('Printing complete');
        },
        onPrintError: (error) => {
            //console.error('Print failed:', error);
        }
    });

    return (
        <div className=" space-y-6 overflow-y-auto h-[calc(100vh-8rem)] pr-8 pt-6 mt-8 pb-20"

        >
            {storySpeakerSummaryResult && (
                <div ref={storySummaryRef}>
                    <div className='print:block hidden'>
                        <img
                            src="/util/app/StoryPath-Logo-Print.png"
                            className="h-8 absolute bottom-14 left-14"
                            alt="StoryPath Logo"
                        />
                    </div>
                    <div className="  flex   rounded-lg print:p-14">
                        <button
                            onClick={handlePrint}
                            className="bg-zinc-800 absolute -top-4 right-8  hover:bg-zinc-600 text-white px-2 py-2 rounded-lg transition-colors flex items-center gap-2 print:hidden"
                        >
                            <img src={'/util/app/b3-admin-export.svg'} className='h-5 w-5' />
                            <span>export</span>
                        </button>

                        <div className="w-1/2 pr-16 pl-8 print:pr-4 print:pl-4 print:pt-4">
                            <div className=' pb-4'>
                                <div className='text-zinc-100 font-medium text-4xl print:text-2xl'>{producedResult?.title?.title}</div>
                                <div className='text-zinc-200 font-medium text-xl mt-2 print:text-base'>{producedResult?.title?.subtitle}</div>
                            </div>

                            <div className=" pt-8 print:pt-4">
                                <div className="text-sky-400 text-xl font-medium print:text-base">
                                    Story Summary
                                </div>
                                {/* <div className='border-l border-sky-400 p-4 pl-8 pt-0 mt-8 '> */}
                                <div className=' pt-0 mt-4 '>
                                    {storySpeakerSummaryResult.opening && (
                                        <p className="text-zinc-300 text-lg leading-12 mt-6 print:text-sm">
                                            {storySpeakerSummaryResult.opening}
                                        </p>
                                    )}
                                    {storySpeakerSummaryResult.middle && (
                                        <p className="text-zinc-300  text-lg leading-12  mt-6 print:text-sm">
                                            {storySpeakerSummaryResult.middle}
                                        </p>
                                    )}
                                    {storySpeakerSummaryResult.closing && (
                                        <p className="text-zinc-300 text-lg leading-12  mt-6 print:text-sm">
                                            {storySpeakerSummaryResult.closing}
                                        </p>
                                    )}
                                </div>
                                {/* <div className='mt-8'>
                                <div className='text-zinc-200 text-xl font-medium'>
                                    Business Analysis
                                </div>
                            </div> */}
                            </div>



                        </div>

                        <div className="w-1/2 pl-8  mt-4 ">
                            {businessAnalysisData.risks && businessAnalysisData.metrics && businessAnalysisData.metrics.length > 0 && (
                                <div className="rounded  bg-black/20 h-full pb-12  rounded-lg p-6 print:p-6">
                                    <h3 className="text-green-400 font-medium text-2xl mb-4 print:text-xl">Business Impact <span className="ml-2 opacity-50 text-sm print:text-xs">(estimated)</span></h3>
                                    <div className="space-y-3">


                                        <div className="grid grid-cols-2 gap-4 print:gap-2">
                                            {businessAnalysisData.metrics.map((item, idx) => (
                                                <div key={idx} className="flex flex-col h-full">
                                                    <div className='flex flex-col h-full rounded-lg overflow-hidden'>
                                                        <div className="flex flex-col text-center p-4 h-28 print:h-20 bg-green-900/20">
                                                            {/* Numeric value row */}
                                                            <div className="text-zinc-100 text-5xl print:text-3xl items-baseline font-base flex mb-2 mx-auto">
                                                                <div className='flex items-baseline mr-2 h-12 w-8 print:h-6 print:w-6 '>
                                                                    {item.increaseOrDecrease && item.increaseOrDecrease.toLowerCase() === 'increase' ?
                                                                        <img src={'/util/app/b3-admin-arrow-up.svg'} className='h-8 print:h-6' /> :
                                                                        <img src={'/util/app/b3-admin-arrow-down.svg'} className='h-8 print:h-6 ' />}
                                                                </div>
                                                                <span>{item.numericValue}</span>
                                                                {item.numericValue ?
                                                                    <span className="text-lg ml-2 text-zinc-300">%</span>
                                                                    :
                                                                    <span className="text-lg ml-2 text-zinc-300"></span>
                                                                }
                                                            </div>
                                                            <div className='text-zinc-100 text-center print:text-xs text-sm'>
                                                                {item.attributeImpacted}
                                                            </div>
                                                        </div>
                                                        <div className="text-zinc-50 font-medium/8 bg-black/25 print:text-xs p-4 flex-grow text-left   print:p-6">{item.explanation}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>


                                    </div>

                                    <h4 className="text-green-400 font-medium text-2xl mb-4 mt-8 print:text-lg">Value Proposition</h4>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 ml-4">
                                        {/* Key Messages Column */}
                                        <div>
                                            <h4 className="text-zinc-100 font-medium mb-4 print:text-base">Key Messages</h4>
                                            <ul className="list-disc pl-4 space-y-4 print:text-xs">
                                                {salesAnalysisData?.valueProposition?.keyMessages?.map((message, idx) => (
                                                    <li key={idx} className="text-zinc-300">{message}</li>
                                                ))}
                                            </ul>
                                        </div>

                                        {/* Unique Value Column */}
                                        <div>
                                            <h4 className="text-zinc-100 font-medium mb-4 print:text-base">Unique Value</h4>
                                            <ul className="list-disc pl-4 space-y-4 print:text-xs   ">
                                                {salesAnalysisData?.valueProposition?.uniqueValue?.map((value, idx) => (
                                                    <li key={idx} className="text-zinc-300">{value}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>                            </div>
                            )}
                            <div>

                            </div>
                        </div>

                    </div>


                </div>
            )}



        </div>
    );
};

export default PathProductBusinessAnalysis;