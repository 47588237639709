import React, { useMemo } from 'react';
import { format, isToday, isYesterday, isSameDay, subDays } from 'date-fns';
import { Card, CardHeader, Typography } from "@material-tailwind/react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";

const CompInsightsRecentActivity = ({ shares, analytics }) => {
    const recentViewers = useMemo(() => {
        if (!analytics || !shares) return [];

        return analytics
            .filter(item => item && item.createdAt && item.userAnon)
            .sort((a, b) => (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0))
            .filter((item, index, self) =>
                index === self.findIndex(t => t.userAnon === item.userAnon)
            )
            .slice(0, 50)
            .map(item => {
                const shareName = shares.find(s => s.shareFriendlyURL === item.shareCode)?.name || 'Unknown';
                const userStats = analytics.filter(a => a.userAnon === item.userAnon);
                return {
                    shareName,
                    userAnon: item.userAnon,
                    uniqueViews: userStats.length,
                    totalTimeViewed: userStats.reduce((sum, a) => sum + (a.engagementTime || 0), 0),
                    totalScenesViewed: new Set(userStats.map(a => a.sceneId).filter(Boolean)).size,
                    createdAt: new Date(item.createdAt.seconds * 1000)
                };
            });
    }, [analytics, shares]);

    const shareEngagement = useMemo(() => {
        const engagement = {};
        recentViewers.forEach(viewer => {
            engagement[viewer.shareName] = (engagement[viewer.shareName] || 0) + viewer.uniqueViews;
        });
        return engagement;
    }, [recentViewers]);

    const maxEngagement = Math.max(...Object.values(shareEngagement), 1);

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours}h ${minutes}m`;
    };

    const groupedViewers = useMemo(() => {
        const groups = {};
        recentViewers.forEach(viewer => {
            const date = viewer.createdAt;
            const dateString = format(date, 'yyyy-MM-dd');
            if (!groups[dateString]) {
                groups[dateString] = [];
            }
            groups[dateString].push(viewer);
        });
        return groups;
    }, [recentViewers]);

    const formatDateHeader = (date) => {
        if (isToday(date)) {
            return 'Today';
        } else if (isYesterday(date)) {
            return 'Yesterday';
        } else if (isSameDay(date, subDays(new Date(), 2))) {
            return '2 days ago';
        } else {
            return format(date, 'EEEE, MMM d');
        }
    };

    return (
        <div className=" text-zinc-300 bg-black/10 rounded-lg pb-4 -mt-5 ">
            <Card className="bg-black/0 border-0 shadow-none">
                <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="flex flex-col  rounded-none md:flex-row md:items-center"
                >
                    <div className="w-max rounded-lg p-5 text-white">
                        {/* <Square3Stack3DIcon className="h-6 w-6" /> */}
                        <img src="/util/app/b3-admin-analytics-top.svg" className="h-8 w-8" />
                    </div>
                    <div>
                        <Typography variant="h6" className="text-zinc-200">
                            Recent Activity
                        </Typography>
                        {/* <Typography variant="small" className="text-zinc-400 max-w-sm font-normal">
                            Showing the number of unique visitors for your most popular shares
                        </Typography> */}
                    </div>
                </CardHeader>
            </Card>

            <div className="grid grid-cols-6 gap-4 px-6 -mt-2 py-3 text-sm text-left font-medium text-zinc-100 
            pr-12
             pl-4
             ">
                <div className='col-span-2'> </div>
                <div>Unique</div>
                <div>Time</div>
                <div>Scenes</div>
                <div>Last View</div>
            </div>

            <div className="h-[calc(100vh-420px)] overflow-y-auto rounded-md pt-2
             xbg-black/5
              xshadow-inner
               ml-4 relative space-y-6 pl-4 mr-4 pr-2 pb-4">
                {Object.entries(groupedViewers).map(([dateString, viewers]) => (
                    <div key={dateString} className="relative">
                        <div className="px-3 py-1 text-sm rounded-full text-zinc-300">
                            {formatDateHeader(new Date(dateString))}
                        </div>

                        <div className="bg-black/10 rounded mr-2">
                            <div className="divide-y divide-zinc-800">
                                {viewers.map((viewer, index) => {
                                    const engagement = shareEngagement[viewer.shareName] || 0;
                                    const opacity = Math.max(0.1, engagement / maxEngagement);

                                    return (
                                        <div
                                            key={index}
                                            className="grid grid-cols-6 gap-4 px-6 py-4 bg-black/10 rounded-lg transition-colors text-sm"
                                        >
                                            <div className="col-span-2">
                                                <div className='inline-block w-4 h-4 mr-4 rounded-full'
                                                    style={{
                                                        background: `rgba(14, 165, 233, ${opacity * 0.5})`
                                                    }}
                                                />
                                                {viewer.shareName}
                                            </div>
                                            <div>{viewer.uniqueViews}</div>
                                            <div>{formatTime(viewer.totalTimeViewed)}</div>
                                            <div>{viewer.totalScenesViewed}</div>
                                            <div>{format(viewer.createdAt, 'h:mm a')}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CompInsightsRecentActivity; 