import { Grid, useAspect, useVideoTexture, shaderMaterial, Environment, Html, CurveModifier, Float, Text, Decal, useTexture, Backdrop, ContactShadows, SpotLight, MeshReflectorMaterial } from '@react-three/drei'
import * as THREE from 'three'
import React, { useRef, useContext, useMemo, useEffect, useState, Suspense } from 'react'
import { useFrame, extend, useThree, useLoader } from '@react-three/fiber'
import gsap from "gsap";
import { SessionContext, SceneContext, StoryContext } from './AppStoryPlayer'
import { v4 } from 'uuid'

// Experience Components 
import ExpHTML from './experience/ExpHTML'
import ExpModel from './experience/ExpModel'
import ExpModelPhong from './experience/ExpModelPhong'
import ExpLine from './experience/ExpLine';
import { ExpModelMatcap } from './experience/ExpModelMatcap';
import { GetContentPosition } from './components/UtilFunctions';
import ExpChart3D from './experience/ExpChart3D';
import ChartLine from './experience/ChartLine';
import MeshShape3D from './experience/MeshShape3D';
import DynamicPattern from './experience/DynamicPattern';

const AppStoryExperience = (props) => {
  const [sceneData, setSceneData] = useContext(SceneContext)
  const [storyData, setStoryData] = useContext(StoryContext)
  const [sessionData, setSessionData] = useContext(SessionContext)

  const { scene } = useThree()

  const { DEG2RAD } = THREE.MathUtils
  const horizontalSpace = 40

  const [gridVisible, setGridVisible] = useState(false)

// storyData.environmentLightMode

  const [isDraggingActive, setIsDraggingActive] = useState(false)
  
  // useEffect(() => {
  //   console.log('isDraggingActive', isDraggingActive)
  // }, [isDraggingActive])

  const lightMode = storyData?.environmentLightMode ?? 1

  const vizNode = {
    data: [
      { x: 0, y: 40, xLabel: 'Jan', yLabel: '40K' },
      { x: 12, y: 26, xLabel: 'Feb' },
      { x: 25, y: 11, xLabel: 'Mar' },
      { x: 34, y: 41, xLabel: 'Apr' },
      { x: 47, y: 33, xLabel: 'May' },
    ],
  };

  const options = {
    color: '#ff0000',
    thickness: 2,
    xScale: 5,
    yScale: 5,
    bezier: true,
    bezierTension: 0.5,
    showJoints: true,
    jointType: 'sphere',
    jointSize: 5,
    jointColor: '#ff0000',
    showLabels: true,
    labelColor: '#333',
    labelSize: 1,
    lineAnimationDuration: 5,
    lineAnimationEase: 'power2.inOut',
    jointAnimationDuration: 0.5,
    jointAnimationEase: 'elastic.out(1, 0.3)',
    jointAnimationDelay: 0.25,
  };

  const spotlightRefs = useRef([]);
  const spotlightIntensities = useRef([]);
  const spotlightTimers = useRef([]);

  // Configuration variables
  const spotlightDelay = 1000; // 1 second delay before intensity increase
  const spotlightTransitionDuration = 2000; // 2 seconds for the light to fully come on
  const spotlightMaxIntensity = 3; // Maximum intensity of the spotlight
  const spotlightMinIntensity = 0; // Minimum intensity of the spotlight


  useEffect(() => {
    spotlightRefs.current = spotlightRefs.current.slice(0, sceneData.length);
    spotlightIntensities.current = spotlightIntensities.current.slice(0, sceneData.length);
    spotlightTimers.current = spotlightTimers.current.slice(0, sceneData.length);
  }, [sceneData]);

  useEffect(() => {
    spotlightRefs.current.forEach((spotlight, index) => {
      if (spotlight) {
        const platformPosition = GetContentPosition(storyData, sceneData, index);
        const targetPosition = new THREE.Vector3(...platformPosition);
        spotlight.target.position.copy(targetPosition);
        scene.add(spotlight.target);
      }
    });

    return () => {
      spotlightRefs.current.forEach((spotlight) => {
        if (spotlight && spotlight.target) {
          scene.remove(spotlight.target);
        }
      });
    };
  }, [sceneData, storyData, scene]);

  useFrame((state, delta) => {
    const currentTime = state.clock.getElapsedTime() * 1000; // Convert to milliseconds

    spotlightRefs.current.forEach((spotlight, index) => {
      if (spotlight) {
        const targetIntensity = sessionData.activeIndex === index ? spotlightMaxIntensity : spotlightMinIntensity;
        
        if (sessionData.activeIndex === index && !spotlightTimers.current[index]) {
          spotlightTimers.current[index] = currentTime + spotlightDelay;
        } else if (sessionData.activeIndex !== index) {
          spotlightTimers.current[index] = null;
        }

        if (spotlightTimers.current[index] && currentTime > spotlightTimers.current[index]) {
          const elapsedTime = currentTime - spotlightTimers.current[index];
          const t = Math.min(elapsedTime / spotlightTransitionDuration, 1);
          spotlightIntensities.current[index] = THREE.MathUtils.lerp(
            spotlightIntensities.current[index] || spotlightMinIntensity,
            targetIntensity,
            t
          );
        } else if (sessionData.activeIndex !== index) {
          spotlightIntensities.current[index] = THREE.MathUtils.lerp(
            spotlightIntensities.current[index] || spotlightMinIntensity,
            targetIntensity,
            delta * 2 // Adjust this value to control the fade-out speed
          );
        }

        spotlight.intensity = spotlightIntensities.current[index];
      }
    });
  });

  const isNavOmitMain = (scene, index) => {
    // Check if navOmitMain exists and is true
    if (scene.hasOwnProperty('navOmitMain') && scene.navOmitMain === true) {
      return true;
    }

    // If navOmitMain doesn't exist or is false, return false
    return false;
  };
  



  const ReflectiveGround = ({ 
    width = 300,
    height = 300,
    position = [0, -2, 0],
    color = "#ffffff",
    blur = [500, 100],
    resolution = 1024,
    mirror = 0.5,
    mixBlur = 10,
    mixStrength = 1,
    roughness = 1,
    metalness = 0.5,
  }) => {
    return (
      <mesh rotation={[-Math.PI / 2, 0, 0]} position={position}>
        <planeGeometry args={[width, height]} />
        <MeshReflectorMaterial
          blur={blur}
          resolution={resolution}
          mirror={mirror}
          mixBlur={mixBlur}
          mixStrength={mixStrength}
          roughness={roughness}
          metalness={metalness}
          color={color}
        />
      </mesh>
    );
  };

  const RenderScenePlatforms = () => {
    return sceneData.map((item, index) => {
      const storyItem = storyData[index];
      const navOmitMainIndices = sceneData.map((scene, index) => {
        if (isNavOmitMain(scene, index)) {
          return index;
        }
        return null;
      }).filter(index => index !== null);

      const isBackgroundScene = storyItem && (storyItem.navOmitMain != 'undefined' && storyItem.navOmitMain === true);
      const shouldRenderLight = !isBackgroundScene;

      return (
        <group key={item.id + 'platform'}>
          {item.platform ?
            <>
              {lightMode == 2 && shouldRenderLight ?
                <SpotLight
                  ref={(el) => (spotlightRefs.current[index] = el)}
                  position={[...GetContentPosition(storyData, sceneData, index, 0, 30, 0)]}
                  angle={.6}
                  penumbra={1}
                  intensity={0}
                  decay={0}
                  distance={.1}
                  color="white"
                />
                : null}
              {item.platform.model != 'platform_empty' ?
                <>
                  {item.platform.model != 'platform_placeholder' ?
                    <group>
                      <ExpModelMatcap
                        model={item.platform.model}
                        matcap={item.platform.model == 'platform_placeholder' ? 'MC-Sample5' : item.platform.matcap}
                        position={GetContentPosition(storyData, sceneData, index)}
                        key={item.id + 'platform'}
                      />
                      <Text
                        color={item.platform.text.color || 'white'}
                        position={GetContentPosition(storyData, sceneData, index, 0, -2, -13.2)}
                        strokeColor={item.platform.text.color}
                        strokeWidth={5}
                        scale={1.25}
                      >
                        {item.name}
                      </Text>
                      <Text
                        color="white"
                        position={GetContentPosition(storyData, sceneData, index, 0, -4.75, -13.2)}
                        scale={.75} maxWidth={24}>
                        {item.description}
                      </Text>
                    </group>
                    : null}
                </>
                :
                null
              }
            </>
            :
            <>
              <ExpModelMatcap
                model='platform_a_cap'
                matcap='434240_D3D3CF_898784_A4A49F_mod4'
                position={GetContentPosition(storyData, sceneData, index)}
                key={item.id + 'platform'}
              />
              <Text
                color="white"
                position={[(index * horizontalSpace) - 50, -1.5, 13.2]}
                scale={1.5}>
                {item.name}
              </Text>
            </>
          }
        </group>
      )
    })
  }

  const RenderStaticItems = () => {
    if (sceneData) {
      return sceneData.map((item, index) =>
        <group key={item.guid + 'vizgroup'}>
          {item.viznodes.map((vizItem) =>
            <group key={vizItem.guid + 'vizitem'}>
              {
                vizItem.id == '4' && item.navOmitMain ?
                  <ExpHTML
                    thisIndex={-1}
                    key={vizItem.guid}
                    position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                    vizItem={vizItem}
                    vizType={4}
                    setOrbitActive={props.setOrbitActive}
                  />
                  : (null)
              }
            </group>
          )}
        </group>
      )
    }
    else {
      return null
    }
  }

  const RenderScenePersonas = () => {
    return sceneData.map((item, index) =>
      <group key={item.guid + 'vizgroup'}>
        {item.viznodes.map((vizItem) =>
          <group key={vizItem.guid + 'vizitem'}>
            {
              vizItem.id == '0' || vizItem.id == 16 ?
                <ExpHTML
                  thisIndex={index}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  vizType={vizItem.id}
                  vizItem={vizItem}
                  key={vizItem.guid}
                  storyData={storyData}
                  setOrbitActive={props.setOrbitActive}
                  isDraggingActive={isDraggingActive}
                  setIsDraggingActive={setIsDraggingActive}
                />
                : (null)
            }
            {
              vizItem.id == '1' ?
                <ExpHTML
                  thisIndex={index}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  vizType={1}
                  vizItem={vizItem}
                  key={vizItem.guid}
                  setOrbitActive={props.setOrbitActive}
                  isDraggingActive={isDraggingActive}
                  setIsDraggingActive={setIsDraggingActive}
                />
                : (null)
            }
            {
              vizItem.id == '2' ?
                <ExpHTML
                  thisIndex={index}
                  key={vizItem.guid}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  vizType={2}
                  vizItem={vizItem}
                  setOrbitActive={props.setOrbitActive}
                  isDraggingActive={isDraggingActive}
                  setIsDraggingActive={setIsDraggingActive}
                />
                : (null)
            }
            {vizItem.id == '3' & vizItem.matcap == undefined ?
              <ExpModel
                vizItem={vizItem}
                modelPath={vizItem.model.includes('storage.googleapis.com') ? vizItem.model : '/components/' + vizItem.model}
                scale={vizItem.scale || 1}
                thisIndex={index}
                setOrbitActive={props.setOrbitActive}
                rotation={vizItem.rot ? [vizItem.rot.x, vizItem.rot.y, vizItem.rot.z] : [0, 0, 0]}
                position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, vizItem.pos.z || 0)}
                key={vizItem.guid}
                navOmitMain={sceneData[index].navOmitMain || false}
              />
              : (null)
            }
            {
              vizItem.id == '3' & vizItem.matcap != undefined ?
                <ExpModelMatcap
                  model={vizItem.model.replace('.glb', '')}
                  matcap={vizItem.model.includes('mini') ? storyData.backgroundMatcap : vizItem.matcap}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, vizItem.pos.z || 0)}
                  key={vizItem.guid + 'matcap'}
                  animation={vizItem.animation}
                  scale={vizItem.scale || 1}
                  navOmitMain={sceneData[index].navOmitMain || false}
                />
                : (null)
            }
            {
              vizItem.id == '4' ?
                <ExpHTML
                  thisIndex={index}
                  key={vizItem.guid}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  vizItem={vizItem}
                  vizType={4}
                  setOrbitActive={props.setOrbitActive}
                  isDraggingActive={isDraggingActive}
                  setIsDraggingActive={setIsDraggingActive}
                />
                : (null)
            }
            {
              vizItem.id == '5' ?
                <ExpHTML
                  thisIndex={index}
                  key={vizItem.guid}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  vizItem={vizItem}
                  vizType={5}
                  setOrbitActive={props.setOrbitActive}
                  isDraggingActive={isDraggingActive}
                  setIsDraggingActive={setIsDraggingActive}
                />
                : (null)
            }
            {
              vizItem.id == '6' ?
                <ExpHTML
                  thisIndex={index}
                  key={vizItem.guid}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  vizItem={vizItem}
                  vizType={6}
                  setOrbitActive={props.setOrbitActive}
                  isDraggingActive={isDraggingActive}
                  setIsDraggingActive={setIsDraggingActive}
                />
                : (null)
            }
            {
              vizItem.id == '7' ?
              <ExpHTML
                thisIndex={index}
                key={vizItem.guid}
                position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                vizItem={vizItem}
                vizType={7}
                setOrbitActive={props.setOrbitActive}
                isDraggingActive={isDraggingActive}
                setIsDraggingActive={setIsDraggingActive}
              />
              : (null)
          }
          {
            vizItem.id == '14' ?
              <ExpHTML
                thisIndex={index}
                key={vizItem.guid}
                position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, vizItem.pos.z)}
                vizItem={vizItem}
                vizType={vizItem.id}
                setOrbitActive={props.setOrbitActive}
                isDraggingActive={isDraggingActive}
                setIsDraggingActive={setIsDraggingActive}
              />
              : (null)
          }
          {
            vizItem.id == '17' ?
              <MeshShape3D
                vizItem={vizItem}
                url={vizItem.url}
                matcapUrl={vizItem.matcapUrl.includes('google')
                  ? vizItem.matcapUrl
                  : `/util/matcap/${vizItem.matcapUrl}.png`}
                extrusion={vizItem.extrusion}
                rotation={[vizItem.rot.x, vizItem.rot.y, vizItem.rot.z]}
                positionCalc={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, vizItem.pos.z)}
                position={[vizItem.pos.x, vizItem.pos.y, vizItem.pos.z]}
                setOrbitActive={props.setOrbitActive}
                scale={vizItem.scale || 1}
                isDraggingActive={isDraggingActive}
                setIsDraggingActive={setIsDraggingActive}
              />
              : (null)
          }
        </group>
      )}

      {storyData.backgroundGroundColor != null ?
        <ExpModelPhong
          model={storyData.backgroundModel}
          position={[0, storyData.backgroundHeight, 0]}
          scale={1}
          color={storyData.backgroundGroundColor.color}
          shininess={storyData.backgroundGroundColor.shininess}
          emissive={storyData.backgroundGroundColor.emissive}
          specular={storyData.backgroundGroundColor.specular}
          metalness={storyData.backgroundGroundColor.metalness}
          roughness={storyData.backgroundGroundColor.roughness}
        />
        :
        (null)
      }
      {storyData.backgroundHeight != null && !storyData.backgroundGroundColor ?
        <ExpModelMatcap
          model={storyData.backgroundModel}
          matcap={storyData.backgroundMatcap}
          position={[0, storyData.backgroundHeight, 0]}
          key={'main-platform'}
        />
        : (null)
      }
    </group>
  )
}

const [storyId, setStoryId] = useState('')

useEffect(() => {
  setStoryId(storyData.id)
}, [storyData])

return React.useMemo(() => {
  return (
    <>
      {lightMode == 1 ?
        <ambientLight intensity={1} />
        : null}
      {lightMode == 2 ?
        <group>
          <ambientLight intensity={.1} />
        </group>
        : null}


      {/* <ReflectiveGround
      color="#212121"
      mixBlur={200}
      width={4200}
      height={8000}
      mixStrength={5}
      roughness={.025}
      metalness={0.75}
      blur={[600, 1300]}
      position={[200, 0, -3000]}
      /> */}
      
      <RenderStaticItems></RenderStaticItems>
      <RenderScenePersonas></RenderScenePersonas>
      <RenderScenePlatforms></RenderScenePlatforms>
    </>
  )
}, [storyData, sceneData])
}

export default AppStoryExperience;