import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
import { db } from '../firebase'

import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
  orderBy,
  setDoc,
  where,
  writeBatch,
  serverTimestamp
} from 'firebase/firestore'

//import { UserAuth } from '/context/AuthContext'
//import { UserAuth } from '../context/AuthContext'
// import { UserAuth } from "../context/AuthContext";
//const { user, logOut } = UserAuth()

import app from "../firebase";
// import * as firebase from 'firebase/app';
// import {
//   getFunctions,
//   httpsCallable,
// } from "firebase/functions";


import ConfigMeshShape3D from "./ConfigMeshShape3d";
import CompMatcapGen from "./CompMatcapGen";
// import { initializeApp } from "firebase/app";
// import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
// import App from "../App";

// functions
import functions from '../firebase'


import { Canvas, extend, useThree, useLoader, useFrame } from "@react-three/fiber";
//const { camera } = useThree();


import { useParams, Link } from "react-router-dom"
import MediaUploader from "./MediaUploader"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CompactPicker, ChromePicker } from 'react-color'
import SceneDetailEdit from "./SceneDetailEdit"
import { v4 as uuidv4 } from 'uuid'
import { Menu, Combobox, Dialog, Transition, Switch, Popover } from '@headlessui/react'
import { MagnifyingGlassIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

import { SessionContext, SceneContext, StoryContext } from '/AppStoryPlayer'
//import Switch from "react-switch"
import '/style/Progress.css'
import 'react-accessible-accordion/dist/fancy-example.css'
import ConfigWebView from "./ConfigWebView"
import ConfigLongText from "./ConfigLongText";
import ConfigFreeText from "./ConfigFreeText";
import ConfigLink from "./ConfigLink";
import ConfigSuperCard from "./ConfigSuperCard";
import BarLoader from "react-spinners/BarLoader";
import { useHotkeys } from 'react-hotkeys-hook'
import Slider from 'react-input-slider'
//import anime, { set } from "animejs";
import anime from "animejs";
import Webcam from "react-webcam";
import MediaUploaderSolo from "./MediaUploaderSolo";

//import toast, { Toaster } from 'react-hot-toast';
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { ToastContainer, toast } from 'react-toastify';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import hotkeys from 'hotkeys-js';

//import { useNotificationCenter } from "react-toastify/addons/useNotificationCenter"
import 'react-toastify/dist/ReactToastify.css';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

//import Hotkeys from 'react-hot-keys'

//import CompProductTour from "./CompProductTour";
import CompHelpGuide from "./CompHelpGuide";

import { useCountListener } from "../events/bridge";

//import canvasScreenshot from "canvas-screenshot";

import StoryEditCamera from "./StoryEditCamera";
//import { TrashIcon } from "@heroicons/react/24/outline";
//import TimeAgo from 'javascript-time-ago'
// English.
//import en from 'javascript-time-ago/locale/en'

import GridSelect from "./CompGridSelect";

import CompModelAI from "./CompModelAI";

import CompAISummary from "./CompAISummary";
import CompAIStoryGen from "./CompAIStoryGen";
import CompAIIngest from "./CompAIIngest";
import CompLayoutPreview from "./CompLayoutPreview";
import SceneLayoutEditor from "./SceneLayoutEditor";

import { logCustomEvent } from "./UtilAnalytics";
import { lab } from "d3";
import BrandGenerator from "./BrandGenerator";
import CompLayoutEditor from "./CompLayoutEditor";
//import { log } from "console";
//import CompPreviewNavigationStyle from "./CompPreviewNavigationStyle";

// import {CameraAccessor, CameraLogger } from "./CameraLogger";

import MediaUpload from "./MediaUpload";


const StoryEditOverlay = (props) => {

  const [input, setInput] = useState('')
  const { storyId } = useParams()
  const { sceneIndex } = useParams()
  const [sceneData, setSceneData] = useContext(SceneContext)
  const [storyData, setStoryData] = useContext(StoryContext)
  const [sessionData, setSessionData] = useContext(SessionContext)

  const [presentMode, setPresentMode] = useState(false)

  // const [sceneData, sceneLoaded, setSceneData] = useContext(SceneContext)
  // const [storyData, storyLoaded, setStoryData] = useContext(StoryContext)
  // const [sessionData, sessionLoaded, setSessionData] = useContext(SessionContext)
  const { notifications } = useNotificationCenter()

  // // Create formatter (English).
  // const timeAgo = new TimeAgo('en-US')

  useEffect(() => {
    //loadDataOnlyOnce();
    //await sleep(1500);

    setSessionData(prevState => ({
      ...prevState,
      playModeActive: 0
    }))

    // setTimeout(() => {
    //   props.updateSceneFunction(0)
    // }, "500");

  }, []);


  // props.updateSceneFunction(0)

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const [activeTabStyleDesign, setActiveTabStyleDesign] = useState('tab1')
  const [activeTabStoryDesign, setActiveTabStoryDesign] = useState('tab1')
  const [activeModelTab, setActiveModelTab] = useState('library');

  const showStyleTab = (tabId) => {
    // // Hide all tab content
    // const tabContents = document.querySelectorAll('.tab-content');
    // tabContents.forEach((content) => {
    //   content.classList.add('hidden');
    // });

    // // Show the selected tab content
    // const selectedTab = document.getElementById(tabId);
    // if (selectedTab) {
    //   selectedTab.classList.remove('hidden');
    // }

    // // Remove the 'active' class from all tab buttons
    // const tabButtons = document.querySelectorAll('.tab-button');
    // tabButtons.forEach((button) => {
    //   button.classList.remove('active');
    // });

    // // Add the 'active' class to the clicked tab button
    // const clickedButton = document.querySelector(`[onclick="showTab('${tabId}')"]`);
    // if (clickedButton) {
    //   clickedButton.classList.add('active');
    // }
  }
  // Initialize the first tab
  //showTab('tab1');


  // position = { x: xPos, y: 60, z: 240 }
  // target = { x: xPos, y: 8, z: 0 }

  const platformOptions = [
    {
      name: 'Standard',
      model: 'platform_a_cap',
      icon: '/util/app/plat-square.svg',
      position: { x: 0, y: 0, z: 0 },
      scale: 1,
      id: 1,
    },
    {
      name: 'Standard',
      model: 'platform_c_cap',
      icon: '/util/app/plat-square-rounded.svg',
      position: { x: 0, y: 0, z: 0 },
      scale: 1,
      id: 2,
    },
    {
      name: 'Standard',
      model: 'platform_rect_rounded',
      icon: '/util/app/plat-rectangle-rounded.svg',
      position: { x: 0, y: 0, z: 0 },
      scale: 1,
      id: 3,
    },
    {
      name: 'Tall',
      model: 'platform_rect_rounded_tall',
      icon: '/util/app/plat-rectangle-rounded.svg',
      position: { x: 0, y: 0, z: 0 },
      scale: 1,
      id: 10,
    },
    // {
    //   name: 'Long',
    //   model: 'platform_flow_right_long',
    //   icon: '/util/app/plat-chevron-long.svg',
    //   position: { x: 0, y: 0, z: 0 },
    //   scale: 1,
    //   id: 11,
    // },
    // {
    //   name: 'Med Long',
    //   model: 'platform_flow_md_right_tall',
    //   icon: '/util/app/plat-chevron-long.svg',
    //   position: { x: 0, y: 0, z: 0 },
    //   scale: 1,
    //   id: 14,
    // },
    // {
    //   name: 'Tall Long',
    //   model: 'platform_flow_right_taill_long',
    //   icon: '/util/app/plat-chevron-long.svg',
    //   position: { x: 0, y: 0, z: 0 },
    //   scale: 1,
    //   id: 12,
    // },
    {
      name: 'Standard',
      model: 'platform_flow_right',
      icon: '/util/app/plat-chevron-long.svg',
      position: { x: 0, y: 0, z: 0 },
      scale: 1,
      id: 5,
    },
    {
      name: 'Tall',
      model: 'platform_flow_right_tall',
      icon: '/util/app/plat-chevron-long.svg',
      position: { x: 0, y: 0, z: 0 },
      scale: 1,
      id: 9,
    },
    {
      name: 'Standard',
      model: 'platform_circle',
      icon: '/util/app/plat-circle.svg',
      position: { x: 0, y: 0, z: 0 },
      scale: 1,
      id: 6,
    },
    // {
    //   name: 'Short',
    //   model: 'platform_circle_short',
    //   icon: '/util/app/plat-circle.svg',
    //   position: { x: 0, y: 0, z: 0 },
    //   scale: 1,
    //   id: 13,
    // },
    {
      name: 'Tall',
      model: 'platform_circle_tall',
      icon: '/util/app/plat-circle.svg',
      position: { x: 0, y: 0, z: 0 },
      scale: 1,
      id: 8,
    },
    {
      name: 'Tall',
      model: 'platform-octagon',
      icon: '/util/app/plat-rectangle-octagon.svg',
      position: { x: 0, y: 0, z: 0 },
      scale: 1,
      id: 16,
    },
    {
      name: 'Split',
      model: 'plat_flow_right_split',
      icon: '/util/app/plat-chevron-split.svg',
      position: { x: 0, y: 0, z: 0 },
      scale: 1,
      id: 17,
    },    //plat_flow_right_join
    {
      name: 'Join',
      model: 'plat_flow_right_join',
      icon: '/util/app/plat-chevron-join.svg',
      position: { x: 0, y: 0, z: 0 },
      scale: 1,
      id: 18,
    },    //plat_flow_right_join    
    // {
    //   name: 'Empty',
    //   model: 'platform_empty',
    //   icon: '/util/app/plat-empty.svg',
    //   position: { x: 0, y: 0, z: 0 },
    //   scale: 1,
    //   id: 7,
    // },
    {
      name: 'None',
      model: 'platform_placeholder',
      icon: '/util/app/plat-placeholder.svg',
      position: { x: 0, y: 0, z: 0 },
      scale: 1,
      id: 15,
    },
  ]
  const groundOffset = [
    {
      name: 'Default',
      backgroundHeight: 0,
    },
    {
      name: '-0.5',
      backgroundHeight: -0.5,
    },
    {
      name: '-1',
      backgroundHeight: -1,
    },
    {
      name: '-2',
      backgroundHeight: -2,
    },
    {
      name: '-3',
      backgroundHeight: -3,
    },
    {
      name: '-5',
      backgroundHeight: -5,
    },
    {
      name: '-7.5',
      backgroundHeight: -7.5,
    },
    {
      name: '-10',
      backgroundHeight: -10,
    },
    {
      name: '-20',
      backgroundHeight: -20,
    },
    {
      name: '-30',
      backgroundHeight: -30,
    },
    {
      name: '-40',
      backgroundHeight: -40,
    },
    {
      name: '-50',
      backgroundHeight: -50,
    },
    {
      name: '-60',
      backgroundHeight: -70,
    },
  ]
  const layoutMode = [
    {
      name: 'Linear',
      layoutType: 'linear',
    },
    {
      name: 'Circle',
      layoutType: 'circle',
    },
    {
      name: 'Vertical',
      layoutType: 'vertical',
    },
  ]
  const cameraOffsetDefaults = [
    {
      name: 'Default',
      position: { x: 0, y: 0, z: 0 },
      target: { x: 0, y: 4, z: 0 }
    },
    {
      name: 'Above',
      position: { x: 0, y: 40, z: 0 },
      target: { x: 0, y: 0, z: 0 }
    },
    {
      name: 'Level',
      position: { x: 0, y: -40, z: 0 },
      target: { x: 0, y: 0, z: 0 }
    },
    {
      name: 'Isometric',
      position: { x: -150, y: 180, z: 0 },
      target: { x: 150, y: 0, z: 0 }
    }
  ]
  const storyNavigationList = [
    {
      guid: 'nav1',
      //name: 'Process Chevrons',
      name: 'Bottom Layout 1',
    },
    {
      guid: 'nav-bottom-2',
      //name: 'Process Chevrons',
      name: 'Bottom Layout 2',
    },
    {
      guid: 'nav-bottom-3',
      //name: 'Process Chevrons',
      name: 'Bottom Layout - Sub Navigation',
    },
    // {
    //   guid: 'nav1-chevrons',
    //   //name: 'Process Chevrons',
    //   name: 'Standard Buttons w Chevrons',
    // },
    // {
    //   guid: 'playlist-sidebar2',
    //   name: 'Playlist Sidebar',
    // },
    // {
    //   guid: 'dots',
    //   name: 'Dots',
    // },
    // {
    //   guid: 'nav4',
    //   name: 'Timeline - Single Label',
    // },
    // {
    //   guid: 'nav5',
    //   name: 'Mystery...',
    // },
  ]
  const skyCloudList = [
    {
      id: 1,
      guid: 'noCloud',
      name: 'No Clouds',
    },
    {
      id: 2,
      guid: 'lightCloud',
      name: 'Light Clouds',
    },
    {
      id: 3,
      guid: 'heavyCloud',
      name: 'Heavy Clouds',
    },
  ]
  const skyList = [
    {
      id: 1,
      guid: 'lightBlue1',
      name: 'lightBlue',
      class: 'skyBackground1',
    },
    {
      id: 2,
      guid: 'darkBlue1',
      name: 'darkBlue',
      class: 'skyBackground2',
    },
    {
      id: 3,
      guid: 'lightBlue1-2',
      name: 'lightBlue2',
      class: 'skyBackground3',
    },
    {
      id: 4,
      guid: 'lightPurple1',
      name: 'lightPurple',
      class: 'skyBackground4',
    },
    {
      id: 5,
      guid: 'darkPurple1',
      name: 'darkPurple',
      class: 'skyBackground5',
    },
    {
      id: 6,
      guid: 'lightGray1',
      name: 'lightGray',
      class: 'skyBackground6',
    },
    {
      id: 7,
      guid: 'darkGrey1',
      name: 'darkGrey',
      class: 'skyBackground7',
    },
    {
      id: 8,
      guid: 'darkGrey2',
      name: 'darkGrey2',
      class: 'skyBackground8',
    },
    {
      id: 9,
      guid: 'greenScreen',
      name: 'greenScreen',
      class: 'skyBackground9',
    },
    {
      id: 10,
      guid: 'whiteScreen',
      name: 'whiteScreen',
      class: 'skyBackground10',
    },
    {
      id: 11,
      guid: 'lightOrange',
      name: 'lightOrange',
      class: 'skyBackground11',
    },
    {
      id: 12,
      guid: 'spaceBg',
      name: 'spaceBg',
      class: 'skyBackgroundSpace',
    },

    // #root.lightOrange,
    // .lightOrange,
    // .skyBackground11

    // {
    //   id: 11,
    //   guid: 'skyBackgroundImg1',
    //   name: 'skyBackgroundImg1',
    //   class: 'skyBackgroundImg1',
    // },  //skyBackgroundImg1    
  ]
  const componentsList = [
    {
      id: 1,
      guid: 'card-persona1',
      name: 'Person',
      icon: 'noun-person-5763652-FFFFFF',
      type: 'Person',
      personaPosX: 0,
      animation: 'fadeIn',
      animationDuration: 3,
      pos: { x: 0, y: 18 },
      media: [],
    },
    {
      id: 0,
      guid: 'card-super',
      name: 'Super Card',
      icon: 'noun-membership-2397537-FFFFFF',
      type: 'Person',
      personaPosX: 0,
      animation: 'fadeIn',
      animationDuration: 3,
      pos: { x: 0, y: 18 },
      media: [],
    },
    {
      id: 2,
      guid: 'card-device1',
      name: 'Gallery',
      icon: 'noun-devices-51939-FFFFFF',
      type: 'Gallery',
      pos: { x: 0, y: 16 },
      animation: 'fadeIn',
      animationDuration: 3,
      size: { width: 500, height: 450 },
      media: [],
    },
    {
      id: 3,
      guid: 'viz-model1',
      name: '3D Item',
      icon: 'noun-cube-1830912-FFFFFF',
      type: '3D Item',
      pos: { x: 0, y: 0, z: 0 },
      rot: { x: 0, y: 0 },
    },
    {
      id: 4,
      guid: 'media-image1',
      name: 'Image',
      icon: 'noun-image-2761478-FFFFFF',
      type: 'Image',
      url: '',
      pos: { x: 0, y: 22 },
      animation: { id: 1, name: 'none', displayName: 'None' },
      animationDuration: 3,
      size: { width: 800, height: 450 },
      media: [],
    },
    {
      id: 5,
      guid: 'link',
      name: 'Link',
      icon: 'noun-link-162420-FFFFFF',
      type: 'Link',
      label: 'Link Label',
      url: 'https://www.yourlink.com',
      pos: { x: 0, y: 18 },
      animation: { id: 1, name: 'none', displayName: 'None' },
      animationDuration: 3,
      size: { width: 800, height: 450 },
    },
    {
      id: 6,
      guid: 'live-web1',
      name: 'Web',
      icon: 'noun-web-5827943-FFFFFF',
      type: 'Web',
      url: '/PlaceholderWeb.html',
      pos: { x: 0, y: 22 },
      size: { width: 800, height: 450 },
      media: [],
    },
    {
      id: 7,
      guid: 'text',
      name: 'Text',
      icon: 'noun-text-5443090-FFFFFF',
      type: 'Text',
      content: '',
      animation: { id: 1, name: 'none', displayName: 'None' },
      animationDuration: 3,
      pos: { x: 0, y: 22 },
      size: { width: 800, height: 450 },
      media: [],
    },
    {
      id: 8,
      guid: 'contentCTA',
      name: 'CTA',
      icon: 'noun-notification-3835779-FFFFFF',
      type: 'CTA',
      content: '',
      position: 'center',
      animation: 'fadeIn',
      animationDuration: 3,
      pos: { x: 0, y: 0 },
      size: { width: 800, height: 450 },
      media: [],
    },
    // {
    //       id: 9,
    //       guid: 'content-block-master-detail-sales',
    //       name: 'Sales',
    //       icon: 'noun-cards-3318535-FEC023',
    //       type: 'Sales',
    //       columnCount: 3,
    //       pos: { x: 0, y: 16 },
    //       animation: 'fadeInDown',
    //       animationDuration: 3,
    //       size: { width: 1600, height: 900 },
    //       content: [
    //         {
    //           id: uuidv4(),
    //           logoUrl: '/media/demo/mfg/mfg-logo-brevit3.png',
    //           logoIconUrl: '/media/demo/mfg/mfg-logo-brevit3-icon.png',
    //           name: 'Brevit3',
    //           roles: ['Marketing', 'Sales'],
    //           description: `Stand out from the crowd and deliver engaging product demonstrations through rich, interactive stories that can be shared live, remotely or via self-service for your customer champions.`,
    //           items: [{
    //             id: uuidv4(),
    //             name: 'Overview',
    //             type: 'text',
    //             content: `Stand out from the crowd and deliver engaging product demonstrations through rich, interactive stories that can be shared live, remotely or via self-service for your customer champions.`,
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'Website',
    //             type: 'link',
    //             content: 'https://www.brevit3.com',
    //           }]
    //         },
    //         {
    //           id: uuidv4(),
    //           logoUrl: '/media/demo/mfg/mfg-logo-salesforce.png',
    //           logoIconUrl: '/media/demo/mfg/mfg-logo-salesforce-icon.png',
    //           name: 'Manufacturing Cloud',
    //           roles: ['Sales', 'Operations', 'Customer Support', 'Field Services'],
    //           description: 'Extend CRM to manage run-rate business throughout its order lifecycle with sales agreements and account-based forecasting.',
    //           items: [{
    //             id: uuidv4(),
    //             name: 'Overview',
    //             type: 'text',
    //             content: `<h1>Manufacturing Cloud Overview</h1>
    //           <p>Rootstock ....</p>`,
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'Demo Video',
    //             type: 'video',
    //             content: 'https://www.youtube.com/watch?v=ZAY8cctNZKk',
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'Website',
    //             type: 'link',
    //             content: 'https://www.salesforce.com/products/manufacturing-cloud/overview/',
    //           }]
    //         },
    //         {
    //           id: uuidv4(),
    //           logoUrl: '/media/demo/mfg/mfg-logo-salesforce.png',
    //           logoIconUrl: '/media/demo/mfg/mfg-logo-salesforce-icon.png',
    //           name: 'Commerce Cloud',
    //           roles: ['Sales'],
    //           description: 'Achieve goals faster, unlock new revenue, and drive automation with the most flexible ecommerce platform. Keep customers coming back with GPT-powered experiences.',
    //           items: [{
    //             id: uuidv4(),
    //             name: 'Overview',
    //             type: 'text',
    //             content: `<h1>CPQ - Revenue Cloud</h1>
    //           <p>Rootstock ....</p>`,
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'Website',
    //             type: 'link',
    //             content: 'https://www.salesforce.com/products/commerce/',
    //           }]
    //         },
    //         {
    //           id: uuidv4(),
    //           logoUrl: '/media/demo/mfg/mfg-logo-salesforce.png',
    //           logoIconUrl: '/media/demo/mfg/mfg-logo-salesforce-icon.png',
    //           name: 'CPQ - Revenue Cloud',
    //           roles: ['Sales', 'Customer Support'],
    //           description: 'Although configure, price, quote (CPQ) software has been available for decades, innovation in CPQ software is seen as the newest wave in sales optimization tools. But what is CPQ software? CPQ is a sales tool for companies to quickly and accurately generate quotes for orders.  ',
    //           items: [{
    //             id: uuidv4(),
    //             name: 'Overview',
    //             type: 'text',
    //             content: `<h1>CPQ - Revenue Cloud</h1>
    //           <p>Rootstock ....</p>`,
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'Demo Video',
    //             type: 'video',
    //             content: 'https://www.youtube.com/watch?v=8ppJMGvfElU',
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'Website',
    //             type: 'link',
    //             content: 'https://www.salesforce.com/products/cpq/resources/what-is-salesforce-cpq/',
    //           }]
    //         },
    //         {
    //           id: uuidv4(),
    //           logoUrl: '/media/demo/mfg/mfg-logo-logik.png',
    //           logoIconUrl: '/media/demo/mfg/mfg-logo-logik-icon.png',
    //           name: 'Logik.io',
    //           roles: ['Sales', 'Customer Support'],
    //           description: `Logik.io supercharges Salesforce CPQ & Commerce Cloud with an advanced configuration engine built to help your sales reps and end-customers configure, price, and quote even your most sophisticated products easily, with better performance and less admin.`,
    //           items: [{
    //             id: uuidv4(),
    //             name: 'Overview',
    //             type: 'text',
    //             content: `Logik.io supercharges Salesforce CPQ & Commerce Cloud with an advanced configuration engine built to help your sales reps and end-customers configure, price, and quote even your most sophisticated products easily, with better performance and less admin.`,
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'Demo Video',
    //             type: 'video',
    //             content: 'https://www.youtube.com/watch?v=M-0GxLHYmR0',
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'AppExchange Listing',
    //             type: 'link',
    //             content: 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N4V00000DkV9tUAF',
    //           }]
    //         },
    //         {
    //           id: uuidv4(),
    //           logoUrl: '/media/demo/mfg/mfg-logo-cds.png',
    //           logoIconUrl: '/media/demo/mfg/mfg-logo-cds-icon.png',
    //           name: 'Partable - CDS',
    //           roles: ['Engineering', 'Product Management', 'Sales'],
    //           description: `Partable delivers a high-performance part search during the B2B aftermarket buying process. Users are able to identify, select, and purchase parts via interactive 3D models, 2D exploded view images, BOM navigation and serial number search.`,
    //           items: [{
    //             id: uuidv4(),
    //             name: 'Overview',
    //             type: 'text',
    //             content: `Partable delivers a high-performance part search during the B2B aftermarket buying process. Users are able to identify, select, and purchase parts via interactive 3D models, 2D exploded view images, BOM navigation and serial number search.`,
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'Demo Video',
    //             type: 'video',
    //             content: 'https://vimeo.com/774764961',
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'AppExchange Listing',
    //             type: 'link',
    //             content: 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N4V00000HZbjGUAT',
    //           }]
    //         },
    //         {
    //           id: uuidv4(),
    //           logoUrl: '/media/demo/mfg/mfg-logo-renderdraw.png',
    //           logoIconUrl: '/media/demo/mfg/mfg-logo-renderdraw-icon.png',
    //           name: 'RenderDraw',
    //           roles: ['Engineering', 'Product Management', 'Sales'],
    //           description: `RenderDraw is 100% Salesforce-native and uses existing 2D product images/PDFs to create visual drag-and-drop configurators, retail planograms, and floor plans. 3D CAD or BIM drawings can also be used for real-time 3D visual configuration and self-service`,
    //           items: [{
    //             id: uuidv4(),
    //             name: 'Overview',
    //             type: 'text',
    //             content: `RenderDraw is 100% Salesforce-native and uses existing 2D product images/PDFs to create visual drag-and-drop configurators, retail planograms, and floor plans. 3D CAD or BIM drawings can also be used for real-time 3D visual configuration and self-service`,
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'Demo Video',
    //             type: 'video',
    //             content: 'https://www.youtube.com/watch?v=U7rLLxuowWs',
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'AppExchange Listing',
    //             type: 'link',
    //             content: 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FP49NUAT',
    //           }]
    //         },
    //         {
    //           id: uuidv4(),
    //           logoUrl: '/media/demo/mfg/mfg-logo-threekit.png',
    //           logoIconUrl: '/media/demo/mfg/mfg-logo-threekit-icon.png',
    //           name: 'Threekit',
    //           roles: ['Engineering', 'Product Management', 'Sales'],
    //           description: `Threekit Visual Commerce lets you create magical product experiences.  Unlock 100% of your product catalog and sell more with Threekit's suite of 3D Configuration, Virtual Photographer (2D), and Augmented Reality solutions.`,
    //           items: [{
    //             id: uuidv4(),
    //             name: 'Overview',
    //             type: 'text',
    //             content: `Threekit Visual Commerce lets you create magical product experiences.  Unlock 100% of your product catalog and sell more with Threekit's suite of 3D Configuration, Virtual Photographer (2D), and Augmented Reality solutions.`,
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'Demo Video',
    //             type: 'video',
    //             content: 'https://www.youtube.com/watch?v=GigBX3_fnAQ',
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'AppExchange Listing',
    //             type: 'link',
    //             content: 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FR5ghUAD',
    //           }]
    //         },
    //         {
    //           id: uuidv4(),
    //           logoUrl: '/media/demo/mfg/mfg-logo-zilliant.png',
    //           logoIconUrl: '/media/demo/mfg/mfg-logo-zilliant-icon.png',
    //           name: 'Zilliant',
    //           roles: ['Sales', 'Product', 'Finance'],
    //           description: `Zilliant Price IQ™ simultaneously accounts for all factors that influence price, measures price elasticity, and rationally aligns prices in accordance with business rules to produce optimized price guidance.`,
    //           items: [{
    //             id: uuidv4(),
    //             name: 'Overview',
    //             type: 'text',
    //             content: `Zilliant Price IQ™ simultaneously accounts for all factors that influence price, measures price elasticity, and rationally aligns prices in accordance with business rules to produce optimized price guidance.`,
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'Demo Video',
    //             type: 'video',
    //             content: 'https://www.youtube.com/watch?v=fGjasq1LalY',
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'AppExchange Listing',
    //             type: 'link',
    //             content: 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N30000009wGJXEA2',
    //           }]
    //         },
    //         {
    //           id: uuidv4(),
    //           logoUrl: '/media/demo/mfg/mfg-logo-xotigo.png',
    //           logoIconUrl: '/media/demo/mfg/mfg-logo-xotigo-icon.png',
    //           name: 'Xotigo',
    //           roles: ['Sales'],
    //           description: `Xotigo builds digital software solutions for the automotive supply, aerospace component and manufacturing industries. Our solutions provide a single source of truth for entire organizations and end-to-end process transparency for commercial teams.`,
    //           items: [{
    //             id: uuidv4(),
    //             name: 'Overview',
    //             type: 'text',
    //             content: `Xotigo builds digital software solutions for the automotive supply, aerospace component and manufacturing industries. Our solutions provide a single source of truth for entire organizations and end-to-end process transparency for commercial teams — delivering the intelligent insights you need to make agile, data-backed decisions that achieve revenue and margin goals.`,
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'Demo Video',
    //             type: 'video',
    //             content: 'https://player.vimeo.com/video/946729123?h=8c8af73ad8',
    //           },
    //           {
    //             id: uuidv4(),
    //             name: 'AppExchange Listing',
    //             type: 'link',
    //             content: 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N4V00000GX7CUUA1',
    //           }]
    //         },

    //       ]
    //     },

    {
      id: 16,
      guid: 'content',
      name: 'Icon',
      icon: 'noun-up-arrow-1181859-FFFFFF',
      type: 'Icon',
      content: '',
      position: 'center',
      animation: 'fadeIn',
      animationDuration: 0,
      pos: { x: 0, y: 20 },
      size: { width: 200, height: 200 },
      media: [],
    },
    // {
    //   id: 17,
    //   guid: 'shape',
    //   name: 'Shape',
    //   icon: 'noun-cube-1830912-FFFFFF',
    //   type: 'Shape',
    //   pos: { x: 0, y: 0, z: 0 },
    //   rot: { x: 0, y: 0, z: 0 },
    //   scale: .025,
    //   extrusion: 20,
    //   url: '/shapes/b3-shape-triangle.svg',
    //   matcapUrl: 'MC-Sample6_40',
    //   decal: '',
    // },    
  ]
  const componentBlockList = [
    {
      guid: 'Blank',
      name: 'Blank',
      description: 'Empty scene',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'IntroductionA',
      name: 'Introduction A',
      description: 'Introduce the concept for your story',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'IntroductionB',
      name: 'Introduction B',
      description: 'Introduce the concept for your story',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'StakeholderQuotes1',
      name: 'Stakeholder Quote',
      description: 'Single stakeholder quote',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'StakeholderQuotes2',
      name: 'Stakeholder 2 Quotes',
      description: 'Two stakeholder quotes',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'StakeholderQuotes3',
      name: 'Stakeholder 3 Quotes',
      description: 'Three stakeholder quotes',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'Compare2',
      name: 'Compare 2',
      description: 'Comparison between 2 ideas, metrics or concepts',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'Compare3',
      name: 'Compare 3',
      description: 'Comparison between 3 ideas, metrics or concepts',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'Compare4',
      name: 'Compare 4',
      description: 'Comparison between 4 ideas, metrics or concepts',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'Compare5',
      name: 'Compare 5',
      description: 'Comparison between 5 ideas, metrics or concepts',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'FromTo',
      name: 'From To',
      description: 'Overview of impact from one state to another',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'DecicePersona',
      name: 'Device + Persona',
      description: 'Show interaction between a user and a device',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'Decice2Persona',
      name: 'Device + 2 Personas',
      description: 'Show interaction between two users and a device',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'CaseStudy',
      name: 'Case Study',
      description: 'Videos, images and quotes from a case study',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'Team2Members',
      name: 'Team 2 Members',
      description: 'Highlight supporting cast for your audience',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'Team3Members',
      name: 'Team 3 Members',
      description: 'Highlight supporting cast for your audience',
      icon: 'noun-image-5698805-FFFFFF',
    },
    {
      guid: 'Team4Members',
      name: 'Team 4 Members',
      description: 'Highlight supporting cast for your audience',
      icon: 'noun-image-5698805-FFFFFF',
    },
  ]
  const componentModelList = [
    // {
    //   guid: 'comp-asset-placeholder',
    //   model: 'placeholder_sign.glb',
    //   name: 'Placeholder',
    //   pos: { x: 0, y: 0 },
    // },
    {
      guid: 'asset_house_suburb_1',
      model: 'asset_house_suburb_1.glb',
      name: 'House Suburb 1',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_house_suburb_1.glb
    {
      guid: 'asset_house_suburb_2',
      model: 'asset_house_suburb_2.glb',
      name: 'House Suburb 2',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_house_suburb_1.glb
    {
      guid: 'asset_house_suburb_3',
      model: 'asset_house_suburb_3.glb',
      name: 'House Suburb 3',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_house_suburb_1.glb  
    {
      guid: 'asset_house_suburb_4',
      model: 'asset_house_suburb_4.glb',
      name: 'House Suburb 4',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_house_suburb_5',
      model: 'asset_house_suburb_5.glb',
      name: 'House Suburb 5',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_warehouse_shelf',
      model: 'asset_warehouse_shelf.glb',
      name: 'Warehouse Shelf',
      category: 'Logistics',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_house_suburb_1.glb
    {
      guid: 'asset_cooler',
      model: 'asset_cooler.glb',
      name: 'Cabinet Cooler',
      category: 'Logistics',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_house_suburb_1.glb
    // {
    //   guid: 'asset_cooler',
    //   model: 'asset_cooler.glb',
    //   name: 'Cabinet Cooler',
    //   category: 'Logistics',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // },
    {
      guid: 'asset_medical_glove_box',
      model: 'asset_medical_glove_box.glb',
      name: 'Medical Glove Box',
      category: 'Logistics',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_electricbox.glb 
    {
      guid: 'asset_warehouse_forklift_solo',
      model: 'asset_warehouse_forklift_solo.glb',
      name: 'Warehouse Forklift Solo',
      category: 'Logistics',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_electricbox.glb            
    {
      guid: 'asset_electricbox',
      model: 'asset_electricbox.glb',
      name: 'Electric Box',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_house_wip.glb
    {
      guid: 'asset_house_wip',
      model: 'asset_house_wip.glb',
      name: 'House Under Construction',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_floodlights.glb.glb
    {
      guid: 'asset_floodlights',
      model: 'asset_floodlights.glb',
      name: 'Floodlights',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_roadblock.glb.glb.glb    
    {
      guid: 'asset_roadblock',
      model: 'asset_roadblock.glb',
      name: 'Roadblock',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_roadblock.glb.glb.glb        
    {
      guid: 'asset_traffic_barrel',
      model: 'asset_traffic_barrel.glb',
      name: 'Traffic Barrel',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_roadblock.glb     
    {
      guid: 'asset_roadblock',
      model: 'asset_roadblock.glb',
      name: 'Roadblock',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_survey_tool.glb    
    {
      guid: 'asset_survey_tool',
      model: 'asset_survey_tool.glb',
      name: 'Survey Tool',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_survey_tool.glb        
    {
      guid: 'asset_concrete_barrier',
      model: 'asset_concrete_barrier.glb',
      name: 'Concrete Barrier',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_roadway_a
    {
      guid: 'asset_roadway_a',
      model: 'asset_roadway_a.glb',
      name: 'Roadway A',
      category: 'Transportation',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_roadway_a            
    {
      guid: 'asset_construction_planning',
      model: 'asset_construction_planning.glb',
      name: 'House Construction Plans',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_kitchen_a.glb   
    {
      guid: 'asset_kitchen_a',
      model: 'asset_kitchen_a.glb',
      name: 'Home Kitchen A',
      category: 'Interior',
      pos: { x: 0, y: 0, z: 0 },
      scale: 1,
    },//asset_arrow_chevrons_right.glb       
    {
      guid: 'asset_portable_office',
      model: 'asset_portable_office.glb',
      name: 'Portable Office',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//pc_asset_hardhat.glb.glb  
    {
      guid: 'pc_asset_hardhat',
      model: 'pc_asset_hardhat.glb',
      name: 'Hardhat',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//pc_asset_hardhat.glb.glb      
    {
      guid: 'asset_landscape_mountain',
      model: 'asset_landscape_mountain.glb',
      name: 'Landscaped Mountain',
      category: 'Nature',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_landscape_mountain.glb       
    {
      guid: 'asset_two_companies',
      model: 'asset_two_companies.glb',
      name: 'Two Organizations',
      category: 'Office',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_industrial_building_2.glb    
    {
      guid: 'asset_ladder',
      model: 'asset_ladder.glb',
      name: 'Ladder',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_ladder.glb
    //asset_office_7
    {
      guid: 'asset_office_7',
      model: 'asset_office_7.glb',
      name: 'Office Building 7',
      category: 'Office',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_ladder.glb  
    {
      guid: 'asset_office_10',
      model: 'asset_office_10.glb',
      name: 'Office Building 10',
      category: 'Office',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_ladder.glb        
    {
      guid: 'asset_industrial_building_1',
      model: 'asset_industrial_building_1.glb',
      name: 'Industrial Building 1',
      category: 'Office',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_ladder.glb    
    {
      guid: 'asset_industrial_building_2',
      model: 'asset_industrial_building_2.glb',
      name: 'Industrial Building 2',
      category: 'Office',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_industrial_building_2.glb
    {
      guid: 'asset_industrial_building_3',
      model: 'asset_industrial_building_3.glb',
      name: 'Industrial Building 3',
      category: 'Office',
      pos: { x: 0, y: 0 },
      scale: 1,
    },    // asset_building_construction
    {
      guid: 'asset_building_construction',
      model: 'asset_building_construction.glb',
      name: 'Building Under Construction',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },    // asset_construction_crane    
    {
      guid: 'asset_construction_crane',
      model: 'asset_construction_crane.glb',
      name: 'Construction Crane',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },    // asset_trafficlight_directional.glb
    {
      guid: 'asset_trafficlight_directional',
      model: 'asset_trafficlight_directional.glb',
      name: 'Traffic Light',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },    // asset_trafficlight_directional.glb            
    {
      guid: 'asset_construction_materials',
      model: 'asset_construction_materials.glb',
      name: 'Construction Materials',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_house_electrical.glb  
    {
      guid: 'asset_excavator',
      model: 'asset_excavator.glb',
      name: 'Excavator',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //plat_rect_const_1_rounded_tall.glb  
    // {
    //   guid: 'plat_rect_const_1_rounded_tall',
    //   model: 'plat_rect_const_1_rounded_tall.glb',
    //   name: 'Excavation Platform',
    //   category: 'Construction',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // }, //plat_rect_const_1_rounded_tall.glb      
    {
      guid: 'asset_excavator_anim',
      model: 'asset_excavator_anim.glb',
      name: 'Excavator Animated',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_excavator_anim      
    {
      guid: 'asset_house_electrical',
      model: 'asset_house_electrical.glb',
      name: 'Construction Electrical',
      category: 'Construction',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_house_electrical.glb                
    {
      guid: 'asset_industrial_tank',
      model: 'asset_industrial_tank.glb',
      name: 'Industrial Tank',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_intro_industrial.glb
    {
      guid: 'asset_intro_industrial',
      model: 'asset_intro_industrial.glb',
      name: 'Manufacturing Ecosystem',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_radio_tower.glb       
    {
      guid: 'asset_radio_tower',
      model: 'asset_radio_tower.glb',
      name: 'Radio Cell Tower',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_radio_tower.glb           
    {
      guid: 'asset_industrial_turbine',
      model: 'asset_industrial_turbine.glb',
      name: 'Wind Turbine',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_industrial_turbine_anim.glb
    {
      guid: 'asset_industrial_turbine_anim',
      model: 'asset_industrial_turbine_anim.glb',
      name: 'Wind Turbine Animated',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_industrial_turbine_anim.glb    
    {
      guid: 'asset_iIndustrial_tank',
      model: 'asset_iIndustrial_tank.glb',
      name: 'Industrial Tank',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_pallet_boxes_3.glb  
    {
      guid: 'asset_pallet_boxes_3',
      model: 'asset_pallet_boxes_3.glb',
      name: 'Pallet Boxes 3',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_pallet_boxes_3.glb                        
    {
      guid: 'asset_shipping_containers',
      model: 'asset_shipping_containers.glb',
      name: 'Shipping Containers',
      category: 'Logistics',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_service_van.glb 
    {
      guid: 'asset_service_van',
      model: 'asset_service_van.glb',
      name: 'Service Van',
      category: 'Service',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_service_van.glb            
    {
      guid: 'asset_logistics_truck',
      model: 'asset_logistics_truck.glb',
      name: 'Logistics Truck',
      category: 'Logistics',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_plane_cargo
    {
      guid: 'asset_plane_cargo',
      model: 'asset_plane_cargo.glb',
      name: 'Cargo Plane',
      category: 'Logistics',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_plane_cargo    
    {
      guid: 'comp-asset-hvac-1',
      model: 'asset_hvac_1.glb',
      name: 'HVAC Unit 1',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_industrial_building.glb
    {
      guid: 'asset_industrial_building',
      model: 'asset_industrial_building.glb',
      name: 'Industrial Building 1',
      category: 'Industrial Building',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_conveyor_1.glb
    {
      guid: 'asset_conveyor_1',
      model: 'asset_conveyor_1.glb',
      name: 'Industrial Conveyor 1',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_conveyor_1.glb    
    {
      guid: 'comp-asset-office-interior-1',
      model: 'asset_office_interior_1.glb',
      name: 'Office Interior 1',
      category: 'Interior',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_office_desk.png
    {
      guid: 'asset_office_desk',
      model: 'asset_office_desk.glb',
      name: 'Office Interior 2',
      category: 'Interior',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_office_desk.png    
    {
      guid: 'asset_conf_room_1',
      model: 'asset_conf_room_1.glb',
      name: 'Office Conference Room 1',
      category: 'Interior',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_conf_room_1.png    
    {
      guid: 'comp-asset-office-building-1',
      model: 'asset_building1.glb',
      name: 'Office Building 1',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'comp-asset-office-building-2',
      model: 'asset_building2.glb',
      name: 'Office Building 2',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'comp-asset-office-building-3',
      model: 'asset_building3.glb',
      name: 'Office Building 3',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'comp-asset-office-building-4',
      model: 'asset_building4.glb',
      name: 'Office Building 4',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'comp-asset-office-building-5',
      model: 'asset_building5.glb',
      name: 'Office Building 5',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_showroom_1',
      model: 'asset_showroom_1.glb',
      name: 'Showroom 1',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_condo_1',
      model: 'asset_condo_1.glb',
      name: 'Condo 1',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_condo_tree.glb
    {
      guid: 'asset_condo_tree',
      model: 'asset_condo_tree.glb',
      name: 'Condo with Trees',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'reatil-computer-1',
      model: 'asset_retail_computer_1.glb',
      name: 'Retail Computer 1',
      category: 'Retail',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_market_1',
      model: 'asset_market_1.glb',
      name: 'Market 1',
      category: 'Retail',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'comp-asset-warehouse-interior-1',
      model: 'asset_warehouse_1.glb',
      name: 'Warehouse Interior 1',
      category: 'Interior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_storage_tank_2',
      model: 'asset_storage_tank_2.glb',
      name: 'Storage Tank 2',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_storage_tank_1',
      model: 'asset_storage_tank_1.glb',
      name: 'Storage Tank 1',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'comp-asset-truck-1',
      model: 'asset_truck_1.glb',
      name: 'Truck 1',
      category: 'Vehicle',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'comp-asset-truck-3',
      model: 'asset_truck_3.glb',
      name: 'Truck 3',
      category: 'Vehicle',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'comp-asset-truck-4',
      model: 'asset_truck_4.glb',
      name: 'Truck 4',
      category: 'Vehicle',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_car_a',
      model: 'asset_car_a.glb',
      name: 'Car A',
      category: 'Vehicle',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_car_b',
      model: 'asset_car_b.glb',
      name: 'Car B',
      category: 'Vehicle',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_car_c',
      model: 'asset_car_c.glb',
      name: 'Car C',
      category: 'Vehicle',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, // asset_billboard_indoor.glb
    {
      guid: 'asset_store_front',
      model: 'asset_store_front.glb',
      name: 'Storefront A',
      category: 'Retail',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, // asset_store_front.glb   
    {
      guid: 'asset_billboard_indoor',
      model: 'asset_billboard_indoor.glb',
      name: 'Billboard Indoor',
      category: 'Retail',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, // asset_store_front.glb    
    {
      guid: 'asset_bus_station_billboard',
      model: 'asset_bus_station_billboard.glb',
      name: 'Bus Stop',
      category: 'Retail',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //     
    {
      guid: 'asset_warehouse_exterior_2',
      model: 'asset_warehouse_exterior_2.glb',
      name: 'Warehouse Exterior',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'manufacture-robot-1',
      model: 'manufacture_robot_1.glb',
      name: 'Manufacturing Robot Conveyor',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_robot_arm.glb
    {
      guid: 'asset_robot_arm-1',
      model: 'asset_robot_arm.glb',
      name: 'Manufacturing Robot Arm',
      category: 'Industrial',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_logistics_crane  
    {
      guid: 'asset_logistics_crane-1',
      model: 'asset_logistics_crane.glb',
      name: 'Logistics Crane',
      category: 'Logistics',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_logistics_crane      
    // {
    //   guid: 'warehouse-exterior-2',
    //   model: 'warehouse-exterior-2.glb',
    //   name: 'Warehouse Exterior 2',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // },
    // {
    //   guid: 'asset_tesla',
    //   model: 'asset_tesla.glb',
    //   name: 'asset_tesla',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // },
    {
      guid: 'comp-home-van-1',
      model: 'asset_home_van.glb',
      name: 'Home with Van 1',
      category: 'Exterior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    // {
    //   guid: 'asset_building_residential_1',
    //   model: 'asset_building_residential_1.glb',
    //   name: 'Building Residential 1',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // },
    // {
    //   guid: 'asset_self_drive_1',
    //   model: 'asset_self_drive_1.glb',
    //   name: 'Self Driving Car 1',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // },
    {
      guid: 'living_room_1',
      model: 'asset_living_room_1.glb',
      name: 'Living Room 1',
      category: 'Interior',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_data_center_racks',
      model: 'asset_data_center_racks.glb',
      name: 'Data Center Racks',
      category: 'Technology',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_cloud',
      model: 'asset_cloud.glb',
      name: 'Cloud Computing',
      category: 'Technology',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_server',
      model: 'asset_server.glb',
      name: 'Server',
      category: 'Technology',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'retail_fitness_1',
      model: 'asset_retail_fitness.glb',
      name: 'Retail Fitness 1',
      category: 'Retail',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'gym_1',
      model: 'asset_gym_1.glb',
      name: 'Gym 1',
      category: 'Retail',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'asset_gym_bike_inside',
      model: 'asset_gym_bike_inside.glb',
      name: 'Gym Bike Gears',
      category: 'Retail',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_gym_bike_inside
    {
      guid: 'gym_bike_1',
      model: 'asset_gym_bike.glb',
      name: 'Gym Bike 1',
      category: 'Retail',
      pos: { x: 0, y: 0 },
      scale: 1,
    },//asset_gym_bike_inside
    {
      guid: 'asset_container_ship',
      model: 'asset_container_ship.glb',
      name: 'Container Ship',
      category: 'Logistics',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'package-1',
      model: 'asset_package.glb',
      name: 'Package 1',
      category: 'Logistics',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_shipping_box
    {
      guid: 'package-2',
      model: 'asset_shipping_box.glb',
      name: 'Package 2',
      category: 'Logistics',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_billboard_large    
    {
      guid: 'Billboard-Large',
      model: 'asset_billboard_large.glb',
      name: 'Billboard Large',
      category: 'Retail',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //     asset_plat_cap_road-2   
    {
      guid: 'asset_boat_dock',
      model: 'asset_boat_dock.glb',
      name: 'Boat Dock',
      category: 'Transportation',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //     asset_plat_cap_road-2       
    // asset_sail_laser.glb
    {
      guid: 'asset_sail_laser',
      model: 'asset_sail_laser.glb',
      name: 'Boat Sail Small',
      category: 'Transportation',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, // asset_boat_dinghy
    {
      guid: 'asset_boat_dinghy',
      model: 'asset_boat_dinghy.glb',
      name: 'Boat Dinghy',
      category: 'Transportation',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, // asset_boat_sail_large    
    {
      guid: 'asset_boat_sail_large',
      model: 'asset_boat_sail_large.glb',
      name: 'Boat Sail Large',
      category: 'Transportation',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, // asset_light_sea_buoy   
    {
      guid: 'asset_light_sea_buoy',
      model: 'asset_light_sea_buoy.glb',
      name: 'Light Sea Buoy',
      category: 'Transportation',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, // asset_light_sea_buoy        

    //asset_boat_dock

    // {
    //   guid: 'comp-job-site-1',
    //   model: 'asset_job_site.glb',
    //   name: 'Job Site',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // },
    // {
    //   guid: 'platform-ocean-1',
    //   model: 'platform_ocean.glb',
    //   name: 'Platform Ocean',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // },
    // {
    //   guid: 'asset_3_logo',
    //   model: 'asset_3_logo.glb',
    //   name: '3 Logo',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // },
    {
      guid: 'asset_lighhouse',
      model: 'asset_lighhouse.glb',
      name: 'Lighthouse',
      category: 'Transportation',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_lighhouse.glb
    {
      guid: 'asset_clift',
      model: 'asset_clift.glb',
      name: 'Clift',
      category: 'Nature',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_lighhouse.glb
    {
      guid: 'asset_tree_a',
      model: 'asset_tree_a.glb',
      name: 'Tree A',
      category: 'Nature',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_clift.glb
    {
      guid: 'asset_tree_b',
      model: 'asset_tree_b.glb',
      name: 'Tree B',
      category: 'Nature',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //    
    {
      guid: 'asset_background_trees_a',
      model: 'asset_background_trees_a.glb',
      name: 'Background Trees A',
      category: 'Nature',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_mini_grass
    {
      guid: 'asset_mini_grass',
      model: 'asset_mini_grass.glb',
      name: 'Background Grass',
      category: 'Nature',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_mini_grass    
    {
      guid: 'mini_background_corn_growing',
      model: 'mini_background_corn_growing.glb',
      name: 'Background Corn Growing',
      category: 'Nature',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    // {
    //   guid: 'mini_background_city',
    //   model: 'mini_background_city.glb',
    //   name: 'Mini Background City',
    //   matcap: 'EAEAEA_B6B6B6_CCCCCC_C4C4C4',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // },
    // {
    //   guid: 'mini_background_homes',
    //   model: 'mini_background_homes.glb',
    //   name: 'Mini Background Home',
    //   matcap: 'EAEAEA_B6B6B6_CCCCCC_C4C4C4',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // },
    // {
    //   guid: 'mini_background_industry',
    //   model: 'mini_background_industry.glb',
    //   name: 'Mini Background Industry',
    //   matcap: 'EAEAEA_B6B6B6_CCCCCC_C4C4C4',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // },
    {
      guid: 'plat_rect_rounded_tall_land',
      model: 'plat_rect_rounded_tall_land.glb',
      name: 'plat_rect_rounded_tall_land',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'plat_rect_rounded_tall_dirt',
      model: 'plat_rect_rounded_tall_dirt.glb',
      name: 'plat_rect_rounded_tall_dirt',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'plat_rect_rounded_tall_road',
      model: 'plat_rect_rounded_tall_road.glb',
      name: 'plat_rect_rounded_tall_road',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //platform_chevron_construction
    {
      guid: 'platform_chevron_construction',
      model: 'platform_chevron_construction.glb',
      name: 'platform_chevron_construction',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //platform-octagon_caution   
    {
      guid: 'platform-octagon_caution',
      model: 'platform-octagon_caution.glb',
      name: 'platform-octagon_caution',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //platform-octagon_caution        

    {
      guid: 'platform_real_sidewalk_1',
      model: 'platform_real_sidewalk_1.glb',
      name: 'platform_real_sidewalk_1',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'platform_real_sidewalk_2',
      model: 'platform_real_sidewalk_2.glb',
      name: 'platform_real_sidewalk_2',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'platform_real_sidewalk_3',
      model: 'platform_real_sidewalk_3.glb',
      name: 'platform_real_sidewalk_3',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    },


    {
      guid: 'platform_square_construction',
      model: 'platform_square_construction.glb',
      name: 'platform_square_construction',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //plat_rect_rounded_tall_cap_dirt    
    {
      guid: 'plat_rect_rounded_tall_cap_wood',
      model: 'plat_rect_rounded_tall_cap_wood.glb',
      name: 'Rounded Wood Cap',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    },
    {
      guid: 'plat_rect_rounded_tall_cap_dirt',
      model: 'plat_rect_rounded_tall_cap_dirt.glb',
      name: 'Rounded Dirt Cap',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //plat_flow_right_tall_cap_metal.glb     
    {
      guid: 'plat_rect_rounded_tall_cap_metal',
      model: 'plat_rect_rounded_tall_cap_metal.glb',
      name: 'Rounded Metal Cap',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //asset_plans.glb
    {
      guid: 'plat_flow_right_tall_cap_dirt',
      model: 'plat_flow_right_tall_cap_dirt.glb',
      name: 'Chevron Dirt Cap',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //plat_flow_right_tall_cap_dirt.glb         
    {
      guid: 'plat_flow_right_tall_cap_metal',
      model: 'plat_flow_right_tall_cap_metal.glb',
      name: 'Chevron Metal Cap',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //platform_chevron_construction_cap_dock.glb    
    {
      guid: 'platform_chevron_construction_cap_dock',
      model: 'platform_chevron_construction_cap_dock.glb',
      name: 'Chevron Dock Cap',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //platform_chevron_construction_cap_concrete.glb     
    {
      guid: 'platform_chevron_construction_cap_concrete',
      model: 'platform_chevron_construction_cap_concrete.glb',
      name: 'Chevron Office Cap A',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //platform_chevron_construction_cap_industrial_dark.glb    
    {
      guid: 'platform_chevron_construction_cap_industrial_dark',
      model: 'platform_chevron_construction_cap_industrial_dark.glb',
      name: 'Chevron Industrial Dark Cap',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //platform_chevron_construction_cap_industrial_dark.glb      
    {
      guid: 'platform_chevron_construction_cap_industrial',
      model: 'platform_chevron_construction_cap_industrial.glb',
      name: 'Chevron Industrial Cap',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //platform_chevron_construction_cap_road.glb   
    {
      guid: 'platform_chevron_construction_cap_road',
      model: 'platform_chevron_construction_cap_road.glb',
      name: 'Chevron Industrial Road',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //platform_chevron_construction_cap_road.glb                      
    {
      guid: 'asset_plat_cap_road',
      model: 'asset_plat_cap_road.glb',
      name: 'Cap Road and Grass',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //     asset_plat_cap_road       
    {
      guid: 'asset_plat_cap_road-2',
      model: 'asset_plat_cap_road-2.glb',
      name: 'Cap Road and Grass 2',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //     asset_plat_cap_tile       
    {
      guid: 'asset_plat_cap_tile',
      model: 'asset_plat_cap_tile.glb',
      name: 'Cap Tile',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //     asset_plat_cap_tile   
    {
      guid: 'asset_plat_cap_tile-2',
      model: 'asset_plat_cap_tile-2.glb',
      name: 'Cap Tile 2',
      category: 'Platform Top',
      pos: { x: 0, y: 0 },
      scale: 1,
    }, //     asset_plat_cap_tile         
    {
      guid: 'asset_arrow_chevrons_right',
      model: 'asset_arrow_chevrons_right.glb',
      name: 'Chevrons Right',
      category: 'Symbol',
      pos: { x: 0, y: 0, z: 0 },
      scale: 1,
    },//asset_arrow_chevrons_right.glb        
    {
      guid: 'asset_arrow_l_to_r_curved',
      model: 'asset_arrow_l_to_r_curved.glb',
      name: 'Arrow Curve Left to Right',
      category: 'Symbol',
      pos: { x: 0, y: 0, z: 0 },
      scale: 1,
    },
    {
      guid: 'asset_arrow_both_directions_a',
      model: 'asset_arrow_both_directions_a.glb',
      name: 'Arrow Both Directions A',
      category: 'Symbol',
      pos: { x: 0, y: 0, z: 0 },
      scale: 1,
    },
    {
      guid: 'asset_arrow_both_directions_b',
      model: 'asset_arrow_both_directions_b.glb',
      name: 'Arrow Both Directions B',
      category: 'Symbol',
      pos: { x: 0, y: 0, z: 0 },
      scale: 1,
    },
    // {
    //   guid: 'asset_plans',
    //   model: 'asset_plans.glb',
    //   name: 'Blueprints',
    //   category: 'Construction',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // }, //asset_plans.glb                
    // {
    //   guid: 'platform_base_3_scene_construction_2',
    //   model: 'platform_base_3_scene_construction_2.glb',
    //   name: 'platform_base_3_scene_construction_2',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // }, //asset_excavator            
    // {
    //   guid: 'background_construction_rail',
    //   model: 'background_construction_rail.glb',
    //   name: 'background_construction_rail',
    //   pos: { x: 0, y: 0 },
    //   scale: 1,
    // }, 

  ]
  const componentDeviceList = [
    {
      guid: 'device-phone',
      name: 'Phone',
    },
    {
      guid: 'device-tablet',
      name: 'Tablet',
    },
    {
      guid: 'device-desktop',
      name: 'Desktop',
    },
  ]
  const groundList = [
    {
      guid: 'background_full_large',
      name: 'Infinite Smooth',
      model: 'background_full_large',
    },
    {
      guid: 'background_wall',
      name: 'Wall Smooth with Ledge',
      model: 'background_wall',
    },//background_wall_2
    {
      guid: 'background_wall_2',
      name: 'Wall Sharp with Ledge',
      model: 'background_wall_2',
    },//background_wall_2
    {
      guid: 'background_wall_2b',
      name: 'Wall Sharp',
      model: 'background_wall_2b',
    },//background_wall_2    
    {
      guid: 'background_wall_3',
      name: 'Wall Sharp no Ledge',
      model: 'background_wall_3',
    },//background_wall_2    
    {
      guid: 'background_circle',
      name: 'Background Circle',
      model: 'background_circle',
    },


    // {
    //   guid: 'background_abstract_squares',
    //   name: 'Abstract Squares',
    //   model: 'background_abstract_squares',
    // },
    // {
    //   guid: 'background_abstract_squares_2',
    //   name: 'Abstract Squares 2',
    //   model: 'background_abstract_squares_2',
    // },
    {
      guid: 'background_abstract_data',
      name: 'Abstract Data',
      model: 'background_abstract_data',
    },
    {
      guid: 'background_full',
      name: 'Full (original)',
      model: 'background_full',
    },
    {
      guid: 'background_hills',
      name: 'Hills',
      model: 'background_hills',
    },
    {
      guid: 'background_plain',
      name: 'Rail - Medium',
      model: 'background_plain',
    }, //background_rail.glb
    {
      guid: 'background_rail',
      name: 'Rail - Narrow',
      model: 'background_rail',
    }, //background_rail.glb      
    // {
    //   guid: 'background_plain_homes',
    //   name: 'Plain Homes',
    //   model: 'background_plain_homes',
    // },
    // {
    //   guid: 'background_plain_city',
    //   name: 'City',
    //   model: 'background_plain_city',
    // },
    // {
    //   guid: 'background_plain_industry',
    //   name: 'Industry',
    //   model: 'background_plain_industry',
    // },
    {
      guid: 'background_ocean',
      name: 'Ocean Water (animated)',
      model: 'background_ocean',
    },
    {
      guid: 'background_abstract_hills',
      name: 'Abstract Hills',
      model: 'background_abstract_hills',
    },//background_platforms    
    {
      guid: 'background_blank',
      name: 'Empty',
      model: 'background_blank',
    },
  ]
  const skyPlusList = [
    {
      name: 'Clear',
      value: 'clear',
    },
    {
      name: 'Clouds',
      value: 'Clouds',
    },
  ]
  const matList = [
    // {
    //   name: 'gen_mat_ai_test',
    //   value: 'gen_mat_ai_test',
    // },
    {
      name: 'MC-Test1',
      value: 'MC-Sample',
    },
    {
      name: 'MC-Test2',
      value: 'MC-Sample2',
    },
    {
      name: 'MC-Test3',
      value: 'MC-Sample3',
    },
    {
      name: 'Steel',
      value: '555555_C8C8C8_8B8B8B_A4A4A4',
    },
    {
      name: 'Gray No Reflect',
      value: '696969_A0A0A0_949494_8C8C8C',
    },
    {
      name: 'Metal',
      value: '434240_D3D3CF_898784_A4A49F',
    },
    {
      name: 'White',
      value: 'C8C8C8_3F3F3F_787878_5C5C5C',
    },
    {
      name: 'Black Matte',
      value: '434240_D3D3CF_898784_A4A49F_mod3',
    },
    {
      name: 'Dark Gray',
      value: '32302B_4C4842_121211_1C1C14',
    }, //blue-metal-2
    {
      name: 'blue-metal-2',
      value: 'blue-metal-2',
    }, //dark -bronze-1    
    {
      name: 'dark-bronze-1',
      value: 'dark-bronze-1',
    }, //dark-bronze-2
    {
      name: 'dark-bronze-2',
      value: 'dark-bronze-2',
    }, //gray-metal-1
    {
      name: 'gray-metal-1',
      value: 'gray-metal-1',
    }, //gray-metal-1
    {
      name: 'gray-metal-2',
      value: 'gray-metal-2',
    }, //gray-metal-1    
    {
      name: 'gray-metal-3',
      value: 'gray-metal-3',
    },
    {
      name: 'dark-metal-a-silver',
      value: '525050_D4D3D3_959393_ACACAC',
    },    
    {
      name: 'dark-metal-a',
      value: '1B1C19_5F615D_4B4E4C_3F403D',
    },
    {
      name: 'dark-metal-b',
      value: '1C1810_352F23_2B2C1C_2B2C24',
    },
    {
      name: 'dark-metal-c',
      value: '0F0F0F_4B4B4B_1C1C1C_2C2C2C',
    },
    {
      name: 'gray-metal-4',
      value: 'gray-metal-4',
    },
    {
      name: 'gray-metal-5',
      value: 'gray-metal-5',
    },
    {
      name: 'White Matte',
      value: '434240_D3D3CF_898784_A4A49F_mod2',
    },
    {
      name: 'White Matte 2',
      value: 'EAEAEA_B6B6B6_CCCCCC_C4C4C4',
    },
    {
      name: 'Black Shine',
      value: '0A0A0A_A9A9A9_525252_747474',
    },
    {
      name: 'MC-Test4',
      value: 'MC-Sample4',
    },
    {
      name: 'Black 2',
      value: '15100F_241D1B_292424_2C2C27',
    },
    {
      name: 'MC-Test5',
      value: 'MC-Sample5',
    },

    {
      name: 'Blue',
      value: '2A4BA7_1B2D44_1F3768_233C81',
    },
    {
      name: 'Light Blue',
      value: '65A0BA_C3E4F1_A7D5E6_97CADF',
    },
    {
      name: 'Gray Blue',
      value: '647686_23292E_333C44_404B55',
    },
    {
      name: 'Gray Blue 2',
      value: '2F3747_6A7C9E_54637F_62748B',
    },
    {
      name: 'Gray Blue 3',
      value: '4D595E_858D87_596F84_748480',
    },
    {
      name: 'Brown / Gray',
      value: '877C72_B6ACBB_36322D_4C443B',
    },
    {
      name: 'Brown',
      value: '8F5D3C_5B3B26_382416_70482E',
    },
    {
      name: 'Brown Light',
      value: 'AC8961_EBDAC3_D1B791_DCC2A0',
    },
    {
      name: 'Brown Light 2',
      value: 'B09273_7A573D_C7AF97_84644C',
    },
    {
      name: 'Brown Light 3',
      value: 'AF986F_CDB489_9C7C5B_7E6C4D',
    },
    {
      name: 'Brown Light 4',
      value: 'CAAC80_FAEBD5_F2DEBF_EAD1AF',
    },
    {
      name: 'Orange',
      value: '9D602E_E4C363_D5A64F_C38A44',
    }, {
      name: 'White',
      value: 'ADC2CD_DFF7FA_C9E6F5_C1DBEC',
    }, {
      name: 'Cream',
      value: 'C7B9A1_F8F1E4_EEE4D2_E4D8C4',
    },
    {
      name: 'Cream-light',
      value: 'C7B9A1_F8F1E4_EEE4D2_E4D8C4-light',
    },
    {
      name: 'White Bump',
      value: 'CBCBCB_595959_8C8C8C_747474',
    },
    {
      name: 'Earth Color 1',
      value: '6A5338_1C120B_C5975F_96886C',
    },
    {
      name: 'Earth Color 2',
      value: '5E5049_CDC2BE_9C8E88_B7ACA4',
    },
    {
      name: 'Earth Color 3',
      value: '5E423A_8C6E69_382416_745D64',
    },
    {
      name: 'Earth Color 4',
      value: '7C7C71_353630_ADAFAF_444444',
    },
    {
      name: 'Earth Color 5',
      value: '8A7968_3C342D_C6B3A5_54443B',
    },
    {
      name: 'Earth Color 6',
      value: '9F886D_362F25_E8C9A4_5C4C3C',
    },
    {
      name: 'Earth Color 7',
      value: '535F6B_A6BDC8_8FA3B4_7F91A2',
    },
    {
      name: 'Earth Color 8',
      value: '686E55_353C2F_869B7F_444434',
    },
    {
      name: 'BlueGround',
      value: '17395A_7EBCC7_4D8B9F_65A1B5',
    },
    {
      name: 'EarthGround2',
      value: '56352F_1E110F_311C19_3C231C',
    },
    {
      name: 'Blue2',
      value: '385264_A1D3E2_86ADC1_6E94A8',
    },
    {
      name: 'Blue22',
      value: '522221_91343B_7F6F6B_744960',
    },
    {
      name: 'Blue2222',
      value: '835984_39273A_4C334C_593D5A',
    },
    {
      name: 'Blue222222',
      value: 'AC725C_2E1610_663E31_E78F6B',
    },
    {
      name: 'Blue22dd2222',
      value: 'CA4FE1_6B2398_A337C7_9334BC',
    },
    {
      name: 'Blue22dd2222Teal',
      value: '046363_0CC3C3_049B9B_04ACAC',
    },
    {
      name: 'dirt-light',
      value: 'dirt-light',
    },
    {
      name: 'dirt-med',
      value: 'dirt-med',
    },
    {
      name: 'dirt-dark',
      value: 'dirt-dark',
    },
    {
      name: 'Red',
      value: 'C30C0C_9F0404_830404_5C0404',
    },
    {
      name: 'Red 2',
      value: 'D04444_AF2F2F_8B2424_9B2C2C',
    },
    {
      name: 'Yellow',
      value: 'C47004_F9D00C_EDAF04_E09704',
    },
    {
      name: 'Yellow 2',
      value: 'FBB82D_FBEDBF_FBDE7D_FB7E05',
    },
    {
      name: 'Yellow 3',
      value: 'D1AC04_F8E50A_EDD004_B38D04',
    },
    {
      name: 'Dark Purple',
      value: 'E804E8_B504B5_CC04CC_FC33FC_3',
    }
  ]

  const [components, setComponents] = useState(componentsList)
  const [componentsActive, setComponentsActive] = useState([])
  const [configureActiveComponent, setConfigureActiveComponent] = useState([])
  const [activeComponentData, setActiveComponentData] = useState([])
  const [editHistory, setEditHistory] = useState([])
  const [designPreviewActive, setDesignPreviewActive] = useState(false)

  const betaReleaseActive = 1

  // createTemplate
  const createTemplate = async (e) => {
    e.preventDefault(e);
    if (input === '') {
      alert('Please enter a template name');
      return;
    }
    // await addDoc(collection(db, 'stories', storyId, 'scenes'), {
    //   name: input,
    //   description: '',
    //   hidden: false,
    //   guid: uuidv4(),
    //   platform: {
    //     model: 'platform_a_cap',
    //     matcap: '434240_D3D3CF_898784_A4A49F_mod4',
    //     text: {
    //       color: '#ffffff',
    //       scale: 1.2
    //     }
    //   },
    //   order: sceneData.length,
    //   pos: { x: 0, y: 0, z: 0 },
    //   camOffset: {
    //     position: { x: 0, y: 0, z: 0 },
    //     target: { x: 0, y: 0, z: 0 }
    //   },
    //   viznodes: [],
    // })
    // setInput('')
    // setToggleComponentBlockMode(false)

    // setSessionData(prevState => ({
    //   ...prevState,
    //   screenshotModeActive: true,
    //   activeIndex: sceneData.length
    // }))
  };

  const createScene = async (e) => {
    e.preventDefault(e);
    if (input === '') {
      alert('Please enter a valid scene name');
      return;
    }
    const newScene = await addDoc(collection(db, 'stories', storyId, 'scenes'), {
      name: input,
      description: '',
      hidden: false,
      guid: uuidv4(),
      layout: false,
      platform: {
        model: 'platform_a_cap',
        matcap: '434240_D3D3CF_898784_A4A49F_mod4',
        text: {
          color: '#ffffff',
          scale: 1.2
        }
      },
      order: sceneData.length,
      pos: { x: 0, y: 0, z: 0 },
      camOffset: {
        position: { x: 0, y: 0, z: 290 },
        target: { x: 0, y: 0, z: 0 }
      },
      viznodes: [],
    })
    setInput('')
    setToggleComponentBlockMode(false)

    setSessionData(prevState => ({
      ...prevState,
      screenshotModeActive: true,
      activeIndex: sceneData.length
    }))

    logCustomEvent('create_scene', {})

    // console.log('yo!')

    // onChangeScene(sceneData.length)
    // setTimeout(() => {
    //      props.updateSceneFunction(sceneData.length)
    //    }, 500);



    // const newSceneIdx = sceneData.length + 1;
    // updateSceneFunction(sceneData.length)

    // console.log('Active Index Details')
    // console.log(sceneData.length)

    // setTimeout(() => {
    //   console.log('-------------------------')
    //   props.updateSceneFunction(sceneData.length)
    // }, 1500);

    // Test screenshot
    // Screenshot()
    // Function
    // Snap of the current setup...
    // grabQuickScreenshot()
  };


  // Take screen grab of the current view
  const grabQuickScreenshot = async () => {
    setSessionData(prevState => ({
      ...prevState,
      quickScreenshotModeActive: true,
    }))

  }

  // const toggleHidden = async (scene) => {
  // const sceneRef = doc(db, 'stories', storyId, 'scenes', scene.id);
  // await updateDoc(sceneRef, {
  //   hidden: !scene.hidden
  // })
  // }

  const loadActiceComponents = (index) => {
    // let activeSceneData = sceneData[index]
    // setComponentsActive(activeSceneData.viznodes)
  }

  const addComponent = async (item) => {
    item.guid = uuidv4()
    let cloneComponentsActive = []

    if (sceneData[sessionData.activeIndex].viznodes != null) {
      cloneComponentsActive = sceneData[sessionData.activeIndex].viznodes
    }

    if (item.id == 3) {

      item['model'] = 'Placeholder-Cube.glb'
    }

    cloneComponentsActive.push(item)
    let activeSceneId = sceneData[sessionData.activeIndex].id

    const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId);
    await updateDoc(sceneRef, {
      viznodes: cloneComponentsActive
    })

    setComponentsActive(cloneComponentsActive)

    const panels = document.querySelectorAll('.accordion__panel');
    panels.forEach((panel, index) => {
      if (index !== panels.length - 1) {
        panel.setAttribute('hidden', '');
      } else {
        panel.removeAttribute('hidden');
      }
    });
    const buttons = document.querySelectorAll('.accordion__button');
    buttons.forEach((button, index) => {
      if (index !== buttons.length - 1) {
        button.setAttribute('aria-expanded', 'false');
      } else {
        button.setAttribute('aria-expanded', 'true');
        button.classList.add('last-accordion-button');

        setTimeout(function () {
          button.classList.remove('last-accordion-button');
          button.classList.remove('last-accordion-panel');
        }, 3000);
      }
    });

    logCustomEvent('add_component', {})

  }

  // useEffect(() => {
  //   console.log(editHistory)
  // }, editHistory)

  // create useEffect to listen for changes in editHistory and output them to console when they change
  // useEffect(() => {
  //   console.log(editHistory)
  // }
  //   , [editHistory])


  const removeActiveComponent = async (item) => {
    //let cloneComponentsActive = [...componentsActive]
    //cloneComponentsActive.push(item)
    // alert('BAM!')



    // toast.custom((t) => (
    //   <div
    //     className={`${t.visible ? 'animate-enter' : 'animate-leave'
    //       } max-w-md w-full bg-zinc-900 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    //   style={{zIndex: 1000000001}}
    //   >
    //     <div className="flex-1 w-0 p-4">
    //       <div className="flex items-start">
    //         <div className="flex-shrink-0 pt-0.5">
    //           <img src="/media/icon/noun-clone-2075584-FFFFFF.svg" className="w-5 opacity-100 inline mr-4" />
    //         </div>
    //         <div className="ml-3 flex-1">
    //           <p className="text-sm font-medium text-gray-100">
    //             {item.name} has been copied
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // ))

    // console.log('---->>>>>>>>>>>> GOT ITEM')
    // console.log(item)
    // console.log('/////////  ---->>>>>>>>>>>> GOT ITEM')

    toast.info(item.name + ' has been deleted.  To Undo, click the recycle icon and select the item to recover.', {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: false,
      // progress: undefined,
      theme: "dark",
      data: {
        item: item,
        time: Date.now() //serverTimestamp()
      },
      //transition: "Slide",
    })


    // Show recycle bin has had items added
    setTimeout(() => {
      setStatusRecycle(' animate__heartBeat opacity-100 ')
    }, 250);

    setTimeout(() => {
      setStatusRecycle(' opacity-50 ')
    }, 2500);


    let cloneComponentsActive = sceneData[sessionData.activeIndex].viznodes
    let activeSceneId = sceneData[sessionData.activeIndex].id

    // console.log('removeActiveComponent')
    // console.log(item)


    const indexData = cloneComponentsActive.indexOf(item);
    if (indexData > -1) { // only splice array when item is found
      cloneComponentsActive.splice(indexData, 1); // 2nd parameter means remove one item only
    }

    const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId);
    await updateDoc(sceneRef, {
      viznodes: cloneComponentsActive
    })

    // const sceneRef = doc(db, 'stories', storyId, 'scenes', id);
    // await deleteDoc(sceneRef)

    setComponentsActive(cloneComponentsActive)

    logCustomEvent('remove_component', {})
  }

  const activeComponentConfig = (item) => {
    setConfigureActiveComponent(item)
  }


  const updateActiveComponentData = (item) => {
    setActiveComponentData(item)
  }


  const updateBackground = (backgroundStyle) => {
    const element = document.getElementById('root')
    element.className = backgroundStyle

    // 
    // lightGray

  }

  // FOR TOGGLES (LATER USE IN UI FOR VARIOUS DISPLAY MODES...)
  const [ideMode, setIdeMode] = useState({ checked: false })
  const handleChange = (checked) => {
    setIdeMode({ checked })
  }


  function setThemeColor(newColor, dynVar) {

    switch (dynVar) {
      case 'bg-nav-theme':
        setColorNavBg(newColor)
        break;
      case 'bg-nav-button-theme':
        setColorNavButtonBg(newColor)
        break;
      case 'bg-nav-button-text-theme':
        setColorNavButtonTextBg(newColor)
        break;
    }

    document.documentElement.style.setProperty('--' + dynVar, newColor);
  }



  // TODO - add images to scene here...
  const selectSceneComponent = async (sceneItem, index, filename) => {

    // console.log('selectSceneComponent')
    // console.log(sceneItem)
    // console.log(index)
    // console.log(sceneData[sessionData.activeIndex].viznodes[index])

    let cloneComponentsActive = []
    cloneComponentsActive = [...sceneData[sessionData.activeIndex].viznodes]

    cloneComponentsActive[index].model = sceneItem.model

    cloneComponentsActive[index].scale = 1

    cloneComponentsActive[index].rot = { x: 0, y: 0, z: 0 }

    if (sceneItem.modelThumbnail != undefined) {
      cloneComponentsActive[index].modelThumbnail = sceneItem.modelThumbnail
      cloneComponentsActive[index].scale = 15 //sceneItem.modelThumbnail
      cloneComponentsActive[index].rot = { x: sceneItem.rot.x, y: sceneItem.rot.y, z: sceneItem.rot.z }
      cloneComponentsActive[index].pos = { x: sceneItem.pos.x, y: sceneItem.pos.y, z: sceneItem.pos.z }
    }

    // console.log('cloneComponentsActive')
    // console.log(cloneComponentsActive)

    if (sceneItem.matcap != undefined) {
      cloneComponentsActive[index].matcap = sceneItem.matcap
    }
    else {
      // Clear the matcap from the item...
      delete cloneComponentsActive[index].matcap
    }

    let activeSceneId = sceneData[sessionData.activeIndex].id
    const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId);
    await updateDoc(sceneRef, {
      viznodes: cloneComponentsActive
    })

    setComponentsActive(cloneComponentsActive)
  }



  const panelSlideDistance = 380
  //let mainPanelOpen = 0
  const [mainPanelOpen, setMainPanelOpen] = useState(false)
  const HidePanelMain = () => {

    setEnabledMode(!enabledMode)

    //    console.log('menu event')
    if (mainPanelOpen) {
      anime({
        targets: '#panel-scenes',
        translateX: 0,
        easing: 'easeInOutExpo'
      });
      anime({
        targets: '#panel-detail',
        translateX: 0,
        easing: 'easeInOutExpo'
      });

      setMainPanelOpen(false)

    }
    else {
      anime({
        targets: '#panel-scenes',
        translateX: -1 * panelSlideDistance,
        easing: 'easeInOutExpo'
      });
      anime({
        targets: '#panel-detail',
        translateX: panelSlideDistance,
        easing: 'easeInOutExpo'
      });
      // anime({
      //   targets: '#panel-story-control',
      //   translateY: -68,
      //   easing: 'easeInOutExpo'
      // });      
      // anime({
      //   targets: '#playModeNavigationTheme',
      //   translateY: -140,
      //   easing: 'easeInOutExpo'
      // });
      //mainPanelOpen = 1
      setMainPanelOpen(true)
    }

    // right side ID = panel-detail
    // panel-detail

  }

  const ToggleSideMode = () => {
    TogglePlayMode()
    const el = document.querySelector('.h-full');
    el.classList.toggle('mode-side');
    el.classList.toggle('pip');
    setSessionData(prevState => ({
      ...prevState,
      modeVideo: 'pip'
    }))
  }



  const TogglePlayMode = () => {
    if (sessionData.playModeActive) {

      anime({
        targets: '#panel-story-control',
        translateY: 100,
        easing: 'easeInOutExpo',
        delay: 0
      });

      anime({
        targets: '.scene-edit-overlay.left',
        translateX: 0,
        easing: 'easeInOutExpo',
        delay: 1000
      });
      anime({
        targets: '.scene-edit-overlay.right',
        translateX: 0,
        easing: 'easeInOutExpo',
        delay: 1000
      });

      let editMode = false
      let location = useLocation()

      let navDelay = 1000

      anime({
        targets: '#playModeNavigation',
        translateY: 0,
        easing: 'easeInOutExpo',
        delay: navDelay
      });

      anime({
        targets: '#panel-story-control',
        translateY: 0,
        easing: 'easeInOutExpo',
        duration: 1000
      });

      setSessionData(prevState => ({
        ...prevState,
        playModeActive: 0
      }))
    }
    else {

      anime({
        targets: '.scene-edit-overlay.left',
        translateX: -340,
        easing: 'easeInOutExpo'
      });
      anime({
        targets: '.scene-edit-overlay.right',
        translateX: 380,
        easing: 'easeInOutExpo'
      });

      let navDelay = 1000

      anime({
        targets: '#playModeNavigation',
        translateY: -170,
        easing: 'easeInOutExpo',
        delay: navDelay
      });

      anime({
        targets: '#panel-story-control',
        translateY: -68,
        easing: 'easeInOutExpo',
        delay: 0
      });

      setSessionData(prevState => ({
        ...prevState,
        playModeActive: 1
      }))
    }
  }

  var moveInArray = function (arr, from, to) {
    // Make sure a valid array is provided
    if (Object.prototype.toString.call(arr) !== '[object Array]') {
      throw new Error('Please provide a valid array');
    }
    // Delete the item from it's current position
    var item = arr.splice(from, 1);
    // Make sure there's an item to move
    if (!item.length) {
      throw new Error('There is no item in the array at index ' + from);
    }
    // Move the item to its new position
    arr.splice(to, 0, item[0]);
  };


  // Update the order of the scenes
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    let itemsArrayReorder = []
    itemsArrayReorder = [...sceneData]

    moveInArray(itemsArrayReorder, result.source.index, result.destination.index)

    // console.log('ITEMS REORDER....')
    // console.log(itemsArrayReorder)

    const batch = writeBatch(db);
    itemsArrayReorder.forEach((item, index) => {
      // console.log('item')
      // console.log(item)
      //console.log(storyData.id)
      const sceneRef = doc(db, 'stories', storyId, 'scenes', item.id)
      // console.log('sceneRef')
      // console.log(sceneRef)
      batch.update(sceneRef, { order: index }); // Update the document with the data from the array
    });

    try {
      await batch.commit();
      //setSceneData(itemsArrayReorder)
      //console.log('Batch update successful');
    } catch (error) {
      //console.log('Error updating documents:', error);
    }
  }


  // Prepare the color selections 
  const [colorNavBg, setColorNavBg] = useState()
  const [colorNavButtonBg, setColorNavButtonBg] = useState()
  const [colorNavButtonTextBg, setColorNavButtonTextBg] = useState()

  // TODO - use as reorder data?
  const [sceneLiveData, setSceneLiveData] = useState(sceneData);
  const setSceneNameInput = (e, id) => {
    var result = [...sceneLiveData];
    result = result.map((x) => {
      if (x.guid === id) {
        x.name = e.target.value;
        return x;
      } else return x;
    });
    setSceneLiveData(result);
  };


  const updateBackgroundMat = (mat) => {
    const storyRef = doc(db, 'stories', storyId);
    updateDoc(storyRef,
      {
        ...storyData,
        backgroundMatcap: mat
      }
    )
  }

  const updateBackgroundGroundColor = (color) => {
    const storyRef = doc(db, 'stories', storyId);
    updateDoc(storyRef,
      {
        ...storyData,
        backgroundGroundColor: color
      }
    )
  }

  const updateStory = (attribute, value) => {
    const storyRef = doc(db, 'stories', storyId);
    updateDoc(storyRef,
      {
        ...storyData,
        [attribute]: value
      }
    )
    if (attribute == 'cameraOffsetDefaults') {
      props.updateSceneFunction(sessionData.activeIndex)
    }
  }


  const updatePlatformPosition = () => {
    // console.log('updatePlatformPosition')
    // console.log({
    //   x: (sessionData.activeIndex * 40) - 50,
    //   y: sceneData[sessionData.activeIndex].pos.y,
    //   z: sceneData[sessionData.activeIndex].pos.z
    // })


    const newXpos = sceneData[sessionData.activeIndex].pos.x != 0 ? sceneData[sessionData.activeIndex].pos.x : (sessionData.activeIndex * 40) - 50

    updateSceneMulti('pos', {
      x: newXpos,
      y: sceneData[sessionData.activeIndex].pos.y,
      z: sceneData[sessionData.activeIndex].pos.z
    }, 'platformPosition', 'manual')

    // console.log('POS INFO', sceneData[sessionData.activeIndex].pos)

    // // Position x for the POS...
    // // updateScene('pos', { x: (sessionData.activeIndex * 40) - 50, y: 0, z: 0 })
    // updateScene('platformPosition', 'manual')
  }

  const updateSceneMulti = (attributeA, valueA, attributeB, valueB) => {
    const sceneRef = doc(db, 'stories', storyId, 'scenes', sceneData[sessionData.activeIndex].id);
    updateDoc(sceneRef,
      {
        ...sceneData[sessionData.activeIndex],
        [attributeA]: valueA,
        [attributeB]: valueB
      }
    )
  }


  const updateScene = (attribute, value) => {
    const sceneRef = doc(db, 'stories', storyId, 'scenes', sceneData[sessionData.activeIndex].id);
    updateDoc(sceneRef,
      {
        ...sceneData[sessionData.activeIndex],
        [attribute]: value
      }
    )
  }

  const [toggleComponetBlockMode, setToggleComponentBlockMode] = useState(false)

  useEffect(() => {
    var elContentBlocks = document.getElementById('component-blocks')
    var elStoryScenes = document.getElementById('story-scenes')

    if (toggleComponetBlockMode) {
      if (elContentBlocks) {
        elContentBlocks.classList.remove('display-none')
        elStoryScenes.classList.add('display-none')
      }
    }
    if (!toggleComponetBlockMode) {
      if (elContentBlocks) {
        elContentBlocks.classList.add('display-none')
        elStoryScenes.classList.remove('display-none')
      }
    }
  }, [toggleComponetBlockMode]);


  let wizardOpen = 0

  const ToggleWizard = () => {
    if (wizardOpen === 0) {
      anime({
        targets: '#panel-wizard',
        translateX: 298,
        easing: 'easeInOutExpo',
        delay: 0
      });
      wizardOpen = 1
    } else {
      anime({
        targets: '#panel-wizard',
        translateX: 0,
        easing: 'easeInOutExpo',
        delay: 0
      });
      wizardOpen = 0
    }
  }

  const [enabledMode, setEnabledMode] = useState(false)
  const [openSceneEdit, setOpenSceneEdit] = useState(false)
  const [sceneDetailUpdate, setSceneDetailUpdate] = useState([])

  componentModelList.sort()

  const [open, setOpen] = useState(false)

  useHotkeys('ctrl+a', () => setOpen(true), [open])



  const ComponentTemplates = () => {

    const [queryItems, setQueryItems] = useState('')
    const [vizPackItems, setVizPackItems] = useState([])

    const importVizPack = async (vizpack) => {
      let activeSceneId = sceneData[sessionData.activeIndex].id
      const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId);
      await updateDoc(sceneRef, {
        viznodes: vizpack.vizNodes
      })

    }

    useEffect(() => {
      const q = query(collection(db, 'vizpacks'), orderBy("name"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let vizpackArr = []
        querySnapshot.forEach((doc) => {
          vizpackArr.push({ ...doc.data(), id: doc.id })
        })
        setVizPackItems(vizpackArr)
      },
        (error) => {
        })
      return () => {
        unsubscribe()
      }
    }, [])

    return (
      <>
        {/* {vizPackItems.map((item, index) => (
          <div className="bg-white/5  hover:bg-white/10 ring-1 ring-white/10 cursor-pointer ring-inset rounded p-4 m-2 my-3"
            key={'comptemp' + index} onClick={() => { importVizPack(item) }}
          >
            <p
              className={classNames(
                'text-sm font-medium text-gray-300'
              )}

            >
              {item.name}
            </p>
            <p className={classNames('text-sm text-gray-300')}>
              {item.description}
            </p>
          </div>
        ))} */}


        <Menu as="div" className="relative inline-block  w-full text-left">
          <div className="   rounded-md">
            <Menu.Button className="text-gray-200 mr-1 border border-white/10 rounded text-sm mt-1.5 px-5 w-full text-center py-2   items-center bg-zinc-300/5 hover:bg-white/10">
              Add Layout Preset
              <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute -mt-5 right-6" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 z-10 mt-2  w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
              <div className="section-scroll h-[60vh] px-1 pr-2">
                <div className="grid grid-cols-2 gap-1 pb-8 gap-y-4">
                  {vizPackItems.map((item, index) => (
                    <div className="bg-black/10  hover:bg-white/10  cursor-pointer   rounded  "
                      key={'comptemp' + index} onClick={() => { importVizPack(item) }}
                    >
                      <span hover-tooltip={item.name} tooltip-position="bottom">
                        {item.preview ?
                          <img src={'/util/app/' + item.preview} className="w-full mx-auto rounded" />
                          : null}
                      </span>
                      {/* <p
                className={classNames(
                  'text-base text-gray-100'
                )}

              >
                {item.name}
              </p>
              <p className={classNames('text-sm text-gray-400 mt-4')}>
                {item.description}
              </p> */}
                    </div>
                  ))}
                </div>

              </div>
            </Menu.Items>
          </Transition>
        </Menu>


      </>
    )
  }




  const OpenVizPackModal = () => {

    const [open, setOpen] = useState(false)
    const [vizPackName, setVizPackName] = useState('')
    const [vizPackDescription, setVizPackDescription] = useState('')
    const cancelButtonRef = useRef(null)

    const saveVizpack = async () => {
      const docRef = await addDoc(collection(db, "vizpacks"), {
        name: vizPackName,
        description: vizPackDescription,
        visibility: 'private',
        vizNodes: sceneData[sessionData.activeIndex].viznodes,
      })
    }
    //});

    return (
      <>
        {/* <img
          src="/media/icon/noun-package-4567014-FFFFFF.svg"
          className="float-right h-6 mr-4 cursor-pointer opacity-50 hover:opacity-100"
          onClick={() =>
            setOpen(true)
          }
        /> */}

        <button type="button" className="text-gray-200 admin-component  component-card rounded text-sm px-2 py-1.5 text-left inline-flex items-center mb-2" onClick={() => { setOpen(true) }}>
          <img className="w-6 h-6 mr-2" src='/util/app/b3-save.svg' fill="currentColor" viewBox="0 0 8 19" />
          Save
        </button>


        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-700 bg-opacity-50  transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg   bg-zinc-800  px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-6/12 sm:p-6">
                    <div>
                      <div className="mt-3 sm:mt-5">
                        {/* <Dialog.Title as="h1" className="text-base leading-6 text-gray-100 text-2xl"> */}
                        <h1 className="text-2xl text-white">Save Scene Template</h1>
                        {/* </Dialog.Title> */}

                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                      focus-within:ring-inset focus-within:ring-sky-400 mt-8">
                          <input
                            type="text"
                            className="block flex-1 border-0 bg-transparent  text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-lg sm:leading-6 p-4 focus:outline-none"
                            placeholder="Pack Name"
                            value={vizPackName || ''}
                            onChange={(e) => setVizPackName(e.target.value)}
                          />
                        </div>

                        <div className="mt-6">
                          <textarea
                            placeholder="Description"
                            rows={2}
                            className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                  ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-lg sm:leading-6v focus:outline-none"
                            value={vizPackDescription || ''}
                            onChange={(e) => setVizPackDescription(e.target.value)}
                          />
                        </div>
                        <div className="mt-6 mb-12 ml-2">
                          <h2 className="text-gray-100">Packing Following Items</h2>
                          <ul className="mt-2 ml-2">
                            <>
                              {open && sceneData[sessionData.activeIndex].viznodes != null ?
                                <>
                                  {sceneData[sessionData.activeIndex].viznodes.map((item, index) => (
                                    <li key={uuidv4()} className="text-gray-300 mt-2">
                                      <img src={'/media/icon/' + item.icon + '.svg'} className="inline-block opacity-50 w-4 mr-4" />
                                      {item.name}
                                    </li>
                                  ))}
                                </>
                                : null
                              }
                            </>
                          </ul>
                        </div>
                        <div className="mt-6 mb-12 ml-2">
                          <h2 className="text-gray-100">Select Icon</h2>
                          <ul className="mt-2 ml-2">
                            {open && sceneData[sessionData.activeIndex].viznodes.map((item, index) => (
                              <li key={uuidv4()} className="text-gray-300 mt-2">
                                <img src={'/media/icon/' + item.icon + '.svg'} className="inline-block opacity-50 w-4 mr-4" />
                                {item.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={() => {
                          saveVizpack(vizPackName, vizPackDescription)
                          setOpen(false)
                        }
                        }
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  }

  //icon: 'b3-theme-ice',

  const themeListOptions = [
    {
      name: 'Dark Ice',
      icon: 'b3-theme-ice',
    },
    {
      name: 'Dark Mint',
      icon: 'b3-theme-mint',
    },
    {
      name: 'Dark Theme with Pink Accents',
      icon: 'b3-theme-ice',
    },
    {
      name: 'Flat UI Blue Theme',
      icon: 'b3-theme-ice',
    },
    {
      name: 'Light Theme with Green Accents',
      icon: 'b3-theme-ice',
    },
    {
      name: 'Dark Theme with Gold Accents',
      icon: 'b3-theme-ice',
    },
    {
      name: 'Purple-based Theme',
      icon: 'b3-theme-ice',
    },
    {
      name: 'Earthy Green and Brown Theme',
      icon: 'b3-theme-ice',
    },
    {
      name: 'Light Blue and White Theme',
      icon: 'b3-theme-ice',
    },
    {
      name: 'Bold Red, White, and Blue Theme',
      icon: 'b3-theme-ice',
    },
    {
      name: 'Warm Orange and Teal Theme',
      icon: 'b3-theme-ice',
    },
    {
      name: 'Soft Peachy Brown Theme',
      icon: 'b3-theme-ice',
    },
  ]


  const themeList = [
    {
      name: 'Dark Ice',
      description: 'A dark theme with ice blue accents',
      usewhen: 'For a cool, modern look',
      styleCategory: 'dark',
      classes: {
        'nav-button-bg-off': '#b3b3b3',
        'nav-button-bg-on': '#222222',
        'nav-button-text-off': '#4d4d4d',
        'nav-button-text-on': '#009ce0',
        'nav-progress-meter': '#808080',
        'nav-progress-meter-bg': '#009ce0',
        'nav-region-bg': '#222222',
        'super-highlight-color': '#009ce0',
        'super-list-icon-color': '#000000',
        'super-list-text-color': '#000000',
        'super-main-bg-color': '#111111',
        'super-main-title-color': '#009ce0',
        'super-secondary-bg-color': '#000000',
        'super-subtitle-color': '#cccccc',
      }
    },
    {
      name: 'Sunset Glow',
      description: 'A warm theme inspired by sunset hues',
      usewhen: 'Ideal for lifestyle blogs or travel websites aiming for a welcoming atmosphere',
      styleCategory: 'colorful',
      classes: {
        'nav-button-bg-off': '#FFCC99',
        'nav-button-bg-on': '#FF9966',
        'nav-button-text-off': '#663300',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#FFCC66',
        'nav-progress-meter-bg': '#FF6600',
        'nav-region-bg': '#FF9966',
        'super-highlight-color': '#FF6600',
        'super-list-icon-color': '#663300',
        'super-list-text-color': '#663300',
        'super-main-bg-color': '#FFCC99',
        'super-main-title-color': '#FF6600',
        'super-secondary-bg-color': '#FFCC66',
        'super-subtitle-color': '#663300',
      }
    },
    {
      name: 'Forest Whisper',
      description: 'A nature-inspired theme with earthy greens and browns',
      usewhen: 'Great for environmental websites or organic product stores',
      styleCategory: 'light',
      classes: {
        'nav-button-bg-off': '#A3C1AD',
        'nav-button-bg-on': '#4B6F44',
        'nav-button-text-off': '#3D3D3D',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#6B8E23',
        'nav-progress-meter-bg': '#556B2F',
        'nav-region-bg': '#E6E6E6',
        'super-highlight-color': '#556B2F',
        'super-list-icon-color': '#3D3D3D',
        'super-list-text-color': '#3D3D3D',
        'super-main-bg-color': '#F5F5F5',
        'super-main-title-color': '#4B6F44',
        'super-secondary-bg-color': '#A3C1AD',
        'super-subtitle-color': '#6B8E23',
      }
    },
    {
      name: 'Ocean Breeze',
      description: 'A fresh theme with cool blues and teals',
      usewhen: 'Perfect for maritime businesses or wellness blogs promoting tranquility',
      styleCategory: 'light',
      classes: {
        'nav-button-bg-off': '#B0E0E6',
        'nav-button-bg-on': '#4682B4',
        'nav-button-text-off': '#2F4F4F',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#5F9EA0',
        'nav-progress-meter-bg': '#008080',
        'nav-region-bg': '#E0FFFF',
        'super-highlight-color': '#008080',
        'super-list-icon-color': '#2F4F4F',
        'super-list-text-color': '#2F4F4F',
        'super-main-bg-color': '#F0FFFF',
        'super-main-title-color': '#4682B4',
        'super-secondary-bg-color': '#B0E0E6',
        'super-subtitle-color': '#5F9EA0',
      }
    },
    {
      name: 'Minimalist Gray',
      description: 'A sleek and modern theme with shades of gray',
      usewhen: 'Ideal for tech startups or professional portfolios aiming for simplicity',
      styleCategory: 'dark',
      classes: {
        'nav-button-bg-off': '#A9A9A9',
        'nav-button-bg-on': '#696969',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#808080',
        'nav-progress-meter-bg': '#D3D3D3',
        'nav-region-bg': '#2F2F2F',
        'super-highlight-color': '#696969',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#1C1C1C',
        'super-main-title-color': '#D3D3D3',
        'super-secondary-bg-color': '#2F2F2F',
        'super-subtitle-color': '#A9A9A9',
      }
    },
    {
      name: 'Candy Pop',
      description: 'A vibrant theme with bright and playful colors',
      usewhen: 'Perfect for children’s websites or brands looking to convey fun and energy',
      styleCategory: 'colorful',
      classes: {
        'nav-button-bg-off': '#FFB6C1',
        'nav-button-bg-on': '#FF69B4',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#FFD700',
        'nav-progress-meter-bg': '#FF4500',
        'nav-region-bg': '#FFA07A',
        'super-highlight-color': '#FF1493',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#FFB6C1',
        'super-main-title-color': '#FF69B4',
        'super-secondary-bg-color': '#FFA07A',
        'super-subtitle-color': '#FFD700',
      }
    },
    {
      name: 'Vintage Sepia',
      description: 'An old-world theme with sepia tones',
      usewhen: 'Ideal for photography portfolios or history blogs seeking a classic look',
      styleCategory: 'light',
      classes: {
        'nav-button-bg-off': '#D2B48C',
        'nav-button-bg-on': '#A0522D',
        'nav-button-text-off': '#000000',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#CD853F',
        'nav-progress-meter-bg': '#8B4513',
        'nav-region-bg': '#F5DEB3',
        'super-highlight-color': '#8B4513',
        'super-list-icon-color': '#000000',
        'super-list-text-color': '#000000',
        'super-main-bg-color': '#FFF8DC',
        'super-main-title-color': '#A0522D',
        'super-secondary-bg-color': '#D2B48C',
        'super-subtitle-color': '#CD853F',
      }
    },
    {
      name: 'Royal Purple',
      description: 'An elegant theme with rich purples and gold accents',
      usewhen: 'Great for luxury brands or high-end products',
      styleCategory: 'dark',
      classes: {
        'nav-button-bg-off': '#800080',
        'nav-button-bg-on': '#4B0082',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFD700',
        'nav-progress-meter': '#8A2BE2',
        'nav-progress-meter-bg': '#FFD700',
        'nav-region-bg': '#4B0082',
        'super-highlight-color': '#FFD700',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#2E0854',
        'super-main-title-color': '#FFD700',
        'super-secondary-bg-color': '#800080',
        'super-subtitle-color': '#DA70D6',
      }
    },
    {
      name: 'Electric Neon',
      description: 'A bold theme with neon colors for a high-energy feel',
      usewhen: 'Perfect for music festivals or events targeting a youthful audience',
      styleCategory: 'colorful',
      classes: {
        'nav-button-bg-off': '#39FF14',
        'nav-button-bg-on': '#FF073A',
        'nav-button-text-off': '#000000',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#FE019A',
        'nav-progress-meter-bg': '#FE0000',
        'nav-region-bg': '#0E0B16',
        'super-highlight-color': '#FF073A',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#000000',
        'super-main-title-color': '#39FF14',
        'super-secondary-bg-color': '#FE019A',
        'super-subtitle-color': '#F4F4F4',
      }
    },
    {
      name: 'Winter Wonderland',
      description: 'A cool theme with icy blues and whites',
      usewhen: 'Ideal for winter promotions or brands focusing on cool aesthetics',
      styleCategory: 'light',
      classes: {
        'nav-button-bg-off': '#E0FFFF',
        'nav-button-bg-on': '#AFEEEE',
        'nav-button-text-off': '#000080',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#B0E0E6',
        'nav-progress-meter-bg': '#4682B4',
        'nav-region-bg': '#F0F8FF',
        'super-highlight-color': '#4682B4',
        'super-list-icon-color': '#000080',
        'super-list-text-color': '#000080',
        'super-main-bg-color': '#FFFFFF',
        'super-main-title-color': '#AFEEEE',
        'super-secondary-bg-color': '#E0FFFF',
        'super-subtitle-color': '#B0E0E6',
      }
    },
    {
      name: 'Autumn Harvest',
      description: 'A warm theme with rich autumnal colors',
      usewhen: 'Ideal for seasonal promotions or brands wanting a cozy feel',
      styleCategory: 'colorful',
      classes: {
        'nav-button-bg-off': '#D2691E',
        'nav-button-bg-on': '#8B4513',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#FF8C00',
        'nav-progress-meter-bg': '#CD5C5C',
        'nav-region-bg': '#FFEFD5',
        'super-highlight-color': '#CD5C5C',
        'super-list-icon-color': '#8B4513',
        'super-list-text-color': '#8B4513',
        'super-main-bg-color': '#FFF8DC',
        'super-main-title-color': '#D2691E',
        'super-secondary-bg-color': '#F4A460',
        'super-subtitle-color': '#CD853F',
      }
    },
    {
      name: 'Midnight Blue',
      description: 'A deep blue theme evoking the night sky',
      usewhen: 'Great for elegant websites or astronomy-related content',
      styleCategory: 'dark',
      classes: {
        'nav-button-bg-off': '#191970',
        'nav-button-bg-on': '#000080',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#00008B',
        'nav-progress-meter-bg': '#1E90FF',
        'nav-region-bg': '#000080',
        'super-highlight-color': '#1E90FF',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#000033',
        'super-main-title-color': '#1E90FF',
        'super-secondary-bg-color': '#191970',
        'super-subtitle-color': '#87CEFA',
      }
    },
    {
      name: 'Spring Blossom',
      description: 'A light theme with pastel pinks and greens',
      usewhen: 'Perfect for florists or brands wanting a fresh, uplifting feel',
      styleCategory: 'light',
      classes: {
        'nav-button-bg-off': '#FFB7C5',
        'nav-button-bg-on': '#98FB98',
        'nav-button-text-off': '#006400',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#ADFF2F',
        'nav-progress-meter-bg': '#FF69B4',
        'nav-region-bg': '#FFF0F5',
        'super-highlight-color': '#FF69B4',
        'super-list-icon-color': '#006400',
        'super-list-text-color': '#006400',
        'super-main-bg-color': '#FFF5EE',
        'super-main-title-color': '#98FB98',
        'super-secondary-bg-color': '#FFB7C5',
        'super-subtitle-color': '#ADFF2F',
      }
    },
    {
      name: 'Cyberpunk',
      description: 'A dark theme with neon accents',
      usewhen: 'Ideal for tech startups or gaming sites wanting a futuristic look',
      styleCategory: 'dark',
      classes: {
        'nav-button-bg-off': '#0D0D0D',
        'nav-button-bg-on': '#1A1A1A',
        'nav-button-text-off': '#FF00FF',
        'nav-button-text-on': '#00FFFF',
        'nav-progress-meter': '#FF00FF',
        'nav-progress-meter-bg': '#00FFFF',
        'nav-region-bg': '#000000',
        'super-highlight-color': '#00FFFF',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#0D0D0D',
        'super-main-title-color': '#FF00FF',
        'super-secondary-bg-color': '#1A1A1A',
        'super-subtitle-color': '#00FFFF',
      }
    },
    {
      name: 'Desert Sand',
      description: 'A warm theme with sandy colors',
      usewhen: 'Great for travel blogs or desert-themed content',
      styleCategory: 'light',
      classes: {
        'nav-button-bg-off': '#EDC9AF',
        'nav-button-bg-on': '#C2B280',
        'nav-button-text-off': '#8B4513',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#DEB887',
        'nav-progress-meter-bg': '#D2B48C',
        'nav-region-bg': '#F5DEB3',
        'super-highlight-color': '#C2B280',
        'super-list-icon-color': '#8B4513',
        'super-list-text-color': '#8B4513',
        'super-main-bg-color': '#FFF8DC',
        'super-main-title-color': '#EDC9AF',
        'super-secondary-bg-color': '#DEB887',
        'super-subtitle-color': '#D2B48C',
      }
    },
    {
      name: 'Monochrome',
      description: 'A sleek grayscale theme',
      usewhen: 'Ideal for professional portfolios or minimalist designs',
      styleCategory: 'dark',
      classes: {
        'nav-button-bg-off': '#A9A9A9',
        'nav-button-bg-on': '#696969',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#808080',
        'nav-progress-meter-bg': '#D3D3D3',
        'nav-region-bg': '#2F2F2F',
        'super-highlight-color': '#696969',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#1C1C1C',
        'super-main-title-color': '#D3D3D3',
        'super-secondary-bg-color': '#2F2F2F',
        'super-subtitle-color': '#A9A9A9',
      }
    },
    // Add more themes up to the limit
    {
      name: 'Golden Hour',
      description: 'A warm theme with soft golds and oranges',
      usewhen: 'Great for photography portfolios or brands wanting a nostalgic feel',
      styleCategory: 'colorful',
      classes: {
        'nav-button-bg-off': '#FFD700',
        'nav-button-bg-on': '#FF8C00',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#FFA500',
        'nav-progress-meter-bg': '#FF4500',
        'nav-region-bg': '#FFE4B5',
        'super-highlight-color': '#FF8C00',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#FFEFD5',
        'super-main-title-color': '#FFD700',
        'super-secondary-bg-color': '#FFA500',
        'super-subtitle-color': '#FF7F50',
      }
    },
    {
      name: 'Autumn Harvest',
      description: 'A warm theme with rich autumnal colors',
      usewhen: 'Ideal for seasonal promotions or brands wanting a cozy feel',
      styleCategory: 'colorful',
      classes: {
        'nav-button-bg-off': '#D2691E',
        'nav-button-bg-on': '#8B4513',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#FF8C00',
        'nav-progress-meter-bg': '#CD5C5C',
        'nav-region-bg': '#FFEFD5',
        'super-highlight-color': '#CD5C5C',
        'super-list-icon-color': '#8B4513',
        'super-list-text-color': '#8B4513',
        'super-main-bg-color': '#FFF8DC',
        'super-main-title-color': '#D2691E',
        'super-secondary-bg-color': '#F4A460',
        'super-subtitle-color': '#CD853F',
      }
    },
    {
      name: 'Midnight Blue',
      description: 'A deep blue theme evoking the night sky',
      usewhen: 'Great for elegant websites or astronomy-related content',
      styleCategory: 'dark',
      classes: {
        'nav-button-bg-off': '#191970',
        'nav-button-bg-on': '#000080',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#00008B',
        'nav-progress-meter-bg': '#1E90FF',
        'nav-region-bg': '#000080',
        'super-highlight-color': '#1E90FF',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#000033',
        'super-main-title-color': '#1E90FF',
        'super-secondary-bg-color': '#191970',
        'super-subtitle-color': '#87CEFA',
      }
    },
    // ... (Themes 13 to 50 in the same format)
    {
      name: 'Golden Hour',
      description: 'A warm theme with soft golds and oranges',
      usewhen: 'Great for photography portfolios or brands wanting a nostalgic feel',
      styleCategory: 'colorful',
      classes: {
        'nav-button-bg-off': '#FFD700',
        'nav-button-bg-on': '#FF8C00',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#FFA500',
        'nav-progress-meter-bg': '#FF4500',
        'nav-region-bg': '#FFE4B5',
        'super-highlight-color': '#FF8C00',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#FFEFD5',
        'super-main-title-color': '#FFD700',
        'super-secondary-bg-color': '#FFA500',
        'super-subtitle-color': '#FF7F50',
      }
    },
    {
      name: 'Sunset Glow',
      description: 'A warm theme inspired by sunset hues',
      usewhen: 'Ideal for lifestyle blogs or travel websites aiming for a welcoming atmosphere',
      styleCategory: 'colorful',
      classes: {
        'nav-button-bg-off': '#FFCC99',
        'nav-button-bg-on': '#FF9966',
        'nav-button-text-off': '#663300',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#FFCC66',
        'nav-progress-meter-bg': '#FF6600',
        'nav-region-bg': '#FF9966',
        'super-highlight-color': '#FF6600',
        'super-list-icon-color': '#663300',
        'super-list-text-color': '#663300',
        'super-main-bg-color': '#FFCC99',
        'super-main-title-color': '#FF6600',
        'super-secondary-bg-color': '#FFCC66',
        'super-subtitle-color': '#663300',
      }
    },
    {
      name: 'Forest Whisper',
      description: 'A nature-inspired theme with earthy greens and browns',
      usewhen: 'Great for environmental websites or organic product stores',
      styleCategory: 'light',
      classes: {
        'nav-button-bg-off': '#A3C1AD',
        'nav-button-bg-on': '#4B6F44',
        'nav-button-text-off': '#3D3D3D',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#6B8E23',
        'nav-progress-meter-bg': '#556B2F',
        'nav-region-bg': '#E6E6E6',
        'super-highlight-color': '#556B2F',
        'super-list-icon-color': '#3D3D3D',
        'super-list-text-color': '#3D3D3D',
        'super-main-bg-color': '#F5F5F5',
        'super-main-title-color': '#4B6F44',
        'super-secondary-bg-color': '#A3C1AD',
        'super-subtitle-color': '#6B8E23',
      }
    },
    {
      name: 'Ocean Breeze',
      description: 'A fresh theme with cool blues and teals',
      usewhen: 'Perfect for maritime businesses or wellness blogs promoting tranquility',
      styleCategory: 'light',
      classes: {
        'nav-button-bg-off': '#B0E0E6',
        'nav-button-bg-on': '#4682B4',
        'nav-button-text-off': '#2F4F4F',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#5F9EA0',
        'nav-progress-meter-bg': '#008080',
        'nav-region-bg': '#E0FFFF',
        'super-highlight-color': '#008080',
        'super-list-icon-color': '#2F4F4F',
        'super-list-text-color': '#2F4F4F',
        'super-main-bg-color': '#F0FFFF',
        'super-main-title-color': '#4682B4',
        'super-secondary-bg-color': '#B0E0E6',
        'super-subtitle-color': '#5F9EA0',
      }
    },
    {
      name: 'Minimalist Gray',
      description: 'A sleek and modern theme with shades of gray',
      usewhen: 'Ideal for tech startups or professional portfolios aiming for simplicity',
      styleCategory: 'dark',
      classes: {
        'nav-button-bg-off': '#A9A9A9',
        'nav-button-bg-on': '#696969',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#808080',
        'nav-progress-meter-bg': '#D3D3D3',
        'nav-region-bg': '#2F2F2F',
        'super-highlight-color': '#696969',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#1C1C1C',
        'super-main-title-color': '#D3D3D3',
        'super-secondary-bg-color': '#2F2F2F',
        'super-subtitle-color': '#A9A9A9',
      }
    },
    {
      name: 'Candy Pop',
      description: 'A vibrant theme with bright and playful colors',
      usewhen: 'Perfect for children’s websites or brands looking to convey fun and energy',
      styleCategory: 'colorful',
      classes: {
        'nav-button-bg-off': '#FFB6C1',
        'nav-button-bg-on': '#FF69B4',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#FFD700',
        'nav-progress-meter-bg': '#FF4500',
        'nav-region-bg': '#FFA07A',
        'super-highlight-color': '#FF1493',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#FFB6C1',
        'super-main-title-color': '#FF69B4',
        'super-secondary-bg-color': '#FFA07A',
        'super-subtitle-color': '#FFD700',
      }
    },
    {
      name: 'Vintage Sepia',
      description: 'An old-world theme with sepia tones',
      usewhen: 'Ideal for photography portfolios or history blogs seeking a classic look',
      styleCategory: 'light',
      classes: {
        'nav-button-bg-off': '#D2B48C',
        'nav-button-bg-on': '#A0522D',
        'nav-button-text-off': '#000000',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#CD853F',
        'nav-progress-meter-bg': '#8B4513',
        'nav-region-bg': '#F5DEB3',
        'super-highlight-color': '#8B4513',
        'super-list-icon-color': '#000000',
        'super-list-text-color': '#000000',
        'super-main-bg-color': '#FFF8DC',
        'super-main-title-color': '#A0522D',
        'super-secondary-bg-color': '#D2B48C',
        'super-subtitle-color': '#CD853F',
      }
    },
    {
      name: 'Royal Purple',
      description: 'An elegant theme with rich purples and gold accents',
      usewhen: 'Great for luxury brands or high-end products',
      styleCategory: 'dark',
      classes: {
        'nav-button-bg-off': '#800080',
        'nav-button-bg-on': '#4B0082',
        'nav-button-text-off': '#FFFFFF',
        'nav-button-text-on': '#FFD700',
        'nav-progress-meter': '#8A2BE2',
        'nav-progress-meter-bg': '#FFD700',
        'nav-region-bg': '#4B0082',
        'super-highlight-color': '#FFD700',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#2E0854',
        'super-main-title-color': '#FFD700',
        'super-secondary-bg-color': '#800080',
        'super-subtitle-color': '#DA70D6',
      }
    },
    {
      name: 'Electric Neon',
      description: 'A bold theme with neon colors for a high-energy feel',
      usewhen: 'Perfect for music festivals or events targeting a youthful audience',
      styleCategory: 'colorful',
      classes: {
        'nav-button-bg-off': '#39FF14',
        'nav-button-bg-on': '#FF073A',
        'nav-button-text-off': '#000000',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#FE019A',
        'nav-progress-meter-bg': '#FE0000',
        'nav-region-bg': '#0E0B16',
        'super-highlight-color': '#FF073A',
        'super-list-icon-color': '#FFFFFF',
        'super-list-text-color': '#FFFFFF',
        'super-main-bg-color': '#000000',
        'super-main-title-color': '#39FF14',
        'super-secondary-bg-color': '#FE019A',
        'super-subtitle-color': '#F4F4F4',
      }
    },
    {
      name: 'Winter Wonderland',
      description: 'A cool theme with icy blues and whites',
      usewhen: 'Ideal for winter promotions or brands focusing on cool aesthetics',
      styleCategory: 'light',
      classes: {
        'nav-button-bg-off': '#E0FFFF',
        'nav-button-bg-on': '#AFEEEE',
        'nav-button-text-off': '#000080',
        'nav-button-text-on': '#FFFFFF',
        'nav-progress-meter': '#B0E0E6',
        'nav-progress-meter-bg': '#4682B4',
        'nav-region-bg': '#F0F8FF',
        'super-highlight-color': '#4682B4',
        'super-list-icon-color': '#000080',
        'super-list-text-color': '#000080',
        'super-main-bg-color': '#FFFFFF',
        'super-main-title-color': '#AFEEEE',
        'super-secondary-bg-color': '#E0FFFF',
        'super-subtitle-color': '#B0E0E6',
      }
    },
    {
      name: 'Dark Ice',
      description: 'A dark theme with ice blue accents',
      usewhen: 'For a cool, modern look', // When to use this theme.  Please be as descriptive as possible, this will help people search for it.  you can include details like 'perfect for a financial institution' or 'great for a tech startup'
      styleCategory: 'dark', // dark, light, colorful - a simple word that can be used to categorize the theme to make it easy for people to find
      classes: {
        'nav-button-bg-off': '#b3b3b3',
        'nav-button-bg-on': '#222222',
        'nav-button-text-off': '#4d4d4d',
        'nav-button-text-on': '#009ce0',
        'nav-progress-meter': '#808080',
        'nav-progress-meter-bg': '#009ce0',
        'nav-region-bg': '#222222',
        'super-highlight-color': '#009ce0',
        'super-list-icon-color': '#000000',
        'super-list-text-color': '#000000',
        'super-main-bg-color': '#111111',
        'super-main-title-color': '#009ce0',
        'super-secondary-bg-color': '#000000',
        'super-subtitle-color': '#cccccc',
      }
    },
    {
      name: 'Sunset Glow',
      description: 'Warm sunset colors with a dark background',
      usewhen: 'For a cozy, inviting atmosphere',
      classes: {
        'nav-button-bg-off': '#4a4a4a',
        'nav-button-bg-on': '#2c2c2c',
        'nav-button-text-off': '#d3d3d3',
        'nav-button-text-on': '#ff7e5f',
        'nav-progress-meter': '#b24592',
        'nav-progress-meter-bg': '#ff7e5f',
        'nav-region-bg': '#2c2c2c',
        'super-highlight-color': '#ff7e5f',
        'super-list-icon-color': '#feb47b',
        'super-list-text-color': '#feb47b',
        'super-main-bg-color': '#1c1c1c',
        'super-main-title-color': '#ff7e5f',
        'super-secondary-bg-color': '#0f0f0f',
        'super-subtitle-color': '#e6e6e6',
      }
    },
    {
      name: 'Forest Breeze',
      description: 'Soothing green tones inspired by nature',
      usewhen: 'For a calm, natural feel',
      classes: {
        'nav-button-bg-off': '#d0e1d4',
        'nav-button-bg-on': '#88b48b',
        'nav-button-text-off': '#3e5641',
        'nav-button-text-on': '#1e352f',
        'nav-progress-meter': '#829e82',
        'nav-progress-meter-bg': '#3e5641',
        'nav-region-bg': '#88b48b',
        'super-highlight-color': '#3e5641',
        'super-list-icon-color': '#1e352f',
        'super-list-text-color': '#1e352f',
        'super-main-bg-color': '#e8f1e8',
        'super-main-title-color': '#3e5641',
        'super-secondary-bg-color': '#d0e1d4',
        'super-subtitle-color': '#5b7b63',
      }
    },
    {
      name: 'Ocean Depths',
      description: 'Deep blues and teals reminiscent of the sea',
      usewhen: 'For a serene, immersive experience',
      classes: {
        'nav-button-bg-off': '#1a3c5a',
        'nav-button-bg-on': '#0f2a3f',
        'nav-button-text-off': '#7ab8cc',
        'nav-button-text-on': '#30e3ca',
        'nav-progress-meter': '#40a8cc',
        'nav-progress-meter-bg': '#30e3ca',
        'nav-region-bg': '#0f2a3f',
        'super-highlight-color': '#30e3ca',
        'super-list-icon-color': '#e4f9f5',
        'super-list-text-color': '#e4f9f5',
        'super-main-bg-color': '#11191f',
        'super-main-title-color': '#30e3ca',
        'super-secondary-bg-color': '#0a1014',
        'super-subtitle-color': '#7ab8cc',
      }
    },
    {
      name: 'Lavender Dreams',
      description: 'Soft purples and pinks for a dreamy ambiance',
      usewhen: 'For a gentle, soothing interface',
      classes: {
        'nav-button-bg-off': '#e6e6fa',
        'nav-button-bg-on': '#d8bfd8',
        'nav-button-text-off': '#8e7cc3',
        'nav-button-text-on': '#9370db',
        'nav-progress-meter': '#b19cd9',
        'nav-progress-meter-bg': '#9370db',
        'nav-region-bg': '#d8bfd8',
        'super-highlight-color': '#9370db',
        'super-list-icon-color': '#4b0082',
        'super-list-text-color': '#4b0082',
        'super-main-bg-color': '#f0e6ff',
        'super-main-title-color': '#8e7cc3',
        'super-secondary-bg-color': '#e6e6fa',
        'super-subtitle-color': '#6a5acd',
      }
    },
    {
      name: 'Citrus Burst',
      description: 'Vibrant oranges and yellows with dark accents',
      usewhen: 'For an energetic, lively interface',
      classes: {
        'nav-button-bg-off': '#2d2d2d',
        'nav-button-bg-on': '#1a1a1a',
        'nav-button-text-off': '#ffa500',
        'nav-button-text-on': '#ffd700',
        'nav-progress-meter': '#ff8c00',
        'nav-progress-meter-bg': '#ffd700',
        'nav-region-bg': '#1a1a1a',
        'super-highlight-color': '#ffd700',
        'super-list-icon-color': '#fff5e6',
        'super-list-text-color': '#fff5e6',
        'super-main-bg-color': '#2d2d2d',
        'super-main-title-color': '#ffa500',
        'super-secondary-bg-color': '#1a1a1a',
        'super-subtitle-color': '#ffdb58',
      }
    },
    {
      name: 'Monochrome Elegance',
      description: 'Sophisticated black, white, and gray tones',
      usewhen: 'For a sleek, professional look',
      classes: {
        'nav-button-bg-off': '#e0e0e0',
        'nav-button-bg-on': '#b3b3b3',
        'nav-button-text-off': '#4d4d4d',
        'nav-button-text-on': '#1a1a1a',
        'nav-progress-meter': '#808080',
        'nav-progress-meter-bg': '#1a1a1a',
        'nav-region-bg': '#b3b3b3',
        'super-highlight-color': '#1a1a1a',
        'super-list-icon-color': '#333333',
        'super-list-text-color': '#333333',
        'super-main-bg-color': '#f5f5f5',
        'super-main-title-color': '#1a1a1a',
        'super-secondary-bg-color': '#e0e0e0',
        'super-subtitle-color': '#4d4d4d',
      }
    },
    {
      name: 'Desert Oasis',
      description: 'Warm sand tones with cool blue accents',
      usewhen: 'For a balanced, earthy feel',
      classes: {
        'nav-button-bg-off': '#e0c9a6',
        'nav-button-bg-on': '#d2b48c',
        'nav-button-text-off': '#5e503f',
        'nav-button-text-on': '#20639b',
        'nav-progress-meter': '#8c734b',
        'nav-progress-meter-bg': '#20639b',
        'nav-region-bg': '#d2b48c',
        'super-highlight-color': '#20639b',
        'super-list-icon-color': '#3d2c2e',
        'super-list-text-color': '#3d2c2e',
        'super-main-bg-color': '#f2e6d8',
        'super-main-title-color': '#20639b',
        'super-secondary-bg-color': '#e0c9a6',
        'super-subtitle-color': '#5e503f',
      }
    },
    {
      name: 'Neon Nights',
      description: 'Bold neon colors on a dark background',
      usewhen: 'For a vibrant, futuristic interface',
      classes: {
        'nav-button-bg-off': '#1a1a1a',
        'nav-button-bg-on': '#0d0d0d',
        'nav-button-text-off': '#ff00ff',
        'nav-button-text-on': '#00ffff',
        'nav-progress-meter': '#ff1493',
        'nav-progress-meter-bg': '#00ffff',
        'nav-region-bg': '#0d0d0d',
        'super-highlight-color': '#00ffff',
        'super-list-icon-color': '#f0f8ff',
        'super-list-text-color': '#f0f8ff',
        'super-main-bg-color': '#1a1a1a',
        'super-main-title-color': '#ff00ff',
        'super-secondary-bg-color': '#0d0d0d',
        'super-subtitle-color': '#ff1493',
      }
    },
    {
      name: 'Autumn Harvest',
      description: 'Rich fall colors with earthy undertones',
      usewhen: 'For a warm, cozy atmosphere',
      classes: {
        'nav-button-bg-off': '#d2691e',
        'nav-button-bg-on': '#8b4513',
        'nav-button-text-off': '#f4a460',
        'nav-button-text-on': '#ffd700',
        'nav-progress-meter': '#b8860b',
        'nav-progress-meter-bg': '#ffd700',
        'nav-region-bg': '#8b4513',
        'super-highlight-color': '#ffd700',
        'super-list-icon-color': '#fffaf0',
        'super-list-text-color': '#fffaf0',
        'super-main-bg-color': '#a0522d',
        'super-main-title-color': '#f4a460',
        'super-secondary-bg-color': '#8b4513',
        'super-subtitle-color': '#deb887',
      }
    },
    {
      name: 'Pastel Paradise',
      description: 'Soft, muted colors for a gentle interface',
      usewhen: 'For a calm, approachable design',
      classes: {
        'nav-button-bg-off': '#f0e6ff',
        'nav-button-bg-on': '#e6f3ff',
        'nav-button-text-off': '#b19cd9',
        'nav-button-text-on': '#87cefa',
        'nav-progress-meter': '#ffd1dc',
        'nav-progress-meter-bg': '#87cefa',
        'nav-region-bg': '#e6f3ff',
        'super-highlight-color': '#87cefa',
        'super-list-icon-color': '#4b0082',
        'super-list-text-color': '#4b0082',
        'super-main-bg-color': '#fff5e6',
        'super-main-title-color': '#b19cd9',
        'super-secondary-bg-color': '#f0e6ff',
        'super-subtitle-color': '#dda0dd',
      }
    },
    {
      name: 'Crimson Glory',
      description: 'Bold reds and deep purples for a regal look',
      usewhen: 'For a luxurious, dramatic interface',
      classes: {
        'nav-button-bg-off': '#4a0e2a',
        'nav-button-bg-on': '#2c0a1a',
        'nav-button-text-off': '#c41e3a',
        'nav-button-text-on': '#ff033e',
        'nav-progress-meter': '#8e2323',
        'nav-progress-meter-bg': '#ff033e',
        'nav-region-bg': '#2c0a1a',
        'super-highlight-color': '#ff033e',
        'super-list-icon-color': '#ffe4e1',
        'super-list-text-color': '#ffe4e1',
        'super-main-bg-color': '#3c0d21',
        'super-main-title-color': '#c41e3a',
        'super-secondary-bg-color': '#2c0a1a',
        'super-subtitle-color': '#e25822',
      }
    },
    {
      name: 'Minty Fresh',
      description: 'Cool mint greens with crisp white accents',
      usewhen: 'For a clean, refreshing look',
      classes: {
        'nav-button-bg-off': '#f5fffa',
        'nav-button-bg-on': '#e0fff4',
        'nav-button-text-off': '#3cb371',
        'nav-button-text-on': '#2e8b57',
        'nav-progress-meter': '#98fb98',
        'nav-progress-meter-bg': '#2e8b57',
        'nav-region-bg': '#e0fff4',
        'super-highlight-color': '#2e8b57',
        'super-list-icon-color': '#006400',
        'super-list-text-color': '#006400',
        'super-main-bg-color': '#f0fff0',
        'super-main-title-color': '#3cb371',
        'super-secondary-bg-color': '#f5fffa',
        'super-subtitle-color': '#20b2aa',
      }
    },
    {
      name: 'Midnight Sky',
      description: 'Deep blues and purples with starry accents',
      usewhen: 'For a mysterious, enchanting interface',
      classes: {
        'nav-button-bg-off': '#191970',
        'nav-button-bg-on': '#0f0f3d',
        'nav-button-text-off': '#4169e1',
        'nav-button-text-on': '#87cefa',
        'nav-progress-meter': '#483d8b',
        'nav-progress-meter-bg': '#87cefa',
        'nav-region-bg': '#0f0f3d',
        'super-highlight-color': '#87cefa',
        'super-list-icon-color': '#e6e6fa',
        'super-list-text-color': '#e6e6fa',
        'super-main-bg-color': '#0c0c2b',
        'super-main-title-color': '#4169e1',
        'super-secondary-bg-color': '#080819',
        'super-subtitle-color': '#b0c4de',
      }
    },
    {
      name: 'Golden Hour',
      description: 'Warm golds and oranges inspired by sunset',
      usewhen: 'For a cozy, inviting atmosphere',
      classes: {
        'nav-button-bg-off': '#ffa07a',
        'nav-button-bg-on': '#ff7f50',
        'nav-button-text-off': '#8b4513',
        'nav-button-text-on': '#cd853f',
        'nav-progress-meter': '#daa520',
        'nav-progress-meter-bg': '#cd853f',
        'nav-region-bg': '#ff7f50',
        'super-highlight-color': '#cd853f',
        'super-list-icon-color': '#fffaf0',
        'super-list-text-color': '#fffaf0',
        'super-main-bg-color': '#ffdab9',
        'super-main-title-color': '#8b4513',
        'super-secondary-bg-color': '#ffa07a',
        'super-subtitle-color': '#d2691e',
      }
    },
    {
      name: 'Arctic Frost',
      description: 'Icy blues and whites for a crisp, clean look',
      usewhen: 'For a cool, minimalist interface',
      classes: {
        'nav-button-bg-off': '#f0f8ff',
        'nav-button-bg-on': '#e6f3ff',
        'nav-button-text-off': '#4682b4',
        'nav-button-text-on': '#1e90ff',
        'nav-progress-meter': '#b0e0e6',
        'nav-progress-meter-bg': '#1e90ff',
        'nav-region-bg': '#e6f3ff',
        'super-highlight-color': '#1e90ff',
        'super-list-icon-color': '#191970',
        'super-list-text-color': '#191970',
        'super-main-bg-color': '#f8f8ff',
        'super-main-title-color': '#4682b4',
        'super-secondary-bg-color': '#f0f8ff',
        'super-subtitle-color': '#6495ed',
      }
    },
    {
      name: 'Emerald City',
      description: 'Rich greens with gold accents',
      usewhen: 'For a luxurious, nature-inspired look',
      classes: {
        'nav-button-bg-off': '#2e8b57',
        'nav-button-bg-on': '#1a5130',
        'nav-button-text-off': '#98fb98',
        'nav-button-text-on': '#ffd700',
        'nav-progress-meter': '#3cb371',
        'nav-progress-meter-bg': '#ffd700',
        'nav-region-bg': '#1a5130',
        'super-highlight-color': '#ffd700',
        'super-list-icon-color': '#f0fff0',
        'super-list-text-color': '#f0fff0',
        'super-main-bg-color': '#228b22',
        'super-main-title-color': '#98fb98',
        'super-secondary-bg-color': '#1a5130',
        'super-subtitle-color': '#7cfc00',
      }
    },
    {
      name: 'Cherry Blossom',
      description: 'Soft pinks and whites inspired by spring',
      usewhen: 'For a delicate, feminine interface',
      classes: {
        'nav-button-bg-off': '#fff0f5',
        'nav-button-bg-on': '#ffe4e1',
        'nav-button-text-off': '#db7093',
        'nav-button-text-on': '#ff69b4',
        'nav-progress-meter': '#ffc0cb',
        'nav-progress-meter-bg': '#ff69b4',
        'nav-region-bg': '#ffe4e1',
        'super-highlight-color': '#ff69b4',
        'super-list-icon-color': '#8b008b',
        'super-list-text-color': '#8b008b',
        'super-main-bg-color': '#fffefd',
        'super-main-title-color': '#db7093',
        'super-secondary-bg-color': '#fff0f5',
        'super-subtitle-color': '#ffb6c1',
      }
    },
    {
      name: 'Volcanic Ash',
      description: 'Dark grays with fiery orange accents',
      usewhen: 'For a bold, intense interface',
      classes: {
        'nav-button-bg-off': '#4a4a4a',
        'nav-button-bg-on': '#2e2e2e',
        'nav-button-text-off': '#a9a9a9',
        'nav-button-text-on': '#ff4500',
        'nav-progress-meter': '#696969',
        'nav-progress-meter-bg': '#ff4500',
        'nav-region-bg': '#2e2e2e',
        'super-highlight-color': '#ff4500',
        'super-list-icon-color': '#dcdcdc',
        'super-list-text-color': '#dcdcdc',
        'super-main-bg-color': '#3a3a3a',
        'super-main-title-color': '#ff6347',
        'super-secondary-bg-color': '#2e2e2e',
        'super-subtitle-color': '#d3d3d3',
      }
    },
    {
      name: 'Moonlit Meadow',
      description: 'Soft purples and blues with silver accents',
      usewhen: 'For a dreamy, ethereal interface',
      classes: {
        'nav-button-bg-off': '#e6e6fa',
        'nav-button-bg-on': '#d8bfd8',
        'nav-button-text-off': '#9370db',
        'nav-button-text-on': '#8a2be2',
        'nav-progress-meter': '#dda0dd',
        'nav-progress-meter-bg': '#8a2be2',
        'nav-region-bg': '#d8bfd8',
        'super-highlight-color': '#8a2be2',
        'super-list-icon-color': '#4b0082',
        'super-list-text-color': '#4b0082',
        'super-main-bg-color': '#f8f4ff',
        'super-main-title-color': '#9370db',
        'super-secondary-bg-color': '#e6e6fa',
        'super-subtitle-color': '#ba55d3',
      }
    },
    {
      name: 'Tropical Paradise',
      description: 'Vibrant greens and blues inspired by exotic locales',
      usewhen: 'For a lively, vacation-themed interface',
      classes: {
        'nav-button-bg-off': '#00fa9a',
        'nav-button-bg-on': '#00cd66',
        'nav-button-text-off': '#008080',
        'nav-button-text-on': '#40e0d0',
        'nav-progress-meter': '#66cdaa',
        'nav-progress-meter-bg': '#40e0d0',
        'nav-region-bg': '#00cd66',
        'super-highlight-color': '#40e0d0',
        'super-list-icon-color': '#006400',
        'super-list-text-color': '#006400',
        'super-main-bg-color': '#7fffd4',
        'super-main-title-color': '#008080',
        'super-secondary-bg-color': '#00fa9a',
        'super-subtitle-color': '#20b2aa',
      }
    },
    {
      name: 'Urban Chic',
      description: 'Sophisticated grays with pops of bright color',
      usewhen: 'For a modern, stylish interface',
      classes: {
        'nav-button-bg-off': '#a9a9a9',
        'nav-button-bg-on': '#808080',
        'nav-button-text-off': '#d3d3d3',
        'nav-button-text-on': '#ff1493',
        'nav-progress-meter': '#c0c0c0',
        'nav-progress-meter-bg': '#ff1493',
        'nav-region-bg': '#808080',
        'super-highlight-color': '#ff1493',
        'super-list-icon-color': '#1a1a1a',
        'super-list-text-color': '#1a1a1a',
        'super-main-bg-color': '#dcdcdc',
        'super-main-title-color': '#ff1493',
        'super-secondary-bg-color': '#a9a9a9',
        'super-subtitle-color': '#696969',
      }
    },
    {
      name: 'Coastal Breeze',
      description: 'Light blues and sandy beiges inspired by the beach',
      usewhen: 'For a relaxing, vacation-like atmosphere',
      classes: {
        'nav-button-bg-off': '#f0e68c',
        'nav-button-bg-on': '#e6d67c',
        'nav-button-text-off': '#4682b4',
        'nav-button-text-on': '#1e90ff',
        'nav-progress-meter': '#b0e0e6',
        'nav-progress-meter-bg': '#1e90ff',
        'nav-region-bg': '#e6d67c',
        'super-highlight-color': '#1e90ff',
        'super-list-icon-color': '#8b4513',
        'super-list-text-color': '#8b4513',
        'super-main-bg-color': '#fffaf0',
        'super-main-title-color': '#4682b4',
        'super-secondary-bg-color': '#f0e68c',
        'super-subtitle-color': '#87ceeb',
      }
    },
    {
      name: 'Retro Funk',
      description: 'Bold, vibrant colors inspired by the 70s',
      usewhen: 'For a fun, nostalgic interface',
      classes: {
        'nav-button-bg-off': '#ff6b35',
        'nav-button-bg-on': '#f7c59f',
        'nav-button-text-off': '#004e89',
        'nav-button-text-on': '#1a659e',
        'nav-progress-meter': '#ff9f1c',
        'nav-progress-meter-bg': '#1a659e',
        'nav-region-bg': '#f7c59f',
        'super-highlight-color': '#1a659e',
        'super-list-icon-color': '#2b2d42',
        'super-list-text-color': '#2b2d42',
        'super-main-bg-color': '#fffbf0',
        'super-main-title-color': '#004e89',
        'super-secondary-bg-color': '#ff6b35',
        'super-subtitle-color': '#ff9f1c',
      }
    },
    {
      name: 'Zen Garden',
      description: 'Calming earth tones with soft green accents',
      usewhen: 'For a peaceful, meditative interface',
      classes: {
        'nav-button-bg-off': '#d6ccc2',
        'nav-button-bg-on': '#c5bdad',
        'nav-button-text-off': '#5f7a61',
        'nav-button-text-on': '#7caa8d',
        'nav-progress-meter': '#a5a58d',
        'nav-progress-meter-bg': '#7caa8d',
        'nav-region-bg': '#c5bdad',
        'super-highlight-color': '#7caa8d',
        'super-list-icon-color': '#3f4238',
        'super-list-text-color': '#3f4238',
        'super-main-bg-color': '#edede9',
        'super-main-title-color': '#5f7a61',
        'super-secondary-bg-color': '#d6ccc2',
        'super-subtitle-color': '#a5a58d',
      }
    },
    {
      name: 'Cyberpunk Neon',
      description: 'Electric blues and magentas on a dark background',
      usewhen: 'For a futuristic, high-tech interface',
      classes: {
        'nav-button-bg-off': '#1a1a2e',
        'nav-button-bg-on': '#16213e',
        'nav-button-text-off': '#e94560',
        'nav-button-text-on': '#0f3460',
        'nav-progress-meter': '#533483',
        'nav-progress-meter-bg': '#0f3460',
        'nav-region-bg': '#16213e',
        'super-highlight-color': '#0f3460',
        'super-list-icon-color': '#e94560',
        'super-list-text-color': '#e94560',
        'super-main-bg-color': '#0f0f0f',
        'super-main-title-color': '#e94560',
        'super-secondary-bg-color': '#1a1a2e',
        'super-subtitle-color': '#533483',
      }
    },
    {
      name: 'Nordic Frost',
      description: 'Cool blues and grays inspired by Scandinavian design',
      usewhen: 'For a clean, minimalist interface',
      classes: {
        'nav-button-bg-off': '#e5e9f0',
        'nav-button-bg-on': '#d8dee9',
        'nav-button-text-off': '#4c566a',
        'nav-button-text-on': '#5e81ac',
        'nav-progress-meter': '#81a1c1',
        'nav-progress-meter-bg': '#5e81ac',
        'nav-region-bg': '#d8dee9',
        'super-highlight-color': '#5e81ac',
        'super-list-icon-color': '#2e3440',
        'super-list-text-color': '#2e3440',
        'super-main-bg-color': '#eceff4',
        'super-main-title-color': '#4c566a',
        'super-secondary-bg-color': '#e5e9f0',
        'super-subtitle-color': '#81a1c1',
      }
    },
    {
      name: 'Moroccan Spice',
      description: 'Rich, warm colors inspired by Middle Eastern markets',
      usewhen: 'For an exotic, inviting interface',
      classes: {
        'nav-button-bg-off': '#e6b89c',
        'nav-button-bg-on': '#eda379',
        'nav-button-text-off': '#9e2a2b',
        'nav-button-text-on': '#540b0e',
        'nav-progress-meter': '#f09d51',
        'nav-progress-meter-bg': '#540b0e',
        'nav-region-bg': '#eda379',
        'super-highlight-color': '#540b0e',
        'super-list-icon-color': '#335c67',
        'super-list-text-color': '#335c67',
        'super-main-bg-color': '#fff3e6',
        'super-main-title-color': '#9e2a2b',
        'super-secondary-bg-color': '#e6b89c',
        'super-subtitle-color': '#f09d51',
      }
    },
    {
      name: 'Electric Lemonade',
      description: 'Bright yellows and lime greens for a zesty look',
      usewhen: 'For an energetic, youthful interface',
      classes: {
        'nav-button-bg-off': '#ccff33',
        'nav-button-bg-on': '#aaff00',
        'nav-button-text-off': '#1a3a3a',
        'nav-button-text-on': '#4a7856',
        'nav-progress-meter': '#9acd32',
        'nav-progress-meter-bg': '#4a7856',
        'nav-region-bg': '#aaff00',
        'super-highlight-color': '#4a7856',
        'super-list-icon-color': '#1a3a3a',
        'super-list-text-color': '#1a3a3a',
        'super-main-bg-color': '#f0fff0',
        'super-main-title-color': '#1a3a3a',
        'super-secondary-bg-color': '#ccff33',
        'super-subtitle-color': '#9acd32',
      }
    },
    {
      name: 'Vintage Typewriter',
      description: 'Muted browns and creams for a nostalgic feel',
      usewhen: 'For a classic, literary-inspired interface',
      classes: {
        'nav-button-bg-off': '#f2e9e4',
        'nav-button-bg-on': '#e5d5c5',
        'nav-button-text-off': '#4a4e69',
        'nav-button-text-on': '#22223b',
        'nav-progress-meter': '#9a8c98',
        'nav-progress-meter-bg': '#22223b',
        'nav-region-bg': '#e5d5c5',
        'super-highlight-color': '#22223b',
        'super-list-icon-color': '#4a4e69',
        'super-list-text-color': '#4a4e69',
        'super-main-bg-color': '#faf9f6',
        'super-main-title-color': '#4a4e69',
        'super-secondary-bg-color': '#f2e9e4',
        'super-subtitle-color': '#9a8c98',
      }
    },
    {
      name: 'Deep Space',
      description: 'Dark purples and blues with starry accents',
      usewhen: 'For a cosmic, awe-inspiring interface',
      classes: {
        'nav-button-bg-off': '#0c0032',
        'nav-button-bg-on': '#190061',
        'nav-button-text-off': '#3500d3',
        'nav-button-text-on': '#282828',
        'nav-progress-meter': '#240090',
        'nav-progress-meter-bg': '#282828',
        'nav-region-bg': '#190061',
        'super-highlight-color': '#282828',
        'super-list-icon-color': '#f0f0f0',
        'super-list-text-color': '#f0f0f0',
        'super-main-bg-color': '#060016',
        'super-main-title-color': '#3500d3',
        'super-secondary-bg-color': '#0c0032',
        'super-subtitle-color': '#240090',
      }
    },
    {
      name: 'Bubblegum Pop',
      description: 'Bright pinks and blues for a playful look',
      usewhen: 'For a fun, youthful interface',
      classes: {
        'nav-button-bg-off': '#ffc0cb',
        'nav-button-bg-on': '#ff69b4',
        'nav-button-text-off': '#1e90ff',
        'nav-button-text-on': '#4169e1',
        'nav-progress-meter': '#87cefa',
        'nav-progress-meter-bg': '#4169e1',
        'nav-region-bg': '#ff69b4',
        'super-highlight-color': '#4169e1',
        'super-list-icon-color': '#4b0082',
        'super-list-text-color': '#4b0082',
        'super-main-bg-color': '#fff0f5',
        'super-main-title-color': '#1e90ff',
        'super-secondary-bg-color': '#ffc0cb',
        'super-subtitle-color': '#87cefa',
      }
    },
    {
      name: 'Rustic Charm',
      description: 'Warm, earthy tones inspired by country living',
      usewhen: 'For a cozy, homey interface',
      classes: {
        'nav-button-bg-off': '#e6ccb2',
        'nav-button-bg-on': '#ddb892',
        'nav-button-text-off': '#7f5539',
        'nav-button-text-on': '#9c6644',
        'nav-progress-meter': '#b08968',
        'nav-progress-meter-bg': '#9c6644',
        'nav-region-bg': '#ddb892',
        'super-highlight-color': '#9c6644',
        'super-list-icon-color': '#3e2a1e',
        'super-list-text-color': '#3e2a1e',
        'super-main-bg-color': '#f2ebe3',
        'super-main-title-color': '#7f5539',
        'super-secondary-bg-color': '#e6ccb2',
        'super-subtitle-color': '#b08968',
      }
    },
    {
      name: 'Neon Jungle',
      description: 'Vibrant greens and pinks on a dark background',
      usewhen: 'For a bold, tropical-meets-urban interface',
      classes: {
        'nav-button-bg-off': '#0d160b',
        'nav-button-bg-on': '#1b2a1f',
        'nav-button-text-off': '#00ff9f',
        'nav-button-text-on': '#ff00ff',
        'nav-progress-meter': '#39ff14',
        'nav-progress-meter-bg': '#ff00ff',
        'nav-region-bg': '#1b2a1f',
        'super-highlight-color': '#ff00ff',
        'super-list-icon-color': '#e6ffed',
        'super-list-text-color': '#e6ffed',
        'super-main-bg-color': '#0a1108',
        'super-main-title-color': '#00ff9f',
        'super-secondary-bg-color': '#0d160b',
        'super-subtitle-color': '#39ff14',
      }
    },
    {
      name: 'Watercolor Dream',
      description: 'Soft, pastel hues blending seamlessly',
      usewhen: 'For a gentle, artistic interface',
      classes: {
        'nav-button-bg-off': '#e6f3ff',
        'nav-button-bg-on': '#d4e7f9',
        'nav-button-text-off': '#90a8c0',
        'nav-button-text-on': '#7d98b3',
        'nav-progress-meter': '#b6cce2',
        'nav-progress-meter-bg': '#7d98b3',
        'nav-region-bg': '#d4e7f9',
        'super-highlight-color': '#7d98b3',
        'super-list-icon-color': '#4a6b8a',
        'super-list-text-color': '#4a6b8a',
        'super-main-bg-color': '#f0f8ff',
        'super-main-title-color': '#90a8c0',
        'super-secondary-bg-color': '#e6f3ff',
        'super-subtitle-color': '#b6cce2',
      }
    },
    {
      name: 'Steampunk Cogs',
      description: 'Rich browns and metallic accents',
      usewhen: 'For a vintage, industrial-inspired interface',
      classes: {
        'nav-button-bg-off': '#d6b08f',
        'nav-button-bg-on': '#b38b6d',
        'nav-button-text-off': '#5c3d2e',
        'nav-button-text-on': '#b6542c',
        'nav-progress-meter': '#8e5572',
        'nav-progress-meter-bg': '#b6542c',
        'nav-region-bg': '#b38b6d',
        'super-highlight-color': '#b6542c',
        'super-list-icon-color': '#2c1a1d',
        'super-list-text-color': '#2c1a1d',
        'super-main-bg-color': '#f2d3ab',
        'super-main-title-color': '#5c3d2e',
        'super-secondary-bg-color': '#d6b08f',
        'super-subtitle-color': '#8e5572',
      }
    },
    {
      name: 'Aurora Borealis',
      description: 'Shimmering greens and purples on a dark sky',
      usewhen: 'For a magical, nature-inspired interface',
      classes: {
        'nav-button-bg-off': '#0c0f13',
        'nav-button-bg-on': '#1b2735',
        'nav-button-text-off': '#48d1cc',
        'nav-button-text-on': '#9932cc',
        'nav-progress-meter': '#3cb371',
        'nav-progress-meter-bg': '#9932cc',
        'nav-region-bg': '#1b2735',
        'super-highlight-color': '#9932cc',
        'super-list-icon-color': '#e6e6fa',
        'super-list-text-color': '#e6e6fa',
        'super-main-bg-color': '#090b0e',
        'super-main-title-color': '#48d1cc',
        'super-secondary-bg-color': '#0c0f13',
        'super-subtitle-color': '#3cb371',
      }
    },
    {
      name: 'Lemon Sorbet',
      description: 'Refreshing yellows and soft greens',
      usewhen: 'For a bright, summery interface',
      classes: {
        'nav-button-bg-off': '#fffacd',
        'nav-button-bg-on': '#fafad2',
        'nav-button-text-off': '#9acd32',
        'nav-button-text-on': '#32cd32',
        'nav-progress-meter': '#f0e68c',
        'nav-progress-meter-bg': '#32cd32',
        'nav-region-bg': '#fafad2',
        'super-highlight-color': '#32cd32',
        'super-list-icon-color': '#556b2f',
        'super-list-text-color': '#556b2f',
        'super-main-bg-color': '#ffffe0',
        'super-main-title-color': '#9acd32',
        'super-secondary-bg-color': '#fffacd',
        'super-subtitle-color': '#f0e68c',
      }
    },
    {
      name: 'Midnight Jazz',
      description: 'Deep blues with golden accents',
      usewhen: 'For a sophisticated, nightlife-inspired interface',
      classes: {
        'nav-button-bg-off': '#1a1b26',
        'nav-button-bg-on': '#24283b',
        'nav-button-text-off': '#7aa2f7',
        'nav-button-text-on': '#e0af68',
        'nav-progress-meter': '#565f89',
        'nav-progress-meter-bg': '#e0af68',
        'nav-region-bg': '#24283b',
        'super-highlight-color': '#e0af68',
        'super-list-icon-color': '#c0caf5',
        'super-list-text-color': '#c0caf5',
        'super-main-bg-color': '#16161e',
        'super-main-title-color': '#7aa2f7',
        'super-secondary-bg-color': '#1a1b26',
        'super-subtitle-color': '#565f89',
      }
    },
    {
      name: 'Cotton Candy',
      description: 'Soft pinks and blues in pastel tones',
      usewhen: 'For a sweet, lighthearted interface',
      classes: {
        'nav-button-bg-off': '#fbcce7',
        'nav-button-bg-on': '#f9bde3',
        'nav-button-text-off': '#a2d2ff',
        'nav-button-text-on': '#bde0fe',
        'nav-progress-meter': '#cdb4db',
        'nav-progress-meter-bg': '#bde0fe',
        'nav-region-bg': '#f9bde3',
        'super-highlight-color': '#bde0fe',
        'super-list-icon-color': '#5e548e',
        'super-list-text-color': '#5e548e',
        'super-main-bg-color': '#fff0f5',
        'super-main-title-color': '#a2d2ff',
        'super-secondary-bg-color': '#fbcce7',
        'super-subtitle-color': '#cdb4db',
      }
    },
    {
      name: 'Terracotta Sunset',
      description: 'Warm earth tones inspired by desert landscapes',
      usewhen: 'For a warm, inviting interface',
      classes: {
        'nav-button-bg-off': '#e07a5f',
        'nav-button-bg-on': '#d36a4f',
        'nav-button-text-off': '#f2cc8f',
        'nav-button-text-on': '#f4f1de',
        'nav-progress-meter': '#81b29a',
        'nav-progress-meter-bg': '#f4f1de',
        'nav-region-bg': '#d36a4f',
        'super-highlight-color': '#f4f1de',
        'super-list-icon-color': '#3d405b',
        'super-list-text-color': '#3d405b',
        'super-main-bg-color': '#f2e5d7',
        'super-main-title-color': '#f2cc8f',
        'super-secondary-bg-color': '#e07a5f',
        'super-subtitle-color': '#81b29a',
      }
    },
    {
      name: 'Enchanted Forest',
      description: 'Deep greens with mystical purple accents',
      usewhen: 'For a magical, nature-inspired interface',
      classes: {
        'nav-button-bg-off': '#2d3e40',
        'nav-button-bg-on': '#1e2d2f',
        'nav-button-text-off': '#7caf5f',
        'nav-button-text-on': '#9b5de5',
        'nav-progress-meter': '#4a8360',
        'nav-progress-meter-bg': '#9b5de5',
        'nav-region-bg': '#1e2d2f',
        'super-highlight-color': '#9b5de5',
        'super-list-icon-color': '#e0e0e0',
        'super-list-text-color': '#e0e0e0',
        'super-main-bg-color': '#1a2425',
        'super-main-title-color': '#7caf5f',
        'super-secondary-bg-color': '#2d3e40',
        'super-subtitle-color': '#4a8360',
      }
    },
    {
      name: 'Retro Diner',
      description: 'Classic red, black, and white with chrome accents',
      usewhen: 'For a nostalgic, 1950s-inspired interface',
      classes: {
        'nav-button-bg-off': '#e6e6e6',
        'nav-button-bg-on': '#d9d9d9',
        'nav-button-text-off': '#b22222',
        'nav-button-text-on': '#000000',
        'nav-progress-meter': '#c0c0c0',
        'nav-progress-meter-bg': '#000000',
        'nav-region-bg': '#d9d9d9',
        'super-highlight-color': '#000000',
        'super-list-icon-color': '#8b0000',
        'super-list-text-color': '#8b0000',
        'super-main-bg-color': '#ffffff',
        'super-main-title-color': '#b22222',
        'super-secondary-bg-color': '#e6e6e6',
        'super-subtitle-color': '#c0c0c0',
      }
    },
  ];








  //  const GetThemes = (props) => {

  const [themelListResponse, setThemeListResponse] = useState();
  const functions = getFunctions(app);

  // const callFirebaseFunction = async () => {
  //   const getThemesCall = httpsCallable(functions, "themeList");
  //   const resp = await getThemesCall();
  //   setThemeListResponse(resp.data)
  // };

  // useEffect(() => {
  //   callFirebaseFunction()
  // }, [])

  // useEffect(() => {
  //   console.log('themelListResponse')
  //   console.log(themelListResponse)
  // }, [themelListResponse])


  //  }





  const LoadTheme = (classes) => {

    const storyRef = doc(db, 'stories', storyId);
    updateDoc(storyRef,
      {
        ...storyData,
        uiStyle: classes,
        lastModified: serverTimestamp()
      }
    )
  }







  const MaterialControl = () => {


    /*
      color={storyData.backgroundGroundColor.color}
      shininess={storyData.backgroundGroundColor.shininess}
      emissive={storyData.backgroundGroundColor.emissive}
      specular={storyData.backgroundGroundColor.specular}
      metalness={storyData.backgroundGroundColor.metalness}
      roughness={storyData.backgroundGroundColor.roughness}
    */

    const [materialProps, setMaterialProps] = useState({
      color: `${storyData.backgroundGroundColor.color || '#ff0000'}`,
      shininess: `${storyData.backgroundGroundColor.shininess || '30'}`,
      emissive: `${storyData.backgroundGroundColor.emissive || '#000000'}`,
      specular: `${storyData.backgroundGroundColor.specular || '#111111'}`,
      metalness: `${storyData.backgroundGroundColor.metalness || 0}`,
      roughness: `${storyData.backgroundGroundColor.roughness || 0.5}`,
    });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setMaterialProps((prev) => ({ ...prev, [name]: value }));

      //console.log('materialProps', materialProps)

      updateBackgroundGroundColor(materialProps)
    };

    const ColorInput = ({ name, value }) => (
      <div className="relative">
        <input
          type="color"
          name={name}
          value={value}
          onChange={handleInputChange}
          className="sr-only"
          id={`color-${name}`}
        />
        <label
          htmlFor={`color-${name}`}
          className="block w-10 h-10 rounded-full cursor-pointer"
          style={{ backgroundColor: value }}
        />
      </div>
    );

    return (
      <div className="">
        <div className="w-full p-4 text-zinc-400 z-10">
          <h2 className="text-xl font-bold mb-4">Material Properties</h2>
          <div className="grid grid-cols-3 gap-4">
            <div className="flex flex-col items-center">
              <span className="mb-2">Color</span>
              <ColorInput name="color" value={materialProps.color} />
            </div>
            <div className="flex flex-col items-center">
              <span className="mb-2">Emissive</span>
              <ColorInput name="emissive" value={materialProps.emissive} />
            </div>
            <div className="flex flex-col items-center">
              <span className="mb-2">Specular</span>
              <ColorInput name="specular" value={materialProps.specular} />
            </div>
          </div>
          <div className="mt-4 grid grid-cols-1 gap-4">
            <label className="flex flex-col">
              Shininess: {materialProps.shininess}
              <input
                type="range"
                name="shininess"
                min="0"
                max="100"
                value={materialProps.shininess}
                onChange={handleInputChange}
                className="w-full rounded-lg"
              />
            </label>
            <label className="flex flex-col">
              Metalness: {materialProps.metalness}
              <input
                type="range"
                name="metalness"
                min="0"
                max="1"
                step="0.1"
                value={materialProps.metalness}
                onChange={handleInputChange}
                className="w-full rounded-lg"
              />
            </label>
            <label className="flex flex-col">
              Roughness: {materialProps.roughness}
              <input
                type="range"
                name="roughness"
                min="0"
                max="1"
                step="0.1"
                value={materialProps.roughness}
                onChange={handleInputChange}
                className="w-full rounded-lg"
              />
            </label>
          </div>
        </div>
        <div className="w-2/3">
          <Canvas>
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} />
            <mesh>
              <sphereGeometry args={[2, 32, 32]} />
              <meshPhongMaterial {...materialProps} />
            </mesh>
          </Canvas>
        </div>
      </div>
    );
  };







  useCountListener((action) => {
    //setCount((count) => (action === "increment" ? count + 1 : count - 1));
    // console.log('count listener')
    // console.log(action)

    const cloneVizItem = action.vizItem

    // console.log('cloneVizItem')
    // console.log(cloneVizItem)

    let cloneVizAll = []
    cloneVizAll = [...sceneData[sessionData.activeIndex].viznodes]

    cloneVizAll.forEach(vizItem => {
      //   if (vizItem.media) {
      if (vizItem.guid == cloneVizItem.guid) {
        //console.log('vizItem updating...')
        if (vizItem.id == 3) {

          vizItem.pos.x = vizItem.pos.x - action.x
          vizItem.pos.y = vizItem.pos.y + action.y
          vizItem.pos.z = (vizItem.pos.z || 0) + action.z
          //console.log('vizItem pos', vizItem.pos)
          // vizItem.pos.x = action.x
          // vizItem.pos.y = action.y
          // vizItem.pos.z = action.z          
        }
        else {
          vizItem.pos.x = vizItem.pos.x + action.x
          vizItem.pos.y = vizItem.pos.y + action.y
        }
        //console.log(vizItem)        
      }
    })

    // console.log('Scene Data....')
    // console.log(sceneData)
    // console.log('Scene - Update Data....')
    // console.log(cloneVizAll)

    // let freshScenes = []

    // sceneData.forEach(sceneItem => {
    //   console.log('----- scene here -----')
    //   console.log(sceneItem)
    //   if (sceneItem.id == sessionData.activeSceneId) {
    //     sceneItem.viznodes = cloneVizAll
    //   }
    //   freshScenes.push(sceneItem)
    // })

    // console.log('freshScenes')
    // console.log(freshScenes)

    //    setSceneData(freshScenes)


    // TODO - update the component data here to keep main and side in sync...!

    // TODO - get data back to the control....
    // setSceneData(prevState => ({
    //   ...prevState,
    //   vizNodes: cloneVizAll
    // }))




    // } )
    let activeSceneId = sceneData[sessionData.activeIndex].id
    const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

    updateDoc(sceneRef, {
      viznodes: cloneVizAll
    })
    //   }
  })






  // TODO - offer detailed control for 
  // Size, Position, Height, Depth
  const modelAdjust = (action, adjustX, adjustY, adjustZ, scale) => {
    const cloneVizItem = action
    let cloneVizAll = []
    cloneVizAll = [...sceneData[sessionData.activeIndex].viznodes]

    cloneVizAll.forEach(vizItem => {
      if (vizItem.guid == cloneVizItem.guid) {
        if (!vizItem.scale || vizItem.scale == null || vizItem.scale == undefined || vizItem.scale == '' || vizItem.scale == 'NaN') {
          vizItem.scale = 1
        }
        if (!vizItem.pos.z || vizItem.pos.z == null || vizItem.pos.z == undefined || vizItem.pos.z == '' || vizItem.pos.z == 'NaN') {
          vizItem.pos.z = 0
        }
        vizItem.pos.x = vizItem.pos.x + adjustX
        vizItem.pos.y = vizItem.pos.y + adjustY
        vizItem.pos.z = vizItem.pos.z + adjustZ
        vizItem.scale = vizItem.scale + scale
      }
    })

    let activeSceneId = sceneData[sessionData.activeIndex].id
    const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

    updateDoc(sceneRef, {
      viznodes: cloneVizAll
    })
  }



  const modelAdjustRot = (action, adjustX, adjustY, adjustZ, scale) => {
    const cloneVizItem = action
    let cloneVizAll = []
    cloneVizAll = [...sceneData[sessionData.activeIndex].viznodes]

    cloneVizAll.forEach(vizItem => {
      if (vizItem.guid == cloneVizItem.guid) {
        if (!vizItem.scale || vizItem.scale == null || vizItem.scale == undefined || vizItem.scale == '' || vizItem.scale == 'NaN') {
          vizItem.scale = 1
        }
        if (!vizItem.pos.z || vizItem.pos.z == null || vizItem.pos.z == undefined || vizItem.pos.z == '' || vizItem.pos.z == 'NaN') {
          vizItem.pos.z = 0
        }
        vizItem.rot.x = (vizItem.rot.x || 0) + adjustX
        vizItem.rot.y = (vizItem.rot.y || 0) + adjustY
        vizItem.rot.z = (vizItem.rot.z || 0) + adjustZ
        vizItem.scale = vizItem.scale + scale
      }
    })

    let activeSceneId = sceneData[sessionData.activeIndex].id
    const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

    updateDoc(sceneRef, {
      viznodes: cloneVizAll
    })
  }






  const modelAttributePosAdjust = (action, pos, value) => {
    const cloneVizItem = action
    let cloneVizAll = []
    cloneVizAll = [...sceneData[sessionData.activeIndex].viznodes]

    cloneVizAll.forEach(vizItem => {
      if (vizItem.guid == cloneVizItem.guid) {
        vizItem.pos[pos] = value
        // console.log('value')
        // console.log(value)
      }
    })

    let activeSceneId = sceneData[sessionData.activeIndex].id
    const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

    updateDoc(sceneRef, {
      viznodes: cloneVizAll
    })
  }


  const PlatformMaterialOptions = (keycode = 'colorcode') => {
    return (
      <Menu as="div" className="relative inline-block  w-full text-left">
        <div className=" admin-dropdown rounded-md">
          <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded admin-bg-side admin-component pl-3 py-3 text-sm font-normal text-gray-200   ">
            <img className="w-5 h-5  rounded-full" src={'/util/matcap/' + sceneData[sessionData.activeIndex].platform.matcap + '.png'} />
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
            <div className="section-scroll h-64 grid grid-cols-5 gap-1 px-1">
              {matList.map((mat, index) => (
                <div
                  key={'platformmat' + keycode + index}
                  className={`w-full p-2 rounded  ${mat.value == sceneData[sessionData.activeIndex].platform.matcap ? 'bg-white/20' : 'hover:bg-white/20 cursor-pointer'}`}
                  //onClick={() => updateBackgroundMat(mat.value)} key={mat.value} 
                  onClick={() => updateScene('platform',
                    {
                      model: sceneData[sessionData.activeIndex].platform.model,
                      matcap: mat.value,
                      text: {
                        color: sceneData[sessionData.activeIndex].platform.text.color,
                        scale: sceneData[sessionData.activeIndex].platform.text.scale,
                      }
                    }
                  )}
                >
                  <img className="w-full  rounded-full" src={'/util/matcap/' + mat.value + '_40.png'} />
                </div>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }





  const [statusRecycle, setStatusRecycle] = useState(' opacity-50 ')

  const ActiveComponentHistory = () => {
    return (
      <Menu as="div" className="relative inline-block w-full text-right">
        <div className="   ">
          <Menu.Button className=" mr-2 py-3 px-4 hover:bg-white/5 rounded ">

            {/* <img className="w-5 h-5  rounded-full" src={'/util/matcap/' + sceneData[sessionData.activeIndex].platform.matcap + '.png'} />
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" /> */}
            {/* <span className="text-gray-300 text-right text-xs mr-2">{notifications.length}</span> */}

            <img src="/util/app/b3-admin-recycle-bin.svg"
              className={`h16 transition-all animate__repeat-2 animate__animated ${statusRecycle}`} />

          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none" style={{ width: '350px', marginLeft: '-280px' }}>
            <div className="section-scroll h-96 p-2">
              {notifications.length == 0 ?

                <div className="info-blank font-normal">
                  <div>
                    <img src="/media/icon/noun-information-5783675-00AAFF.svg" className="h24 m-lr-auto mt-4 mb-4" />
                    If you delete items, they will appear here - allowing you to restore them to their scene
                  </div>
                </div>
                :
                <ul>
                  {notifications.map(notification => (
                    <li key={'notif' + notification.id}
                      //className="mb-2"
                      onClick={() =>
                        //console.log(notification.data.item)
                        addComponent(notification.data.item)
                      }
                    >
                      <div className="flex justify-between hover:cursor-pointer hover:bg-white/10 rounded-md pr-4">
                        <div className="flex">
                          <div className="w-16 h-16 mr-4 mb-2 text-center">
                            {(notification && notification.data && notification.data.item && notification.data.item.media) ?
                              <>

                                {/* {notification.data.item.model.includes('storage.googleapis.com') ?
                                  <>
                                    <img src={notification.data.item.modelThumbnail} className=" w-full inline-block" />
                                  </>
                                  :
                                  <>
                                    <img src={'/media/thumbnail/' + notification.data.item.model.replace('glb', 'png')} className=" w-full inline-block" />
                                  </>
                                } */}

                                {/* <img src={'/media/thumbnail/' + notification.data.item.model.replace('glb', 'png')} className=" w-full inline-block " /> */}



                              </>
                              : <>
                                {notification.data?.item?.media ?
                                  <>
                                    {notification.data.item.media[0] ?
                                      <img src={notification.data.item.media[0].image} className="mt-4   w-8 mx-auto" />
                                      :
                                      <img src={'/media/icon/' + notification.data.item.icon + '.svg'} className="mt-4 opacity-50 w-8 mx-auto" />
                                    }
                                  </>
                                  :
                                  null
                                }</>
                            }
                          </div>
                          <div className="inline-block mt-6 text-sm">
                            {notification.data && notification.data.item && (
                              <span>{notification.data.item.name}</span>
                            )}
                          </div>
                        </div>

                        <div className="flex-initial mt-6 text-sm">
                          {/* {timeAgo.format(notification.data.time, 'mini-minute-now')} */}
                        </div>

                      </div>

                      {/* you get the idea, you are free to use the properties the way that best suits your needs */}
                    </li>
                  ))}
                </ul>
              }
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }

  // const PlatformOptions = () => {
  //   return (
  //     <Menu as="div" className="relative inline-block  w-full text-left">
  //       <div>
  //         <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded   admin-component px-3 py-3 text-sm font-normal text-gray-300  pl-4 ">
  //           Shape
  //           <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
  //         </Menu.Button>
  //       </div>
  //       <Transition
  //         as={Fragment}
  //         enter="transition ease-out duration-100"
  //         enterFrom="transform opacity-0 scale-95"
  //         enterTo="transform opacity-100 scale-100"
  //         leave="transition ease-in duration-75"
  //         leaveFrom="transform opacity-100 scale-100"
  //         leaveTo="transform opacity-0 scale-95"
  //       >
  //         <Menu.Items className="absolute left-0 z-10 w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">

  //           {/* <div>COL2</div> */}
  //           <div className="section-scroll h300  grid grid-flow-row-dense grid-cols-1">

  //             {sceneData[sessionData.activeIndex] != null ? platformOptions.map((platformItem, index) => (
  //               <div
  //                 className={`    px-4 py-2 hover:bg-white/10  text-xs cursor-pointer 
  //                 ${sceneData[sessionData.activeIndex].platform ? platformItem.model == sceneData[sessionData.activeIndex].platform.model ? 'bg-white/10 ' : '' : null}
  //                 `}
  //                 onClick={() => updateScene('platform',
  //                   {
  //                     model: platformItem.model,
  //                     matcap: sceneData[sessionData.activeIndex].platform.matcap,
  //                     text: {
  //                       color: sceneData[sessionData.activeIndex].platform.text.color,
  //                       scale: sceneData[sessionData.activeIndex].platform.text.scale,
  //                     }
  //                   }
  //                 )}
  //                 key={platformItem.id + '_' + platformItem.name} >
  //                 {platformItem.name}
  //               </div>
  //             ))
  //               : null}
  //           </div>

  //         </Menu.Items>
  //       </Transition>
  //     </Menu>
  //   )
  // }

  const NavigationStyle = () => {
    return (
      <Menu as="div" className="relative inline-block  w-full text-left">
        <div className="mt-2 admin-dropdown rounded-md">
          <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded admin-bg-side admin-component px-3 py-3 text-sm font-normal text-gray-400   ">
            Navigation Layout
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2  w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
            <div className="section-scroll h-96 px-1 pr-2">
              {storyNavigationList.map((navItem, index) => (
                <div
                  className={`rounded text-sm
      ${navItem.guid == storyData.navigation ? 'bg-white/20' : 'hover:bg-white/20 cursor-pointer'}`}
                  onClick={() => updateStory('navigation', navItem.guid)} key={navItem.guid} >
                  <div className=" p-3">{navItem.name}</div>
                </div>
              ))}
            </div>
          </Menu.Items>

          {/* <Menu.Items className="absolute left-0 z-10 mt-2  w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
            <div className="section-scroll h-96 px-1 pr-2">
              {groundList.map((ground, index) => (
                <div
                  className={`rounded 
              ${ground.model == storyData.backgroundModel ? 'bg-white/20' : 'hover:bg-white/20 cursor-pointer'}`}
                  onClick={() => updateStory('backgroundModel', ground.model)} key={ground.guid} >
                  <div className=" p-2">{ground.name}</div>
                </div>
              ))}
            </div>
          </Menu.Items> */}

        </Transition>
      </Menu>
    )
  }

  const SkyOGOptions = () => {
    return (
      <Menu as="div" className="relative inline-block  w-full text-left">
        <div className="mt-2 admin-dropdown rounded-md">
          <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded admin-bg-side admin-component px-3 py-3 text-sm font-normal text-gray-400   ">
            <div className={"h-6 w-6  rounded-full mr-2 " + storyData.backgroundSky}  ></div> Sky Color
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2  w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
            <div className="section-scroll h300 gap-2 grid grid-flow-row-dense grid-cols-3">
              {skyList.map((sky, index) => (
                <div
                  className={`component-card model-select auto-cols-auto 
     ${sky.name == storyData.backgroundSky ? sky.class + ' active ' : sky.class}`}
                  onClick={() => updateStory('backgroundSky', sky.class)} key={sky.guid} >
                </div>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }




  const SkyCloudOptions = () => {
    return (
      <Menu as="div" className="relative inline-block  w-full text-left">
        <div className="mt-2 admin-dropdown rounded-md">
          <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded admin-bg-side admin-component px-3 py-3 text-sm font-normal text-gray-400   ">
            <div className={"h-6 w-6  rounded-full mr-2 " + storyData.backgroundSkyCloud}  ></div> Clouds
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2  w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
            <div className="section-scroll h-96 px-1 pr-2">
              {skyCloudList.map((sky, index) => (
                <div
                  className={`rounded text-sm 
                    ${sky.guid == storyData.backgroundSkyCloud ? 'bg-white/20' : 'hover:bg-white/20 cursor-pointer'}`}
                  onClick={() => updateStory('backgroundSkyCloud', sky.guid)} key={sky.guid} >
                  <div className="p-3">{sky.name}</div>
                </div>
              ))}
            </div>


          </Menu.Items>
        </Transition>
      </Menu>
    )
  }




  // const GroundOptions = () => {
  //   return (
  //     <Menu as="div" className="relative inline-block  w-full text-left">
  //       <div className="mt-2 admin-dropdown rounded-md">
  //         <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded admin-bg-side admin-component px-3 py-3 text-sm font-normal text-gray-400   ">
  //           Background Landscape
  //           <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
  //         </Menu.Button>
  //       </div>
  //       <Transition
  //         as={Fragment}
  //         enter="transition ease-out duration-100"
  //         enterFrom="transform opacity-0 scale-95"
  //         enterTo="transform opacity-100 scale-100"
  //         leave="transition ease-in duration-75"
  //         leaveFrom="transform opacity-100 scale-100"
  //         leaveTo="transform opacity-0 scale-95"
  //       >
  //         <Menu.Items className="absolute left-0 z-10 mt-2  w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
  //           <div className="section-scroll h300 gap-2 grid grid-flow-row-dense grid-cols-1">
  //             {groundList.map((ground, index) => (
  //               <div
  //                 className={`component-card model-select auto-cols-auto  row-height-contents 
  //             ${ground.model == storyData.backgroundModel ? 'active' : ''}`}
  //                 onClick={() => updateStory('backgroundModel', ground.model)} key={ground.guid} >
  //                 <div className="h-24">{ground.name}</div>
  //               </div>
  //             ))}
  //           </div>
  //         </Menu.Items>
  //       </Transition>
  //     </Menu>
  //   )
  // }

  const GroundColor = () => {
    return (
      <Menu as="div" className="relative inline-block  w-full text-left">
        <div className="mt-2 admin-dropdown rounded-md">
          <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded admin-bg-side admin-component px-3 py-3 text-sm font-normal text-gray-400   ">
            <img className="w-6 h-6 mr-2 rounded-full" src={'/util/matcap/' + storyData.backgroundMatcap + '_40.png'} />   Ground Color
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2  w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
            <div className="section-scroll h-64 grid grid-cols-5 gap-1 px-1">
              {matList.map((mat, index) => (
                <div
                  key={mat.value}
                  className={`w-full p-2 rounded  ${mat.value == storyData.backgroundMatcap ? 'bg-white/20' : 'hover:bg-white/20 cursor-pointer'}`}
                  onClick={() => updateBackgroundMat(mat.value)}  >
                  <img className="w-100 rounded-full" src={'/util/matcap/' + mat.value + '_40.png'} />
                </div>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }


  const GroundOptions = () => {
    return (
      <Menu as="div" className="relative inline-block  w-full text-left">
        <div className="mt-2 admin-dropdown rounded-md">
          <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded admin-bg-side admin-component px-3 py-3 text-sm font-normal text-gray-400   ">
            Background Landscape
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2  w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
            <div className="section-scroll h-96 px-1 pr-2">
              {groundList.map((ground, index) => (
                <div
                  className={`rounded text-sm 
              ${ground.model == storyData.backgroundModel ? 'bg-white/20' : 'hover:bg-white/20 cursor-pointer'}`}
                  onClick={() => updateStory('backgroundModel', ground.model)} key={ground.guid} >
                  <div className=" p-2">{ground.name}</div>
                </div>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }

  //cameraOrbitOn




  const SkyOptions = () => {
    return (
      <Menu as="div" className="relative inline-block  w-full text-left">
        <div className="mt-2 admin-dropdown rounded-md">
          <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded admin-bg-side admin-component px-3 py-3 text-sm font-normal text-gray-400   ">
            <img className="w-6 h-6 mr-2 rounded-full" src={'/util/matcap/' + storyData.backgroundMatcap + '.png'} />   Ground Color
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2  w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
            <div className="section-scroll h300 mt-2 gap-2 grid grid-flow-row-dense grid-cols-4">
              {matList.map((mat, index) => (
                <div
                  className={`component-card model-select auto-cols-auto  
                       ${mat.value == storyData.backgroundMatcap ? 'active' : ''}`}
                  onClick={() => updateBackgroundMat(mat.value)} key={mat.value} >
                  <img className="w-100 rounded-full" src={'/util/matcap/' + mat.value + '.png'} />
                </div>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }

  const GroundOffset = () => {
    return (
      <Menu as="div" className="relative inline-block  w-full text-left">
        <div className="mt-2 admin-dropdown rounded-md">
          <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded admin-bg-side admin-component px-3 py-3 text-sm font-normal text-gray-400   ">
            Ground Height Offset
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2  w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
            <div className="section-scroll h200 gap-2 grid grid-flow-row-dense grid-cols-1">
              {groundOffset.map((groundItem, index) => (
                <div
                  className={`component-card model-select auto-cols-auto row-height-contents `}
                  onClick={() => updateStory('backgroundHeight', groundItem.backgroundHeight)}
                  key={groundItem.name + '-' + index} >
                  {groundItem.name}
                </div>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }

  const SceneLayout = () => {
    return (
      <Menu as="div" className="relative inline-block  w-full text-left">
        <div className="mt-2 admin-dropdown rounded-md">
          <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded admin-bg-side admin-component px-3 py-3 text-sm font-normal text-gray-200   ">
            Scene Layout
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2  w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
            <div className="section-scroll h200 gap-2 grid grid-flow-row-dense grid-cols-1">
              {layoutMode.map((layoutItem, index) => (
                <div
                  className={`component-card model-select auto-cols-auto row-height-contents `}
                  onClick={() => updateStory('layoutType', layoutItem.layoutType)}
                  key={layoutItem.name + 'key' + index} >
                  {layoutItem.name}
                </div>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }


  const [preExpandedDesignGuid, setPreExpandedDesignGuid] = useState('')

  const CompColorPicker = (props) => {
    const [colorVariable, setColorVariable] = useState('#000000')
    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
      setDataLoaded(true)
      if (storyData != null) {
        setColorVariable(storyData.uiStyle[props.styleName])
      }
    }, [])

    const storeColor = (color) => {
      if (color != undefined) {
        const storyRef = doc(db, 'stories', storyId)
        let cloneStory = storyData

        cloneStory['uiStyle'] =
        {
          ...cloneStory['uiStyle'],
          [props.styleName]: color
        }
        updateDoc(storyRef,
          cloneStory
        )
      }
    }



    return (

      <AccordionItem onClick={() => {
        preExpandedDesignGuid != props.styleName ? setPreExpandedDesignGuid([props.styleName]) : null
      }}
        uuid={props.styleName}>
        <AccordionItemHeading>
          <AccordionItemButton>
            <div className="flex w-3/4 ml-6 -mt-6">
              {dataLoaded ?
                <div className="flex-none h-6 w-6 rounded-full mr-2" style={{ background: storyData.uiStyle[props.styleName] }}></div>
                :
                <div className="flex-none h-6 w-6 rounded-full mr-2" style={{ background: '#000000' }}></div>
              }
              <div className="inline flex-auto ml-2 mt-0.5 text-sm text-gray-300">{props.title}</div>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel data-itemguid={'none'}>

          {generatedText.length > 0 ?
            <div className="p-1 mb-8 mt-4">
              <div className="text-gray-100 mb-2">Brand Colors</div>
              <div className="grid grid-cols-12 gap-1">
                {generatedText.map((colorCode, index) => (
                  <div
                    className='p-2 rounded-sm text-gray-50 h-6 cursor-pointer'
                    key={'colorgen' + index}
                    style={{ background: colorCode }}
                    onClick={() => {
                      storeColor(colorCode)
                    }}
                  >
                  </div>
                ))}
              </div>
            </div>
            : null}

          <CompactPicker
            // width={'100%'}
            // height={200}
            style={{ width: '20px', background: '#000000' }}
            color={colorVariable}
            disableAlpha={true}
            className="color-picker-dark"
            onChange={(color) => {
              //setColorVariable(color.hex)
              //              storeColor(colorVariable)
              //console.log('on change...')
              storeColor(color.hex)
              // Update DB... maybe use the DB to push back to this UI...
            }
            }
            onChangeComplete={(color, event) => {
              //            setColorVariable(color.hex)
              // storeColor(colorVariable)
              // console.log('on change complete...')
            }
            }
          />

        </AccordionItemPanel>
      </AccordionItem>



    )
  }

  const CompModelBrowser = (props) => {

    // console.log('Story Data...')
    // console.log(storyData)

    const [open, setOpen] = useState(false)
    const [modelCols, setModelCols] = useState(5)
    const [modelFilter, setModelFilter] = useState('')

    let uniqueCategories = [...new Set(componentModelList.map(item => item.category).sort())];

    const [filterCategory, setFilterCategory] = useState('')
    const [modelListResponse, setModelListResponse] = useState();
    const [modelListGenResponse, setModelListGenResponse] = useState();
    const functions = getFunctions(app);

    const callFirebaseFunction = async () => {
      const getGradesCall = httpsCallable(functions, "modelList"); //orgGetUsers
      const resp = await getGradesCall();
      setModelListResponse(resp.data)
      // if (resp.data) {
      //   console.log('resp')
      //   console.log(resp.data)        
      //   setModelListResponse(resp.data)
      // }
    };

    const callModelGeneration = async () => {
      const getGradesCall = httpsCallable(functions, "modelListGenerative"); // 
      const resp = await getGradesCall();
      setModelListGenResponse(resp.data)
    };

    useEffect(() => {
      callFirebaseFunction()
      callModelGeneration()
    }, [])

    useEffect(() => {
      //      console.log(modelListResponse)
    }, [modelListResponse])

    // useEffect(() => {
    //   const modelsRef = collection(db, "models");
    //   const q = query(modelsRef, where("org", "array-contains", 3));

    //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //     console.log('ORG SPECIFIC MODELS....')

    //     // let vizpackArr = []
    //     querySnapshot.forEach((doc) => {
    //       //vizpackArr.push({ ...doc.data(), id: doc.id })
    //       console.log(doc.data())
    //     })
    //     // setVizPackItems(vizpackArr)
    //     unsubscribe()
    //   },
    //     (error) => {
    //     })
    // }, [])

    useEffect(() => {
      setFilterCategory('all')
    }, [])

    return (
      <>
        <div className="text-gray-200 admin-component component-card rounded text-sm px-3 py-2.5    mb-2 text-center" onClick={() => { setOpen(true) }}>
          {props.linkText}
        </div>
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full  items-end justify-center p-8   text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden  rounded-lg bg-zinc-900    text-left shadow-xl transition-all sm:my-8 sm:w-5/6   ">
                    <div className="absolute right-4 -top-4 hidden  h-fit pr-4 pt-4 sm:block w-10">
                      <button
                        type="button"
                        className="rounded-md   focus:outline-none  "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <img src={'/media/icon/noun-close-fff.svg'} className=' opacity-50 hover:opacity-80 absolute hover:scale-105 ' />
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start">
                      <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <div className="p-4">
                          <div className='text-xl mt-4  text-gray-50  font-semibold'>
                            {props.headingText}
                          </div>
                          <input
                            type="text"
                            className="block w-full rounded-md border-0 px-4 py-2 my-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                            placeholder="Search Models"
                            value={modelFilter}
                            onChange={(e) => setModelFilter(e.target.value)}
                          />

                          <div className="flex  gap-4">
                            <div className="  w-2/12">
                              <button key={'category-all'} className={`w-full p-3 text-sm  text-gray-300 text-left rounded mb-2 ${filterCategory == 'all' ? 'bg-white/10' : 'bg-black/20 hover:bg-white/10'}`}
                                onClick={() => { setFilterCategory('all') }}
                              >
                                All Standard
                              </button>

                              <button key={'category-gen'} className={`w-full p-3 py-2 text-sm  text-gray-300 text-left rounded mb-2 ${filterCategory == 'gen' ? 'bg-white/10' : 'bg-black/20 hover:bg-white/10'}`}
                                onClick={() => {
                                  setFilterCategory('gen')
                                  //setModelCols(1)
                                }}
                              >
                                <img src="/util/app/b3-genai-v2.svg" className="inline mr-2 h-6" /> My Generated
                              </button>


                              {modelListResponse ?
                                <button key={'category-org' + modelListResponse[0].name} className={`w-full p-3 text-sm  text-gray-300 text-left rounded mb-2 ${filterCategory == 'org' ? 'bg-white/10' : 'bg-black/20 hover:bg-white/10'}`}
                                  onClick={() => { setFilterCategory('org') }}
                                >
                                  <img src={'/media/custmark/' + modelListResponse[0].category + '.svg'} className="h-6 inline mr-2" /> {modelListResponse[0].category}
                                </button>
                                : null}

                              {uniqueCategories.map(category => (

                                <button key={category} className={`w-full p-3 text-sm  text-gray-300 text-left rounded mb-2 ${filterCategory == category ? 'bg-white/10' : 'bg-black/20 hover:bg-white/10'}`}
                                  onClick={() => { setFilterCategory(category) }}
                                >
                                  {category}
                                </button>

                              ))}
                            </div>


                            <div className="w-10/12">
                              <div className=" h-[80vh] overflow-y-auto pr-4 section-scroll">






                                {filterCategory == 'gen' ?
                                  <>

                                    {/* <CompModelAI
                                      callModelGeneration={callModelGeneration}
                                    //selectSceneComponent={selectSceneComponent}
                                    /> */}

                                    <div className={"grid grid-cols-" + modelCols + " gap-4 model-select-modal"}>
                                      {modelListGenResponse ? modelListGenResponse.map((model, index) => (
                                        <div
                                          key={model.guid}
                                          className={`bg-black/40 hover:bg-white/5 rounded cursor-pointer p2 text-gray-400 auto-cols-auto  `}
                                          onClick={() => {
                                            props.selectSceneComponent(model, props.itemIndex)
                                            setOpen(false)
                                          }}
                                        >
                                          <img
                                            src={model.modelThumbnail}
                                            className="model-thumbnail   mt-4" />
                                          <div className="model-preview-name text-base text-center mt-2 mb-2">
                                            {model.name}
                                          </div>
                                        </div>
                                      ))
                                        : null
                                      }
                                    </div>
                                  </>
                                  : null}



                                <div className={"grid grid-cols-" + modelCols + " gap-4 model-select-modal"}>

                                  {filterCategory == 'org' ?
                                    <>
                                      {modelListResponse.map((model, index) => (
                                        <div
                                          key={index + model.name}
                                          className={`bg-black/40 hover:bg-white/5 rounded cursor-pointer p2 text-gray-400 auto-cols-auto  `}
                                          onClick={() => {
                                            props.selectSceneComponent(model, props.itemIndex)
                                            setOpen(false)
                                          }}
                                        >
                                          <img
                                            src={`/media/thumbnail/` + model.model.replace('glb', 'png')}
                                            className="model-thumbnail" />
                                          <div className="model-preview-name text-base text-center mt-2 mb-2">
                                            {model.name}
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                    : null}



                                  {componentModelList.filter(item => {
                                    if (filterCategory == 'all') {
                                      if (modelFilter && item.name && item.name.toLowerCase().includes(modelFilter.toLowerCase())) {
                                        return true;
                                      }
                                      if (!modelFilter) {
                                        return true;
                                      }
                                      return false
                                    };

                                    if (!filterCategory && !modelFilter) return true;

                                    if (filterCategory && item.category && item.category.toLowerCase().includes(filterCategory.toLowerCase())) {
                                      if (modelFilter && item.name && item.name.toLowerCase().includes(modelFilter.toLowerCase())) {
                                        return true;
                                      }
                                      if (!modelFilter) {
                                        return true;
                                      }

                                    }

                                    //return true;

                                    return false;
                                  })
                                    .map((model, index) => (
                                      <div
                                        key={index + model.name}
                                        className={`bg-black/40 hover:bg-white/5 rounded cursor-pointer p2 text-gray-400 auto-cols-auto  `}
                                        onClick={() => {
                                          props.selectSceneComponent(model, props.itemIndex)
                                          setOpen(false)
                                        }}
                                      >
                                        <img
                                          src={`/media/thumbnail/` + model.model.replace('glb', 'png')}
                                          className="model-thumbnail" />
                                        <div className="model-preview-name text-base text-center mt-2 mb-2">
                                          {model.name}
                                        </div>
                                      </div>

                                    ))}
                                </div>
                              </div>



                            </div>
                          </div>
                          {/* <div className='text-base mt-8 mb-8  text-gray-50'>
                            Cannot find what you are looking for?  We have a library of thousands of models, let us know what you need and we will add it to the library
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  }





  const CompCardLayoutBrowser = (props) => {
    const [open, setOpen] = useState(false)
    const [modelCols, setModelCols] = useState(6)

    const [modelFilter, setModelFilter] = useState('')

    return (
      <>
        <div className="text-gray-200 admin-component component-card rounded text-sm px-3 py-2.5    mb-2 text-center" onClick={() => { setOpen(true) }}>
          {props.linkText}
        </div>
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full  items-end justify-center p-8   text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden  rounded-lg bg-zinc-800    text-left shadow-xl transition-all sm:my-8 sm:w-3/4   ">
                    <div className="absolute right-4 -top-4 hidden  h-fit pr-4 pt-4 sm:block w-10">
                      <button
                        type="button"
                        className="rounded-md   focus:outline-none  "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <img src={'/media/icon/noun-close-fff.svg'} className=' opacity-50 hover:opacity-80 absolute hover:scale-105 ' />
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start">
                      <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <div className="p-4">
                          <div className='text-xl mt-4  text-gray-50  font-semibold'>
                            {props.headingText}
                          </div>

                          <div className=" h-[60vh] overflow-y-auto pr-4 section-scroll">
                            <div className={"grid grid-cols-" + modelCols + " gap-4 model-select-modal"}>

                              {filterCategory == 'org' ?
                                <>
                                  {componentModelList.map((model, index) => (
                                    <div
                                      key={index}
                                      className={`component-card model-select auto-cols-auto  `}
                                      onClick={() => {
                                        props.selectSceneComponent(model, props.itemIndex)
                                        setOpen(false)
                                      }}
                                    >
                                      <img
                                        src={`/media/thumbnail/` + model.model.replace('glb', 'png')}
                                        className="model-thumbnail" />
                                      <div className="model-preview-name text-base text-center">
                                        {model.name}
                                      </div>
                                    </div>
                                  ))}
                                </>
                                : null}

                              {componentModelList.map((model, index) => (
                                <div
                                  key={index}
                                  className={`component-card model-select auto-cols-auto  `}
                                  onClick={() => {
                                    props.selectSceneComponent(model, props.itemIndex)
                                    setOpen(false)
                                  }}
                                >
                                  <img
                                    src={`/media/thumbnail/` + model.model.replace('glb', 'png')}
                                    className="model-thumbnail" />
                                  <div className="model-preview-name text-base text-center">
                                    {model.name}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className='text-base mt-8 mb-8  text-gray-50'>
                            Cannot find what you are looking for?  We have a library of thousands of models, let us know what you need and we will add it to the library
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  }




  const PersonGrid = ({ sceneData }) => {
    // Function to extract person data from viznodes and ensure unique names
    const extractUniquePersonData = (scenes) => {
      const allPersons = scenes.flatMap(scene =>
        scene.viznodes
          .filter(node => node.type === "Person" && node.media && node.media.length > 0)
          .map(node => node.media[0])
      );

      // Use a Map to keep only unique entries based on personaName
      const uniquePersons = new Map();
      allPersons.forEach(person => {
        if (!uniquePersons.has(person.personaName)) {
          uniquePersons.set(person.personaName, person);
        }
      });

      return Array.from(uniquePersons.values());
    };

    const personData = extractUniquePersonData(sceneData);

    return (
      <div className="container mx-auto">
        <div className="grid grid-cols-4 gap-2 mt-4 pr-1 mb-2">
          {personData.map((person, index) => (
            <div key={person.personaName} className="text-gray-300 px-2 ml-1 mt-2 pt-3 
        
        admin-component component-card 
        w-fullm 
        h-28  rounded text-sm text-center pb-2">
              <img
                src={person.image}
                alt={person.personaName}
                className="  h-14 w-14 bg-zinc-900 rounded-full mx-auto outline outline-zinc-800"
              />
              <div className="p-1 text-center">
                <div className="text-xs ">{person.personaName}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };



  const [generatedText, setGeneratedText] = useState([]);

  const CompGetColors = () => {
    const [companyName, setCompanyName] = useState('');
    const [aiLoading, setAILoading] = useState(false);

    //let [loading, setLoading] = useState(true)
    let [color, setColor] = useState("#0ca5e9")
    const override = {
      width: "50%",
      margin: "auto",
      marginTop: "20%",
    }

    const generateTextFromPrompt = async () => {
      setAILoading(true)
      const apiUrl = `https://us-central1-brevit3-57b00.cloudfunctions.net/generateBrandResponse?companyName=${encodeURIComponent(companyName)}`;
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          setGeneratedText(data)
          console.log('This is your data', data)
          setAILoading(false)
        }
        );
    };

    // useEffect(() => {
    //   setAILoading(false)
    // }, [generatedText])


    // Render the component
    return (
      <div
      // onKeyDown={e => e.stopPropagation()}
      // onClick={e => e.stopPropagation()}
      // onFocus={e => e.stopPropagation()}
      // onMouseOver={e => e.stopPropagation()}
      >


        {aiLoading ?
          <>
            <div className="w-full">
              <BarLoader
                color={color}
                loading={aiLoading}
                cssOverride={override}
                size={450}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </> :
          <>


            <div className="py-4">
              {/* <label for="hs-trailing-icon" className="block text-sm font-medium mb-2 dark:text-white">Card number</label> */}
              <div className="relative px-1">

                <form onSubmit={generateTextFromPrompt}>
                  <input type="text" id="hs-trailing-icon" name="hs-trailing-icon" className="py-3 px-4 pr-11 block w-full    rounded-md text-sm focus:z-10  dark:text-gray-300  focus-within:ring-2 ring-zinc-700 ring-1 bg-black/20
                focus-within:ring-inset focus-within:ring-sky-400 " placeholder="Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <button className="absolute inset-y-0 right-0 flex items-center   z-20 pr-4"
                    onClick={() => generateTextFromPrompt()}
                  >
                    <img className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="20" height="20" src="/util/app/b3-genai.svg" />
                  </button>
                </form>

              </div>
            </div>






            {/* <div className="grid grid-cols-6 grid-rows-1 gap-4 mb-8">
              <div className='col-span-5'>
                <div className="col-span-full">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                focus-within:ring-inset focus-within:ring-sky-400 mt-2 focus:outline-none">
                    <input
                      type="text"
                      className="block w-full rounded-md border-0 p-2 bg-transparent text-gray-100  ring-1 ring-inset 
                      ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-sm sm:leading-6 focus:outline-none"
                      placeholder="Company Name" 
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className=" w-full justify-center rounded-md   px-3 py-2 text-base font-base text-gray-100 shadow-sm  hover:bg-gray-600 mt-2"
                  onClick={() => generateTextFromPrompt()} >
                  <img src="/util/app/b3-genai.svg" className="w-8 mx-auto" />
                </button>
              </div>
            </div> */}


          </>
        }

      </div>
    );
  };



  // // NEW TODO
  // // Platform adjustment handling here...
  // const handleSelectPlatformPosition = (e) => {
  //   const checked = e.target.checked;
  //   const value = e.target.value;
  //   setSelectedRooms(
  //     checked
  //       ? [...selectedRooms, value]
  //       : selectedRooms.filter((item) => item !== value)
  //  );
  // };







  // For layouts for SC
  const [openLayout, setOpenLayout] = useState(false)
  const [scLayout, setScLayout] = useState('')

  const OpenLayoutSelectModal = ({ updateImage }) => {
    const cancelButtonRef = useRef(null)
    const [layoutSelectedId, setLayoutSelectedId] = useState('')


    const [queryItems, setQueryItems] = useState('')
    const [vizPackItems, setVizPackItems] = useState([])

    const importVizPack = async (vizpack) => {
      let activeSceneId = sceneData[sessionData.activeIndex].id

      // TODO - add into existing... vs replace...!!

      // console.log('vizpack')
      // console.log(sceneData[sessionData.activeIndex].viznodes + vizpack.vizNodes)

      // sceneData[sessionData.activeIndex].viznodes +  

      const mergedVizNodes = sceneData[sessionData.activeIndex].viznodes.concat(vizpack.vizNodes)

      const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId);
      await updateDoc(sceneRef, {
        viznodes: mergedVizNodes //vizpack.vizNodes
      })
      setOpenLayout(false)
    }

    useEffect(() => {
      const q = query(collection(db, 'vizpacks'), orderBy("name"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let vizpackArr = []
        querySnapshot.forEach((doc) => {
          vizpackArr.push({ ...doc.data(), id: doc.id })
        })
        setVizPackItems(vizpackArr)
      },
        (error) => {
        })
      return () => {
        unsubscribe()
      }
    }, [])


    const handleChange = (newIcon) => {
      // updateImage(newIcon)
      // setScImage(newIcon)
    }



    return (
      <>
        {/* {sceneData.length > 0 ?
          <button type="button" className="text-gray-200  rounded text-sm px-5 w-full text-center py-2   items-center bg-white/5 hover:bg-white/10" onClick={() => { setOpenLayout(true) }}>
            <img src="/util/app/b3-genai.svg" className='h-7 w-7'/>
            Load Layout Preset
          </button>
          : null} */}

        <Transition.Root show={openLayout} as={Fragment}>
          <Dialog as="div" className="relative z-10"
            //initialFocus={cancelButtonRef} 
            onClose={setOpenLayout}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-700 bg-opacity-50  transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-zinc-900 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-11/12 sm:p-10" >
                    <div className="absolute right-4 -top-4 hidden  h-fit pr-4 pt-4 sm:block w-10">
                      <button
                        type="button"
                        className="rounded-md   focus:outline-none  "
                        onClick={() => setOpenLayout(false)}
                      >
                        <span className="sr-only">Close</span>
                        <img src={'/media/icon/noun-close-fff.svg'} className=' opacity-50 hover:opacity-80 absolute hover:scale-105 ' />
                      </button>
                    </div>
                    <div>
                      <div className='text-2xl text-gray-100'>
                        Select Template Layout
                      </div>

                      <div className="text-gray-400 text-base  mb-8 mt-2">
                        Selecting a template below will add the components to your scene.  Once added, they can be updated and removed as needed.
                      </div>

                      {/* <div className="text-red-400 font-bold text-sm  mb-8 mt-2">
                        TODO - THINK THROUGH HOW TO ORGANIZE... SHOULD BE SAME FOR ALL MODALS LIKE THIS...!
                      </div> */}


                      <div className=" h-[60vh] overflow-y-auto pr-4 section-scroll">

                        <div className="grid grid-cols-5 gap-4 pb-8">
                          {vizPackItems.map((item, index) => (
                            <div className="bg-white/5  hover:bg-white/10 ring-1 ring-white/10 cursor-pointer ring-inset rounded p-4 m-2 my-3"
                              key={'comptemp' + index} onClick={() => { importVizPack(item) }}
                            >
                              {item.preview ?
                                <img src={'/util/app/' + item.preview} className="w-3/4 mx-auto rounded" />
                                : null}
                              <p
                                className={classNames(
                                  'text-base text-gray-100'
                                )}

                              >
                                {item.name}
                              </p>
                              <p className={classNames('text-sm text-gray-400 mt-4')}>
                                {item.description}
                              </p>
                            </div>
                          ))}
                        </div>


                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  }






  //const [bgHeight, setBgHeight] = useState(storyData.backgroundHeight || 0);


  // const getCameraDetails = () => {
  //   const { camera } = useThree()
  //   console.log('Camera Position:', camera.position);
  //   console.log('Camera Rotation:', camera.rotation);
  // };


  //hotkeys('ctrl+z, command+z', function() {

  //hotkeys('command+z', function() {

  const updateSceneMatcap = (blankdata, mat) => {
    updateScene('platform',
      {
        model: sceneData[sessionData.activeIndex].platform.model,
        matcap: mat,
        text: {
          color: sceneData[sessionData.activeIndex].platform.text.color,
          scale: sceneData[sessionData.activeIndex].platform.text.scale,
        }
      }
    )
  }


  return (
    <>

      <div
        id="panel-story-control"
        className={`admin-bg-side p-3 transition ease-in-out delay-500 ${sessionData.playModeActive == 1 ? '-translate-y-full' : 'translate-y-0'}`}
      >
        <div className="flex">
          <div className="flex-none w-14">
            <Link to={"/app/stories"} className="button-back-to-admin opacity-50 hover:opacity-100 h-8 w-8">
              <img src="/media/icon/noun-left-arrow-1035682-FFFFFF.svg" className="ml-2 h18 admin-button-main-back"></img>
            </Link>
          </div>

          <div className="flex-auto mt-1 ">
          </div>

          <div className="admin-editor-story-name">
            <div className="text-gray-200 text-center">
              <div className="text-base inline">{storyData.name}</div>
            </div>
          </div>

          <div className="flex-none mr-4">

            <div className={`${enabledMode ? 'text-gray-500' : 'text-gray-200'
              } inline transition-all text-sm mr-4`}>story</div>
            <Switch checked={enabledMode} onChange={() => {
              setEnabledMode(!enabledMode)
            }
            } as={Fragment}>
              {({ checked }) => (
                <button
                  className={`${checked ? 'bg-black' : 'bg-black'
                    } relative inline-flex h-6  w-11 items-center rounded-full navigation-top-toggle`}
                >
                  <span className="sr-only">Switch Edit Mode</span>
                  <span
                    className={`${checked ? 'translate-x-6 bg-sky-500' : 'translate-x-1 bg-sky-500'
                      } inline-block h-4 w-4 transform rounded-full   `}
                  />
                </button>
              )}
            </Switch>
            <div className={`${enabledMode ? 'text-gray-200' : 'text-gray-500'
              } inline transition-all ml-4 text-sm`}>theme</div>

            <div className="relative inline-block ml-6  w-30 text-left inline-flex   gap-x-1.5 rounded    pl-3  text-base font-normal text-gray-200 text-sm  ">
              Present

              <div className="ml-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <span hover-tooltip="Play Story with Webcam" tooltip-position="bottom">
                      <button className="admin-component   rounded-sm" onClick={
                        ToggleSideMode
                      }>
                        <img src="/media/icon/Self-Paced.svg" className="h-4 mt-1" />
                      </button>
                    </span>
                  </div>
                  <div>
                    <span hover-tooltip="Play Story" tooltip-position="bottom">
                      <button className="admin-component rounded-sm"
                        onClickCapture={() => {
                          setPresentMode(true)
                          setSessionData(prevState => ({
                            ...prevState,
                            playModeActive: 1
                          }))
                        }}
                      >
                        <img src="/util/app/b3-admin-play.svg" className="h-4 mt-1 opacity-70 hover:opacity-100" />
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div className="ml-8 ">
                <CompHelpGuide />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='scene-edit-overlay left' id="panel-wizard">
        <div>
          <h2>
            <img src="/media/icon/noun-left-arrow-1035682-FFFFFF.svg" className="h18 admin-button-main-back" onClick={ToggleWizard}></img>
          </h2>
        </div>

        <h3 className="mt-4 mb-2">Scene Wizard</h3>

        <form onSubmit={createScene} className="mb-4">
          <input
            value={input}
            onChange={(e) => {
              setInput(e.target.value)
            }}
            //onClick={() => setToggleComponentBlockMode(true)}
            className='block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 
          text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 
          focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6'
            type='text'
            id="scene-name-input"
            placeholder='Add Scene'
          />
          <button className='admin-buttom' onClick={createScene}>
            +
          </button>
        </form>


        <div className="section-scroll h-full-480v">
          <div className=" gap-2 grid grid-flow-row-dense grid-cols-1">
            {componentBlockList.map((comp, index) => (
              <div
                className={`component-card  auto-cols-auto h-28`}
                //onClick={() => updateStory('backgroundModel', ground.model)} 
                key={comp.guid}
              >

                <div className="flex">
                  <div className="flex-none w-24">
                    <img src={'/media/icon/' + comp.icon + '.svg'} className="object-cover o5" />
                  </div>
                  <div className="flex-none w-4">
                  </div>
                  <div className="grow">
                    <h3>{comp.name}</h3>
                    <p>{comp.description}</p>
                  </div>
                </div>

              </div>
            ))}
          </div>
        </div>

      </div> */}




      {/* WRAPPER LEFT ADMIN COMPONENTS */}
      <div
        className={`scene-edit-overlay-wrapper left transition-all duration-500 ease-in-out ${sessionData.playModeActive == 1 ? '-translate-x-full' : 'translate-x-0 delay-1000'}`}
      >
        <div
          // className='scene-edit-overlay left' 
          className={`scene-edit-overlay left transition-all duration-500 ease-in-out ${enabledMode ? '-translate-x-full' : 'translate-x-0'}`}
          id="panel-scenes">

          <div>
            <div className="mode-action-bar mb-4">
            </div>

            <h3 className="mt-2 ml-3">
              Scenes {presentMode}
            </h3>

            {/* <button onClick={grabQuickScreenshot()} className="bg-black text-white">Screen</button> */}

          </div>


          <div className="admin-section scene-add">
            <form onSubmit={createScene}>
              <input
                value={input}
                onChange={(e) => {
                  setInput(e.target.value)
                }}
                //onClick={() => setToggleComponentBlockMode(true)}
                className='block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 
          text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 
          focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 focus:outline-none'
                type='text'
                placeholder='Add Scene'
              />
              <button className='admin-buttom' onClick={createScene}>
                +
              </button>
            </form>
          </div>



          <OpenLayoutSelectModal />



          <div className="admin-section display-none  animate__animated animate__fadeIn" id="component-blocks">



            <div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                onClick={() => setOpen(false)}
              >
                Save
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                onClick={() => setToggleComponentBlockMode(false)}
              //ref={cancelButtonRef}
              >
                Cancel
              </button>
            </div>



          </div>


          <div className="scene-detail mt2  mb-2 ml-1">
            {sceneData.length < 1 ? null : (
              <div className="mt-2 ml-2 text-sm inline">
                {`${sceneData.length} scene` + (sceneData.length > 1 ? 's' : '')}
              </div>
            )}

            {betaReleaseActive == 1 &&
              <>
                {/* <CompLayoutPreview scenes={sceneData} storyData={storyData} storyId={storyId} db={db} /> */}
                {/* <CompLayoutEditor scenes={sceneData} storyData={storyData} storyId={storyId} db={db} /> */}
              </>
            }

            {/* <SceneLayoutEditor  sceneData={sceneData}   initialScenes={sceneData} storyData={storyData} storyId={storyId} db={db} /> */}
            {/* </div>

            <div className="h-24 scene-detail mt-2 ml-2"> */}
            {/* <CompAISummary scenes={sceneData} /> */}
            {/* {betaReleaseActive == 1 &&
              <CompAIStoryGen storyId={storyId} sceneData={sceneData} />
            } */}
            {/* <CompAIIngest/> */}

          </div>

          <div className="section-scroll h-full-420 pb-8" id="story-scenes">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters2">
                {(provided) => (
                  <ul className="characters2 hover:cursor-grab mt-2" {...provided.droppableProps} ref={provided.innerRef}>
                    {sceneData.map((scene, index) => (
                      <Draggable key={scene.id + 'scenelistitem'} draggableId={scene.id + 'scenelistitem'} index={index}>
                        {(provided) => (
                          <li className="characters-thumb-left " ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <div
                              className={`mb-half-1 animate__animated animate__fadeIn   rounded
                              ${scene.navOmitMain == true ? ' ' : ' bg-zinc-800/40 hover:bg-zinc-800'}`
                              }>
                              <div className="flex mt-2 p-2 rounded">
                                <div className="flex-none w-4 mr-2">
                                  <img src="/util/app/b3-grab-2.svg" className="h18 o4 mt-2 " />
                                </div>
                                <div className="flex-auto mt-2 text-sm truncate">
                                  {index + 1}. {scene.name}
                                </div>
                                <div className="flex-none w-10 mr-1">
                                  <button key={scene.id + 'scene-item-edit'} onClick={() => {
                                    setSceneDetailUpdate(scene)
                                    setOpenSceneEdit(true)
                                  }}
                                    className="p-2.5 rounded hover:bg-white/10"
                                  >
                                    <img
                                      src="/media/icon/noun-edit-1918366-FFFFFF.svg"
                                      className="opacity-30"
                                    />
                                  </button>
                                </div>
                                <div className="flex-none w-10">
                                  <button onClick={() => {
                                    props.updateSceneFunction(index);
                                    //loadActiceComponents(index);
                                  }}
                                    className={`p-2 float-right rounded ${sessionData.activeIndex == index ? '  bg-sky-500 ' : ' hover:bg-white/10'}`} >

                                    {scene.navOmitMain == true ?
                                      <img src="/util/app/b3-admin-background-scene.svg" className="  float-right" />
                                      :
                                      <img src="/media/icon/noun-play-1154292-FFFFFF.svg" className="  float-right" />
                                    }

                                  </button>
                                </div>
                              </div>

                            </div>

                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <SceneDetailEdit
            scene={sceneDetailUpdate}
            openSceneEdit={openSceneEdit}
            setOpenSceneEdit={setOpenSceneEdit}
            key={sceneDetailUpdate.id + 'scene-detail-edit'}
          />

        </div>
        <div className='scene-edit-overlay left panel-design' id="panel-design-left">

          {/* <h2>
            Branding &amp; Background
          </h2> */}

          <div className="section">
            <h3 className="ml-2 mt-2 mb-2">
              Background
            </h3>
          </div>





          {/* <div className="mt-8 ml-3 text-sm text-gray-50">
            Picture in Picture Background
          </div>

          <div className="mt-2 ml-1">
            <MediaUploaderSolo itemName="Background Here" storyId={storyId} dataAttribute="imageStoryBackground" storyData={storyData} />
          </div> */}

          <div className="mt-14 ml-3 text-sm text-gray-50">
            Sky Options
          </div>
          <SkyOGOptions />
          <SkyCloudOptions />

          <div className="mt-10 ml-3 text-sm text-gray-50">
            Ground Options
          </div>


          {/* <MaterialControl /> */}


          <div className="grid grid-cols-4 gap-2 mt-2">
            <div className="text-gray-300 text-sm mt-2 ml-3">
              Height
            </div>
            <div className="text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold  rounded text-center font-semibold cursor-pointer py-1"
              onClick={() => { updateStory('backgroundHeight', storyData.backgroundHeight - 1) }}
            >
              -
            </div>
            <div className="text-gray-200 text-center mt-1">
              {storyData.backgroundHeight}
            </div>
            <div className="text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold  rounded text-center font-semibold cursor-pointer"
              onClick={() => { updateStory('backgroundHeight', storyData.backgroundHeight + 1) }}

            >
              +
            </div>
          </div>

          <GroundColor />

          {/* <CompMatcapGen updateStory={updateStory} storyData={storyData} updateAttribute="backgroundMatcap" /> */}

          <GroundOptions />


          {/* <GroundOffset /> */}




          {/* <Slider
          // bgHeight, setBgHeight
            axis="y"
            ymin={100}
            ymax={1600}
            ystep={1}
            //xreverse={false}
            y={bgHeight}
            onChange={({ bgHeight }) => setBgHeight(bgHeight)}
            //onClick={() => updateStory('backgroundHeight', y)}
            styles={{
              active: {
                backgroundColor: '#333'
              },
              track: {
                backgroundColor: '#1b1e21'
              }
            }}
          /> */}

          {/* <h3 className="mt2 ml-1">
            Scene Layout Style
          </h3>
          <SceneLayout /> */}

          <div className="section">
            <h3 className="ml-2 mt-12 mb-2">
              Navigation
            </h3>
          </div>




          <div className="text-gray-100 text-sm ml-2 mb-8 mt-12">
            <div className="text-gray-100 text-sm  text-gray-100 font-base mb-2">
              Camera Control
            </div>
            <div className="text-gray-400 mb-4">
              The level of exploration that a user will be able to do within the 3d environment (move around or keep the view fixed)
            </div>
            <div className="flex rounded-md w-full p-1 bg-white/5 gap-1 ">
              <div
                className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
                      ${storyData.cameraOrbitOn == 1 ? 'bg-sky-600 text-gray-200 font-semibold' : ' text-gray-300 hover:bg-sky-600/50 '}`}
                onClick={() => {
                  updateStory('cameraOrbitOn', 1)
                  props.setOrbitActive(1)
                }}>
                <img src="/util/app/b3-admin-yaw.svg" className="h-8 mx-auto mb-2" />
                Rotate and Zoom
              </div>
              <div
                className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
                       ${storyData.cameraOrbitOn == 0 ? 'bg-sky-600 text-gray-200 font-semibold' : ' text-gray-300 hover:bg-sky-600/50 '}`}
                onClick={() => {
                  updateStory('cameraOrbitOn', 0)
                  props.setOrbitActive(0)
                }}>
                <img src="/util/app/b3-admin-lock.svg" className="h-8 mx-auto mb-2" />
                Locked
              </div>
            </div>
          </div>








          {/* <div className="mt-8 ml-3 text-gray-50"> */}
          <div className="text-sm  text-gray-50 font-base mt-10 ml-3">
            Navigation Style
          </div>
          {/* <div className="text-gray-200">Full Screen</div> */}
          <NavigationStyle />

          {/* <div className="text-gray-200">Picture in Picture</div>
          <NavigationStyle /> */}

          {/* <div className="section mt-6">
            <h2>Camera Defaults</h2>
            <div className="section-scroll h200 gap-2 grid grid-flow-row-dense grid-cols-1">
              {cameraOffsetDefaults.map((camItem, index) => (
                <div
                  className={`component-card model-select auto-cols-auto row-height-contents `}
                  onClick={() => updateStory('cameraOffsetDefaults', camItem)} key={camItem.name} >
                  {camItem.name}
                </div>
              ))}
            </div>
          </div> */}


        </div>
      </div>



      {/* WRAPPER RIGHT ADMIN COMPONENTS */}
      <div
        className={`scene-edit-overlay right transition-all duration-500 ease-in-out ${sessionData.playModeActive == 1 ? 'translate-x-full' : 'translate-x-0 delay-1000'}`}
      >

        <div className='scene-edit-overlay right design' id="panel-design-right" >


          <div className="section">
            <h3 className="ml-2 mt-2 mb-2">
              Branding
            </h3>
          </div>


          {betaReleaseActive == 1 &&
            <div className="section pb-4">
              <BrandGenerator />
            </div>
          }


          <div className="mt-14 ml-3 text-sm text-gray-50 mb-2">
            Company Logo
          </div>

          <div className="mt-2 ml-1">
            <MediaUploaderSolo itemName="Logo Here" storyId={storyId} dataAttribute="imageStoryLogo" storyData={storyData} />
          </div>

          {/* <CompGetColors /> */}

          <div className="w-full  max-w-xl mx-auto mt-8">
            <div className="  mb-8">
              <div className="flex text-center text-sm pr-1">
                <button
                  className={`py-2 w-1/3   border-b-2  text-center focus:outline-none tab-button ${activeTabStyleDesign == 'tab1' ? ' text-white border-gray-200 ' : 'border-gray-600 text-gray-400'} `}
                  onClick={() => {
                    setActiveTabStyleDesign('tab1')
                  }}>
                  Navigation
                </button>
                <button
                  className={`py-2  w-1/3 border-b-2 border-gray-400 text-center focus:outline-none tab-button ${activeTabStyleDesign == 'tab2' ? ' text-white border-gray-50 ' : 'border-gray-600 text-gray-400'} `}
                  onClick={() => {
                    setActiveTabStyleDesign('tab2')
                  }}>
                  Card
                </button>
                <button
                  className={`py-2  w-1/3 border-b-2 border-gray-400 text-center focus:outline-none tab-button ${activeTabStyleDesign == 'tab3' ? ' text-white border-gray-50 ' : 'border-gray-600 text-gray-400'} `}
                  onClick={() => {
                    setActiveTabStyleDesign('tab3')
                  }}>
                  Themes
                </button>
              </div>
            </div>


            <div className=" ">
              <div id="tab1" className={`tab-content text-gray-200 cursor-pointer ${activeTabStyleDesign != 'tab1' ? ' hidden' : ''} `}>




                {/* <CompPreviewNavigationStyle storyData={storyData} /> */}
                <div className="p-4 w-full -mt-2">
                  <div style={{ backgroundColor: `${storyData.uiStyle['nav-region-bg']} ` }} className='flex w-full rounded-md p-2 mb-8'>
                    <div className="flex-grow">
                      <ul className=" flex p-2 mx-auto">
                        <li role="button" className={"text-sm p-3 px-5 border-b-4 rounded mr-2 font-semibold px-3"}
                          style={{
                            borderColor: storyData.uiStyle['nav-progress-meter-bg'],
                            backgroundColor: storyData.uiStyle['nav-button-bg-on'],
                            color: storyData.uiStyle['nav-button-text-on']
                          }} >
                          Selected Scene
                        </li>
                        <li role="button" className={"text-sm p-3 px-5 border-b-4 rounded mr-2 font-semibold px-3"}
                          style={{
                            borderColor: storyData.uiStyle['nav-progress-meter'],
                            backgroundColor: storyData.uiStyle['nav-button-bg-off'],
                            color: storyData.uiStyle['nav-button-text-off']
                          }} >
                          Not Selected
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <Accordion allowZeroExpanded preExpanded={preExpandedDesignGuid} >
                  <CompColorPicker
                    title="Region Background"
                    styleName="nav-region-bg"
                  />
                  <CompColorPicker
                    title="Button Text - Off"
                    styleName="nav-button-text-off"
                  />
                  <CompColorPicker
                    title="Button Text - On"
                    styleName="nav-button-text-on"
                  />
                  <CompColorPicker
                    title="Button Background - Off"
                    styleName="nav-button-bg-off"
                  />
                  <CompColorPicker
                    title="Button Background - On"
                    styleName="nav-button-bg-on"
                  />
                  <CompColorPicker
                    title="Progress Meter"
                    styleName="nav-progress-meter"
                  />
                  <CompColorPicker
                    title="Progress Meter Background"
                    styleName="nav-progress-meter-bg"
                  />
                </Accordion>

              </div>
              <div id="tab2" className={`tab-content text-gray-200 cursor-pointer ${activeTabStyleDesign != 'tab2' ? ' hidden' : ''} `}>


                <div className="section-scroll h-full-400">
                  <div
                    // style={{ animationDelay: vizItem.animationDuration + 's' }}
                    className={`border-t-4 rounded pt-4  mt-10 mx-auto w-4/5 mb-10 card-preview  org-${storyData.name.replace(/\s+/g, '-').toLowerCase() || 'none'}`}
                    style={{

                      // storyData.uiStyle['image-persona-bg']
                      // background: storyData.uiStyle['image-persona-bg']
                      //   ? `url(${storyData.uiStyle['image-persona-bg']}) center/cover no-repeat`
                      //   : storyData.uiStyle['super-main-bg-color'],

                      background: storyData.uiStyle['super-main-bg-color'],


                      color: storyData.uiStyle['super-main-title-color'],
                      borderColor: storyData.uiStyle['super-highlight-color'],
                    }}>
                    <div
                      className="text-left px-4 font-semibold text-xl"
                      style={{
                        color: storyData.uiStyle['super-main-title-color']
                      }}
                    >
                      {/* <img src={vizItem.cardImage} className="w-1/2 mx-auto mb-10" /> */}
                      Sample Card Title
                    </div>
                    <div
                      className="text-left px-4 pb-4"
                      style={{
                        color: storyData.uiStyle['super-subtitle-color']
                      }}
                    >
                      Card Subtitle Goes Here
                    </div>
                    <div
                      className="relative inline-flex text-left text-sm px-4 pb-8"
                      style={{
                        color: storyData.uiStyle['super-body-color']
                      }}
                    >
                      Body of the card where you would place additional detail in long form content.  This is a sample of the body text.
                    </div>
                  </div>



                  <Accordion allowZeroExpanded>
                    <CompColorPicker
                      title="Text Color - Main Title"
                      styleName="super-main-title-color"
                    />
                    <CompColorPicker
                      title="Text Color - Subtitle"
                      styleName="super-subtitle-color"
                    />
                    <CompColorPicker
                      title="Text Color - Body"
                      styleName="super-body-color"
                    />
                    <CompColorPicker
                      title="Background - Main"
                      styleName="super-main-bg-color"
                    />
                    <CompColorPicker
                      title="Background - Secondary"
                      styleName="super-secondary-bg-color"
                    />
                    <CompColorPicker
                      title="Highlight Color"
                      styleName="super-highlight-color"
                    />
                    <CompColorPicker
                      title="List Icon Color"
                      styleName="super-list-icon-color"
                    />
                    {/* <CompColorPicker
                  title="List Text Color"
                  styleName="super-list-text-color"
                /> */}
                  </Accordion>



                  <div className="mt-8 ml-3 inline-flex  text-sm text-gray-50 mb-2">
                    Custom Backgrounds
                  </div>


                  <div className=" inline-flex   rounded-md  text-zinc-200 text-sm  p-1 pb-8"
                  // style={{ backgroundColor: '#1c1e21' }}
                  >
                    {/* Card Background Image */}

                    <MediaUploaderSolo
                      itemName="Persona Image"
                      storyId={storyId}
                      dataAttribute="image-persona-bg"
                      storyData={storyData}
                      isTheme={true}
                    />

                    {/* <MediaUpload
                    itemName="Card Background Image"
                    storyId={storyId}
                    dataAttribute="image-persona-bg"
                    storyData={storyData}
                  /> */}

                    {/* {storyData.uiStyle['image-persona-bg'] || ''} */}

                    {/* {storyData.uiStyle['image-persona-bg'] ?
                    <img src={storyData.uiStyle['image-persona-bg']} className='w-1/3 border-2 border-black/20 rounded-sm' />
                    : null
                  } */}
                  </div>
                </div>

                {/* {window.electron &&
                  <div className="bg-sk-600 p-2 rounded-sm ml-4"
                    onClick={() => {
                      if (window.electron) {
                        window.electron.openModal('https://fa-ewki-saasfademo1.ds-fa.oraclepdemos.com/fscmUI/redwood/rwdref');
                      }
                    }}
                  >
                    Window Test
                  </div>
                } */}



              </div>
              <div id="tab3" className={`tab-content text-gray-200 cursor-pointer ${activeTabStyleDesign != 'tab3' ? ' hidden' : ''} `}>




                {/* {themelListResponse.map((item, index) => (
                  <div
                    className="bg-white/5  hover:bg-white/10 ring-1 ring-white/10 cursor-pointer ring-inset rounded p-4 m-2 my-3"
                    key={'themeResp' + index}
                  >
                    <p className={classNames('text-sm font-medium text-gray-300')}>
                      <img src={'/media/custmark/' + item.name + '.svg'} className="h-8 inline ml-1 mr-3 " />
                      {item.name}
                    </p>
                  </div>
                ))} */}

                <div className="section-scroll h-full-400">
                  {themeList.map((item, index) => (
                    <div
                      className="bg-white/5  hover:bg-white/10 ring-1 ring-white/10 cursor-pointer ring-inset rounded p-4 m-2 my-3"
                      key={'comptemp' + index}
                      onClick={() => { LoadTheme(item.classes) }}
                    >
                      <p className={classNames('text-sm font-medium text-gray-300')}>
                        {/* <img src={"/util/app/" + item.icon + ".svg"} className="h-8 inline ml-1 mr-3 " /> */}
                        {item.name}
                      </p>


                      <div
                        // style={{ animationDelay: vizItem.animationDuration + 's' }}
                        className={`border-t-4 rounded pt-4  mt-10 mx-auto w-4/5 mb-10 card-preview  org-${storyData.name.replace(/\s+/g, '-').toLowerCase() || 'none'}`}
                        style={{
                          background: item.classes['super-main-bg-color'],
                          color: item.classes['super-main-title-color'],
                          borderColor: item.classes['super-highlight-color'],
                        }}>
                        <div
                          className="text-left px-4 font-semibold text-xl"
                          style={{
                            color: item.classes['super-main-title-color']
                          }}
                        >
                          {/* <img src={vizItem.cardImage} className="w-1/2 mx-auto mb-10" /> */}
                          Sample Card Title
                        </div>
                        <div
                          className="text-left px-4 pb-4"
                          style={{
                            color: item.classes['super-subtitle-color']
                          }}
                        >
                          Card Subtitle Goes Here
                        </div>
                        <div
                          className="relative inline-flex text-left text-sm px-4 pb-8"
                          style={{
                            color: item.classes['super-body-color']
                          }}
                        >
                          Body of the card where you would place additional detail.
                        </div>
                      </div>

                      <div className=" w-full -mt-2">
                        <div style={{ backgroundColor: `${item.classes['nav-region-bg']} ` }} className='flex w-full rounded-md p-2 mb-8'>
                          <div className="flex-grow">
                            <ul className=" flex p-2 mx-auto">
                              <li role="button" className={"text-sm p-3 px-5 border-b-4 rounded mr-2 font-semibold px-3"}
                                style={{
                                  borderColor: item.classes['nav-progress-meter-bg'],
                                  backgroundColor: item.classes['nav-button-bg-on'],
                                  color: item.classes['nav-button-text-on']
                                }} >
                                Selected Scene
                              </li>
                              <li role="button" className={"text-sm p-3 px-5 border-b-4 rounded mr-2 font-semibold px-3"}
                                style={{
                                  borderColor: item.classes['nav-progress-meter'],
                                  backgroundColor: item.classes['nav-button-bg-off'],
                                  color: item.classes['nav-button-text-off']
                                }} >
                                Not Selected
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </div>
                  ))}
                </div>

                {/* <form onSubmit={console.log('hit!')} className="mb-4">
                  <input
                    value={input}
                    onChange={(e) => {
                      setInput(e.target.value)
                    }}
                    className='block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 
          text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 
          focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6'
                    type='text'
                    id="template-scene-input"
                    placeholder='Theme Name'
                  />
                  <button className='admin-buttom' onClick={createTemplate}>
                    +
                  </button>
                </form> */}


              </div>
            </div>
          </div>

        </div >


        <div
          className={`scene-edit-overlay right transition-all duration-500 ease-in-out   ${enabledMode ? 'translate-x-full' : 'translate-x-0'}`}
          id="panel-detail"
        >
          {/* <div className="section">
            <div className="ml-2 mt-2 mb-2 text-zinc-400 text">Scene Detail</div>            
          </div> */}

          {/* <div className="section-scroll h200 gap-2 grid grid-flow-row-dense grid-cols-1">
              {platformOptions.map((platformItem, index) => (
                <div
                  className={`component-card model-select auto-cols-auto row-height-contents 
                  ${sceneData[sessionData.activeIndex].platform ? platformItem.model == sceneData[sessionData.activeIndex].platform.model ? 'active' : '' : null}
                  `}
                  onClick={() => updateScene('platform',
                    {
                      model: platformItem.model,
                      matcap: sceneData[sessionData.activeIndex].platform.matcap,
                      text: {
                        color: sceneData[sessionData.activeIndex].platform.text.color,
                        scale: sceneData[sessionData.activeIndex].platform.text.scale,
                      }
                    }
                  )}
                  key={platformItem.id + 'key'} >
                  {platformItem.name}
                </div>
              ))}
            </div> */}





          {sceneData[sessionData.activeIndex] !== undefined ?
            <>



              {/* <div className="section">
              <div className="ml-2 mt-4 mb-2 text-gray-300 text-sm">Scene Templates</div>
            </div> */}
              <div className="section  ">
                <div className="flex text-center text-sm pr-1">
                  <button
                    className={`py-2 w-1/3   border-b-2  text-center focus:outline-none tab-button ${activeTabStoryDesign == 'tab1' ? ' text-white border-gray-200 ' : 'border-zinc-800 text-gray-400'} `}
                    onClick={() => {
                      //showStyleTab('tab1') 
                      setActiveTabStoryDesign('tab1')
                    }}>
                    Components
                  </button>
                  <button
                    className={`py-2  w-1/3 border-b-2 border-gray-400 text-center focus:outline-none tab-button ${activeTabStoryDesign == 'tab2' ? ' text-white border-gray-50 ' : 'border-zinc-800 text-gray-400'} `}
                    onClick={() => {
                      //showStyleTab('tab1') 
                      setActiveTabStoryDesign('tab2')
                    }}>
                    Platform
                  </button>
                  <button
                    className={`py-2  w-1/3 border-b-2 border-gray-400 text-center focus:outline-none tab-button ${activeTabStoryDesign == 'tab3' ? ' text-white border-gray-50 ' : 'border-zinc-800 text-gray-400'} `}
                    onClick={() => {
                      //showStyleTab('tab1') 
                      setActiveTabStoryDesign('tab3')
                    }}>
                    {/* Templates */}
                    Viewpoint
                  </button>

                </div>
              </div>

              <div className=" ">
                <div id="tab1Story" className={`tab-content text-gray-200 ${activeTabStoryDesign != 'tab1' ? ' hidden' : ''} `}>

                  {/* {sceneData[sessionData.activeIndex] ?
                  <div className="section">
                    <div className="ml-2 mt-2 mb-2 text-gray-300 text-sm">Add Component</div>
                  </div>
                  : (null)
                } */}

                  <div className="float-left w-full h-24 grid  gap-1 grid-cols-5 mt-4">
                    {components.map((component, index) => (
                      <span hover-tooltip={component.name} tooltip-position="bottom" key={component.guid}>
                        <div className="component-card  auto-cols-auto" onClick={() => addComponent(component)} >
                          <img src={"/media/icon/" + component.icon + ".svg"} className="h20 mx-auto pointer-events-none" />
                        </div>
                      </span>
                    ))}
                  </div>

                  <div className="section">
                    {/* <div className="flex">
                      {sceneData.length > 0 ?
                        <button type="button" className="text-gray-200 mr-1 border border-white/10 rounded text-sm mt-1.5 px-5 w-full text-center py-2   items-center bg-gray-400/5 hover:bg-white/10" onClick={() => { setOpenLayout(true) }}>
                          <img src="/util/app/b3-genai.svg" className='h-6 w-6 inline mr-2' />
                          Select Template Preset
                        </button>
                        : null}
                    </div> */}

                    <ComponentTemplates />

                  </div>





                  <div className="section">
                    <div className="flex">
                      <div className="flex-auto ml-2 mt-6 mb-2 text-gray-300 text-sm">Active Components</div>
                      <div className="flex-initial w-20 mt-3">
                        <ActiveComponentHistory />
                      </div>
                    </div>
                  </div>

                  {/* <div className="section-scroll h-full-300 mt1"> */}
                  <div className="section-scroll h-full-400-20 mt1 pb-4">
                    {sceneData[sessionData.activeIndex].viznodes.length < 1 ?
                      <div className="info-blank font-normal">
                        <div>
                          <img src="/media/icon/noun-information-5783675-00AAFF.svg" className="h24 m-lr-auto mt-4 mb-4" />
                          Add item from list above.  Once added, click on the item to customize for your needs.
                        </div>
                      </div>
                      : (null)}

                    <Accordion allowZeroExpanded>

                      {sceneData[sessionData.activeIndex].viznodes.map((item, index) => (

                        <AccordionItem key={item.guid + sessionData.activeIndex} id={item.guid} className="animate__animated animate__fadeIn">
                          <AccordionItemHeading>
                            <AccordionItemButton data-itemguid={item.guid}>
                              <img src={"/media/icon/" + item.icon + ".svg"} className="h18 inline ml-1 mr-3 " />

                              <span className="xtext-zinc-400 xtext-sm">
                                {item.id == 1 ?
                                  <>
                                    {item.media[0] ?
                                      <>
                                        {item.media[0].personaName ? item.media[0].personaName : 'Persona'}
                                      </>
                                      : 'Persona'}
                                  </>
                                  :
                                  <>
                                    {item.id == 0 ?
                                      <>
                                        {item.cardTitle ? item.cardTitle : 'Super Card'}
                                      </>
                                      :
                                      <>
                                        {item.name}
                                      </>
                                    }
                                  </>
                                }
                              </span>
                              <
                                div className="admin-mini-buttom right" onClick={() => removeActiveComponent(item)}>
                                <img src="/media/icon/noun-trash-5765342-FFFFFF.svg" className="h18 o5" />
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>

                          <AccordionItemPanel key={item.guid} id={item.guid} data-itemguid={item.guid}>
                            {item.id == 0 ?
                              <div>
                                <ConfigSuperCard
                                  storyId={storyId}
                                  sceneData={sceneData}
                                  sessionData={sessionData}
                                  cardItem={item}
                                  //updateSceneDataNew={props.updateSceneData}
                                  key={item.guid}
                                />
                              </div>
                              : (null)}
                            {item.id == 1 ?
                              <div>
                                {/* <PersonGrid sceneData={sceneData} /> */}
                                <MediaUploader
                                  uploadMode={1}
                                  selectSceneComponent={selectSceneComponent}
                                  configureActiveComponent={configureActiveComponent}
                                  storyId={storyId}
                                  sceneData={sceneData}
                                  sessionData={sessionData}
                                  activeIndex={index}
                                  personaItem={item}
                                  key={item.guid}
                                />
                                {/* <GridSelect
                                  //viznodes
                                  activeNode={item.guid}
                                  viznodes={sceneData[sessionData.activeIndex].viznodes}
                                  //setCardPositionXY={setPersonaPositionXY}
                                /> */}
                              </div>
                              : (null)}
                            {item.id == 2 ?
                              <>
                                <MediaUploader
                                  uploadMode={2}
                                  activeComponentData={item}
                                  updateActiveComponentData={updateActiveComponentData}
                                  selectSceneComponent={selectSceneComponent}
                                  storyId={storyId}
                                  sceneData={sceneData}
                                  sessionData={sessionData}
                                  personaItem={item}
                                  key={item.guid}
                                />
                              </>
                              : (null)}
                            {item.id == 3 ?
                              <div key={item.guid}>
                                <div className="grid grid-cols-1 gap-2">
                                  <div className="mt-4 w-full">
                                    <div className="w-full">




                                      <div className="w-full">
                                        <div className="w-full mb-8">
                                          <div className="flex text-center text-sm">
                                            <button
                                              className={`py-2 w-1/2 border-b-2 text-center focus:outline-none ${activeModelTab === 'library'
                                                ? 'text-white border-gray-200'
                                                : 'border-gray-600 text-gray-400'
                                                }`}
                                              onClick={() => setActiveModelTab('library')}>
                                              Library
                                            </button>
                                            <button
                                              className={`py-2 w-1/2 border-b-2 text-center focus:outline-none ${activeModelTab === 'ai'
                                                ? 'text-white border-gray-200'
                                                : 'border-gray-600 text-gray-400'
                                                }`}
                                              onClick={() => setActiveModelTab('ai')}>
                                              <img className="h-4 w-4 text-gray-400 inline-block mr-1" src="/util/app/b3-genai-v2.svg" /> Generate
                                            </button>
                                          </div>

                                          <div className="mt-4">
                                            {activeModelTab === 'library' ? (
                                              <CompModelBrowser
                                                linkText="Select from Library"
                                                headingText="Select Model"
                                                selectSceneComponent={selectSceneComponent}
                                                itemIndex={index}
                                              />
                                            ) : (
                                              <div className="pr-1">
                                                <CompModelAI
                                                  selectSceneComponent={selectSceneComponent}
                                                  index={index}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>



                                    </div>
                                    <div className="w-3/4 mx-auto">

                                      {item.model.includes('storage.googleapis.com') ?
                                        <>
                                          <img src={item.modelThumbnail} className="model-previssew " />
                                          {/* {item.modelThumbnail} */}
                                        </>
                                        :
                                        <>
                                          <img src={'/media/thumbnail/' + item.model.replace('glb', 'png')} className="model-previssew " />
                                          {/* {item.name} */}
                                        </>
                                      }

                                    </div>
                                    <div className="w-full px-4">
                                      <div className="grid grid-cols-4 gap-2 mt-4">
                                        <div className="text-gray-300 text-sm mt-1">
                                          Size
                                        </div>
                                        {/* <div className="text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold rounded text-center h-8 cursor-pointer"
                                          onClick={() => {
                                            if (!item.scale) { item.scale = 1 }
                                            item.scale > 0.2 ? modelAdjust(item, 0, 0, 0, -0.1) : console.log('less than .1...')
                                          }}
                                        >
                                          -
                                        </div> */}

                                        <div className="grid grid-cols-2 gap-1 w-full">
                                          <div className="   text-gray-200 bg-white/5 hover:bg-white/10 text-2xl font-bold  rounded text-center font-base cursor-pointer"
                                            onClick={() => { modelAdjust(item, 0, 0, 0, -1) }}
                                          >
                                            -
                                          </div>
                                          <div className="text-gray-200 bg-white/5 hover:bg-white/10 text-xs font-bold  rounded text-center font-base cursor-pointer"
                                            onClick={() => { modelAdjust(item, 0, 0, 0, -0.1) }}
                                          >
                                            <div className="mt-2">-</div>
                                          </div>
                                        </div>

                                        <div className="text-gray-200 text-center">
                                          {Number(`${Math.round(`${item.scale || 1}e2`)}e-2`)}
                                        </div>
                                        {/* <div className="text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold rounded text-center cursor-pointer"
                                          onClick={() => { modelAdjust(item, 0, 0, 0, 0.1) }}
                                        >
                                          +
                                        </div> */}
                                        <div className="grid grid-cols-2 gap-1 w-full">
                                          <div className="   text-gray-200 bg-white/5 hover:bg-white/10 text-xs font-bold  rounded text-center font-base cursor-pointer"
                                            onClick={() => { modelAdjust(item, 0, 0, 0, 0.1) }}

                                          >
                                            <div className="mt-2">+</div>
                                          </div>
                                          <div className=" text-gray-200 bg-white/5 hover:bg-white/10 text-2xl font-bold  rounded text-center font-bold cursor-pointer"
                                            onClick={() => { modelAdjust(item, 0, 0, 0, 1) }}

                                          >
                                            <div className="-mt-0.5">+</div>
                                          </div>
                                        </div>
                                      </div>



                                      <div className="grid grid-cols-4 gap-2 mt-2">
                                        <div className="text-gray-300 text-sm mt-1">
                                          Height
                                        </div>

                                        <div className="grid grid-cols-2 gap-1 w-full">
                                          <div className="   text-gray-200 bg-white/5 hover:bg-white/10 text-2xl font-bold  rounded text-center font-base cursor-pointer"
                                            onClick={() => { modelAdjust(item, 0, -1, 0, 0) }}
                                          >
                                            -
                                          </div>
                                          <div className="text-gray-200 bg-white/5 hover:bg-white/10 text-xs font-bold  rounded text-center font-base cursor-pointer"
                                            onClick={() => { modelAdjust(item, 0, -.25, 0, 0) }}
                                          >
                                            <div className="mt-2">-</div>
                                          </div>
                                        </div>

                                        <div className="text-gray-200 text-center">
                                          {item.pos.y.toFixed(2)}
                                        </div>

                                        <div className="grid grid-cols-2 gap-1 w-full">
                                          <div className="   text-gray-200 bg-white/5 hover:bg-white/10 text-xs font-bold  rounded text-center font-base cursor-pointer"
                                            onClick={() => { modelAdjust(item, 0, .25, 0, 0) }}

                                          >
                                            <div className="mt-1.5">+</div>
                                          </div>
                                          <div className=" text-gray-200 bg-white/5 hover:bg-white/10 text-2xl font-bold  rounded text-center font-bold cursor-pointer"
                                            onClick={() => { modelAdjust(item, 0, 1, 0, 0) }}

                                          >
                                            <div className="-mt-0.5">+</div>
                                          </div>
                                        </div>
                                      </div>




                                      <div className="grid grid-cols-4 gap-2 mt-2">
                                        <div className="text-gray-300 text-sm mt-1">
                                          Position
                                        </div>
                                        <div className="text-gray-200 bg-white/5 hover:bg-white/10 rounded text-center h-8 cursor-pointer"
                                          onClick={() => { modelAdjust(item, 1, 0, 0, 0) }}
                                        >
                                          <img src="/media/icon/noun-left-arrow-1035682-FFFFFF.svg" className="h-3 mt-2 mx-auto" />
                                        </div>
                                        <div className="text-gray-200 text-center">
                                          {item.pos.x.toFixed(2)}
                                        </div>
                                        <div className="text-gray-200 bg-white/5 hover:bg-white/10 rounded text-center font-semibold cursor-pointer"
                                          onClick={() => { modelAdjust(item, -1, 0, 0, 0) }}
                                        >
                                          <img src="/media/icon/noun-right-arrow-1035676-FFFFFF.svg" className="h-3 mt-2 mx-auto" />
                                        </div>
                                      </div>



                                      <div className="grid grid-cols-4 gap-2 mt-2">
                                        <div className="text-gray-300 text-sm mt-1">
                                          Depth
                                        </div>
                                        <div className='  text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold  rounded text-center font-semibold cursor-pointer pb-2'
                                          onClick={() => { modelAdjust(item, 0, 0, 1, 0) }}
                                        >
                                          <img src="/media/icon/noun-up-arrow-1035685-FFFFFF.svg" className="h-3 mt-2 mx-auto" />
                                        </div>






                                        {/* <input
                                          className="remove-arrow bg-black/50 px-3 w-14  text-center block rounded-md border-0  py-2.5 bg-transparent text-gray-100  ring-1 ring-inset
                                          ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-sm sm:leading-6v focus:outline-none"
                                          type="number"
                                          min="0"
                                          pattern='^[0-9]*$'
                                          value={-1 * (item.pos.z || 0)}
                                        //onChange={(e) => setCardScale(Math.round(e.target.value * 100) / 100)}
                                        /> */}

                                        <div className="text-gray-200 text-center">
                                          {-1 * (item.pos.z || 0)}
                                        </div>

                                        <div className=' text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold  rounded text-center font-semibold cursor-pointer pb-2'
                                          onClick={() => { modelAdjust(item, 0, 0, -1, 0) }}
                                        >
                                          <img src="/media/icon/noun-down-arrow-1035684-FFFFFF.svg" className="h-3 mt-2 mx-auto" />
                                        </div>
                                      </div>

                                      <div className="w-full mt-2">
                                        <div className="text-gray-300 text-sm mt-1">
                                          Rotate
                                        </div>
                                      </div>

                                      <div className="grid grid-cols-4 gap-2 mt-2">
                                        <div className="p-2.5 text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold  rounded text-center font-semibold cursor-pointer"
                                          onClick={() => { modelAdjustRot(item, 0, -30, 0, 0) }}>
                                          <img src="/util/app/b3-admin-rotate-left.svg" className="h-2.5 mx-auto mt-1" />
                                        </div>
                                        <div className="p-2.5 text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold  rounded text-center font-semibold cursor-pointer"
                                          onClick={() => { modelAdjustRot(item, 0, 30, 0, 0) }}>
                                          <img src="/util/app/b3-admin-rotate-right.svg" className="h-2.5 mx-auto mt-1" />
                                        </div>
                                        <div className="p-2.5 text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold  rounded text-center font-semibold cursor-pointer"
                                          onClick={() => { modelAdjustRot(item, -5, 0, 0, 0) }}>
                                          <img src="/util/app/b3-admin-rotate-up.svg" className="h-4 mx-auto mt-0.5" />
                                        </div>
                                        <div className="p-2.5 text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold  rounded text-center font-semibold cursor-pointer"
                                          onClick={() => { modelAdjustRot(item, 5, 0, 0, 0) }}>
                                          <img src="/util/app/b3-admin-rotate-down.svg" className="h-4 mx-auto mt-0.5" />
                                        </div>
                                      </div>

                                    </div>




                                  </div>
                                </div>
                              </div>
                              : (null)}


                            {item.id == 4 ?
                              <div>
                                <MediaUploader
                                  uploadMode={1}
                                  selectSceneComponent={selectSceneComponent}
                                  configureActiveComponent={configureActiveComponent}
                                  storyId={storyId}
                                  sceneData={sceneData}
                                  sessionData={sessionData}
                                  activeIndex={index}
                                  personaItem={item}
                                  key={item.guid}
                                />
                              </div>
                              : (null)}

                            {item.id == 5 ?
                              <div key={item.guid}>
                                <ConfigLink contentItem={item} storyId={storyId}></ConfigLink>
                              </div>
                              : (null)}

                            {item.id == 6 ?
                              <div key={item.guid}>
                                <ConfigWebView webView={item} storyId={storyId}></ConfigWebView>
                              </div>
                              : (null)}

                            {item.id == 7 ?
                              <div key={item.guid}>
                                {/* <ConfigWebView webView={item}></ConfigWebView> */}
                                {/* <ReactQuill theme="snow" value={valueLongText} onChange={setValueLongText} /> */}
                                <ConfigFreeText
                                  storyId={storyId}
                                  sceneData={sceneData}
                                  sessionData={sessionData}
                                  activeIndex={index}
                                  personaItem={item}
                                  key={item.guid}
                                  contentItem={item}
                                />
                              </div>
                              : (null)}


                            {item.id == 8 ?
                              <div key={item.guid}>
                                {/* <ConfigWebView webView={item}></ConfigWebView> */}
                                {/* <ReactQuill theme="snow" value={valueLongText} onChange={setValueLongText} /> */}
                                <ConfigLongText contentItem={item}></ConfigLongText>
                              </div>
                              : (null)}


                            {item.id == 15 ?
                              <>
                                <div key={item.guid}>
                                  <MediaUploader
                                    uploadMode={1}
                                    selectSceneComponent={selectSceneComponent}
                                    configureActiveComponent={configureActiveComponent}
                                    storyId={storyId}
                                    sceneData={sceneData}
                                    sessionData={sessionData}
                                    activeIndex={index}
                                    personaItem={item}
                                    key={item.guid}
                                  />
                                </div>
                              </>
                              : (null)}

                            {item.id == 16 ?
                              <>
                                <div key={item.guid}>
                                  <ConfigSuperCard
                                    storyId={storyId}
                                    sceneData={sceneData}
                                    sessionData={sessionData}
                                    cardItem={item}
                                    updateSceneDataNew={props.updateSceneData}
                                    key={item.guid}
                                    mode={'icon'}
                                  />
                                </div>
                              </>
                              : (null)}


                            {item.id == 17 ?
                              <>
                                <ConfigMeshShape3D contentItem={item} storyId={storyId} />
                              </>
                              : (null)}


                          </AccordionItemPanel>
                        </AccordionItem>

                      ))}
                    </Accordion>
                  </div>
                </div>
                <div id="tab2Story" className={` section text-gray-200 ${activeTabStoryDesign != 'tab2' ? ' hidden' : ''} `}>
                  <div className="section mt-2 ">

                    <div className="text-gray-100 text-sm ml-2 mb-8 mt-2">
                      <div className="text-gray-100 text-sm  text-gray-100 font-semibold mb-2">
                        Location
                      </div>
                      <div className="text-gray-400 mb-4">
                        Set to manual to enable you to move the Platform to a specific location in your story
                      </div>
                      <div className="flex rounded-md w-full p-1 bg-white/5 gap-1 ">
                        <div
                          className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
                          ${sceneData[sessionData.activeIndex].platformPosition != 'manual' ? 'bg-sky-600 text-gray-200 font-semibold' : ' text-gray-300 hover:bg-sky-600/50 '}`}
                          onClick={() => {
                            updateScene('platformPosition', 'automatic')
                          }}>
                          Automatic
                        </div>
                        <div
                          className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
                       ${sceneData[sessionData.activeIndex].platformPosition == 'manual' ? 'bg-sky-600 text-gray-200 font-semibold' : ' text-gray-300 hover:bg-sky-600/50 '}`}
                          onClick={() => {
                            //updateScene('platformPosition', 'manual')
                            updatePlatformPosition(sceneData[sessionData.activeIndex])
                          }}>
                          Manual
                        </div>
                      </div>

                      {sceneData[sessionData.activeIndex].platformPosition === "manual" ?
                        <div className="pr-2 mt-8">
                          <div className="grid grid-cols-4 gap-2">
                            <div className="text-gray-300 mt-1">
                              position
                            </div>
                            <div className="grid grid-cols-2 gap-2 col-span-1">
                              <div className="text-gray-200 bg-white/5 hover:bg-white/10 rounded text-center h-8 cursor-pointer"
                                onClick={() => {
                                  updateScene('pos', {
                                    x: sceneData[sessionData.activeIndex].pos.x - 10,
                                    y: sceneData[sessionData.activeIndex].pos.y,
                                    z: sceneData[sessionData.activeIndex].pos.z
                                  })
                                }}
                              >
                                <img src="/media/icon/noun-left-arrow-1035682-FFFFFF.svg" className="h-3 mt-2 mx-auto" />
                              </div>
                              <div className="text-gray-200 bg-white/5 hover:bg-white/10 rounded text-center h-8 cursor-pointer"
                                onClick={() => {
                                  updateScene('pos', {
                                    x: sceneData[sessionData.activeIndex].pos.x - 2.5,
                                    y: sceneData[sessionData.activeIndex].pos.y,
                                    z: sceneData[sessionData.activeIndex].pos.z
                                  })
                                }}
                              >
                                <img src="/media/icon/noun-left-arrow-1035682-FFFFFF.svg" className="h-3 mt-2 mx-auto" />
                              </div>
                            </div>
                            <div className="text-gray-200 text-center mt-1">
                              {sceneData[sessionData.activeIndex].pos.x}
                            </div>
                            <div className="grid grid-cols-2 gap-2 col-span-1">
                              <div className="text-gray-200 bg-white/5 hover:bg-white/10 rounded text-center font-semibold cursor-pointer"
                                onClick={() => {
                                  updateScene('pos', {
                                    x: sceneData[sessionData.activeIndex].pos.x + 2.5,
                                    y: sceneData[sessionData.activeIndex].pos.y,
                                    z: sceneData[sessionData.activeIndex].pos.z
                                  })
                                }}
                              >
                                <img src="/media/icon/noun-right-arrow-1035676-FFFFFF.svg" className="h-3 mt-2 mx-auto" />
                              </div>
                              <div className="text-gray-200 bg-white/5 hover:bg-white/10 rounded text-center font-semibold cursor-pointer"
                                onClick={() => {
                                  updateScene('pos', {
                                    x: sceneData[sessionData.activeIndex].pos.x + 10,
                                    y: sceneData[sessionData.activeIndex].pos.y,
                                    z: sceneData[sessionData.activeIndex].pos.z
                                  })
                                }}
                              >
                                <img src="/media/icon/noun-right-arrow-1035676-FFFFFF.svg" className="h-3 mt-2 mx-auto" />
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-4 gap-2 mt-4">
                            <div className="text-gray-300 mt-1">
                              height
                            </div>
                            <div className="text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold rounded text-center h-8 cursor-pointer"
                              onClick={() => {
                                updateScene('pos', {
                                  x: sceneData[sessionData.activeIndex].pos.x,
                                  y: sceneData[sessionData.activeIndex].pos.y - 2.5,
                                  z: sceneData[sessionData.activeIndex].pos.z
                                })
                              }}
                            >
                              -
                            </div>
                            <div className="text-gray-200 text-center mt-1">
                              {sceneData[sessionData.activeIndex].pos.y}
                            </div>
                            <div className="text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold rounded text-center h-8 cursor-pointer"
                              onClick={() => {
                                updateScene('pos', {
                                  x: sceneData[sessionData.activeIndex].pos.x,
                                  y: sceneData[sessionData.activeIndex].pos.y + 2.5,
                                  z: sceneData[sessionData.activeIndex].pos.z
                                })
                              }}
                            >
                              +
                            </div>
                          </div>
                          <div className="grid grid-cols-4 gap-2 mt-4">
                            <div className="text-gray-300 mt-1">
                              depth
                            </div>
                            <div className="text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold rounded text-center h-8 cursor-pointer"
                              onClick={() => {
                                updateScene('pos', {
                                  x: sceneData[sessionData.activeIndex].pos.x,
                                  y: sceneData[sessionData.activeIndex].pos.y,
                                  z: sceneData[sessionData.activeIndex].pos.z - 5
                                })
                              }}
                            >
                              <img src="/media/icon/noun-up-arrow-1035685-FFFFFF.svg" className="h-3 mt-2 mx-auto" />

                            </div>
                            <div className="text-gray-200 text-center mt-1">
                              {sceneData[sessionData.activeIndex].pos.z}
                            </div>
                            <div className="text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold rounded text-center h-8 cursor-pointer"
                              onClick={() => {
                                updateScene('pos', {
                                  x: sceneData[sessionData.activeIndex].pos.x,
                                  y: sceneData[sessionData.activeIndex].pos.y,
                                  z: sceneData[sessionData.activeIndex].pos.z + 5
                                })
                              }}
                            >
                              <img src="/media/icon/noun-down-arrow-1035684-FFFFFF.svg" className="h-3 mt-2 mx-auto" />
                            </div>
                          </div>
                        </div>
                        : null}
                    </div>








                    <div className="grid gap-2 grid-cols-4 pl-2 pr-2 mt-4">

                      {sceneData[sessionData.activeIndex] != null ? platformOptions.map((platformItem, index) => (
                        <div
                          className={` text-center p-2  text-sm   rounded hover:bg-zinc-800   hover:border-gray-600 border  
${sceneData[sessionData.activeIndex].platform ? platformItem.model == sceneData[sessionData.activeIndex].platform.model ? 'bg-white/5 border border-white/10' : 'border-gray-900' : null}
`}
                          onClick={() => updateScene('platform',
                            {
                              model: platformItem.model,
                              matcap: sceneData[sessionData.activeIndex].platform.matcap,
                              text: {
                                color: sceneData[sessionData.activeIndex].platform.text.color,
                                scale: sceneData[sessionData.activeIndex].platform.text.scale,
                              }
                            }
                          )}
                          key={platformItem.id + 'key' + platformItem.name} >
                          <div className="align-center text-center">
                            <img src={platformItem.icon} className="mx-auto w-full w-8 h-8 mb-4 " />
                          </div>
                          <div className="mx-auto text-sm text-gray-300">
                            {platformItem.name}
                          </div>
                        </div>
                      ))
                        : null}
                    </div>


                    <div className="w-full text-sm ml-2 mt-8">
                      <div className="mb-2  text-gray-100 font-semibold">
                        Style
                      </div>
                    </div>

                    {/* <div className="flex rounded-md w-full p-1 bg-white/5 gap-1 ">
                      <div
                        className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
                          ${sceneData[sessionData.activeIndex].vfxShadows == 0 ? 'bg-sky-600 text-gray-200 font-semibold' : ' text-gray-300 hover:bg-sky-600/50 '}`}
                        onClick={() => {
                          updateScene('vfxShadows', 0)
                        }}>
                        Plain
                      </div>
                      <div
                        className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
                       ${sceneData[sessionData.activeIndex].vfxShadows == 1 ? 'bg-sky-600 text-gray-200 font-semibold' : ' text-gray-300 hover:bg-sky-600/50 '}`}
                        onClick={() => {
                          updateScene('vfxShadows', 1)
                        }}>
                        Shadows
                      </div>
                    </div> */}


                    <div className="w-full text-sm text-gray-200 ml-2 mt-4">
                      {/* <div className="mb-2  text-gray-100 font-semibold">
                        Style Options
                      </div> */}


                      <div className="grid grid-cols-4 gap-1 mr-1 mt-6 mb-6">
                        <div className="col-span-3 ml-0 mt-2 mb-2 text-gray-300 text-sm">Platform Color</div>
                        <div className="col-span-3">
                          <div className="grid grid-cols-3 gap-1">
                            {/* <div className="col-span-2">
                          <PlatformOptions />
                        </div> */}
                            <div className="col-span-3">

                              {/* <CompMatcapGen
                                updateStory={updateSceneMatcap}
                                updateAttribute=''
                                storyData={storyData}
                              /> */}


                              {/* 

updateScene('platform',
                    {
                      model: sceneData[sessionData.activeIndex].platform.model,
                      matcap: mat.value,
                      text: {
                        color: sceneData[sessionData.activeIndex].platform.text.color,
                        scale: sceneData[sessionData.activeIndex].platform.text.scale,
                      }
                    }
                  )

*/}




                              <PlatformMaterialOptions keycode={'Platformcolor'} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-2">
                        Text Color
                      </div>
                      <CompactPicker
                        style={{ width: '20px', background: '#000000' }}
                        color={sceneData[sessionData.activeIndex].platform.text.color}
                        disableAlpha={true}
                        className="color-picker-dark "
                        onChange={(color) => {
                          //setColorVariable(color.hex)
                          //              storeColor(colorVariable)
                          //console.log('on change...')
                          //storeColor(color.hex)
                        }
                        }
                        onChangeComplete={(color, event) => {
                          //            setColorVariable(color.hex)
                          // storeColor(colorVariable)
                          //  console.log('on change complete...')
                          //console.log(color.hex)

                          updateScene('platform',
                            {
                              model: sceneData[sessionData.activeIndex].platform.model,
                              matcap: sceneData[sessionData.activeIndex].platform.matcap,
                              text: {
                                color: color.hex,
                                scale: sceneData[sessionData.activeIndex].platform.text.scale,
                              }
                            }
                          )

                        }
                        }
                      />


                    </div>







                  </div>
                </div>
                <div id="tab3Story" className={`tab-content section text-gray-200   ${activeTabStoryDesign != 'tab3' ? ' hidden' : ''} `}>
                  <div className="section mt-2 ">
                    <div className="w-full  section-scroll h-full-180 pr-1  ">





                      {/* <div className="text-gray-400 text-sm ml-4 mb-8 mt-2">
                      Camera Controls...

                      
                      <div className="">
                          Use custom camera for scene
                      </div>

                      <div className="grid grid-cols-3 gap-2 mt-4">
                        <div></div>
                        <div className="text-gray-200 bg-white/5 hover:bg-white/10 rounded text-center h-8 cursor-pointer"
                          onClick={() => { //modelAdjust(item, 1, 0, 0, 0) 
                          }}
                        >
                          U
                        </div>
                        <div></div>

                        <div className="text-gray-200 bg-white/5 hover:bg-white/10 rounded text-center h-8 cursor-pointer">
                          L
                        </div>
                        <div></div>
                        <div className="text-gray-200 bg-white/5 hover:bg-white/10 rounded text-center h-8 cursor-pointer">
                          R
                        </div>

                        <div></div>
                        <div className="text-gray-200 bg-white/5 hover:bg-white/10 rounded text-center h-8 cursor-pointer"
                          onClick={() => { //modelAdjust(item, 1, 0, 0, 0) 
                          }}
                        >
                          D
                        </div>
                        <div></div>                        
                      </div>




                      <div className="grid grid-cols-4 gap-2 mt-4">
                        <div className="text-gray-300">
                          position
                        </div>
                        <div className="text-gray-200 bg-white/5 hover:bg-white/10 rounded text-center h-8 cursor-pointer"
                          onClick={() => { //modelAdjust(item, 1, 0, 0, 0) 
                          }}
                        >
                          <img src="/media/icon/noun-left-arrow-1035682-FFFFFF.svg" className="h-3 mt-2 mx-auto" />
                        </div>
                        <div className="text-gray-200 text-center">
                          xx
                        </div>
                        <div className="text-gray-200 bg-white/5 hover:bg-white/10 rounded text-center font-semibold cursor-pointer"
                          onClick={() => { //modelAdjust(item, -1, 0, 0, 0)
                          }}
                        >
                          <img src="/media/icon/noun-right-arrow-1035676-FFFFFF.svg" className="h-3 mt-2 mx-auto" />
                        </div>
                      </div>

                      <div className="grid grid-cols-4 gap-2 mt-4">
                        <div className="text-gray-300">
                          depth
                        </div>
                        <div className="text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold rounded text-center h-8 cursor-pointer"
                          onClick={() => { // modelAdjust(item, 0, 0, 1, 0) 
                          }}
                        >
                          -
                        </div>
                        <div className="text-gray-200 text-center">
                          xx
                        </div>
                        <div className="text-gray-200 bg-white/5 hover:bg-white/10 text-lg font-bold rounded text-center h-8 cursor-pointer"
                          onClick={() => { // modelAdjust(item, 0, 0, -1, 0) 
                          }}
                        >
                          +
                        </div>
                      </div>



                    </div> */}





                      <div className="text-gray-100 text-sm ml-2 mb-8 mt-2">

                        <div className="text-gray-400 mb-4">
                          Select Manual to change your viewpoint of the current scene
                        </div>




                        {/* <CameraLogger /> */}



                        {/* {sceneData[sessionData.activeIndex].layout ?
                        <div className="w-full bg-sky-600 hover:bg-sky-500 rounded-md p-2 text-center text-sm cursor-pointer mb-12"
                          onClick={() => {
                            props.getCameraInfo()
                          }}
                        >
                          Save Current View
                        </div>
                        : null} */}


                        <StoryEditCamera
                          scene={sceneData[sessionData.activeIndex]}
                          updateSceneFunction={props.updateSceneFunction}
                          activeIndex={sessionData.activeIndex}
                        // TODO - camera object goes here....
                        />






                      </div>

                      {/* <button type="button" className="text-gray-200 admin-component component-card rounded text-sm px-3 py-2.5 text-left inline-flex items-center  mb-2" onClick={() => { setOpen(true) }}>
                      <img className="w-6 h-6 mr-2" src='/util/app/b3-load.svg' fill="currentColor" viewBox="0 0 8 19" />
                      Load
                    </button> */}
                      {/* <OpenVizPackModal /> */}
                      {/* <ComponentTemplates /> */}

                    </div>
                  </div>
                </div>

                : (null)
              </div>






            </>
            : (
              <div className="section-scroll h-full-300 mt1" id="detail-panel-start">
                <div className="info-blank font-normal">
                  <img src="/media/icon/noun-information-5783675-00AAFF.svg" className="h24 m-lr-auto mt-4 mb-4" />
                  Add a Scene to get started.  Use the &quot;Add Scene&quot; form within the left panel to get started.  Happy storytelling!</div>
              </div>
            )
          }
        </div>
      </div>




      <div id="playModeNavigation">

        <div className="navigation-segment-wrapper navstyle-side">
          {/* <div className="w-100p text-white pip-story-name">
            {sceneData[sessionData.activeIndex] != undefined ?
              <>
                <div className=" font-2xl">{sceneData[sessionData.activeIndex].name}</div>
              </>
              : (null)
            }
          </div>

          <div className="navigation-progress-wrapper">
            <div className="navigation-progress-meter" id="navigation-progress-meter"></div>
          </div> */}


          <div className=" shadow-md p-4 rounded-lg mt-10" id="interaction-wrapper"
            style={{ background: storyData.uiStyle['nav-region-bg'] }}
          >
            {sessionData.playModeActive & sessionData.modeVideo == 'pip' ?
              <div className="container mx-auto">

                <div className="pt-4 pb-8">
                  {storyData.imageStoryLogo != undefined ?
                    <img
                      src={storyData.imageStoryLogo}
                      // src='/util/Procore_Logo_FC_Black_RGB.png'

                      style={{ height: '32px' }}
                    //id="pip-logo22"
                    />
                    :
                    // null
                    <img src="/media/demo/workoutai/B3-WorkoutAI-Logo.png" id="pip-log22o" />
                  }
                </div>





                <Webcam
                  width={'100%'}>
                </Webcam>


              </div>
              : (null)}


            <nav aria-label="Progress" className="mt-2 pb-4 ml-2">

              <ol role="list" className="overflow-hidden">

                {sceneData.map((step, stepIdx) => (
                  <li key={step.name} className={classNames(stepIdx !== sceneData.length - 1 ? 'pb-4' : '', 'relative')}>
                    {stepIdx < sessionData.activeIndex ? (
                      <>
                        {/* <div className={"absolute left-2.5 top-4 -ml-px mt-0.5 h-full w-0.5 opacity-50 animate_animated animate_fadeIn"} aria-hidden="true"
                          style={{ background: storyData.uiStyle['nav-progress-meter-bg'] }}
                        /> */}
                        <div
                          onClick={() => {
                            props.updateSceneFunction(stepIdx)
                          }}
                          className="group relative flex items-start cursor-pointer">
                          <span className="flex h-8 items-center">
                            <span className={"relative z-10 flex h-5 w-5 items-center justify-center rounded-md   mt-2 "}
                              style={{ background: storyData.uiStyle['nav-progress-meter-bg'] }}
                            >
                              <span className="h-2.5 w-2.5 rounded-full bg-transparent  " />
                            </span>
                          </span>
                          <span className="ml-4 flex mt-2  min-w-0 flex-col">
                            <span className="text-lg font-medium  "
                              style={{ color: storyData.uiStyle['nav-button-text-off'] }}
                            >{step.name}</span>
                          </span>
                        </div>
                      </>
                    ) : sessionData.activeIndex == stepIdx ? (
                      <>
                        <div
                          onClick={() => {
                            props.updateSceneFunction(stepIdx)
                          }}
                          className="group relative flex items-start cursor-pointer" aria-current="step"
                          style={{
                            //background: storyData.uiStyle['nav-button-bg-on']
                            background: storyData.name.includes('IC Demo') ? 'inherit' : storyData.uiStyle['nav-button-bg-on']

                          }}

                        >
                          <span className="flex h-8 items-center animate__animated animate__bounceIn " aria-hidden="true">
                            <span className="relative z-10 flex h-5 w-5 items-center justify-center rounded-md border-4 border-white/10 mt-2"
                              style={{ background: storyData.uiStyle['nav-progress-meter-bg'] }}
                            >
                              <span className="h-2.5 w-2.5 rounded-full bg-transparent  " />
                            </span>
                          </span>
                          <span className="ml-4 mt-2 flex min-w-0 flex-col">
                            <span className="text-lg font-medium"
                              //style={{ color: storyData.uiStyle['nav-button-text-on'] }}

                              style={{
                                color: storyData.name.includes('IC Demo') ? storyData.uiStyle['super-main-title-color'] : storyData.uiStyle['nav-button-text-on']
                              }}

                            >{step.name}</span>
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          onClick={() => {
                            props.updateSceneFunction(stepIdx)
                          }}
                          className="group relative flex items-start cursor-pointer">
                          <span className="flex h-8 items-center" aria-hidden="true">
                            <span className="relative z-10 flex  mt-2 h-5 w-5 items-center justify-center rounded-md   border-gray-300 bg-white group-hover:border-gray-400"
                              style={{ background: storyData.uiStyle['nav-progress-meter'] }}
                            >
                              <span className="h-2.5 w-2.5 rounded-full bg-transparent  " />
                            </span>
                          </span>
                          <span className="ml-4 flex mt-2  min-w-0 flex-col">
                            <span className="text-lg font-medium"
                              style={{ color: storyData.uiStyle['nav-button-text-off'] }}
                            >{step.name}</span>
                          </span>
                        </div>
                      </>
                    )}
                  </li>
                ))}
              </ol>
            </nav>


            {/* {sceneData.map((scene, index) => (
              <div
                key={scene.id}
                onClick={() => {
                  props.updateSceneFunction(index)
                }}
                className={`grow 
                timeline-nav
                pb-8
                 ${sessionData.activeIndex == index ? 'xxxlive-nav-on' : ''}`} >
                <div className="rounded-md inline bg-black text-gray-50 p-4 mr-4">{index + 1}</div>
                <div className="nav-heading inline">{scene.name}</div>
              </div>
            ))} */}


          </div>

          <div className="navstyle-side">

          </div>


          {/* <div className="flex-none  play-toggle button-playmode"> */}

          {/* </div> */}

        </div>






        {/* {storyData.navigation == 'nav1' ?
          <NavigationProcessChevron updateSceneFunction={props.updateSceneFunction} TogglePlayMode={TogglePlayMode} ></NavigationProcessChevron>
          : (null)
        }
        {storyData.navigation == 'nav2' ?
          <NavigationTimelineMainSub updateSceneFunction={props.updateSceneFunction} TogglePlayMode={TogglePlayMode} ></NavigationTimelineMainSub>
          : (null)
        }
        {storyData.navigation == 'nav3' ?
          <NavigationTimelineSolo updateSceneFunction={props.updateSceneFunction} TogglePlayMode={TogglePlayMode} ></NavigationTimelineSolo>
          : (null)
        }
        {storyData.navigation == 'nav4' ?
          <div className="navigation-segment scene-details">
            {sceneData[sessionData.activeIndex] ?
              <div className="nav-single-major">
                <div className="nav-heading">{sceneData[sessionData.activeIndex].name}</div>
                <div className="nav-subheading">The best Lorem Ipsum Generator in all the sea! Heave this scurvy copyfiller fer yar next adventure and cajol yar clients into walking the plank with ev'ry layout.</div>
                <div className="navigation-progress-wrapper">
                  <div className="navigation-progress-meter" id="navigation-progress-meter"></div>
                </div>
              </div>
              : (null)
            }
          </div>
          : (null)
        }
        {storyData.navigation == 'nav5' ?
          <div className="flex" id="interaction-wrapper">
            {sceneData.map((scene, index) => (
              <div
                key={scene.id}
                onClick={() => {
                  props.updateSceneFunction(index)
                }}
                className={`grow h-12 timeline-nav ${sessionData.activeIndex == index ? 'live-nav-on' : ''}`} >
                {scene.name}
              </div>
            ))}
          </div>
          : (null)
        } */}


        {/* 
        
        <CompColorPicker
            title="Main Title"
            styleName="super-main-title-color"
          />
          <CompColorPicker
            title="Main Background"
            styleName="super-main-bg-color"
          />
          <CompColorPicker
            title="Subtitle"
            styleName="super-subtitle-color"
          />
          <CompColorPicker
            title="Secondary Background"
            styleName="super-secondary-bg-color"
          />
          <CompColorPicker
            title="Highlight Color"
            styleName="super-highlight-color"
          />
          <CompColorPicker
            title="List Icon Color"
            styleName="super-list-icon-color"
          />
          <CompColorPicker
            title="List Text Color"
            styleName="super-list-text-color"
          />

                    styleName="nav-progress-meter"
        />
        <CompColorPicker
          title="Progress Meter Background"
          styleName="nav-progress-meter-bg"

        */}



        {/* {designPreviewActive ?
          <div className="p-8 bg-white rounded-md ">
            {storyData.uiStyle != undefined ?
              <div className="rounded-md overflow-hidden animate__animated animate__fadeIn select-none" id="preview-supercard">
                <img src="/media/site/b3-image-person.png" className="mx-auto" />
                <div className="grid grid-cols-1 gap-1"
                  style={{ background: storyData.uiStyle['super-main-bg-color'] }}
                >
                  <div
                    className="  border-t-4 text-center px-4 pt-4 font-semibold text-xl"
                    style={{
                      color: storyData.uiStyle['super-main-title-color'],
                      borderColor: storyData.uiStyle['super-highlight-color']
                    }}
                  >
                    Main Heading
                  </div>
                  <div
                    className="  text-center px-4 pb-4"
                    style={{
                      color: storyData.uiStyle['super-subtitle-color']
                    }}
                  >
                    Subtitle Heading
                  </div>
                  <div
                    className="relative inline-flex text-left px-6 pb-8"
                    style={{
                      color: storyData.uiStyle['super-subtitle-color']
                    }}
                  >
                    Body text here that helps to tell an amazing story of solution value
                  </div>
                  <div
                    className="relative inline-flex text-center px-6 pt-6"
                    style={{
                      background: storyData.uiStyle['super-secondary-bg-color'],
                      color: storyData.uiStyle['super-list-text-color']
                    }}
                  >
                    <div className="grid grid-rows-4 grid-flow-col gap-4">
                      <div className="relative inline-flex">
                        <div className="inline w-2 h-2 mt-2 rounded-full mr-3"
                          style={{
                            background: storyData.uiStyle['super-list-icon-color']
                          }}
                        ></div> List item example
                      </div>
                      <div className="relative inline-flex">
                        <div className="inline w-2 h-2 mt-2 rounded-full mr-3"
                          style={{
                            background: storyData.uiStyle['super-list-icon-color']
                          }}
                        ></div> List item example
                      </div>
                      <div className="relative inline-flex">
                        <div className="inline w-2 h-2 mt-2 rounded-full mr-3"
                          style={{
                            background: storyData.uiStyle['super-list-icon-color']
                          }}
                        ></div> List item example
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              : null
            }
            {storyData.uiStyle != undefined ?
              <div className="rounded-md p-4 animate__animated animate__fadeIn select-none" id="preview-navigation" style={{ background: storyData.uiStyle['nav-region-bg'] }}>
                <div className="grid grid-cols-4 gap-3 pb-3">

                  {storyData.imageStoryLogo != undefined ?
                    <img src={storyData.imageStoryLogo} className="relative inline pt-2" />
                    : null}

                  <button
                    type="button"
                    className="relative inline-flex text-center p-4 rounded-md"
                    style={{
                      background: storyData.uiStyle['nav-button-bg-on'],
                      color: storyData.uiStyle['nav-button-text-on']
                    }}
                  >
                    Nav Item One
                  </button>
                  <button
                    type="button"
                    className="relative inline-flex text-center p-4 rounded-md"
                    style={{
                      background: storyData.uiStyle['nav-button-bg-off'],
                      color: storyData.uiStyle['nav-button-text-off']
                    }}
                  >
                    Nav Item Two
                  </button>
                  <button
                    type="button"
                    className="relative inline-flex text-center p-4 rounded-md"
                    style={{
                      background: storyData.uiStyle['nav-button-bg-off'],
                      color: storyData.uiStyle['nav-button-text-off']
                    }}
                  >
                    Nav Item Three
                  </button>
                </div>
                <div className="w-full rounded h-2.5 dark:bg-gray-700"
                  style={{ background: storyData.uiStyle['nav-progress-meter-bg'] }}
                >
                  <div className=" h-2.5 rounded" style={{ width: '45%', background: storyData.uiStyle['nav-progress-meter'] }}></div>
                </div>
              </div>
              : null
            }
          </div>
          : null} */}


      </div>

      {storyData.navigation != 'playlist-sidebar2' ?
        <button className="button-playmode w-12 h-12 bg-gray-600 rounded p-2 m-2 hover:bg-gray-700
          absolute bottom-4 left-2
          "
          onClick={() => {
            window.location.reload()
          }
          }>
          <img src="/media/icon/noun-down-arrow-1035684-FFFFFF.svg" />
        </button>
        : null}

      <ToastContainer
        // position="bottom-center"
        // autoClose={5000}
        // limit={5}
        // hideProgressBar={false}
        // newestOnTop
        // closeOnClick
        // rtl={false}
        // //pauseOnFocusLoss
        // draggable={false}
        // pauseOnHover
        // theme="dark"
        // transition="Slide"
        transition={Slide}
      />
    </>

  )
}

export default StoryEditOverlay