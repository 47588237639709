import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
import { db } from '../firebase'
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore'
import { Dialog, Transition } from '@headlessui/react'
import { StoryContext } from '/AppStoryPlayer'
import CompLoaderFacts from './CompLoaderFacts'
// import CompLoader from './CompLoader'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const BrandGenerator = ({}) => {
  const [storyData, setStoryData] = useContext(StoryContext)
  const [openBrandGenerator, setOpenBrandGenerator] = useState(false)
  const [brandName, setBrandName] = useState('')
  const [brandDomain, setBrandDomain] = useState('')
  const [themes, setThemes] = useState([])
  const [logo, setLogo] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const baseColor = '#222225';
  const highlightColor = '#2C2C30';

  const generateThemes = async () => {

    console.log('GENERATE THEMES for : ' + brandName)

    setIsLoading(true)
    try {
      const response = await fetch('http://127.0.0.1:5001/brevit3-57b00/us-central1/generateBrandTheme', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ companyName: brandDomain }),
      })
      const data = await response.json()
      setThemes(data.themes)
    } catch (error) {
      console.error('Error generating themes:', error)
    }

    try {
        const response = await fetch('http://127.0.0.1:5001/brevit3-57b00/us-central1/generateBrandLogo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ companyDomain: brandDomain }),
        })
        const data = await response.json()
        console.log('Logo Data: ', data)
        setLogo(data)
      } catch (error) {
        console.error('Error generating logo:', error)
      }



    setIsLoading(false)
  }

  const [inputError, setInputError] = useState(false)

const handleGenerate = () => {
  if (!brandDomain.trim()) {
    setInputError(true)
    setTimeout(() => setInputError(false), 2000) // Reset error state after 2 seconds
      return
    }
    generateThemes()
  }

  return (
    <>
      <div>
        <div className={`text-gray-300 px-2 ml-1 mt-2 py-2
        admin-component component-card 
        w-fullm mr-2
          rounded text-sm text-center`} onClick={() => setOpenBrandGenerator(true)}><img src="/util/app/b3-genai.svg" className='h-6 w-6 inline mr-2' /> Brand Generator</div>
      </div>

      <Transition.Root show={openBrandGenerator} as={Fragment} key={'brandmodal'}>
        <Dialog as="div" className="relative z-10" onClose={() => setOpenBrandGenerator(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center text-center sm:items-center p-10">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-zinc-900 px-4 pt-2 text-left shadow-xl transition-all sm:my-2 w-full   m-40 sm:p-12">
                  <div className="w-full  flex flex-col">

                    <div className="text-xl  text-white mb-4">
                        Brand Generator
                    </div>
                    <div className="absolute right-4 -top-4 hidden  h-fit pr-4 pt-4 sm:block w-10">
                                        <button
                                            type="button"
                                            className="rounded-md   focus:outline-none  "
                                            onClick={() => setOpenBrandGenerator(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <img src={'/media/icon/noun-close-fff.svg'} className=' opacity-50 hover:opacity-80 absolute hover:scale-105 ' />
                                        </button>
                                    </div>

                    {/* Input and Generate Button */}
                    <div className="flex gap-4 mb-8">
                      {/* <input
                        type="text"
                        value={brandName}
                        onChange={(e) => setBrandName(e.target.value)}
                        placeholder="Enter brand name..."
                        className="flex-grow p-2 rounded bg-zinc-800 text-white"
                      /> */}
<input
  type="text"
  value={brandDomain}
  onChange={(e) => setBrandDomain(e.target.value)}
  onKeyDown={(e) => {
    if (e.key === 'Enter') {
      handleGenerate()
    }
  }}
  placeholder="Brand domain..."
  className={`block flex rounded-md border-0 px-4 py-3 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset w-full
    ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none
    ${inputError ? 'ring-red-500 animate-shake' : ''}`}
/>

<button
  onClick={handleGenerate}
  disabled={isLoading}
  className="px-4 py-2 bg-sky-500 hover:bg-sky-400 rounded text-white disabled:opacity-50"
>
  {isLoading ? 'Generating...' : 'Generate'}
</button>
                    </div>


                    
 

<div className="flex gap-8">
                    <div className="w-1/5">
                    <div className="text-zinc-100 text-lg mb-4">Brand Logos</div>
                    
{isLoading &&
                    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
                                                                            <Skeleton height={600} width={'100%'} containerClassName="flex-1 w-full h-full rounded-md" />
                                                                        </SkeletonTheme>
}

{!isLoading && themes.length == 0 &&
<div className="border border-zinc-800 rounded-md bg-black/10 h-[600px]"> </div>
}
                    {!isLoading && logo && logo.logos && (
    <div className=" h-[600px] overflow-y-auto">

      {logo.logos.map((logoItem, index) => (
        <div key={index} className="  border px-4 my-2 border-zinc-800 rounded-md bg-black/10">
          {/* <p className="text-gray-400 text-sm">Theme: {logoItem.theme}, Type: {logoItem.type}</p> */}

            {logoItem.formats.map((format, fIndex) => (
          <div className=" p-4 rounded cursor-pointer"
          onClick={() => setStoryData(prev => ({...prev, imageStoryLogo: format.src}))}
          >
<img 
                key={fIndex}
                src={format.src}
                alt={`${logoItem.theme} ${logoItem.type} logo`}
                className="max-w-full h-auto"
                //style={{ background: format.background || 'transparent' }}
              />
          </div>
            ))}


          
        </div>
      ))}
     
  </div>
)}
                    </div>

                    <div className="w-4/5">
                    <div className="text-zinc-100 text-lg mb-4">Theme Options</div>
                    {!isLoading && themes.length == 0 &&
<div className="border border-zinc-800 rounded-md bg-black/10 h-[600px]"> </div>
}
                    {isLoading &&
                    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
                                                                            <Skeleton height={600} width={'100%'} containerClassName="flex-1 w-full h-full rounded-md" />
                                                                        </SkeletonTheme>
                                                                        }

{!isLoading &&
                    <div className="grid grid-cols-3 gap-2">
                      {themes.map((theme, index) => (
                        <div key={index} className=" border  cursor-pointer border-zinc-800 rounded-md bg-black/10 hover:bg-white/5"
                        onClick={() => setStoryData(prev => ({...prev, uiStyle: theme.classes}))}
                        >  
                          {/* Card Preview */}
                          <div
                            className={`border-t-4 rounded pt-4 mt-8 mx-auto w-4/5 mb-4 card-preview`}
                            style={{
                              background: theme.classes['super-main-bg-color'],
                              color: theme.classes['super-main-title-color'],
                              borderColor: theme.classes['super-highlight-color'],
                            }}>
                            <div
                              className="text-left px-4 font-semibold text-xl"
                              style={{
                                color: theme.classes['super-main-title-color']
                              }}>
                              Sample Card Title
                            </div>
                            <div
                              className="text-left px-4 pb-4"
                              style={{
                                color: theme.classes['super-subtitle-color']
                              }}>
                              Card Subtitle Goes Here
                            </div>
                            <div
                              className="relative inline-flex text-left text-sm px-4 pb-8"
                              style={{
                                color: theme.classes['super-body-color']
                              }}>
                              Body of the card where you would place additional detail in long form content. This is a sample of the body text.
                            </div>
                          </div>


                          {/* Navigation Preview */}
                          <div className="p-4 w-full">
                            <div style={{ backgroundColor: theme.classes['nav-region-bg'] }} className='flex w-full rounded-md p-2 '>
                              <div className="flex-grow">
                                <ul className="flex p-2 mx-auto">
                                  <li role="" className="text-sm p-3 px-5 border-b-4 rounded mr-2 font-semibold"
                                    style={{
                                      borderColor: theme.classes['nav-progress-meter-bg'],
                                      backgroundColor: theme.classes['nav-button-bg-on'],
                                      color: theme.classes['nav-button-text-on']
                                    }}>
                                    Selected Scene
                                  </li>
                                  <li role="" className="text-sm p-3 px-5 border-b-4 rounded mr-2 font-semibold"
                                    style={{
                                      borderColor: theme.classes['nav-progress-meter'],
                                      backgroundColor: theme.classes['nav-button-bg-off'],
                                      color: theme.classes['nav-button-text-off']
                                    }}>
                                    Not Selected
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                        </div>
                      ))}
                    </div>
}
                    </div>
                    </div>

                    
                    {/* Dialog Buttons */}
                    {/* <div className="flex mt-4">
                      <div className="flex-auto px-2">
                        <button
                          type="button"
                          className="mt-3 w-full justify-center rounded-md px-3 py-2 text-lg text-gray-200 shadow-sm bg-zinc-800 hover:bg-zinc-700 sm:col-start-1 sm:mt-0"
                          onClick={() => setOpenBrandGenerator(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="flex-auto px-2">
                        <button
                          type="button"
                          className="w-full justify-center rounded-md px-3 py-2 text-lg font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                          onClick={() => setOpenBrandGenerator(false)}
                        >
                          Save
                        </button>
                      </div>
                    </div> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default BrandGenerator


// import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
// import { db } from '../firebase'
// import {
//   query,
//   collection,
//   onSnapshot,
//   updateDoc,
//   doc,
//   addDoc,
//   deleteDoc,
//   setDoc,
// } from 'firebase/firestore'
// import { Dialog, Transition } from '@headlessui/react'
// import { StoryContext } from '/AppStoryPlayer'


// const BrandGenerator = ({}) => {
 
//   const [storyData, setStoryData] = useContext(StoryContext)
//   const [openBrandGenerator, setOpenBrandGenerator] = useState(false)

//   useEffect(() => {
     
//     //setSceneName(scene.description)
//   }, []);

//   const updateSceneName = () => {
//     // const sceneRef = doc(db, 'stories', storyId, 'scenes', scene.id);
//     // updateDoc(sceneRef, sceneData)
//   }
 
//   return (
//     <>
//     <div>
//         <button onClick={() => setOpenBrandGenerator(true)}>Brand Generator</button>
//     </div>

//     <Transition.Root show={openBrandGenerator} as={Fragment} key={'brandmodal'}>
//       <Dialog as="div" className="relative z-10" onClose={() => setOpenBrandGenerator(false)}>
//         <Transition.Child
//           as={Fragment}
//           enter="ease-out duration-300"
//           enterFrom="opacity-0"
//           enterTo="opacity-100"
//           leave="ease-in duration-200"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
//         </Transition.Child>

//         <div className="fixed inset-0 z-10 overflow-y-auto">
//           <div className="flex min-h-full items-end justify-center text-center sm:items-center p-10">
//             <Transition.Child
//               as={Fragment}
//               enter="ease-out duration-300"
//               enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//               enterTo="opacity-100 translate-y-0 sm:scale-100"
//               leave="ease-in duration-200"
//               leaveFrom="opacity-100 translate-y-0 sm:scale-100"
//               leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//             >

//               <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-zinc-900 px-4 pt-2 text-left
//                shadow-xl transition-all sm:my-2 sm:w-1/2 sm:w-none m-40 sm:p-12">
//                 <div className="w-full mt-12 flex">
 
                


//                 {/* Style Preview Display */}
//                 <div className="p-4 w-full -mt-2">
//                   <div style={{ backgroundColor: `${storyData.uiStyle['nav-region-bg']} ` }} className='flex w-full rounded-md p-2 mb-8'>
//                     <div className="flex-grow">
//                       <ul className=" flex p-2 mx-auto">
//                         <li role="button" className={"text-sm p-3 px-5 border-b-4 rounded mr-2 font-semibold px-3"}
//                           style={{
//                             borderColor: storyData.uiStyle['nav-progress-meter-bg'],
//                             backgroundColor: storyData.uiStyle['nav-button-bg-on'],
//                             color: storyData.uiStyle['nav-button-text-on']
//                           }} >
//                           Selected Scene
//                         </li>
//                         <li role="button" className={"text-sm p-3 px-5 border-b-4 rounded mr-2 font-semibold px-3"}
//                           style={{
//                             borderColor: storyData.uiStyle['nav-progress-meter'],
//                             backgroundColor: storyData.uiStyle['nav-button-bg-off'],
//                             color: storyData.uiStyle['nav-button-text-off']
//                           }} >
//                           Not Selected
//                         </li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   className={`border-t-4 rounded pt-4  mt-10 mx-auto w-4/5 mb-10 card-preview  org-${storyData.name.replace(/\s+/g, '-').toLowerCase() || 'none'}`}
//                   style={{
//                     background: storyData.uiStyle['super-main-bg-color'],
//                     color: storyData.uiStyle['super-main-title-color'],
//                     borderColor: storyData.uiStyle['super-highlight-color'],
//                   }}>
//                   <div
//                     className="text-left px-4 font-semibold text-xl"
//                     style={{
//                       color: storyData.uiStyle['super-main-title-color']
//                     }}
//                   >
//                     Sample Card Title
//                   </div>
//                   <div
//                     className="text-left px-4 pb-4"
//                     style={{
//                       color: storyData.uiStyle['super-subtitle-color']
//                     }}
//                   >
//                     Card Subtitle Goes Here
//                   </div>
//                   <div
//                     className="relative inline-flex text-left text-sm px-4 pb-8"
//                     style={{
//                       color: storyData.uiStyle['super-body-color']
//                     }}
//                   >
//                     Body of the card where you would place additional detail in long form content.  This is a sample of the body text.
//                   </div>
//                 </div>
//  {/* End Style Preview Display */}









//                   <div className="flex-auto px-2">
//                     <button
//                       type="button"
//                       className="mt-3  w-full justify-center rounded-md px-3 py-2 
//                     text-lg text-gray-200 shadow-sm  
//                     bg-zinc-800 hover:bg-zinc-700 sm:col-start-1 sm:mt-0"
//                       onClick={() => 
//                         setOpenBrandGenerator(false)
//                       }
//                     >
//                       Cancel
//                     </button>
//                   </div>
//                   <div className="flex-auto px-2">
//                     <button
//                       type="button"
//                       className="w-full justify-center rounded-md  px-3 py-2
//                      text-lg font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline
//                       focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
//                       onClick={() => {
//                         setOpenBrandGenerator(false);
//                       }}
//                     >
//                       Save
//                     </button>
//                   </div>
//                 </div>
//               </Dialog.Panel>
//             </Transition.Child>
//           </div>
//         </div >
//       </Dialog >
//     </Transition.Root >
//     </>
//   );
// };


// export default BrandGenerator