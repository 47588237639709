import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import KanbanBoard from './KanbanBoard';

const KanbanModal = ({ isOpen, onClose, story }) => {
    if (!story) return null;
    
    const safelyParseJSON = (jsonString, fallback = {}) => {
        try {
            return JSON.parse(jsonString);
        } catch (e) {
            console.error("Error parsing JSON:", e);
            return fallback;
        }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-black/80 z-50 flex items-center justify-center"
                >
                    <motion.div
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 20, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="bg-zinc-900 w-full h-full overflow-auto p-6 relative"
                    >
                        <button
                            type="button"
                            onClick={onClose}
                            className="absolute top-3 right-6 bg-zinc-800 rounded-full p-2 z-50 cursor-pointer text-zinc-400 hover:text-white"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                        <KanbanBoard
                            storyChaptersData={safelyParseJSON(story.storyChapters, [])}
                            formData={safelyParseJSON(story.formData, {})}
                            businessTransformationResult={safelyParseJSON(story.businessTransformation, {})}
                            producedResult={safelyParseJSON(story.producerResult, {})}
                            businessAnalysisData={safelyParseJSON(story.businessAnalysis, {})}
                            salesAnalysisData={safelyParseJSON(story.salesAnalysis, {})}
                            storySpeakerSummaryResult={safelyParseJSON(story.storySpeakerSummary, {})}
                        />
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default KanbanModal; 