import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Protected from './components/Protected';
import { AuthContextProvider } from './context/AuthContext';
import Account from './pages/Account';
import Signin from './pages/Signin';
import AdminNav from './components/AdminNav';
import AppStoryPlayer from './AppStoryPlayer';

// Pages
import Home from './pages/Home';
import Stories from './pages/Stories';
import Broadcast from './pages/Broadcast';
import Insights from './pages/Insights';
import Messages from './pages/Messages';
import NotFound from './pages/NotFound';
import PublicTemplate from './pages/PublicTemplate';
import PublicTest from './pages/PublicTest';
import Training from './pages/Training';
import PublicHomeLive from './pages/PublicHomeLive';
import PublicAbout from './pages/PublicAbout';
import PublicPromo from './pages/PublicPromo';
import PublicPrivacy from './pages/PublicPrivacy';
import PublicTerms from './pages/PublicTerms';
import PublicContact from './pages/PublicContact';
import PublicBook from './pages/PublicBook';
import Signup from './pages/Signup';
import SampleApp from './pages/SampleApp';
import Folder from './pages/Folder';
import PublicSalesforce from './pages/PublicSalesforce';
import Admin from './pages/Admin';
import PublicHomeV2 from './pages/PublicHomeV2';
import Login from './pages/Login';
import Share from './pages/Share';
import PublicAI from './pages/PublicAI';
import Scene from './pages/Scene';
import DEV_Arrow from './pages/DEV_Arrow';
import DEV_Matcap from './pages/DEV_Matcap';
import DEV_Embed from './pages/DEV_Embed';
import CompAIStoryBoardv2 from './components/CompAIStoryBoardv2';
import AdminNavV2 from './components/AdminNav-v2';
import StoryLibrary from './components/StoryLibrary';
import InsightsV2 from './pages/Insights-v2';
import Pulses from './components/Pulses';

// Memoize the routes to prevent unnecessary re-renders
const AppRoutes = React.memo(function AppRoutes() {
  return (
    <Routes>
      {/* Public story player routes */}
      <Route path="/play/:storyId" element={<AppStoryPlayer />} />
      <Route path="/share/:storyId/v/:shareCode" element={<AppStoryPlayer />} />
      
      {/* Protected story editor route */}
      <Route 
        path="/app/stories/:storyId/edit/:sceneIndex" 
        element={<Protected><AppStoryPlayer /></Protected>} 
      />

      {/* Main app routes */}
      <Route path='/app' element={<Protected><AdminNavV2 /></Protected>}>
        <Route path='storyboard' element={<CompAIStoryBoardv2 />} />
        <Route path='home' element={<Home />} />
        <Route path='stories' element={<StoryLibrary />} />
        <Route path='account' element={<Account />} />
        <Route path='admin' element={<Admin />} />
        <Route path='pulses' element={<Pulses />} />
        <Route path='broadcast' element={<Broadcast />} />
        <Route path='share' element={<Share />} />
        <Route path='insights' element={<InsightsV2 />} />
        <Route path='messages' element={<Messages />} />
        <Route path='folder' element={<Folder />} />
        <Route path='training' element={<Training />} />
        <Route path='scene' element={<Scene />} />
      </Route>

      {/* Sample app route */}
      <Route path='/appsample' element={<SampleApp />} />

      {/* Public routes */}
      <Route path='/' element={<PublicTemplate />}>
        <Route index element={<PublicHomeV2 />} />
        <Route path='login' element={<Login />} />
        
        <Route path='about' element={<PublicAbout />} />
        <Route path='privacy' element={<PublicPrivacy />} />
        <Route path='contact' element={<PublicContact />} />
        <Route path='terms' element={<PublicTerms />} />
        <Route path='hello' element={<PublicPromo />} />
        <Route path='devdemo' element={<DEV_Embed />} />
        <Route path='remarkable' element={<PublicAI />} />
        <Route path='signin' element={<Signin />} />
        <Route path='*' element={<NotFound />} />
      </Route>

      <Route path='signup' element={<Signup />} />

      {/* Innovator routes */}
      <Route path='/innovator' element={<PublicTemplate />} >
        <Route path='salesforce' element={<PublicSalesforce />} />
      </Route>
    </Routes>
  );
});

function App() {
  return (
    <AuthContextProvider>
      <AppRoutes />
    </AuthContextProvider>
  );
}

export default App;
