import React, { useState, useRef } from 'react';
import { Link, Element, scroller, Events } from 'react-scroll';
import PathProductBusinessAnalysis from './PathProductBusinessAnalysis';
//import PathProductBusinessFromTo from './PathProductBusinessFromTo';
import BrandGenerator from './BrandGenerator';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import PathProductSalesStrategy from './PathProductSalesStrategy';
//import { PDFViewer, Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import PathProductRoadblocks from './PathProductRoadblocks';
import PathProductBusinessTransformation from './PathProductBusinessTransformation';
import { useReactToPrint } from "react-to-print";
// import { htmlToImage } from 'html-to-image';
import { logCustomEvent } from './UtilAnalytics';
import CompPrintCover from './CompPrintCover';
import PathProductBusinessCostOfInaction from './PathProductBusinessCostOfInaction';
import GoogleAuthSlides from './google/GoogleAuthSlides';

const KanbanBoard = ({ storyVisualizationData, storyChaptersData, businessAnalysisData, salesAnalysisData, producedResult, storySpeakerSummaryResult, businessTransformationResult, onUpdate, generateStoryBite, businessCostOfInactionResult, formData }) => {


    // Log all props for debugging
    console.log('storyVisualizationData:', storyVisualizationData);
    console.log('storyChaptersData:', storyChaptersData); 
    console.log('businessAnalysisData:', businessAnalysisData);
    console.log('salesAnalysisData:', salesAnalysisData);
    console.log('producedResult:', producedResult);
    console.log('storySpeakerSummaryResult:', storySpeakerSummaryResult);
    console.log('businessTransformationResult:', businessTransformationResult);
    console.log('onUpdate:', onUpdate);
    console.log('generateStoryBite:', generateStoryBite);
    console.log('formData:', formData);
    console.log('businessCostOfInactionResult:', businessCostOfInactionResult);

    

 //   console.log('storyVisualizationData', storyVisualizationData);
    //console.log('storyChaptersData', storyChaptersData);
    // console.log('businessAnalysisData', businessAnalysisData);
    // console.log('salesAnalysisData', salesAnalysisData);
    // console.log('producedResult', producedResult);
     //console.log('storySpeakerSummaryResult', storySpeakerSummaryResult);
//    console.log('businessTransformationResult', businessTransformationResult);

    const businessAnalysis = businessAnalysisData;


    const storyDayToDayRef = useRef(null);

    const handlePrint = useReactToPrint({
        //content: () => storyDayToDayRef.current,
        contentRef: storyDayToDayRef,
        documentTitle: 'StoryPath_' + (formData.promptCustomerName || 'Customer').replace(/ /g, '_') + '_DayToDay_Story_' + (storyVisualizationData?.title?.title || 'Product').replace(/ /g, '_') + '_' + new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' }),
        onBeforeGetContent: () => {
            // Optional: Add any preparation logic here
            //console.log('Preparing content for print...');
        },
        onAfterPrint: () => {
            //console.log('Printing complete');
        },
        onPrintError: (error) => {
            //console.error('Print failed:', error);
        }
    });


    let renderedOutcomes = false;
    // Initialize columns with story data
    const [columns, setColumns] = useState(() => {
        const initialColumns = {
            introduction: {
                id: 'introduction',
                title: storyVisualizationData?.title?.title || 'Introduction',
                // subtitle: storyVisualizationData?.title?.subtitle || '',
                items: [
                    // {
                    //     id: 'intro-summary',
                    //     type: 'text',
                    //     label: 'Summary',
                    //     content: storyVisualizationData?.opening?.summary || '',
                    // },
                    // {
                    //     id: 'intro-challenge',
                    //     type: 'text',
                    //     label: 'Challenge',
                    //     content: storyVisualizationData?.opening?.challenge || '',
                    // },
                    ...(storyVisualizationData?.opening?.participants || []).map((participant, index) => ({
                        id: `participant-${index}`,
                        type: 'participant',
                        label: 'Stakeholder',
                        content: participant,
                    })),
                ],
            },
            // contextAnalysis: {
            //     id: 'contextAnalysis',
            //     title: 'Context Analysis',
            //     items: [
            //         {
            //             id: 'themes',
            //             type: 'list',
            //             label: 'Themes',
            //             content: storyVisualizationData?.contextAnalysis?.themes || [],
            //         },
            //         {
            //             id: 'turning-points',
            //             type: 'list',
            //             label: 'Turning Points',
            //             content: storyVisualizationData?.contextAnalysis?.turningPoints || [],
            //         },
            //         {
            //             id: 'benefits',
            //             type: 'list',
            //             label: 'Benefits',
            //             content: storyVisualizationData?.contextAnalysis?.benefits || [],
            //         },
            //         {
            //             id: 'tech-highlights',
            //             type: 'list',
            //             label: 'Technology Highlights',
            //             content: storyVisualizationData?.contextAnalysis?.techHighlights || [],
            //         },
            //         {
            //             id: 'value-props',
            //             type: 'valueProps',
            //             label: 'Value Propositions',
            //             content: storyVisualizationData?.contextAnalysis?.valueProps || {},
            //         },
            //     ],
            // },
            ...storyChaptersData?.scenes?.reduce((acc, scene, index) => {
                acc[`chapter-${index + 1}`] = {
                    id: `chapter-${index + 1}`,
                    //title: `${index + 1} ${scene.title}`,
                    title: `${scene.title}`,
                    subtitle: scene.story,
                    location: scene.location,
                    talkTrack: scene.talk_track,
                    items: [
                        {
                            id: `chapter-${index + 1}-description`,
                            type: 'text',
                            label: 'Description',
                            content: scene.description,
                        },
                        // Add Technology item if scene.technology exists
                        ...(scene.technology.devices || []).map((technology, tIndex) => ({
                            id: `chapter-${index + 1}-technology-${tIndex}`,
                            type: 'technology',
                            label: 'Technology',
                            content: technology,
                        })),
                        ...(scene.people || []).map((person, pIndex) => ({
                            id: `chapter-${index + 1}-person-${pIndex}`,
                            type: 'person',
                            label: 'Key Person',
                            content: person,
                        })),
                        {
                            id: `chapter-${index + 1}-customer-value`,
                            type: 'text',
                            label: 'Customer Value',
                            content: scene.customer_value,
                        },
                        {
                            id: `chapter-${index + 1}-business-value`,
                            type: 'text',
                            label: 'Business Value',
                            content: scene.business_value,
                        },
                    ],
                };
                // console.log('///////')
                // console.log(acc[`chapter-${index + 1}`]);
                // console.log('///////')
                return acc;
            }, {}),
            conclusion: {
                id: 'conclusion',
                title: 'Conclusion',
                items: [
                    {
                        id: 'conclusion-summary',
                        type: 'text',
                        label: 'Summary',
                        content: storyVisualizationData?.closing?.summary || '',
                    },
                    ...(storyVisualizationData?.closing?.outcomes || []).map((outcome, index) => ({
                        id: `outcome-${index}`,
                        type: 'outcome',
                        label: 'Outcome',
                        content: outcome,
                    })),
                    {
                        id: 'future-implications',
                        type: 'text',
                        label: 'Future Implications',
                        content: storyVisualizationData?.closing?.future_implications || '',
                    },
                    //     {
                    //     id: 'metrics',
                    //     type: 'list',
                    //     label: 'Metrics',
                    //     content: storyVisualizationData?.contextAnalysis?.metrics || [],
                    // },
                ],
            },
        };
        return initialColumns;
    });

    const [activeSection, setActiveSection] = useState('introduction');
    const [undoStack, setUndoStack] = useState([]);

    // Add scroll event listener to update active section
    React.useEffect(() => {
        Events.scrollEvent.register('begin', () => { });
        Events.scrollEvent.register('end', () => { });

        const handleScroll = () => {
            const scrollContainer = document.querySelector('.main-content-scroll');
            if (!scrollContainer) return;

            const scrollPosition = scrollContainer.scrollTop;
            const sections = Object.keys(columns);
            let currentSection = sections[0];

            // Find the last section that has been scrolled past
            sections.forEach(section => {
                const element = document.getElementById(section);
                if (element) {
                    const rect = element.getBoundingClientRect();
                    // If the top of the element is above the middle of the viewport
                    if (rect.top <= window.innerHeight / 2) {
                        currentSection = section;
                    }
                }
            });

            setActiveSection(currentSection);
        };

        const scrollContainer = document.querySelector('.main-content-scroll');
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
            // Trigger initial check
            handleScroll();
        }

        return () => {
            const scrollContainer = document.querySelector('.main-content-scroll');
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, [columns]);

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.items];
        const destItems = source.droppableId === destination.droppableId
            ? sourceItems
            : [...destColumn.items];

        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);

        setColumns({
            ...columns,
            [source.droppableId]: {
                ...sourceColumn,
                items: sourceItems,
            },
            [destination.droppableId]: {
                ...destColumn,
                items: destItems,
            },
        });

        if (onUpdate) {
            onUpdate(columns);
        }
    };

    const handleContentEdit = (columnId, itemId, newContent) => {
        const updatedColumns = {
            ...columns,
            [columnId]: {
                ...columns[columnId],
                items: columns[columnId].items.map(item =>
                    item.id === itemId
                        ? { ...item, content: newContent }
                        : item
                ),
            },
        };
        setColumns(updatedColumns);
        if (onUpdate) {
            onUpdate(updatedColumns);
        }
    };

    // Add new handler for title updates
    const handleTitleEdit = (columnId, newTitle) => {
        const updatedColumns = {
            ...columns,
            [columnId]: {
                ...columns[columnId],
                title: newTitle,
            },
        };
        setColumns(updatedColumns);
        if (onUpdate) {
            onUpdate(updatedColumns);
        }
    };

    // Add new handler for subtitle updates
    const handleSubtitleEdit = (columnId, newSubtitle) => {
        const updatedColumns = {
            ...columns,
            [columnId]: {
                ...columns[columnId],
                subtitle: newSubtitle,
            },
        };
        setColumns(updatedColumns);
        if (onUpdate) {
            onUpdate(updatedColumns);
        }
    };

    const handlePersonFieldEdit = (columnId, itemId, field, newValue) => {
        const updatedColumns = {
            ...columns,
            [columnId]: {
                ...columns[columnId],
                items: columns[columnId].items.map(item =>
                    item.id === itemId
                        ? {
                            ...item,
                            content: {
                                ...item.content,
                                [field]: newValue
                            }
                        }
                        : item
                ),
            },
        };
        setColumns(updatedColumns);
        if (onUpdate) {
            onUpdate(updatedColumns);
        }
    };

    const addNewItem = (columnId, type, label) => {
        const newItem = {
            id: `${type}-${Date.now()}`,
            type,
            label,
            content: type === 'person' ? {
                name: 'New Person',
                role: 'Role',
                actions: 'Actions'
            } : ''
        };

        const updatedColumns = {
            ...columns,
            [columnId]: {
                ...columns[columnId],
                items: [...columns[columnId].items, newItem]
            }
        };
        setColumns(updatedColumns);
        if (onUpdate) {
            onUpdate(updatedColumns);
        }
    };

    const deleteItem = (columnId, itemId) => {
        const column = columns[columnId];
        const updatedItems = column.items.filter(item => item.id !== itemId);

        // Save current state to undo stack
        setUndoStack(prev => [...prev, { ...columns }]);

        const updatedColumns = {
            ...columns,
            [columnId]: {
                ...column,
                items: updatedItems
            }
        };

        setColumns(updatedColumns);
        if (onUpdate) {
            onUpdate(updatedColumns);
        }
    };

    const handleUndo = (e) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'z') {
            e.preventDefault();
            if (undoStack.length > 0) {
                const previousState = undoStack[undoStack.length - 1];
                setColumns(previousState);
                setUndoStack(prev => prev.slice(0, -1));
                if (onUpdate) {
                    onUpdate(previousState);
                }
            }
        }
    };

    // Add this effect to listen for undo keyboard shortcuts
    React.useEffect(() => {
        document.addEventListener('keydown', handleUndo);
        return () => {
            document.removeEventListener('keydown', handleUndo);
        };
    }, [undoStack]);

    const renderItem = (item, columnId) => {
        switch (item.type) {



            case 'technology':


                const device = typeof item.content === 'string'
                    ? item.content
                    : item.content;

                return (
                    <div key={item.id} className="text-zinc-300 bg-amber-400/5 p-2 rounded">
                        {device.name && (
                            <div className="mb-2 group relative">
                                <button
                                    onClick={() => {
                                        const updatedTech = { ...tech };
                                        updatedTech.devices.splice(index, 1);
                                        handleContentEdit(columnId, item.id, updatedTech);
                                    }}
                                    className="absolute -right-2 -top-2 text-zinc-700 opacity-0 group-hover:opacity-100 hover:text-zinc-400 transition-opacity print:hidden"
                                    title="Delete (Undo with Cmd/Ctrl+Z)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>
                                {/* <strong>Name:</strong>{' '} */}
                                <span
                                    className="hover:bg-zinc-50/5 focus:bg-zinc-50/5 px-2 py-1 rounded cursor-text inline-block font-semibold text-lg"
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                        const updatedTech = { ...tech };
                                        updatedTech.devices[index].name = e.target.textContent;
                                        handleContentEdit(columnId, item.id, updatedTech);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            e.target.blur();
                                        }
                                    }}
                                >
                                    {device.name}
                                </span>
                            </div>
                        )}
                        {device.capabilities && (
                            <div className="mb-2">
                                <div className="text-amber-400 ml-2">Capabilities</div>{' '}
                                <span
                                    className="hover:bg-zinc-50/5 focus:bg-zinc-50/5 px-2 py-1 rounded cursor-text inline-block"
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                        const updatedTech = { ...tech };
                                        updatedTech.devices[index].capabilities = e.target.textContent;
                                        handleContentEdit(columnId, item.id, updatedTech);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            e.target.blur();
                                        }
                                    }}
                                >
                                    {device.capabilities}
                                </span>
                            </div>
                        )}
                        {device.actions && (
                            <div className="mb-2">
                                <div className="text-amber-400 ml-2">Actions</div>{' '}
                                <span
                                    className="hover:bg-zinc-50/5 focus:bg-zinc-50/5 px-2 py-1 rounded cursor-text inline-block"
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                        const updatedTech = { ...tech };
                                        updatedTech.devices[index].actions = e.target.textContent;
                                        handleContentEdit(columnId, item.id, updatedTech);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            e.target.blur();
                                        }
                                    }}
                                >
                                    {device.actions}
                                </span>
                            </div>
                        )}
                    </div>
                );


            case 'participant':
            case 'person':
                return (
                    <div key={item.id} className="w-full group relative">
                        <div className="rounded-lg bg-zinc-600/5 p-2 flex flex-col h-full gap-2">
                            <button
                                onClick={() => deleteItem(columnId, item.id)}
                                className="absolute top-2 right-2 text-zinc-700 opacity-0 group-hover:opacity-100 hover:text-zinc-400 transition-opacity"
                                title="Delete (Undo with Cmd/Ctrl+Z)"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </button>

                            {/* Upper row with persona info */}
                            <div className="flex gap-4">
                                {item.content.image && (
                                    <div
                                        className="w-16 h-16 ml-2 mt-3 rounded-full bg-white flex-shrink-0"
                                        style={{
                                            backgroundImage: `url(/media/persona/${item.content.image})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'top'
                                        }}
                                    />
                                )}
                                <div className="flex flex-col">
                                    <div
                                        className='text-sky-500 text-lg font-semibold w-full hover:bg-zinc-50/5 focus:bg-zinc-50/5 px-2 py-1 rounded cursor-text mt-2'
                                        contentEditable
                                        suppressContentEditableWarning
                                        onBlur={(e) => handlePersonFieldEdit(columnId, item.id, 'name', e.target.textContent)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                e.target.blur();
                                            }
                                        }}
                                    >
                                        {item.content.name}
                                    </div>
                                    <div
                                        className='text-zinc-300 text-sm w-full hover:bg-zinc-50/5 focus:bg-zinc-50/5 px-2 py-1 rounded cursor-text'
                                        contentEditable
                                        suppressContentEditableWarning
                                        onBlur={(e) => handlePersonFieldEdit(columnId, item.id, 'role', e.target.textContent)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                e.target.blur();
                                            }
                                        }}
                                    >
                                        {item.content.role}
                                    </div>
                                </div>
                            </div>

                            {/* Lower row with actions */}
                            <div
                                className='text-zinc-50 text-md w-full hover:bg-zinc-50/5 focus:bg-zinc-50/5 px-2 py-1 rounded cursor-text mt-2  pb-2'
                                contentEditable
                                suppressContentEditableWarning
                                onBlur={(e) => handlePersonFieldEdit(columnId, item.id, 'actions', e.target.textContent)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        e.target.blur();
                                    }
                                }}
                            >
                                {item.content.actions}
                            </div>
                        </div>
                    </div>
                );


            case 'text':
                if (item.label === 'Customer Value' || item.label === 'Business Value') {
                    return (
                        <div key={item.id} className="w-full h-full group relative">
                            <button
                                onClick={() => deleteItem(columnId, item.id)}
                                className="absolute top-2 right-2 text-zinc-700 opacity-0 group-hover:opacity-100 hover:text-zinc-400 transition-opacity z-10"
                                title="Delete (Undo with Cmd/Ctrl+Z)"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </button>
                            <div className="flex gap-4 h-full">
                                {/* {item.label === 'Customer Value' && (
                                    <div className="rounded h-12 w-12 flex items-center justify-center mt-2">
                                        <img src="/util/app/b3-admin-path-value2.svg" className="h-8 opacity-80" />
                                    </div>
                                )} */}
                                <div className="rounded  flex-grow h-full">
                                    {/* <h4 className="text-green-500 text-lg ml-4">Value</h4> */}
                                    <div className="bg-green-500/5 h-full rounded p-4 ">
                                        <h4 className="text-zinc-100 font-semibold text-lg mb-2">{item.label}</h4>
                                        <div
                                            className="text-zinc-300 w-full hover:bg-green-200/25 focus:bg-zinc-50/5  py-1 rounded cursor-text"
                                            contentEditable
                                            suppressContentEditableWarning
                                            onBlur={(e) => handleContentEdit(columnId, item.id, e.target.textContent)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    e.target.blur();
                                                }
                                            }}
                                        >
                                            {item.content}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }

                return (
                    <div key={item.id} className="mb-2 rounded-lg   p-4 px-14">
                        <h4 className="text-zinc-50 text-lg mb-2">{item.label} </h4>
                        <div className="text-zinc-100 leading-8">
                            {typeof item.content === 'string' ? item.content : JSON.stringify(item.content)}
                        </div>
                    </div>
                );




            case 'outcome':
                const outcomeData = typeof item.content === 'string'
                    ? JSON.parse(item.content)
                    : item.content;
                return (
                    <div key={item.id} className="rounded-lg bg-green-900/10 p-4">
                        <div className="space-y-4">
                            <div>
                                {/* <div className="text-zinc-50 font-medium mb-2">Benefit</div> */}
                                <div className="text-zinc-50 text-lg">{outcomeData.benefit}</div>
                            </div>
                            <div>
                                <div className="text-green-300 mt-2 text-base  mb-2">Impact</div>
                                <div className="text-zinc-400 print:text-zinc-100 print:text-sm">{outcomeData.impact}</div>
                            </div>
                        </div>
                    </div>
                );

            case 'list':
                return (
                    <div key={item.id} className="w-full">
                        <div className="bg-black/20 rounded p-4">
                            <h4 className="text-sky-400 text-lg mb-4">{item.label}</h4>
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                                {item.content.map((point, index) => (
                                    <div key={index} className="bg-black/20 rounded p-3">
                                        <p className="text-zinc-300">{point}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );

            case 'valueProps':
                return (
                    <div key={item.id} className="w-full">
                        <div className="bg-black/20 rounded p-4">
                            <h4 className="text-sky-400 text-lg mb-4">{item.label}</h4>
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                                {Object.entries(item.content).map(([key, value]) => (
                                    <div key={key} className="bg-black/20 rounded p-3">
                                        <h5 className="text-zinc-100 capitalize mb-2">{key}</h5>
                                        <p className="text-zinc-300">{value}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );

            default:
                return (
                    <div key={item.id} className="mb-2 rounded-lg bg-black/20 p-4">
                        <h4 className="text-sky-400 text-lg mb-2">{item.label} </h4>
                        <div className="text-zinc-400">
                            {typeof item.content === 'string' ? item.content : JSON.stringify(item.content)}
                        </div>
                    </div>
                );
        }
    };

    // Add this new function after the other handler functions
    const addNewScene = (currentSceneId) => {
        // Extract the current scene number
        const currentNumber = parseInt(currentSceneId.split('-')[1]);
        const newSceneId = `chapter-${currentNumber + 1}`;

        // Shift all subsequent scenes up by one
        const updatedColumns = { ...columns };
        Object.entries(columns)
            .filter(([key]) => key.startsWith('chapter-') && parseInt(key.split('-')[1]) > currentNumber)
            .reverse()
            .forEach(([key]) => {
                const nextNumber = parseInt(key.split('-')[1]) + 1;
                updatedColumns[`chapter-${nextNumber}`] = {
                    ...updatedColumns[key],
                    id: `chapter-${nextNumber}`
                };
            });

        // Insert new scene
        updatedColumns[newSceneId] = {
            id: newSceneId,
            title: 'Title Here',
            subtitle: 'Story placeholder...',
            location: 'Location placeholder...',
            talkTrack: 'Talk track placeholder...',
            items: [
                {
                    id: `${newSceneId}-description`,
                    type: 'text',
                    label: 'Description',
                    content: 'Description placeholder...',
                },
                {
                    id: `${newSceneId}-customer-value`,
                    type: 'text',
                    label: 'Customer Value',
                    content: '',
                },
                {
                    id: `${newSceneId}-business-value`,
                    type: 'text',
                    label: 'Business Value',
                    content: '',
                }
            ],
        };

        setColumns(updatedColumns);
        if (onUpdate) {
            onUpdate(updatedColumns);
        }
    };

    // Add new handler for location updates
    const handleLocationEdit = (columnId, newLocation) => {
        const updatedColumns = {
            ...columns,
            [columnId]: {
                ...columns[columnId],
                location: newLocation,
            },
        };
        setColumns(updatedColumns);
        if (onUpdate) {
            onUpdate(updatedColumns);
        }
    };

    // Add this mock data for comments
    const mockComments = {
        "introduction": [
            {
                id: 1,
                user: {
                    name: "Jane Doe",
                    avatar: "/media/persona/f-1.png"
                },
                timestamp: "2024-03-20T10:30:00Z",
                content: "The challenge section really resonates with our current situation."
            },
            {
                id: 2,
                user: {
                    name: "John Smith",
                    avatar: "/media/persona/m-2.png"
                },
                timestamp: "2024-03-20T11:15:00Z",
                content: "Agreed. We should emphasize the cost implications more."
            }
        ],
        "chapter-1": [
            {
                id: 3,
                user: {
                    name: "Emily Chen",
                    avatar: "/media/persona/f-3.png"
                },
                timestamp: "2024-03-20T14:20:00Z",
                content: "The technology comparison could be more detailed here."
            }
        ],
        // Add more comments for other sections...
    };

    // Add these state variables at the top of the component
    const [showAllComments, setShowAllComments] = useState(false);
    const [newComment, setNewComment] = useState('');

    // Add this function near the top of the component, after the initial state declarations
    const getTimeAgo = (timestamp) => {
        const seconds = Math.floor((new Date() - new Date(timestamp)) / 1000);

        let interval = seconds / 31536000; // years
        if (interval > 1) return Math.floor(interval) + 'y ago';

        interval = seconds / 2592000; // months
        if (interval > 1) return Math.floor(interval) + ' mo ago';

        interval = seconds / 86400; // days
        if (interval > 1) return Math.floor(interval) + 'd ago';

        interval = seconds / 3600; // hours
        if (interval > 1) return Math.floor(interval) + 'h ago';

        interval = seconds / 60; // minutes
        if (interval > 1) return Math.floor(interval) + 'm ago';

        return Math.floor(seconds) + 's ago';
    };

    // Add this handler function
    const handleAddComment = () => {
        if (!newComment.trim()) return;

        // Here you would typically make an API call to save the comment
        // For now, we'll just log it
        //console.log('New comment:', {
        //    section: activeSection,
        //    content: newComment,
        //    timestamp: new Date().toISOString(),
        //    user: {
        //        name: 'Current User',
                //avatar: '/util/app/m-2.png'
            //}
        //});

        setNewComment('');
    };

    // Add this state near the top where other states are declared
    const [showCommentsSidebar, setShowCommentsSidebar] = useState(false);

    // Add this new component for PDF rendering
    const StoryPDF = ({ columns }) => {
        const styles = StyleSheet.create({
            page: {
                padding: 40,
                backgroundColor: '#ffffff',
                fontFamily: 'Helvetica'
            },
            section: {
                marginBottom: 40,
                borderRadius: 8
            },
            header: {
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 20
            },
            chapterNumber: {
                width: 40,
                height: 40,
                backgroundColor: '#0EA5E9',
                borderRadius: 20,
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 16
            },
            chapterNumberText: {
                color: '#ffffff',
                fontSize: 20,
                fontWeight: 'bold'
            },
            titleBlock: {
                flex: 1
            },
            title: {
                fontSize: 24,
                color: '#000000',
                marginBottom: 8,
                fontWeight: 'bold'
            },
            subtitle: {
                fontSize: 14,
                color: '#666666',
                marginBottom: 8
            },
            location: {
                fontSize: 12,
                color: '#666666',
                marginBottom: 16
            },
            talkTrack: {
                marginLeft: 56,
                marginBottom: 24,
                padding: 16,
                backgroundColor: '#f8fafc',
                borderRadius: 8
            },
            talkTrackText: {
                fontSize: 12,
                color: '#333333'
            },
            sectionHeader: {
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 16,
                marginTop: 24,
                paddingLeft: 56
            },
            personSection: {
                marginLeft: 56,
                marginBottom: 24,
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 16
            },
            personCard: {
                width: '45%',
                padding: 16,
                backgroundColor: '#f8fafc',
                borderRadius: 8,
                marginBottom: 16
            },
            personName: {
                fontSize: 16,
                color: '#0EA5E9',
                marginBottom: 8,
                fontWeight: 'bold'
            },
            personRole: {
                fontSize: 12,
                color: '#666666',
                marginBottom: 8
            },
            personActions: {
                fontSize: 13,
                color: '#333333'
            },
            valueSection: {
                marginLeft: 56,
                marginBottom: 24,
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 16
            },
            valueCard: {
                width: '45%',
                padding: 16,
                backgroundColor: 'rgba(34, 197, 94, 0.05)',
                borderRadius: 8
            },
            valueLabel: {
                fontSize: 14,
                color: '#000000',
                fontWeight: 'bold',
                marginBottom: 8
            },
            valueContent: {
                fontSize: 12,
                color: '#333333'
            },
            techSection: {
                marginLeft: 56,
                padding: 16,
                backgroundColor: 'rgba(245, 158, 11, 0.05)',
                borderRadius: 8
            },
            techName: {
                fontSize: 14,
                color: '#000000',
                fontWeight: 'bold',
                marginBottom: 8
            },
            techCapabilities: {
                fontSize: 13,
                color: '#333333',
                marginBottom: 8
            }
        });

        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    {Object.entries(columns).map(([columnId, column], index) => (
                        <View key={columnId} style={styles.section}>
                            <View style={styles.header}>
                                {columnId.startsWith('chapter-') && (
                                    <View style={styles.chapterNumber}>
                                        <Text style={styles.chapterNumberText}>{index}</Text>
                                    </View>
                                )}
                                <View style={styles.titleBlock}>
                                    <Text style={styles.title}>{column.title}</Text>
                                    {column.location && (
                                        <Text style={styles.location}>{column.location}</Text>
                                    )}
                                </View>
                            </View>

                            {column.subtitle && (
                                <View style={styles.talkTrack}>
                                    <Text style={styles.talkTrackText}>{column.subtitle}</Text>
                                </View>
                            )}

                            {/* People Section */}
                            {column.items.filter(item =>
                                item.type === 'participant' || item.type === 'person'
                            ).length > 0 && (
                                    <>
                                        <View style={styles.sectionHeader}>
                                            <Text style={styles.valueLabel}>People</Text>
                                        </View>
                                        <View style={styles.personSection}>
                                            {column.items
                                                .filter(item => item.type === 'participant' || item.type === 'person')
                                                .map((item, index) => (
                                                    <View key={index} style={styles.personCard}>
                                                        <Text style={styles.personName}>{item.content.name}</Text>
                                                        <Text style={styles.personRole}>{item.content.role}</Text>
                                                        <Text style={styles.personActions}>{item.content.actions}</Text>
                                                    </View>
                                                ))
                                            }
                                        </View>
                                    </>
                                )}

                            {/* Values Section */}
                            {column.items.filter(item =>
                                item.type === 'text' &&
                                (item.label === 'Customer Value' || item.label === 'Business Value')
                            ).length > 0 && (
                                    <View style={styles.valueSection}>
                                        {column.items
                                            .filter(item =>
                                                item.type === 'text' &&
                                                (item.label === 'Customer Value' || item.label === 'Business Value')
                                            )
                                            .map((item, index) => (
                                                <View key={index} style={styles.valueCard}>
                                                    <Text style={styles.valueLabel}>{item.label}</Text>
                                                    <Text style={styles.valueContent}>{item.content}</Text>
                                                </View>
                                            ))
                                        }
                                    </View>
                                )}

                            {/* Technology Section */}
                            {column.items.filter(item =>
                                item.type === 'text' && item.label === 'Technology'
                            ).length > 0 && (
                                    <View style={styles.techSection}>
                                        {column.items
                                            .filter(item => item.type === 'text' && item.label === 'Technology')
                                            .map((item, index) => (
                                                <View key={index}>
                                                    <Text style={styles.techName}>
                                                        {typeof item.content === 'string'
                                                            ? item.content
                                                            : item.content.devices?.[0]?.name}
                                                    </Text>
                                                    {typeof item.content !== 'string' && item.content.devices?.[0]?.capabilities && (
                                                        <Text style={styles.techCapabilities}>
                                                            {item.content.devices[0].capabilities}
                                                        </Text>
                                                    )}
                                                </View>
                                            ))
                                        }
                                    </View>
                                )}
                        </View>
                    ))}
                </Page>
            </Document>
        );
    };

    const handleExportPNG = () => {
        // Get the TabPanel that contains the Summary Story content
        const summaryStoryElement = document.querySelector('[value="business-analysis"].tab-panel');

        if (!summaryStoryElement) {
            //console.error('Could not find Summary Story content element');
            return;
        }

        // Set a temporary white background for better image quality
        const originalBackground = summaryStoryElement.style.background;
        summaryStoryElement.style.background = 'white';

        htmlToImage.toPng(summaryStoryElement)
            .then(dataUrl => {
                // Reset the background
                summaryStoryElement.style.background = originalBackground;

                // Create download link
                const link = document.createElement('a');
                link.download = `StoryPath - ${introTitle} - Summary.png`;
                link.href = dataUrl;
                link.click();
            })
            .catch(error => {
                //console.error('Error generating PNG:', error);
                summaryStoryElement.style.background = originalBackground;
            });
    };


    // const storySummaryRef = useRef(null); // Explicitly initialize with null
    // const reactToPrintFn = useReactToPrint({
    //     content: () => storySummaryRef.current,
    //     documentTitle: 'StoryPath - Summary',
    //     onAfterPrint: () => {
    //         console.log('Printing complete');
    //     }
    // });

    // Modify the BrandGenerator component or create a new PublishTab component
    const PublishTab = ({ columns, handlePrint }) => {
        const [showPDF, setShowPDF] = useState(false);


        // const storySummaryRef = useRef(null); // Explicitly initialize with null
        // const handlePrint = useReactToPrint({
        //     content: () => storySummaryRef.current,
        //     documentTitle: 'StoryPath - Summary',
        //     onAfterPrint: () => {
        //         console.log('Printing complete');
        //     }
        // });

        // Get the title and customer from the introduction section
        const introTitle = columns?.introduction?.title || 'Untitled';
        const customer = columns?.introduction?.items?.find(item =>
            item.type === 'text' && item.label === 'Summary'
        )?.content?.split(' - ')?.[0] || 'Customer';

        // Format filename
        const filename = `StoryPath - ${introTitle}.pdf`;

        return (
            <div className="p-4">
                <div className="flex gap-4">
                    <button
                        onClick={() => setShowPDF(!showPDF)}
                        className="bg-sky-500 hover:bg-sky-600 text-white px-4 py-2 rounded-lg transition-colors flex items-center gap-2"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clipRule="evenodd" />
                        </svg>
                        {showPDF ? 'Hide PDF Preview' : 'Preview PDF'}
                    </button>







                    {/* <PDFDownloadLink
                        document={<StoryPDF columns={columns} />}
                        fileName={filename}
                        className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg transition-colors flex items-center gap-2"
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? (
                                <>
                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span>Preparing Download...</span>
                                </>
                            ) : (
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span>Download PDF</span>
                                </>
                            )
                        }
                    </PDFDownloadLink> */}

                </div>

                {/* {showPDF && (
                    <div className="mt-4" style={{ height: 'calc(100vh - 200px)' }}>
                        <PDFViewer style={{ width: '100%', height: '100%' }}>
                            <StoryPDF columns={columns} />
                        </PDFViewer>
                    </div>
                )} */}
            </div>
        );
    };

    const tabs = [
        {
            value: "business-analysis",
            label: "Summary Story",
            icon: <img src="/util/app/b3-admin-path-value2.svg" className="w-5 h-5" />,
            content: (

                <PathProductBusinessAnalysis
                    producedResult={producedResult}
                    businessAnalysisData={businessAnalysisData}
                    storySpeakerSummaryResult={storySpeakerSummaryResult}
                    salesAnalysisData={salesAnalysisData}
                    businessTransformationResult={businessTransformationResult}
                    formData={formData}
                //handlePrint={handlePrint}
                />


            )
        },
        {
            value: "from-to",
            label: "From - To Transform Story",
            icon: <img src="/util/app/b3-admin-path-value2.svg" className="w-5 h-5" />,
            content: <PathProductBusinessTransformation businessAnalysisData={businessAnalysisData} storySpeakerSummaryResult={storySpeakerSummaryResult} producedResult={producedResult} salesAnalysisData={salesAnalysisData} businessTransformationResult={businessTransformationResult} generateStoryBite={generateStoryBite} />
        },


        {
            value: "story-content",
            label: "Day-to-Day Story",
            icon: <img src="/util/app/b3-admin-story-5.svg" className="w-5 h-5" />,
            content: (
                <div className='flex h-full '>

                    {/* First child - Navigation sidebar */}
                    <div className="w-2/12 h-full bg-zinc-900 p-4 flex flex-col mt-12">
                        <nav className="flex flex-col md:grid grid-cols-12 text-zinc-50 ">
                            {Object.entries(columns).map(([columnId, column], index) => (
                                <div key={columnId} className="flex md:contents">
                                    <div className="col-start-1 col-end-2  -ml-2 h-24  relative">
                                        <div className="h-full w-8 flex items-center justify-center">
                                            {index !== Object.keys(columns).length - 1 && (
                                                <div className={`h-full w-0.5 ${activeSection === columnId - 1
                                                    ? 'bg-sky-500'
                                                    : index < Object.keys(columns).indexOf(activeSection)
                                                        ? 'bg-sky-500'
                                                        : 'bg-zinc-700'
                                                    } pointer-events-none ${index === 0 ? 'mt-12' : ''}`}
                                                ></div>
                                            )}
                                        </div>
                                        <div className={`w-8 h-8 absolute top-0 rounded-full ${activeSection === columnId
                                            ? 'text-zinc-50 border-sky-600 bg-sky-900 border-2 '
                                            : index < Object.keys(columns).indexOf(activeSection)
                                                ? 'text-zinc-50 border-sky-600 bg-sky-900 border-2 '
                                                : 'bg-zinc-700'
                                            } shadow text-center flex items-center justify-center`}>
                                            <span className="text-xs text-zinc-50">{index + 1}</span>
                                        </div>
                                    </div>
                                    <Link
                                        to={columnId}
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        containerId="storyScrollContainer"
                                        className={`col-start-3 col-end-12  cursor-pointer transition-colors duration-200 ${activeSection === columnId
                                            ? "text-zinc-50"
                                            : "text-zinc-500 hover:text-zinc-200"
                                            }`}
                                    >
                                        {column.title}
                                    </Link>
                                </div>
                            ))}
                        </nav>
                    </div>

                    {/* Second child - Main content */}
                    <div
                        id="storyScrollContainer"
                        className={`${showCommentsSidebar ? 'w-8/12' : 'w-10/12'} h-full bg-black/20 overflow-y-auto main-content-scroll transition-all duration-300 px-4 print:overflow-visible print:bg-black/0  print:w-full print:px-24`}
                        //ref={storySummaryRef}
                        ref={storyDayToDayRef}
                    >
                        <div className="p-4">
                            <div className='bg-zinc-900 absolute -top-1 right-4  pr-4  p-2 transition-colors flex items-center gap-2 print:hidden'>
                                <button
                                    onClick={handlePrint}
                                    className="bg-zinc-900  hover:bg-zinc-600 text-white px-2 py-1 rounded-md transition-colors flex items-center gap-2 print:hidden"
                                >
                                    <img src={'/util/app/b3-admin-export.svg'} className='h-5 w-5' />
                                    <span>export</span>
                                </button>
                            </div>

                            <CompPrintCover formData={formData} coverType={'day-to-day'} />


                            {Object.entries(columns).map(([columnId, column], index) => (
                                <React.Fragment key={columnId}>
                                    <Element
                                        name={columnId}
                                        id={columnId}
                                        className={index === 0 ? "pt-0" : "pt-16"}
                                    >
                                        <div className="  rounded-lg p-2 overflow-hidden print:break-after-auto ">

                                            <div className='flex justify-between'>
                                                <div className='flex  '>
                                                    {/* <img src="/util/app/b3-admin-talk-track.svg" className='h-8 inline mr-4' /> */}
                                                    <div className='text-zinc-50 border-sky-600 bg-sky-900 border-2 text-xl w-10 h-10 focus:bg-zinc-50/5 px-2 py-1 rounded-full mt-5 mr-4 -ml-2 text-center flex items-center justify-center'>
                                                        {index + 1}
                                                    </div>
                                                </div>

                                                <div className=" mb-4   -mt-3 pt-8 -ml-4 p-2 w-[calc(100%+2rem)]">
                                                    <div className=" ">
                                                        <div className="flex justify-between items-start">
                                                            <h3
                                                                className="text-zinc-50 text-2xl mb-2  hoverx:bg-zinc-50/5 focus:bg-zinc-50/5 px-2 py-1 rounded cursor-text"
                                                                contentEditable
                                                                suppressContentEditableWarning
                                                                onBlur={(e) => handleTitleEdit(columnId, e.target.textContent)}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        e.preventDefault();
                                                                        e.target.blur();
                                                                    }
                                                                }}
                                                            >
                                                                {column.title}
                                                            </h3>
                                                            {column.location && (
                                                                <div
                                                                    className="flex items-center justify-endx"
                                                                    contentEditable
                                                                    suppressContentEditableWarning
                                                                    onBlur={(e) => handleLocationEdit(columnId, e.target.textContent)}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            e.preventDefault();
                                                                            e.target.blur();
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className="bg-sky-500/10 font-semibold hover:bg-zinc-50/5 focus:bg-zinc-50/5 px-3 py-2 -mr-2 rounded-full text-sm text-sky-600">
                                                                        {column.location}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {index != 0 ?
                                                        <p className="text-zinc-100 text-md mb-4 w-full hover:bg-zinc-50/5 focus:bg-zinc-50/5 px-2 py-1 rounded cursor-text">
                                                            {column.talkTrack}
                                                        </p>
                                                        :
                                                        <>
                                                            <p className="text-zinc-100 text-md mb-4 leading-8 w-full hover:bg-zinc-50/5 focus:bg-zinc-50/5 px-2 py-1 rounded cursor-text">
                                                                {storySpeakerSummaryResult.opening}
                                                            </p>
                                                            <p className="text-zinc-100 text-md leading-8 mb-4 w-full hover:bg-zinc-50/5 focus:bg-zinc-50/5 px-2 py-1 rounded cursor-text">
                                                                {storySpeakerSummaryResult.middle}
                                                            </p>
                                                        </>
                                                    }


                                                    { Object.keys(columns).length - 1 === index ?

                                                        <p className="text-zinc-100 text-md leading-8 mb-4 w-full hover:bg-zinc-50/5 focus:bg-zinc-50/5 px-2 py-1 rounded cursor-text">
                                                            {storySpeakerSummaryResult.closing}
                                                        </p>
                                                        : null
                                                    }

                                                </div>
                                            </div>
                                            {/* {column.items
                                            .filter(item => 
                                                item.label == 'Description'
                                            )
                                            .map(item => (
                                                <div key={item.id} className="space-y-4">
                                                    {renderItem(item, columnId)}
                                                </div>
                                            ))} */}
                                            {column.subtitle && (
                                                <div className="w-full flex pl-10 print:pl-10  ">
                                                    {/* <img src="/util/app/b3-admin-talk-track-present-b.svg" className='h-8 inline mr-4 mt-2' /> */}
                                                    <div
                                                        className="text-zinc-200 text-base/8 mb-4 w-full hover:bg-zinc-50/5 focus:bg-zinc-50/5 px-2 py-1 rounded cursor-text"
                                                        contentEditable
                                                        suppressContentEditableWarning
                                                        onBlur={(e) => handleSubtitleEdit(columnId, e.target.textContent)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                                e.target.blur();
                                                            }
                                                        }}
                                                    >
                                                        {column.subtitle}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="space-y-4  -ml-2">

                                                {/* <img src="/util/app/b3-admin-path-people.svg" className="h-8 opacity-80 mr-4" /> */}
                                                {/* First render participants/persons in a grid */}



                                                {columnId !== 'conclusion' && index !== 0 && (

                                                    <div className='w-full flex pt-12'>
                                                        <img src="/util/app/b3-admin-path-people.svg" className="h-8 opacity-80 mr-4 self-center" />

                                                        {/* <div className="flex items-center mb-4">                                                    
                                                    <h4 className="text-sky-400 text-lg">People</h4>
                                                </div> */}
                                                        {column.items.some(item => item.type === 'participant' || item.type === 'person') && (

                                                            <div className="flex-grow grid grid-cols-3 gap-4">
                                                                {column.items
                                                                    .filter(item => item.type === 'participant' || item.type === 'person')
                                                                    .map(item => renderItem(item, columnId))}
                                                            </div>


                                                        )}
                                                        <div className='flex justify-center ml-6 print:hidden'>
                                                            {/* <button
                                                                type="button"
                                                                onClick={() => addNewItem(columnId, 'person', 'Key Person')}
                                                                className="flex items-center h-10 w-10 justify-center mx-auto p-3 rounded-md border border-dashed border-sky-500/50 text-sky-500 hover:bg-sky-500/10 transition-colors self-center print:hidden"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                                                </svg>
                                                            </button> */}
                                                        </div>
                                                    </div>
                                                )}


                                                {/* Only show add button if not in Conclusion section */}
                                                {columnId !== 'conclusion' &&
                                                    index !== 0 && (
                                                        <div className='w-full flex pt-0'>
                                                            <img src="/util/app/b3-admin-path-value2.svg" className="h-8 opacity-80 mr-4 self-center" />
                                                            {column.items.some(item => item.type === 'text' && (item.label === 'Customer Value' || item.label === 'Business Value')) && (
                                                                <div>
                                                                    <div className="flex-grow grid grid-cols-3 gap-4">
                                                                        {column.items
                                                                            .filter(item => item.type === 'text' && (item.label === 'Customer Value' || item.label === 'Business Value'))
                                                                            .map(item => renderItem(item, columnId))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className='flex justify-center ml-6 print:hidden'>
                                                                {/* <button
                                                                    type="button"
                                                                    onClick={() => addNewItem(columnId, 'text', 'Customer Value')}
                                                                    className="flex items-center h-10 w-10 justify-center mx-auto p-3 rounded-md border border-dashed border-green-500/50 text-green-500 hover:bg-green-500/10 transition-colors self-center print:hidden"
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                                                    </svg>
                                                                </button> */}
                                                            </div>
                                                        </div>
                                                    )}

                                                {columnId !== 'conclusion' && index !== 0 && (
                                                    <div className='w-full flex pt-0'>
                                                        <img src="/util/app/b3-admin-path-tech.svg" className="h-8 opacity-80 mr-4 self-center" />

                                                        {/* Render technology items */}
                                                        {column.items.some(item => item.type === 'technology') && (

                                                            <div className="space-y-4 w-full">
                                                                <div className="flex-grow grid grid-cols-3 gap-4">
                                                                    {column.items
                                                                        .filter(item => item.type === 'technology')
                                                                        .map(item => renderItem(item, columnId))}
                                                                </div>
                                                            </div>


                                                        )}
                                                        <div className='flex justify-center ml-6 print:hidden'>
                                                            {/* <button
                                                                type="button"
                                                                onClick={() => addNewItem(columnId, 'text', 'Technology')}
                                                                className="flex items-center h-10 w-10 justify-center mx-auto p-3 rounded-md border border-dashed border-amber-500/50 text-amber-500 hover:bg-amber-500/10 transition-colors self-center print:hidden"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                                                </svg>
                                                            </button> */}
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Then render all other items */}
                                                {
                                                    column.items
                                                        .filter(item =>
                                                            item.type !== 'participant' &&
                                                            item.type !== 'person' &&
                                                            item.label !== 'Description' &&
                                                            item.label !== 'Technology' &&
                                                            !(item.type === 'text' && (item.label === 'Customer Value' || item.label === 'Business Value'))
                                                        )
                                                        .map(item => {
                                                            if (item.type === 'outcome' && !renderedOutcomes) {
                                                                // Set flag to prevent rendering outcomes again
                                                                renderedOutcomes = true;
                                                                return (
                                                                    <div key={item.id} >
                                                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-12">
                                                                            {column.items
                                                                                .filter(item => item.type === 'outcome')
                                                                                .map(item => renderItem(item, columnId))}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                            if (item.type !== 'outcome') {
                                                                return (
                                                                    <div key={item.id} className="space-y-4">
                                                                        {renderItem(item, columnId)}
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        })}

                                            </div>
                                        </div>
                                    </Element>
                                    {/* Add Scene button after each chapter, but not after introduction or conclusion */}
                                    {/* {
                                        columnId.startsWith('chapter-') && (
                                            <button
                                                type="button"
                                                onClick={() => addNewScene(columnId)}
                                                className="mt-8 flex items-center justify-center mx-auto p-3 rounded-lg border border-dashed border-sky-500/50 text-sky-500 hover:bg-sky-500/10 transition-colors print:hidden"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                                </svg>
                                                <span className="ml-2">Scene</span>
                                            </button>
                                        )
                                    } */}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>

                    {/* Third child - Comments sidebar */}
                    < div className={`${showCommentsSidebar ? 'w-2/12' : 'w-0'} h-full bg-zinc-900 transition-all duration-300 relative`}>
                        {/* Toggle button - positioned absolutely */}
                        < button
                            onClick={() => setShowCommentsSidebar(!showCommentsSidebar)}
                            className="absolute -left-8 top-0 bg-zinc-900 text-zinc-400 hover:text-zinc-200 p-3 rounded-l-lg shadow-lg transition-colors"
                            title={showCommentsSidebar ? "Hide Comments" : "Show Comments"}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                {showCommentsSidebar ? (
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                ) : (
                                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                )}
                            </svg>
                        </button >

                        {/* Comments content - only render when sidebar is shown */}
                        {
                            showCommentsSidebar && (
                                <div className="pl-4 py-4 flex flex-col h-full">
                                    <div className="flex items-center justify-between mb-4">
                                        <h3 className="text-zinc-50 text-base -mt-4 font-base">Comments</h3>
                                        <div className="flex items-center gap-2">
                                            <button
                                                onClick={() => setShowAllComments(!showAllComments)}
                                                className={`px-2 py-1 rounded text-sm ${showAllComments
                                                    ? 'bg-sky-500 text-white'
                                                    : 'bg-zinc-800 text-zinc-400'
                                                    }`}
                                            >
                                                {showAllComments ? 'All' : 'Current'}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="flex-1 overflow-y-auto mb-4">
                                        <div className="space-y-4">
                                            {(showAllComments ? Object.values(mockComments).flat() : (mockComments[activeSection] || []))
                                                .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                                                .map(comment => (
                                                    <div key={comment.id} className="bg-zinc-800/50 rounded-lg p-3">
                                                        <div className="flex items-center gap-2 mb-2">
                                                            <img
                                                                src={comment.user.avatar}
                                                                alt={comment.user.name}
                                                                className="w-8 h-8 rounded-sm bg-zinc-100"
                                                            />
                                                            <span className="text-zinc-300 text-sm">{comment.user.name}</span>
                                                            <span className="text-zinc-500 text-xs ml-auto">
                                                                {getTimeAgo(comment.timestamp)}
                                                            </span>
                                                        </div>
                                                        <p className="text-zinc-300 mt-4 text-sm">{comment.content}</p>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>

                                    <div className="  pt-4">
                                        <div className="relative">
                                            <textarea
                                                value={newComment}
                                                onChange={(e) => setNewComment(e.target.value)}
                                                placeholder="comment..."
                                                className="w-full bg-zinc-800 border border-zinc-700 text-zinc-200 rounded-lg pl-3 pr-10 py-2 text-sm resize-none focus:border-sky-400 focus:outline-none transition-colors"
                                                rows={3}
                                            />
                                            {/* <button
                                        onClick={handleAddComment}
                                        className="absolute right-2 bottom-2 text-sky-500 hover:text-sky-400"
                                        disabled={!newComment.trim()}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                        </svg>
                                    </button> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div >
                </div >
            )
        },
        {
            value: "roadblocks",
            label: "Roadblocks",
            icon: <img src="/util/app/b3-admin-path-value2.svg" className="w-5 h-5" />,
            content: <PathProductRoadblocks businessAnalysisData={businessAnalysisData} storySpeakerSummaryResult={storySpeakerSummaryResult} producedResult={producedResult} salesAnalysisData={salesAnalysisData} businessTransformationResult={businessTransformationResult} />
        },

        {
            value: "COI",
            label: "Cost of Inaction",
            icon: <img src="/util/app/b3-admin-path-value2.svg" className="w-5 h-5" />,
            content: <PathProductBusinessCostOfInaction businessAnalysisData={businessAnalysisData} storySpeakerSummaryResult={storySpeakerSummaryResult} producedResult={producedResult} salesAnalysisData={salesAnalysisData} businessTransformationResult={businessTransformationResult} generateStoryBite={generateStoryBite} businessCostOfInactionResult={businessCostOfInactionResult} />
        },        
        // {
        //     value: "experience-creator",
        //         label: "Export",
        //             icon: <img src="/util/app/b3-admin-active-5.svg" className="w-5 h-5" />,
        //                 content: <GoogleAuthSlides businessCostOfInactionResult={businessCostOfInactionResult} />
        // }
    ];

    const [activeTab, setActiveTab] = useState("business-analysis");

    return (
        <div className="h-screen overflow-hidden -mt-8">



            <Tabs value={activeTab} className="h-full mr-14">
                <TabsHeader
                    className="rounded-none border-b border-zinc-800 bg-transparent"
                    indicatorProps={{
                        className: "bg-transparent border-b-4 border-sky-500 shadow-none rounded-none",
                    }}
                >
                    <img src="/util/app/sp-test-logo-2.png" className="h-8 ml-8 mr-24  inline-block mt-6" />
                    {tabs.map(({ label, value, icon, index }) => (
                        <Tab
                            key={value}
                            value={value}
                            onClick={() => setActiveTab(value)}
                            className={`px-8 py-4 text-base mt-2 transition-colors 
                                cursor-pointer
                                duration-200
                                ${activeTab === value
                                    ? "text-zinc-50"
                                    : "text-zinc-400 hover:text-zinc-200"
                                }`}
                        >
                            <div className="flex items-center gap-2">
                                <div className={`transition-opacity duration-200 
                                    ${activeTab === value ? "opacity-100" : "opacity-50"}`}
                                >
                                    {/* {icon}*/}

                                </div>
                                {label}
                            </div>
                        </Tab>
                    ))}
                </TabsHeader>

                <TabsBody className="h-[calc(100%-150px)]">
                    {tabs.map(({ value, content }) => (
                        <TabPanel key={value} value={value} className="p-0 h-full">
                            {content}
                        </TabPanel>
                    ))}
                </TabsBody>
            </Tabs>

        </div>
    );
};

export default KanbanBoard; 