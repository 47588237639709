import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Menu } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { doc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { UserAuth } from '../context/AuthContext';
//import { useAuth } from '../AuthContext';
import { logCustomEvent } from './UtilAnalytics';
//import { RotateLoader } from 'react-spinners';


const SpeakIt = ({ defaultVoiceId, textToSpeak, title = 'Speech' }) => {
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(defaultVoiceId || '');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audio, setAudio] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const { user } = UserAuth(); // Changed from useAuth to UserAuth

  const ElevenLabsAPIKey = 'sk_f8d3797facf090b36877ce8b64da0a567b894125d3a71931';

  // Fetch available voices on component mount
  useEffect(() => {
    const fetchVoices = async () => {
      try {
        const response = await axios.get('https://api.elevenlabs.io/v1/voices', {
          headers: {
            'xi-api-key': ElevenLabsAPIKey
          }
        });
        setVoices(response.data.voices);
        
        // Set default voice if provided and exists in the list
        if (!defaultVoiceId && response.data.voices.length > 0) {
          setSelectedVoice(response.data.voices[0].voice_id);
        }
      } catch (error) {
        console.error('Error fetching voices:', error);
      }
    };

    fetchVoices();
  }, [ElevenLabsAPIKey, defaultVoiceId]);

  // Save voice preference to Firebase
  const saveVoicePreference = async (voiceId) => {
    if (!user?.uid) return;

    logCustomEvent('ai_speakit_voice_selected', {
      voiceId: voiceId
    });
    
    try {
      const userDocRef = doc(db, 'users', user.uid);
      
      // Update the prefs field in the user's document
      await updateDoc(userDocRef, {
        'prefs.selectedVoice': voiceId
      });
      
      //console.log('Voice preference saved successfully');
    } catch (error) {
      console.error('Error saving voice preference:', error);
    }
  };

  // Load saved voice preference from Firebase
  useEffect(() => {
    const loadVoicePreference = async () => {
      if (!user?.uid) return;
      
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const savedVoice = userData?.prefs?.selectedVoice;
          
          if (savedVoice) {
            setSelectedVoice(savedVoice);
          }
        }
      } catch (error) {
        console.error('Error loading voice preference:', error);
      }
    };
    
    loadVoicePreference();
  }, [user]);

  // Subscribe to real-time updates for voice preference changes
  useEffect(() => {
    if (!user?.uid) return;
    
    // Create a reference to the user's document
    const userDocRef = doc(db, 'users', user.uid);
    
    // Set up a real-time listener
    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        const savedVoice = userData?.prefs?.selectedVoice;
        
        if (savedVoice && savedVoice !== selectedVoice) {
          //console.log('Voice preference updated from another instance:', savedVoice);
          setSelectedVoice(savedVoice);
        }
      }
    }, (error) => {
      console.error('Error listening to voice preference changes:', error);
    });
    
    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [user, selectedVoice]);

  // Play the generated audio
  const playAudio = async () => {
    if (!selectedVoice || !textToSpeak) return;


      logCustomEvent('ai_speakit_play_audio', {
        voiceId: selectedVoice,
    });
    
    try {
      setIsLoading(true);
      
      // Stop any existing audio
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
      
      // Generate new audio from the API
      const response = await axios.post(
        `https://api.elevenlabs.io/v1/text-to-speech/${selectedVoice}`,
        {
          text: textToSpeak,
          //model_id: 'eleven_monolingual_v1',
          model_id: 'eleven_flash_v2_5',
          voice_settings: {
            stability: 0.5,
            similarity_boost: 0.5
          }
        },
        {
          headers: {
            'xi-api-key': ElevenLabsAPIKey,
            'Content-Type': 'application/json'
          },
          responseType: 'blob'
        }
      );
      
      // Create audio element and play
      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const audioUrl = URL.createObjectURL(audioBlob);
      const newAudio = new Audio(audioUrl);
      
      setAudio(newAudio);
      setIsPlaying(true);
      
      newAudio.play();
      
      newAudio.onended = () => {
        setIsPlaying(false);
      };
      
    } catch (error) {
      console.error('Error generating speech:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Stop the audio playback
  const stopAudio = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(false);
    }
  };

  // Download the audio file
  const downloadAudio = async () => {
    if (!selectedVoice || !textToSpeak) return;

    logCustomEvent('ai_speakit_download_audio', {
      voiceId: selectedVoice,
    });
    
    try {
      setIsDownloading(true);
      
      // Generate audio from the API
      const response = await axios.post(
        `https://api.elevenlabs.io/v1/text-to-speech/${selectedVoice}`,
        {
          text: textToSpeak,
          //model_id: 'eleven_monolingual_v1',
          model_id: 'eleven_flash_v2_5',
          voice_settings: {
            stability: 0.5,
            similarity_boost: 0.5
          }
        },
        {
          headers: {
            'xi-api-key': ElevenLabsAPIKey,
            'Content-Type': 'application/json'
          },
          responseType: 'blob'
        }
      );
      
      // Create download link
      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const audioUrl = URL.createObjectURL(audioBlob);
      
      // Format the title for the filename
      const formattedTitle = title.replace(/\s+/g, '_');
      const filename = `StoryPath_${formattedTitle}.mp3`;
      
      // Create a download link and trigger download
      const downloadLink = document.createElement('a');
      downloadLink.href = audioUrl;
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      
    } catch (error) {
      console.error('Error downloading speech:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  // Find the name of the selected voice
  const selectedVoiceName = voices.find(v => v.voice_id === selectedVoice)?.name || 'Select a voice';

  // Modified function to handle voice selection and save to Firebase
  const handleVoiceChange = (voiceId) => {
    setSelectedVoice(voiceId);
    saveVoicePreference(voiceId);
  };

  return (
    <div className="px-4 w-full mx-auto my-6 bg-black/20 rounded-lg border border-zinc-700 shadow-lg p-2 print:hidden">
      {/* Restructured layout - flex container for voice dropdown and play button */}
      <div className="flex items-center gap-4">
        {/* PathCast Label */}
        <div className="text-zinc-100 flex-shrink-0 hidden sm:block mr-2">
          <img src="/util/app/b3-admin-podcast-a.svg" alt="PathCast" className="w-6 h-6 mr-2 inline-block" />
          PathCast <span className="text-xs pl-2 text-amber-200"></span>
        </div>
        
        {/* Voice dropdown */}
        <div className="relative flex-grow">
          <Menu as="div" className="relative inline-block text-left w-full">
            <Menu.Button 
              as={motion.button}
              whileTap={{ scale: 0.98 }}
              className="w-full flex items-center justify-between px-4 py-2 bg-zinc-800 text-zinc-200 rounded-md border border-zinc-700 focus:outline-none focus:ring-2 focus:ring-sky-500"
              disabled={isLoading || isPlaying}
            >
              <span className="truncate">{selectedVoiceName}</span>
              <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" />
            </Menu.Button>
            
            <AnimatePresence>
              <Menu.Items 
                as={motion.div}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                className="absolute z-10 w-full mt-1 origin-top-right bg-zinc-800 divide-y divide-zinc-700 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-y-auto"
              >
                <div className="py-1">
                  {voices.map((voice) => (
                    <Menu.Item key={voice.voice_id}>
                      {({ active }) => (
                        <button
                          onClick={() => handleVoiceChange(voice.voice_id)}
                          className={`${
                            active ? 'bg-zinc-700 text-zinc-100' : 'text-zinc-300'
                          } ${
                            selectedVoice === voice.voice_id ? 'bg-blue-900/40' : ''
                          } group flex items-center w-full px-3 py-2 text-sm`}
                        >
                          <div className="w-6 h-6 rounded-full bg-blue-600 flex items-center justify-center text-white font-bold mr-2 text-xs">
                            {voice.name.charAt(0)}
                          </div>
                          <div className="text-left">
                            <p className="font-medium truncate">{voice.name}</p>
                            {voice.labels?.accent && (
                              <p className="text-xs text-zinc-400">{voice.labels.accent}</p>
                            )}
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </AnimatePresence>
          </Menu>
        </div>
        
        {/* Play/Stop button moved to the right */}
        <motion.button
          onClick={isPlaying ? stopAudio : playAudio}
          className="min-w-[32px] h-[32px] rounded-md flex-shrink-0 flex items-center justify-center bg-sky-500 hover:bg-sky-400 text-white transition-colors focus:outline-none  "
          disabled={isLoading || !selectedVoice || !textToSpeak}
          whileTap={{ scale: 0.95 }}
        >
          <AnimatePresence mode="wait">
            {isLoading ? (
              <motion.div
              key="loader"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="w-6 h-6 flex items-center justify-center"
            >
              <svg className="h-5 w-5 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span className="sr-only">Loading...</span>
            </motion.div>
            ) : isPlaying ? (
              <motion.svg
                key="stop"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 002 0V8a1 1 0 00-1-1zm4 0a1 1 0 00-1 1v4a1 1 0 002 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
              </motion.svg>
            ) : (
              <motion.svg
                key="play"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
              </motion.svg>
            )}
          </AnimatePresence>
        </motion.button>
        
        {/* Download button - new */}
        <motion.button
          onClick={downloadAudio}
          className="min-w-[32px] h-[32px] rounded-md flex-shrink-0 flex items-center justify-center  bg-sky-500 hover:bg-sky-400 text-white transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-zinc-900"
          disabled={isDownloading || !selectedVoice || !textToSpeak}
          whileTap={{ scale: 0.95 }}
          title="Download audio file"
        >
          <AnimatePresence mode="wait">
            {isDownloading ? (
              <motion.div
              key="loader"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="w-6 h-6 flex items-center justify-center"
            >
              <svg className="h-5 w-5 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span className="sr-only">Loading...</span>
            </motion.div>
            ) : (
              <motion.svg
                key="download"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
              </motion.svg>
            )}
          </AnimatePresence>
        </motion.button>
      </div>
      
      {/* Optional: Hidden text preview */}
      <div className="sr-only">
        <div className="text-xs text-zinc-400 mt-2">
          {textToSpeak || "No text provided"}
        </div>
      </div>
    </div>
  );
};

export default SpeakIt;