import React from 'react';

const PathProductRoadblocks = ({ businessAnalysisData, storySpeakerSummaryResult, producedResult, salesAnalysisData, businessTransformationResult }) => {
    // console.log('PRODUCED RESULT ///////')
    // console.log(producedResult);
    // console.log('PRODUCED RESULT ///////')
    if (!businessAnalysisData) return null;

    return (
        <div className="mt-8 space-y-6 overflow-y-auto h-[calc(100vh-8rem)] pr-2">

            {businessAnalysisData.implementation && businessAnalysisData.implementation.length > 0 && (
                <div className="  rounded p-4">
                    <div className="grid grid-cols-3 w-full items-top gap-4">
                        <div className=" p-4">
                            {businessAnalysisData.risks && businessAnalysisData.risks.length > 0 && (
                                <div className="rounded    rounded-lg  ">
                                    <h3 className="text-sky-400 font-medium text-xl mb-6">Risks and Mitigation</h3>
                                    <div className="space-y-3">
                                        {businessAnalysisData.risks.map((item, idx) => (
                                            <div key={idx} className="py-3 rounded h-full">
                                                <div className="grid grid-cols-[24px_1fr] gap-4 items-start mb-2">
                                                    <img src={'/util/app/b3-admin-risk.svg'} className='w-6 h-6' />
                                                    <p className="text-zinc-300 font-medium">{item.risk}</p>
                                                </div>
                                                <div className="grid grid-cols-[24px_1fr] gap-4 mt-4 items-start">
                                                    <img src={'/util/app/b3-admin-mitigation.svg'} className='w-6 h-6 mt-0.5' />
                                                    <p className="text-zinc-400">{item.mitigation}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <div className=" px-4 pl-7 pb-12">
                            <h3 className="text-sky-400 font-medium text-xl mt-4 mb-8">Implementation Steps</h3>
                            <ul className="list-disc pl-4 space-y-4">
                                {businessAnalysisData.implementation.map((step, idx) => (
                                    <li key={idx} className="text-zinc-200">{step}</li>
                                ))}
                            </ul>

                            <h3 className="text-sky-400 font-medium text-xl mt-12 mb-8">Required Resources</h3>
                            {businessAnalysisData.resources && businessAnalysisData.resources.length > 0 && (
                                <ul className="list-disc pl-4 space-y-4">
                                    {businessAnalysisData.resources.map((resource, idx) => (
                                        <li key={idx} className="text-zinc-200">{resource}</li>
                                    ))}
                                </ul>
                            )}

                            <h3 className="text-sky-400 font-medium text-xl mt-12 mb-8">Change Management Strategy</h3>
                            {businessAnalysisData.changeManagement && businessAnalysisData.changeManagement.length > 0 && (
                                <ul className="list-disc pl-4 space-y-4">
                                    {businessAnalysisData.changeManagement.map((strategy, idx) => (
                                        <li key={idx} className="text-zinc-200">{strategy}</li>
                                    ))}
                                </ul>
                            )}
                        </div> */}
                        <div className="px-4  pl-12">
                            <h3 className="text-sky-400 font-medium text-xl mt-4 mb-8">Timeline</h3>
                            <div className=" ">
                                {businessAnalysisData.timeline.map((item, idx) => (
                                    <div key={idx} className="flex">
                                        <div className="flex flex-col items-center mr-4">
                                            <div>
                                                <div className="flex items-center justify-center w-10 h-10 border border-sky-400 rounded-full">
                                                    <span className="text-sky-400 text-sm font-medium">
                                                        {idx + 1}
                                                    </span>
                                                </div>
                                            </div>
                                            {idx !== businessAnalysisData.timeline.length - 1 && (
                                                <div className="w-px h-full bg-sky-400/20"></div>
                                            )}
                                        </div>
                                        <div className="pt-1 pb-8">
                                            <p className="mb-2 text-lg font-bold text-zinc-100">
                                                {item.milestone}
                                            </p>
                                            <p className="text-zinc-400">
                                                {item.duration}
                                            </p>
                                            {item.description && (
                                                <p className="mt-2 text-zinc-300">
                                                    {item.description}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PathProductRoadblocks;