import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

const RagDemo = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);
  
  const functions = getFunctions();
  if (process.env.NODE_ENV === 'development') {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }

  const ragCreateIndex = httpsCallable(functions, 'ragCreateIndex');
  const ragGenerate = httpsCallable(functions, 'ragGenerate');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setError('');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      setFileName(droppedFile.name);
      setError('');
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file to upload');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      // Read file as text
      const fileContent = await readFileAsText(file);
      
      // Call the Cloud Function
      const result = await ragCreateIndex({
        fileContent,
        fileName: fileName,
        metadata: { scope: "user-docs" }
      });
      
      setUploadResponse(result.data);
      setIsUploaded(true);
      
    } catch (err) {
      console.error('Error uploading document:', err);
      setError(`Error uploading document: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const readFileAsText = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(new Error('Failed to read file'));
      reader.readAsText(file);
    });
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    if (!question.trim()) return;

    setIsLoading(true);
    setError('');

    try {
      const result = await ragGenerate({
        query: question,
        filter: { scope: "user-docs" },
        rerank: true
      });
      
      setAnswer(result.data.answer || result.data.text || JSON.stringify(result.data));
    } catch (err) {
      console.error('Error generating answer:', err);
      setError(`Error generating answer: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setFile(null);
    setFileName('');
    setIsUploaded(false);
    setUploadResponse(null);
    setQuestion('');
    setAnswer('');
    setError('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="min-h-screen bg-zinc-900 text-white p-4 md:p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-2xl md:text-3xl font-bold mb-8 text-zinc-100">Document Q&A</h1>
        
        <div className="bg-black/20 rounded-md overflow-hidden flex flex-col">
          <div className="p-6 flex flex-col h-full">
            <h2 className="text-xl text-zinc-100 font-semibold mb-6">Upload & Analyze Your Documents</h2>
            
            {!isUploaded ? (
              <div className="flex flex-col space-y-6">
                {/* File Upload Area */}
                <motion.div 
                  className="border-2 border-dashed border-sky-400/50 bg-sky-900/10 rounded-lg p-8 text-center"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                >
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="hidden"
                    ref={fileInputRef}
                    id="document-upload"
                  />
                  <div className="flex flex-col items-center justify-center space-y-4">
                    <div className="w-12 h-12 bg-sky-900/20 rounded-full flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-sky-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                      </svg>
                    </div>
                    <label 
                      htmlFor="document-upload" 
                      className="text-zinc-100 cursor-pointer hover:text-sky-300 transition-colors"
                    >
                      {fileName ? fileName : "Click to select or drop a document here"}
                    </label>
                    <p className="text-zinc-400 text-sm">
                      PDF, TXT, DOCX, or other text documents
                    </p>
                  </div>
                </motion.div>
                
                {/* Upload Button */}
                <motion.button
                  onClick={handleUpload}
                  disabled={!file || isLoading}
                  className={`bg-sky-900/80 border-l-2 border-sky-400 text-sky-100 py-3 px-6 rounded-md self-start
                    ${!file || isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-sky-800 hover:text-white'}`}
                  whileHover={{ scale: file && !isLoading ? 1.03 : 1 }}
                  whileTap={{ scale: file && !isLoading ? 0.98 : 1 }}
                >
                  {isLoading ? 'Uploading...' : 'Upload Document'}
                </motion.button>
              </div>
            ) : (
              <motion.div 
                className="flex flex-col space-y-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                {/* Success Message */}
                <div className="bg-green-900/20 border-l-2 border-green-400 p-4 rounded-md">
                  <p className="text-green-300">
                    Document uploaded successfully! You can now ask questions about it.
                  </p>
                </div>
                
                {/* Question Form */}
                <form onSubmit={handleQuestionSubmit} className="space-y-4">
                  <div>
                    <label htmlFor="question" className="block text-zinc-300 mb-2">
                      Ask a Question:
                    </label>
                    <input
                      type="text"
                      id="question"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      placeholder="e.g., What are the main points in this document?"
                      className="w-full bg-black/30 border border-zinc-700 focus:border-sky-400 text-zinc-100 rounded-md p-3 focus:outline-none focus:ring-1 focus:ring-sky-400"
                    />
                  </div>
                  
                  <div className="flex flex-col sm:flex-row gap-4">
                    <motion.button
                      type="submit"
                      disabled={!question.trim() || isLoading}
                      className={`bg-sky-900/80 border-l-2 border-sky-400 text-sky-100 py-2 px-6 rounded-md
                        ${!question.trim() || isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-sky-800 hover:text-white'}`}
                      whileHover={{ scale: question.trim() && !isLoading ? 1.03 : 1 }}
                      whileTap={{ scale: question.trim() && !isLoading ? 0.98 : 1 }}
                    >
                      {isLoading ? 'Thinking...' : 'Ask'}
                    </motion.button>
                    
                    <motion.button
                      type="button"
                      onClick={resetForm}
                      className="bg-zinc-800 text-zinc-300 py-2 px-6 rounded-md hover:bg-zinc-700"
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      Reset
                    </motion.button>
                  </div>
                </form>
                
                {/* Answer Area */}
                <AnimatePresence>
                  {answer && (
                    <motion.div 
                      className="bg-black/30 rounded-md p-6 mt-4"
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <h3 className="text-zinc-300 font-medium mb-2">Answer:</h3>
                      <div className="text-zinc-100 leading-7 whitespace-pre-wrap">
                        {answer}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}
            
            {/* Error message */}
            <AnimatePresence>
              {error && (
                <motion.div 
                  className="bg-red-900/20 border-l-2 border-red-400 p-4 rounded-md mt-4 text-red-300"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                >
                  {error}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RagDemo;
