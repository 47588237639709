import React, { useEffect, useState } from 'react';
import { GoogleButton } from 'react-google-button';
import { UserAuth } from '../context/AuthContext';
import { useNavigate, NavLink, Link } from 'react-router-dom';
//import { NavLink } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { auth } from '../firebase';
import Intercom from '@intercom/messenger-js-sdk';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const Signup = () => {
    const { googleSignIn, user } = UserAuth();
    const navigate = useNavigate();

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log('Signin Error')
            console.log(error)
        }
    };

    const [visitorCompany, setVisitorCompany] = useState(false);
    const [inviteDetails, setInviteDetails] = useState(null);
    const [visitorInviteCode, setVisitorInviteCode] = useState(false);
    const [visitorInviteCodeDetails, setVisitorInviteCodeDetails] = useState([]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        // Check for company group
        if (params.get('group') === '8dn31n9k8NXbfscjkTpOM') {
            setVisitorCompany(true);
        }

        // Check for invite parameter
        const inviteCode = params.get('invite');
        console.log('inviteCode', inviteCode)
        if (inviteCode) {
            // Fetch invite details
            const fetchInviteDetails = async () => {
                console.log('fetchInviteDetails', inviteCode)
                try {
                    // Query the invite-codes collection where code equals the provided invite code
                    const inviteCodesRef = collection(db, 'invite-codes');
                    const q = query(inviteCodesRef, where("code", "==", inviteCode));
                    const querySnapshot = await getDocs(q);
                    //console.log('querySnapshot', querySnapshot)
                    if (!querySnapshot.empty) {
                        // Get the first matching document
                        const inviteData = querySnapshot.docs[0].data();
                        console.log('Invite details:', inviteData);
                        setInviteDetails(inviteData);
                        setVisitorInviteCode(true);
                        setVisitorInviteCodeDetails(inviteData);
                        //setVisitorCompany(inviteData.company);
                    } else {
                        console.log('No invite found with code:', inviteCode);
                    }
                } catch (error) {
                    console.error('Error fetching invite details:', error);
                }
            };

            fetchInviteDetails();
        }
    }, []);

    const ChatBubble = React.memo(function ChatBubble() {
        //const [isOpen, setIsOpen] = useState(false)

        const { user, logOut } = UserAuth()

        useEffect(() => {
            Intercom({
                app_id: 'g2h9i6in',
                user_id: user?.uid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
                name: user?.displayName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
                email: user?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
                created_at: user?.metadata?.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
            });
        }, [user])


        return (
            <>

            </>
        )
    })



    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')
    const [emailSent, setEmailSent] = useState(false)
    //const {setTimeActive} = useAuthValue()

    const validatePassword = () => {
        let isValid = true
        if (password === '' || confirmPassword === '') {
            isValid = false
            setError('Please enter a password and confirmation')
        }
        if (password !== '' && confirmPassword !== '') {
            if (password !== confirmPassword) {
                isValid = false
                setError('Passwords do not match')
            }
        }
        return isValid
    }

    const register = e => {
        e.preventDefault()
        setError('')
        if (validatePassword()) {
            // Create a new user with email and password using firebase
            createUserWithEmailAndPassword(auth, email, password)
                .then(() => {
                    sendEmailVerification(auth.currentUser)
                        .then(() => {
                            setEmailSent(true)
                            setEmail('')
                            setPassword('')
                            setConfirmPassword('')
                            //setTimeActive(true)
                            //navigate('/verify-email')
                        }).catch((err) => alert(err.message))
                })
                .catch(err => setError(err.message.replace('Firebase:', ''))
                    //err => console.log(err)
                )
        }
    }


    useEffect(() => {
        if (user != null) {
            if (user.accessToken) {
                navigate('/app/home');
            }
        }
    }, [user]);


    return (
        <div className="flex h-screen">
            {/* Left column - 1/3 width */}
            <div className="w-5/12 overflow-y-auto px-8">
                <div className="py-12">
                    <div className="p-12 text-left h-full mt-24">
                        <img src="/util/app/sp-test-logo-2.png" className="logo h-16 ml-6"></img>







                        {visitorInviteCode && (
                            <div className="relative  mb-10 rounded-md bg-white/5 p-6 mt-12 ">
                                <div className="relative  justify-center text-sm font-medium leading-6">
                                    {/* <div className="text-2xl px-6 text-gray-200">Welcome Modern Treasury</div> */}
                                    <div className="flex items-center space-x-6">
                                        {/* <div className="rounded-full bg-black/20 flex-none items-center justify-center h-24 w-24 p-6">
                                                    <img src="/media/logo/logo-mt.png" className=" " />
                                                </div> */}
                                        <div>
                                            <div className="text-2xl text-zinc-100">
                                                Welcome {visitorInviteCodeDetails.name}
                                            </div>
                                            <div className="text-lg text-zinc-400 mt-4">
                                                {visitorInviteCodeDetails.welcomeMessage}
                                            </div>

                                            <div className={`google-login-cenxter mb-6  pt-8 `}>
                                                <GoogleButton
                                                    //type="dark"
                                                    onClick={handleGoogleSignIn}
                                                    //className='w-full rounded-md overflow-hidden'
                                                    style={{
                                                        width: '100%',
                                                        borderRadius: '8px',
                                                        overflow: 'hidden'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}



                        <div className={`rounded-md bg-white/5 p-6 mt-12  ${visitorCompany ? '' : 'hidden'}`}>

                            <>
                                {!visitorCompany && (
                                    <>
                                        {!emailSent ?
                                            <form onSubmit={register} className=''>
                                                <h1 className='text-xl font-base py-2 text-gray-300 text-left'>Account Registration</h1>
                                                <div className='flex flex-col py-2'>
                                                    <div className='text-sky-500 w-full mx-auto text-center'>{error && <div className='auth__error pb-4'>{error}</div>}</div>
                                                    <label className='py-2 font-sm  text-gray-300'>Email Address</label>
                                                    <input onChange={(e) => setEmail(e.target.value)} className='block w-full rounded-md border-0 bg-white/5 py-3 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6' type='email' />
                                                </div>
                                                <div className='flex flex-col py-2'>
                                                    <label className='py-2 font-sm text-gray-300'>Password</label>
                                                    <input onChange={(e) => setPassword(e.target.value)} className='block w-full rounded-md border-0 bg-white/5 py-3 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6' type='password' />
                                                </div>
                                                <div className='flex flex-col py-2'>
                                                    <label className='py-2 font-sm text-gray-300'>Confirm Password</label>
                                                    <input onChange={(e) => setConfirmPassword(e.target.value)} className='block w-full rounded-md border-0 bg-white/5 py-3 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6' type='password' />
                                                </div>
                                                <button className='border border-blue-500 bg-sky-600 hover:bg-sky-500 w-full py-2 mt-6 text-white rounded'>
                                                    Create Account
                                                </button>
                                            </form>
                                            :
                                            <div className='flex flex-col py-2'>
                                                <label className='py-2 font-sm text-gray-300'>An email has been sent to your address with a link to verify.  Please click the link in the email to verify your account to continue.</label>
                                            </div>

                                        }
                                    </>
                                )}
                            </>







                            {!emailSent ?
                                <>
                                    <div className="relative mt-10 mb-10">

                                        {!visitorCompany && (
                                            <div className="relative flex justify-center text-sm font-medium leading-6">
                                                <span className=" px-6 text-gray-200">Or continue with</span>
                                            </div>
                                        )}




                                        {visitorCompany && (
                                            <div className="relative  justify-center text-sm font-medium leading-6">
                                                {/* <div className="text-2xl px-6 text-gray-200">Welcome Modern Treasury</div> */}
                                                <div className="flex items-center space-x-6">
                                                    <div className="rounded-full bg-black/20 flex-none items-center justify-center h-24 w-24 p-6">
                                                        <img src="/media/logo/logo-mt.png" className=" " />
                                                    </div>
                                                    <div>
                                                        <div className="text-2xl text-zinc-100">
                                                            Welcome Modern Treasury
                                                        </div>
                                                        <div className="text-lg text-zinc-400 mt-4">
                                                            Please sign in using your Google account to continue.  If you have any issues please use the chat icon in the bottom right corner to contact support.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}




                                    </div>

                                    <div className={`google-login-cenxter mb-6 ${visitorCompany ? 'pt-8' : ''}`}>
                                        <GoogleButton
                                            //type="dark"
                                            onClick={handleGoogleSignIn}
                                            //className='w-full rounded-md overflow-hidden'
                                            style={{
                                                width: '100%',
                                                borderRadius: '8px',
                                                overflow: 'hidden'
                                            }}
                                        />
                                    </div>
                                </>
                                : null}


                        </div>


                        <div className={`text-left mx-auto mt-24 ml-6 text-gray-50  ${!visitorCompany ? '' : 'hidden'}`}>
                            A code is required to access StoryPath.  Interested in getting a code?{' '}
                            <br/><br/>
                            <a href="https://storypath.ai/" className='text-sky-500'>
                                Request a Code
                            </a>
                        </div>


                        <div className={`text-left mx-auto mt-24 ml-6 text-gray-50  ${visitorCompany ? '' : 'hidden'}`}>
                            Already have an account?{' '}
                            <NavLink to="/signin" className='text-sky-500'>
                                Sign in
                            </NavLink>
                        </div>

                        <div className='text-left absolute bottom-8 text-zinc-400 text-sm'>
                            Copyright 2025 Brevit3, Inc. All rights reserved.
                        </div>

                    </div>




                </div>
            </div>

            {/* Right column - 2/3 width with background image */}
            <div className="w-7/12 bg-cover bg-center" style={{ backgroundImage: "url('/media/logo/sp-login-surfboard-cover-a.jpg')" }}>
                {/* <div className="h-full flex items-center justify-center p-12 bg-black/30">
                    <div className="text-white">
                        Some info
                    </div>
                </div> */}
            </div>
            <ChatBubble />
        </div>
    );
};

export default Signup;
