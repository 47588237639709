import React, { useRef, useState, useContext, Fragment, useEffect, Suspense, CSSProperties, useMemo } from "react";
import { analytics, db } from '../firebase'
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  orderBy,
  doc,
  where,
  limit,
  addDoc,
  deleteDoc, serverTimestamp
} from 'firebase/firestore'
import { Timestamp } from 'firebase/firestore';
import { UserAuth } from '../context/AuthContext';
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from '../firebase';
import BarLoader from "react-spinners/BarLoader";
//import { LineChart, Line, Pie, PieChart, XAxis, YAxis, CartesianGrid, BarChart, Bar, Tooltip, Legend, ResponsiveContainer, ScatterChart, Scatter } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, ScatterChart, Scatter, CartesianGrid, Tooltip, Legend, LineChart, Line, PieChart, Pie, Cell, ComposedChart } from 'recharts';
//import { Card, CardHeader, CardContent } from '@/components/ui/card';


import CompInsightsRecentActivity from "../components/CompInsightsRecentActivity";

import {
  Card,
  CardBody,
  CardHeader,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";

import CompInsightsDashboard from "../components/CompInsightsDashboard";
//import { Tab } from "@headlessui/react";
import { connectFunctionsEmulator } from 'firebase/functions';

const Insightsv2 = (props) => {
  const { user, logOut } = UserAuth();
  //const [timeRange, setTimeRange] = useState(48);

  let [color, setColor] = useState("#0ca5e9")
  const cancelButtonRef = useRef(null)
  const [stories, setStories] = useState([])
  const [shares, setShares] = useState([])
  const [sharesLoaded, setSharesLoaded] = useState(false)
  const [storiesLoaded, setStoriesLoaded] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [analytics, setAnalytics] = useState([])
  const [analyticsCount, setAnalyticsCount] = useState([])
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false)
  const [timeRange, setTimeRange] = useState(48); // Default to 48 hours
  const [selectedShareCodes, setSelectedShareCodes] = useState([]);
  const [activeTab, setActiveTab] = useState("overview");



  const functions = getFunctions();
  if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }


  const handleTabClick = (tab) => {
    setActiveTab(tab.value);
  };

  const tabs = [
    {
      label: "Overview",
      value: "overview",
      icon: <img src="/util/app/b3-genai-v2.svg" className="h-5 w-5" />,
    },
    {
      label: "Share Analytics",
      value: "details",
      icon: <img src="/util/app/b3-genai-v2.svg" className="h-5 w-5" />,
    },
    // {
    //   label: "Embed Analytics",
    //   value: "embed",
    //   icon: <img src="/util/app/b3-genai-v2.svg" className="h-5 w-5" />,
    // },
  ];

  useEffect(() => {
    setTimeout(() => {
      if (user?.uid) {
        const q = query(collection(db, 'shares'), orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let sharesArr = [];
          querySnapshot.forEach((doc) => {
            sharesArr.push({ ...doc.data(), id: doc.id })
          });
          setShares(sharesArr)
          setSharesLoaded(true)
        });
        return () => unsubscribe()
      }
    }, 250)
  }, [user])

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (user?.uid) {

  //       // const callFirebaseFunction = async () => {
  //       //   const functions = getFunctions(app);
  //       //   const getAnalyticsDataCall = httpsCallable(functions, "analyticsGetData"); //orgGetUsers
  //       //   const resp = await getAnalyticsDataCall();
  //       //   setAnalytics(resp.data)
  //       //   setAnalyticsLoaded(true)     
  //       // };

  //       // callFirebaseFunction()

  //       // const callFirebaseFunction = async () => {
  //       //   console.log('Calling analyticsGetData');
  //       //   const getAnalyticsDataCall = httpsCallable(functions, "analyticsGetData"); //orgGetUsers
  //       //   const resp = await getAnalyticsDataCall();
  //       //   console.log('Analytics:', resp.data);
  //       //   setAnalytics(resp.data)
  //       //   setAnalyticsLoaded(true)
  //       // };

  //       // callFirebaseFunction()



  //       // const q = query(collection(db, 'analytics'), where("role.owner", "array-contains", user?.uid));
  //       // const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //       //   let analyticsArr = [];
  //       //   querySnapshot.forEach((doc) => {
  //       //     analyticsArr.push({ ...doc.data(), id: doc.id })
  //       //   });
  //       //   console.log('Analytics:', analyticsArr);
  //       //   setAnalytics(analyticsArr)
  //       //   setTimeout(() => {
  //       //     setAnalyticsLoaded(true)
  //       //   }, 500)
  //       // });
  //       // return () => unsubscribe()
  //     }
  //   }, 250)
  // }, [user])

  useEffect(() => {
    setTimeout(() => {
      if (user?.uid) {
        const q = query(collection(db, 'stories'), orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid), limit(30));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let storiesArr = [];
          querySnapshot.forEach((doc) => {
            storiesArr.push({ ...doc.data(), id: doc.id })
          });
          setStories(storiesArr);
          setStoriesLoaded(true)


          // Get only the analytics for the stories that are owned by the user
          if (storiesArr.length > 0) {
            const q = query(collection(db, 'analytics'), where("storyId", "in", storiesArr.map(story => story.id)));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
              let analyticsArr = [];
              querySnapshot.forEach((doc) => {
                analyticsArr.push({ ...doc.data(), id: doc.id })
              });
              setAnalytics(analyticsArr)
              setAnalyticsLoaded(true)
            });
            return () => unsubscribe()
          }
          else {
            setAnalyticsLoaded(true)
          }

        });
        return () => unsubscribe()
      }
    }, 250)
  }, [user])

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.round(seconds % 60);

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
  };

  const CustomTooltipTime = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip bg-zinc-900 p-4 text-zinc-100 rounded shadow">
          <p className="text-lg text-zinc-200">{data.count}</p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip bg-zinc-900 p-4 text-zinc-100 rounded shadow">
          <p className="font-semibold">{data.sceneName}</p>
          <p className="text-sm text-zinc-400">Total Time: {formatTime(data.engagementTime)}</p>
          <p className="text-sm text-zinc-400">Total Views: {data.viewCount}</p>
        </div>
      );
    }
    return null;
  };

  const CustomShape = (props) => {
    const { cx, cy, fill, engagementTime, viewCount, maxEngagementTime, maxViewCount } = props;
    const maxSize = 40;
    const minSize = 5;
    const size = minSize + (maxSize - minSize) * (engagementTime / maxEngagementTime) * (viewCount / maxViewCount);
    const minBrightness = 30;
    const maxBrightness = 80;
    const brightness = minBrightness + (maxBrightness - minBrightness) * (engagementTime / maxEngagementTime) * (viewCount / maxViewCount);

    return (
      <circle
        cx={cx}
        cy={cy}
        r={size / 2}
        fill={`hsl(201, 90%, ${brightness}%)`}
        style={{ transition: 'all 0.3s ease' }}
      />
    );
  };

  const TimeRangeSelector = ({ onSelectTimeRange, currentTimeRange }) => (
    <div className="flex space-x-2 mb-4">
      {[
        { label: '24 Hours', value: 24 },
        { label: '48 Hours', value: 48 },
        { label: '1 Week', value: 24 * 7 },
        { label: '1 Month', value: 24 * 30 },
        { label: '3 Months', value: 24 * 90 },
        { label: '6 Months', value: 24 * 180 },
        { label: '1 Year', value: 24 * 365 }
      ].map(({ label, value }) => (
        <button
          key={value}
          type="button"
          onClick={() => onSelectTimeRange(value)}
          className={`
             
            flex rounded items-center justify-center 
            text-sm 
            ${currentTimeRange === value ? 'bg-sky-500 text-sky-100' : 'text-zinc-400 bg-black/10  hover:bg-black/20'} 
             
            duration-300 shadow px-4 py-2
          `}
        >
          {label}
        </button>
      ))}
    </div>
  );

  const ShareCodeFilter = ({ shareCodes, selectedCodes, onChange }) => {
    return (
      <div className="flex items-center space-x-2">
        {/* <label className="text-sm text-zinc-400">Filter Share Codes:</label>
        <select
          multiple
          value={selectedCodes}
          onChange={(e) => onChange([...e.target.selectedOptions].map(option => option.value))}
          className="bg-black/20 text-zinc-200 rounded px-2 py-1"
        >
          {shareCodes.map((code) => (
            <option key={code} value={code}>
              {code}
            </option>
          ))}
        </select> */}
      </div>
    );
  };




  const ShareAnalyticsChart2 = ({ analyticsData, timeRange, selectedShareCodes }) => {
    const chartData = useMemo(() => {
      // Get unique visitors and total viewing time per share
      const shareStats = analyticsData.reduce((acc, item) => {
        if (!acc[item.shareCode]) {
          acc[item.shareCode] = {
            visitors: new Set(),
            totalTime: 0
          };
        }
        if (item.userAnon) {
          acc[item.shareCode].visitors.add(item.userAnon);
        }
        acc[item.shareCode].totalTime += item.engagementTime || 0;
        return acc;
      }, {});

      // Find matching share names and prepare data
      const sortedShares = Object.entries(shareStats)
        .map(([shareCode, stats]) => {
          const matchingShare = shares.find(share => share.shareFriendlyURL === shareCode);
          return {
            shareCode,
            visitors: stats.visitors.size,
            totalTime: stats.totalTime,
            shareName: matchingShare?.name || shareCode
          };
        })
        .sort((a, b) => b.visitors - a.visitors)
        .slice(0, 5); // Get top 5

      const chartConfig = {
        type: "bar",
        series: [
          {
            name: "Unique Visitors",
            type: "bar",
            data: sortedShares.map(share => share.visitors),
          },
          {
            name: "Total Time (min)",
            type: "line",
            data: sortedShares.map(share => Math.round(share.totalTime / 60)),
          }
        ],
        options: {
          chart: {
            toolbar: { show: false },
            background: 'transparent',
            stacked: false,
          },
          legend: {
            show: false
          },
          title: { show: "" },
          dataLabels: { enabled: false },
          colors: ["#0EA5E9", "#F59E0B"], // Sky blue for bars, amber for line
          plotOptions: {
            bar: {
              columnWidth: "33%",
              borderRadius: 4,
              // hover: {
              //   filter: {
              //     type: 'darken',
              //     value: 0.15
              //   }
              // }
            }
          },
          stroke: {
            width: [0, 3], // 0 for bars, 3 for line
            curve: 'smooth',
          },
          xaxis: {
            axisTicks: { show: false },
            axisBorder: { show: false },
            labels: {
              style: {
                colors: "#fff",
                fontSize: "14px",
                paddingTop: "12px",
                fontFamily: "inherit",
                fontWeight: 300,
              },
            },
            categories: sortedShares.map(share => share.shareName),
          },
          yaxis: [
            {
              title: {
                text: "Unique Visitors",
                style: {
                  color: "#0EA5E9",
                  fontSize: "16px",
                },
              },
              labels: {
                style: {
                  colors: "#9CA3AF",
                },
              },
            },
            {
              opposite: true,
              title: {
                text: "Total Time (min)",
                style: {
                  color: "#F59E0B",
                  fontSize: "16px",
                },
              },
              labels: {
                style: {
                  colors: "#9CA3AF",
                },
              },
            },
          ],
          grid: {
            show: true,
            borderColor: "#505062",
            strokeDashArray: 5,
            xaxis: {
              lines: { show: false },
            },
            padding: {
              top: 5,
            },
          },
          fill: {
            opacity: [0.55, 1],
            // gradient: {
            //   inverseColors: false,
            //   shade: 'light',
            //   type: "vertical",
            //   opacityFrom: 0.85,
            //   opacityTo: 0.55,
            //   stops: [0, 100, 100, 100]
            // }
          },
          tooltip: {
            theme: "dark",
            shared: true,
            intersect: false,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              const shareName = w.globals.labels[dataPointIndex];
              const visitors = series[0][dataPointIndex];
              const time = series[1][dataPointIndex];

              return (
                '<div class="custom-tooltip bg-zinc-900 p-4 rounded-lg  ">' +
                `<div class="text-md text-zinc-100 mb-2">${shareName}</div>` +
                `<div class="text-zinc-400 mt-2 text-sm flex items-center">
                     <div class="w-2 h-2 rounded-full bg-[#0EA5E9] mr-2"></div>
                     Unique Visitors: ${visitors}
                   </div>` +
                `<div class="text-zinc-400 mt-2 text-sm flex items-center">
                     <div class="w-2 h-2 rounded-full bg-[#F59E0B] mr-2"></div>
                     Total Time: ${time} min
                   </div>` +
                '</div>'
              );
            }
          },
        },
      };

      return chartConfig;
    }, [analyticsData, shares]);

    return (
      <ResponsiveContainer width="100%" height={340}>
        <Chart {...chartData} />
      </ResponsiveContainer>
    );
  };


  const ShareAnalyticsChart = ({ analyticsData, timeRange, selectedShareCodes }) => {
    const chartData = useMemo(() => {
      const now = new Date();
      const pastTime = new Date(now.getTime() - timeRange * 60 * 60 * 1000);

      const parseTimestamp = (input) => {
        const firebaseTimestamp = new Timestamp(
          input.seconds,
          input.nanoseconds
        );
        return firebaseTimestamp.toDate();
      };

      const filteredData = analyticsData.filter(item => {
        const itemDate = parseTimestamp(item.createdAt);
        return itemDate > pastTime && (selectedShareCodes.length === 0 || selectedShareCodes.includes(item.shareCode));
      });

      const shareCodeData = {};
      const timeSlots = {};

      filteredData.forEach(item => {
        const itemDate = parseTimestamp(item.createdAt);
        const hour = itemDate.setMinutes(0, 0, 0);

        if (!shareCodeData[item.shareCode]) {
          shareCodeData[item.shareCode] = {};
        }

        shareCodeData[item.shareCode][hour] = (shareCodeData[item.shareCode][hour] || 0) + 1;
        timeSlots[hour] = true;
      });

      const sortedTimeSlots = Object.keys(timeSlots).sort((a, b) => parseInt(a) - parseInt(b));

      return sortedTimeSlots.map(timestamp => {
        const dataPoint = {
          time: new Date(parseInt(timestamp)).toLocaleString(),
        };
        Object.keys(shareCodeData).forEach(shareCode => {
          dataPoint[shareCode] = shareCodeData[shareCode][timestamp] || 0;
        });
        return dataPoint;
      });
    }, [analyticsData, timeRange, selectedShareCodes]);

    const shareCodeDataKeys = useMemo(() => {
      return Object.keys(chartData[0] || {}).filter(key => key !== 'time');
    }, [chartData]);

    return (
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} strokeOpacity={0.1} />
          <XAxis
            dataKey="time"
            label={{ value: 'Time', position: 'insideBottomRight', offset: -10 }}
            axisLine={false}
          />
          <YAxis
            label={{ value: 'Views', angle: -90, position: 'insideLeft' }}
            axisLine={false}
          />
          <Tooltip />
          <Legend />
          {shareCodeDataKeys.map((shareCode, index) => (
            <Line
              key={shareCode}
              type="monotone"
              dataKey={shareCode}
              stroke={COLORS[index % COLORS.length]}
              //activeDot={{ r: 8 }}
              strokeWidth={3} strokeDasharray="2 5" dot={{ r: 4 }} activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const getUniqueShareCodes = useMemo(() => {
    return [...new Set(analytics.map(item => item.shareCode))];
  }, [analytics]);

  const COLORS = ["#48dbfb", "#00d2d3", "#54a0ff", "#22a6b3", "#01a3a4", "#686de0", "#7269ef", "#5f27cd", "#4834d4", "#30336b"];

  const ScenesViewedChart = ({ analyticsData, shareCode, timeRange }) => {
    const chartData = useMemo(() => {
      const now = new Date();
      const pastTime = new Date(now.getTime() - timeRange * 60 * 60 * 1000);

      const parseTimestamp = (input) => {
        const firebaseTimestamp = new Timestamp(
          input.seconds,
          input.nanoseconds
        );
        return firebaseTimestamp.toDate();
      };

      const filteredData = analyticsData.filter(item => {
        if (item.shareCode !== shareCode) return false;
        const itemDate = parseTimestamp(item.createdAt);
        return itemDate > pastTime;
      });

      const hourlyData = {};
      filteredData.forEach(item => {
        const itemDate = parseTimestamp(item.createdAt);
        const hour = itemDate.setMinutes(0, 0, 0);
        hourlyData[hour] = (hourlyData[hour] || 0) + 1;
      });

      return Object.entries(hourlyData)
        .map(([timestamp, count]) => ({
          time: new Date(parseInt(timestamp)),
          count
        }))
        .sort((a, b) => a.time - b.time);
    }, [analyticsData, shareCode, timeRange]);

    const formatXAxis = (tickItem) => {
      const date = new Date(tickItem);
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    return (
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 50,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} strokeOpacity={0.1} />
          <XAxis
            dataKey="time"
            tickFormatter={formatXAxis}
            label={{ value: 'Time', position: 'insideBottomRight', offset: -10 }}
            axisLine={false}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, position: 'insideLeft' }} axisLine={false}
          />
          <Tooltip content={<CustomTooltipTime />} />
          <Line type="monotone" strokeWidth={3} dataKey="count" strokeDasharray="2 5" stroke="#0EA5E9" dot={{ r: 4 }} activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    );
  };




  const ShareCodeTable = ({ analyticsData, shareCode }) => {
    const { processedAnalytics, totalCount, totalEngagementTime, scatterData, maxEngagementTime, maxViewCount, totalUniqueCount, processedTags } = useMemo(() => {
      if (!Array.isArray(analyticsData)) {
        console.error('Analytics data is not an array:', analyticsData);
        return {
          processedAnalytics: [],
          totalCount: 0,
          totalEngagementTime: 0,
          scatterData: [],
          maxEngagementTime: 0,
          maxViewCount: 0,
          totalUniqueCount: 0,
          processedTags: []
        };
      }

      const filteredData = analyticsData.filter(item => item.shareCode === shareCode);

      if (filteredData.length === 0) {
        return {
          processedAnalytics: [],
          totalCount: 0,
          totalEngagementTime: 0,
          scatterData: [],
          maxEngagementTime: 0,
          maxViewCount: 0,
          totalUniqueCount: 0,
          processedTags: []
        };
      }

      const sceneMap = {};
      const tagMap = {};
      let totalCount = 0;
      let totalEngagementTime = 0;
      const uniqueUsers = new Set();

      filteredData.forEach(item => {
        if (!item.sceneName) {
          console.warn('Item missing sceneName:', item);
          return;
        }

        if (!sceneMap[item.sceneName]) {
          sceneMap[item.sceneName] = {
            count: 0,
            totalEngagementTime: 0
          };
        }

        sceneMap[item.sceneName].count++;
        sceneMap[item.sceneName].totalEngagementTime += item.engagementTime || 0;

        totalCount++;
        totalEngagementTime += item.engagementTime || 0;

        if (item.userAnon) {
          uniqueUsers.add(item.userAnon);
        }

        if (Array.isArray(item.engagementTags)) {
          item.engagementTags.forEach(tag => {
            if (!tagMap[tag]) {
              tagMap[tag] = {
                count: 0,
                totalEngagementTime: 0
              };
            }
            tagMap[tag].count++;
            tagMap[tag].totalEngagementTime += item.engagementTime || 0;
          });
        }
      });

      const processedAnalytics = Object.entries(sceneMap)
        .map(([sceneName, data]) => ({
          sceneName,
          count: data.count,
          totalEngagementTime: data.totalEngagementTime
        }))
        .sort((a, b) => b.totalEngagementTime - a.totalEngagementTime);

      const processedTags = Object.entries(tagMap)
        .map(([tagName, data]) => ({
          tagName,
          count: data.count,
          totalEngagementTime: data.totalEngagementTime
        }))
        .sort((a, b) => b.totalEngagementTime - a.totalEngagementTime);

      const scatterData = processedAnalytics.map(item => ({
        sceneName: item.sceneName,
        engagementTime: item.totalEngagementTime,
        viewCount: item.count
      }));

      const maxEngagementTime = Math.max(...scatterData.map(d => d.engagementTime));
      const maxViewCount = Math.max(...scatterData.map(d => d.viewCount));

      return {
        processedAnalytics,
        totalCount,
        totalEngagementTime,
        scatterData,
        maxEngagementTime,
        maxViewCount,
        totalUniqueCount: uniqueUsers.size,
        processedTags
      };
    }, [analyticsData, shareCode, timeRange]);

    const COLORS = ["#48dbfb", "#00d2d3", "#54a0ff", "#22a6b3", "#01a3a4", "#686de0", "#7269ef", "#5f27cd", "#4834d4", "#30336b"]

    const ScenePieCharts = () => {
      const countData = processedAnalytics.map(({ sceneName, count }) => ({
        name: sceneName,
        value: count
      }));

      const timeData = processedAnalytics.map(({ sceneName, totalEngagementTime }) => ({
        name: sceneName,
        value: totalEngagementTime
      }));

      const CustomizedLegend = (props) => {
        const { payload } = props;
        return (
          <ul className="flex flex-wrap justify-start mt-20 ml-16">
            {payload.map((entry, index) => (
              <li key={`item-${index}`} className="w-1/2 mb-2 flex items-center">
                <svg width="10" height="10" className="mr-2">
                  <rect width="10" height="10" fill={entry.color} />
                </svg>
                <span className="text-sm">{entry.value}</span>
              </li>
            ))}
          </ul>
        );
      };

      return (
        <div className="
         mt-8">
          <div>
            <h3 className="text-base text-zinc-200 pl-2 mb-4 ml-8">Scene View Count</h3>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              >
                <Pie

                  data={countData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  paddingAngle={4}
                  innerRadius={100}
                  outerRadius={120}
                  fill="#82ca9d"
                  label={(entry) => entry.name}
                  labelLine={false}
                  strokeWidth={0}
                >
                  {countData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                {/* <Legend content={<CustomizedLegend />} /> */}
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h3 className="text-base text-zinc-200 pl-2 mb-4 ml-8 mt-12">Scene View Time</h3>
            <ResponsiveContainer width="100%" height={500}>
              <PieChart>
                <Pie
                  data={timeData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  paddingAngle={4}
                  innerRadius={100}
                  outerRadius={120}
                  fill="#8884d8"
                  label={(entry) => entry.name}
                  labelLine={false}
                  strokeWidth={0}
                >
                  {timeData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend content={<CustomizedLegend />} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    };



    return (
      <>
        <div className="px-4 rounded-md">

          <div className="grid grid-cols-1 gap-px bg-black/10  grid-cols-3  ">

            <div className="px-4 py-6 sm:px-6 lg:px-8  rounded">
              <p className="text-sm font-medium leading-6 text-zinc-400">Unique <br />Viewers</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-3xl font-semibold tracking-tight text-zinc-50">{totalUniqueCount}</span>
              </p>
            </div>

            <div className="px-4 py-6 sm:px-6 lg:px-8  rounded">
              <p className="text-sm font-medium leading-6 text-zinc-400">Scenes  <br />Viewed</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-3xl font-semibold tracking-tight text-zinc-50">{totalCount}</span>
              </p>
            </div>

            <div className=" px-4 py-6 sm:px-6 lg:px-8  rounded">
              <p className="text-sm font-medium leading-6 text-zinc-400">Total Time <br />Watched</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-3xl font-semibold tracking-tight text-zinc-50">{formatTime(totalEngagementTime)}</span>
              </p>
            </div>

          </div>

        </div>




        <div className="pr-2 mt-8">

          <div className="pr-2 mt-8 mb-12">
            {/* <div className="text-xl pl-2 mb-4 text-zinc-200 ml-8">Last 48 Hours <span className="text-sm ml-4 text-zinc-500">(Scenes Viewed)</span></div> */}
            <h3 className="text-base text-zinc-200 pl-2 mb-4 ml-8">Scenes Viewed</h3>

            <ScenesViewedChart
              analyticsData={analyticsData}
              shareCode={shareCode}
              timeRange={timeRange}
            />
            {/* <div className="text-zinc-400 text-sm mt-2">{shareCode}....</div>   */}

          </div>

          <ResponsiveContainer width="100%" height={300}>
            <ScatterChart
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 50,
              }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                strokeOpacity={0.1}
              />
              <XAxis
                type="number"
                dataKey="engagementTime"
                name="engagement time"
                unit="s"
                tickFormatter={formatTime}
                axisLine={false}
                tickLine={false}
                label={{ value: 'Duration', position: 'insideBottomRight', offset: -10 }}
              />
              <YAxis
                type="number"
                dataKey="viewCount"
                name="view count"
                axisLine={false}
                tickLine={false}
                label={{ value: 'Count', angle: -90, position: 'insideLeft' }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Scatter
                name="Scenes"
                data={scatterData}
                fill="#0EA5E9"
                shape={
                  (props) => (
                    <CustomShape
                      {...props}
                      maxEngagementTime={maxEngagementTime}
                      maxViewCount={maxViewCount}
                    />
                  )
                }
              />
            </ScatterChart>
          </ResponsiveContainer>
        </div>

        <div className="w-full">
          <ScenePieCharts />
        </div>

        <div className="rounded-md p-6 grid grid-cols-2 gap-14">
          <div>
            <h3 className="text-xl  pl-2 mb-4">Scenes</h3>
            <table className="w-full -mt-12 ">
              <thead>
                <tr>
                  <th className="p-2 text-left"> </th>
                  <th className="p-2 text-right">Count</th>
                  <th className="p-2 text-right">Time</th>
                </tr>
              </thead>
              <tbody>
                {processedAnalytics.map(({ sceneName, count, totalEngagementTime }) => (
                  <tr key={sceneName} className="text-zinc-400">
                    <td className="px-2 text-left">{sceneName}</td>
                    <td className="px-2 text-right">{count}</td>
                    <td className="px-2 text-right">{formatTime(totalEngagementTime)}</td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
          <div>
            <h3 className="text-xl   mb-4">Topics</h3>
            <table className="w-full -mt-12 ">
              <thead>
                <tr>
                  <th className="p-2 text-left"> </th>
                  <th className="p-2 text-right">Count</th>
                  <th className="p-2 text-right">Time</th>
                </tr>
              </thead>
              <tbody>
                {processedTags.map(({ tagName, count, totalEngagementTime }) => (
                  <tr key={tagName} className="text-zinc-400 py-4">
                    <td className="px-2 text-left">{tagName}</td>
                    <td className="px-2 text-right">{count}</td>
                    <td className="px-2 text-right">{formatTime(totalEngagementTime)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </>
    );
  };

  const override = {
    width: "50%",
    margin: "auto",
    marginTop: "20%",
  }

  return (
    <div className="h-[calc(100vh-100px)] w-full">


      <Tabs value={activeTab}>
        <TabsHeader
          className="rounded-none border-b-0    flex justify-between"
          indicatorProps={{
            className: "bg-transparent border-b-4 border-sky-500 shadow-none rounded-none",
          }}
        >
          <div className="flex">
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                onClick={() => handleTabClick(tab)}
                className={`px-4 py-4 text-md w-fit transition-colors duration-200 relative -mt-4
                  ${activeTab === tab.value
                    ? "text-zinc-50"
                    : "text-zinc-400 hover:text-zinc-200"
                  }`}
              >
                <div className="flex items-center gap-2">
                  {/* {tab.icon} */}
                  {tab.label}
                </div>
              </Tab>
            ))}
          </div>

          <div className="flex items-center ">
            <ShareCodeFilter
              shareCodes={getUniqueShareCodes}
              selectedCodes={selectedShareCodes}
              onChange={setSelectedShareCodes}
            />
            <TimeRangeSelector onSelectTimeRange={setTimeRange} currentTimeRange={timeRange} />
          </div>
        </TabsHeader>

        <TabsBody>
          <TabPanel value="overview">
            {/* Overview Tab Content */}
            <div className="mx-auto w-full">
              {analyticsLoaded && (



                <div className="grid grid-cols-12 gap-8">


                  {sharesLoaded && shares.length === 0 ? (
                    <div className="bg-black/10 rounded-md p-6 flex col-span-12">
                      <div className="  p-6   items-center col-span-3 mx-auto">
                        <div className="flex justify-center mb-4 mx-auto">
                          <img src={'/util/app/b3-admin-analytics-11.svg'} className="w-16 rounded-md" />
                        </div>
                        <div className="text-center mx-auto">
                          <div className="text-lg text-zinc-50">No insights yet available</div>
                          <div className="text-sm mt-1 text-zinc-400">Create a new share to start collecting insights (the a tracking URL will be provided)</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="w-full   col-span-7">

                        <Card className="bg-black/0 border-0 shadow-none rounded-md ">
                          <CardHeader
                            floated={false}
                            shadow={false}
                            color="transparent"
                            className="flex flex-col rounded-none md:flex-row md:items-center"
                          >
                            <div className="w-max rounded-lg   mr-4 text-white">
                              {/* <Square3Stack3DIcon className="h-6 w-6" /> */}
                              <img src="/util/app/b3-admin-analytics-all-1.svg" className="h-8 w-8" />
                            </div>
                            <div>
                              <Typography variant="h6" className="text-zinc-200">
                                Top 5 Shares by Unique Visitors
                              </Typography>
                            </div>
                          </CardHeader>
                          <CardBody className="-mt-4 h-[calc(100vh-370px)] p-0 mt-2">




                            <ShareAnalyticsChart2
                              analyticsData={analytics}
                              timeRange={timeRange}
                              selectedShareCodes={selectedShareCodes}
                            />

                            {/* <div className="h-[calc(100vh-720px)] overflow-y-auto"> */}
                            <div className="h-[calc(100vh-720px)] overflow-y-auto bg-black/10 rounded-md pt-2 shadow-inner ml-4 relative space-y-6   pb-2 pr-2 mt-4">

                              <CompInsightsDashboard
                                shares={shares}
                                analytics={analytics}
                                timeRange={timeRange}
                                stories={stories}
                              />
                            </div>

                          </CardBody>
                          <div>
                          </div>
                        </Card>

                      </div>
                      <div className="w-full mb-8 col-span-5">

                        {/* <ShareAnalyticsChart
                          analyticsData={analytics}
                          timeRange={timeRange}
                          selectedShareCodes={selectedShareCodes}
                        /> */}

                        <CompInsightsRecentActivity
                          shares={shares}
                          analytics={analytics}
                        />
                      </div>
                    </>
                  )}



                </div>

              )}

            </div>
          </TabPanel>

          <TabPanel value="details">
            {/* Engagement Details Tab Content */}
            <div className="mx-auto">
              <div className={"grid grid-cols-3 gap-4 text-zinc-100"}>
                {sharesLoaded && shares.length === 0 ? (
                  <div className="bg-black/10 rounded-md p-6 flex col-span-3">
                    <div className="  p-6   items-center col-span-3 mx-auto">
                      <div className="flex justify-center mb-4 mx-auto">
                        <img src={'/util/app/b3-admin-analytics-11.svg'} className="w-16 rounded-md" />
                      </div>
                      <div className="text-center mx-auto">
                        <div className="text-lg text-zinc-50">No insights yet available</div>
                        <div className="text-sm mt-1 text-zinc-400">Create a new share to start collecting insights (the a tracking URL will be provided)</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  shares.map((share, index) => (
                    <div key={index} className="bg-black/10 rounded-md">
                      <div className="flex justify-between p-4">
                        <div className="flex gap-4">

                          <div className="bg-contain bg-center w-24 bg-no-repeat mr-2"
                            style={{ backgroundImage: `url(${share.imageStoryLogo})` }}
                          ></div>

                          <div>
                            <div className="text-lg text-gray-50  ">{share.name}</div>

                          </div>
                        </div>

                      </div>


                      {analyticsLoaded && storiesLoaded ? (
                        <ShareCodeTable
                          analyticsData={analytics}
                          shareCode={share.shareFriendlyURL || ''}
                          stories={stories}
                          timeRange={timeRange}
                        />
                      ) : null}

                    </div>
                  ))
                )}
              </div>
            </div>
          </TabPanel>
        </TabsBody>
      </Tabs>






    </div>
  )
}

export default Insightsv2