import React, { useState, useEffect, useRef, Fragment, useCallback } from 'react';
import { Popover, Transition } from '@headlessui/react'
//import { Fragment } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import { JSONTree } from 'react-json-tree';
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
// import BocceWorkflow from './BocceWorkflow';
// import BocceWorkflowNews from './BocceWorkflowNews';
import { usePDF } from 'react-to-pdf';
import { ImpulseSpinner, RotateSpinner } from "react-spinners-kit";
//import html2pdf from 'html2pdf.js';
import { motion, AnimatePresence } from "framer-motion";
//import { format } from 'date-fns';
import StoryLibrary from './StoryLibrary';
import { getFirestore, query, where, getDocs, orderBy, doc, deleteDoc, limit, addDoc, collection, serverTimestamp, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
//import { useAuth } from '../contexts/AuthContext';
import KanbanBoard from './KanbanBoard';
import { XMarkIcon } from '@heroicons/react/20/solid'
//import Sticky from 'react-sticky-el';
import StickyBox from 'react-sticky-box';
import CompExplainer from './CompExplainer';
import SpeakIt from './CompAISpeakIt';
import CompAIStoryBoardItem from './CompAIStoryBoardItem';
//import { getFunctions, httpsCallable } from 'firebase/functions';
//import {app} from '../firebase-config';
//import { functions } from '../firebase-config';
//import { connectFunctionsEmulator } from 'firebase/functions';

//import app from "../firebase";
import app from "../firebase";
import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator,
} from "firebase/functions";

import ReactConfetti from 'react-confetti';
import { useReactToPrint } from "react-to-print";
import { logCustomEvent } from './UtilAnalytics';
import CompPrintCover from './CompPrintCover';

import { ArcherContainer, ArcherElement } from 'react-archer';

//import { useAuth } from '../contexts/AuthContext';
//import CompAIStoryTone from './CompAIStoryTone';


import {
    Accordion,
    AccordionHeader,
    AccordionBody,
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import CompAIStoryTone from './CompAIStoryTone';

// Define the SVG for three dots
const ThreeDotsSVG = () => (
    <svg width="28" height="24" viewBox="0 0 34 34" fill="none" className="print:hidden" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="16" r="4" fill="#333" />
        <circle cx="18" cy="16" r="4" fill="#333" />
        <circle cx="32" cy="16" r="4" fill="#333" />
    </svg>
);


const ThreeDotsSVGLoading = () => {
    // Animation variants for the circles

    const initialScale = 1;
    const finalScale = 1.25;

    const circleVariants = {
        initial: {
            scale: initialScale,
            fill: "#0C4A6E"
        },
        animate: (i) => ({
            scale: [initialScale, finalScale, initialScale],
            fill: ["#333", "#7DD3FC", "#333"],
            transition: {
                duration: 0.6,
                ease: "easeInOut",
                times: [0, 0.5, 1],
                repeat: Infinity,
                repeatDelay: 1.1, // Wait for all circles to finish (3 * 0.6)
                delay: i * 0.6 // Stagger based on circle index
            }
        })
    };

    return (
        <motion.svg
            width="28"
            height="24"
            viewBox="0 0 34 34"
        >
            <motion.circle
                cx="4"
                cy="16"
                r="4"
                initial="initial"
                animate="animate"
                variants={circleVariants}
                custom={0} // First circle
            />
            <motion.circle
                cx="18"
                cy="16"
                r="4"
                initial="initial"
                animate="animate"
                variants={circleVariants}
                custom={1} // Second circle
            />
            <motion.circle
                cx="32"
                cy="16"
                r="4"
                initial="initial"
                animate="animate"
                variants={circleVariants}
                custom={2} // Third circle
            />
        </motion.svg>
    );
};

const FiveDotsSVG = () => (
    <svg width="60" height="24" viewBox="0 0 74 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="16" r="4" fill="#333" />
        <circle cx="18" cy="16" r="4" fill="#333" />
        <circle cx="32" cy="16" r="4" fill="#333" />
        <circle cx="46" cy="16" r="4" fill="#333" />
        <circle cx="60" cy="16" r="4" fill="#333" />
    </svg>
);

const FiveDotsSVGLoading = () => {
    // Animation variants for the circles remain the same
    const initialScale = 1;
    const finalScale = 1.25;

    const circleVariants = {
        initial: {
            scale: initialScale,
            fill: "#0C4A6E"
        },
        animate: (i) => ({
            scale: [initialScale, finalScale, initialScale],
            fill: ["#333", "#7DD3FC", "#333"],
            transition: {
                duration: 0.6,
                ease: "easeInOut",
                times: [0, 0.5, 1],
                repeat: Infinity,
                repeatDelay: 1.9,
                delay: i * 0.3
            }
        })
    };

    return (
        <motion.svg
            width="60"
            height="24"
            viewBox="0 0 74 34"
        >
            <motion.circle
                cx="4"
                cy="16"
                r="4"
                initial="initial"
                animate="animate"
                variants={circleVariants}
                custom={0}
            />
            <motion.circle
                cx="18"
                cy="16"
                r="4"
                initial="initial"
                animate="animate"
                variants={circleVariants}
                custom={1}
            />
            <motion.circle
                cx="32"
                cy="16"
                r="4"
                initial="initial"
                animate="animate"
                variants={circleVariants}
                custom={2}
            />
            <motion.circle
                cx="46"
                cy="16"
                r="4"
                initial="initial"
                animate="animate"
                variants={circleVariants}
                custom={3}
            />
            <motion.circle
                cx="60"
                cy="16"
                r="4"
                initial="initial"
                animate="animate"
                variants={circleVariants}
                custom={4}
            />
        </motion.svg>
    );
};

const Icon = ({ id, open, isLoading = false }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="#38BDF8"
            className={`${open === id ? "rotate-180" : ""} h-5 w-5 transition-transform ${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 print:hidden`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
};

let tabs = [
    {
        value: "path-type",
        label: "Updates",
        desc: "Updates Content",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        )
    },
    {
        value: "path-Stories",
        label: "Story Library",
        desc: "Stories Content",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
            </svg>
        )
    },
    {
        value: "path-Create",
        label: "Create",
        desc: "Create Content",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
        )
    },
    {
        value: "path-create",
        label: "Sharing",
        desc: "Sharing Content",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
            </svg>
        )
    },
    {
        value: "path-storyboard",
        label: "Analytics",
        desc: "Analytics Content",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
            </svg>
        )
    },
    {
        value: "path-publish",
        label: "Admin",
        desc: "Admin Content",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
        )
    },
];




const useRecentPrompts = (user) => {
    const [recentPrompts, setRecentPrompts] = useState([]);

    useEffect(() => {
        const fetchRecentPrompts = async () => {
            if (!user?.uid) return;

            const db = getFirestore();
            const promptsRef = collection(db, "gen-prompt-cache");

            try {
                const q = query(
                    promptsRef,
                    where("userId", "==", user.uid),
                    orderBy("timestamp", "desc"),
                    limit(5)
                );

                const querySnapshot = await getDocs(q);
                const prompts = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setRecentPrompts(prompts);
            } catch (error) {
                console.error("Error fetching recent prompts:", error);
            }
        };

        fetchRecentPrompts();
    }, [user]);

    //return recentPrompts;
    return { recentPrompts, setRecentPrompts };
};

// Add new Context Analysis section
const ContextAnalysisSection = ({ contextAnalysis, loading }) => (
    <div className="mt-4">
        <h2 className="text-lg font-semibold text-zinc-100 mb-3">Story Context Analysis</h2>
        {loading ? (
            <Skeleton height={100} />
        ) : contextAnalysis && (
            <div className="space-y-6">
                {/* Key Themes */}
                <div className="bg-zinc-800/50 rounded p-4">
                    <div className="text-sky-400 font-medium mb-2">Key Themes</div>
                    <ul className="list-disc pl-4 space-y-1">
                        {contextAnalysis.themes.map((theme, idx) => (
                            <li key={idx} className="text-zinc-400">{theme}</li>
                        ))}
                    </ul>
                </div>

                {/* Turning Points */}
                <div className="bg-zinc-800/50 rounded p-4">
                    <div className="text-sky-400 font-medium mb-2">Critical Turning Points</div>
                    <ul className="list-disc pl-4 space-y-1">
                        {contextAnalysis.turningPoints.map((point, idx) => (
                            <li key={idx} className="text-zinc-400">{point}</li>
                        ))}
                    </ul>
                </div>

                {/* Value Propositions */}
                <div className="bg-zinc-800/50 rounded p-4">
                    <div className="text-sky-400 font-medium mb-2">Value Propositions</div>
                    <div className="space-y-3">
                        <div>
                            <h4 className="text-zinc-300 font-medium">Business</h4>
                            <p className="text-zinc-400">{contextAnalysis.valueProps.business}</p>
                        </div>
                        <div>
                            <h4 className="text-zinc-300 font-medium">Technical</h4>
                            <p className="text-zinc-400">{contextAnalysis.valueProps.technical}</p>
                        </div>
                        <div>
                            <h4 className="text-zinc-300 font-medium">Customer</h4>
                            <p className="text-zinc-400">{contextAnalysis.valueProps.customer}</p>
                        </div>
                    </div>
                </div>

                {/* Technology Highlights */}
                <div className="bg-zinc-800/50 rounded p-4">
                    <div className="text-sky-400 font-medium mb-2">Technology Implementation Highlights</div>
                    <ul className="list-disc pl-4 space-y-1">
                        {contextAnalysis.techHighlights.map((highlight, idx) => (
                            <li key={idx} className="text-zinc-400">{highlight}</li>
                        ))}
                    </ul>
                </div>
            </div>
        )}
    </div>
);



export default function CompAIStoryBoardv2() {
    const { user } = UserAuth();

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const ElevenLabsAPIKey = 'sk_f8d3797facf090b36877ce8b64da0a567b894125d3a71931';

    // Load default story tone settings, then override with user settings
    const [storyTone, setStoryTone] = useState({
        research: 'Casual',
        story: 'Casual',
        storyTone: 'Casual',
        storyLength: '3',
        storyIntensity: 'a regular day, nothing overly dramatic, but the product helps make it easier in important ways',
        researchDepth: 'deep',
        notificationTone: 'none'
    });




    const functions = getFunctions(app);
    if (process.env.NODE_ENV === 'development') {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }

    useEffect(() => {
        const loadUserSettings = async () => {
            // console.log('loading user settings');
            // console.log('storyTone', storyTone);
            try {
                const userGetSettings = httpsCallable(functions, 'userGetSettings');
                const result = await userGetSettings();

                if (result.data.userData) {
                    setStoryTone(prevTone => ({
                        ...prevTone,
                        ...result.data.userData.storySettings
                    }));
                    //console.log('settings - from store', result.data.userData.storySettings);
                }
                else {
                    //console.log('No user settings found');
                    // setStoryTone({
                    //     research: 'Casual',
                    //     story: 'Casual',
                    //     storyTone: 'Casual',
                    //     storyLength: '3',
                    //     storyIntensity: 'a regular day, nothing overly dramatic, but the product helps make it easier in important ways',
                    //     researchDepth: 'deep',
                    //     notificationTone: 'none'
                    // });
                    setStoryTone(prevTone => ({
                        ...prevTone,
                        research: 'Casual',
                        story: 'Casual',
                        storyTone: 'Casual',
                        storyLength: '3',
                        storyIntensity: 'a regular day, nothing overly dramatic, but the product helps make it easier in important ways',
                        researchDepth: 'deep',
                        notificationTone: 'none'
                    }));
                    //console.log('settings - fallback', storyTone);
                }
            } catch (error) {
                //console.error('Error loading settings:', error);
            }
        };

        loadUserSettings();
    }, []);


    const [maxDeepData, setMaxDeepData] = useState(null);

    // useEffect(() => {
    //     console.log("maxDeepData state updated:", maxDeepData);
    // }, [maxDeepData]);


    useEffect(() => {
        ////console.log('storyTone', storyTone);
    }, [storyTone]);

    // Add handler for tone changes
    const handleToneChange = (newToneSettings) => {
        //      //console.log('newToneSettings', newToneSettings);
        setStoryTone(newToneSettings);
    };

    //const baseColor = '#1E1E25';
    const baseColor = '#34343A';
    const highlightColor = '#2C2C30';
    const [loading, setLoading] = useState(false);
    const [allowGeneration, setAllowGeneration] = useState(true);
    const [researchData, setResearchData] = useState({
        customerInsights: null,
        productInsights: null,
        podcastInsights: null,
        //researchDeepData: null
    });
    const [researchDeepData, setResearchDeepData] = useState(null);
    const [storyData, setStoryData] = useState({
        introduction: null,
        scenes: { scenes: [] },
        conclusion: null,
        personas: []
    });
    const [isNavVisible, setIsNavVisible] = useState(true);
    const [error, setError] = useState(null);
    const [personaData, setPersonaData] = useState(null);
    const [salesAnalysisData, setSalesAnalysisData] = useState(null);
    const [viewMode, setViewMode] = useState("detailed");
    const [activeTab, setActiveTab] = useState('insights');
    const [brandLogoData, setBrandLogoData] = useState(null);
    const [eli5Data, setEli5Data] = useState(null);
    const [eli5ResearchData, setEli5ResearchData] = useState(null);
    const [eli5DataBusiness, setEli5DataBusiness] = useState(null);
    const [eli5DataIndustry, setEli5DataIndustry] = useState(null);
    const [eli5StakeholdersData, setEli5StakeholdersData] = useState(null);
    const [contentTone, setContentTone] = useState('casual');
    //const [jobsELI5, setJobsELI5] = useState(null);
    const [currentStep, setCurrentStep] = useState("Ready");
    const [openPaths, setOpenPaths] = useState(false);

    const [storySpeakerData, setStorySpeakerData] = useState(null);
    const [storySpeakerSummaryResult, setStorySpeakerSummaryResult] = useState(null);
    const [storyChaptersData, setStoryChaptersData] = useState(null);
    const [businessTransformationResult, setBusinessTransformationResult] = useState(null);
    const [businessCostOfInactionResult, setBusinessCostOfInactionResult] = useState(null);
    const [businessModelData, setBusinessModelData] = useState(null);


    //const [apiEndpoint, setApiEndpoint] = useState(apiEndpoint +  '');
    //const [apiEndpoint, setApiEndpoint] = useState('http://127.0.0.1:5001/brevit3-57b00/us-central1/');
    const [apiEndpoint, setApiEndpoint] = useState('https://us-central1-brevit3-57b00.cloudfunctions.net/');

    // const [formData, setFormData] = useState({ 
    //     promptCompanyName: 'Procore',
    //     promptProductName: 'Project Management',
    //     promptCustomerName: 'Turner Construction',
    //     promptCustomerRole: 'Director',
    //     promptCustomerProblem: 'accelerating data center construction',

    //     //website: 'https://www.webflow.com',
    //     website: 'https://webflow.com/platform',
    //     competitor: 'https://wpvip.com/',

    // });





    //    const { user } = useAuth();

    const [formData, setFormData] = useState({
        // ... existing code ...
        promptCompanyName: '',
        promptProductName: '',
        promptCustomerName: '',
        promptCustomerRole: '',
        promptCustomerProblem: '',
        // ... existing code ...
    });

    // Update form data when user changes
    useEffect(() => {
        if (user?.email === 'john.brunswick@gmail.com') {
            setFormData(prevData => ({
                ...prevData,
                promptCompanyName: 'Procore',
                promptProductName: 'Project Management',
                promptCustomerName: 'Turner Construction',
                promptCustomerRole: 'Director',
                promptCustomerProblem: 'accelerating data center construction',
                website: '',
                competitor: '',
            }));
        }
    }, [user]);





    const [producedStory, setProducedStory] = useState({
        contextAnalysis: null,
        title: null,
        opening: null,
        middleScenes: [],
        closing: null
    });
    const [storyVisualizationData, setStoryVisualizationData] = useState(null);
    //const [storyChaptersData, setStoryChaptersData] = useState(null);
    const [producedResult, setProducedResult] = useState(null);


    const [apiCalls, setApiCalls] = useState({
        research: false,
        eli5Research: false,
        persona: false,
        story: false,
        producer: false,
        talkTrack: false,
        salesAnalysis: false,
        wardrobe: false,
        businessNews: false,
        eli5Business: false,
        industryTrends: false,
        eli5Industry: false,
        storyChapters: false,
        //messagingResearch: false,
        mediaResearch: false,
        eli5Media: false,
        businessAnalysis: false,
        storySpeaker: false,
        storySpeakerSummary: false,
        maxDeep: false,
        maxDeepData: false,
        deepLanguage: false
    });


    const [apiCallsCompleted, setApiCallsCompleted] = useState({
        research: false,
        eli5Research: false,
        persona: false,
        story: false,
        producer: false,
        talkTrack: false,
        salesAnalysis: false,
        wardrobe: false,
        businessNews: false,
        eli5Business: false,
        industryTrends: false,
        eli5Industry: false,
        storyChapters: false,
        //messagingResearch: false,
        mediaResearch: false,
        eli5Media: false,
        businessAnalysis: false,
        storySpeaker: false,
        storySpeakerSummary: false,
        maxDeep: false,
        maxDeepData: false,
        deepLanguage: false
    });



    const [talkTrackData, setTalkTrackData] = useState(null);
    const [timer, setTimer] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [sectionTimes, setSectionTimes] = useState({
        research: null,
        eli5Research: null,
        persona: null,
        story: null,
        producer: null,
        talkTrack: null,
        salesAnalysis: null,
        wardrobe: null,
        businessNews: null,
        eli5Business: null,
        industryTrends: null,
        eli5Industry: null,
        storyChapters: null,
        //messagingResearch: null,
        mediaResearch: null,
        eli5Media: null,
        businessAnalysis: null,
        storySpeaker: null,
        storySpeakerSummary: null,
        maxDeep: null,
        maxDeepData: null,
        deepLanguage: null
    });
    //    const [wardrobeData, setWardrobeData] = useState(null);
    const [businessNewsData, setBusinessNewsData] = useState(null);
    const [industryTrendsData, setIndustryTrendsData] = useState(null);
    const [podcastGenerationData, setPodcastGenerationData] = useState(null);
    const [businessAnalysisData, setBusinessAnalysisData] = useState(null);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [eli5DataMedia, setEli5DataMedia] = useState(null);

    //    console.log("maxDeepData state initialized:", maxDeepData);



    const [isKanbanModalOpen, setIsKanbanModalOpen] = useState(false);
    const [researchCompetitorData, setResearchCompetitorData] = useState(null);
    const [isResearchCompetitorLoading, setIsResearchCompetitorLoading] = useState(false);
    //    const [expandedSections, setExpandedSections] = useState({});
    const [showConfetti, setShowConfetti] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Add timer effect
    useEffect(() => {
        let intervalId;
        if (isTimerRunning) {
            intervalId = setInterval(() => {
                setTimer(prev => prev + 10); // Update every 10ms
            }, 10);
        }
        return () => clearInterval(intervalId);
    }, [isTimerRunning]);

    // Format time function
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        const milliseconds = Math.floor((time % 1000) / 10);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds.toString().padStart(2, '0')}`;
    };

    const playSound = () => {
        if (storyTone.notificationTone === 'none') return;
        const audio = new Audio(`/media/notifications/${storyTone.notificationTone}`);
        audio.play();
    };



    // Add new state variable near the top where other states are defined
    const [researchProductData, setResearchProductData] = useState(null);
    const [isResearchProductLoading, setIsResearchProductLoading] = useState(false);


    const [exampleProblems] = useState([
        "reducing customer churn by 25% in the next quarter",
        "streamlining their order fulfillment process to improve delivery times",
        "implementing a scalable solution for managing remote team collaboration"
    ]);

    const handleResearchProductDetails = async () => {
        setIsResearchProductLoading(true);
        try {
            // First API call
            const [productResponse] = await Promise.all([
                fetch(apiEndpoint + 'jobsCrawlWebsite', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        ...formData,
                        website: formData.website,
                        maxPages: 20,
                        competitor: ''
                    })
                })
            ]);

            if (!productResponse.ok) {
                throw new Error(`HTTP error! status: ${productResponse.status}`);
            }

            const [productData] = await Promise.all([
                productResponse.json()
            ]);

            // Second API call after first one completes
            const productResponseCompany2 = await fetch(apiEndpoint + 'jobsCrawlWebsite', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    ...formData,
                    website: formData.competitor,
                    maxPages: 20,
                    competitor: ''
                })
            });

            if (!productResponseCompany2.ok) {
                throw new Error(`HTTP error! status: ${productResponseCompany2.status}`);
            }

            const competitorData = await productResponseCompany2.json();

            //console.log('Product Data:', productData);
            //console.log('Competitor Data:', competitorData);

            setResearchProductData(productData);
            setResearchCompetitorData(competitorData);
        } catch (error) {
            //console.error('Error fetching research details:', error);
            setError(error.message);
        } finally {
            setIsResearchProductLoading(false);
            setIsResearchCompetitorLoading(false);
        }
    };

    const { recentPrompts, setRecentPrompts } = useRecentPrompts(user);

    const validateFormFields = (formDataToCheck) => {
        const requiredFields = [
            'promptCompanyName',
            'promptProductName',
            'promptCustomerRole',
            'promptCustomerName',
            'promptCustomerProblem'
        ];

        const emptyFields = requiredFields.filter(field => !formDataToCheck[field]);
        return emptyFields;
    };


    const handleFieldBlur = (e) => {
        const emptyFields = validateFormFields(formData);
        if (emptyFields.length > 0) {
            //setError('Please fill in all required fields');
        } else {
            //allowGeneration = true;
            setAllowGeneration(true);
            setError(null);
        }
    };

    const generateStory = async (e) => {
        if (!allowGeneration) return;

        e.preventDefault();
        setIsFormSubmitted(true);
        logCustomEvent('gen_story', {});

        // Input validation
        const requiredFields = [
            'promptCompanyName',
            'promptProductName',
            'promptCustomerRole',
            'promptCustomerName',
            'promptCustomerProblem'
        ];



        const emptyFields = validateFormFields(formData);
        if (emptyFields.length > 0) {
            //setError('Please fill in all required fields');
            return;
        }

        // Initialize state
        setError(null);
        setTimer(0);
        setIsTimerRunning(true);
        setLoading(true);
        setShowProgressBar(true);

        // Setup for storing results
        let producedResult;
        let storyResult;
        let businessAnalysisDataFeeder;
        let salesAnalysisDataFeeder;
        let storySpeakerSummaryFeeder;
        let storyChaptersDataFeeder;
        let businessTransformationFeeder;
        let businessCostOfInactionFeeder;
        let personaDataFeeder;
        let researchDataFeeder;
        let businessNewsFeeder;
        let industryTrendsFeeder;
        let businessModelDataFeeder;
        let maxDeepDataFeeder;

        try {


            try {
                const db = getFirestore();
                const promptCacheRef = collection(db, "gen-prompt-cache");

                // Check if a similar prompt already exists in the cache
                const q = query(promptCacheRef,
                    where("promptCompanyName", "==", formData.promptCompanyName),
                    where("promptProductName", "==", formData.promptProductName),
                    where("promptCustomerRole", "==", formData.promptCustomerRole),
                    where("promptCustomerName", "==", formData.promptCustomerName),
                    where("promptCustomerProblem", "==", formData.promptCustomerProblem),
                    where("userId", "==", user?.uid)
                );

                const querySnapshot = await getDocs(q);

                // Only save if no matching document exists
                if (querySnapshot.empty) {
                    const currentDate = new Date();
                    const timestamp = serverTimestamp();

                    const newPromptCache = await addDoc(promptCacheRef, {
                        // Form fields
                        promptCompanyName: formData.promptCompanyName,
                        promptProductName: formData.promptProductName,
                        promptCustomerRole: formData.promptCustomerRole,
                        promptCustomerName: formData.promptCustomerName,
                        promptCustomerProblem: formData.promptCustomerProblem,

                        // User info
                        userId: user?.uid,

                        // Date/time
                        date: currentDate.toLocaleDateString(),
                        time: currentDate.toLocaleTimeString(),
                        timestamp: timestamp,

                        // Story tone settings
                        storyTone: {
                            storyIntensity: storyTone.storyIntensity,
                            storyLength: storyTone.storyLength,
                            story: storyTone.story,
                            research: storyTone.research,
                            storyTone: storyTone.storyTone
                        }
                    });

                    // Get the document data with the ID from the addDoc result
                    const newPromptDocRef = doc(db, "gen-prompt-cache", newPromptCache.id);
                    const newPromptDoc = await getDoc(newPromptDocRef);

                    // Add the new prompt to the recent prompts state with proper document data
                    setRecentPrompts(prev => [
                        ...prev,
                        {
                            id: newPromptCache.id,
                            ...newPromptDoc.data()
                        }
                    ]);

                    // setRecentPrompts(prev => [...prev, {
                    //     promptCompanyName: formData.promptCompanyName,
                    //     promptProductName: formData.promptProductName,
                    //     promptCustomerRole: formData.promptCustomerRole,
                    //     promptCustomerName: formData.promptCustomerName,
                    //     promptCustomerProblem: formData.promptCustomerProblem,

                    //     // User info
                    //     userId: user?.uid,

                    //     // Date/time
                    //     date: currentDate.toLocaleDateString(),
                    //     time: currentDate.toLocaleTimeString(),
                    //     timestamp: timestamp,

                    //     // Story tone settings
                    //     storyTone: {
                    //         storyIntensity: storyTone.storyIntensity,
                    //         storyLength: storyTone.storyLength,
                    //         story: storyTone.story,
                    //         research: storyTone.research,
                    //         storyTone: storyTone.storyTone
                    //     }
                    // }]);
                }
            } catch (error) {
                console.error("Error saving prompt cache:", error);
                // Continue execution even if cache save fails
            }


            // Helper function for API calls with retry
            const callAPIWithRetry = async (apiCall, params, maxRetries = 2, retryDelay = 3000) => {
                let lastError;
                for (let attempt = 0; attempt <= maxRetries; attempt++) {
                    try {
                        return await apiCall(params);
                    } catch (error) {
                        lastError = error;
                        if (attempt < maxRetries) {
                            //console.log(`Retrying... Attempt ${attempt + 1} of ${maxRetries}`);
                            await new Promise(resolve => setTimeout(resolve, retryDelay));
                        }
                    }
                }
                throw lastError;
            };

            // 1. Deep Test...





            // 1. Research call
            try {
                setApiCalls(prev => ({ ...prev, research: true }));
                setCurrentStep("Starting Research");
                const researchStartTime = Date.now();

                const researchResponse = await fetch(apiEndpoint + 'jobsResearcher?' + new URLSearchParams(formData));
                if (!researchResponse.ok) throw new Error(`HTTP error! status: ${researchResponse.status}`);

                const researchResult = await researchResponse.json();
                researchDataFeeder = researchResult;
                // console.log('researchDataFeeder', researchDataFeeder);
                //console.log('researchResult', researchResult);

                // // Call jobsResearchDeep with the research results
                try {
                    //console.log('Calling jobsResearchDeep with research results...');
                    const jobsResearchDeepFunction = httpsCallable(functions, 'jobsResearchDeepStory');
                    const deepResearchResponse = await jobsResearchDeepFunction({
                        ...formData,
                        synthData: researchResult,
                        tone: storyTone.research,
                        version: 1,
                        mediaProcessing: 0,
                    });

                    //setResearchData(prev => ({ ...prev, researchDeepData: deepResearchResponse }));
                    setResearchDeepData(deepResearchResponse.data.researchDeepData);

                    const deepResearchResult = deepResearchResponse.data;
                    //console.log('Deep Research Result:', deepResearchResult);
                } catch (deepResearchError) {
                    //console.error("Deep Research API error:", deepResearchError);
                    // Continue with the flow even if deep research fails
                }








                try {
                    //console.log('Calling jobsBusinessModel with research results...');
                    const jobsBusinessModelFunction = httpsCallable(functions, 'jobsBusinessModel');
                    const businessModelResponse = await jobsBusinessModelFunction({
                        ...formData,
                        synthData: researchResult,
                        tone: storyTone.research,
                        version: 1,
                        mediaProcessing: 0,
                    });

                    //console.log('Business Model Response:', businessModelResponse);

                    //setResearchData(prev => ({ ...prev, researchDeepData: deepResearchResponse }));
                    //setBusinessModelData(businessModelResponse.data.businessModelData);

                    const businessModelResult = businessModelResponse.data;
                    setBusinessModelData(businessModelResult.businessModelData);
                    //console.log('Business Model Result:', businessModelResult);
                } catch (businessModelError) {
                    //console.error("Business Model API error:", businessModelError);
                    // Continue with the flow even if business model fails
                }


                setResearchData(researchResult);
                setApiCalls(prev => ({ ...prev, research: false }));
                setSectionTimes(prev => ({ ...prev, research: Date.now() - researchStartTime }));
                handleOpen(1);
                playSound();
            } catch (error) {
                //console.error("Research API error:", error);
                setError(`Research failed: ${error.message}`);
                throw error;
            }

            // 2. Media Research (in parallel)
            // const mediaResearchPromise = (async () => {
            //     try {





            // setCurrentStep("Media Research");
            // setApiCalls(prev => ({ ...prev, mediaResearch: true }));
            // const startTime = Date.now();



            // // try {
            // //     // console.log('Calling jobsMaxDeep with research results...');
            // //     const jobsMaxDeepFunction = httpsCallable(functions, 'jobsMaxConversationDeep');
            // //     const maxDeepResponse = await jobsMaxDeepFunction({
            // //         ...formData,
            // //         //synthData: researchResult,
            // //         tone: storyTone.research,
            // //         version: 1,
            // //         mediaProcessing: 0,
            // //     });

            // //     // Update this line to use the correct property
            // //     setMaxDeepData(maxDeepResponse.data.researchDeepData);

            // //     const maxDeepResult = maxDeepResponse.data;
            // //     maxDeepDataFeeder = maxDeepResult;
            // //     //setApiCalls(prev => ({ ...prev, mediaResearch: false }));
            // //     //setApiCalls(prev => ({ ...prev, maxDeep: false }));
            // //     setApiCallsCompleted(prev => ({ ...prev, maxDeepData: true }));
            // //     //setApiCalls(prev => ({ ...prev, mediaResearch: false }));
            // //     //setApiCallsCompleted(prev => ({ ...prev, mediaResearch: true }));
            // //     //console.log('Max Deep Result:', maxDeepResult);
            // //     //console.log("maxDeepData:", maxDeepData);
            // // } catch (maxDeepError) {
            // //     console.error("Max Deep API error:", maxDeepError);
            // //     // Continue with the flow even if max deep research fails
            // // }



            // // const runTranscript = httpsCallable(functions, 'runTranscript');
            // // const transcriptResult = await callAPIWithRetry(
            // //     params => runTranscript(params),
            // //     {
            // //         promptCompanyName: formData.promptCompanyName,
            // //         promptProductName: formData.promptProductName
            // //     }
            // // );

            // // // Format results
            // // const formattedResults = transcriptResult.data.video_results?.map(video => ({
            // //     title: video.title,
            // //     link: video.link,
            // //     thumbnail: video.thumbnail?.static,
            // //     duration: video.duration,
            // //     views: video.views,
            // //     published_date: video.published_date
            // // })) || [];

            // // setTranscriptResults(formattedResults);
            // // setApiCalls(prev => ({ ...prev, mediaResearch: false }));
            // // setApiCallsCompleted(prev => ({ ...prev, mediaResearch: true }));
            // // setSectionTimes(prev => ({ ...prev, mediaResearch: Date.now() - startTime }));

            // // // YouTube Transcript processing
            // // setApiCalls(prev => ({ ...prev, eli5Media: true }));
            // // setCurrentStep("Reviewing Media");

            // // const top5Links = formattedResults.slice(0, 5).map(result => result.link);
            // // const runYouTubeTranscript = httpsCallable(functions, 'runYouTubeTranscript');

            // // const ytResult = await callAPIWithRetry(
            // //     params => runYouTubeTranscript(params),
            // //     {
            // //         videoLinks: top5Links,
            // //         promptCompanyName: formData.promptCompanyName,
            // //         promptProductName: formData.promptProductName,
            // //         promptCustomerName: formData.promptCustomerName,
            // //         promptCustomerRole: formData.promptCustomerRole,
            // //         promptCustomerProblem: formData.promptCustomerProblem,
            // //         result: formattedResults
            // //     }
            // // );

            // // setTranscriptData(ytResult.data);

            // // // ELI5 Media Analysis
            // // const eli5MediaStartTime = Date.now();
            // // setCurrentStep("Deep Analysis");

            // // const jobsELI5b = httpsCallable(functions, 'jobsELI5b');
            // // const eli5Result = await callAPIWithRetry(
            // //     params => jobsELI5b(params),
            // //     {
            // //         ...formData,
            // //         tone: storyTone.research,
            // //         version: 5,
            // //         mediaProcessing: 1,
            // //         synthData: ytResult.data.individualAnalyses,
            // //         optionalInstructions: ''
            // //     }
            // // );

            // //setEli5DataMedia(eli5Result.data);
            // setApiCalls(prev => ({ ...prev, eli5Media: false }));
            // //setSectionTimes(prev => ({ ...prev, eli5Media: Date.now() - eli5MediaStartTime }));
            // setApiCallsCompleted(prev => ({ ...prev, eli5Media: true }));


            //return eli5Result.data;
            // } catch (error) {
            //     console.error("Media Research error:", error);
            //     setApiCalls(prev => ({ ...prev, mediaResearch: false, eli5Media: false }));
            //     setError(`Media research failed: ${error.message}`);
            //     // Don't throw - allow other processes to continue
            //     return null;
            // }
            //   })();









            try {
                setCurrentStep("Gathering Research");

                // Create promises for all three operations
                const businessNewsPromise = (async () => {
                    try {
                        const businessNewsStartTime = Date.now();
                        setApiCalls(prev => ({ ...prev, businessNews: true }));

                        const jobsBusinessNewsDeep = httpsCallable(functions, 'jobsBizDeep');
                        const businessNewsResult = await callAPIWithRetry(
                            params => jobsBusinessNewsDeep(params),
                            formData
                        );

                        businessNewsFeeder = businessNewsResult.data;
                        setBusinessNewsData(businessNewsResult.data);
                        setApiCalls(prev => ({ ...prev, businessNews: false }));
                        setApiCallsCompleted(prev => ({ ...prev, businessNews: true }));
                        setSectionTimes(prev => ({ ...prev, businessNews: Date.now() - businessNewsStartTime }));

                        // ELI5 Business
                        const eli5StartTime = Date.now();
                        setApiCalls(prev => ({ ...prev, eli5Business: true }));
                        setCurrentStep("Summarizing Insights");

                        const jobsELI5b = httpsCallable(functions, 'jobsELI5b');
                        const eli5Result = await callAPIWithRetry(
                            params => jobsELI5b(params),
                            {
                                ...formData,
                                tone: storyTone.research,
                                version: 2,
                                synthData: businessNewsResult.data
                            }
                        );

                        setEli5DataBusiness(eli5Result.data);
                        setApiCalls(prev => ({ ...prev, eli5Business: false }));
                        setSectionTimes(prev => ({ ...prev, eli5Business: Date.now() - eli5StartTime }));
                        setApiCallsCompleted(prev => ({ ...prev, eli5Business: true }));
                        playSound();
                        handleOpen(2);
                        return { success: true };
                    } catch (error) {
                        console.error("Business News error:", error);
                        setApiCalls(prev => ({ ...prev, businessNews: false, eli5Business: false }));
                        setError(`Business insights failed: ${error.message}`);
                        return { success: false, error };
                    }
                })();

                // const customerLanguagePromise = (async () => {
                //     try {
                //         const deepLanguageStartTime = Date.now();
                //         setSectionTimes(prev => ({ ...prev, deepLanguage: Date.now() }));
                //         setApiCalls(prev => ({ ...prev, deepLanguage: true }));
                //         setCurrentStep("Customer Language");

                //         const jobsMaxDeepFunction = httpsCallable(functions, 'jobsMaxConversationDeep');
                //         const maxDeepResponse = await jobsMaxDeepFunction({
                //             ...formData,
                //             tone: storyTone.research,
                //             version: 1,
                //             mediaProcessing: 0,
                //         });

                //         setMaxDeepData(maxDeepResponse.data.researchDeepData);
                //         setSectionTimes(prev => ({ ...prev, deepLanguage: Date.now() - deepLanguageStartTime }));
                //         setApiCalls(prev => ({ ...prev, deepLanguage: false }));
                //         setApiCallsCompleted(prev => ({ ...prev, deepLanguage: true }));

                //         const maxDeepResult = maxDeepResponse.data;
                //         maxDeepDataFeeder = maxDeepResult;

                //         handleOpen(4);
                //         playSound();
                //         return { success: true };
                //     } catch (error) {
                const customerLanguagePromise = (async () => {
                    try {
                        const deepLanguageStartTime = Date.now();
                        setSectionTimes(prev => ({ ...prev, deepLanguage: Date.now() }));
                        setApiCalls(prev => ({ ...prev, deepLanguage: true }));
                        setCurrentStep("Customer Language");

                        const jobsMaxDeepFunction = httpsCallable(functions, 'jobsMaxConversationDeep');

                        // Use the callAPIWithRetry function instead of direct API call
                        const maxDeepResponse = await callAPIWithRetry(
                            () => jobsMaxDeepFunction({
                                ...formData,
                                tone: storyTone.research,
                                version: 1,
                                mediaProcessing: 0,
                            }),
                            null, // No additional params needed since they're included in the function call
                            2,    // maxRetries (using default value from callAPIWithRetry)
                            3000  // retryDelay (using default value from callAPIWithRetry)
                        );

                        setMaxDeepData(maxDeepResponse.data.researchDeepData);
                        setSectionTimes(prev => ({ ...prev, deepLanguage: Date.now() - deepLanguageStartTime }));
                        setApiCalls(prev => ({ ...prev, deepLanguage: false }));
                        setApiCallsCompleted(prev => ({ ...prev, deepLanguage: true }));

                        const maxDeepResult = maxDeepResponse.data;
                        maxDeepDataFeeder = maxDeepResult;

                        handleOpen(4);
                        playSound();
                        return { success: true };
                    } catch (error) {
                        console.error("Max Deep API error:", error);
                        setApiCalls(prev => ({ ...prev, deepLanguage: false }));
                        return { success: false, error };
                    }
                })();

                const industryTrendsPromise = (async () => {
                    try {
                        setApiCalls(prev => ({ ...prev, industryTrends: true }));
                        setCurrentStep("Industry Trends");
                        const trendsStartTime = Date.now();

                        const jobsIndDeep = httpsCallable(functions, 'jobsIndDeep');
                        const trendsResult = await callAPIWithRetry(
                            params => jobsIndDeep(params),
                            formData
                        );

                        industryTrendsFeeder = trendsResult.data;
                        setIndustryTrendsData(trendsResult.data);
                        setApiCalls(prev => ({ ...prev, industryTrends: false }));
                        setApiCallsCompleted(prev => ({ ...prev, industryTrends: true }));
                        setSectionTimes(prev => ({ ...prev, industryTrends: Date.now() - trendsStartTime }));

                        // ELI5 Industry
                        const eli5IndustryStartTime = Date.now();
                        setApiCalls(prev => ({ ...prev, eli5Industry: true }));
                        setCurrentStep("Summarizing Trends");

                        const jobsELI5Trends = httpsCallable(functions, 'jobsELI5b');
                        const eli5Result = await callAPIWithRetry(
                            params => jobsELI5Trends(params),
                            {
                                ...formData,
                                tone: storyTone.research,
                                version: 3,
                                synthData: trendsResult.data
                            }
                        );

                        setEli5DataIndustry(eli5Result.data);
                        setSectionTimes(prev => ({ ...prev, eli5Industry: Date.now() - eli5IndustryStartTime }));
                        setApiCalls(prev => ({ ...prev, eli5Industry: false }));
                        setApiCallsCompleted(prev => ({ ...prev, eli5Industry: true }));
                        handleOpen(3);
                        playSound();
                        return { success: true };
                    } catch (error) {
                        console.error("Industry Trends error:", error);
                        setApiCalls(prev => ({ ...prev, industryTrends: false, eli5Industry: false }));
                        setError(`Industry trends failed: ${error.message}`);
                        return { success: false, error };
                    }
                })();

                // Wait for all promises to complete
                const results = await Promise.all([
                    businessNewsPromise,
                    customerLanguagePromise,
                    industryTrendsPromise
                ]);

                // Check if any of them failed
                const failures = results.filter(result => !result.success);
                if (failures.length > 0) {
                    const firstError = failures[0].error;
                    throw firstError; // Throw the first error encountered
                }
            } catch (error) {
                console.error("Research process error:", error);
                setError(`Research process failed: ${error.message}`);
                throw error;
            }






            // 3. Business News
            // try {
            //     setCurrentStep("Business Insights");
            //     const businessNewsStartTime = Date.now();
            //     setApiCalls(prev => ({ ...prev, businessNews: true }));

            //     const jobsBusinessNewsDeep = httpsCallable(functions, 'jobsBizDeep');
            //     const businessNewsResult = await callAPIWithRetry(
            //         params => jobsBusinessNewsDeep(params),
            //         formData
            //     );

            //     businessNewsFeeder = businessNewsResult.data;
            //     setBusinessNewsData(businessNewsResult.data);
            //     setApiCalls(prev => ({ ...prev, businessNews: false }));
            //     setApiCallsCompleted(prev => ({ ...prev, businessNews: true }));
            //     setSectionTimes(prev => ({ ...prev, businessNews: Date.now() - businessNewsStartTime }));

            //     // ELI5 Business
            //     const eli5StartTime = Date.now();
            //     setApiCalls(prev => ({ ...prev, eli5Business: true }));
            //     setCurrentStep("Summarizing Insights");

            //     const jobsELI5b = httpsCallable(functions, 'jobsELI5b');
            //     const eli5Result = await callAPIWithRetry(
            //         params => jobsELI5b(params),
            //         {
            //             ...formData,
            //             tone: storyTone.research,
            //             version: 2,
            //             synthData: businessNewsResult.data
            //         }
            //     );

            //     setEli5DataBusiness(eli5Result.data);
            //     setApiCalls(prev => ({ ...prev, eli5Business: false }));
            //     setSectionTimes(prev => ({ ...prev, eli5Business: Date.now() - eli5StartTime }));
            //     setApiCallsCompleted(prev => ({ ...prev, eli5Business: true }));
            //     playSound();
            //     handleOpen(2);
            // } catch (error) {
            //     console.error("Business News error:", error);
            //     setApiCalls(prev => ({ ...prev, businessNews: false, eli5Business: false }));
            //     setError(`Business insights failed: ${error.message}`);
            //     throw error;
            // }


            // try {
            //     //setApiCalls(prev => ({ ...prev, maxDeepData: true }));
            //     //setApiCalls(prev => ({ ...prev, eli5Media: true }));
            //     setApiCalls(prev => ({ ...prev, deepLanguage: true }));

            //     setCurrentStep("Customer Language");
            //     //console.log('Calling jobsMaxDeep with research results...');
            //     const jobsMaxDeepFunction = httpsCallable(functions, 'jobsMaxConversationDeep');
            //     const maxDeepResponse = await jobsMaxDeepFunction({
            //         ...formData,
            //         //synthData: researchResult,
            //         tone: storyTone.research,
            //         version: 1,
            //         mediaProcessing: 0,
            //     });

            //     // Update this line to use the correct property
            //     setMaxDeepData(maxDeepResponse.data.researchDeepData);
            //     // setApiCalls(prev => ({ ...prev, maxDeepData: false }));
            //     // setApiCallsCompleted(prev => ({ ...prev, maxDeepData: true }));
            //     setApiCalls(prev => ({ ...prev, deepLanguage: false }));

            //     setApiCallsCompleted(prev => ({ ...prev, deepLanguage: true }));

            //     const maxDeepResult = maxDeepResponse.data;
            //     maxDeepDataFeeder = maxDeepResult;
            //     // console.log('Max Deep Result:', maxDeepResult);
            //     // console.log("maxDeepData:", maxDeepData);

            //     handleOpen(4);
            //     playSound();
            // } catch (maxDeepError) {
            //     console.error("Max Deep API error:", maxDeepError);
            //     // Continue with the flow even if max deep research fails
            // }

            // // 4. Industry Trends
            // try {
            //     setApiCalls(prev => ({ ...prev, industryTrends: true }));
            //     setCurrentStep("Industry Trends");
            //     const trendsStartTime = Date.now();

            //     const jobsIndDeep = httpsCallable(functions, 'jobsIndDeep');
            //     const trendsResult = await callAPIWithRetry(
            //         params => jobsIndDeep(params),
            //         formData
            //     );

            //     industryTrendsFeeder = trendsResult.data;
            //     setIndustryTrendsData(trendsResult.data);
            //     setApiCalls(prev => ({ ...prev, industryTrends: false }));
            //     setApiCallsCompleted(prev => ({ ...prev, industryTrends: true }));
            //     setSectionTimes(prev => ({ ...prev, industryTrends: Date.now() - trendsStartTime }));

            //     // ELI5 Industry
            //     const eli5IndustryStartTime = Date.now();
            //     setApiCalls(prev => ({ ...prev, eli5Industry: true }));
            //     setCurrentStep("Summarizing Trends");

            //     const jobsELI5Trends = httpsCallable(functions, 'jobsELI5b');
            //     const eli5Result = await callAPIWithRetry(
            //         params => jobsELI5Trends(params),
            //         {
            //             ...formData,
            //             tone: storyTone.research,
            //             version: 3,
            //             synthData: trendsResult.data
            //         }
            //     );

            //     setEli5DataIndustry(eli5Result.data);
            //     setSectionTimes(prev => ({ ...prev, eli5Industry: Date.now() - eli5IndustryStartTime }));
            //     setApiCalls(prev => ({ ...prev, eli5Industry: false }));
            //     setApiCallsCompleted(prev => ({ ...prev, eli5Industry: true }));
            //     handleOpen(3);
            //     playSound();
            // } catch (error) {
            //     console.error("Industry Trends error:", error);
            //     setApiCalls(prev => ({ ...prev, industryTrends: false, eli5Industry: false }));
            //     setError(`Industry trends failed: ${error.message}`);
            //     throw error;
            // }

            // 5. Persona
            try {
                setApiCalls(prev => ({ ...prev, persona: true }));
                setCurrentStep("Stakeholder Analysis");
                const personaStartTime = Date.now();

                // console.log('__________________________________________________________');
                // console.log('researchDataFeeder', researchDataFeeder);
                // console.log('__________________________________________________________');

                const jobsCast = httpsCallable(functions, 'jobsCast');
                const personaResult = await callAPIWithRetry(
                    params => jobsCast(params),
                    {
                        ...formData,
                        podcastInsights: researchDataFeeder.podcastInsights

                        // researchDataFeeder && researchDataFeeder.podcastInsights
                        //     ? JSON.stringify(researchDataFeeder.podcastInsights)
                        //     : JSON.stringify([])  // or some default value
                    }
                );

                personaDataFeeder = personaResult.data;
                setPersonaData(personaResult.data);
                setApiCalls(prev => ({ ...prev, persona: false }));
                setSectionTimes(prev => ({ ...prev, persona: Date.now() - personaStartTime }));
                setApiCallsCompleted(prev => ({ ...prev, persona: true }));
                handleOpen(5);
                playSound();
            } catch (error) {
                console.error("Persona error:", error);
                setApiCalls(prev => ({ ...prev, persona: false }));
                setError(`Stakeholder analysis failed: ${error.message}`);
                throw error;
            }

            // Wait a moment before proceeding with story generation
            //await new Promise(resolve => setTimeout(resolve, 00));




            //await mediaResearchPromise;

            // 6. Story Generation
            try {
                setApiCalls(prev => ({ ...prev, story: true }));
                setCurrentStep("Thinking Story");
                const storyStartTime = Date.now();

                const jobsStoryteller = httpsCallable(functions, 'jobsStoryteller');
                const storyResponse = await callAPIWithRetry(
                    params => jobsStoryteller(params),
                    {
                        customerInsights: researchDataFeeder && researchDataFeeder.customerInsights
                            ? researchDataFeeder.customerInsights
                            : [],
                        productInsights: researchDataFeeder && researchDataFeeder.productInsights
                            ? researchDataFeeder.productInsights
                            : [],
                        podcastInsights: researchDataFeeder && researchDataFeeder.podcastInsights
                            ? researchDataFeeder.podcastInsights
                            : [],
                        personas: personaDataFeeder || [],
                        promptCompanyName: formData?.promptCompanyName || "",
                        promptProductName: formData?.promptProductName || "",
                        promptCustomerName: formData?.promptCustomerName || "",
                        promptCustomerRole: formData?.promptCustomerRole || "",
                        promptCustomerProblem: formData?.promptCustomerProblem || "",
                        promptInspiration: formData?.promptCustomerProblem || "",
                        businessNewsResult: businessNewsFeeder || {}, customerInsights: researchDataFeeder && researchDataFeeder.customerInsights
                            ? researchDataFeeder.customerInsights
                            : [],
                        productInsights: researchDataFeeder && researchDataFeeder.productInsights
                            ? researchDataFeeder.productInsights
                            : [],
                        podcastInsights: researchDataFeeder && researchDataFeeder.podcastInsights
                            ? researchDataFeeder.podcastInsights
                            : [],
                        personas: personaDataFeeder || [],
                        promptCompanyName: formData?.promptCompanyName || "",
                        promptProductName: formData?.promptProductName || "",
                        promptCustomerName: formData?.promptCustomerName || "",
                        promptCustomerRole: formData?.promptCustomerRole || "",
                        promptCustomerProblem: formData?.promptCustomerProblem || "",
                        promptInspiration: formData?.promptCustomerProblem || "",
                        businessNewsResult: businessNewsFeeder || {},
                        industryTrendsResult: industryTrendsFeeder || {},
                        storyIntensity: storyTone?.storyIntensity || "medium",
                        storyLength: storyTone?.storyLength || "medium",
                        tone: storyTone?.story || "neutral"
                        //industryTrendsResult: industryTrendsFeeder || {},
                        //storyIntensity: storyTone?.storyIntensity || "medium",
                        //storyLength: storyTone?.storyLength || "medium",
                        //tone: storyTone?.story || "neutral"
                    }
                );

                storyResult = storyResponse.data;
                setStoryData(storyResult);
                setApiCalls(prev => ({ ...prev, story: false }));
                setApiCallsCompleted(prev => ({ ...prev, story: true }));
                setSectionTimes(prev => ({ ...prev, story: Date.now() - storyStartTime }));
                playSound();
            } catch (error) {
                console.error("Story error:", error);
                setApiCalls(prev => ({ ...prev, story: false }));
                setError(`Story generation failed: ${error.message}`);
                throw error;
            }

            // 7. Producer
            try {
                setApiCalls(prev => ({ ...prev, producer: true }));
                const producerStartTime = Date.now();
                setCurrentStep("Framing Story");

                const jobsStoryProducer = httpsCallable(functions, 'jobsStoryProducer');
                const producerResponse = await callAPIWithRetry(
                    params => jobsStoryProducer(params),
                    {
                        // Form data
                        promptCompanyName: formData.promptCompanyName,
                        promptProductName: formData.promptProductName,
                        promptCustomerName: formData.promptCustomerName,
                        promptCustomerRole: formData.promptCustomerRole,
                        promptCustomerProblem: formData.promptCustomerProblem,
                        storyIntensity: storyTone.storyIntensity,
                        storyLength: storyTone.storyLength,
                        // Story data
                        story: storyResult,
                        story_scenes: storyResult.story.scenes,
                        story_introduction: storyResult.story.introduction,
                        story_conclusion: storyResult.story.conclusion,
                        story_personas: personaDataFeeder,
                        story_introduction_summary: storyResult.story.introduction,
                        story_conclusion_summary: storyResult.story.conclusion,
                        story_conclusion_benefits: storyResult.story.conclusion,
                        // Prompts
                        titlePrompt: `Create a compelling title for the story of how ${formData.promptCustomerName} solved ${formData.promptCustomerProblem} using ${formData.promptProductName}`,
                        openingPrompt: `Create an engaging opening for the story of how ${formData.promptCustomerName} implemented ${formData.promptProductName}`,
                        closingPrompt: `Create a powerful conclusion highlighting how ${formData.promptProductName} helped ${formData.promptCustomerName} solve ${formData.promptCustomerProblem}`,
                        // Additional settings
                        ...formData,
                        tone: storyTone.story
                    }
                );

                producedResult = producerResponse.data;
                setProducedResult(producedResult);
                setProducedStory(producedResult);
                setStoryVisualizationData(producedResult);
                setApiCallsCompleted(prev => ({ ...prev, producer: true }));
                setApiCalls(prev => ({ ...prev, producer: false }));
                setSectionTimes(prev => ({ ...prev, producer: Date.now() - producerStartTime }));
                playSound();
            } catch (error) {
                console.error("Producer error:", error);
                setApiCalls(prev => ({ ...prev, producer: false }));
                setError(`Story framing failed: ${error.message}`);
                throw error;
            }

            // 8. Start parallel processes for remaining components
            try {
                // Start all parallel processes
                setApiCalls(prev => ({
                    ...prev,
                    storyChapters: true,
                    salesStrategy: true,
                    businessAnalysis: true,
                    salesAnalysis: true
                }));

                const storyChaptersStartTime = Date.now();
                const businessAnalysisStartTime = Date.now();
                const salesAnalysisStartTime = Date.now();

                // 8.1 Story Chapters
                const storyChaptersPromise = (async () => {
                    try {
                        setCurrentStep("Generating Chapters");

                        const storyChaptersCallable = httpsCallable(functions, 'jobsStoryChapters');
                        const chaptersResult = await callAPIWithRetry(
                            params => storyChaptersCallable(params),
                            {
                                promptCompanyName: formData?.promptCompanyName || "",
                                promptProductName: formData?.promptProductName || "",
                                promptCustomerName: formData?.promptCustomerName || "",
                                promptCustomerRole: formData?.promptCustomerRole || "",
                                promptCustomerProblem: formData?.promptCustomerProblem || "",
                                producedWrapper: producedResult || {},
                                personaData: personaDataFeeder || [],
                                researchData: researchDataFeeder || {},
                                executiveInterview: researchDataFeeder && researchDataFeeder.podcastInsights
                                    ? researchDataFeeder.podcastInsights
                                    : [],
                                businessNewsData: businessNewsFeeder || {},
                                industryTrendsData: industryTrendsFeeder || {},
                                tone: storyTone?.story || "neutral",
                                storyIntensity: storyTone?.storyIntensity || "medium",
                                customerInsights: researchDataFeeder && researchDataFeeder.customerInsights
                                    ? researchDataFeeder.customerInsights
                                    : [],
                                productInsights: researchDataFeeder && researchDataFeeder.productInsights
                                    ? researchDataFeeder.productInsights
                                    : [],
                                podcastInsights: researchDataFeeder && researchDataFeeder.podcastInsights
                                    ? researchDataFeeder.podcastInsights
                                    : [],
                            }
                        );

                        storyChaptersDataFeeder = chaptersResult.data;
                        setStoryChaptersData(storyChaptersDataFeeder);
                        setApiCalls(prev => ({ ...prev, storyChapters: false }));
                        setApiCallsCompleted(prev => ({ ...prev, storyChapters: true }));
                        setSectionTimes(prev => ({ ...prev, storyChapters: Date.now() - storyChaptersStartTime }));
                        playSound();

                        return storyChaptersDataFeeder;
                    } catch (error) {
                        console.error("Story Chapters error:", error);
                        setApiCalls(prev => ({ ...prev, storyChapters: false }));
                        setError(`Chapter generation failed: ${error.message}`);
                        return null;
                    }
                })();

                // 8.2 Business Analysis
                const businessAnalysisPromise = (async () => {
                    try {
                        setCurrentStep("Business Analysis");

                        const jobsBusinessAnalysis = httpsCallable(functions, 'jobsBusinessAnalysis');
                        const analysisResult = await callAPIWithRetry(
                            params => jobsBusinessAnalysis(params),
                            {
                                promptCompanyName: formData.promptCompanyName,
                                promptProductName: formData.promptProductName,
                                promptCustomerName: formData.promptCustomerName,
                                promptCustomerRole: formData.promptCustomerRole,
                                promptCustomerProblem: formData.promptCustomerProblem,
                                producedResult: JSON.stringify(producedResult),
                                tone: storyTone.research,
                                personaResult: JSON.stringify(personaDataFeeder),
                                executiveInterview: researchDataFeeder && researchDataFeeder.podcastInsights
                                    ? JSON.stringify(researchDataFeeder.podcastInsights)
                                    : JSON.stringify({}),
                            }
                        );

                        businessAnalysisDataFeeder = analysisResult.data;
                        setBusinessAnalysisData(businessAnalysisDataFeeder);
                        setApiCallsCompleted(prev => ({ ...prev, businessAnalysis: true }));
                        setApiCalls(prev => ({ ...prev, businessAnalysis: false }));
                        setSectionTimes(prev => ({ ...prev, businessAnalysis: Date.now() - businessAnalysisStartTime }));
                        playSound();

                        return businessAnalysisDataFeeder;
                    } catch (error) {
                        console.error("Business Analysis error:", error);
                        setApiCalls(prev => ({ ...prev, businessAnalysis: false }));
                        setError(`Business analysis failed: ${error.message}`);
                        return null;
                    }
                })();

                // Wait for the two main components before proceeding
                const [storyChaptersData, businessAnalysisData] = await Promise.all([
                    storyChaptersPromise,
                    businessAnalysisPromise
                ]);

                // 9. Story Speaker (depends on business analysis)
                if (businessAnalysisData) {
                    try {
                        const storySpeakerStartTime = Date.now();
                        setApiCalls(prev => ({ ...prev, storySpeaker: true }));
                        setCurrentStep("Generating Talktrack");

                        const storySpeakerCallable = httpsCallable(functions, 'jobsStorySpeaker');
                        const speakerResult = await callAPIWithRetry(
                            params => storySpeakerCallable(params),
                            {
                                formData: {
                                    promptCompanyName: formData.promptCompanyName,
                                    promptProductName: formData.promptProductName,
                                    promptCustomerName: formData.promptCustomerName,
                                    promptCustomerRole: formData.promptCustomerRole,
                                    promptCustomerProblem: formData.promptCustomerProblem,
                                    tone: storyTone.story
                                },
                                storyData: storyResult,
                                storyIntensity: storyTone.storyIntensity,
                                storyLength: storyTone.storyLength,
                                tone: storyTone.story,
                                businessAnalysis: businessAnalysisData
                            }
                        );

                        setStorySpeakerData(speakerResult.data);
                        setApiCalls(prev => ({ ...prev, storySpeaker: false }));
                        setApiCallsCompleted(prev => ({ ...prev, storySpeaker: true }));
                        setSectionTimes(prev => ({ ...prev, storySpeaker: Date.now() - storySpeakerStartTime }));
                        playSound();

                        // 10. Story Speaker Summary (depends on story speaker)
                        const storySpeakerSummaryStartTime = Date.now();
                        setApiCalls(prev => ({ ...prev, storySpeakerSummary: true }));
                        setCurrentStep("Generating Summary");

                        const jobsStorySpeakerSummary = httpsCallable(functions, 'jobsStorySpeakerSummary');
                        const summaryResult = await callAPIWithRetry(
                            params => jobsStorySpeakerSummary(params),
                            {
                                formData: {
                                    promptCompanyName: formData.promptCompanyName,
                                    promptProductName: formData.promptProductName,
                                    promptCustomerName: formData.promptCustomerName,
                                    promptCustomerRole: formData.promptCustomerRole,
                                    promptCustomerProblem: formData.promptCustomerProblem
                                },
                                storyData: speakerResult.data,
                                producerData: producedResult,
                                storyIntensity: storyTone.storyIntensity,
                                storyLength: storyTone.storyLength,
                                tone: storyTone.story,
                            }
                        );

                        storySpeakerSummaryFeeder = summaryResult.data;
                        setStorySpeakerSummaryResult(storySpeakerSummaryFeeder);
                        setApiCalls(prev => ({ ...prev, storySpeakerSummary: false }));
                        setApiCallsCompleted(prev => ({ ...prev, storySpeakerSummary: true }));
                        setSectionTimes(prev => ({ ...prev, storySpeakerSummary: Date.now() - storySpeakerSummaryStartTime }));
                        playSound();

                    } catch (error) {
                        console.error("Story Speaker error:", error);
                        setApiCalls(prev => ({ ...prev, storySpeaker: false, storySpeakerSummary: false }));
                        setError(`Talk track generation failed: ${error.message}`);
                    }
                }

                // 11. Business Transformation
                try {
                    setCurrentStep("Transformation Analysis");
                    setApiCalls(prev => ({ ...prev, businessTransformation: true }));

                    const jobsBusinessTransformationFromTo = httpsCallable(functions, 'jobsBusinessTransformationFromTo');
                    const transformationResult = await callAPIWithRetry(
                        params => jobsBusinessTransformationFromTo(params),
                        {
                            formData: {
                                promptCompanyName: formData.promptCompanyName,
                                promptProductName: formData.promptProductName,
                                promptCustomerName: formData.promptCustomerName,
                                promptCustomerRole: formData.promptCustomerRole,
                                promptCustomerProblem: formData.promptCustomerProblem
                            },
                            storyData: storyResult,
                            businessAnalysis: businessAnalysisDataFeeder,
                            tone: storyTone.story,
                        }
                    );


                    // jobsBusinessCostOfInaction
                    const jobsBusinessCostOfInaction = httpsCallable(functions, 'jobsBusinessCostOfInaction');
                    const costOfInactionResult = await callAPIWithRetry(
                        params => jobsBusinessCostOfInaction(params),
                        {
                            formData: {
                                promptCompanyName: formData.promptCompanyName,
                                promptProductName: formData.promptProductName,
                                promptCustomerName: formData.promptCustomerName,
                                promptCustomerRole: formData.promptCustomerRole,
                                promptCustomerProblem: formData.promptCustomerProblem
                            },
                            storyData: storyResult,
                            businessAnalysis: businessAnalysisDataFeeder,
                            tone: storyTone.story,
                        }
                    );

                    setBusinessCostOfInactionResult(costOfInactionResult.data);
                    businessCostOfInactionFeeder = costOfInactionResult.data;

                    // console.log('businessCostOfInactionFeeder', businessCostOfInactionFeeder);

                    setBusinessTransformationResult(transformationResult.data);
                    businessTransformationFeeder = transformationResult.data;
                    setApiCallsCompleted(prev => ({ ...prev, businessTransformation: true }));
                    setApiCalls(prev => ({ ...prev, businessTransformation: false }));
                    playSound();
                } catch (error) {
                    console.error("Business Transformation error:", error);
                    setApiCalls(prev => ({ ...prev, businessTransformation: false }));
                    setError(`Transformation analysis failed: ${error.message}`);
                }

                // 12. Sales Analysis
                try {
                    setCurrentStep("Sales Analysis");

                    const salesAnalysisCall = httpsCallable(functions, 'jobsSalesAnalysis');
                    const salesAnalysisResult = await callAPIWithRetry(
                        params => salesAnalysisCall(params),
                        {
                            promptCompanyName: formData.promptCompanyName,
                            promptProductName: formData.promptProductName,
                            promptCustomerName: formData.promptCustomerName,
                            promptCustomerRole: formData.promptCustomerRole,
                            promptCustomerProblem: formData.promptCustomerProblem,
                            storyChapters: storyChaptersDataFeeder,
                            podcastInsights: researchDataFeeder && researchDataFeeder.podcastInsights
                                ? JSON.stringify(researchDataFeeder.podcastInsights)
                                : JSON.stringify({}),
                            producedResult: producedResult,
                            personaResult: personaDataFeeder,
                            researchData: researchDataFeeder,
                            businessNewsData: businessNewsFeeder,
                            industryTrendsData: industryTrendsFeeder
                        }
                    );

                    setSalesAnalysisData(salesAnalysisResult.data);
                    salesAnalysisDataFeeder = salesAnalysisResult.data;
                    setApiCalls(prev => ({ ...prev, salesAnalysis: false }));
                    setSectionTimes(prev => ({ ...prev, salesAnalysis: Date.now() - salesAnalysisStartTime }));
                    setApiCallsCompleted(prev => ({ ...prev, salesAnalysis: true }));

                    try {
                        const storiesRef = collection(db, "stories");
                        const docRef = await addDoc(storiesRef, {
                            type: "tell",
                            userDisplayName: user?.displayName || "",
                            userEmail: user?.email || "",
                            userPhotoURL: user?.photoURL || "",
                            visibility: "private",
                            previewThumb: "blank",
                            name: formData.promptCustomerName,
                            description: formData.promptCustomerProblem,
                            producedResult: JSON.stringify(producedResult),
                            storyChapters: JSON.stringify(storyChaptersData),
                            formData: JSON.stringify(formData),
                            businessTransformation: JSON.stringify(businessTransformationFeeder),
                            producerResult: JSON.stringify(producedResult),
                            businessAnalysis: JSON.stringify(businessAnalysisData),
                            salesAnalysis: JSON.stringify(salesAnalysisDataFeeder),
                            storySpeakerSummary: JSON.stringify(storySpeakerSummaryFeeder),

                            // Add these critical fields
                            lastModified: serverTimestamp(),
                            role: {
                                writer: [user?.uid || ""],
                                reader: [user?.uid || ""],
                                owner: [user?.uid || ""],
                                commenter: [user?.uid || ""]
                            },
                            layoutType: "linear",
                            layoutCircleDistance: 70,
                            backgroundModel: "background_plain",
                            backgroundHeight: -5,
                            backgroundSky: "skyBackground1",
                            backgroundMatcap: "C8C8C8_3F3F3F_787878_5C5C5C",
                            navigation: "nav1",
                            imageStoryBackground: "/media/icon/B3-Image-Placeholder.png",
                            imageStoryLogo: "/media/icon/B3-Image-Placeholder.png",
                            analyticsViewTotal: 0,
                            analyticsLastViewed: serverTimestamp(),
                            cameraOrbitOn: 1,
                            messageUserId: user?.uid || "",

                            // Add uiStyle if needed
                            uiStyle: {
                                "nav-region-bg": "#111111",
                                "nav-progress-meter-bg": "#009ce0",
                                "super-subtitle-color": "#cccccc",
                                "nav-button-text-on": "#009ce0",
                                "nav-button-bg-off": "#b3b3b3",
                                "super-list-text-color": "#000000",
                                "super-list-icon-color": "#000000",
                                "nav-button-bg-on": "#222222",
                                "super-main-title-color": "#009ce0",
                                "super-main-bg-color": "#111111",
                                "super-secondary-bg-color": "#000000",
                                "nav-button-text-off": "#4d4d4d",
                                "super-highlight-color": "#009ce0",
                                "nav-progress-meter": "#808080"
                            },

                            createdAt: serverTimestamp()
                        });

                        // Get the document data after creation
                        const docSnap = await getDoc(doc(db, "stories", docRef.id));
                        if (docSnap.exists()) {
                            // console.log("Document data:", docSnap.data());
                            // console.log("Document ID:", docSnap.id);
                        } else {
                            //console.log("No such document!");
                        }

                    } catch (error) {
                        console.error("Error saving story to Firestore:", error);
                    }

                    playSound();
                } catch (error) {
                    console.error("Sales Analysis error:", error);
                    setApiCalls(prev => ({ ...prev, salesAnalysis: false }));
                    setError(`Sales analysis failed: ${error.message}`);
                }
            } catch (error) {
                console.error("Parallel processing error:", error);
                setError(`Process failed: ${error.message}`);
            }

            // Final completion steps
            setCurrentStep("Finished");
            setOpenPaths(true);
            setIsTimerRunning(false);

            playSound();

            // // Log all section durations for debugging
            // Object.entries(sectionTimes).forEach(([section, duration]) => {
            //     console.log(`${section}: ${formatSectionTime(duration)}`);
            // });

        } catch (error) {
            console.error("Generation process error:", error);
            setError(`Story generation failed: ${error.message}`);
            setIsTimerRunning(false);
        } finally {
            setLoading(false);
        }
    };


    // useEffect(() => {
    //     //console.log('Wardrobe Data:', wardrobeData);
    // }, [wardrobeData]);



    //const [storyBiteData, setStoryBiteData] = useState(null);

    const generateStoryBite = async (item) => {

        const randomVersion = Math.floor(Math.random() * 100000);
        logCustomEvent('gen_story_bite', {})

        const storyBiteResponse = await fetch(apiEndpoint + 'jobsStoryBite', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                producedWrapper: producedResult,
                story: storyData,
                storyData: storyData,
                personaData: personaData,
                researchData: researchData,
                businessNewsData: businessNewsData,
                industryTrendsData: industryTrendsData,
                formData: formData,
                transcriptData: transcriptData,
                version: randomVersion,
                executiveInterview: researchData.podcastInsights,
                tone: storyTone.story,
                storyIntensity: storyTone.storyIntensity,
                storyLength: storyTone.storyLength,
                item: item
            }),
        });

        const storyBiteResult = await storyBiteResponse.json();
        //console.log('StoryBite Generation API Response:', storyBiteResult);
        //setStoryBiteData(storyBiteResponse);
        playSound(); // Play sound after storyBite generation call
        return storyBiteResult;
    }









    const getHeadingClass = (apiCall, data) => {
        if (apiCall) return "text-zinc-600";
        if (data) return "text-zinc-100";
        return "text-zinc-600";
    };

    const formatSectionTime = (ms) => {
        if (ms === null) return '';
        const seconds = Math.floor(ms / 1000);
        const milliseconds = ms % 1000;
        return `${seconds}.${milliseconds.toString().padStart(3, '0')}s`;
    };

    // const pagination = {
    //     clickable: true,
    //     renderBullet: function (index, className) {
    //         return '<div className="' + className + ' w-8 h-8">' + (index + 1) + '</div>';
    //     },
    // };

    //    const storyPathRef = useRef();

    // const storyPathRef = useRef(null); // Explicitly initialize with null
    // const reactToPrintFn = useReactToPrint({
    //     contentRef: storyPathRef, // Change from content to contentRef
    //     // Add these options for better debugging
    //     onBeforeGetContent: () => //console.log("Before getting content"),
    //     onAfterPrint: () => //console.log("Printing completed"),
    //     onPrintError: (error) => //console.error("Print error:", error)
    // });

    const storyPathRef = useRef(null); // Explicitly initialize with null
    const reactToPrintFn = useReactToPrint({
        contentRef: storyPathRef,
        onBeforeGetContent: () => {
            //console.log("Before getting content");
        },
        onAfterPrint: () => console.log("Printing completed"),
        onPrintError: (error) => console.error("Print error:", error),
        pageStyle: `
            @page {
                size: auto;
                margin: 20mm 15mm 25mm 15mm;
            }
            @media print {
                html, body {
                    height: 100%;
                    margin: 0 !important;
                    padding: 0 !important;
                    -webkit-print-color-adjust: exact;
                    print-color-adjust: exact;
                }
                
                /* Header that appears on every page */
                .page-header {
                    position: fixed;
                    top: 5mm;
                    left: 15mm;
                    right: 15mm;
                    height: 10mm;
                    text-align: right;
                    
                    color: #666;
                    border-bottom: 1px solid #eee;
                    padding-bottom: 3mm;
                }
                
                /* Content positioning to account for header and footer */
                .print-content {
                    margin-top: 15mm;
                    margin-bottom: 20mm;
                }
                
                /* Cover page styling */
                .cover-page {
                    display: block !important;
                    height: calc(100vh - 45mm); /* Account for margins */
                    width: calc(100% - 30mm); /* Account for margins */
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    page-break-after: always;
                    position: relative;
                    margin: 20mm 15mm;
                }
                .cover-page-logo {
                    max-width: 200px;
                    margin-bottom: 40px;
                }
                .cover-page-title {
                    margin-bottom: 20px;
                }
                .cover-page-subtitle {
                    margin-bottom: 40px;
                }
                .cover-page-details {
                    margin-bottom: 10px;
                }
                
                /* Footer styling */
                .page-footer {
                    display: block !important;
                    position: fixed;
                    bottom: 5mm; /* Position within the margin area */
                    left: 15mm;
                    right: 15mm;
                    height: 15mm;
                    padding: 3mm 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    
                    border-top: 1px solid #ddd;
                    background-color: white;
                }
                .page-footer-left {
                    display: flex;
                    align-items: center;
                }
                .page-footer-left img {
                    height: 15px;
                    margin-right: 10px;
                }
                .page-footer-right::after {
                    content: "Page " counter(page) " of " counter(pages);
                    margin-left: 10px;
                }
            }
        `,
        documentTitle: `StoryPath_${formData.promptCustomerName || 'Customer'}_Research_${formData.promptProductName || 'Product'}_${new Date().toLocaleDateString().replace(/\//g, '_')}`,
    });

    const injectFooter = useCallback(() => {
        const printContainer = storyPathRef.current;
        if (!printContainer) {
            //console.error("Print container not found");
            return;
        }

        //console.log("Injecting header, footer and cover page");

        const currentYear = new Date().getFullYear();
        const currentDate = new Date().toLocaleDateString();

        // Add a wrapper div to properly position content
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'print-content';

        // Move all existing children to the wrapper
        while (printContainer.firstChild) {
            contentWrapper.appendChild(printContainer.firstChild);
        }

        // Create header
        const header = document.createElement('div');
        header.className = 'page-header';
        header.innerHTML = `
            <span>StoryPath Report - ${formData.promptCompanyName || 'Company'}</span>
        `;

        // Create cover page
        // const coverPage = document.createElement('div');
        // coverPage.className = 'cover-page';
        // coverPage.style.display = 'none'; // Hide in normal view, show only in print
        // coverPage.innerHTML = `
        //     <img className="cover-page-logo" src="/logo.png" alt="StoryPath Logo" />
        //     <div className="cover-page-title">StoryPath Report</div>
        //     <div className="cover-page-subtitle">${formData.promptCompanyName || 'Company'} Story Analysis</div>
        //     <div className="cover-page-details">Prepared for: ${formData.customerName || 'Client'}</div>
        //     <div className="cover-page-details">Date: ${currentDate}</div>
        // `;

        // Create footer
        const footer = document.createElement('div');
        footer.className = 'page-footer';
        footer.style.display = 'none'; // Hide in normal view, show only in print
        footer.innerHTML = `
            <div className="page-footer-left">
                <img src="/logo.png" alt="StoryPath Logo" />
                <span>© ${currentYear} StoryPath</span>
            </div>
            <div className="page-footer-right">
                <span>Customer: ${formData.promptCompanyName || 'N/A'} | Date: ${currentDate}</span>
            </div>
        `;

        // Add elements to the print container in the correct order
        printContainer.appendChild(header);
        printContainer.appendChild(coverPage);
        printContainer.appendChild(contentWrapper);
        printContainer.appendChild(footer);

        return () => {
            // Return cleanup function that restores the original structure
            if (header && header.parentNode) header.parentNode.removeChild(header);
            if (footer && footer.parentNode) footer.parentNode.removeChild(footer);

            // Move content back from wrapper to container
            if (contentWrapper && contentWrapper.parentNode) {
                while (contentWrapper.firstChild) {
                    printContainer.appendChild(contentWrapper.firstChild);
                }
                contentWrapper.parentNode.removeChild(contentWrapper);
            }

            if (coverPage && coverPage.parentNode) coverPage.parentNode.removeChild(coverPage);

            //console.log("Cleaned up print elements");
        };
    }, [formData]);

    // Modified print function to inject the footer and cover page
    const handlePrint = useCallback(() => {
        //console.log("Print initiated");

        // First inject the elements
        const cleanup = injectFooter();

        // Give browser time to render the elements before printing
        setTimeout(() => {
            //console.log("Starting print after delay");
            reactToPrintFn();

            // Don't clean up immediately - wait until after printing is complete
            // The onAfterPrint callback would be ideal, but as a fallback:
            setTimeout(() => {
                if (cleanup) {
                    //console.log("Running cleanup");
                    cleanup();
                }
            }, 2000); // Longer timeout to ensure printing completes
        }, 300); // Delay to ensure DOM is updated
    }, [injectFooter, reactToPrintFn]);

    const businessNewsRef = useRef();
    const producedStoryRef = useRef();
    const researchRef = useRef();
    const industryTrendsRef = useRef();
    const personasRef = useRef();
    const castRef = useRef();
    const draftStoryRef = useRef();
    const talkTrackRef = useRef();
    const salesAnalysisRef = useRef();
    const businessAnalysisRef = useRef();

    const [transcriptResults, setTranscriptResults] = useState([]);
    const [transcriptData, setTranscriptData] = useState([]);

    // Initialize usePDF hook
    const { toPDF: generatePDF } = usePDF();




    const [open, setOpen] = useState(0);

    //const handleOpen = (value) => setOpen(open === value ? 0 : value);

    // Replace the single open state with an array
    const [openSections, setOpenSections] = useState([]);

    //    Update the handleOpen function
    const handleOpen = (value, inplace = 0) => {
        setOpenSections(prev => {
            if (prev.includes(value)) {
                return prev.filter(item => item !== value);
            } else {
                return [...prev, value];
            }
        });
        if (inplace === 1) {
            setTimeout(() => {
                const accordion = document.querySelector(`[data-accordion-id="${value}"]`);
                if (accordion) {
                    accordion.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 100);
        }
    };

    // const handleOpen = (value) => {
    //     setOpen(value);
    //     // Add smooth scrolling
    //     setTimeout(() => {
    //         const accordion = document.querySelector(`[data-accordion-id="${value}"]`);
    //         if (accordion) {
    //             accordion.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //         }
    //     }, 100);
    // };    

    const generateSectionPDF = (sectionRef, sectionName) => {
        if (sectionRef.current) {
            generatePDF({
                filename: `${formData.promptCompanyName}_${sectionName}.pdf`,
                targetRef: sectionRef.current
            });
        } else {
            //console.error("Unable to get the target element.");
        }
    };



    {/* 
                    <div className="mt-4 mb-6">
                        <button
                            onClick={handleResearchProductDetails}
                            disabled={isResearchProductLoading || !formData.promptCompanyName}
                            className="bg-sky-500 hover:bg-sky-600 text-white font-bold py-2 px-4 rounded"
                        >
                            {isResearchProductLoading ? (
                                <ImpulseSpinner size={24} frontColor="#ffffff" />
                            ) : (
                                "Research Company Details"
                            )}
                        </button>
                    </div> */}

    const storyCards = [
        {
            id: 'generate',
            bigTitle: 'Tell',
            title: 'Deep Research, Insights & Product Stories',
            description: 'We do the heavy lifting and generate a story based on your needs and target audience',
            icon: 'M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z',
            provides: [
                '3 Product Stories - Summary, Day-to-Day, and Deep Dive',
            ],
            benefits: [
                '3 story types - summary, business transformation storybites and day-to-day',
                'Built on deep research, focused through business context',
                'Personalized for your audience and situation',
                'Export both research & stories'
            ],
            roadmap: [
                'Collaborate with your team',
            ],
            onClick: () => {/* handle generate story */ }
        },
        {
            id: 'blank',
            bigTitle: 'Show',
            title: 'Immersive Theater Experience',
            description: 'Bring your story to life with an immersive visual experience that combines people, process, technology, and business value',
            provides: [
                'Asset - Create and Share',
            ],
            benefits: [
                'Interactive, cinematic experiences that visualizes people, process, technology and business value',
                'Embed your solution demonstrations',
                'On-Demand delivery and analytics to understand your audience',
            ],
            roadmap: [
                'Import Your Existing Presentations',
            ],
            icon: 'M12 4v16m8-8H4', // Plus icon
            onClick: () => {/* handle blank story */ }
        },
        // {
        //     id: 'competition',
        //     title: 'Competition',
        //     description: 'Understand your competitors in the context of your deal',
        //     icon: 'M12 4v16m8-8H4', // Plus icon
        //     onClick: () => {/* handle blank story */ }
        // },
        // {
        //     id: 'library',
        //     title: 'Copy from Library',
        //     description: "Duplicate existing story & customize for your needs",
        //     icon: 'M12 4v16m8-8H4',
        //     onClick: () => {/* handle library template */ }
        // }
    ];

    const [storypathType, setStorypathType] = useState(null);

    const [challenges, setChallenges] = useState([]);
    const [loadingChallenges, setLoadingChallenges] = useState(false);
    const [showChallengesModal, setShowChallengesModal] = useState(false);

    // Add this function to call jobsChallengeFinder
    const findCustomerChallenges = async () => {
        try {
            setLoadingChallenges(true);

            // Get the necessary data from formData
            const { promptProductName, promptCustomerName, promptCustomerRole, promptCompanyName } = formData;

            // Call the Firebase function
            const functionsInstance = getFunctions();
            const jobsChallengeFinderCallable = httpsCallable(functionsInstance, 'jobsChallengeFinder');

            const result = await jobsChallengeFinderCallable({
                productName: promptProductName,
                customerName: promptCustomerName,
                customerRole: promptCustomerRole,
                companyName: promptCompanyName
            });

            if (result.data.challenges && Array.isArray(result.data.challenges)) {
                console.log('challenges', result.data.challenges);
                setChallenges(result.data.challenges);

            } else {
                console.error('Invalid response format from jobsChallengeFinder');
                setChallenges([]);
            }

            // } else {
            //     console.error('Invalid response format from jobsChallengeFinder');
            //     setChallenges([]);
            // }
        } catch (error) {
            console.error('Error finding customer challenges:', error);
            setChallenges([]);
        } finally {
            setLoadingChallenges(false);
        }
    };




    // First, let's add a state to track which sections are expanded
    // const [expandedSections, setExpandedSections] = useState({
    //     companyResearch: false,
    //     businessNews: false,
    //     industryTrends: false,
    //     messagingResearch: false,
    //     eli5Media: false
    // });

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

    const [storyName, setStoryName] = useState('');
    const [storyDescription, setStoryDescription] = useState('');
    const navigate = useNavigate();
    //const { user } = UserAuth(); //useAuth();


    const createStory = async () => {
        if (storyName === '') {
            alert('Please enter a valid story name');
            return;
        }

        if (storyDescription === '') {
            alert('Please enter a valid story description');
            return;
        }

        let userOwnerKey = user?.uid;

        const docRef = await addDoc(collection(db, 'stories'), {
            name: storyName,
            description: storyDescription,
            userPhotoURL: user?.photoURL,
            userEmail: user?.email,
            userDisplayName: user?.displayName,
            messageUserId: user?.uid,
            backgroundMatcap: 'C8C8C8_3F3F3F_787878_5C5C5C',
            backgroundModel: 'background_plain',
            backgroundSky: 'skyBackground1',
            backgroundHeight: -5,
            layoutType: 'linear',
            layoutCircleDistance: 70,
            navigation: 'nav1',
            cameraOrbitOn: 1,
            visibility: 'private',
            previewThumb: '/media/screen/B3_New_Project.png',
            createdAt: serverTimestamp(),
            lastModified: serverTimestamp(),
            analyticsViewTotal: 0,
            analyticsLastViewed: serverTimestamp(),
            imageStoryBackground: '/media/icon/B3-Image-Placeholder.png',
            imageStoryLogo: '/media/icon/B3-Image-Placeholder.png',
            uiStyle: {
                'nav-button-bg-off': '#b3b3b3',
                'nav-button-bg-on': '#222222',
                'nav-button-text-off': '#4d4d4d',
                'nav-button-text-on': '#009ce0',
                'nav-progress-meter': '#808080',
                'nav-progress-meter-bg': '#009ce0',
                'nav-region-bg': '#111111',
                'super-highlight-color': '#009ce0',
                'super-list-icon-color': '#000000',
                'super-list-text-color': '#000000',
                'super-main-bg-color': '#111111',
                'super-main-title-color': '#009ce0',
                'super-secondary-bg-color': '#000000',
                'super-subtitle-color': '#cccccc',
            },
            role: {
                owner: [userOwnerKey],
                commenter: [userOwnerKey],
                writer: [userOwnerKey],
                reader: [userOwnerKey],
            },
        });

        // Reset form fields
        setStoryName('');
        setStoryDescription('');

        // Navigate to the new story
        navigate('/app/stories/' + docRef.id + '/edit/0');
    };

    const [selectedTemplate, setSelectedTemplate] = useState('blank');


    const handlePromptClick = (prompt) => {
        //console.log('Prompt clicked:', prompt);

        // Update form data with the clicked prompt's values
        setFormData(prevData => ({
            ...prevData,
            promptCompanyName: prompt.promptCompanyName || '',
            promptProductName: prompt.promptProductName || '',
            promptCustomerName: prompt.promptCustomerName || '',
            promptCustomerRole: prompt.promptCustomerRole || '',
            promptCustomerProblem: prompt.promptCustomerProblem || ''
        }));

        // Update story tone settings if they exist in the prompt
        // if (prompt.storyTone) {
        //     handleToneChange({
        //         story: prompt.storyTone.story || 'Casual',
        //         research: prompt.storyTone.research || 'Casual',
        //         storyLength: prompt.storyTone.storyLength || '3',
        //         storyIntensity: prompt.storyTone.storyIntensity || 'a regular day, nothing overly dramatic, but the product helps make it easier in important ways',
        //         storyTone: prompt.storyTone.storyTone || 'Casual'
        //     });
        // }

        setAllowGeneration(true);
        setError(null);
    };





    const handleDeletePrompt = async (promptId) => {
        try {
            if (!user) {
                console.error('No user logged in');
                return;
            }

            // Reference to the specific prompt document
            const db = getFirestore();
            const promptRef = doc(db, "gen-prompt-cache", promptId);
            await deleteDoc(promptRef);

            // Update local state by filtering out the deleted prompt
            setRecentPrompts(prevPrompts => prevPrompts.filter(prompt => prompt.id !== promptId));

            // Update local state by filtering out the deleted prompt
            //setRecentPrompts(prevPrompts => prevPrompts.filter(prompt => prompt.id !== promptId));
        } catch (error) {
            console.error('Error deleting prompt:', error);
            // Optionally show an error message to the user
            setError('Failed to delete prompt. Please try again.');
        }
    };


    return (






        <div className="  mx-auto  w-full
            h-[calc(100vh-180px)]  ">


            <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>

                {isKanbanModalOpen && (
                    <div className="absolute top-0 left-0 w-full h-full bg-black/50 z-50 p-0 flex items-center justify-center">
                        <div className="bg-zinc-900 rounded-lg w-full h-[calc(100%-80px)]  m-14  p-6 relative">
                            <button
                                type="button"
                                onClick={() => setIsKanbanModalOpen(false)}
                                className="absolute top-4 right-4 bg-zinc-800 rounded-full p-2 z-50 cursor-pointer text-zinc-400 hover:text-white"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>


                            <KanbanBoard
                                storyVisualizationData={storyVisualizationData}
                                storyChaptersData={storyChaptersData}
                                formData={formData}
                                generateStoryBite={generateStoryBite}
                                businessTransformationResult={businessTransformationResult}
                                businessCostOfInactionResult={businessCostOfInactionResult}
                                producedResult={producedResult}
                                businessAnalysisData={businessAnalysisData}
                                salesAnalysisData={salesAnalysisData}
                                storySpeakerSummaryResult={storySpeakerSummaryResult}
                            // onUpdate={(updatedColumns) => {
                            // }}

                            />
                        </div>
                    </div>
                )}

                <div className="grid grid-cols-2 gap-6 mb-8">
                    {storyCards.map((card) => (
                        <motion.button
                            key={card.id}
                            className={`group bg-black/10 hover:bg-black/25 rounded-t-lg p-6 text-left transition-all duration-200 
                            border-b-2 ${storypathType === card.id ? 'border-sky-400 bg-black/30' : 'border-transparent'}`}
                            onClick={() => {
                                setStorypathType(card.id);
                                logCustomEvent('nav_create_story_button_' + card.bigTitle, { tab: card.bigTitle });
                                if (card.onClick) card.onClick();
                            }}
                            whileTap={{ scale: 0.95 }}
                            whileHover={{ scale: 1.01 }}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.2,
                                ease: "easeOut",
                                layout: { duration: 0.2 }
                            }}
                        >
                            <div className="space-x-4">
                                <div className="grid grid-cols-[auto,1fr] gap-4">
                                    <div className="px-3 pb-2 flex rounded-lg">
                                        <motion.svg
                                            className={`w-8 h-8 mt-3 ${storypathType === card.id ? 'text-sky-400' : 'text-sky-500'}`}
                                            fill="none"
                                            stroke={storypathType === card.id ? "currentColor" : "#3f3f46"}
                                            viewBox="0 0 24 24"
                                            animate={{
                                                scale: storypathType === card.id ? 1.1 : 1,
                                                color: storypathType === card.id ? "#38bdf8" : "#3f3f46"
                                            }}
                                            transition={{ duration: 0.2 }}
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={card.icon} />
                                        </motion.svg>
                                    </div>
                                    <div>
                                        <div className={`${storypathType === card.id ? 'text-orange-300' : 'text-zinc-400'} text-3xl sp-big-heading mb-2`}>{card.bigTitle}</div>
                                        <div className={`text-zinc-100 font-base 
                                        ${storypathType === null ? 'text-xl' : 'text-lg'} mb-2`}>{card.title}</div>

                                        {/* <p className="text-zinc-400 text-sm">{card.description}</p> */}
                                    </div>

                                </div>
                                <AnimatePresence>
                                    {storypathType === null && (
                                        <motion.div
                                            className="flex flex-col gap-2 pl-12 pt-4"
                                            initial={{ opacity: 1, height: 'auto' }}
                                            exit={{ opacity: 0, height: 0, overflow: 'hidden' }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <ul className="list-disc pl-5 text-md text-zinc-400 mb-3">
                                                {card.benefits.map((item, index) => (
                                                    <li key={index} className="mb-1 marker:text-sky-400">{item}</li>
                                                ))}
                                            </ul>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </motion.button>
                    ))}
                </div>


                {/* <CompExplainer
                    user={user}
                    title="Tell & Show"
                    explainerId="tell-show-tips"
                    type="inline"
                    className="mx-10"
                >
                    Listen first, pitch last. Great sellers don't start with their product—they start with their prospect. 'Tell' is where you gather intelligence, understand what your customer cares about, and make sure you know how to speak their language. 'Show' is where you take what you've learned and craft an engaging, tailored experience that makes your solution impossible to ignore. If you rush to the 'Show' before the 'Tell,' you'll probably end up in 'dead, no decision' yet again.
                </CompExplainer> */}


                {/* <div className="grid grid-cols-2 gap-6 mb-8">
                    {storyCards.map((card) => (
                         
                            <div className="space-x-4">
                                <div className="grid grid-cols-[auto,1fr] gap-4">
                                    <div className="px-3 pb-2 flex rounded-lg">
                                        INFO
                                    </div>
                                    <div>
                                        INFO
                                    </div>
                                </div>
                            </div>
                         
                    ))}
                </div> */}

                {/* <div className='flex-auto  w-full   '>
                    <div className=' gap-4 bg-black/20 p-4 rounded-lg text-sky-400 text-center text-sm'>
                        Please note that if this is the first time you are running this for a new scenario, it may take a few minutes to generate the story.  Once it has been generated, running again with tone or other changes will be much faster.
                    </div>
                </div> */}


                <div className="flex-auto w-full mb-6 hidden">
                    <div className="bg-black/20 p-4 rounded-lg  ">
                        <h3 className="text-zinc-100 font-medium text-lg mb-3">API Call Status</h3>
                        <div className="grid grid-cols-3 gap-4">
                            {Object.keys(apiCalls).map((key) => (
                                <div
                                    key={key}
                                    className={`p-3 rounded-lg flex items-center gap-3 ${apiCalls[key] && !apiCallsCompleted[key]
                                        ? 'bg-amber-500/20 border border-amber-500/30'
                                        : apiCallsCompleted[key]
                                            ? 'bg-green-500/20 border border-green-500/30'
                                            : 'bg-zinc-700/30 border border-zinc-700/50'
                                        }`}
                                >
                                    <div className="flex-shrink-0">
                                        {apiCalls[key] && !apiCallsCompleted[key] ? (
                                            <div className="w-3 h-3 rounded-full bg-amber-400 animate-pulse"></div>
                                        ) : apiCallsCompleted[key] ? (
                                            <svg className="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                            </svg>
                                        ) : (
                                            <div className="w-3 h-3 rounded-full bg-zinc-500"></div>
                                        )}
                                    </div>
                                    <div className="flex-1">
                                        <div className="text-sm font-medium text-zinc-100">
                                            {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                        </div>
                                        <div className="text-xs text-zinc-400">
                                            {apiCalls[key] && !apiCallsCompleted[key]
                                                ? 'In progress'
                                                : apiCallsCompleted[key]
                                                    ? 'Completed'
                                                    : 'Waiting'}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>



                {/* {showProgressBar && ( */}
                {showProgressBar && (

                    <div className="absolute bottom-4 animate__animated animate__fadeInUp left-14 w-[calc(100%-140px)]  rounded-lg  overflow-hidden   bg-zinc-900 z-50 shadow-lg shadow-black/25">

                        <div className=" bg-black/20 p-2 ">
                            <div className="relative mt-0 w-full">
                                <div className="grid grid-cols-12 gap-0  w-full ">


                                    <div className="text-zinc-400 text-sm col-span-7 border-white/10 bg-zinc-600/10 bxxorder rounded-lg   pr-4 pl-3">

                                        <div className="flex gap-0.5 mt-2.5  ">
                                            <div className="text-zinc-100 text-md w-56 ">
                                                {currentStep}
                                            </div>
                                            <div className="text-zinc-400 text-md mr-2">
                                                Research
                                            </div>
                                            {[
                                                { name: 'Research', key: 'research' },
                                                { name: 'Deep Language', key: 'deepLanguage' },
                                                { name: 'Business News', key: 'businessNews' },

                                                //{ name: 'Media Analysis', key: 'eli5Media' },
                                                { name: 'Summarizing', key: 'eli5Business' },
                                                { name: 'Trends', key: 'industryTrends' },
                                                { name: 'Summarizing', key: 'eli5Industry' },
                                                { name: 'Stakeholders', key: 'persona' },
                                            ].map((step, idx) => (
                                                <div key={step.name + '-' + idx} className="flex-1 ">
                                                    <div className={`relative w-full rounded-sm  h-1 -mb-2 mt-2  ${apiCalls[step.key] ? 'bg-sky-400 pulse-sky' :
                                                        sectionTimes[step.key] ? 'bg-sky-300' : 'bg-white/10'
                                                        }`}
                                                        title={`${step.name}: apiCalls=${!!apiCalls[step.key]}, sectionTimes=${!!sectionTimes[step.key]}, key=${step.key}`}
                                                    >
                                                    </div>
                                                    <div className="text-center text-xs">
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="text-zinc-400 text-md ml-12 mr-2">
                                                Story
                                            </div>

                                            {[
                                                { name: 'Brainstorm', key: 'story' },
                                                { name: 'Framing', key: 'producer' },
                                                { name: 'Chapters', key: 'storyChapters' },
                                                { name: 'Business Analysis', key: 'businessAnalysis' },
                                                { name: 'Talktrack', key: 'storySpeaker' },
                                                { name: 'Story Summary', key: 'storySpeakerSummary' },
                                                { name: 'Pitch Analysis', key: 'salesAnalysis' },


                                            ].map((step, idx) => (
                                                <div key={step.name + '-' + idx} className="flex-1 ">
                                                    {/* {step.name == 'Brainstorm' && (
                                                        <div className="text-zinc-50 text-md col-span-1 mt-3">
                                                            Story
                                                        </div>
                                                    )} */}
                                                    <div className={`relative w-full rounded-sm  h-1 -mb-2 mt-2  ${apiCalls[step.key] ? 'bg-sky-400 pulse-sky' :
                                                        sectionTimes[step.key] ? 'bg-sky-300' : 'bg-white/10'
                                                        }`}>
                                                    </div>
                                                    <div className="text-center">
                                                        <div className={`font-base mt-4 text-xs ${apiCalls[step.key] ? 'text-zinc-100' :
                                                            sectionTimes[step.key] ? 'text-zinc-100' :
                                                                'text-zinc-600'
                                                            }`}>
                                                        </div>
                                                        {/* {step.name}
                                                        
                                                        {sectionTimes[step.key] && (
                                                            <span className="text-xs text-zinc-400">
                                                                {formatSectionTime(sectionTimes[step.key])}
                                                            </span>
                                                        )} */}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="col-span-1 mr-4 ml-12 ">
                                        <div className="text-zinc-400  text-sm mt-3 text-right">
                                            View
                                        </div>
                                    </div>

                                    <div className="col-span-2 mr-4 ml-2 bg-zinc-800/50 rounded-lg px-2">

                                        <div className="relative flex flex-wrap  py-0.5">

                                            <button
                                                className={`z-30 w-1/2 py-2 text-sm transition-all ease-in-out rounded-lg ${viewMode === "detailed" ? "text-white" : "text-zinc-400"} bg-inherit`}
                                                onClick={() => setViewMode("detailed")}
                                                type="button"
                                                aria-pressed={viewMode === "detailed"}
                                            >
                                                Detailed
                                            </button>
                                            <button
                                                className={`z-30 w-1/2 py-2 text-sm transition-all ease-in-out rounded-lg ${viewMode === "summary" ? "text-white" : "text-zinc-400"} bg-inherit`}
                                                onClick={() => setViewMode("summary")}
                                                type="button"
                                                aria-pressed={viewMode === "summary"}
                                            >
                                                Summary
                                            </button>

                                            <div
                                                className="absolute rounded-full bottom-0.5 h-0.5 bg-sky-500 transition-all duration-200"
                                                style={{
                                                    left: viewMode === "summary" ? "50%" : "0%",
                                                    width: "50%",
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-span-2 flex justify-end gap-2  ml-4'>
                                        {openPaths ?
                                            <>
                                                <div>
                                                    <button
                                                        type="button"
                                                        onClick={reactToPrintFn}
                                                        className="group bg-zinc-800 mx-auto hover:bg-zinc-600 text-zinc-400 hover:text-white py-0 px-3 rounded-lg text-sm flex items-center gap-2 h-full"
                                                    >
                                                        <img src="/util/app/b3-admin-export.svg" className="h-5 content-center opacity-50 group-hover:opacity-100" /> <span className="group-hover:text-white">Export</span>
                                                    </button>
                                                </div>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        logCustomEvent('open_storypaths_modal');
                                                        setIsKanbanModalOpen(true);
                                                    }}
                                                    className="bg-sky-500 mx-auto hover:bg-sky-400 text-white py-0 px-3 rounded-lg text-sm flex items-center gap-2  "
                                                >
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                                                        </svg> */}
                                                    Open StoryPaths

                                                </button>

                                            </>
                                            :

                                            <div className={`bg-zinc-800/50 text-zinc-700 py-0 px-3 rounded-lg text-sm flex items-center gap-2  `}>
                                                Open StoryPaths
                                            </div>


                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                )}


















                {/* <div className=" text-orange-300  p-6  text-xl   bg-black/20 rounded-lg w-full  text-center items-center  ">
                    Storyelling Deep Research, Insights &amp; Product Stories Goes Live 3.13.25
                </div> */}
                {(storypathType === 'generate' || storypathType === 'competition')

                    && (

                        <>

                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                className={`${user?.email === 'john.brunswick@gmail.com' ? '' : ' '}`}
                                transition={{
                                    duration: 0.25,
                                    ease: "easeOut"
                                }}
                            >
                                <div className='flex mt-12' >

                                    <div className='flex-none print:hidden '>
                                        {/* <div className=" justify-center items-center text-sm text-zinc-100 font-medium mr-4  text-center  rounded-full  h-10 w-10 pt-2.5 text-zinc-50 border-sky-600 bg-black/20 
                             
                                "> */}
                                        <div className={`justify-center items-center text-sm mr-4 text-center rounded-full h-10 w-10   
                                ${allowGeneration ?
                                                "justify-center items-center text-sm text-zinc-100 font-medium mr-4  text-center  rounded-full  h-10 w-10  pt-2 text-zinc-50 border-sky-600 bg-black/20 border-2 " :
                                                "text-zinc-400 font-medium bg-black/20 pt-2.5"
                                            }`}>
                                            1
                                        </div>
                                        <div className=' w-0.5 h-[calc(100%-40px)] mt-1  bg-white/5 ml-5'>
                                        </div>
                                    </div>




                                    <div className='flex-auto  w-full   '>
                                        <div className='flex-auto flex gap-4'>
                                            <div className='flex-grow'>
                                                <div className='w-full -mt-3 p-4 text-lg text-zinc-100 font-medium '>
                                                    <div>
                                                        <div className={`text-lg font-medium ${allowGeneration ? 'text-sky-400' : 'text-zinc-400'}`}>Scenario</div>
                                                        {/* <ThreeDotsSVGLoading /> */}
                                                        <div className='w-full text-zinc-400 text-base font-normal'>Please complete the following sentence to get our collaboration started</div>
                                                    </div>
                                                    {/* Recent Prompts Section */}

                                                    {recentPrompts.length > 0 && (
                                                        <div className="mt-6 mb-2 animate__animated animate__fadeIn">
                                                            <div className="mt-6 mb-2">
                                                                <div className="w-full gap-4 flex">
                                                                    <img src="/util/app/b3-admin-prompt-history.svg" className="h-8 mt-2 content-center opacity-50 group-hover:opacity-100 
                                                                
                                                                " />
                                                                    {recentPrompts.map((prompt, index) => (
                                                                        <motion.div
                                                                            key={prompt.id + prompt.promptCustomerName}
                                                                            className="bg-black/20 text-zinc-200 rounded-lg p-3 text-sm cursor-pointer hover:bg-black/50 transition-colors flex justify-between items-center"
                                                                            onClick={() => handlePromptClick(prompt)}
                                                                            whileTap={{ scale: 0.95 }}
                                                                            whileHover={{ scale: 1.01 }}
                                                                            initial={{ opacity: 0, y: 20 }}
                                                                            animate={{ opacity: 1, y: 0 }}
                                                                            transition={{
                                                                                duration: 0.2,
                                                                                ease: "easeOut",
                                                                                layout: { duration: 0.2 }
                                                                            }}
                                                                        >
                                                                            {/* <div
                                                                        //onClick={() => handlePromptClick(prompt)}
                                                                        >
                                                                            {prompt.promptCustomerName || 'Unnamed Customer'} - {prompt.promptProductName || 'Unnamed Product'}
                                                                        </div> */}

                                                                            <div className="group">
                                                                                <div>
                                                                                    {prompt.promptCustomerName || 'Unnamed Customer'} - {prompt.promptProductName || 'Unnamed Product'}
                                                                                </div>
                                                                                {/* <motion.div
                                                                                className="absolute z-50 left-0 -bottom-2 transform translate-y-full bg-zinc-800 text-zinc-200 p-4 rounded-lg shadow-lg w-72"
                                                                                initial={{ opacity: 0, y: -10 }}
                                                                                whileHover={{ opacity: 1, y: 0 }}
                                                                                transition={{ duration: 0.2 }}
                                                                            >
                                                                                <div className="space-y-2">
                                                                                    <p><span className="font-semibold">Company:</span> {prompt.promptCompanyName || 'N/A'}</p>
                                                                                    <p><span className="font-semibold">Customer Role:</span> {prompt.promptCustomerRole || 'N/A'}</p>
                                                                                    <p><span className="font-semibold">Problem:</span> {prompt.promptCustomerProblem || 'N/A'}</p>
                                                                                </div>
                                                                            </motion.div> */}
                                                                            </div>

                                                                            <button
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    handleDeletePrompt(prompt.id);
                                                                                }}
                                                                                className="ml-2 text-zinc-400 hover:text-red-400 transition-colors"
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                                                </svg>
                                                                            </button>
                                                                        </motion.div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}



                                                </div>
                                            </div>
                                            <div className='flex-none mr-0 mt-4'>
                                                <CompAIStoryTone
                                                    initialToneSettings={storyTone}
                                                    onToneChange={handleToneChange}
                                                />
                                            </div>
                                        </div>
                                        {error && (
                                            <div className="mt-4 p-4  text-red-300 rounded bg-black/20">
                                                {error}
                                            </div>
                                        )}

                                        <form onSubmit={generateStory} className="space-y-4   text-zinc-100  p-4 pb-1 mb-4 bg-black/20 rounded-lg   ">
                                            <CompExplainer
                                                user={user}
                                                title="Using The Story Prompt"
                                                explainerId="story-prompt-tips"
                                                type="inline"
                                                className="mx-0"
                                            >
                                                <div className='text-zinc-100 text-sm'>
                                                    This kicks off your research! Enter the company, contact, and problems you think they might be struggling to solve, and boom—StoryPath generates insights to make you sound like you've been following their business for years. Walking into a meeting blind is like showing up to a first date without looking at their profile—bad move. Don't just skim—look for patterns. If you don't know what problem they're trying to solve, assume they don't either.

                                                    <div className='mt-8 text-amber-200 text-sm'>
                                                        Please note that the first research segment may take a minute to complete.  In our next release, this time will be reduced by 50%.  We appreciate your patience!-
                                                    </div>
                                                </div>
                                            </CompExplainer>
                                            <div className='flex justify-between items-center '>



                                                <div className='flex-grow'>
                                                    {storypathType === 'competition' && (
                                                        <>
                                                            <div className="flex flex-wrap items-center gap-2 mb-24">
                                                                <input
                                                                    type="text"
                                                                    name="website"
                                                                    value={formData.website}
                                                                    placeholder="Your Company Website"
                                                                    onChange={handleInputChange}
                                                                    className="inline-flex flex-grow p-3 bg-zinc-600/25 text-zinc-50 rounded border border-zinc-600 focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                                                                    required
                                                                    onBlur={handleFieldBlur}
                                                                />

                                                                <input
                                                                    type="text"
                                                                    name="competitor"
                                                                    value={formData.competitor}
                                                                    placeholder="Your Competitor Website"
                                                                    onChange={handleInputChange}
                                                                    className="inline-flex flex-grow p-3 bg-zinc-600/25 text-zinc-50 rounded border border-zinc-600 focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                                                                    required
                                                                    onBlur={handleFieldBlur}
                                                                />
                                                            </div>
                                                            <div className="mt-4 mb-6">
                                                                <button
                                                                    onClick={handleResearchProductDetails}
                                                                    disabled={isResearchProductLoading || !formData.promptCompanyName}
                                                                    className="bg-sky-500 hover:bg-sky-600 text-white font-bold py-2 px-4 rounded"
                                                                >
                                                                    {isResearchProductLoading ? (
                                                                        <ImpulseSpinner size={24} frontColor="#ffffff" />
                                                                    ) : (
                                                                        "Research Company Details"
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="flex flex-wrap items-center gap-2">
                                                        <div className="flex-grow">
                                                            <input
                                                                type="text"
                                                                name="promptCompanyName"
                                                                value={formData.promptCompanyName}
                                                                placeholder="Your Company Name"
                                                                onChange={handleInputChange}
                                                                className={`inline-flex w-full p-3 bg-black/20 text-zinc-50 rounded-md border ${formData.promptCompanyName || !isFormSubmitted ? 'border-zinc-700 focus:border-sky-500' : 'border-red-400/50 bg-red-400/10 focus:border-red-400/50'} focus:ring-1 ${formData.promptCompanyName || !isFormSubmitted ? 'focus:ring-sky-500' : 'focus:ring-red-400/50'} `}
                                                                onBlur={handleFieldBlur}
                                                            //required
                                                            />
                                                            {!formData.promptCompanyName && isFormSubmitted && (
                                                                <p className="text-red-400 text-sm mt-1">Please enter your company name</p>
                                                            )}
                                                        </div>
                                                        <div className="px-3 mt-1">would like to sell </div>
                                                        <div className="flex-grow">
                                                            <input
                                                                type="text"
                                                                name="promptProductName"
                                                                value={formData.promptProductName}
                                                                placeholder="Your Product Name"
                                                                onChange={handleInputChange}
                                                                className={`inline-flex w-full p-3 bg-black/20 text-zinc-50 rounded-md border ${formData.promptProductName || !isFormSubmitted ? 'border-zinc-700 focus:border-sky-500' : 'border-red-400/50 bg-red-400/10 focus:border-red-400/50'} focus:ring-1 ${formData.promptProductName || !isFormSubmitted ? 'focus:ring-sky-500' : 'focus:ring-red-400/50'}  `}
                                                                onBlur={handleFieldBlur}
                                                            //required
                                                            />
                                                            {!formData.promptProductName && isFormSubmitted && (
                                                                <p className="text-red-400 text-sm mt-1">Please enter your product name</p>
                                                            )}
                                                        </div>
                                                        <div className="px-3 mt-1">to the</div>
                                                        <div className="flex-grow">
                                                            <input
                                                                type="text"
                                                                name="promptCustomerRole"
                                                                placeholder="Target Role"
                                                                value={formData.promptCustomerRole}
                                                                onChange={handleInputChange}
                                                                className={`inline-flex w-full p-3 bg-black/20 text-zinc-50 rounded-md border ${formData.promptCustomerRole || !isFormSubmitted ? 'border-zinc-700 focus:border-sky-500' : 'border-red-400/50 bg-red-400/10 focus:border-red-400/50'
                                                                    } focus:ring-1 ${formData.promptCustomerRole || !isFormSubmitted ? 'focus:ring-sky-500' : 'focus:ring-red-400/50'} [&:-webkit-autofill]:bg-zinc-600/25 [&:-webkit-autofill]:!text-zinc-50 [&:-webkit-autofill]:shadow-[0_0_0_30px_rgb(82_82_91_/_0.25)_inset] [&:-webkit-autofill]:[filter:none]`}
                                                                onBlur={handleFieldBlur}
                                                            //required
                                                            />
                                                            {!formData.promptCustomerRole && isFormSubmitted && (
                                                                <p className="text-red-400 text-sm mt-1">Please enter the target role</p>
                                                            )}
                                                        </div>
                                                        <div className="px-3 mt-1">at</div>
                                                        <div className="flex-grow">
                                                            <input
                                                                type="text"
                                                                name="promptCustomerName"
                                                                placeholder="Prospect Company Name"
                                                                value={formData.promptCustomerName}
                                                                onChange={handleInputChange}
                                                                className={`inline-flex w-full p-3  bg-black/20 text-zinc-50 rounded-md border ${formData.promptCustomerName || !isFormSubmitted ? 'border-zinc-700 focus:border-sky-500' : 'border-red-400/50 bg-red-400/10 focus:border-red-400/50'
                                                                    } focus:ring-1 ${formData.promptCustomerName || !isFormSubmitted ? 'focus:ring-sky-500' : 'focus:ring-red-400/50'} [&:-webkit-autofill]:bg-zinc-600/25 [&:-webkit-autofill]:!text-zinc-50 [&:-webkit-autofill]:shadow-[0_0_0_30px_rgb(82_82_91_/_0.25)_inset] [&:-webkit-autofill]:[filter:none]`}
                                                                onBlur={handleFieldBlur}
                                                            //required
                                                            />
                                                            {!formData.promptCustomerName && isFormSubmitted && (
                                                                <p className="text-red-400 text-sm mt-1">Please enter the prospect company name</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="flex mt-4">
                                                        <div className="p-3 mt-1">in order to overcome</div>
                                                        <div className="relative flex-grow">
                                                            <input
                                                                name="promptCustomerProblem"
                                                                value={formData.promptCustomerProblem}
                                                                onChange={handleInputChange}
                                                                placeholder="Customer Problem"
                                                                className={`inline-flex w-full p-3 pr-14   bg-black/20  text-zinc-50 rounded-md border ${formData.promptCustomerProblem || !isFormSubmitted ? 'border-zinc-700 focus:border-sky-500' : 'border-red-400/50 bg-red-400/10 focus:border-red-400/50'
                                                                    } focus:ring-1 ${formData.promptCustomerProblem || !isFormSubmitted ? 'focus:ring-sky-500' : 'focus:ring-red-400/50'} [&:-webkit-autofill]:bg-zinc-600/25 [&:-webkit-autofill]:!text-zinc-50 [&:-webkit-autofill]:shadow-[0_0_0_30px_rgb(82_82_91_/_0.25)_inset] [&:-webkit-autofill]:[filter:none]`}
                                                                rows="3"
                                                                required
                                                                onBlur={handleFieldBlur}
                                                            />
                                                            {!formData.promptCustomerProblem && isFormSubmitted && (
                                                                <p className="text-red-400 text-sm mt-1">Please describe the problem to overcome</p>
                                                            )}
                                                            {/* <div className="absolute right-1 top-0">
                                                                <div className="absolute right-1 top-1">
                                                                    <button
                                                                        className="p-1 hover:bg-sky-500/10 rounded-md"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            findCustomerChallenges();
                                                                            setShowChallengesModal(true);
                                                                        }}
                                                                    >
                                                                        <svg className="w-8 h-8 text-sky-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" style={{ transform: 'scale(1)', color: 'rgb(56, 189, 248)' }}><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"></path></svg>
                                                                    </button>
                                                                </div>


                                                               
                                                                {showChallengesModal && (
                                                                    <AnimatePresence>
                                                                        <motion.div 
                                                                            className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center"
                                                                            aria-labelledby="modal-title" 
                                                                            role="dialog" 
                                                                            aria-modal="true"
                                                                            initial={{ opacity: 0 }}
                                                                            animate={{ opacity: 1 }}
                                                                            exit={{ opacity: 0 }}
                                                                            transition={{ duration: 0.3 }}
                                                                        >
                                                                            
                                                                            <motion.div 
                                                                                className="fixed inset-0 bg-black/75 transition-opacity" 
                                                                                aria-hidden="true" 
                                                                                onClick={() => setShowChallengesModal(false)}
                                                                                initial={{ opacity: 0 }}
                                                                                animate={{ opacity: 1 }}
                                                                                exit={{ opacity: 0 }}
                                                                            ></motion.div>

                                                                         
                                                                            <motion.div 
                                                                                className="relative bg-zinc-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-3/4 h-3/4 max-h-[75vh]"
                                                                                initial={{ scale: 0.9, y: 20 }}
                                                                                animate={{ scale: 1, y: 0 }}
                                                                                exit={{ scale: 0.9, y: 20 }}
                                                                                transition={{ type: "spring", bounce: 0.3, duration: 0.5 }}
                                                                            >
                                                                                <div className="flex justify-between items-center px-6 py-4 ">
                                                                                    <h3 className="text-2xl mt-4 ml-4 text-zinc-100">Customer Challenges</h3>
                                                                                    <button
                                                                                        className="text-zinc-400 hover:text-zinc-200"
                                                                                        onClick={() => setShowChallengesModal(false)}
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>

                                                                                <div className="px-6 py-4 h-[calc(100%-8rem)] overflow-y-auto mx-auto w-full">
                                                                                    {loadingChallenges ? (
                                                                                        <div className="flex justify-center items-center p-8">
                                                                                            <div className="text-zinc-400 flex flex-col items-center">
                                                                                            <RotateSpinner color="#38BDF8" className="mx-auto mb-12 mt-24" size={64} />
                                                                                                <div className="text-zinc-300 text-lg mt-12">Thinking about customer challenges...</div> 
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="space-y-4 grid grid-cols-3 gap-4">
                                                                                            {challenges && challenges.length > 0 ?
                                                                                                challenges.map((challenge, index) => (
                                                                                                    <div
                                                                                                        key={index}
                                                                                                        className="w-full   flex flex-col text-left p-4 bg-zinc-900 rounded-lg hover:bg-zinc-800"
                                                                                                    >
                                                                                                        <h4 className="text-lg text-white mb-2">{challenge.title}</h4>
                                                                                                        <p className="text-zinc-300 text-sm mb-3">{challenge.description}</p>

                                                                                                        {challenge.impacts && challenge.impacts.length > 0 && (
                                                                                                            <div className="mb-3">
                                                                                                                <span className="text-sm text-zinc-400">Impacts: </span>
                                                                                                                <div className="flex flex-wrap gap-1 mt-2">
                                                                                                                    {challenge.impacts.map((impact, i) => (
                                                                                                                        <span key={i} className="text-xs bg-zinc-700 px-2 py-1 rounded-full text-zinc-300">
                                                                                                                            {impact}
                                                                                                                        </span>
                                                                                                                    ))}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}

                                                                                                        <div className="mt-auto pt-3">
                                                                                                            <button
                                                                                                                className="w-full bg-sky-600 hover:bg-sky-700 px-4 py-2 rounded text-sm text-white transition"
                                                                                                                onClick={() => {
                                                                                                                    setFormData({
                                                                                                                        ...formData,
                                                                                                                        promptCustomerProblem: challenge.title 
                                                                                                                        //+ ": " + challenge.description
                                                                                                                    });
                                                                                                                    setShowChallengesModal(false);
                                                                                                                }}
                                                                                                            >
                                                                                                                Use This Challenge
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )) : (
                                                                                                    <div className="text-zinc-400 text-sm p-6 text-center col-span-3">
                                                                                                        No challenges found. Try with different product or customer information.
                                                                                                    </div>
                                                                                                )}
                                                                                        </div>
                                                                                    )}
                                                                                </div> 
                                                                            </motion.div>
                                                                        </motion.div>
                                                                    </AnimatePresence>
                                                                )}
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pl-10 pr-6 text-center   flex-none">
                                                    <div
                                                        className="relative   inline-flex items-center justify-center cursor-pointer overflow-hidden group rounded-md"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setAllowGeneration(false);
                                                            generateStory(e);
                                                        }}
                                                    >
                                                        <span className={`w-full text-center relative p-2   transition-all ease-out rounded-md  duration-400 ${loading ? ' ' : 'group-hover:bg-sky-400 bg-sky-500'}`}>
                                                            <span className="relative text-white text-base">
                                                                {loading ?
                                                                    <RotateSpinner color="#38BDF8" className="mx-auto" size={24} /> :
                                                                    <div className='text-zinc-100 font-medium '>
                                                                        {/* <div className='text-base text-zinc-100'>create</div> */}

                                                                        {/* create */}

                                                                        <img src="/util/app/b3-admin-create-4.svg" className="h-10    inline" />
                                                                    </div>
                                                                }
                                                            </span>
                                                        </span>

                                                    </div>
                                                    {/* <div className="text-sky-400 text-sm mt-3">
                                                ETA 3:30
                                            </div> */}
                                                </div>




                                            </div>


                                            <div className=" w-full">
                                                <div className=" items-center ">

                                                    {!showProgressBar && (
                                                        <div className='mx-auto'>
                                                            {(timer > 0 || isTimerRunning) && (
                                                                <div className="text-zinc-100 font-mono mx-auto w-full text-center mt-4 text-lg">
                                                                    {formatTime(timer)}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}



                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>


                                <div ref={storyPathRef} >
                                    <CompPrintCover formData={formData} />
                                    <div className='flex print:bg-black/0 print:mx-8   print:break-after-auto ' >
                                        <div className='flex-none print:hidden'>
                                            <div className={`justify-center items-center text-sm mr-4 text-center rounded-full h-10 w-10   ${apiCalls.research || apiCalls.businessNews || apiCalls.industryTrends || apiCalls.mediaResearch || apiCalls.eli5Business || apiCalls.eli5Data ?
                                                "justify-center items-center text-sm text-zinc-100 font-medium mr-4  text-center  rounded-full  h-10 w-10  pt-2 text-zinc-50 border-sky-600 bg-black/20 border-2 animate-border-pulse" :
                                                "text-zinc-400 font-medium bg-black/20 pt-2.5"
                                                }`}>
                                                2
                                            </div>
                                            <div className=' w-0.5 h-[calc(100%-40px)] mt-1  bg-white/5 ml-5'>
                                            </div>
                                        </div>

                                        <div className='flex-auto  w-full   rounded-sm gap-y-0'>

                                            <div className='w-full -mt-5 p-4 text-lg text-zinc-100 font-medium '>

                                                <div className="flex justify-between items-center">
                                                    <div className={`${apiCalls.research || apiCalls.businessNews || apiCalls.industryTrends || apiCalls.mediaResearch ? 'text-sky-400' : 'text-zinc-400'} text-lg font-medium mt-2 print:hidden`}>Research</div>

                                                    <div className='text-zinc-300 text-base -mr-4'>
                                                        <div className="px-2 py-1 rounded-md  ">


                                                            {/* <div className="absolute bottom-0.5 h-1 bg-sky-600 transition-all duration-200 rounded"
                                                         
                                                         />
                                                         Some info
                                                     </div> */}
                                                        </div>
                                                    </div>
                                                    {/* <div>
                                            You
                                        </div> */}
                                                </div>


                                            </div>

















                                            {1 == 1 ? (

                                                <motion.div
                                                    initial={{ opacity: 0, y: 20 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{
                                                        duration: 0.25,
                                                        ease: "easeOut"
                                                    }}
                                                >
                                                    <div className="  print:break-before-page" ref={researchRef} id="content-id">
                                                        <Accordion
                                                            data-accordion-id={1}
                                                            open={openSections.includes(1)}

                                                            //open={open === 1} 
                                                            icon={researchData.customerInsights ? <Icon id={1} open={open} /> : null} className={`border-none  ${researchData && researchData.customerInsights ? 'rounded-t-lg' : 'bg-black/20 rounded-t-lg'} `}>
                                                            <AccordionHeader onClick={() => handleOpen(1, 1)}
                                                                className={`border-none font-normal p-3 ${apiCalls.research
                                                                    //|| apiCalls.eli5Research
                                                                    ? ' bg-gradient-to-r from-[#121216] via-[#1e1e25] to-[#121216] bg-[length:200%_100%] animate-shimmer rounded-t-lg'
                                                                    : ' hover:bg-black/10'
                                                                    }`}

                                                            >
                                                                <div className="flex justify-between items-center">

                                                                    <div className={`ml-0 text-base flex items-center ${getHeadingClass(apiCalls.research, researchData?.customerInsights || researchData?.productInsights)}`}>
                                                                        <div className="flex items-center ">
                                                                            {apiCalls.research ? (
                                                                                // <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                                                                <ThreeDotsSVGLoading />
                                                                            ) : (
                                                                                <ThreeDotsSVG />
                                                                            )}
                                                                            <div className='ml-4 mt-1 print:hidden'>Company Research</div>
                                                                            <div className='ml-10 text-3xl mt-12 text-orange-300 print:block hidden'>Company Research</div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </AccordionHeader>
                                                            <AccordionBody>
                                                                <>

                                                                    <CompExplainer
                                                                        user={user}
                                                                        title="Using Company Research"
                                                                        explainerId="company-research-tips"
                                                                        type="inline"
                                                                        className="mx-10"
                                                                    >
                                                                        If you don't know how your customers make money, how can you help them make more of it? This section distills CEO-level insights so you can sound like an insider, not just another sales rep. Look for big strategic moves—expansion plans, competitive threats, shifts in focus. If you can tie your solution to their business model instead of their tech stack, you'll be playing the game at a different level.
                                                                    </CompExplainer>

                                                                    {(apiCalls.research || (researchData?.customerInsights || researchData?.productInsights)) && (
                                                                        <>

                                                                            {/* Handle both potential structures */}

















                                                                            <div className="  rounded text-sm text-zinc-300 h-content overflow-y-auto">
                                                                                {apiCalls.research ? (
                                                                                    <Skeleton height={128} />
                                                                                ) : researchData && (
                                                                                    <div className="  px-8   ">

                                                                                        <div className="mb-8   ">
                                                                                            {/* JobsELI5 Section */}


                                                                                            {researchData ?
                                                                                                <>
                                                                                                    <div className='flex-1 items-center px-6'>

                                                                                                        <h4 className="text-zinc-100 text-4xl sp-big-headxing mt-2 ">
                                                                                                            {/* <img src={brandLogoData.logos[0].formats[0].src} className="h-12  inline-block mr-4 mb-2 rounded-lg" /> */}
                                                                                                            {formData.promptCustomerName}</h4>
                                                                                                    </div>

                                                                                                    <div className="text-zinc-200 leading-8 py-4 text-lg w-full px-6">

                                                                                                        {researchData.customerInsights.companyOverview}</div>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }




                                                                                            {businessModelData && (
                                                                                                <ArcherContainer strokeColor="rgba(255,255,255,0.3)"
                                                                                                    lineStyle="curve"
                                                                                                    startMarker={false} endMarker={true}>
                                                                                                    <div className=" mb-12 mt-12  rounded-lg px-6 animate__animated animate__fadeIn">
                                                                                                        <div className="mb-8 flex flex-col md:flex-row gap-0">
                                                                                                            {/* Left column - Summary */}
                                                                                                            <div className="w-7/12  ">
                                                                                                                <h3 className="text-4xl text-zinc-50 mb-4 sp-big-heading">Company Story</h3>
                                                                                                                <h3 className="text-2xl text-zinc-50 mb-4">{businessModelData.headline}</h3>
                                                                                                                <div className="mb-6">
                                                                                                                    <p className="text-zinc-200 leading-7 text-base mb-4">{businessModelData.story.opening}</p>
                                                                                                                    <ArcherElement
                                                                                                                        id="revenue-streams"
                                                                                                                        relations={[{
                                                                                                                            targetId: 'revenue-detail',
                                                                                                                            targetAnchor: 'left',
                                                                                                                            sourceAnchor: 'right',
                                                                                                                            style: { strokeColor: '#437660', strokeWidth: 2, strokeDasharray: '2,5', }
                                                                                                                        }]}
                                                                                                                    >
                                                                                                                        <div>

                                                                                                                            <div className="text-zinc-100 my-4 text-base leading-8 border-emerald-400/50 bg-black/20 rounded-l-lg border-r-2 p-4 -ml-4">
                                                                                                                                <div className="text-base font-medium text-emerald-200 mb-2">
                                                                                                                                    How they Make Money
                                                                                                                                </div>
                                                                                                                                {businessModelData.story.revenueStreams}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </ArcherElement>


                                                                                                                    <ArcherElement
                                                                                                                        id="products-services"
                                                                                                                        relations={[{
                                                                                                                            targetId: 'products-services-detail',
                                                                                                                            //targetId: 'vision-box',
                                                                                                                            targetAnchor: 'left',
                                                                                                                            sourceAnchor: 'right',
                                                                                                                            startMarker: false,
                                                                                                                            endMarker: false,
                                                                                                                            style: {
                                                                                                                                strokeColor: '#6A528C', strokeWidth: 2, strokeDasharray: '2,5', markerEnd: 'none',
                                                                                                                                markerStart: 'none'
                                                                                                                            } // Changed to sky color
                                                                                                                        }]}
                                                                                                                    >
                                                                                                                        <div>

                                                                                                                            <div className="text-zinc-100 my-4 text-base leading-8 border-purple-400/50 bg-black/20 rounded-l-lg border-r-2 p-4 -ml-4">
                                                                                                                                <div className="text-base font-medium text-purple-200 mb-2">
                                                                                                                                    What they Sell
                                                                                                                                </div>
                                                                                                                                {businessModelData.story.productsAndServices}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </ArcherElement>

                                                                                                                    <ArcherElement
                                                                                                                        id="key-customers"
                                                                                                                        relations={[{
                                                                                                                            targetId: 'key-customers-detail',
                                                                                                                            targetAnchor: 'left',
                                                                                                                            sourceAnchor: 'right',
                                                                                                                            style: { strokeColor: '#46608B', strokeWidth: 2, strokeDasharray: '2,5', }
                                                                                                                        }]}
                                                                                                                    >
                                                                                                                        <div className="text-zinc-100 my-4 text-base leading-8 border-blue-400/50 bg-black/20 rounded-l-lg border-r-2 p-4 -ml-4">
                                                                                                                            <div className="text-base font-medium text-blue-200 mb-2">
                                                                                                                                Who they Serve
                                                                                                                            </div>
                                                                                                                            {businessModelData.story.keyCustomers}
                                                                                                                        </div>

                                                                                                                    </ArcherElement>



                                                                                                                    <ArcherElement
                                                                                                                        id="key-competitors"
                                                                                                                        relations={[{
                                                                                                                            targetId: 'key-competitors-detail',
                                                                                                                            targetAnchor: 'left',
                                                                                                                            sourceAnchor: 'right',
                                                                                                                            style: { strokeColor: '#855A39', strokeWidth: 2, strokeDasharray: '2,5', }
                                                                                                                        }]}
                                                                                                                    >
                                                                                                                        <div className="text-zinc-100 my-4 text-base leading-8 border-orange-300/50 bg-black/20 rounded-l-lg border-r-2 p-4 -ml-4">
                                                                                                                            <div className="text-base font-medium text-orange-200 mb-2">
                                                                                                                                Competition
                                                                                                                            </div>
                                                                                                                            {businessModelData.story.keyCompetitors}
                                                                                                                        </div>
                                                                                                                        {/* <p className="text-zinc-100 my-4 text-base leading-8 border-amber-400/50 bg-black/20 rounded-l-lg border-r-2 p-4 -ml-4">
                                                                                                                            {businessModelData.story.keyCompetitors}
                                                                                                                        </p> */}
                                                                                                                    </ArcherElement>



                                                                                                                    <ArcherElement
                                                                                                                        id="financial-analysis"
                                                                                                                        relations={[{
                                                                                                                            //targetId: 'financial-analysis-detail',
                                                                                                                            targetId: 'financial-analysis-detail',
                                                                                                                            targetAnchor: 'left',
                                                                                                                            sourceAnchor: 'right',
                                                                                                                            style: { strokeColor: '#437660', strokeWidth: 2, strokeDasharray: '2,5', }
                                                                                                                        }]}
                                                                                                                    >
                                                                                                                        <div className="text-zinc-100 my-4 text-base leading-8 border-emerald-400/50 bg-black/20 rounded-l-lg border-r-2 p-4 -ml-4">
                                                                                                                            <div className="text-base font-medium text-emerald-200 mb-2">
                                                                                                                                Financial Analysis
                                                                                                                            </div>
                                                                                                                            {businessModelData.story.financialAnalysis}
                                                                                                                        </div>
                                                                                                                        {/* <p className="text-zinc-100 my-4 text-base leading-8 border-emerald-400/50 bg-black/20 rounded-l-lg border-r-2 p-4 -ml-4">
                                                                                                                            {businessModelData.story.financialAnalysis}
                                                                                                                        </p> */}
                                                                                                                    </ArcherElement>
                                                                                                                    <p className="text-zinc-200 leading-7 text-base mb-4">{businessModelData.story.bigTakeaway}</p>
                                                                                                                </div>

                                                                                                                {/* <div className="mb-8">
                                                                                                                    <h4 className="text-xl text-zinc-100 mb-3 flex items-center">
                                                                                                                        <img src="/util/app/b3-admin-business-focus.svg" className="h-6 w-6 mr-2" />
                                                                                                                        Financial Analysis
                                                                                                                    </h4>
                                                                                                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                                                                                        <div className="bg-black/20 p-4 rounded-lg">
                                                                                                                            <h5 className="text-green-400 text-lg mb-2">Revenue & Growth</h5>
                                                                                                                            <p className="text-zinc-200 text-sm">{businessModelData.financialAnalysis.revenueAndGrowth}</p>
                                                                                                                        </div>
                                                                                                                        <div className="bg-black/20 p-4 rounded-lg">
                                                                                                                            <h5 className="text-green-400 text-lg mb-2">Profitability</h5>
                                                                                                                            <p className="text-zinc-200 text-sm">{businessModelData.financialAnalysis.profitability}</p>
                                                                                                                        </div>
                                                                                                                        <div className="bg-black/20 p-4 rounded-lg">
                                                                                                                            <h5 className="text-sky-400 text-lg mb-2">Investment Priorities</h5>
                                                                                                                            <p className="text-zinc-200 text-sm">{businessModelData.financialAnalysis.investmentPriorities}</p>
                                                                                                                        </div>
                                                                                                                        <div className="bg-black/20 p-4 rounded-lg">
                                                                                                                            <h5 className="text-orange-300 text-lg mb-2">Key Financial Challenges</h5>
                                                                                                                            <p className="text-zinc-200 text-sm">{businessModelData.financialAnalysis.keyFinancialChallenges}</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> */}
                                                                                                            </div>

                                                                                                            <div className="w-5/12  pl-24  py-6 ">
                                                                                                                <SpeakIt textToSpeak={businessModelData.story.opening + businessModelData.story.productsAndServices + businessModelData.story.revenueStreams + businessModelData.story.keyCustomers + businessModelData.story.keyCompetitors + businessModelData.story.financialAnalysis + businessModelData.story.bigTakeaway}
                                                                                                                    title={businessModelData.headline} />
                                                                                                                <ArcherElement id="revenue-detail">
                                                                                                                    <div className="bg-emerald-900/10 rounded-lg p-4 mb-6 flex flex-col"

                                                                                                                    >
                                                                                                                        <div className="text-emerald-400   text-xl font-medium mb-2">
                                                                                                                            <div className="flex items-center gap-4">
                                                                                                                                <div className="rounded  h-16 flex items-center justify-center">

                                                                                                                                    <div className="rounded w-12 h-12 flex items-center justify-center bg-emerald-900/30">
                                                                                                                                        <img src="/util/app/b3-admin-revenue.svg" className="h-full w-full p-2" />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <h4 className="text-emerald-200 font-base">
                                                                                                                                    Revenue Streams
                                                                                                                                </h4>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <ul className="list-disc pl-8 space-y-2 flex-grow">
                                                                                                                            {businessModelData.revenueStreams.map((stream, i) => (
                                                                                                                                <li key={i} className="text-zinc-200 text-sm leading-4 pl-2 marker:text-emerald-500 print:text-sm">{stream}</li>
                                                                                                                            ))}
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </ArcherElement>
                                                                                                                <ArcherElement id="products-services-detail">
                                                                                                                    <div className="bg-purple-900/10 rounded-lg p-4 mb-6 flex flex-col"

                                                                                                                    >

                                                                                                                        <div className="text-purple-400  text-xl font-medium mb-2">
                                                                                                                            <div className="flex items-center gap-4">
                                                                                                                                <div className="rounded bg-purple-900/30  w-12 h-12 flex items-center justify-center">
                                                                                                                                    <img src="/util/app/b3-admin-products-services.svg" className="h-full w-full p-2" />
                                                                                                                                </div>
                                                                                                                                <h4 className="text-purple-200 font-base">
                                                                                                                                    Products & Services
                                                                                                                                </h4>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <ul className="list-disc pl-8 space-y-2 flex-grow">
                                                                                                                            {businessModelData.productsAndServices.map((item, i) => (
                                                                                                                                <li key={i} className="text-zinc-200 text-sm leading-4 pl-2 marker:text-purple-500 print:text-sm">{item}</li>
                                                                                                                            ))}
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </ArcherElement>
                                                                                                                <ArcherElement id="key-customers-detail">
                                                                                                                    <div className="bg-blue-900/10 rounded-lg p-4 mb-6 flex flex-col"

                                                                                                                    >
                                                                                                                        <div className="text-blue-400 py-1.5 text-xl font-medium mb-2">
                                                                                                                            <div className="flex items-center gap-4">
                                                                                                                                <div className="rounded bg-blue-900/30  w-12 h-12 flex items-center justify-center">
                                                                                                                                    <img src="/util/app/b3-admin-customer.svg" className="h-12 p-2" />
                                                                                                                                </div>
                                                                                                                                <h4 className="text-blue-200 font-base">
                                                                                                                                    Key Customers
                                                                                                                                </h4>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <ul className="list-disc pl-8 space-y-2 flex-grow">
                                                                                                                            {businessModelData.keyCustomers.map((customer, i) => (
                                                                                                                                <li key={i} className="text-zinc-200 text-sm leading-4 pl-2 marker:text-blue-500 print:text-sm">{customer}</li>
                                                                                                                            ))}
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </ArcherElement>

                                                                                                                <ArcherElement id="key-competitors-detail">
                                                                                                                    <div className="bg-orange-900/10 rounded-lg p-4 mb-6 flex flex-col"

                                                                                                                    >
                                                                                                                        <div className="text-xl    font-medium      mb-3 flex    items-center ">
                                                                                                                            <div className="rounded bg-orange-900/30  w-12 h-12 flex items-center   justify-center">
                                                                                                                                <img src="/util/app/b3-admin-competition-2.svg" className="h-full w-full p-1.5  " />
                                                                                                                            </div>
                                                                                                                            <h4 className="ml-4 text-orange-200    font-base">
                                                                                                                                Key Competitors
                                                                                                                            </h4>
                                                                                                                        </div>
                                                                                                                        <div className="flex mt-2 flex-wrap gap-3">
                                                                                                                            {businessModelData.competitors.map((competitor, i) => (
                                                                                                                                <span
                                                                                                                                    key={i}
                                                                                                                                    className={`px-3 py-1 rounded-full text-sm ${competitor.comparison === 'same' ? 'bg-black/30 text-orange-200' :
                                                                                                                                        competitor.comparison === 'worse' ? 'bg-black/30 text-red-200' :
                                                                                                                                            'bg-black/30 text-green-200'
                                                                                                                                        }`}
                                                                                                                                >
                                                                                                                                    {competitor.name}
                                                                                                                                </span>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </ArcherElement>

                                                                                                                <ArcherElement id="financial-analysis-detail">
                                                                                                                    <div className="mb-8 bg-emerald-900/10 p-4 rounded-lg">
                                                                                                                        <div className="text-xl text-emerald-200 mb-3 flex items-center">
                                                                                                                            <div className="rounded bg-emerald-900/30  w-12 h-12 flex items-center   justify-center">
                                                                                                                                <img src="/util/app/b3-admin-financial-analysis.svg" className="h-full w-full p-2" />
                                                                                                                            </div>
                                                                                                                            <h4 className="ml-4 text-emerald-200 font-semibold">
                                                                                                                                Financial Analysis
                                                                                                                            </h4>
                                                                                                                        </div>
                                                                                                                        <div className=" gap-4">
                                                                                                                            <div className="">
                                                                                                                                <h5 className="text-emerald-200 text-base mt-2">Revenue & Growth</h5>
                                                                                                                                <p className="text-zinc-200 text-sm leading-6">{businessModelData.financialAnalysis.revenueAndGrowth}</p>
                                                                                                                            </div>
                                                                                                                            {/* <div className="bg-black/20 p-4 rounded-lg">
                                                                                                                                <h5 className="text-green-400 text-lg mb-2">Profitability</h5>
                                                                                                                                <p className="text-zinc-200 text-sm">{businessModelData.financialAnalysis.profitability}</p>
                                                                                                                            </div>
                                                                                                                            <div className="bg-black/20 p-4 rounded-lg">
                                                                                                                                <h5 className="text-sky-400 text-lg mb-2">Investment Priorities</h5>
                                                                                                                                <p className="text-zinc-200 text-sm">{businessModelData.financialAnalysis.investmentPriorities}</p>
                                                                                                                            </div> */}
                                                                                                                            <div className="">
                                                                                                                                <h5 className="text-emerald-200 text-base mt-2">Key Financial Challenges</h5>
                                                                                                                                <p className="text-zinc-200 text-sm leading-6">{businessModelData.financialAnalysis.keyFinancialChallenges}</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </ArcherElement>


                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ArcherContainer>
                                                                                            )}


                                                                                            {researchDeepData && researchDeepData.storyTitle && (
                                                                                                <div className="  ml-5 animate__animated animate__fadeIn">

                                                                                                    {/* Path Forward Section */}
                                                                                                    {researchDeepData.pathForward && (
                                                                                                        <ArcherContainer strokeColor="rgba(74, 222, 128, 0.6)" strokeWidth={2}>
                                                                                                            <div className="mb-8 flex flex-col md:flex-row gap-0">
                                                                                                                {/* Left column - Summary */}
                                                                                                                <div className="w-7/12 pr-7">
                                                                                                                    <h3 className="text-4xl text-zinc-50 mb-4 sp-big-heading">Leadership Story</h3>
                                                                                                                    <h3 className="text-2xl text-zinc-50 mb-3">{researchDeepData.storyTitle}</h3>
                                                                                                                    <p className="text-zinc-200 text-base leading-8">{researchDeepData.introduction}</p>

                                                                                                                    <div className="gap-y-4 -mx-4">
                                                                                                                        <ArcherElement
                                                                                                                            id="vision-summary"
                                                                                                                            relations={[{
                                                                                                                                targetId: 'vision-box',
                                                                                                                                targetAnchor: 'left',
                                                                                                                                sourceAnchor: 'right',
                                                                                                                                startMarker: false,
                                                                                                                                endMarker: false,
                                                                                                                                style: {
                                                                                                                                    strokeColor: '#46608B', strokeWidth: 2, strokeDasharray: '2,5', markerEnd: 'none',
                                                                                                                                    markerStart: 'none'
                                                                                                                                } // Changed to sky color
                                                                                                                            }]}
                                                                                                                        >
                                                                                                                            <p className="text-zinc-100 my-4 text-base leading-8 border-sky-400/50 bg-black/20 rounded-l-lg border-r-2 p-4">{researchDeepData.vision.summary}</p>
                                                                                                                        </ArcherElement>

                                                                                                                        <ArcherElement
                                                                                                                            id="challenges-summary"
                                                                                                                            relations={[{
                                                                                                                                targetId: 'challenges-box',
                                                                                                                                targetAnchor: 'left',
                                                                                                                                sourceAnchor: 'right',
                                                                                                                                startMarker: false,
                                                                                                                                endMarker: false,
                                                                                                                                style: {
                                                                                                                                    strokeColor: '#f8717180', strokeWidth: 2, strokeDasharray: '2,5', markerEnd: 'none',
                                                                                                                                    markerStart: 'none'
                                                                                                                                } // Changed to red color
                                                                                                                            }]}
                                                                                                                        >
                                                                                                                            <p className="text-zinc-100 my-4 text-base leading-8 border-red-400/50 bg-black/20 rounded-l-lg border-r-2 p-4">{researchDeepData.challenges.summary}</p>
                                                                                                                        </ArcherElement>

                                                                                                                        <ArcherElement
                                                                                                                            id="path-forward-summary"
                                                                                                                            relations={[{
                                                                                                                                targetId: 'path-forward-box',
                                                                                                                                targetAnchor: 'left',
                                                                                                                                sourceAnchor: 'right',
                                                                                                                                endShape: 'circle',
                                                                                                                                //endMarker: false,
                                                                                                                                startMarker: false,
                                                                                                                                endMarker: false,
                                                                                                                                style: {
                                                                                                                                    strokeColor: '#437660', strokeWidth: 2, strokeDasharray: '2,5', markerEnd: 'none',
                                                                                                                                    markerStart: 'none'
                                                                                                                                } // Kept green color

                                                                                                                            }]}
                                                                                                                        >
                                                                                                                            <p className="text-zinc-100 my-4 text-base leading-8 border-green-400/50 bg-black/20 rounded-l-lg border-r-2 p-4">{researchDeepData.pathForward.summary}</p>
                                                                                                                        </ArcherElement>

                                                                                                                        <p className="text-zinc-200 my-4 leading-8 text-base p-4 rounded-lg ">{researchDeepData.conclusion}</p>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                {/* Right column - Heading, image and bullets */}
                                                                                                                <div className="w-5/12">
                                                                                                                    <div className=" pl-14 pt-6 mb-8  animate__animated animate__fadeIn">
                                                                                                                        <div className=" mx-8 ">
                                                                                                                            <SpeakIt textToSpeak={researchDeepData.introduction + researchDeepData.vision.summary + researchDeepData.challenges.summary + researchDeepData.pathForward.summary + researchDeepData.conclusion}
                                                                                                                                title={researchDeepData.storyTitle}
                                                                                                                            />
                                                                                                                        </div>

                                                                                                                        <ArcherElement id="vision-box">
                                                                                                                            <div className="bg-sky-900/10 rounded-lg p-6 mb-8 mx-8 animate__animated animate__fadeIn">
                                                                                                                                <div className="flex items-center gap-4 mb-4">
                                                                                                                                    <div className="rounded w-12 h-12 flex items-center justify-center bg-sky-900/30">
                                                                                                                                        <img src="/util/app/b3-admin-north-star.svg" className="h-8 w-8" alt="Vision" />
                                                                                                                                    </div>
                                                                                                                                    <h4 className="text-xl font-medium text-sky-300">Vision</h4>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <ul className="space-y-2 mt-3">
                                                                                                                                        {researchDeepData.vision.keyGoals.map((goal, i) => (
                                                                                                                                            <li key={i} className="text-zinc-200 flex items-start gap-4">
                                                                                                                                                <span className="text-sxky-400">{goal}</span>
                                                                                                                                            </li>
                                                                                                                                        ))}
                                                                                                                                    </ul>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </ArcherElement>
                                                                                                                        <ArcherElement id="challenges-box">
                                                                                                                            <div className="bg-red-900/10 rounded-lg p-6 mb-8 mx-8 animate__animated animate__fadeIn">
                                                                                                                                <div className="flex items-center gap-4 mb-4">
                                                                                                                                    <div className="rounded w-12 h-12 flex items-center justify-center bg-red-900/25">
                                                                                                                                        <img src="/util/app/b3-admin-challenges-b.svg" className="h-8 w-8" alt="Challenges" />
                                                                                                                                    </div>
                                                                                                                                    <h4 className="text-xl font-medium text-red-300">Challenges</h4>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <ul className="space-y-2 mt-3">
                                                                                                                                        {researchDeepData.challenges.keyChallenges.map((challenge, i) => (
                                                                                                                                            <li key={i} className="text-zinc-200 flex items-start gap-2">
                                                                                                                                                <span className="text-rexd-300">{challenge}</span>
                                                                                                                                            </li>
                                                                                                                                        ))}
                                                                                                                                    </ul>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </ArcherElement>
                                                                                                                        <ArcherElement id="path-forward-box">
                                                                                                                            <div className="bg-green-900/10 rounded-lg p-6 mb-8 mx-8 animate__animated animate__fadeIn">
                                                                                                                                <div className="flex items-center gap-4 mb-4">
                                                                                                                                    <div className="rounded w-12 h-12 flex items-center justify-center bg-green-900/30">
                                                                                                                                        <img src="/util/app/b3-admin-path-2.svg" className="h-8 w-8" alt="Path Forward" />
                                                                                                                                    </div>
                                                                                                                                    <h4 className="text-xl font-medium text-green-300">Path Forward</h4>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <ul className="space-y-2 mt-3">
                                                                                                                                        {researchDeepData.pathForward.strategies.map((strategy, i) => (
                                                                                                                                            <li key={i} className="text-zinc-200 flex items-start gap-2">
                                                                                                                                                <span className="text-grexen-300">{strategy}</span>
                                                                                                                                            </li>
                                                                                                                                        ))}
                                                                                                                                    </ul>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </ArcherElement>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </ArcherContainer>
                                                                                                    )}

                                                                                                    {/* Conclusion */}
                                                                                                    {/* {researchDeepData.conclusion && (
                                                                                <div className="mt-6 border-t border-zinc-700 pt-6">
                                                                                    <h4 className="text-xl font-medium text-sky-400 mb-3">Conclusion</h4>
                                                                                    <p className="text-zinc-200 leading-7 text-base">{researchDeepData.conclusion}</p>
                                                                                </div>
                                                                            )} */}
                                                                                                </div>
                                                                                            )}

                                                                                            {/* Product Insights */}
                                                                                            {/* {researchData.productInsights && (
                                                                                                <div className="grid grid-cols-3 gap-4 mt-4">

                                                                                                  
                                                                                                    <div className=" bg-black/20   rounded-lg p-4 flex flex-col">

                                                                                                        <div className="text-green-400  py-1.5 rounded-full text-md w-42 text-xl font-medium mb-2">
                                                                                                            <div className="flex items-center gap-4">
                                                                                                                <div className="rounded   w-14 h-14 flex items-center justify-center">
                                                                                                                    <img src="/util/app/b3-admin-business-focus.svg" className="h-full w-full p-2" />
                                                                                                                </div>
                                                                                                                <h4 className="text-green-200 font-base">
                                                                                                                    Business Goals
                                                                                                                </h4>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <ul className="list-disc pl-8 space-y-2   flex-grow">
                                                                                                            {researchData.customerInsights.businessGoals?.map((goal, i) => (
                                                                                                                <li key={i} className="text-zinc-200 text-base leading-6 pl-2  marker:text-green-500 print:text-sm">{goal}</li>
                                                                                                            ))}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                   

                                                                                                    {researchData.customerInsights.strategicInitiatives && viewMode == 'detailed' && (

                                                                                                        <div className=" bg-black/20   rounded-lg p-4 flex flex-col">

                                                                                                            <div className="text-sky-400  py-1.5 rounded-full text-md w-42 text-xl font-medium mb-2">
                                                                                                                <div className="flex items-center gap-4">
                                                                                                                    <div className="rounded    w-14 h-14 flex items-center justify-center">
                                                                                                                        <img src="/util/app/b3-admin-business-strategy.svg" className="h-full w-full p-2" />
                                                                                                                    </div>
                                                                                                                    <h4 className="text-sky-200  ">Strategic Initiatives</h4>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            <ul className="list-disc pl-8 space-y-2 mt-2 flex-grow">
                                                                                                                {researchData.customerInsights.strategicInitiatives.map((initiative, i) => (
                                                                                                                    <li key={i} className="text-zinc-200 text-base  pl-2 leading-6 marker:text-sky-400 print:text-sm">{initiative}</li>
                                                                                                                ))}
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    
                                                                                                    {researchData.customerInsights.challenges && (
                                                                                                        <div className=" bg-black/20   rounded-lg p-4 flex flex-col">

                                                                                                            <div className="text-sky-400  py-1.5 rounded-full text-md w-42 text-xl font-medium mb-2">
                                                                                                                <div className="flex items-center gap-4">
                                                                                                                    <div className="rounded    w-14 h-14 flex items-center justify-center">
                                                                                                                        <img src="/util/app/b3-admin-challenges-b.svg" className="h-full w-full p-2" />
                                                                                                                    </div>
                                                                                                                    <h4 className="text-red-300 font-medium">Challenges</h4>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <ul className="list-disc pl-8 space-y-2 mt-2 flex-grow">
                                                                                                                {researchData.customerInsights.challenges.map((challenge, i) => (
                                                                                                                    <li key={i} className="text-zinc-200  pl-2 text-base marker:text-red-400 print:text-sm leading-6">{challenge}</li>
                                                                                                                ))}
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    )}

                                                                                                </div>
                                                                                            )} */}









                                                                                        </div>


                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    )}









                                                                    {/* {researchDeepData && (
                                                                        <div className="bg-black/20 rounded-lg p-4 flex flex-col mx-8">
                                                                            <h4 className="text-zinc-200 font-medium">Research Deep</h4>
                                                                            <div className="text-zinc-200 py-4 w-full px-6 space-4 grid grid-cols-3 gap-8">
                                                                                {researchDeepData.uniqueInsights &&
                                                                                    researchDeepData.uniqueInsights.map((insight, i) => (
                                                                                        <div key={i} className="mb-3">
                                                                                            <h5 className="text-green-500 font-medium text-lg">{insight.title}</h5>
                                                                                            <p className="text-zinc-200 text-base leading-6 mt-1   print:text-sm">
                                                                                                {insight.explanation}
                                                                                            </p>
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        </div>
                                                                    )} */}


                                                                </>   </AccordionBody>
                                                        </Accordion></div>
                                                </motion.div>

                                            ) : (
                                                null
                                            )}

                                            {1 == 1 ? (
                                                <motion.div
                                                    initial={{ opacity: 0, y: 20 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{
                                                        duration: 0.25,
                                                        ease: "easeOut"
                                                    }}
                                                >
                                                    <div className=" " ref={businessNewsRef}>

                                                        <Accordion
                                                            open={openSections.includes(2)}
                                                            //open={open === 2} 
                                                            data-accordion-id={2}
                                                            icon={apiCallsCompleted.eli5Business ? <Icon id={2} open={open} /> : null} className={`border-none 
                                                    ${apiCallsCompleted.eli5Business ? '' : 'bg-black/20  rounded-none'} `}    >
                                                            <AccordionHeader onClick={() => handleOpen(2, 1)}

                                                                className={`border-none font-normal p-3 ${apiCalls.businessNews || apiCalls.eli5Business
                                                                    ? ' bg-gradient-to-r from-[#121216] via-[#1e1e25] to-[#121216] bg-[length:200%_100%] animate-shimmer rounded-t-lg'
                                                                    : ' hover:bg-black/10'
                                                                    }`}
                                                            >

                                                                {/* <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
            <AccordionHeader onClick={() => handleOpen(2)} className='border-none bg-black/20 hover:bg-black/30 p-4 rounded-lg'> */}
                                                                <div className="flex justify-between items-center ">
                                                                    <h2 className={`   text-base flex items-center ${getHeadingClass(apiCalls.businessNews || apiCalls.eli5Business, businessNewsData?.newsItems)}`}>
                                                                        <div className="flex items-center">
                                                                            {apiCalls.businessNews || apiCalls.eli5Business ? (
                                                                                // <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                                                                <ThreeDotsSVGLoading />
                                                                            ) : (
                                                                                <ThreeDotsSVG />
                                                                            )}
                                                                            <div className='ml-4 mt-1 print:hidden'>Business Events</div>
                                                                            <div className='ml-10 text-3xl mt-12 text-orange-300 print:block hidden'>Business Events</div>
                                                                        </div>
                                                                    </h2>

                                                                </div>
                                                            </AccordionHeader>
                                                            <AccordionBody>
                                                                <>

                                                                    <CompExplainer
                                                                        user={user}
                                                                        title="Using Business Events"
                                                                        explainerId="business-events-tips"
                                                                        type="inline"
                                                                        className="mx-10"
                                                                    >
                                                                        Timing is everything in sales and big changes = big opportunities for you to jump in with timely, relevant insights. Use the intelligence in this section to understand what's happening inside your prospect's world right now that could open a door for you. Maybe they just made an acquisition (hello, integration challenges), hired a new CTO (who wants to make a big impact fast), or launched a new product (which means pressure to hit revenue targets). Find the event that connects to what you sell, and you have a built-in reason to reach out—and more importantly, a reason for them to listen.
                                                                    </CompExplainer>

                                                                    {(apiCalls.businessNews || businessNewsData?.newsItems) && (
                                                                        <div className="rounded text-sm px-8 py-1 text-zinc-300 h-content overflow-y-auto  ">
                                                                            {apiCalls.businessNews ? (
                                                                                <div className="grid grid-cols-1 gap-4">
                                                                                    <Skeleton height={128} />
                                                                                </div>
                                                                            ) : businessNewsData && (
                                                                                <>
                                                                                    <div className="mb-8  ">
                                                                                        {/* JobsELI5 Section */}
                                                                                        {/* {//console.log("ELI5 Data Structure:", eli5DataBusiness)} */}

                                                                                        {/* Handle both potential structures */}
                                                                                        {(eli5DataBusiness?.simpleExplanation || eli5DataBusiness?.eli5Data?.simpleExplanation) && (
                                                                                            <div className="mb-8   grid grid-cols-8 gap-4 px-6">
                                                                                                <div className="col-span-8">
                                                                                                    <div className="text-2xl font-base leading-10 text-zinc-50 mb-2">
                                                                                                        {eli5DataBusiness?.headline || eli5DataBusiness?.eli5Data?.headline}
                                                                                                    </div>
                                                                                                    <div className="text-lg font-base leading-8 text-zinc-300 mb-4">
                                                                                                        {eli5DataBusiness?.simpleExplanation || eli5DataBusiness?.eli5Data?.simpleExplanation}
                                                                                                    </div>
                                                                                                </div>

                                                                                                {/* <div className="grid grid-cols-1 col-span-3 gap-6">
                                                                                        {Array.isArray(eli5DataBusiness?.topReasons || eli5DataBusiness?.eli5Data?.topReasons) && (eli5DataBusiness?.topReasons || eli5DataBusiness?.eli5Data?.topReasons).map((item, index) => (
                                                                                            <div key={index} className="bg-black/10 rounded-lg p-5 transition-all">
                                                                                                <h4 className="text-sky-400 text-lg font-medium mb-3">{index + 1}. {item.reason}</h4>
                                                                                                <p className="text-zinc-300 text-base leading-6">{item.explanation}</p>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div> */}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>


                                                                                    <div className="w-full">
                                                                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                                                                            {businessNewsData.newsItems
                                                                                                .filter(newsItem =>
                                                                                                    newsItem.headline)
                                                                                                .map((newsItem, index) => (
                                                                                                    <CompAIStoryBoardItem key={index} newsItem={newsItem} />
                                                                                                ))}
                                                                                        </div>



                                                                                    </div>



                                                                                    {viewMode === "detailedx" ?
                                                                                        <>
                                                                                            <div className="mb-8    grid grid-cols-8 gap-4 px-6 mt-12 ">
                                                                                                <div className="col-span-8">
                                                                                                    <div className="text-2xl font-base leading-10 text-zinc-50 mb-2">
                                                                                                        Strategy
                                                                                                    </div>
                                                                                                    <div className="text-lg font-base leading-8 text-zinc-300 mb-4">
                                                                                                        Based on the above business events, the following strategies illustrate the application of your proposed solution.
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>


                                                                                            <div className="grid grid-cols-12">


                                                                                                <div className="   col-span-4">

                                                                                                </div>
                                                                                                <div className="table col-span-8 w-full ">
                                                                                                    <div className="flex flex-row  gap-4  print:gap-1 ">
                                                                                                        <div className="flex-1 bg-black/30 print:bg-transparent rounded-t-lg ">
                                                                                                            <div className="mt-2 text-sky-400 px-3 py-1 rounded-full text-lg inline-block">
                                                                                                                <h4 className="text-sky-400 font-medium ">Strategy</h4>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="flex-1 bg-black/10 print:bg-transparent rounded-t-lg ">
                                                                                                            <div className="mt-2 text-sky-400 px-3 py-1 rounded-full text-lg inline-block">
                                                                                                                <h4 className="text-sky-400 font-medium"> Relevance</h4>
                                                                                                            </div>
                                                                                                        </div>



                                                                                                        <div className="flex-1 bg-black/10 print:bg-transparent rounded-t-lg ">
                                                                                                            <div className="mt-2 text-sky-400 px-3 py-1 rounded-full text-lg inline-block">
                                                                                                                <h4 className="text-sky-400 font-medium">Impact</h4>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                {businessNewsData.newsItems
                                                                                                    .filter(newsItem =>
                                                                                                        newsItem.headline &&
                                                                                                        newsItem.summary)

                                                                                                    .map((newsItem, index) => (
                                                                                                        <React.Fragment key={index}>
                                                                                                            <div className="bg-black/0 p-6 rounded-lg col-span-4">
                                                                                                                <div className="flex justify-between w-full">
                                                                                                                    <div className="flex-1">
                                                                                                                        <div className="flex justify-between items-center mb-3">
                                                                                                                            <div className="text-lg font-base text-zinc-50 flex items-center">
                                                                                                                                {newsItem.headline}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className="text-zinc-300 text-sm leading-6 pr-4">
                                                                                                                            <div className="flex items-center justify-between">
                                                                                                                                <div className="flex-1 pr-4">
                                                                                                                                    {newsItem.summary}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="flex items-center justify-center w-8 ml-4 opacity-25 hover:opacity-100 transition-all duration-300">
                                                                                                                        {/* <a
                                                                                                                    href={newsItem.url}
                                                                                                                    target="_blank"
                                                                                                                    rel="noopener noreferrer"
                                                                                                                    title="View source"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src="/media/icon/noun-link-1721082-FFFFFF.svg"
                                                                                                                        className="w-5 h-5"
                                                                                                                        alt="External link"
                                                                                                                    />
                                                                                                                </a> */}
                                                                                                                        <div className=" ">
                                                                                                                            {newsItem.url ?
                                                                                                                                <a
                                                                                                                                    href={newsItem.url}
                                                                                                                                    target="_blank"
                                                                                                                                    rel="noopener noreferrer"
                                                                                                                                    className=""
                                                                                                                                    title="View source"
                                                                                                                                >
                                                                                                                                    <img
                                                                                                                                        src="/media/icon/noun-link-1721082-FFFFFF.svg"
                                                                                                                                        className="w-5 h-5"
                                                                                                                                        alt="External link"
                                                                                                                                    />
                                                                                                                                </a>
                                                                                                                                :
                                                                                                                                <a
                                                                                                                                    href={'https://www.google.com/search?q=' + newsItem.headline}
                                                                                                                                    target="_blank"
                                                                                                                                    rel="noopener noreferrer"
                                                                                                                                    className=" "
                                                                                                                                    title="View source"
                                                                                                                                >
                                                                                                                                    <img
                                                                                                                                        src="/media/icon/noun-link-1721082-FFFFFF.svg"
                                                                                                                                        className="w-5 h-5"
                                                                                                                                        alt="External link"
                                                                                                                                    />
                                                                                                                                </a>

                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className="flex items-center justify-center w-8 ml-4 print:hidden">
                                                                                                                        <svg
                                                                                                                            className="w-6 h-6 text-sky-500"
                                                                                                                            fill="none"
                                                                                                                            stroke="currentColor"
                                                                                                                            viewBox="0 0 24 24"
                                                                                                                        >
                                                                                                                            <path
                                                                                                                                strokeLinecap="round"
                                                                                                                                strokeLinejoin="round"
                                                                                                                                strokeWidth={2}
                                                                                                                                d="M9 5l7 7-7 7"
                                                                                                                            />
                                                                                                                        </svg>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="table col-span-8 w-full h-full ">
                                                                                                                <div className="flex flex-row gap-4 h-full print:gap-1 ">
                                                                                                                    <div className="flex-1 bg-black/30 print:rounded-lg">
                                                                                                                        <div className="text-zinc-200 text-base px-3 pl-8 py-2 pt-8 pb-6 h-full print:text-sm">
                                                                                                                            <ul className="list-disc pl-4 space-y-2">
                                                                                                                                {newsItem.sales_takeaways?.map((takeaway, i) => (
                                                                                                                                    <li key={i} className="text-zinc-200 marker:text-sky-400 print:text-sm -ml-4">
                                                                                                                                        {takeaway}
                                                                                                                                    </li>
                                                                                                                                ))}
                                                                                                                            </ul>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="flex-1 bg-black/10 print:rounded-lg">
                                                                                                                        <div className="text-zinc-200 text-base px-3 py-2 pt-8 pb-6 h-full print:text-sm">
                                                                                                                            {newsItem.relevance}
                                                                                                                        </div>
                                                                                                                    </div>



                                                                                                                    <div className="flex-1 bg-black/10 print:rounded-lg">
                                                                                                                        <div className="text-zinc-200 text-base px-3 py-2 pt-8 pb-6 h-full print:text-sm">
                                                                                                                            {newsItem.impact}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </React.Fragment>
                                                                                                    ))}



                                                                                                <div className="   col-span-4 print:hidden">

                                                                                                </div>
                                                                                                <div className="table col-span-8 w-full print:hidden ">
                                                                                                    <div className="flex flex-row  gap-4 ">
                                                                                                        <div className="flex-1 bg-black/30 rounded-b-lg ">
                                                                                                            <div className="mt-2 text-sky-400 px-3 py-1 rounded-full text-base inline-block">
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="flex-1 bg-black/10 rounded-b-lg ">
                                                                                                            <div className="mt-2 text-sky-400 px-3 py-1 rounded-full text-base inline-block">
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="flex-1 bg-black/10 rounded-b-lg ">
                                                                                                            <div className="mt-2 text-sky-400 px-3 py-1 rounded-full text-base inline-block">
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                    }



                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </>   </AccordionBody>
                                                        </Accordion>
                                                    </div>
                                                </motion.div>
                                            ) : (
                                                null
                                            )}


                                            {/* Display Industry Trends Results */}
                                            <div className=" " ref={industryTrendsRef}>
                                                <Accordion

                                                    open={openSections.includes(3)}
                                                    //open={open === 3} 
                                                    icon={apiCallsCompleted.eli5Industry ? <Icon id={3} open={open} /> : null} className={`border-none 
                                            ${apiCallsCompleted.eli5Industry ? ' ' : 'bg-black/20 rounded-none'} `}>
                                                    <AccordionHeader onClick={() => handleOpen(3, 1)}

                                                        className={`border-none font-normal p-3 ${apiCalls.industryTrends && !apiCallsCompleted.eli5Industry
                                                            ? ' bg-gradient-to-r from-[#121216] via-[#1e1e25] to-[#121216] bg-[length:200%_100%] animate-shimmer rounded-t-lg'
                                                            : ' hover:bg-black/10'
                                                            }`}
                                                    >
                                                        <div className="flex justify-between items-center">
                                                            <h2 className={`  ml-0 text-base flex items-center ${getHeadingClass(apiCalls.industryTrends || !apiCallsCompleted.eli5Industry, industryTrendsData?.trendItems)}`}>
                                                                <div className="flex items-center">
                                                                    {(apiCalls.industryTrends || apiCalls.eli5Industry) && !apiCallsCompleted.eli5Industry ? (
                                                                        // <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                                                        <ThreeDotsSVGLoading />
                                                                    ) : (
                                                                        <ThreeDotsSVG />
                                                                    )}
                                                                    <div className='ml-4 mt-1 print:hidden'>Industry Insights</div>
                                                                    <div className='ml-10 text-3xl mt-12 text-orange-300 print:block hidden'>Industry Insights</div>
                                                                </div>
                                                            </h2>
                                                        </div>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <>
                                                            <CompExplainer
                                                                user={user}
                                                                title="Using Industry Insights"
                                                                explainerId="industry-insights-tips"
                                                                type="inline"
                                                                className="mx-10"
                                                            >
                                                                Even if your prospect's world is quiet, their industry is always moving. Regulations change, competitors launch new products, AI disrupts workflows—whatever it is, these shifts create both risks and opportunities. Your job? Help your prospect see what's coming and position yourself as the guide to help them navigate it. Bring up a relevant trend and ask, 'How's your team handling this?'—it's an easy way to start a strategic conversation instead of just a sales pitch.
                                                            </CompExplainer>

                                                            {(industryTrendsData && industryTrendsData?.trendItems) && (
                                                                <div className="rounded text-sm px-8 py-1 text-zinc-300 h-content overflow-y-auto">
                                                                    {/* {apiCalls.industryTrends ? (
                                                            <div className="grid grid-cols-1 gap-4">
                                                                <Skeleton height={128} />
                                                            </div>
                                                        ) : industryTrendsData && ( */}
                                                                    <div className="w-full">

                                                                        <>
                                                                            {/* {(eli5DataIndustry?.simpleExplanation ) && ( */}
                                                                            <div className="mb-8 px-6  grid grid-cols-8 gap-4">
                                                                                <div className="col-span-8">
                                                                                    <div className="text-2xl font-base leading-10 text-zinc-50 mb-6">
                                                                                        {eli5DataIndustry?.simpleExplanation || eli5DataIndustry?.eli5Data?.headline}
                                                                                    </div>
                                                                                    <div className="text-lg font-base leading-8 text-zinc-300 mb-6">
                                                                                        {eli5DataIndustry?.simpleExplanation || eli5DataIndustry?.eli5Data?.simpleExplanation}
                                                                                    </div>
                                                                                </div>

                                                                                {/* <div className="grid grid-cols-1 col-span-3   gap-6  ">
                                                                                {Array.isArray(eli5DataIndustry?.topReasons) && eli5DataIndustry.topReasons.map((item, index) => (
                                                                                    <div key={index} className="bg-black/10 rounded-lg p-5    transition-all">
                                                                                        <h4 className="text-sky-400 text-xl font-medium mb-3">{item.reason}</h4>
                                                                                        <p className="text-zinc-300">{item.explanation}</p>
                                                                                    </div>
                                                                                ))}

                                                                                {Array.isArray(eli5DataIndustry?.eli5Data?.topReasons) && eli5DataIndustry.eli5Data.topReasons.map((item, index) => (
                                                                                    <div key={index} className="bg-black/10 rounded-lg p-5    transition-all">
                                                                                        <h4 className="text-sky-400 text-lg font-medium mb-3">{index + 1}. {item.reason}</h4>
                                                                                        <p className="text-zinc-300 text-base leading-6">{item.explanation}</p>
                                                                                    </div>
                                                                                ))}
                                                                            </div> */}
                                                                            </div>
                                                                            {/* )} */}
                                                                        </>


                                                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                                                            {industryTrendsData.trendItems
                                                                                .filter(item => item.headline)
                                                                                .map((newsItem, index) => (
                                                                                    <CompAIStoryBoardItem key={index} newsItem={newsItem} />
                                                                                ))}

                                                                            {/*  .map((trendItem, index) => (
                                                                                    <div key={index} className="bg-black/20 rounded-md overflow-hidden">
                                                                                        
                                                                                        <div className="p-6">
                                                                                            
                                                                                            <div className="flex items-start justify-between mb-4">
                                                                                                <div className="text-lg font-base text-zinc-100 flex-1">
                                                                                                    {trendItem.headline}
                                                                                                </div>

                                                                                                {trendItem.url ?
                                                                                                    <a
                                                                                                        href={trendItem.url}
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                        className="ml-2   flex-shrink-0 print:self-start "
                                                                                                        title="View source"
                                                                                                    >
                                                                                                        <img
                                                                                                            src="/media/icon/noun-link-1721082-FFFFFF.svg"
                                                                                                            className="w-5 h-5"
                                                                                                            alt="External link"
                                                                                                        />
                                                                                                    </a>
                                                                                                    :
                                                                                                    <a
                                                                                                        href={'https://www.google.com/search?q=' + trendItem.headline}
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                        className="ml-2   flex-shrink-0 print:self-start  "
                                                                                                        title="View source"
                                                                                                    >
                                                                                                        <img
                                                                                                            src="/media/icon/noun-link-1721082-FFFFFF.svg"
                                                                                                            className="w-5 h-5"
                                                                                                            alt="External link"
                                                                                                        />
                                                                                                    </a>
                                                                                                }

                                                                                            </div>

                                                                                            <div className="space-y-2 pt-2">

                                                                                                <div className="flex print:flex-none pt-4 items-start">
                                                                                                    <div className="bg-green-900/20 w-24   flex-none text-green-300 px-3 py-2 rounded-md text-md text-center  mr-2 print:hidden">
                                                                                                        Relevance
                                                                                                    </div>
                                                                                                    <div className="text-text-200 leading-6 ">
                                                                                                        <div className="bg-green-900/20   flex-none text-green-300 px-3 py-2 rounded-md text-md text-left   -mx-3 mb-2   hidden print:block">
                                                                                                            Relevance
                                                                                                        </div>
                                                                                                        {trendItem.relevance}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="flex print:flex-none pt-4 items-start">
                                                                                                    <div className="bg-sky-900/20 w-24   flex-none text-sky-300 px-3 py-2 rounded-md text-md text-center  mr-2 print:hidden">
                                                                                                        Impact
                                                                                                    </div>
                                                                                                    <div className="text-zinc-200 leading-6">
                                                                                                        <div className="bg-sky-900/20   flex-none text-sky-300 px-3 py-2 rounded-md text-md text-left  -mx-3 mb-2 hidden print:block">
                                                                                                            Impact
                                                                                                        </div>
                                                                                                        {trendItem.impact}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))} */}


                                                                        </div>

                                                                        {viewMode === 'detaxxxxiled' ? (
                                                                            <>
                                                                                <div className="mb-8   grid grid-cols-8 gap-4 px-6 mt-12 ">
                                                                                    <div className="col-span-8">
                                                                                        <div className="text-2xl font-base leading-10 text-zinc-50 mb-2">
                                                                                            Strategy
                                                                                        </div>
                                                                                        <div className="text-lg font-base leading-8 text-zinc-300 mb-4">
                                                                                            Based on the above industry and ecosystem trends, the following strategies illustrate the application of your proposed solution.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="grid grid-cols-12">
                                                                                    {/* Header row */}
                                                                                    <div className="col-span-4"></div>
                                                                                    <div className="table col-span-8 w-full">
                                                                                        <div className="flex flex-row gap-4 print:gap-1 print:gap-y-2 ">
                                                                                            <div className="flex-1 bg-black/20 print:bg-transparent rounded-t-lg">
                                                                                                <div className="mt-2 text-sky-400 px-3 py-1 rounded-full text-lg inline-block">
                                                                                                    <h4 className="text-sky-400 font-medium">Strategy</h4>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="flex-1 bg-black/10 print:bg-transparent rounded-t-lg ">
                                                                                                <div className="mt-2 text-sky-400 px-3 py-1 rounded-full text-lg inline-block">
                                                                                                    <h4 className="text-sky-400 font-medium">Relevance</h4>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="flex-1 bg-black/10 print:bg-transparent rounded-t-lg">
                                                                                                <div className="mt-2 text-sky-400 px-3 py-1 rounded-full text-lg inline-block"><h4 className="text-sky-400 font-medium">Impact</h4></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Trend items */}
                                                                                    {industryTrendsData.trendItems
                                                                                        .filter(item => item.headline && item.summary) // Filter out items without headline or url
                                                                                        .map((trendItem, index) => (
                                                                                            <React.Fragment key={index}>
                                                                                                <div className="bg-black/0 p-4 rounded-lg col-span-4 ">
                                                                                                    <div className="flex justify-between w-full">
                                                                                                        <div className="flex-1">
                                                                                                            <div className="flex justify-between items-center mb-3">
                                                                                                                <div className="text-lg mt-4 font-base text-zinc-50">{trendItem.headline}</div>
                                                                                                            </div>
                                                                                                            <div className="text-zinc-300 text-base  print:text-sm leading-6 pr-4">
                                                                                                                {trendItem.summary}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="flex items-center justify-center w-8 ml-4 opacity-25 hover:opacity-100 transition-all duration-300">
                                                                                                            {/* <a
                                                                                                        href={trendItem.url}
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                        title="View source"
                                                                                                    >
                                                                                                        <img
                                                                                                            src="/media/icon/noun-link-1721082-FFFFFF.svg"
                                                                                                            className="w-5 h-5"
                                                                                                            alt="External link"
                                                                                                        />
                                                                                                    </a> */}
                                                                                                            <div className="print:pt-24">
                                                                                                                {trendItem.url ?
                                                                                                                    <a
                                                                                                                        href={trendItem.url}
                                                                                                                        target="_blank"
                                                                                                                        rel="noopener noreferrer"
                                                                                                                        className=" print:self-start print:mt-2"
                                                                                                                        title="View source"
                                                                                                                    >
                                                                                                                        <img
                                                                                                                            src="/media/icon/noun-link-1721082-FFFFFF.svg"
                                                                                                                            className="w-5 h-5"
                                                                                                                            alt="External link"
                                                                                                                        />
                                                                                                                    </a>
                                                                                                                    :
                                                                                                                    <a
                                                                                                                        href={'https://www.google.com/search?q=' + trendItem.headline}
                                                                                                                        target="_blank"
                                                                                                                        rel="noopener noreferrer"
                                                                                                                        className=" print:self-start print:mt-2"
                                                                                                                        title="View source"
                                                                                                                    >
                                                                                                                        <img
                                                                                                                            src="/media/icon/noun-link-1721082-FFFFFF.svg"
                                                                                                                            className="w-5 h-5"
                                                                                                                            alt="External link"
                                                                                                                        />
                                                                                                                    </a>

                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="flex items-center justify-center w-8 ml-4 print:hidden">
                                                                                                            <svg
                                                                                                                className="w-6 h-6 text-sky-500"
                                                                                                                fill="none"
                                                                                                                stroke="currentColor"
                                                                                                                viewBox="0 0 24 24"
                                                                                                            >
                                                                                                                <path
                                                                                                                    strokeLinecap="round"
                                                                                                                    strokeLinejoin="round"
                                                                                                                    strokeWidth={2}
                                                                                                                    d="M9 5l7 7-7 7"
                                                                                                                />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="table col-span-8 w-full h-full">
                                                                                                    <div className="flex flex-row gap-4 print:gap-1 h-full    ">

                                                                                                        <div className="flex-1 print:rounded-lg  bg-black/20">
                                                                                                            <div className="text-zinc-200 text-base px-3 pl-8 py-2 pt-8 pb-6">
                                                                                                                <ul className="list-disc pl-4 space-y-2 print:text-sm">
                                                                                                                    {trendItem.sales_takeaways?.map((takeaway, i) => (
                                                                                                                        <li key={i} className="text-zinc-200 marker:text-sky-400 -ml-4">
                                                                                                                            {takeaway}
                                                                                                                        </li>
                                                                                                                    ))}
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="flex-1 bg-black/10  print:rounded-lg ">
                                                                                                            <div className="text-zinc-200 text-base px-3 py-2 pt-8 pb-6 print:text-sm">
                                                                                                                {trendItem.relevance}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="flex-1   bg-black/10 print:rounded-lg ">
                                                                                                            <div className="text-zinc-200 text-base px-3 py-2 pt-8 pb-6 print:text-sm">
                                                                                                                {trendItem.impact}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ))}

                                                                                    {/* Footer row */}
                                                                                    <div className="col-span-4 print:hidden"></div>
                                                                                    <div className="table col-span-8 w-full print:hidden">
                                                                                        <div className="flex flex-row gap-4">
                                                                                            <div className="flex-1 bg-black/20 rounded-b-lg">
                                                                                                <div className="mt-2 text-sky-400 px-3 py-1 rounded-full text-base inline-block">
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="flex-1 bg-black/10 rounded-b-lg">
                                                                                                <div className="mt-2 text-sky-400 px-3 py-1 rounded-full text-base inline-block">
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="flex-1 bg-black/10 rounded-b-lg">
                                                                                                <div className="mt-2 text-sky-400 px-3 py-1 rounded-full text-base inline-block">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : null
                                                                        }
                                                                    </div>
                                                                    {/* )} */}
                                                                </div>
                                                            )}
                                                        </>
                                                    </AccordionBody>
                                                </Accordion>
                                            </div>



                                            {/* New Messaging Research Section */}
                                            <div className="mt-0  ">
                                                <Accordion
                                                    data-accordion-id={4}
                                                    open={openSections.includes(4)}
                                                    //open={open === 4} 
                                                    icon={apiCallsCompleted.deepLanguage ? <Icon id={4} open={open} /> : null} className={`border-none  ${apiCallsCompleted.deepLanguage && !apiCalls.deepLanguage ? 'rounded-t-lg' : 'bg-black/20 rounded-b-lg'} `}>
                                                    <AccordionHeader onClick={() => handleOpen(4, 1)}

                                                        className={`border-none font-normal p-3 ${apiCalls.deepLanguage
                                                            ? ' bg-gradient-to-r from-[#121216] via-[#1e1e25] to-[#121216] bg-[length:200%_100%] animate-shimmer rounded-t-lg'
                                                            : ' hover:bg-black/10'
                                                            }`}
                                                    >
                                                        <div className="flex justify-between items-center">

                                                            {/* <h2 className={`   text-base flex items-center ${getHeadingClass(apiCalls.businessNews || apiCalls.eli5Business, businessNewsData?.newsItems)}`}> */}

                                                            <h2 className={`  ml-0 text-base flex items-center ${getHeadingClass(
                                                                apiCalls.deepLanguage

                                                            )}`}>
                                                                <div className="flex items-center">
                                                                    {apiCalls.deepLanguage ? (
                                                                        // <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                                                        <ThreeDotsSVGLoading />
                                                                    ) : (
                                                                        <ThreeDotsSVG />
                                                                    )}
                                                                    <div className='ml-4 font-medium print:hidden'>
                                                                        Customer Language
                                                                        {/* <span className="text-orange-300 text-xs">(upgrade in process)</span> */}
                                                                    </div>
                                                                    <div className='ml-10 text-3xl mt-12 text-orange-300 print:block hidden'>Customer's Mindset and Language</div>
                                                                </div>
                                                            </h2>
                                                        </div>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <>
                                                            <CompExplainer
                                                                user={user}
                                                                title="Customer Language"
                                                                explainerId="media-research-tips"
                                                                type="inline"
                                                                className="mx-10"
                                                            >
                                                                Want to really stand out? Use this section. News articles give you headlines, but deep research from media—podcasts, interviews, deep-dive reports—gives you the real story. This is where execs let their guard down and talk about what actually matters to them. Look for direct pain points they admit to, or market shifts they're concerned about.  Communicate in their language.
                                                            </CompExplainer>







                                                            {/* {console.log("maxDeepData:", maxDeepData)} */}
                                                            {maxDeepData && (
                                                                <div className="overflow-hidden">
                                                                    <div className="px-4 sm:px-6 md:px-14">
                                                                        {/* <h3 className="text-xl sm:text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                Customer Language
            </h3> */}

                                                                        {/* Narrative Story Section */}
                                                                        <div className="mb-6">
                                                                            <h3 className="text-4xl text-zinc-50 mb-4 sp-big-heading">Speaking their Language</h3>
                                                                            <h4 className="text-2xl font-base leading-10 text-zinc-50 mb-6">
                                                                                Lean into your Customer Mindset and Language <span className="text-orange-300 text-xs">* design partner exclusive</span>
                                                                            </h4>
                                                                            <div className="prose dark:prose-invert max-w-none">
                                                                                {maxDeepData.story ? (
                                                                                    <p className="text-lg font-base leading-8 text-zinc-300 mb-6">
                                                                                        {maxDeepData.story}
                                                                                    </p>
                                                                                ) : (
                                                                                    <div className="flex items-center justify-center h-20">
                                                                                        <FiveDotsSVGLoading />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        {/* Conversation Starters with Nested Key Insights */}
                                                                        <div>
                                                                            <h4 className="text-lg font-medium text-gray-800 dark:text-gray-200 mb-2">
                                                                                Language Starters
                                                                            </h4>
                                                                            {maxDeepData.conversation_starters ? (
                                                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                                                    {maxDeepData.conversation_starters.map((starter, index) => (
                                                                                        <div key={index} className="bg-black/20 p-4 rounded-lg ">
                                                                                            <div className="mb-5 bg-black/10 rounded-lg p-8 flex items-center">
                                                                                                <div className="flex items-center justify-center w-12 h-12 border border-orange-300/80 text-orange-300/80 rounded-full mr-8 text-lg bg-black/10 font-medium">
                                                                                                    {index + 1}
                                                                                                </div>
                                                                                                <div className="font-normal text-zinc-50 text-lg flex-1">
                                                                                                    {starter.starter}
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* Starter Details */}
                                                                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm mb-4 bg-black/20 rounded-lg p-8">
                                                                                                <div className="text-zinc-50 col-span-2">
                                                                                                    <p className="font-normal text-lg   text-orange-300/80">
                                                                                                        Follow-up
                                                                                                    </p>
                                                                                                    <p className="text-zinc-300">
                                                                                                        {starter.follow_up}
                                                                                                    </p>
                                                                                                </div>

                                                                                                <div className="text-zinc-50 col-span-2">
                                                                                                    <p className="font-normal text-lg  text-orange-300/80">
                                                                                                        Why It Works
                                                                                                    </p>
                                                                                                    <p className="text-zinc-300">
                                                                                                        {starter.reasons}
                                                                                                    </p>
                                                                                                </div>

                                                                                                <div className="text-zinc-50 col-span-2">
                                                                                                    <p className="font-normal text-lg    text-orange-300/80">
                                                                                                        Key Language
                                                                                                    </p>
                                                                                                    <p className="text-zinc-300">
                                                                                                        {starter.key_language}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* Related Insights - Updated from key_insights to related_insights */}
                                                                                            {starter.related_insights && starter.related_insights.length > 0 && (
                                                                                                <div className="mt-4">

                                                                                                    <div className="grid grid-cols-1 bg-black/20 rounded-lg p-4">
                                                                                                        {starter.related_insights.map((insight, insightIndex) => (
                                                                                                            <div key={insightIndex} className="    p-3 rounded-md">
                                                                                                                <p className="font-normal text-lg   text-orange-300/80">
                                                                                                                    Deeper Insight
                                                                                                                </p>
                                                                                                                <h6 className="font-medium text-zinc-300   mb-2">
                                                                                                                    {insight.insight}
                                                                                                                </h6>
                                                                                                                <div className="space-y-2 text-sm mt-8">
                                                                                                                    <div>
                                                                                                                        <p className="font-medium text-zinc-50">
                                                                                                                            Significance
                                                                                                                        </p>
                                                                                                                        <p className="text-zinc-300 mt-2">
                                                                                                                            {insight.significance}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <p className="font-medium text-zinc-50">
                                                                                                                            Supporting Data
                                                                                                                        </p>
                                                                                                                        <p className="text-zinc-300 mt-2">
                                                                                                                            {insight.supporting_data}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div className="text-zinc-50 col-span-2">
                                                                                                                        <p className="font-medium text-gray-700 dark:text-gray-300 text-right">
                                                                                                                            Sources:
                                                                                                                        </p>
                                                                                                                        <p className="text-gray-600 dark:text-gray-400 text-right">
                                                                                                                            {starter.sources}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </div>

                                                                                                </div>

                                                                                            )}
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            ) : (
                                                                                <div className="flex items-center justify-center h-20">
                                                                                    <FiveDotsSVGLoading />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}




                                                        </>
                                                    </AccordionBody>
                                                </Accordion>
                                            </div>

                                        </div>

                                        {/* <div className='flex justify-between items-center w-full'>
    <div className='w-1 bg-black/0 h-12 mx-auto'></div>
</div> */}




                                    </div>

                                    <div className='flex mt-4' >
                                        {/* <div className="flex-none   ">
                                <div className="flex justify-center items-center text-sm text-zinc-300 mr-4 rounded-full bg-black/20 h-10 w-10">
                                    3
                                </div>
                            </div> */}


                                        <div className='flex-none print:hidden'>
                                            <div className={`justify-center items-center text-sm mr-4 text-center rounded-full h-10 w-10  ${apiCalls.persona ?
                                                "justify-center items-center text-sm text-zinc-100 font-medium mr-4  text-center  rounded-full  h-10 w-10  pt-2 text-zinc-50 border-sky-600 bg-black/20 border-2 " :
                                                "text-zinc-400 font-medium bg-black/20 pt-2.5"
                                                }`}>
                                                3
                                            </div>
                                            <div className=' w-0.5 h-[calc(100%-40px)] mt-1  bg-white/5 ml-5'>
                                            </div>
                                        </div>

                                        <div className='flex-auto w-full   rounded-sm'>
                                            <div className='w-full -mt-3 p-4 print:mx-12 text-lg text-zinc-400 font-medium '>
                                                <div className='ml-0 mt-1 print:hidden'>Representative People</div>
                                                <div className='ml-6 text-3xl mt-12 text-orange-300 print:block hidden'>Representative People</div>
                                                {/* <div className='w-full text-zinc-300 text-base'>Please complete the following setence to get our collaboration started</div> */}
                                            </div>
                                            <div className=" print:mx-12">
                                                <Accordion
                                                    open={openSections.includes(5)}
                                                    //open={open === 5} 
                                                    icon={personaData ? <Icon id={5} open={open} /> : null} className={`border-none 
                                                ${!personaData ? 'bg-black/20' : ''}
                                                print:bg-black/0 rounded-lg`}>
                                                    <AccordionHeader onClick={() => handleOpen(5, 1)}

                                                        className={`border-none font-normal p-3 ${apiCalls.persona
                                                            ? ' bg-gradient-to-r from-[#121216] via-[#1e1e25] to-[#121216] bg-[length:200%_100%] animate-shimmer rounded-lg'
                                                            : ' hover:bg-black/10'
                                                            }`}
                                                    >
                                                        <div className="flex justify-between items-center print:hidden">
                                                            <h2 className={`  ml-0 text-base flex items-center ${getHeadingClass(apiCalls.persona, personaData?.personas)}`}>
                                                                <div className="flex items-center">
                                                                    {apiCalls.persona ? (
                                                                        // <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                                                        <ThreeDotsSVGLoading />
                                                                    ) : (
                                                                        <ThreeDotsSVG />
                                                                    )}
                                                                    <div className='ml-4 font-medium'>Stakeholders
                                                                    </div>
                                                                </div>
                                                            </h2>
                                                        </div>

                                                    </AccordionHeader>
                                                    <AccordionBody>

                                                        <>
                                                            <CompExplainer
                                                                user={user}
                                                                title="Using Stakeholders"
                                                                explainerId="stakeholders-tips"
                                                                type="inline"
                                                                className="mx-10"
                                                            >
                                                                Deals aren't closed with one person. They're won by understanding the different players and how they each define success. The CIO cares about security, the CFO cares about profits and losses, and the end user just wants something that actually makes their life easier. Use this section to help you map out the key roles so you can tailor your message accordingly. If you only sell to one perspective, you risk getting blocked later—so make sure you're covering all bases from the start.
                                                            </CompExplainer>
                                                            {(apiCalls.persona || personaData?.personas) && (
                                                                <div className=" rounded text-sm text-zinc-300 h-content overflow-y-auto">
                                                                    {apiCalls.persona ? (
                                                                        <Skeleton height={128} />
                                                                    ) : personaData?.personas && Array.isArray(personaData.personas) && (

                                                                        <>
                                                                            {/* <div className="grid grid-cols-1  gap-0 px-10 pb-6">
                                                                    {eli5StakeholdersData && eli5StakeholdersData.eli5StakeholdersData &&
                                                                        eli5StakeholdersData.eli5StakeholdersData.personas.map((persona, index) => (
                                                                            <div key={index} className="relative rounded-lg overflow-hidden  bg-black/10  h-full">
                                                                                <div
                                                                                    className="absolute inset-0 opacity-20 z-0"
                                                                                    style={{
                                                                                        backgroundSize: 'cover',
                                                                                        backgroundPosition: 'center'
                                                                                    }}
                                                                                />
                                                                                <div className="grid grid-cols-12 gap-4 h-full px-0 pr-12 py-6">
                                                                                    <div className="col-span-2 mr-4">
                                                                                        <img src={`/media/persona/${persona.image}`} alt={persona.name} className=" mx-auto h-48  " />
                                                                                        <div className="col-span-12 border-t border-zinc-700 p-4">
                                                                                            <div className="flex flex-col">
                                                                                                <div className="font-bold text-lg text-white">{persona.name} - <span className="text-zinc-400 font-normal text-lg">{persona.role}</span></div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-span-10">
                                                                                        <div className='grid grid-cols-1 gap-4'>
                                                                                            <div className="mb-4">
                                                                                                <div className="text-green-400 text-lg font-normal -mb-1">Ideal State</div>
                                                                                                <div className=" text-base text-zinc-300 py-2">
                                                                                                    "{persona.idealState}"
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="mb-6">
                                                                                                <div className="text-red-400 text-lg font-normal -mb-1">Concerns</div>
                                                                                                <div className="text-base  text-zinc-300 py-2">
                                                                                                    "{persona.concerns}"
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        ))
                                                                    }
                                                                </div> */}



                                                                            <div className="space-y-0 px-4  ">
                                                                                {personaData.personas.map((persona, index) => (
                                                                                    <div key={index} className="   ">
                                                                                        <div className="flex  justify-between   px-4 pb-4 rounded">
                                                                                            <div className="flex-none w-3/12 items-start gap-6 print:gap-2 ">
                                                                                                {/* Persona Header Info */}
                                                                                                <div className="flex-grow ">
                                                                                                    <div className="flex items-center gap-3">
                                                                                                        <div className="w-24 h-24 ml-2 mt-4 rounded-full bg-black/25  flex items-center aspect-square justify-center inline border-2 border-zinc-800"
                                                                                                            style={{
                                                                                                                backgroundImage: `url(/media/persona/${persona.image})`,
                                                                                                                backgroundSize: 'cover',
                                                                                                                backgroundPosition: 'top'
                                                                                                            }}
                                                                                                        />
                                                                                                        <div className='ml-2 flex flex-col justify-center'>
                                                                                                            <h4 className="text-zinc-50 text-lg ml-2">{persona.name}</h4>
                                                                                                            <div className="  mt-2 text-sky-400 px-2 py-1 rounded-full w-full text-md font-medium">
                                                                                                                {persona.externalStakeholder == true ?
                                                                                                                    <span className="text-teal-400">
                                                                                                                        {persona.role}<br />
                                                                                                                        * customer of {formData.promptCustomerName}
                                                                                                                    </span>
                                                                                                                    :
                                                                                                                    <span>
                                                                                                                        {persona.role}
                                                                                                                    </span>
                                                                                                                }

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* Goals Section */}

                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="flex-grow ml-4">

                                                                                                <div className="grid grid-cols-3 gap-0 text-base">

                                                                                                    <div className="border-blue-400/20 borderdd bg-blxxue-900/10  rounded p-4">
                                                                                                        <h5 className="text-blue-400 text-lg mb-2">Goals</h5>
                                                                                                        <ul className="list-disc pl-4 space-y-1 print:text-sm">
                                                                                                            {persona.goals.map((goal, idx) => (
                                                                                                                <li key={idx} className="text-zinc-200">{goal}</li>
                                                                                                            ))}
                                                                                                        </ul>
                                                                                                    </div>

                                                                                                    {/* Pain Points Section */}
                                                                                                    <div className="border-rose-400/20 borxder bg-rosxxe-900/10  rounded p-4">
                                                                                                        <h5 className="text-rose-400  text-lg mb-3">Pain Points</h5>
                                                                                                        <ul className="list-disc pl-4 space-y-1 print:text-sm">
                                                                                                            {persona.painPoints.map((point, idx) => (
                                                                                                                <li key={idx} className="text-zinc-200">{point}</li>
                                                                                                            ))}
                                                                                                        </ul>
                                                                                                    </div>

                                                                                                    {/* Success Metrics Section */}
                                                                                                    <div className="border-green-400/20 borxder bg-gxxreen-900/10  rounded p-4">
                                                                                                        <h5 className="text-green-400  text-lg mb-3">Success Metrics</h5>
                                                                                                        <ul className="list-disc pl-4 space-y-1 print:text-sm">
                                                                                                            {persona.successMetrics.map((metric, idx) => (
                                                                                                                <li key={idx} className="text-zinc-200">{metric}</li>
                                                                                                            ))}
                                                                                                        </ul>
                                                                                                    </div>

                                                                                                    {/* {persona.benefits.efficiency && (
                                                <div className="bg-black/5 rounded p-3">
                                                    <h5 className="text-zinc-50  mb-3">Efficiency Benefits</h5>
                                                    <ul className="list-disc pl-4 space-y-1">
                                                        {Array.isArray(persona.benefits.efficiency) && persona.benefits.efficiency.map((benefit, idx) => (
                                                            <li key={idx} className="text-zinc-400">{benefit}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )} */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        {/* Benefits Section */}
                                                                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                                                                            {persona.benefits && (
                                                                                                <>


                                                                                                    {persona.benefits.strategic && (
                                                                                                        <div className="bg-black/20 rounded p-3">
                                                                                                            <h5 className="text-zinc-100  mb-2">Strategic Benefits</h5>
                                                                                                            <ul className="list-disc pl-4 space-y-1">
                                                                                                                {Array.isArray(persona.benefits.strategic) && persona.benefits.strategic.map((benefit, idx) => (
                                                                                                                    <li key={idx} className="text-zinc-400">{benefit}</li>
                                                                                                                ))}
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    )}

                                                                                                    {persona.benefits.financial && (
                                                                                                        <div className="bg-black/20 rounded p-3">
                                                                                                            <h5 className="text-zinc-100 mb-2">Financial Benefits</h5>
                                                                                                            <ul className="list-disc pl-4 space-y-1">
                                                                                                                {Array.isArray(persona.benefits.financial) && persona.benefits.financial.map((benefit, idx) => (
                                                                                                                    <li key={idx} className="text-zinc-400">{benefit}</li>
                                                                                                                ))}
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>

                                                                                ))}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>

                                                            )}
                                                        </>
                                                    </AccordionBody>
                                                </Accordion>
                                            </div>
                                        </div>


                                        {/* <div className='flex justify-between items-center w-full'>
                                <div className='w-1 bg-black/0 h-12 mx-auto'></div>
                            </div> */}
                                    </div>
                                </div>

                                <div className='flex mt-4 pb-24' >
                                    <div className="flex-none   ">
                                        <div className={`justify-center items-center text-sm mr-4 text-center rounded-full h-10 w-10   ${apiCalls.story || apiCalls.producer || apiCalls.storyChapters || apiCalls.businessAnalysis || apiCalls.storySpeaker || apiCalls.storySpeakerSummary ?
                                            "justify-center items-center text-sm text-zinc-100 font-medium mr-4  text-center  rounded-full  h-10 w-10  pt-2 text-zinc-50 border-sky-600 bg-black/20 border-2 " :
                                            "text-zinc-400 font-medium bg-black/20 pt-2.5"
                                            }`}>
                                            4
                                        </div>
                                    </div>

                                    <div
                                        //className='rounded-lg  bg-black/20 p-4 '
                                        className={` flex-auto    `}
                                    >
                                        <div className='w-full -mt-3 p-4 text-lg text-zinc-100 font-medium '>
                                            <div className={`${apiCalls.story || apiCalls.producer || apiCalls.storyChapters || apiCalls.businessAnalysis || apiCalls.storySpeaker || apiCalls.storySpeakerSummary ? 'text-sky-400' : 'text-zinc-400'} text-lg font-medium`}>Story</div>
                                            {/* <div className='w-full text-zinc-300 text-base'>Please complete the following setence to get our collaboration started</div> */}
                                        </div>

                                        <div ref={draftStoryRef}

                                            className={`bg-black/20 p-4 rounded-lg ${apiCalls.story || apiCalls.producer || apiCalls.storyChapters || apiCalls.businessAnalysis || apiCalls.storySpeaker || apiCalls.storySpeakerSummary
                                                ? ' bg-gradient-to-r from-[#121216] via-[#1e1e25] to-[#121216] bg-[length:200%_100%] animate-shimmer rounded-lg'
                                                : '  '
                                                }`}
                                        >



                                            <div className="relative w-full">
                                                <div className="grid grid-cols-7 gap-1   rounded   rounded-md">
                                                    {[
                                                        { name: 'Story Brainstorm', key: 'story' },
                                                        { name: 'Story Framing', key: 'producer' },
                                                        { name: 'Story Chapters', key: 'storyChapters' },
                                                        { name: 'Business Review', key: 'businessAnalysis' },
                                                        { name: 'Talk Track', key: 'storySpeaker' },
                                                        { name: 'Story Summary', key: 'storySpeakerSummary' },
                                                        { name: 'Review Analysis', key: 'salesAnalysis' },
                                                    ].map((step) => (
                                                        <div key={step.name} className="flex flex-col items-center">
                                                            <div className="text-center">
                                                                <div className={`font-base mt-1 text-base ${apiCalls[step.key] ? 'text-zinc-100' :
                                                                    sectionTimes[step.key] ? 'text-zinc-100' :
                                                                        'text-zinc-600'
                                                                    }`}>
                                                                    {step.name}
                                                                </div>
                                                                {/* {sectionTimes[step.key] && (
                                                            <span className="text-xs text-zinc-400">
                                                                {formatSectionTime(sectionTimes[step.key])}
                                                            </span>
                                                        )} */}
                                                            </div>
                                                            <div className={`relative  mt-2 w-full rounded flex items-center justify-center ${apiCalls[step.key] ? 'bg-transparent' :
                                                                sectionTimes[step.key] ? ' ' : ' '
                                                                }`}>
                                                                {apiCalls[step.key] ? (
                                                                    <FiveDotsSVGLoading />
                                                                ) : (
                                                                    <FiveDotsSVG />
                                                                )}
                                                            </div>

                                                        </div>
                                                    ))}
                                                    {/* <div key='openStory' className="flex flex-col items-center">
                                                <div className="text-center">
                                                    <div className={`text-base text-zinc-100 bg-sky-500 px-2 py-2 mt-2 rounded`}>
                                                        Open Story Paths
                                                    </div>
                                                </div>
                                            </div> */}


                                                    {/* <div key='openStory' className="flex flex-col items-center">
                                                {(storyVisualizationData && storyChaptersData && businessAnalysisData && !apiCalls.storySpeakerSummary && !apiCalls.storySpeaker && !apiCalls.story) ?
                                                    <button
                                                        type="button"
                                                        onClick={() => setIsKanbanModalOpen(true)}
                                                        className="bg-sky-500 mx-auto hover:bg-sky-400 text-white py-2 px-4 rounded text-sm flex items-center gap-2 mt-8"
                                                    >
                                                        Open Your StoryPaths
                                                    </button>
                                                    :
                                                    <div className="text-center">
                                                        <div className={`text-base text-zinc-700 bg-zinc-800/50 px-2 py-2 mt-2 rounded`}>
                                                            Open Story Paths
                                                        </div>
                                                    </div>
                                                }
                                            </div> */}



                                                </div>
                                            </div>





                                            <div className="rounded text-sm text-zinc-300 h-content overflow-y-auto">
                                                {/* {apiCalls.story ? (
                                            <Skeleton height={128} />
                                        ) : ''} */}
                                                <div>


                                                    {storyData?.introduction && (
                                                        <div className="border-l-4 border-sky-500 pl-4 mb-8">
                                                            <div className="text-lg font-semibold text-zinc-100 mb-3">Introduction</div>
                                                            <div className="bg-zinc-800/50 rounded p-4">
                                                                <p className="text-zinc-300 leading-relaxed mb-6">{storyData.introduction.summary}</p>
                                                            </div>
                                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                                {storyData.introduction.people.map((person, index) => (
                                                                    <div key={index} className="bg-zinc-800/50 rounded-lg p-4">
                                                                        <h4 className="text-zinc-100 font-medium">{person.name}</h4>
                                                                        <p className="text-sky-400 text-xs">{person.role}</p>
                                                                        <p className="text-zinc-400 text-sm">{person.goal}</p>
                                                                        <div className="text-zinc-500 text-xs">
                                                                            {person.age} years • {person.gender}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}


                                                    {storyData?.scenes?.scenes && storyData.scenes.scenes.map((scene, index) => (
                                                        <div key={index} className="border-l-4 border-sky-500 pl-4 mb-8">
                                                            <div className="flex items-center gap-2 mb-3">
                                                                <span className="bg-sky-500/20 text-sky-300 px-2 py-1 rounded-full text-lg font-semibold">
                                                                    {index + 1}
                                                                </span>
                                                                <div className="text-lg font-semibold text-zinc-100">{scene.title}</div>
                                                                <p className="text-zinc-300 mb-4">{scene.description}</p>
                                                            </div>

                                                            <p className="text-zinc-300 mb-4">{scene.story}</p>

                                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                                <div className="bg-zinc-800/50 rounded p-4">
                                                                    <h4 className="text-zinc-300 font-medium mb-2">Customer Value</h4>
                                                                    <p className="text-zinc-400">{scene.customer_value}</p>
                                                                </div>
                                                                <div className="bg-zinc-800/50 rounded p-4">
                                                                    <h4 className="text-zinc-300 font-medium mb-2">Business Value</h4>
                                                                    <p className="text-zinc-400">{scene.business_value}</p>
                                                                </div>
                                                                <div className="bg-zinc-800/50 rounded p-4 col-span-2">
                                                                    <h4 className="text-zinc-300 font-medium mb-2">Talk Track</h4>
                                                                    <p className="text-zinc-400">{scene.talk_track}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}


                                                    {storyData?.conclusion && (
                                                        <div className="border-l-4 border-emerald-500 pl-4 mt-12">
                                                            <div className="text-lg font-semibold text-zinc-100 mb-3">Conclusion</div>
                                                            <p className="text-zinc-300 leading-relaxed mb-6">{storyData.conclusion.summary}</p>
                                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                                {storyData.conclusion.benefits.map((benefit, index) => (
                                                                    <div key={index} className="bg-zinc-800/50 rounded-lg p-4">
                                                                        <h4 className="text-zinc-100 font-medium mb-2">{benefit.name}</h4>
                                                                        <p className="text-sky-400 text-xs">{benefit.role}</p>
                                                                        <ul className="list-disc pl-4 space-y-1">
                                                                            {benefit.benefits.map((b, idx) => (
                                                                                <li key={idx}>{b}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>

                                            </div>










                                            <div className=" " ref={producedStoryRef}>
                                                {/* <div className="flex justify-between items-center">
            <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.producer, (producedStory && producedStory.scenes))}`}>
                <div className="flex items-center">
                    {apiCalls.producer ? (
                        <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                    ) : (
                        <ThreeDotsSVG />
                    )}
                    <div className='ml-4'>Produced Story</div>
                </div>
            </h2>
        </div> */}


                                                {/* {storyVisualizationData && storyChaptersData && businessAnalysisData && ( */}
                                                <div className="w-full pb-8">
                                                    {/* <div className='mx-auto flex mt-12 w-full items-center justify-center text-zinc-300'>
                                                    cool loader - StoryPath brand filling up?
                                                </div> */}



                                                </div>
                                                {/* )} */}

                                                <div className="flex justify-between items-center">
                                                    {/* <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.producer, (producedStory && producedStory.scenes))}`}>
                <div className="flex items-center">
                    {apiCalls.producer ? (
                        <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                    ) : (
                        <ThreeDotsSVG />
                    )}
                    <div className='ml-4'>Produced Story</div>
                </div>
            </h2> */}

                                                </div>



                                                {/* <div id="producedStoryContent" className=' ' >


                                            



                                            {storyVisualizationData && (
                                                <div className="p-6">


                                                    <div className="bg-black/10 rounded p-8">
                                                        <h2 className="text-3xl text-zinc-50 mb-2">{storyVisualizationData.title?.title}</h2>
                                                        <div className="text-xl text-zinc-50 mb-4">{storyVisualizationData.title?.subtitle}</div>
                                                        <p className="leading-relaxed mt-8 text-zinc-400">{storyVisualizationData.opening?.summary}</p>
                                                        <p className="text-zinc-400 mt-4">{storyVisualizationData.opening?.challenge}</p>
                                                    </div>

                                                    <div className="mt-8">
                                                        <div className="text-lg mt-8 mb-2 ml-8 text-sky-400">Stakeholders</div>
                                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                                                            {storyVisualizationData.opening?.participants.map((person, index) => (
                                                                <div key={index} className="bg-black/10 rounded p-8">
                                                                    <h4 className="text-zinc-50 text-lg">{person.name}</h4>
                                                                    <p className="text-sky-400 text-base">{person.role}</p>
                                                                    <p className="text-zinc-400 text-sm mt-2">{person.introduction}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {!storyChaptersData ? (
                                                        <div className="flex justify-between items-center mt-8">
                                                            <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.storyChapters, storyChaptersData)}`}>
                                                                <div className="flex items-center">
                                                                    {apiCalls.storyChapters ? (
                                                                        <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                                                    ) : (
                                                                        <ThreeDotsSVG />
                                                                    )}
                                                                    <div className='ml-4'>Story Chapters</div>
                                                                </div>
                                                            </h2>
                                                        </div>
                                                    ) : null}
                                                    {(apiCalls.storyChapters || storyChaptersData) && (
                                                        <div className="rounded text-sm text-zinc-300 h-content overflow-y-auto mt-8">
                                                            {apiCalls.storyChapters ? (
                                                                <Skeleton height={128} />
                                                            ) : storyChaptersData?.scenes && Array.isArray(storyChaptersData.scenes) ? (
                                                                storyChaptersData.scenes.map((scene, index) => (
                                                                    <div key={index} className="bg-black/10 p-8 pt-12 rounded-lg mb-8">
                                                                        <div className="flex    mb-3">
                                                                            <div className="bg-black/40 text-white  h-14 w-14 pt-2.5  text-center ml-3   mr-4 rounded-full text-3xl   ">
                                                                                {index + 1}
                                                                            </div>
                                                                            <div className="flex flex-col">
                                                                                <div className="text-2xl  text-zinc-50">{scene.title}</div>
                                                                                <p className="text-zinc-300 mb-4">{scene.location}</p>
                                                                                <p className="text-zinc-100 text-lg mb-4">{scene.description}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="   p-5 mb-4">
                                                                            <div className='flex'>
                                                                                <img src="/util/app/b3-admin-talk-track.svg" className='h-8 inline mr-4' />
                                                                                <h4 className="text-zinc-100 text-lg ml-4  ">Talk Track</h4>
                                                                            </div>
                                                                            <div className=" rounded px-4">
                                                                                <p className="text-zinc-300 pl-12 mb-4">{scene.talk_track}</p>

                                                                                <p className="text-zinc-300 pl-12">{scene.story}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 mt-8">
                                                                            <div className="  rounded ml-4 col-span-2">
                                                                                <div className='flex'>
                                                                                    <img src="/util/app/b3-admin-path-people.svg" className='h-8 inline mr-4' />
                                                                                    <h4 className="text-zinc-100 text-lg ml-4 mb-2">Key People</h4>
                                                                                </div>
                                                                                <div className="grid grid-cols-3 mt-2 gap-4 pl-14">
                                                                                    {scene.people.map((person, idx) => (
                                                                                        <div key={idx} className="mb-2 rounded-lg bg-black/10 p-4 flex gap-4">

                                                                                            {person.image && (
                                                                                                <div className="w-24 h-24  rounded-lg bg-white/5 flex items-center justify-center inline"
                                                                                                    style={{
                                                                                                        backgroundImage: `url(/media/persona/${person.image})`,
                                                                                                        backgroundSize: 'cover',
                                                                                                        backgroundPosition: 'top'
                                                                                                    }} />
                                                                                            )}
                                                                                            <div className="flex flex-col">
                                                                                                <div className="text-sky-500 text-lg">{person.name}</div>
                                                                                                <div className=" text-sm">{person.role}</div>
                                                                                                <div className="text-zinc-50 text-md mt-2">{person.actions}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex gap-4 ml-2 mb-4">
                                                                            <div className=" rounded h-12 w-12 flex items-center justify-center mt-2">
                                                                                <img src="/util/app/b3-admin-path-value2.svg" className="h-8  opacity-80 " />
                                                                            </div>

                                                                            <div className=" rounded py-4">
                                                                                <h4 className="text-green-500  text-lg ml-4 ">Value</h4>

                                                                                <div className="bg-green-500/5 mt-4  rounded p-4">
                                                                                    <h4 className="text-zinc-100 text-md font-semibold mb-2">Customer Value</h4>
                                                                                    <p className="text-zinc-300">{scene.customer_value}</p>
                                                                                    <h4 className="text-zinc-100 text-md font-semibold mb-2 mt-4">Business Value</h4>
                                                                                    <div className=" text-zinc-300 ">
                                                                                        <p className="text-zinc-300">{scene.business_value}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="  rounded h-12 w-12 flex items-center justify-center mt-2">
                                                                                <img src="/util/app/b3-admin-path-tech.svg" className="h-8  opacity-80 " />
                                                                            </div>
                                                                            <div className=" ">
                                                                                {scene.technology && (
                                                                                    <div className="mt-4">
                                                                                        <h4 className="text-amber-400 text-lg ml-4 mb-2">Technology</h4>
                                                                                        <ul className="space-y-2 bg-amber-400/5 rounded mt-4 p-4">
                                                                                            {scene.technology.devices ? (
                                                                                                scene.technology.devices.map((device, idx) => (
                                                                                                    <li key={idx} className="text-zinc-300">
                                                                                                        <div className="font-medium text-white">{device.name}</div>
                                                                                                        <div className="text-sm text-zinc-300 mt-2">{device.capabilities}</div>
                                                                                                    </li>
                                                                                                ))
                                                                                            ) : scene.technology.integrations ? (
                                                                                                scene.technology.integrations.map((integration, idx) => (
                                                                                                    <li key={idx} className="text-zinc-300">
                                                                                                        <div className="font-medium text-white">{integration}</div>
                                                                                                    </li>
                                                                                                ))
                                                                                            ) : null}
                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="text-zinc-400 p-4">No story chapters available</div>
                                                            )}
                                                        </div>
                                                    )}
                                                    <div className="mt-8">
                                                        <div className="text-lg mb-2 text-sky-400">Conclusion</div>
                                                        <div className="bg-black/10 rounded p-8">
                                                            <p className="leading-relaxed text-zinc-100">{storyVisualizationData.closing?.summary}</p>

                                                            <div className="mt-6">
                                                                <h4 className="text-zinc-50 text-lg mb-4">Outcomes</h4>
                                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                                    {storyVisualizationData.closing?.outcomes.map((outcome, index) => (
                                                                        <div key={index} className="bg-black/40 rounded p-4">
                                                                            <h5 className="text-sky-400">{outcome.benefit}</h5>
                                                                            <p className="text-zinc-300 mt-2">{outcome.impact}</p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>

                                                            <div className="mt-6">
                                                                <h4 className="text-zinc-50 text-lg mb-4">Future Implications</h4>
                                                                <p className="text-zinc-300">{storyVisualizationData.closing?.future_implications}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div> */}

                                                {/* {(apiCalls.producer || (producedStory && producedStory.scenes)) && (
                                            <div className="rounded text-sm text-zinc-300 h-content overflow-y-auto">
                                                {apiCalls.producer ? (
                                                    <div className="grid grid-cols-1 gap-4">
                                                        <Skeleton height={128} />
                                                    </div>
                                                ) : producedStory && producedStory.scenes ? (
                                                    <div>
                                                        <div className="border-l-4 border-purple-500 pl-4">
                                                            <div className="text-lg font-semibold text-zinc-100 mb-3">Introduction</div>
                                                            <p className="text-zinc-300 leading-relaxed">{producedStory.introduction?.summary}</p>
                                                            <div className="mt-4 space-y-4">
                                                                <h4 className="text-zinc-300 font-medium">Key People</h4>
                                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                                    {producedStory.introduction?.people?.map((person, index) => (
                                                                        <div key={index}>
                                                                            <div className="bg-zinc-800/50 rounded p-4">
                                                                                <div className="flex items-center gap-2 mb-2">
                                                                                    <span className="text-zinc-100 font-medium">{person.name}</span>
                                                                                    <span className="bg-sky-500/20 text-sky-400 px-2 py-0.5 rounded-full text-xs">
                                                                                        {person.role}
                                                                                    </span>
                                                                                </div>
                                                                                <p className="text-zinc-400 text-sm mb-2">{person.goal}</p>
                                                                                <div className="text-zinc-500 text-xs">
                                                                                    {person.age} years • {person.gender}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div className="border-l-4 border-emerald-500 pl-4 mt-12">
                                                            <div className="text-lg font-semibold text-zinc-100 mb-3">Conclusion</div>
                                                            <p className="text-zinc-300 mb-4">{producedStory.conclusion?.summary}</p>
                                                            <div className="space-y-4">
                                                                <h4 className="text-zinc-300 font-medium">Benefits by Role</h4>
                                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                                    {producedStory.conclusion?.benefits?.map((person, index) => (
                                                                        <div key={index}>
                                                                            <div className="bg-zinc-800/50 rounded p-4">
                                                                                <div className="flex items-center gap-2 mb-2">
                                                                                    <span className="text-zinc-100 font-medium">{person.name}</span>
                                                                                    <span className="bg-sky-500/20 text-sky-400 px-2 py-0.5 rounded-full text-xs">
                                                                                        {person.role}
                                                                                    </span>
                                                                                </div>
                                                                                <ul className="list-disc pl-4 space-y-1">
                                                                                    {person.benefits.map((benefit, idx) => (
                                                                                        <li key={idx} className="text-zinc-400">{benefit}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        )} */}

                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </motion.div>
                        </>
                    )}



                {storypathType === 'library' && (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 0.25,
                            ease: "easeOut"
                        }}
                        exit={{ y: -10, opacity: 0 }}
                    >
                        <div onSubmit={generateStory} className="  text-zinc-100 mt-8   ">

                            <StoryLibrary />

                        </div>
                    </motion.div>
                )}


                {storypathType === 'blank' && (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{
                            duration: 0.25,
                            ease: "easeOut"
                        }}
                    >
                        <div className="space-y-4 text-zinc-100 mt-8 p-8 bg-black/20 rounded-lg">
                            <div className="text-xl leading-6 text-gray-100 mb-6">Create Immersive Story</div>

                            {/* Story Name Input */}
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-400 focus:outline-none">
                                <input
                                    type="text"
                                    // className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                    className={`inline-flex w-full p-3 bg-black/20 text-zinc-50 rounded border border-zinc-700 focus:border-sky-500 focus:ring-sky-500 
[-webkit-autofill:bg-zinc-600/25] 
[-webkit-autofill:text-zinc-50] 
[-webkit-autofill:shadow-[0_0_0_30px_rgb(82_82_91_/_0.25)_inset]] 
[-webkit-autofill:hover:bg-zinc-600/25] 
[-webkit-autofill:focus:bg-zinc-600/25] 
[-webkit-autofill:active:bg-zinc-600/25]`}
                                    placeholder="Story Name"
                                    value={storyName}
                                    onChange={(e) => setStoryName(e.target.value)}
                                />
                            </div>

                            {/* Story Description Input */}
                            <div className="mt-4">
                                <textarea
                                    placeholder="Description"
                                    rows={4}
                                    className={`inline-flex w-full p-3 bg-black/20 text-zinc-50 rounded border border-zinc-700 focus:border-sky-500 focus:ring-sky-500 
                                        [-webkit-autofill:bg-zinc-600/25] 
                                        [-webkit-autofill:text-zinc-50] 
                                        [-webkit-autofill:shadow-[0_0_0_30px_rgb(82_82_91_/_0.25)_inset]] 
                                        [-webkit-autofill:hover:bg-zinc-600/25] 
                                        [-webkit-autofill:focus:bg-zinc-600/25] 
                                        [-webkit-autofill:active:bg-zinc-600/25]`}
                                    value={storyDescription}
                                    onChange={(e) => setStoryDescription(e.target.value)}
                                />
                            </div>


                            {/* <div className="mt-4">

                                <div className="mt-4">
                                    <div className="text-lg text-zinc-100 mb-4">Template</div>
                                    <div className="grid grid-cols-5 gap-4">
                                        {[

                                            { id: 'retail', name: 'Retail Journey', icon: '/media/templates/retail.svg' },
                                            { id: 'field', name: 'Field Service', icon: '/media/templates/field.svg' },
                                            { id: 'marketing', name: 'Marketing Automation', icon: '/media/templates/marketing.svg' },
                                            { id: 'hub', name: 'Hub Spoke', icon: '/media/templates/hub.svg' },
                                            { id: 'blank', name: 'Blank', icon: '/media/templates/blank.svg' },
                                        ].map((template) => (
                                            <button
                                                key={template.id}
                                                onClick={() => setSelectedTemplate(template.id)}
                                                className={`flex flex-col items-center p-4 rounded-lg transition-all ${selectedTemplate === template.id
                                                    ? 'bg-black/20 ring-2 ring-sky-500 ring-offset-3 ring-offset-transparent '
                                                    : 'bg-black/20 hover:bg-black/40'
                                                    }`}
                                            >
                                                <div
                                                    className="w-full h-32 mb-2 bg-black/20 rounded-lg flex items-center justify-center"
                                                >
                                                    <div className="text-zinc-500 text-xs">Icon</div>
                                                </div>
                                                <span className="text-zinc-100 text-sm">{template.name}</span>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div> */}


                            {/* <div className="mt-4">

                                <div className="mt-4">
                                    <div className="text-lg text-zinc-100 mb-4">Theme</div>
                                    <div className="grid grid-cols-5 gap-4">
                                        {[
                                            { id: 'blank', name: 'corporate', icon: '/media/templates/blank.svg' },
                                            { id: 'corporate1', name: 'corporate 1', icon: '/media/templates/retail.svg' },
                                            { id: 'conferenceA', name: 'conference A', icon: '/media/templates/field.svg' },
                                        ].map((template) => (
                                            <button
                                                key={template.id}
                                                onClick={() => setSelectedTemplate(template.id)}
                                                className={`flex flex-col items-center p-4 rounded-lg transition-all ${selectedTemplate === template.id
                                                    ? 'bg-black/20 ring-2 ring-sky-500 ring-offset-3 ring-offset-transparent '
                                                    : 'bg-black/20 hover:bg-black/40'
                                                    }`}
                                            >
                                                <div
                                                    className="w-full h-32 mb-2 bg-black/20 rounded-lg flex items-center justify-center"
                                                >
                                                    <div className="text-zinc-500 text-xs">Icon</div>
                                                        </div>
                                                <span className="text-zinc-100 text-sm">{template.name}</span>
                                            </button>
                                        ))}
                                                    </div>
                                                </div>
                            </div> */}



                            {/* Create Button */}
                            <div className="mt-6 flex justify-end pt-12">
                                <button
                                    onClick={createStory}
                                    className=" rounded-md bg-sky-500 px-4 py-3 text-sm  text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
                                >
                                    Create Story
                                </button>
                            </div>
                        </div>
                    </motion.div>
                )}

                {storypathType === 'tutorial' && (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 0.25,
                            ease: "easeOut"
                        }}
                        exit={{ y: -10, opacity: 0 }}
                    >
                        <div onSubmit={generateStory} className="space-y-4  text-zinc-100 mt-8  p-8 bg-black/20 rounded-lg">
                            <div>Hello</div>
                        </div>
                    </motion.div>
                )}

                {/* Website Analysis Results */}
                {/* <div className="mt-2">
    <Accordion open={open === 5} icon={researchProductData ? <Icon id={5} open={open} /> : null} className='border-none bg-black/20 rounded-lg'>
        <AccordionHeader onClick={() => handleOpen(5)} className='border-none hover:bg-black/10 p-4 rounded-lg'>
            <div className="flex justify-between items-center">
                <h2 className={`ml-0 text-base flex items-center ${getHeadingClass(isResearchProductLoading, researchProductData)}`}>
                    <div className="flex items-center">
                        {isResearchProductLoading ? (
                            <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                        ) : (
                            <ThreeDotsSVG />
                        )}
                        <div className='ml-4'>Website Analysis</div>
                            </div>
                </h2>
            </div>
        </AccordionHeader>
        <AccordionBody>
            <div className="px-10 py-6 text-zinc-300">
                {isResearchProductLoading ? (
                    <Skeleton height={128} />
                ) : (researchProductData?.overallSummary || researchCompetitorData?.overallSummary) ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <div className="mb-8">
                            <div className="text-xl font-semibold text-zinc-100 mb-4 border-l-4 border-sky-500 pl-4">
                                Company Overview
                            </div>
                            <div className="bg-black/20 p-6 rounded-lg">
                                <p className="text-zinc-300 mb-6 leading-relaxed">
                                    {researchProductData.overallSummary.companyOverview}
                                </p>
                                
                                {researchProductData.overallSummary.marketPosition && (
                                    <div className="mb-6">
                                        <h4 className="text-sky-400 font-medium mb-3">Market Position</h4>
                                        <p className="text-zinc-300">{researchProductData.overallSummary.marketPosition}</p>
                                    </div>
                                )}

                                {researchProductData.overallSummary.productOfferings?.length > 0 && (
                                    <div>
                                        <h4 className="text-sky-400 font-medium mb-3">Product Offerings</h4>
                                        <div className="flex flex-wrap gap-2">
                                            {researchProductData.overallSummary.productOfferings.map((offering, index) => (
                                                <span key={index} className="bg-black/20 text-sky-300 px-4 py-2 rounded-full text-sm">
                                                    {offering}
                                                </span>
                                            ))}
                                </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {researchProductData.products?.length > 0 && (
                            <div className="space-y-6">
                                {researchProductData.products.map((product, index) => (
                                    <div key={index} className="bg-black/20 rounded-lg p-6">
                                        <div className="text-lg font-semibold text-zinc-100 mb-4 border-l-4 border-sky-500 pl-4">
                                            {product.name}
                                        </div>
                                        <p className="text-zinc-300 mb-6 leading-relaxed">{product.description}</p>

                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                            <div className="space-y-6">
                                                {(product.productDetails?.capabilities?.length > 0 || 
                                                  product.productDetails?.keyFeatures?.length > 0) && (
                                                    <div className="bg-black/10 rounded-lg p-4">
                                                        {product.productDetails.capabilities?.length > 0 && (
                                                            <>
                                                                <h4 className="text-sky-400 font-medium mb-3">Capabilities</h4>
                                                                <ul className="list-disc pl-4 space-y-2">
                                                                    {product.productDetails.capabilities.map((capability, i) => (
                                                                        <li key={i} className="text-zinc-400">{capability}</li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        )}
                                                        
                                                        {product.productDetails.keyFeatures?.length > 0 && (
                                                            <>
                                                                <h4 className="text-sky-400 font-medium mt-4 mb-3">Key Features</h4>
                                                                <ul className="list-disc pl-4 space-y-2">
                                                                    {product.productDetails.keyFeatures.map((feature, i) => (
                                                                        <li key={i} className="text-zinc-400">{feature}</li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        )}
                                                        </div>
                                                )}
                                                {product.productDetails?.technicalSpecs?.length > 0 && (
                                                    <div className="bg-black/10 rounded-lg p-4">
                                                        <h4 className="text-sky-400 font-medium mb-3">Technical Specifications</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {product.productDetails.technicalSpecs.map((spec, i) => (
                                                                <li key={i} className="text-zinc-400">{spec}</li>
                                                            ))}
                                                        </ul>
                                                        </div>
                                                )}
                                                    </div>

                                            <div className="space-y-6">
                                                {(product.customerBenefits?.benefits?.length > 0 ||
                                                  product.customerBenefits?.valuePropositions?.length > 0) && (
                                                    <div className="bg-black/10 rounded-lg p-4">
                                                        {product.customerBenefits.benefits?.length > 0 && (
                                                            <>
                                                                <h4 className="text-sky-400 font-medium mb-3">Benefits</h4>
                                                                <ul className="list-disc pl-4 space-y-2">
                                                                    {product.customerBenefits.benefits.map((benefit, i) => (
                                                                        <li key={i} className="text-zinc-400">{benefit}</li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        )}

                                                        {product.customerBenefits.valuePropositions?.length > 0 && (
                                                            <>
                                                                <h4 className="text-sky-400 font-medium mt-4 mb-3">Value Propositions</h4>
                                                                <ul className="list-disc pl-4 space-y-2">
                                                                    {product.customerBenefits.valuePropositions.map((prop, i) => (
                                                                        <li key={i} className="text-zinc-400">{prop}</li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        )}
                                                        </div>
                                                )}

                                                {product.implementation?.process?.length > 0 && (
                                                    <div className="bg-black/10 rounded-lg p-4">
                                                        <h4 className="text-sky-400 font-medium mb-3">Implementation Process</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {product.implementation.process.map((step, i) => (
                                                                <li key={i} className="text-zinc-400">{step}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                                        </div>
                                                    </div>

                                        {product.successStories?.length > 0 && (
                                            <div className="mt-6 bg-black/10 rounded-lg p-4">
                                                <h4 className="text-sky-400 font-medium mb-4">Success Stories</h4>
                                                {product.successStories.map((story, i) => (
                                                    <div key={i} className="border-l-2 border-sky-500 pl-4">
                                                        <h5 className="text-zinc-100 font-medium mb-2">{story.title}</h5>
                                                        <p className="text-zinc-400 mb-2">Industry: {story.industry}</p>
                                                        <p className="text-zinc-400 mb-2">Challenge: {story.challenge}</p>
                                                        <p className="text-zinc-400 mb-2">Solution: {story.solution}</p>
                                                        {story.results?.length > 0 && (
                                                            <div className="mt-2">
                                                                <h6 className="text-sky-400 text-sm mb-1">Results:</h6>
                                                                <ul className="list-disc pl-4">
                                                                    {story.results.map((result, j) => (
                                                                        <li key={j} className="text-zinc-400">{result}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div>
                <h2 className="text-xl font-semibold text-white mb-4 border-l-4 border-red-400 pl-4">
                    Competitor Analysis
                </h2>
                {researchCompetitorData?.overallSummary && (
                    <div>
                        <div className="mb-8">
                            <div className="bg-black/20 p-6 rounded-lg">
                                <p className="text-zinc-300 mb-6 leading-relaxed">
                                    {researchCompetitorData.overallSummary.companyOverview}
                                </p>
                                
                                {researchCompetitorData.overallSummary.marketPosition && (
                                    <div className="mb-6">
                                        <h4 className="text-red-400 font-medium mb-3">Market Position</h4>
                                        <p className="text-zinc-300">{researchCompetitorData.overallSummary.marketPosition}</p>
                                    </div>
                                )}

                                {researchCompetitorData.overallSummary.productOfferings?.length > 0 && (
                                    <div>
                                        <h4 className="text-red-400 font-medium mb-3">Product Offerings</h4>
                                        <div className="flex flex-wrap gap-2">
                                            {researchCompetitorData.overallSummary.productOfferings.map((offering, index) => (
                                                <span key={index} className="bg-black/20 text-red-300 px-4 py-2 rounded-full text-sm">
                                                    {offering}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {researchCompetitorData.products?.length > 0 && (
                            <div className="space-y-6">
                                {researchCompetitorData.products.map((product, index) => (
                                    <div key={index} className="bg-black/20 rounded-lg p-6">
                                        <div className="text-lg font-semibold text-zinc-100 mb-4 border-l-4 border-red-400 pl-4">
                                            {product.name}
                                        </div>
                                        <p className="text-zinc-300 mb-6 leading-relaxed">{product.description}</p>

                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                            <div className="space-y-6">
                                                {(product.productDetails?.capabilities?.length > 0 || 
                                                  product.productDetails?.keyFeatures?.length > 0) && (
                                                    <div className="bg-black/10 rounded-lg p-4">
                                                        {product.productDetails.capabilities?.length > 0 && (
                                                            <>
                                                                <h4 className="text-red-400 font-medium mb-3">Capabilities</h4>
                                                                <ul className="list-disc pl-4 space-y-2">
                                                                    {product.productDetails.capabilities.map((capability, i) => (
                                                                        <li key={i} className="text-zinc-400">{capability}</li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        )}
                                                        
                                                        {product.productDetails.keyFeatures?.length > 0 && (
                                                            <>
                                                                <h4 className="text-red-400 font-medium mt-4 mb-3">Key Features</h4>
                                                                <ul className="list-disc pl-4 space-y-2">
                                                                    {product.productDetails.keyFeatures.map((feature, i) => (
                                                                        <li key={i} className="text-zinc-400">{feature}</li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        )}
                                                    </div>
                                                )}

                                                {product.productDetails?.technicalSpecs?.length > 0 && (
                                                    <div className="bg-black/10 rounded-lg p-4">
                                                        <h4 className="text-red-400 font-medium mb-3">Technical Specifications</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {product.productDetails.technicalSpecs.map((spec, i) => (
                                                                <li key={i} className="text-zinc-400">{spec}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="space-y-6">
                                                {(product.customerBenefits?.benefits?.length > 0 ||
                                                  product.customerBenefits?.valuePropositions?.length > 0) && (
                                                    <div className="bg-black/10 rounded-lg p-4">
                                                        {product.customerBenefits.benefits?.length > 0 && (
                                                            <>
                                                                <h4 className="text-red-400 font-medium mb-3">Benefits</h4>
                                                                <ul className="list-disc pl-4 space-y-2">
                                                                    {product.customerBenefits.benefits.map((benefit, i) => (
                                                                        <li key={i} className="text-zinc-400">{benefit}</li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        )}

                                                        {product.customerBenefits.valuePropositions?.length > 0 && (
                                                            <>
                                                                <h4 className="text-red-400 font-medium mt-4 mb-3">Value Propositions</h4>
                                                                <ul className="list-disc pl-4 space-y-2">
                                                                    {product.customerBenefits.valuePropositions.map((prop, i) => (
                                                                        <li key={i} className="text-zinc-400">{prop}</li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        )}
                                                    </div>
                                                )}

                                                {product.implementation?.process?.length > 0 && (
                                                    <div className="bg-black/10 rounded-lg p-4">
                                                        <h4 className="text-red-400 font-medium mb-3">Implementation Process</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {product.implementation.process.map((step, i) => (
                                                                <li key={i} className="text-zinc-400">{step}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {product.successStories?.length > 0 && (
                                            <div className="mt-6 bg-black/10 rounded-lg p-4">
                                                <h4 className="text-red-400 font-medium mb-4">Success Stories</h4>
                                                {product.successStories.map((story, i) => (
                                                    <div key={i} className="border-l-2 border-red-400 pl-4">
                                                        <h5 className="text-zinc-100 font-medium mb-2">{story.title}</h5>
                                                        <p className="text-zinc-400 mb-2">Industry: {story.industry}</p>
                                                        <p className="text-zinc-400 mb-2">Challenge: {story.challenge}</p>
                                                        <p className="text-zinc-400 mb-2">Solution: {story.solution}</p>
                                                        {story.results?.length > 0 && (
                                                            <div className="mt-2">
                                                                <h6 className="text-red-400 text-sm mb-1">Results:</h6>
                                                                <ul className="list-disc pl-4">
                                                                    {story.results.map((result, j) => (
                                                                        <li key={j} className="text-zinc-400">{result}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                            </div>
                        )}
                    </div>



                    </div>
                ) : (
                    <div className="text-zinc-500">No analysis data available</div>
                )}
            </div>
        </AccordionBody>
    </Accordion>
</div> */}











                {(apiCalls.research == 3333 ?
                    <div className='flex justify-between items-center w-full'>
                        <div className='w-1 
                        bg-gradient-to-b from-[#ff8a05] via-[#ff5478] to-[#ff00c6]
                animate-gradient bg-[length:100%_100%] 
                        h-12 mx-auto'></div>
                    </div>
                    :
                    <div className='flex justify-between items-center w-full'>
                        <div className='w-1 bg-black/0 h-12 mx-auto'></div>
                    </div>
                )}

                {/* <BocceWorkflow />
                <BocceWorkflowNews /> */}








                {/* <AccordionExample /> */}






                {transcriptData && transcriptData.length > 0 && (
                    <div className="mb-6 bg-zinc-800/50 rounded-lg p-4">
                        <div className="text-zinc-100 text-lg mb-4">Video Analysis</div>

                        {/* Summary Section */}
                        {transcriptData.summary && (
                            <div className="mb-8 bg-black/20 rounded-lg p-6">
                                <h4 className="text-zinc-100 font-medium mb-4 text-lg">Overall Summary</h4>
                                <div className="space-y-4">
                                    <div>
                                        <h5 className="text-sky-400 text-sm mb-2">Common Themes</h5>
                                        <ul className="list-disc pl-4 text-zinc-300">
                                            {transcriptData.summary.commonThemes.map((theme, i) => (
                                                <li key={i}>{theme}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div>
                                        <h5 className="text-sky-400 text-sm mb-2">Key Differences</h5>
                                        <ul className="list-disc pl-4 text-zinc-300">
                                            {transcriptData.summary.keyDifferences.map((diff, i) => (
                                                <li key={i}>{diff}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div>
                                        <h5 className="text-sky-400 text-sm mb-2">Key Insights</h5>
                                        <ul className="list-disc pl-4 text-zinc-300">
                                            {transcriptData.summary.significantInsights.map((insight, i) => (
                                                <li key={i}>{insight}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Individual Video Analyses */}
                        <div className="space-y-6">
                            {transcriptData.map((item, index) => (
                                <div key={index} className="bg-zinc-900 rounded-lg p-6">
                                    <div className="flex justify-between items-start mb-4">
                                        <h4 className="text-zinc-100 font-medium">Video {index + 1}</h4>
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-sky-400 hover:text-sky-300 text-sm"
                                        >
                                            View Video →
                                        </a>
                                    </div>

                                    {item.error ? (
                                        <p className="text-red-400">{item.error}</p>
                                    ) : (
                                        <div className="space-y-6">





                                            {/* Analysis Section */}
                                            {item.analysis && (
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                    <div className="bg-black/20 rounded-lg p-4">
                                                        <h5 className="text-sky-400 text-sm mb-3">Key Topics</h5>
                                                        <ul className="list-disc pl-4 text-zinc-300 space-y-1">
                                                            {item.analysis.keyTopics.map((topic, i) => (
                                                                <li key={i}>{topic}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className="bg-black/20 rounded-lg p-4">
                                                        <h5 className="text-sky-400 text-sm mb-3">Main Insights</h5>
                                                        <ul className="list-disc pl-4 text-zinc-300 space-y-1">
                                                            {item.analysis.mainInsights.map((insight, i) => (
                                                                <li key={i}>{insight}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    {item.analysis.examples.length > 0 && (
                                                        <div className="bg-black/20 rounded-lg p-4">
                                                            <h5 className="text-sky-400 text-sm mb-3">Examples & Case Studies</h5>
                                                            <ul className="list-disc pl-4 text-zinc-300 space-y-1">
                                                                {item.analysis.examples.map((example, i) => (
                                                                    <li key={i}>{example}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                    {item.analysis.quotes.length > 0 && (
                                                        <div className="bg-black/20 rounded-lg p-4">
                                                            <h5 className="text-sky-400 text-sm mb-3">Notable Quotes</h5>
                                                            <ul className="list-disc pl-4 text-zinc-300 space-y-1">
                                                                {item.analysis.quotes.map((quote, i) => (
                                                                    <li key={i}>{quote}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </SkeletonTheme>

            {showConfetti && (
                <ReactConfetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    // confettiSource={{
                    //     x: window.innerWidth / 2 - 250, // Center horizontally
                    //     y: window.innerHeight,        // Start from bottom
                    //     w: 500,                       // Width of source
                    //     h: 0                          // Height of source
                    // }}
                    // initialVelocityY={{ min: -50, max: -10 }} // Negative values make confetti go up
                    gravity={0.1}                           // Slightly stronger gravity
                    numberOfPieces={300}                     // More pieces for effect
                />
            )}

        </div>

    );
}