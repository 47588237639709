import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";


const logCustomEvent = (eventName, eventParams = {}) => {
  const analytics = getAnalytics();
  const auth = getAuth();
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  // Add userId to eventParams if available
  if (userId) {
    eventParams.userId = userId;
  }

  // Add timestamp to event params
  const timestamp = new Date();
  eventParams.timestamp = timestamp;

  // Create a document in the core-activity collection
  try {
    const db = getFirestore();
    const coreActivityRef = collection(db, 'core-activity');


    // Prepare the document data
    const activityData = {
      event: eventName,
      userId: userId || 'anonymous',
      timestamp: timestamp,
      //...eventParams
    };
    
    // Add the document to the core-activity collection
    addDoc(coreActivityRef, activityData)
      .then(() => {
        //console.log(`Activity logged to core-activity: ${eventName}`);
      })
      .catch((error) => {
        //console.error('Error logging activity to Firestore:', error);
      });
  } catch (error) {
    //console.error('Error with Firestore logging:', error);
  }


  
  // Log the custom event
  logEvent(analytics, eventName, eventParams);





  // Log the event to the console
  // console.log(`Custom event logged: ${eventName}`, eventParams);

};

// Example usage
// const track3DModelGeneration = (modelId) => {
//   logCustomEvent("3D_Model_Generation", { modelId });
// };


// const trackEvent = (modelId) => {
//   logCustomEvent("3D_Model_Generation", { modelId });
// };


export { logCustomEvent };