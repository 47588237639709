import React, { useState, useEffect } from 'react';
import { UserAuth } from '../context/AuthContext';
import { motion } from 'framer-motion';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $createTextNode, $createParagraphNode } from 'lexical';
import { debounce } from 'lodash';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeNode } from '@lexical/code';
import { LinkNode } from '@lexical/link';
import { QuoteNode, HeadingNode } from '@lexical/rich-text';
import ToolbarPlugin from './editor/ToolbarPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { $generateHtmlFromNodes } from '@lexical/html';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { TableNode, TableCellNode, TableRowNode } from '@lexical/table';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';

const Editor = ({ onChange, placeholder, initialValue, onInit }) => {
  const [editor] = useLexicalComposerContext();
  
  useEffect(() => {
    if (onInit) {
      onInit(editor);
    }
  }, [editor, onInit]);
  
  // Initialize editor with initial value
  useEffect(() => {
    if (initialValue) {
      editor.update(() => {
        const root = $getRoot();
        if (root.getTextContent() !== initialValue) {
          root.clear();
          const paragraph = $createParagraphNode();
          paragraph.append($createTextNode(initialValue));
          root.append(paragraph);
        }
      });
    }
  }, [editor, initialValue]);

  // Handle content changes
  useEffect(() => {
    return editor.registerUpdateListener(({editorState}) => {
      editorState.read(() => {
        const root = $getRoot();
        const content = root.getTextContent();
        if (onChange) {
          onChange(content);
        }
      });
    });
  }, [editor, onChange]);

  return (
    <div className="editor-inner">
      <ToolbarPlugin />
      <RichTextPlugin
        contentEditable={
          <ContentEditable 
            className="editor-input w-full bg-black/20 text-white rounded-lg px-4 py-2 min-h-[8rem] focus:outline-none focus:ring-2 focus:ring-sky-500"
          />
        }
        placeholder={
          <div className="editor-placeholder absolute top-[2.5rem] left-4 text-gray-500 pointer-events-none">
            {placeholder}
          </div>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      <ListPlugin />
      <TablePlugin />
      <HistoryPlugin />
      <OnChangePlugin onChange={onChange} />
    </div>
  );
};

const editorConfig = {
  namespace: 'MyEditor',
  theme: {
    table: 'border-collapse w-full my-4',
    tableRow: '',
    tableCell: 'border border-zinc-800 border-dashed p-2 min-w-[100px]',
    tableCellHeader: ' border border-dashed border-zinc-800 p-2 min-w-[100px]',
    text: {
      bold: 'font-bold',
      italic: 'italic',
      underline: 'underline',
    },
    heading: {
      h1: 'text-3xl font-bold',
      h2: 'text-2xl font-bold',
      h3: 'text-xl font-bold',
      h4: 'text-lg font-bold',
      h5: 'text-base font-bold',
      h6: 'text-sm font-bold',
    },
    paragraph: 'editor-paragraph',
    quote: 'border-l-4 border-gray-500 pl-4 my-2',
    list: {
      ol: 'list-decimal ml-4',
      ul: 'list-disc ml-4',
    },
  },
  nodes: [
    TableNode,
    TableCellNode,
    TableRowNode,
    ListItemNode,
    ListNode,
    CodeNode,
    LinkNode,
    QuoteNode,
    HeadingNode,
  ],
  onError(error) {
    console.error('Lexical error:', error);
  },
};

const RichTextEditor = ({ value, onChange, placeholder, onInit }) => {
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container h-full relative">
        <Editor 
          onChange={onChange} 
          placeholder={placeholder}
          initialValue={value}
          onInit={onInit}
          
        />
      </div>
    </LexicalComposer>
  );
};

const Pulses = () => {
  const { user } = UserAuth();
  //const [editor] = useLexicalComposerContext();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [newMessage, setNewMessage] = useState({
    title: '',
    content: '',
    type: 'update', // default type
    permissions: 'all', // default permissions
    author: '',
    avatarImage: '',
  });
  const [editorInstance, setEditorInstance] = useState(null);

  const baseColor = '#1C1C22';
  const highlightColor = '#2d2d2d';

  // Initialize functions with emulator
  const functions = getFunctions();
  if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }

  const getPulseMessagesCall = httpsCallable(functions, 'getPulseMessages');
  const createPulseMessageCall = httpsCallable(functions, 'createPulseMessage');
  const updatePulseMessageCall = httpsCallable(functions, 'updatePulseMessage');

  // Update fetch messages
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        if (!user) {
          console.log('User not authenticated');
          return;
        }

        const result = await getPulseMessagesCall();
        setMessages(result.data.messages || []); // Add fallback empty array
      } catch (error) {
        console.error('Error fetching messages:', error);
        setMessages([]); // Set empty array on error
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchMessages();
    } else {
      setLoading(false);
    }
  }, [user]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const messageData = isEditing ? {
        messageId: editingMessage.id,
        content: editorInstance.getEditorState().read(() => $generateHtmlFromNodes(editorInstance)),
        ...editingMessage
      } : {
        ...newMessage,
        content: editorInstance.getEditorState().read(() => $generateHtmlFromNodes(editorInstance)),
        author: user.displayName || 'Anonymous',
        avatarImage: user.photoURL || '',
        timestamp: new Date().toISOString(),
        createdBy: user.uid
      };

      console.log('Sending message data:', messageData);

      if (isEditing) {
        await updatePulseMessageCall(messageData);
      } else {
        await createPulseMessageCall(messageData);
      }

      // Refresh messages
      const result = await getPulseMessagesCall();
      setMessages(result.data.messages);
      
      // Reset form
      setNewMessage({
        title: '',
        content: '',
        type: 'update',
        permissions: 'all',
        author: '',
        avatarImage: ''
      });
      setIsEditing(false);
      setEditingMessage(null);
    } catch (error) {
      console.error('Error saving message:', error);
    }
  };

  const handleContentChange = (content) => {
    if (isEditing) {
      setEditingMessage({...editingMessage, content});
    } else {
      setNewMessage({...newMessage, content});
    }
  };

  const handleEdit = (message) => {
    setIsEditing(true);
    setEditingMessage(message);
  };

 

  return (
    <div className="h-[calc(100vh-220px)] admin-scroll">
      <div className="relative isolate">
        <div className="grid grid-cols-4 gap-8">
          {/* Messages Column */}
          <div className="col-span-2">
            <div className="px-0">
              <div className="space-y-8">
                <div className="h-[calc(100vh-220px)] p-4 rounded-lg bg-black/10">
                  <div className="overflow-y-auto h-full pr-4">
                    {loading ? (
                      <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
                        <Skeleton count={3} height={200} className="mb-4" />
                      </SkeletonTheme>
                    ) : (
                      messages.map((message, index) => (
                        <motion.div
                          key={message.id}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: index * 0.1 }}
                          className="mb-4 w-full bg-black/20 rounded-lg"
                        >
                          <div className='flex'>
                            <div className='flex-none w-1/5 p-4'>
                              <div 
                                className='w-full h-full bg-black/20 rounded-lg p-6'
                                style={{
                                  backgroundImage: message.image ? `url(${message.image})` : 'url(/util/app/pulse-placeholder-1.png)',
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center'
                                }}
                              />
                            </div>

                            <div className="py-5 pl-0 pr-4 flex-grow gap-4">
                              <div className="flex-shrink-0 flex gap-4">
                                <div className="w-12 h-12 rounded-lg bg-[#56F3D6]/5 flex items-center justify-center">
                                  <img src={message.avatarImage || message.author?.substring(0, 2)} alt="Avatar" className="w-12 h-12 rounded-lg" />
                                </div>
                                <div className="flex flex-col">
                                  <div className="text-xs font-base text-zinc-500">{message.author}</div>
                                  <h3 className="text-lg font-medium text-zinc-50 mt-1">{message.title}</h3>
                                </div>
                                <div className="flex flex-1 justify-end">
                                  <div className="text-xs text-zinc-500 bg-white/5 rounded-lg p-2 h-8">
                                    {new Date(message.timestamp).toLocaleDateString()}
                                  </div>
                                  {message.createdBy === user?.uid && (
                                    <button
                                      onClick={() => handleEdit(message)}
                                      className="ml-2 text-sky-500 hover:text-sky-400 bg-white/5 rounded-lg p-2 h-8"
                                    >
                                      Edit
                                    </button>
                                  )}
                                </div>
                              </div>

                              <div className="flex-1">
                              <div 
  className="space-y-1 text-zinc-300 mt-6 pb-4 leading-8"
  dangerouslySetInnerHTML={{ __html: message.content }}
/>
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Form Column */}
          <div className="col-span-2">
            <div className="space-y-4">
              <div className="block antialiased tracking-normal text-base font-semibold leading-relaxed text-zinc-200">
                <img src="/util/app/b3-admin-idea.svg" className="h-7 inline-block mr-3 opacity-50" />
                {isEditing ? 'Edit Message' : 'Create New Message'}
              </div>

              <div className="flow-root">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.25, ease: "easeOut" }}
                >
                  <form onSubmit={handleSubmit} className="space-y-4 bg-black/20 rounded-lg p-4">
                    <div>
                      <label className="block text-sm font-medium text-zinc-300 mb-2">Title</label>
                      <input
                        type="text"
                        value={isEditing ? editingMessage.title : newMessage.title}
                        onChange={(e) => isEditing ? 
                          setEditingMessage({...editingMessage, title: e.target.value}) : 
                          setNewMessage({...newMessage, title: e.target.value})
                        }
                        className="w-full bg-black/20 text-white rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                        required
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-zinc-300 mb-2">Content</label>
                      <RichTextEditor 
                        value={isEditing ? editingMessage.content : newMessage.content}
                        onChange={handleContentChange}
                        placeholder=""
                        onInit={setEditorInstance}
                      />
                    </div>

                    <div className="flex gap-4">
                      <button
                        type="submit"
                        className="flex-1 bg-sky-600 hover:bg-sky-700 text-white px-4 py-2 rounded-lg transition-colors"
                      >
                        {isEditing ? 'Update Message' : 'Post Message'}
                      </button>
                      {isEditing && (
                        <button
                          type="button"
                          onClick={() => {
                            setIsEditing(false);
                            setEditingMessage(null);
                          }}
                          className="flex-1 bg-zinc-600 hover:bg-zinc-700 text-white px-4 py-2 rounded-lg transition-colors"
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </form>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pulses;