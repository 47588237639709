import React, { useState } from 'react';
import { RotateSpinner } from 'react-spinners-kit';

const PathProductBusinessTransformation = ({ businessAnalysisData, storySpeakerSummaryResult, producedResult, salesAnalysisData, businessTransformationResult, generateStoryBite, handlePrint }) => {
    // console.log('PRODUCED RESULT ///////')
    // console.log(producedResult);
    // console.log('PRODUCED RESULT ///////')

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [storyBite, setStoryBite] = useState(null);
    const [storyBiteCategory, setStoryBiteCategory] = useState(null);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);




    const handleGenerateStoryBite = async (item) => {
        setStoryBiteCategory(item.category);
        setIsLoading(true);
        setIsModalOpen(true);

        try {
            const result = await generateStoryBite(item);
            setStoryBite(result);

        } catch (error) {
            console.error("Error generating StoryBite:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const copyToClipboard = () => {
        if (storyBite?.data?.shortStory) {
            navigator.clipboard.writeText(storyBite.data.shortStory);
        }
    };


    if (!businessAnalysisData) return null;

    return (
        <div className="mt-10 space-y-6 overflow-y-auto h-[calc(100vh-8rem)]  ">
            <div className="w-full px-6 ">
                {/* <div className="mb-6">
        <h2 className="text-2xl font-medium text-zinc-100 mb-2">Business Transformation Analysis</h2>
        <p className="text-zinc-300">Current state to future state transformation journey</p>
    </div> */}
                <div className='  rounded-lg  '>
                    {/* Header Row */}
                    <div className="flex items-stretch gap-1 mb-4">
                        {/* Category Header */}
                        <div className="w-56 flex-shrink-0 p-4">
                            {/* <h3 className="text-lg font-semibold text-gray-100">Category</h3>
                <p className="text-sm text-gray-400">Transformation area</p> */}
                        </div>
                        <div className="flex-1 p-4">
                            <h3 className=" font-medium text-2xl text-amber-300">Current State</h3>
                            <p className="text-sm text-gray-400">Existing challenges and pain points</p>
                        </div>
                        <div className="flex items-center justify-center w-8">
                            {/* <svg
                    className="w-6 h-6 text-sky-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                    />
                </svg> */}
                        </div>
                        <div className="flex-1 p-4 rounded-t-lg">
                            <h3 className=" font-medium text-2xl text-sky-400">Future State</h3>
                            <p className="text-sm text-gray-400">Technology-enabled solution</p>
                        </div>
                        <div className="flex items-center justify-center w-8">
                            {/* <svg
                    className="w-6 h-6 text-sky-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                    />
                </svg> */}
                        </div>
                        <div className="flex-1 p-4 rounded-t-lg">
                            <h3 className=" font-medium text-2xl text-green-300">Business Impact</h3>
                            <p className="text-base text-gray-400">Expected outcomes and benefits</p>
                        </div>
                        <div className="flex-none w-24 p-4 ">

                        </div>

                    </div>

                    {/* Content Rows */}
                    {businessTransformationResult?.transformationItems?.map((item, index) => (
                        <div key={index}
                            className={`flex items-stretch gap-1 min-h-[120px] ${hoveredRowIndex === index ? 'bg-black/10 rounded-lg' : ''}`}>
                            {/* Category Column */}
                            <div className="w-56 flex-shrink-0 p-4 flex items-start">
                                <div className="text-sky-400 font-base text-lg w-full">{item.category}</div>
                            </div>
                            <div className="flex-1 p-4 flex items-start">
                                <div className="text-gray-300 w-full">{item.from}</div>
                            </div>
                            <div className="flex   mt-6   w-8  justify-center">
                                <svg
                                    className="w-6 h-6 text-sky-500"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            </div>
                            <div className="flex-1 p-4 flex items-start">
                                <div className="text-gray-300 w-full">{item.to}</div>
                            </div>
                            <div className="flex  w-8 mt-6  justify-center">
                                <svg
                                    className="w-6 h-6 text-green-300"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            </div>
                            <div className="flex-1 p-4 flex items-start">
                                <div className="text-gray-300 w-full">{item.impact}</div>
                            </div>


                            <div className="flex-none w-24  p-4  ">
                                <button
                                    onClick={() => handleGenerateStoryBite(item)}
                                    className="px-2 py-2 bg-zinc-800 hover:bg-sky-500 text-white rounded-full transition-colors"
                                    onMouseEnter={() => setHoveredRowIndex(index)}
                                    onMouseLeave={() => setHoveredRowIndex(null)}
                                >
                                    <img src="/util/app/b3-admin-create-4.svg" alt="Generate StoryBite" className="h-7" />
                                </button>
                            </div>
                        </div>
                    ))}

                    {/* StoryBite Modal */}
                    {isModalOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center z-50">
                            <div className="bg-zinc-800 rounded-lg p-8 max-w-2xl w-full max-h-[80vh] overflow-y-auto">
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-xl font-base text-white">{storyBiteCategory} Story Bite</h3>
                                    <button
                                        onClick={() => setIsModalOpen(false)}
                                        className="text-gray-400 hover:text-white"
                                    >
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>

                                {isLoading ? (
                                    <div className="flex flex-col items-center justify-center py-10">
                                        {/* <div className="w-12 h-12 border-4 border-sky-500 border-t-transparent rounded-full animate-spin"></div> */}
                                        <RotateSpinner color="#38BDF8" className="mx-auto mb-12" size={48} />
                                        <p className="mt-4 text-gray-300">Generating StoryBite...</p>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="bg-black/25 rounded-lg p-4 mb-4 whitespace-pre-line leading-8 text-gray-200">
                                            {/* {storyBite?.data?.shortStory} */}
                                            {storyBite?.data?.currentState}
                                        </div>
                                        <div className="bg-black/25 rounded-lg p-4 mb-4 whitespace-pre-line leading-8 text-gray-200">
                                            {storyBite?.data?.futureState}
                                        </div>
                                        <div className="bg-black/25 rounded-lg p-4 mb-4 whitespace-pre-line leading-8 text-gray-200">
                                            {storyBite?.data?.benefits}
                                        </div>
                                        <div className="flex justify-end">
                                            <button
                                                onClick={copyToClipboard}
                                                className="px-4 py-2 bg-sky-600 hover:bg-sky-700 text-white rounded-md transition-colors flex items-center"
                                            >
                                                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                                                </svg>
                                                Copy to Clipboard
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    )}

                    {(!businessTransformationResult?.transformationItems || businessTransformationResult.transformationItems.length === 0) && (
                        <div className="text-center py-8 text-gray-400">
                            No transformation data available
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PathProductBusinessTransformation;