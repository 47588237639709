import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { motion } from 'framer-motion';
import {
    Tabs,
    TabsHeader,
    Tab,
    ThemeProvider,
} from "@material-tailwind/react";
import { auth } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { connectFunctionsEmulator } from 'firebase/functions';

export default function CompAIStoryTone({ onToneChange, initialToneSettings }) {


    const CUSTOM_THEME = {
        tabsHeader: {
            defaultProps: {
                className: "",
            },
            styles: {
                base: {
                    display: "flex",
                    position: "relative",
                    bg: "bg-transparent",
                    borderRadius: "rounded-none",
                    p: "p-1",
                },
                horizontal: {
                    flexDirection: "flex-row",
                },
                vertical: {
                    flexDirection: "flex-col",
                },
            },
        },
        tab: {
            styles: {
                base: {
                    position: "relative",
                    bg: "bg-transparent",
                    p: "p-2",
                    color: "text-zinc-400",
                    cursor: "cursor-pointer",
                    userSelect: "select-none",
                    rounded: "rounded-lg",
                    transition: "transition-colors duration-200",
                },
                selected: {
                    color: "text-zinc-50",
                    bg: "bg-transparent",
                },
            },
        },
        tabsHeader: {
            indicatorProps: {
                className: "bg-transparent border-b-4 border-sky-500 shadow-none rounded-none",
            },
        },
    };


    const [isOpen, setIsOpen] = useState(false);
    const [toneSettings, setToneSettings] = useState(
        initialToneSettings || {
            research: 'Casual',
            story: 'Casual',
            storyLength: '4',
            storyIntensity: 'a regular day, nothing overly dramatic, but the product helps make it easier in important ways',
            notificationTone: 'none', 
            
        }
    );




    const [activeTab, setActiveTab] = useState('style');

    const toneAttributes = [
        {
            name: 'research',
            label: 'Research Tone',
            options: [
                { label: 'Casual', value: 'Casual' },
                { label: 'Business Professional', value: 'Business Professional' },
                { label: 'Sports Announcer', value: 'Sports Announcer' },
                { label: 'Comedian', value: 'Comedian' },
                { label: 'Pirate', value: 'Pirate' },
            ],
            description: 'Style for your research summaries'
        },
        {
            name: 'story',
            label: 'Story Tone',
            options: [
                { label: 'Casual', value: 'Casual' },
                { label: 'Business Professional', value: 'Business Professional' },
                { label: 'Technical Architect', value: 'Technical Architect' },
                { label: 'Tech Visionary', value: 'Tech Visionary' },
            ],
            description: 'What tone would you like your story to have?'
        },
        {
            name: 'storyLength',
            label: 'Story Length for Day-to-Day',
            options: [
                { label: 'Short - 3 Scenes', value: '3' },
                { label: 'Default - 4 Scenes', value: '4' },
                { label: 'Medium - 5 Scenes', value: '5' },
                { label: 'Long - 6 Scenes', value: '6' },
                { label: 'Super Long - 7 Scenes', value: '7' },
            ],
            description: 'How many scenes would you like your story to have?  The opening and closing scenes are always included.'
        },

        {
            name: 'storyIntensity',
            label: 'Story Intensity',
            options: [
                { label: 'Mild', value: 'a regular day, nothing overly dramatic, but the product helps make it easier in important ways', details: 'a regular day, nothing overly dramatic, but the product helps make it easier in important ways' },
                { label: 'Medium', value: 'a big challenge comes up on a key project, but by using the product, it solves the problem', details: 'a big challenge comes up on a key project, but by using the product, it solves the problem' },
                { label: 'Hot', value: 'a big challenge comes at the company level, and the product is the only solution', details: 'a big challenge comes at the company level, and the product is the only solution' },
                { label: 'Super Spicy', value: 'if they don\'t use the product, the company will fail', details: 'if they don\'t use the product, the company will fail' },
            ],
            description: 'How aggressive do you want your story to be?'
        },
        // {
        //     name: 'researchDepth',
        //     label: 'Research Depth',
        //     options: [
        //         { label: 'Deep - Higher Quality, but longer time', value: 'deep' },
        //         { label: 'Standard - Faster, but less accurate', value: 'standard' },
        //     ],
        //     description: 'How much research do you want to do?  Deep research is more accurate, but slower.  Regular research is faster, but less accurate.'
        // },
        {
            name: 'notificationTone',
            label: 'Notification Sound',
            options: [
                { label: 'Acoustic Guitar', value: 'acousticguitar-c-chord-103782.mp3' },
                { label: 'Applause Cheer', value: 'applause-cheer-236786.mp3' },
                { label: 'C Chord', value: 'c-chord-83638.mp3' },
                { label: 'Crowd Clapping', value: 'crowd-clapping-100071.mp3' },
                { label: 'Happy Logo 1', value: 'happy-logo-13397.mp3' },
                { label: 'Happy Logo 2', value: 'happy-logo-167474.mp3' },
                { label: 'Intercom', value: 'intercom-in-83962.mp3' },
                { label: 'Intergalactic Alarm', value: 'intergalactic-alarm-for-smartphone-71104.mp3' },
                { label: 'Joke Drums', value: 'joke-drums-242242.mp3' },
                { label: 'Mandolin Strum', value: 'mandolin-strum-high-g-chord-100702.mp3' },
                { label: 'Notification Sound', value: 'notification-sound-269266.mp3' },
                { label: 'Victory Rock Guitar', value: 'victory-rock-guitar-tapping-104575.mp3' },
                { label: 'Wheep Wheep', value: 'wheep-wheep-101146.mp3' },
                { label: 'Wind Up Tone', value: 'wind-up-tone-effect-240240.mp3' },
                { label: 'Zen Tone Deep', value: 'zen-tone-deep-202555.mp3' },
                { label: 'Zen Tone High', value: 'zen-tone-high-202554.mp3' },
                { label: 'Zen Tone Mid', value: 'zen-tone-mid-202556.mp3' },
                { label: 'Zen Tone Mid High', value: 'zen-tone-mid-high-202557.mp3' },
                { label: 'None', value: 'none' }
            ],
            description: 'Choose a notification sound for alerts'
        },
    ];

    // const handleOptionChange = async (setting, value) => {
    //     const newSettings = {
    //         ...toneSettings,
    //         [setting]: value
    //     };
    //     setToneSettings(newSettings);

    //     try {
    //         const idToken = await  auth.currentUser.getIdToken(true);
    //         console.log('idToken', idToken)
    //         const response = await fetch('http://127.0.0.1:5001/brevit3-57b00/us-central1/userSaveSettings', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${idToken}` 
    //             },
    //             body: JSON.stringify({ settings: newSettings })
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }
    //     } catch (error) {
    //         console.error('Error saving settings:', error);
    //     }

    //     onToneChange?.(newSettings);
    // };
    // Initialize functions with emulator
    const functions = getFunctions();
    if (process.env.NODE_ENV === 'development') {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }

    const handleOptionChange = async (setting, value) => {
        // Make sure we're properly formatting the data object when calling the function
        try {
            // Don't re-initialize functions here, use the one from above
            // const functions = getFunctions();
            const userSaveSettings = httpsCallable(functions, 'userSaveSettings');

            const newSettings = {
                ...toneSettings,
                [setting]: value
            };
            setToneSettings(newSettings);

            // Play sound if the notification tone setting is changed and not set to 'none'
            if (setting === 'notificationTone' && value !== 'none') {
                const audio = new Audio(`/media/notifications/${value}`);
                audio.play();
            }            

            // Create a properly structured settings object
            const settingsData = {
                storySettings: {
                    ...toneSettings,
                    [setting]: value
                }
            };

            const result = await userSaveSettings(settingsData);
            console.log('Settings saved successfully:', result);

            // Update local state if needed
            if (onToneChange) {
                onToneChange(setting, value);
            }
        } catch (error) {
            //console.error('Error saving settings:', error);
        }
    };

    return (
        <ThemeProvider value={CUSTOM_THEME}>
            <div className="">
                <button
                    //type="button"
                    onClick={() => setIsOpen(true)}
                    className="bg-black/10 hover:bg-black/25 text-zinc-50 px-4 text-sm py-3 rounded-lg"
                >
                    Research &amp; Story Tone
                    <img src="/util/app/b3-admin-story-settings.svg" alt="tone" className="h-6 inline-block ml-3" />
                </button>

                
            </div>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                className="relative z-50"
            >
                <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

                <div className="fixed inset-0 flex items-center justify-center p-4 ">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        className="bg-zinc-900 rounded-lg w-3/4 h-3/4 p-6 shadow-xl"
                    >
                        <Dialog.Title className="text-lg font-semibold text-zinc-100 mb-4 flex justify-between items-center">
                            <div>
                                <img src="/util/app/b3-admin-tone-1.svg" alt="Logo" className="h-10 inline-block mr-4" />   Research &amp; Story Tone
                            </div>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="px-2 py-2 rounded-full bg-zinc-700 hover:bg-zinc-600 text-zinc-100"
                            >
                               <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            </button>
                        </Dialog.Title>

                        <div className="flex gap-2 mb-12 border-b border-zinc-800">
                            {/* TODO - new tabs for research and content */}
                            {['style', 'notifications'].map((tab) => (
                                <button
                                    key={tab}
                                    onClick={() => setActiveTab(tab)}
                                    className={`px-4 py-2 capitalize text-base transition-colors duration-200
                                        ${
                                            activeTab === tab
                                                ? 'border-b-2 border-sky-500 text-white'
                                                : 'text-zinc-400 hover:text-zinc-300'
                                        }`}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>

                        <div className="space-y-6 mx-4">
                            {activeTab === 'style' && (
                                <>
                                    {toneAttributes
                                        .filter(attr => ['research', 'story', 'storyLength', 'storyIntensity'].includes(attr.name))
                                        .map((attribute) => (
                                            <div key={attribute.name} className="space-y-2 ">
                                                <div className="flex justify-between mt-12">
                                                    <label className="text-zinc-100 font-medium">
                                                        {attribute.label}
                                                    </label>
                                                </div>
                                                <div className="text-zinc-500 text-sm mb-4">
                                                    {attribute.description}
                                                </div>
                                                <div className="flex flex-wrap gap-3 mb-6 ">
                                                    {attribute.options.map(({ label, value }) => (
                                                        <button
                                                            key={value}
                                                            onClick={() => handleOptionChange(attribute.name, value)}
                                                            className={`px-4 py-2 text-sm transition-colors duration-200 rounded-md
                                                                ${
                                                                    toneSettings[attribute.name] === value 
                                                                        ? 'bg-sky-500 text-white' 
                                                                        : 'bg-zinc-800 text-zinc-400 hover:bg-zinc-700'
                                                                }`}
                                                        >
                                                            {label}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                </>
                            )}

                            {activeTab === 'research' && (
                                <>
                                    {toneAttributes
                                        .filter(attr => attr.name === 'researchDepth')
                                        .map((attribute) => (
                                            <div key={attribute.name} className="space-y-2">
                                                <div className="flex justify-between">
                                                    <label className="text-zinc-100 font-medium">
                                                        {attribute.label}
                                                    </label>
                                                </div>
                                                <div className="text-zinc-500 text-sm mb-4">
                                                    {attribute.description}
                                                </div>
                                                <div className="flex flex-wrap gap-3 mb-6">
                                                    {attribute.options.map(({ label, value }) => (
                                                        <button
                                                            key={value}
                                                            onClick={() => handleOptionChange(attribute.name, value)}
                                                            className={`px-4 py-2 text-sm transition-colors duration-200 rounded-md
                                                                ${
                                                                    toneSettings[attribute.name] === value 
                                                                        ? 'bg-sky-500 text-white' 
                                                                        : 'bg-zinc-800 text-zinc-400 hover:bg-zinc-700'
                                                                }`}
                                                        >
                                                            {label}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                </>
                            )}

                            {activeTab === 'notifications' && (
                                <>
                                    {toneAttributes
                                        .filter(attr => attr.name === 'notificationTone')
                                        .map((attribute) => (
                                            <div key={attribute.name} className="space-y-2">
                                                <div className="flex justify-between">
                                                    <label className="text-zinc-100 font-medium">
                                                        {attribute.label}
                                                    </label>
                                                </div>
                                                <div className="text-zinc-500 text-sm mb-4">
                                                    {attribute.description}
                                                </div>
                                                <div className="flex flex-wrap gap-3 mb-6">
                                                    {attribute.options.map(({ label, value }) => (
                                                        <button
                                                            key={value}
                                                            onClick={() => handleOptionChange(attribute.name, value)}
                                                            className={`px-4 py-2 text-sm transition-colors duration-200 rounded-md
                                                                ${
                                                                    toneSettings[attribute.name] === value 
                                                                        ? 'bg-sky-500 text-white' 
                                                                        : 'bg-zinc-800 text-zinc-400 hover:bg-zinc-700'
                                                                }`}
                                                        >
                                                            {label}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                </>
                            )}

                            {activeTab === 'content' && (
                                <div className="space-y-4">
                                    <div className="space-y-2">
                                        <label className="text-zinc-100 font-medium">Account Notes</label>
                                        <textarea
                                            value={toneSettings.accountNotes || ''}
                                            onChange={(e) => handleOptionChange('accountNotes', e.target.value)}
                                            className="w-full bg-zinc-800 text-zinc-100 rounded-lg p-3 focus:ring-2 focus:ring-sky-500 focus:outline-none"
                                            rows={4}
                                            placeholder="Enter account-specific notes..."
                                        />
                                    </div>
                                    <div className="space-y-2">
                                        <label className="text-zinc-100 font-medium">Must Include</label>
                                        <textarea
                                            value={toneSettings.mustInclude || ''}
                                            onChange={(e) => handleOptionChange('mustInclude', e.target.value)}
                                            className="w-full bg-zinc-800 text-zinc-100 rounded-lg p-3 focus:ring-2 focus:ring-sky-500 focus:outline-none"
                                            rows={4}
                                            placeholder="Enter elements that must be included..."
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* <div className="mt-6 flex justify-end space-x-4 pt-12">
                            <button
                                onClick={() => setIsOpen(false)}
                                className="px-4 py-2 rounded bg-zinc-700 hover:bg-zinc-600 text-zinc-100"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    setIsOpen(false);
                                    onToneChange?.(toneSettings);
                                }}
                                className="px-4 py-2 rounded bg-sky-500 hover:bg-sky-400 text-white"
                            >
                                Set Tone
                            </button>
                        </div> */}
                    </motion.div>
                </div>
            </Dialog>
        </ThemeProvider>
    );
}
