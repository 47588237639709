import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { CompactPicker, ChromePicker } from 'react-color'
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
} from 'lexical';
import { 
  $setBlocksType 
} from '@lexical/selection';
import {
  $createHeadingNode,
  $createQuoteNode,
  HeadingNode,
  QuoteNode,
} from '@lexical/rich-text';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  ListNode,
  ListItemNode,
} from '@lexical/list';
import { useState, useEffect } from 'react';
import {
  TOGGLE_LINK_COMMAND,
  $isLinkNode,
  $createLinkNode,
} from '@lexical/link';
import {
  INSERT_TABLE_COMMAND,
  TableNode,
  TableCellNode,
  TableRowNode,
} from '@lexical/table';

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const [activeStyles, setActiveStyles] = useState(new Set());
  const [blockType, setBlockType] = useState('paragraph');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showBlockTypeDropdown, setShowBlockTypeDropdown] = useState(false);
  const [activeColor, setActiveColor] = useState('#FFFFFF');

  const [showFontFamilyDropdown, setShowFontFamilyDropdown] = useState(false);
  const [currentFontFamily, setCurrentFontFamily] = useState('Arial');
  const [fontSize, setFontSize] = useState('15');

  // Add these font options
  const fontFamilyOptions = [
    'Arial',
    'Courier New',
    'Georgia',
    'Times New Roman',
    'Trebuchet MS',
    'Verdana'
  ];

  // Add new formatting functions
  const formatFontFamily = (fontFamily) => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        selection.getNodes().forEach(node => {
          if (node.setStyle) {
            node.setStyle(`font-family: ${fontFamily}`);
          }
        });
      }
    });
    setCurrentFontFamily(fontFamily);
    setShowFontFamilyDropdown(false);
  };

  const formatFontSize = (size) => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        selection.getNodes().forEach(node => {
          if (node.setStyle) {
            node.setStyle(`font-size: ${size}px`);
          }
        });
      }
    });
    setFontSize(size);
  };

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const styles = new Set();
          if (selection.hasFormat('bold')) styles.add('bold');
          if (selection.hasFormat('italic')) styles.add('italic');
          if (selection.hasFormat('underline')) styles.add('underline');
          setActiveStyles(styles);
        }
      });
    });
  }, [editor]);

  const formatText = (format) => {
    if (format === 'link') {
      // Prompt for the URL
      const url = prompt('Enter URL:');
      if (url) {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
      }
    } else {
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
    }
  };

  const formatBlock = (blockType) => {
    if (blockType === 'h1' || blockType === 'h2') {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode(blockType));
        }
      });
    } else if (blockType === 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createQuoteNode());
        }
      });
    } else if (blockType === 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else if (blockType === 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, blockType);
    }
    setBlockType(blockType);
  };

  const formatAlignment = (alignment) => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, alignment);
  };

  const handleColorChange = (color) => {
    setActiveColor(color.hex);
    // Method 1: Using FORMAT_TEXT_COMMAND
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, {
      format: 'textColor',
      value: color.hex,
    });
    
    // Method 2: Using direct style application
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        // Try applying style directly
        selection.getNodes().forEach(node => {
          if (node.setStyle) {
            node.setStyle(`color: ${color.hex}`);
          }
        });
      }
    });
    
    setShowColorPicker(false);
  };

  const insertTable = () => {
    editor.dispatchCommand(INSERT_TABLE_COMMAND, {
      rows: 3,
      columns: 3,
    });
  };

  return (
    <div className="toolbar flex items-center gap-2 p-2  ">
      {/* Undo/Redo */}
      <button
        type="button"
        onClick={() => editor.dispatchCommand('undo')}
        className="p-2 hover:bg-gray-700 rounded"
        title="Undo"
      >
        <svg className="text-zinc-400" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M3 7v6h6"/>
          <path d="M3 13c0-4.97 4.03-9 9-9 4.97 0 9 4.03 9 9s-4.03 9-9 9"/>
        </svg>
      </button>
      <button
        type="button"
        onClick={() => editor.dispatchCommand('redo')}
        className="p-2 hover:bg-gray-700 rounded"
        title="Redo"
      >
        <svg className="text-zinc-400" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M21 7v6h-6"/>
          <path d="M21 13c0-4.97-4.03-9-9-9-4.97 0-9 4.03-9 9s4.03 9 9 9"/>
        </svg>
      </button>


      <div className="relative">
        <button
          type="button"
          onClick={() => setShowFontFamilyDropdown(!showFontFamilyDropdown)}
          className="flex items-center gap-2 bg-transparent border border-gray-700 rounded px-2 py-1 text-zinc-400 min-w-[100px]"
        >
          <span>{currentFontFamily}</span>
          <svg className="w-4 h-4 text-zinc-400" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
        
        {showFontFamilyDropdown && (
          <div className="absolute z-10 mt-1 w-48 bg-gray-800 border border-gray-700 rounded shadow-lg">
            {fontFamilyOptions.map((font) => (
              <button
                key={font}
                className="w-full px-4 py-2 text-left hover:bg-gray-700 text-zinc-400"
                style={{ fontFamily: font }}
                onClick={() => formatFontFamily(font)}
              >
                {font}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Add Font Size Control */}
      <div className="flex items-center gap-1">
        <button
          type="button"
          onClick={() => formatFontSize(Math.max(1, parseInt(fontSize) - 1))}
          className="p-2 hover:bg-gray-700 rounded text-zinc-400"
        >
          -
        </button>
        <input
          type="number"
          value={fontSize}
          onChange={(e) => formatFontSize(e.target.value)}
          className="w-12 bg-transparent border border-gray-700 rounded px-1 py-1 text-zinc-400 text-center"
        />
        <button
          type="button"
          onClick={() => formatFontSize(parseInt(fontSize) + 1)}
          className="p-2 hover:bg-gray-700 rounded text-zinc-400"
        >
          +
        </button>
      </div>

      <div className="h-6 w-px bg-gray-700 mx-2" />

      {/* Block Type Dropdown */}
      <div className="relative">
        <button
          type="button"
          onClick={() => setShowBlockTypeDropdown(!showBlockTypeDropdown)}
          className="flex items-center gap-2 bg-transparent border border-gray-700 rounded px-2 py-1 text-zinc-400"
        >
          <span className="flex items-center gap-2">
            {blockType === 'paragraph' && <span>¶</span>}
            {blockType === 'h1' && <span>H1</span>}
            {blockType === 'h2' && <span>H2</span>}
            {blockType === 'bullet' && <span>•</span>}
            {blockType === 'number' && <span>1.</span>}
            {blockType === 'quote' && <span>"</span>}
            {blockType === 'code' && <span>{'<>'}</span>}
            {blockType === 'paragraph' ? 'Normal' : 
              blockType === 'h1' ? 'Large Heading' :
              blockType === 'h2' ? 'Small Heading' :
              blockType === 'bullet' ? 'Bullet List' :
              blockType === 'number' ? 'Numbered List' :
              blockType === 'quote' ? 'Quote' :
              'Code Block'}
          </span>
          <svg className="w-4 h-4 text-zinc-400" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
        
        {showBlockTypeDropdown && (
          <div className="absolute z-10 mt-1 w-48 bg-gray-800 border border-gray-700 rounded shadow-lg">
            <button
              className="w-full px-4 py-2 text-left hover:bg-gray-700 flex items-center gap-2 text-zinc-400"
              onClick={() => { formatBlock('paragraph'); setShowBlockTypeDropdown(false); }}
            >
              <span className="w-6">¶</span> Normal
            </button>
            <button
              className="w-full px-4 py-2 text-left hover:bg-gray-700 flex items-center gap-2 text-zinc-400"
              onClick={() => { formatBlock('h1'); setShowBlockTypeDropdown(false); }}
            >
              <span className="w-6">H1</span> Large Heading
            </button>
            <button
              className="w-full px-4 py-2 text-left hover:bg-gray-700 flex items-center gap-2 text-zinc-400"
              onClick={() => { formatBlock('h2'); setShowBlockTypeDropdown(false); }}
            >
              <span className="w-6">H2</span> Small Heading
            </button>
            <button
              className="w-full px-4 py-2 text-left hover:bg-gray-700 flex items-center gap-2 text-zinc-400"
              onClick={() => { formatBlock('bullet'); setShowBlockTypeDropdown(false); }}
            >
              <span className="w-6">•</span> Bullet List
            </button>
            <button
              className="w-full px-4 py-2 text-left hover:bg-gray-700 flex items-center gap-2 text-zinc-400"
              onClick={() => { formatBlock('number'); setShowBlockTypeDropdown(false); }}
            >
              <span className="w-6">1.</span> Numbered List
            </button>
            <button
              className="w-full px-4 py-2 text-left hover:bg-gray-700 flex items-center gap-2 text-zinc-400"
              onClick={() => { formatBlock('quote'); setShowBlockTypeDropdown(false); }}
            >
              <span className="w-6">"</span> Quote
            </button>
             
          </div>
        )}
      </div>

      <div className="h-6 w-px bg-gray-700 mx-2" />

      {/* Text Formatting */}
      <button
        type="button"
        onClick={() => formatText('bold')}
        className={`p-2 hover:bg-gray-700 rounded text-zinc-400 font-bold ${
          activeStyles.has('bold') ? 'bg-gray-700' : ''
        }`}
        title="Bold"
      >
        B
      </button>
      <button
        type="button"
        onClick={() => formatText('italic')}
        className={`p-2 hover:bg-gray-700 rounded text-zinc-400 italic ${
          activeStyles.has('italic') ? 'bg-gray-700' : ''
        }`}
        title="Italic"
      >
        I
      </button>
      <button
        type="button"
        onClick={() => formatText('underline')}
        className={`p-2 hover:bg-gray-700 rounded text-zinc-400 underline ${
          activeStyles.has('underline') ? 'bg-gray-700' : ''
        }`}
        title="Underline"
      >
        U
      </button>

      <div className="h-6 w-px bg-gray-700 mx-2" />

      {/* Additional Formatting */}
      {/* <button
        type="button"
        onClick={() => formatText('strikethrough')}
        className="p-2 hover:bg-gray-700 rounded"
        title="Strikethrough"
      >
        S̶
      </button> */}
      {/* <button
        type="button"
        onClick={() => formatText('code')}
        className="p-2 hover:bg-gray-700 rounded font-mono"
        title="Code"
      >
        {'</>'}
      </button> */}
      <button
        type="button"
        onClick={() => formatText('link')}
        className="p-2 hover:bg-gray-700 rounded"
        title="Link"
      >
        <svg className="text-zinc-400" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
          <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
        </svg>
      </button>

      <div className="h-6 w-px bg-gray-700 mx-2" />

      {/* Add Text Alignment Buttons */}
      <button
        type="button"
        onClick={() => formatAlignment('left')}
        className="p-2 hover:bg-gray-700 rounded"
        title="Left Align"
      >
        <svg className="text-zinc-400" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="12" x2="15" y2="12"></line>
          <line x1="3" y1="18" x2="18" y2="18"></line>
        </svg>
      </button>
      <button
        type="button"
        onClick={() => formatAlignment('center')}
        className="p-2 hover:bg-gray-700 rounded"
        title="Center Align"
      >
        <svg className="text-zinc-400" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="6" y1="12" x2="18" y2="12"></line>
          <line x1="4" y1="18" x2="20" y2="18"></line>
        </svg>
      </button>
      <button
        type="button"
        onClick={() => formatAlignment('right')}
        className="p-2 hover:bg-gray-700 rounded"
        title="Right Align"
      >
        <svg className="text-zinc-400" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="9" y1="12" x2="21" y2="12"></line>
          <line x1="6" y1="18" x2="21" y2="18"></line>
        </svg>
      </button>
      <button
        type="button"
        onClick={() => formatAlignment('justify')}
        className="p-2 hover:bg-gray-700 rounded"
        title="Justify"
      >
        <svg className="text-zinc-400" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg>
      </button>

      <div className="h-6 w-px bg-gray-700 mx-2" />

      {/* Add Color Picker */}
      <div className="relative">
        <button
          type="button"
          onClick={() => setShowColorPicker(!showColorPicker)}
          className="p-2 hover:bg-gray-700 rounded flex items-center gap-2"
          title="Text Color"
        >
          <div 
            className="w-5 h-5 rounded-md border border-gray-700"
            style={{ backgroundColor: activeColor }}
          />
          <svg 
            className="w-4 h-4 text-zinc-400" 
            viewBox="0 0 20 20" 
            fill="currentColor"
          >
            <path 
              fillRule="evenodd" 
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" 
              clipRule="evenodd" 
            />
          </svg>
        </button>
        {showColorPicker && (
          <div className="absolute z-10 top-full mt-2">
            <CompactPicker onChange={handleColorChange} />
          </div>
        )}
      </div>

      <div className="h-6 w-px bg-gray-700 mx-2" />

      {/* Add Table Button */}
      <button
        type="button"
        onClick={insertTable}
        className="p-2 hover:bg-gray-700 rounded"
        title="Insert Table"
      >
        <svg 
          className="text-zinc-400" 
          xmlns="http://www.w3.org/2000/svg" 
          width="20" 
          height="20" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
          <line x1="3" y1="9" x2="21" y2="9"/>
          <line x1="3" y1="15" x2="21" y2="15"/>
          <line x1="9" y1="3" x2="9" y2="21"/>
          <line x1="15" y1="3" x2="15" y2="21"/>
        </svg>
      </button>
    </div>
  );
} 